import { useController, FieldValues, Control, Path } from 'react-hook-form';
import {
  ListItemBordered as ListItemBorderedDumb,
  ListItemBorderProps as ListItemBorderPropsDumb,
} from '@components/ListMui/ListItemBordered';
import { MouseEventHandler } from 'react';

export type ListItemBorderItem = Omit<ListItemBorderPropsDumb, `onClick` | `selected`>;
export interface ListItemBorderProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  listItems: ListItemBorderItem[];
  disabled?: boolean;
}

export const FormListItemBordered = <T extends FieldValues>({
  name,
  control,
  listItems,
  disabled,
}: ListItemBorderProps<T>) => {
  const { field } = useController({ control, name });

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    field.onChange(event.currentTarget.id);
  };

  return (
    <>
      {listItems.map((listItem) => (
        <ListItemBorderedDumb
          {...listItem}
          selected={String(field.value) === String(listItem.id)}
          key={`${listItem.title}-${listItem.id}`}
          onClick={handleClick}
          id={listItem.id}
          disabled={disabled || listItem.disabled}
        />
      ))}
    </>
  );
};
