import './validated-wrapper.css';

type InputErrorElementProps = {
  errorMsg: string | undefined;
  elementClasses?: string;
};

export const InputErrorElement = (props: InputErrorElementProps) => {
  if (!props.errorMsg) {
    return null;
  }

  return (
    <span data-testid="error-message" className={`validated-error${props.elementClasses || ``}`}>
      {props.errorMsg}
    </span>
  );
};
