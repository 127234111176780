import { Control, FieldValues, useController, Path } from 'react-hook-form';
import { RichTextInputWrapper, RichTextWrapperProps } from '@components/controls/rich-text-input-wrapper';

export type RichTextInputProps<T extends FieldValues> = Omit<
  RichTextWrapperProps,
  `name` | `value` | `onChange` | `errorMsg`
> & {
  control: Control<T>;
  name: Path<T>;
};

const RichTextInput = <T extends FieldValues>({ control, name, ...restRichTextInputProps }: RichTextInputProps<T>) => {
  const { field, fieldState } = useController({ control, name });
  return (
    <RichTextInputWrapper
      name={name}
      value={field.value}
      onChange={field.onChange}
      errorMsg={fieldState.error?.message}
      {...restRichTextInputProps}
    />
  );
};

export { RichTextInput as RichTextInputWrapper };
