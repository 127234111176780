import {
  COMMON_CONSTRAINTS,
  FormValidationArgs,
  ParsedBackendValidationResults,
  useFormValidation,
} from '@components/controls/validations';
import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useOnNumberStringBlur } from './onBlurs';
import { getOnChangeFunctionForType, OnChangeType } from './onChanges';
import { getComponentsForFieldType, getValueFromFormValues } from './utils';
import {
  BaseFormInputs,
  CheckboxRadioFieldsType,
  ComplexFormFieldType,
  FieldValue,
  SimpleFormFieldType,
  UseFormInputFields,
  UseFormOptionsType,
  UseFormReturnType,
  UseFormSchema,
  UseFormSchemaComplexFieldType,
  UseFormSchemaNumericStringFieldType,
  ValuesForSubmitValue,
} from './useFormTypes';

const getDefaultValueForType = <FieldType extends SimpleFormFieldType | ComplexFormFieldType>(
  type: FieldType,
): FieldValue<FieldType> => (type === SimpleFormFieldType.numeric ? 0 : ``) as FieldValue<FieldType>;

/** @deprecated please use useForm from react-hook-form */
export const useForm = <Schema extends UseFormSchema>(
  inputSchema: Schema,
  options?: UseFormOptionsType,
): UseFormReturnType<Schema> => {
  const [schema, setSchema] = useState(inputSchema);

  const { inputs: inputsDefaults, errors: errorsDefaults } = useMemo(
    () =>
      Object.keys(schema).reduce(
        (acc, key: keyof Schema) => {
          const { defaultValue, type } = schema[key];
          acc.inputs[key] = (defaultValue ?? getDefaultValueForType(type)) as FieldValue<Schema[typeof key][`type`]>;
          acc.errors[key] = ``;

          return acc;
        },
        {
          inputs: {} as BaseFormInputs<Schema>,
          errors: {} as Record<keyof Schema, string>,
        },
      ),
    [schema],
  );

  const [inputs, setInputs] = useState<BaseFormInputs<Schema>>(inputsDefaults);
  const [startingFormData, setStartingFormData] = useState<BaseFormInputs<Schema>>(inputsDefaults);
  const [errors, setErrors] = useState<Record<keyof Schema, string>>(errorsDefaults);
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  const valuesForSubmit = useMemo(
    () =>
      Object.keys(inputs).reduce(
        (acc, key: keyof Schema) => {
          const val = getValueFromFormValues(inputs[key]);
          let parsedVal: ValuesForSubmitValue<Schema, typeof key>;
          if (!schema[key]) {
            return acc;
          }
          switch (schema[key].type) {
            case SimpleFormFieldType.numeric:
              parsedVal = (val ?? null) as ValuesForSubmitValue<Schema, typeof key>;
              break;
            case ComplexFormFieldType.checkbox:
              parsedVal = String(val)
                .split(`,`)
                .filter((elem) => elem !== ``) as ValuesForSubmitValue<Schema, typeof key>;
              break;
            case SimpleFormFieldType.numericString: {
              const newParsed = String(val).replace(`,`, `.`);
              parsedVal = (isNaN(Number(newParsed)) ? `0` : newParsed) as ValuesForSubmitValue<Schema, typeof key>;
              break;
            }
            default:
              parsedVal = val as ValuesForSubmitValue<Schema, typeof key>;
          }
          acc[key] = parsedVal;
          return acc;
        },
        {} as {
          [key in keyof Schema]: ValuesForSubmitValue<Schema, key>;
        },
      ),
    [inputs, schema],
  );

  const handleSetError = useCallback(
    (field: keyof typeof schema) => (value: string | undefined) => {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: value,
      }));
    },
    [],
  );

  const handleSetValue = useCallback(
    (field: keyof typeof schema) => (value: FieldValue<Schema[typeof field][`type`]>) => {
      if (value !== inputs[field]) {
        setInputs((prevInputs) => ({
          ...prevInputs,
          [field]: value,
        }));
      }
    },
    [inputs],
  );

  const validationSchema = useMemo(
    () =>
      Object.keys(schema).reduce((acc, key: keyof Schema) => {
        const { constraints = [] } = schema[key];
        acc[key] = {
          constraints,
          setter: handleSetError(key) as (value: SetStateAction<string>) => void,
        };

        return acc;
      }, {} as FormValidationArgs),
    [handleSetError, schema],
  );

  const { validate: validateForm, checkValidationAndAssign, resetErrorFields } = useFormValidation(validationSchema);

  const setAllErrors = useCallback((newErrors: Record<keyof Schema, string>) => {
    setErrors(newErrors);
  }, []);

  const setAllValues = useCallback(
    (newValues: BaseFormInputs<Schema>) => {
      resetErrorFields();
      setInputs(newValues);
      setStartingFormData(newValues);
    },
    [resetErrorFields],
  );

  const resetAllValues = useCallback(() => {
    const newValues = Object.keys(schema).reduce((acc, key: keyof Schema) => {
      const { defaultValue, type } = schema[key];
      acc[key] = (defaultValue ?? getDefaultValueForType(type)) as FieldValue<Schema[typeof key][`type`]>;
      return acc;
    }, {} as BaseFormInputs<Schema>);
    setAllValues(newValues);
  }, [schema, setAllValues]);

  const resetToStartingValues = useCallback(() => {
    setAllValues(startingFormData);
  }, [setAllValues, startingFormData]);

  const formFields = useMemo(
    () =>
      Object.keys(schema).reduce(
        (acc, key: keyof Schema) => {
          const { type: fieldType, constraints = [], isDisabled: isFieldDisabled } = schema[key];

          const propsOfNumericString = schema[key] as UseFormSchemaNumericStringFieldType;
          const min = typeof propsOfNumericString?.min === `undefined` ? 0 : propsOfNumericString?.min ?? undefined;
          const max = propsOfNumericString?.max;

          const onChangeFunction = getOnChangeFunctionForType(
            fieldType,
            setInputs as React.Dispatch<React.SetStateAction<BaseFormInputs<Schema>>>,
            key,
            ``,
            {
              precision: propsOfNumericString?.precision,
              min,
              max,
            },
          );

          const isDisabled =
            options?.isDisabled ||
            (isFieldDisabled && typeof isFieldDisabled === `function`
              ? isFieldDisabled(inputs[key], inputs)
              : isFieldDisabled);

          const baseReturnInputFields = {
            value: inputs[key],
            errorMsg: errors[key],
            onChange: onChangeFunction,
            onBlur: fieldType === SimpleFormFieldType.numericString ? useOnNumberStringBlur(min, max) : undefined,
            isRequired: constraints.includes(COMMON_CONSTRAINTS.required),
            name: String(key),
            type: fieldType === SimpleFormFieldType.numeric ? `number` : `text`,
            isDisabled,
            min,
            max,
          };

          let allInputFields;

          if (fieldType === ComplexFormFieldType.dropdown) {
            allInputFields = {
              components: getComponentsForFieldType(fieldType),
              options: (schema[key] as UseFormSchemaComplexFieldType).options,
              errorMsg: errors[key],
            };
          }

          if (fieldType === ComplexFormFieldType.checkbox || fieldType === ComplexFormFieldType.radio) {
            const options = (schema[key] as UseFormSchemaComplexFieldType).options;
            const fields = (options ?? []).map(({ value, label, disabled }) => {
              const name = key;
              const checked =
                fieldType === ComplexFormFieldType.checkbox
                  ? (String(baseReturnInputFields.value).split(`,`) || []).includes(String(value) as never)
                  : String(baseReturnInputFields.value) === String(value);
              const onChange = getOnChangeFunctionForType(
                fieldType,
                setInputs as React.Dispatch<React.SetStateAction<BaseFormInputs<Schema>>>,
                key,
                value,
              ) as OnChangeType<ComplexFormFieldType.checkbox | ComplexFormFieldType.radio>;
              return {
                type: fieldType === ComplexFormFieldType.checkbox ? `checkbox` : `radio`,
                label,
                name: String(name),
                value: String(value),
                checked,
                onChange,
                isDisabled: isDisabled || disabled,
              } as CheckboxRadioFieldsType;
            });

            allInputFields = {
              fields,
              value: inputs[key],
              errorMsg: errors[key],
              isDisabled: isDisabled,
            };
          } else {
            allInputFields = {
              ...allInputFields,
              ...baseReturnInputFields,
            };
          }

          acc[key] = {
            inputFields: {
              ...allInputFields,
            } as UseFormInputFields<Schema, typeof fieldType>,
            setError: handleSetError(key),
            setValue: handleSetValue(key),
          };

          return acc;
        },
        {} as UseFormReturnType<typeof schema>[`formFields`],
      ),
    [schema, options?.isDisabled, inputs, errors, handleSetError, handleSetValue],
  );

  const validate = useCallback(() => {
    setAllErrors(errorsDefaults);
    const errors = validateForm(inputs);
    checkValidationAndAssign({
      errors,
    });
    setHasErrors(!!errors);
    return !errors;
  }, [checkValidationAndAssign, errorsDefaults, inputs, setAllErrors, validateForm]);

  const setBackendValidation = useCallback(
    ({ errors }: ParsedBackendValidationResults) => {
      checkValidationAndAssign({
        errors,
      });
      setHasErrors(!!errors);
    },
    [checkValidationAndAssign],
  );

  useEffect(() => {
    if (options?.beValidationResults) {
      setBackendValidation(options?.beValidationResults);
    }
  }, [options?.beValidationResults, setBackendValidation]);

  const refreshSchema = () => {
    setSchema(inputSchema);
  };

  return {
    formFields,
    setAllErrors,
    setAllValues,
    resetErrorFields,
    resetAllValues,
    resetToStartingValues,
    setBackendValidation,
    hasErrors,
    validate,
    valuesForSubmit,
    refreshSchema,
  };
};
