import { useCallback, useMemo } from 'react';
import {
  ProductCategoryRowDto,
  ProjectProductOrigin,
  ProjectProductRowDto,
  ProjectType,
  SolarEnergyProductionForRoofDto,
} from '@generatedTypes/data-contracts';
import { ProjectProductListItem } from './ProjectProductListItem';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { getFormattedNumberWithStaticFraction } from '@pages/NewLeads/utils';
import { WindCalculationButton } from './WindCalculationButton';
import { Empty } from '@components/Empty/Empty';
import emptyLeadInformation from '@assets/svg/EmptyComponentIcons/emptyLeadInformation.svg';
import { Theme } from '@mui/material/styles';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useGetOffer } from '@services/api/offers/offers';

type useGetGroupedProductsProps = {
  projectProducts: ProjectProductRowDto[];
  solarEnergyProductionForRoof: SolarEnergyProductionForRoofDto[];
  mapProductsToListItems: (product: ProjectProductRowDto, projectId: number) => JSX.Element;
  projectId: number;
};

const listSxOverrides = (theme: Theme) => ({
  marginInline: -3,
  '& .MuiListItemSecondaryAction-root': {
    right: theme.spacing(4),
  },
});
const useGetGroupedProducts = ({
  projectProducts,
  mapProductsToListItems,
  projectId,
  solarEnergyProductionForRoof,
}: useGetGroupedProductsProps) => {
  return useMemo(() => {
    const userProducts = projectProducts
      ?.filter((product) => product.origin === ProjectProductOrigin.User)
      .map((product) => mapProductsToListItems(product, projectId));
    const templateProducts = projectProducts?.filter((product) => product.origin === ProjectProductOrigin.Template);

    const doesAllHaveRoofGroup = (
      projectProductGroups: ProjectProductRowDto[],
    ): projectProductGroups is Required<ProjectProductRowDto>[] =>
      projectProductGroups.every((product) => Boolean(product.group));

    const groupedTemplateProducts: Record<
      number,
      {
        roofName?: string;
        products: JSX.Element[];
        pdfReportUri?: string | null;
        production?: number;
        peakPower?: number;
      }
    > = {};

    if (doesAllHaveRoofGroup(templateProducts)) {
      templateProducts.forEach((product, index) => {
        if (groupedTemplateProducts[product.group.groupId]) {
          groupedTemplateProducts[product.group.groupId].products.push(mapProductsToListItems(product, projectId));
        } else {
          const solarDataForRoof = solarEnergyProductionForRoof[index];
          groupedTemplateProducts[product.group.groupId] = {
            roofName: product.group?.groupName,
            products: [mapProductsToListItems(product, projectId)],
            pdfReportUri: product.group?.pdfReportUri,
            production: solarDataForRoof?.yearlyProduction,
            peakPower: solarDataForRoof?.peekPower,
          };
        }
      });
    } else {
      groupedTemplateProducts[0] = { products: templateProducts.map(mapProductsToListItems) };
    }

    return { userProducts, groupedTemplateProducts };
  }, [mapProductsToListItems, projectId, projectProducts, solarEnergyProductionForRoof]);
};

type UseMapProductsToListItemsProps = {
  productCategories: ProductCategoryRowDto[];
  showPanelForSelectedProduct: (categoryId: number, projectProductId?: number) => void;
  isOfferLocked: boolean;
};

const useMapProductsToListItems = ({
  productCategories,
  showPanelForSelectedProduct,
  isOfferLocked,
}: UseMapProductsToListItemsProps) =>
  useCallback(
    (product: ProjectProductRowDto, projectId: number) => (
      <ProjectProductListItem
        key={`${product.id}-${product.productId}`}
        productCategories={productCategories}
        product={product}
        showPanelForSelectedProduct={showPanelForSelectedProduct}
        sx={{ paddingInlineStart: 3 }}
        projectId={projectId}
        disableEditing={isOfferLocked}
      />
    ),
    [isOfferLocked, productCategories, showPanelForSelectedProduct],
  );

type ProductsListForProjectProps = Omit<UseMapProductsToListItemsProps, `isOfferLocked`> & {
  projectType: ProjectType;
  projectProducts: ProjectProductRowDto[];
  projectId: number;
  solarEnergyProductionForRoof: SolarEnergyProductionForRoofDto[];
  lastModifiedDate: string;
};

export const ProductsListsForProject: React.FC<ProductsListForProjectProps> = (props) => {
  const {
    translate,
    translations: {
      editProduct,
      common: {
        units: { kiloWattHour, kiloWattPeak },
        year,
      },
    },
  } = useTranslations();

  const { isOfferLocked } = useGetOffer();

  const mapProductsToListItems = useMapProductsToListItems({
    productCategories: props.productCategories,
    showPanelForSelectedProduct: props.showPanelForSelectedProduct,
    isOfferLocked: isOfferLocked,
  });
  const { userProducts, groupedTemplateProducts } = useGetGroupedProducts({
    projectProducts: props.projectProducts,
    mapProductsToListItems,
    projectId: props.projectId,
    solarEnergyProductionForRoof: props.solarEnergyProductionForRoof,
  });

  const templateGroupsToArr = Object.entries(groupedTemplateProducts);

  const haveAnyProjectProduct = templateGroupsToArr.some(([, { products }]) => products.length > 0);
  const haveAnyUserProduct = userProducts?.length > 0;

  return (
    <>
      {userProducts?.length > 0 && <List sx={listSxOverrides}>{userProducts}</List>}
      {props.projectType !== ProjectType.Empty && haveAnyProjectProduct ? (
        <Typography variant="h3">{translate(editProduct.templateProductHeader)}</Typography>
      ) : null}
      {templateGroupsToArr.map(([groupId, { roofName, products, pdfReportUri, production, peakPower }]) => (
        <div key={groupId}>
          {roofName && (
            <>
              <Stack direction="row" spacing={2} alignItems="center" mb={1}>
                <Typography variant="h4">{roofName}</Typography>
                {production && peakPower && (
                  <Typography variant="subtitle2">
                    {getFormattedNumberWithStaticFraction(production / 1000, 0)} {translate(kiloWattHour)}/
                    {translate(year)},{` `}
                    {getFormattedNumberWithStaticFraction(peakPower, 0)} {translate(kiloWattPeak)}
                  </Typography>
                )}
              </Stack>
              <WindCalculationButton pdfReportUri={pdfReportUri} lastProjectModificationDate={props.lastModifiedDate} />
            </>
          )}
          <List sx={listSxOverrides}>{products}</List>
        </div>
      ))}
      {!haveAnyUserProduct && !haveAnyProjectProduct && (
        <Empty icon={emptyLeadInformation} description={translate(editProduct.noProducts)} />
      )}
    </>
  );
};
