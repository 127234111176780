import { ListItemBordered } from '@components/ListMui/ListItemBordered';
import { ComplexFormFieldType, UseFormFormFieldReturnTypeCheckboxRadio } from '@hooks/useForm/useFormTypes';
import React, { useCallback } from 'react';
import { Box } from '@mui/material';

type BoxListProps = {
  classesForContainer?: string;
  isDisabled?: boolean;
  inputFields: UseFormFormFieldReturnTypeCheckboxRadio<ComplexFormFieldType.radio>;
  onClickCallback?: (value: string) => void;
  label?: string;
};

export const BoxList: React.FC<BoxListProps> = ({
  inputFields,
  classesForContainer,
  isDisabled = false,
  onClickCallback,
  label,
}) => (
  <>
    {label ? <span>{label}</span> : null}
    <Box
      component="fieldset"
      display="grid"
      gap={1}
      className={`${classesForContainer}`}
      disabled={isDisabled}
      radioGroup=""
    >
      {inputFields.fields.map((field, index) => {
        const onClick = useCallback(() => {
          onClickCallback?.(field.value);
          field.onChange();
        }, [field]);

        return (
          <ListItemBordered
            key={index}
            disabled={isDisabled || field.isDisabled}
            selected={field.checked}
            onClick={!field.isDisabled ? onClick : undefined}
            title={field.label}
          ></ListItemBordered>
        );
      })}
    </Box>
  </>
);
