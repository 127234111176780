import flow from 'lodash.flow';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { setSolarEnergySelectedRoofId } from '@redux/actions/lead';
import {
  RailOrientationAttributeValueDto,
  SolarPanelOrientationAttributeValueDto,
} from '@generatedTypes/data-contracts';
import { SolarEnergyProjectValues } from '../../SolarEnergyProject';
import {
  dispatchOptionsMappedWithAvailability,
  getIdsFromAvailableOptions,
  getProductAttributeValueRowDtoForIds,
  getRoofIdToFind,
  getRoofMaterialAndOrientations,
  getValuesFromAttributeRow,
  mapAttributeValuesToIds,
} from '../utils';
import {
  initialState,
  actions,
  useHandleSolarEnergyDataChangeReducer,
  getDispatchMaterialIdOrOrientationsData,
} from './store';
import { useFetchSolarEnergyProjectMountingMaterialManufacturers } from '@services/api/solarEnergyProjects/solarEnergyProjectMountingMaterialManufacturers';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { getOptionsFromSettings } from '@pages/NewLeads/project/solarEnergyProject/optionsWithTranslations';
import {
  selectAvailableMountingMaterialManufacturers,
  selectAvailablePanelOrientations,
  selectAvailableRailOrientations,
  setAvailableMountingMaterialManufacturers,
  setAvailablePanelOrientations,
  setAvailableRailOrientations,
} from '@redux/reducers/slices/solarEnergyProjectPage';
import { selectSelectedRoofId } from '@redux/selectors/lead';

export const useHandleSolarEnergyDataChanges = (
  setValue: UseFormSetValue<SolarEnergyProjectValues>,
  watch: UseFormWatch<SolarEnergyProjectValues>,
  getValues: UseFormGetValues<SolarEnergyProjectValues>,
) => {
  const {
    fetchSolarEnergyProjectMountingMaterialManufacturers,
    solarEnergyProjectMountingMaterialManufacturers,
    isLoadingSolarEnergyProjectMountingMaterialManufacturers,
  } = useFetchSolarEnergyProjectMountingMaterialManufacturers();
  const dispatch = useDispatch();

  const selectedRoofId = useSelector(selectSelectedRoofId);
  const solarPanelOrientationsWithAvailability = useSelector(selectAvailablePanelOrientations);
  const railOrientationOptionsWithAvailability = useSelector(selectAvailableRailOrientations);
  const solarPanelMountingMaterialManufacturersWithAvailability = useSelector(
    selectAvailableMountingMaterialManufacturers,
  );
  const availablePanelOrientationIds = useMemo(
    () => getIdsFromAvailableOptions(solarPanelOrientationsWithAvailability),
    [solarPanelOrientationsWithAvailability],
  );
  const availableRailOrientationIds = useMemo(
    () => getIdsFromAvailableOptions(railOrientationOptionsWithAvailability),
    [railOrientationOptionsWithAvailability],
  );
  const availableMountingMaterialIds = useMemo(
    () => getIdsFromAvailableOptions(solarPanelMountingMaterialManufacturersWithAvailability),
    [solarPanelMountingMaterialManufacturersWithAvailability],
  );

  const solarEnergyProjectSettings = useGetSolarEnergyProjectSettings();
  const roofMaterialValues = getValuesFromAttributeRow(solarEnergyProjectSettings?.roofMaterial);
  const settingsOptions = useMemo(
    () => getOptionsFromSettings(solarEnergyProjectSettings),
    [solarEnergyProjectSettings],
  );
  const [selectedRoofFormData, setSelectedRoofFormData] = useState<SolarEnergyProjectValues[`roofs`][number] | null>(
    null,
  );
  const [
    {
      currentRoofMaterialId,
      currentMountingMaterialId,
      currentAttachmentId,
      currentPanelOrientations,
      currentRailOrientations,
      panelOrientationsString,
      railOrientationsString,
      previousRoofMaterialId,
      previousAttachmentId,
      initialOptionsLoaded,
      optionsForNewSelectionLoadingState,
      lastSuccessfullyLoadedRoofId,
      previousSelectedRoofId,
    },
    dispatchLocal,
  ] = useReducer(useHandleSolarEnergyDataChangeReducer, initialState);
  const dispatchMaterialIdOrOrientationsChanged = getDispatchMaterialIdOrOrientationsData(dispatchLocal);

  const updateDataFromFormHandler = useCallback(
    (data: SolarEnergyProjectValues, name: string) => {
      const roofIdToFind = getRoofIdToFind(selectedRoofId, name);
      if (roofIdToFind !== null) {
        const newData = data?.roofs?.[roofIdToFind] as SolarEnergyProjectValues[`roofs`][number] | null;
        const { roofMaterialId, mountingMaterialId, attachmentId, panelOrientations, railOrientations } =
          getRoofMaterialAndOrientations(newData, roofMaterialValues);
        const dataChanged =
          selectedRoofId !== previousSelectedRoofId ||
          (roofMaterialId !== null && roofMaterialId !== currentRoofMaterialId) ||
          (attachmentId !== null && attachmentId !== currentAttachmentId) ||
          panelOrientations.toString() !== currentPanelOrientations.toString() ||
          railOrientations.toString() !== currentRailOrientations.toString();

        if (dataChanged) {
          dispatchLocal(actions.setPreviousSelectedRoofId(selectedRoofId));
          setSelectedRoofFormData(newData);
          dispatchMaterialIdOrOrientationsChanged({
            roofMaterialId,
            attachmentId,
            panelOrientations,
            railOrientations,
            mountingMaterialId,
          });
        }
      }
    },
    [
      currentAttachmentId,
      currentPanelOrientations,
      currentRailOrientations,
      currentRoofMaterialId,
      dispatchMaterialIdOrOrientationsChanged,
      previousSelectedRoofId,
      selectedRoofId,
      roofMaterialValues,
    ],
  );

  useEffect(() => {
    if (!isLoadingSolarEnergyProjectMountingMaterialManufacturers && optionsForNewSelectionLoadingState === `LOADING`) {
      dispatchLocal(actions.setOptionsForNewSelectionLoadingState(`LOADED`));
      dispatchLocal(actions.setLastSuccessfullyLoadedRoofId(selectedRoofId));
    }
  }, [
    dispatchLocal,
    isLoadingSolarEnergyProjectMountingMaterialManufacturers,
    optionsForNewSelectionLoadingState,
    selectedRoofId,
  ]);

  useEffect(() => {
    if (selectedRoofId !== null) {
      updateDataFromFormHandler(getValues(), `roofs.${selectedRoofId}`);
    }
  }, [initialOptionsLoaded, getValues, updateDataFromFormHandler, selectedRoofId]);

  watch((data, { name }) => {
    updateDataFromFormHandler(data as SolarEnergyProjectValues, name as string);
  });

  useEffect(() => {
    return () => {
      dispatch(setSolarEnergySelectedRoofId(null));
      dispatch(setAvailablePanelOrientations([]));
      dispatch(setAvailableRailOrientations([]));
      dispatch(setAvailableMountingMaterialManufacturers([]));
    };
  }, [dispatch]);

  const getAvailablePanelOrientationsMapForRoofMaterial = useCallback(
    (materialId: number): SolarPanelOrientationAttributeValueDto[] => {
      const solarPanelOrientationValues = getValuesFromAttributeRow(solarEnergyProjectSettings?.solarPanelOrientation);
      const availableIds = roofMaterialValues.find(({ id }) => id === materialId)?.availablePanelOrientationIds;
      return solarPanelOrientationValues.filter(({ id }) => availableIds?.includes(id)) ?? [];
    },
    [roofMaterialValues, solarEnergyProjectSettings?.solarPanelOrientation],
  );

  const getAvailablePanelOrientationsForRoofMaterial = useCallback(
    (materialId: number) =>
      flow(
        getAvailablePanelOrientationsMapForRoofMaterial,
        mapAttributeValuesToIds,
        getProductAttributeValueRowDtoForIds(settingsOptions?.SolarPanelOrientation ?? []),
      )(materialId),
    [getAvailablePanelOrientationsMapForRoofMaterial, settingsOptions?.SolarPanelOrientation],
  );

  const getAvailableRailOrientationsMapForRoofMaterial = useCallback(
    (materialId: number): RailOrientationAttributeValueDto[] => {
      const railOrientationValues = getValuesFromAttributeRow(solarEnergyProjectSettings?.railOrientation);
      const availableIds = roofMaterialValues.find(({ id }) => id === materialId)?.availableRailOrientationIds;
      return railOrientationValues.filter(({ id }) => availableIds?.includes(id)) ?? [];
    },
    [roofMaterialValues, solarEnergyProjectSettings?.railOrientation],
  );

  const getAvailableRailOrientationsForRoofMaterial = useCallback(
    (materialId: number) =>
      flow(
        getAvailableRailOrientationsMapForRoofMaterial,
        mapAttributeValuesToIds,
        getProductAttributeValueRowDtoForIds(settingsOptions?.RailOrientation ?? []),
      )(materialId),
    [getAvailableRailOrientationsMapForRoofMaterial, settingsOptions?.RailOrientation],
  );

  const dispatchPatchOrientationValuesForMaterialId = useCallback(
    (materialId: number) => {
      dispatchOptionsMappedWithAvailability(
        settingsOptions?.SolarPanelOrientation ?? [],
        getAvailablePanelOrientationsForRoofMaterial(materialId).map(({ id }) => id),
        setAvailablePanelOrientations,
        dispatch,
      );
    },
    [dispatch, getAvailablePanelOrientationsForRoofMaterial, settingsOptions?.SolarPanelOrientation],
  );

  const dispatchRailOrientationValuesForMaterialId = useCallback(
    (materialId: number) => {
      dispatchOptionsMappedWithAvailability(
        settingsOptions?.RailOrientation ?? [],
        getAvailableRailOrientationsForRoofMaterial(materialId).map(({ id }) => id),
        setAvailableRailOrientations,
        dispatch,
      );
    },
    [dispatch, getAvailableRailOrientationsForRoofMaterial, settingsOptions?.RailOrientation],
  );

  useEffect(() => {
    const optionsAreLoaded = optionsForNewSelectionLoadingState === `LOADED`;
    if (!optionsAreLoaded) {
      return;
    }
    const shouldUpdate =
      solarEnergyProjectMountingMaterialManufacturers?.length > 0 ||
      solarEnergyProjectMountingMaterialManufacturers?.length !==
        solarPanelMountingMaterialManufacturersWithAvailability?.length;
    if (shouldUpdate) {
      dispatchOptionsMappedWithAvailability(
        settingsOptions?.MountingMaterialManufacturer ?? [],
        solarEnergyProjectMountingMaterialManufacturers,
        setAvailableMountingMaterialManufacturers,
        dispatch,
      );
    }
    dispatchLocal(actions.setOptionsForNewSelectionLoadingState(`FINISHED`));
  }, [
    dispatch,
    optionsForNewSelectionLoadingState,
    settingsOptions?.MountingMaterialManufacturer,
    solarEnergyProjectMountingMaterialManufacturers,
    solarPanelMountingMaterialManufacturersWithAvailability?.length,
  ]);

  // Handles dispatching of available options when roof material or panel orientations change
  useEffect(() => {
    const panelOrientationsChanged = currentPanelOrientations.toString() !== panelOrientationsString;
    const railOrientationsChanged = currentRailOrientations.toString() !== railOrientationsString;
    const roofMaterialChanged = currentRoofMaterialId !== previousRoofMaterialId;
    const attachmentChanged = currentAttachmentId !== previousAttachmentId;

    if (!currentRoofMaterialId || optionsForNewSelectionLoadingState === `LOADING`) {
      return;
    }
    if (roofMaterialChanged) {
      dispatchLocal(actions.setOptionsForNewSelectionLoadingState(`LOADING`));
      fetchSolarEnergyProjectMountingMaterialManufacturers({
        roofMaterialId: currentRoofMaterialId,
        solarPanelOrientationId: currentPanelOrientations,
        railOrientationId: currentRailOrientations,
        attachmentId: currentAttachmentId || 0,
      });
      dispatchLocal(actions.setPreviousRoofMaterialId(currentRoofMaterialId));
      dispatchPatchOrientationValuesForMaterialId(currentRoofMaterialId);
      dispatchRailOrientationValuesForMaterialId(currentRoofMaterialId);
      if (!initialOptionsLoaded) {
        dispatchLocal(actions.setInitialOptionsLoaded(true));
      }
      return;
    }
    if (attachmentChanged) {
      dispatchLocal(actions.setOptionsForNewSelectionLoadingState(`LOADING`));
      fetchSolarEnergyProjectMountingMaterialManufacturers({
        roofMaterialId: currentRoofMaterialId,
        solarPanelOrientationId: currentPanelOrientations,
        railOrientationId: currentRailOrientations,
        attachmentId: currentAttachmentId || 0,
      });
      dispatchLocal(actions.setPreviousAttachmentId(currentAttachmentId));
      return;
    }
    if (panelOrientationsChanged) {
      dispatchLocal(actions.setOptionsForNewSelectionLoadingState(`LOADING`));
      fetchSolarEnergyProjectMountingMaterialManufacturers({
        roofMaterialId: currentRoofMaterialId,
        solarPanelOrientationId: currentPanelOrientations,
        railOrientationId: currentRailOrientations,
        attachmentId: currentAttachmentId || 0,
      });
      dispatchLocal(actions.setPanelOrientationsString(currentPanelOrientations.toString()));
      return;
    }
    if (railOrientationsChanged) {
      dispatchLocal(actions.setOptionsForNewSelectionLoadingState(`LOADING`));
      fetchSolarEnergyProjectMountingMaterialManufacturers({
        roofMaterialId: currentRoofMaterialId,
        solarPanelOrientationId: currentPanelOrientations,
        railOrientationId: currentRailOrientations,
        attachmentId: currentAttachmentId || 0,
      });
      dispatchLocal(actions.setRailOrientationsString(currentRailOrientations.toString()));
      return;
    }
  }, [
    currentRoofMaterialId,
    panelOrientationsString,
    currentPanelOrientations,
    currentRailOrientations,
    previousRoofMaterialId,
    settingsOptions?.MountingMaterialManufacturer,
    settingsOptions?.SolarPanelOrientation,
    dispatch,
    dispatchPatchOrientationValuesForMaterialId,
    dispatchRailOrientationValuesForMaterialId,
    initialOptionsLoaded,
    fetchSolarEnergyProjectMountingMaterialManufacturers,
    currentAttachmentId,
    railOrientationsString,
    previousAttachmentId,
    optionsForNewSelectionLoadingState,
  ]);

  // Handles setting of default values for solar panel orientation after changing available options
  useEffect(() => {
    if (
      selectedRoofId === null ||
      !selectedRoofFormData ||
      !selectedRoofFormData?.patches?.length ||
      !initialOptionsLoaded ||
      optionsForNewSelectionLoadingState === `LOADING`
    ) {
      return;
    }
    selectedRoofFormData?.patches.forEach((patch, index) => {
      const { solarPanelOrientationId, railOrientationId } = patch;
      if (!solarPanelOrientationId || !availablePanelOrientationIds.includes(Number(solarPanelOrientationId))) {
        setValue(
          `roofs.${selectedRoofId}.patches.${index}.solarPanelOrientationId`,
          availablePanelOrientationIds[0] ?? null,
        );
      }
      if (!railOrientationId || !availableRailOrientationIds.includes(Number(railOrientationId))) {
        setValue(`roofs.${selectedRoofId}.patches.${index}.railOrientationId`, availableRailOrientationIds[0] ?? null);
      }
    });
  }, [
    optionsForNewSelectionLoadingState,
    availablePanelOrientationIds,
    availableRailOrientationIds,
    initialOptionsLoaded,
    selectedRoofFormData,
    selectedRoofId,
    setValue,
    solarPanelOrientationsWithAvailability,
  ]);

  // Handles setting of default values for solar panel mounting material after changing available options
  useEffect(() => {
    if (
      selectedRoofId === null ||
      !solarPanelMountingMaterialManufacturersWithAvailability?.length ||
      !initialOptionsLoaded ||
      optionsForNewSelectionLoadingState !== `FINISHED` ||
      lastSuccessfullyLoadedRoofId !== selectedRoofId
    ) {
      return;
    }
    const valueToSet = availableMountingMaterialIds.includes(Number(currentMountingMaterialId))
      ? currentMountingMaterialId
      : availableMountingMaterialIds[0];
    if (!currentMountingMaterialId || !availableMountingMaterialIds.includes(Number(currentMountingMaterialId))) {
      setValue(`roofs.${selectedRoofId}.mountingMaterialManufacturerId`, valueToSet);
    }
  }, [
    optionsForNewSelectionLoadingState,
    availableMountingMaterialIds,
    currentMountingMaterialId,
    initialOptionsLoaded,
    selectedRoofId,
    setValue,
    solarPanelMountingMaterialManufacturersWithAvailability,
    solarPanelOrientationsWithAvailability,
    isLoadingSolarEnergyProjectMountingMaterialManufacturers,
    lastSuccessfullyLoadedRoofId,
  ]);
};
