import { styled } from '@mui/material/styles';
import SliderCore from '@mui/material/Slider';

export const SliderSmall = styled(SliderCore)(({ theme }) => ({
  marginLeft: 0,
  width: `100%`,
  '& .MuiSlider-thumb': {
    height: 14,
    width: 14,
    backgroundColor: theme.palette.rexelEnergy.ctaBlue,
  },
  '& .MuiSlider-rail': {
    backgroundColor: theme.palette.rexelEnergy.lightGrey,
    marginLeft: 0,
    width: `100%`,
  },
  '& .MuiSlider-track': {
    display: `none`,
  },
}));
