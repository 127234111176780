import { ProjectType } from '@generatedTypes/data-contracts';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { FormInputs } from '@pages/NewLeads/project/emptyProject/emptyProject';
import { useGetOffer } from '@services/api/offers/offers';
import { useSelector } from 'react-redux';
import { selectCurrentOfferBidId } from '@redux/selectors/lead';
import {
  useDeleteEnergyStorageProject,
  useGetEnergyStorageProject,
  usePostEnergyStorageProject,
  usePutEnergyStorageProject,
} from '@services/api/energyStorageProject/energyStorageProject';
import { EnergyStorageProjectForm } from '../project/energyStorageProject/EnergyStorageProject';

export interface EnergyStorageProps {
  onClose: () => void;
}

export const EnergyStorageFormWrapper = ({ onClose }: EnergyStorageProps) => {
  const pushDataLayer = useAnalytics();
  const { isOfferLocked, lead } = useGetOffer();
  const { energyStorageProjectDetails } = useGetEnergyStorageProject();
  const {
    isCreateEnergyStorageProjectLoading,
    createEnergyStorageProjectAsync,
    validationErrors: createEnergyStorageProjectErrors,
  } = usePostEnergyStorageProject();
  const {
    isUpdateEnergyStorageProjectLoading,
    updateEnergyStorageProjectAsync,
    validationErrors: updateEnergyStorageProjectErrors,
  } = usePutEnergyStorageProject();
  const { deleteEnergyStorageProjectAsync } = useDeleteEnergyStorageProject();
  const currentProjectID = useSelector(selectCurrentOfferBidId);

  const handleAddEditEmptyProject = (values: FormInputs) => {
    pushDataLayer({
      event: AnalyticEvents.saveProject,
      projectType: ProjectType.Empty,
    });
    if (currentProjectID) {
      updateEnergyStorageProjectAsync({ ...values, id: currentProjectID }).then(() => onClose());
    } else {
      createEnergyStorageProjectAsync({ ...values, leadId: lead?.id || 0 }).then(() => onClose());
    }
  };

  return (
    <EnergyStorageProjectForm
      onSubmit={handleAddEditEmptyProject}
      onDelete={(id) => deleteEnergyStorageProjectAsync(id).then(() => onClose())}
      onClose={() => {
        onClose();
        pushDataLayer({ event: AnalyticEvents.cancelProject, projectType: ProjectType.Empty });
      }}
      projectDetails={energyStorageProjectDetails}
      beValidationResults={createEnergyStorageProjectErrors || updateEnergyStorageProjectErrors}
      isDisabled={isCreateEnergyStorageProjectLoading || isUpdateEnergyStorageProjectLoading || isOfferLocked}
    />
  );
};
