import { LeadRowDto } from '@generatedTypes/data-contracts';
import { LeadsAction, LEADS_ACTIONS } from '@redux/actions/leads';

export type LeadsState = {
  leadsList: LeadRowDto[];
};

export const initState: LeadsState = {
  leadsList: [],
};

export const leadsReducer = (state = initState, action: LeadsAction) => {
  switch (action.type) {
    case LEADS_ACTIONS.LEADS_LIST:
      return { ...state, leadsList: action.payload.leadsList ?? state.leadsList };
    default:
      return state;
  }
};
