import { SolarEnergyProductionMonthlyDto } from '@generatedTypes/data-contracts';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useMemo } from 'react';
import { createMonthRange } from './monthText';
import { prepareChartData } from './prepareChartData';
import { BarChart } from '../BarChart/BarChart';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Stack } from '@mui/material';

type ProductionSimpleColumnChartProps = {
  monthlyProduction: SolarEnergyProductionMonthlyDto[];
};

const FIRST_MONTH = 1;
const LAST_MONTH = 12;

export const ProductionSimpleColumnChart: React.FC<ProductionSimpleColumnChartProps> = ({ monthlyProduction }) => {
  const {
    translate,
    translations: { months },
  } = useTranslations();

  const chartDataMod = useMemo(
    () => prepareChartData(monthlyProduction, months, translate),
    [months, monthlyProduction, translate],
  );

  const maxChartValue = Math.round(
    Math.max(
      ...chartDataMod.datasets[0].data.map((value) => {
        if (value !== null && typeof value === `number`) {
          return value || 0;
        }
        return 0;
      }),
    ) * 1.02,
  );

  const monthRange = createMonthRange(months, translate, FIRST_MONTH, LAST_MONTH);

  return (
    <Stack>
      <Typography variant="subtitle1" component="p" lineHeight="24px">{`${maxChartValue} kWh`}</Typography>
      <Divider sx={{ margin: 0, marginTop: `-1px` }} />
      <Box sx={{ height: 160 }}>
        <BarChart
          data={chartDataMod}
          showLegend={false}
          showXScale={false}
          showYScale={false}
          tooltipOptions={{ title: () => ``, label: (context) => `${context.formattedValue} kWh` }}
          barPercentage={1.05}
          borderRadius={3}
          yTicksCallback={(_, index, allTicks) =>
            Number(((allTicks.length - 1) / 2).toFixed(0)) === index ? `` : undefined
          }
          yTicksDash={[5, 5]}
        />
      </Box>
      <Typography variant="subtitle1" mt={1}>
        {monthRange}
      </Typography>
    </Stack>
  );
};
