import { useSelector } from 'react-redux';
import { selectSelectedPartnerAdditionTemplateId } from '@redux/reducers/slices/partner';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { PROJECT_ADDITION_TEMPLATES } from '@variables/queryKeys';
import { fetchPartnerAdditionTemplate } from '@services/api/projectAdditionTemplates/projectAdditionTempaltes';

export const useGetProjectAdditionTemplate = () => {
  const selectedProjectAdditionTemplateId = useSelector(selectSelectedPartnerAdditionTemplateId) ?? -1;

  const { data, isLoading, error } = useCustomQuery({
    queryKey: [PROJECT_ADDITION_TEMPLATES, selectedProjectAdditionTemplateId],
    queryFn: () => fetchPartnerAdditionTemplate(selectedProjectAdditionTemplateId),
    enabled: selectedProjectAdditionTemplateId > -1,
  });

  return {
    partnerProjectAdditionsTemplate: data,
    isFetchingPartnerProjectAdditionsTemplate: isLoading,
    errorFetchingPartnerProjectAdditionsTemplate: error as Error | undefined,
  };
};
