import React from 'react';

type CommaSeparatedTitleProps = {
  firstPart: string;
  secondPart: string;
  preventWrap?: boolean;
};

export const CommaSeparatedTitle: React.FC<CommaSeparatedTitleProps> = ({
  firstPart,
  secondPart,
  preventWrap = false,
}) => {
  return (
    <span style={{ whiteSpace: `nowrap` }}>
      {firstPart.trimEnd()}
      {preventWrap ? `` : <wbr />}
      {secondPart && `, ${secondPart}`}
    </span>
  );
};
