import { ProjectProductOrigin, ProjectProductRowDto } from '@generatedTypes/data-contracts';
import { ProductList } from '@assets/translations/translations';
import { mdiCancel, mdiPlusCircleOutline, mdiTrashCanOutline } from '@mdi/js';
import { useDeleteProjectProduct } from '@services/api/projectProducts/projectProductDelete';
import { useUpdateProjectProductMutation } from '@services/api/projectProducts/projectProductEdit';

enum ProductExclusionState {
  ClientProduct,
  Excluded,
  Included,
}

const getProductExclusionState = (product: ProjectProductRowDto) =>
  product.origin === ProjectProductOrigin.User
    ? ProductExclusionState.ClientProduct
    : product.isExcluded
      ? ProductExclusionState.Excluded
      : ProductExclusionState.Included;

type GetExclusionButtonProps = {
  product: ProjectProductRowDto;
  translation: ProductList;
  deleteAction: ReturnType<typeof useDeleteProjectProduct>[`deleteProjectProduct`];
  editAction: ReturnType<typeof useUpdateProjectProductMutation>[`updateProjectProduct`];
};

export const getExclusionButtonProps = ({
  product,
  translation,
  editAction,
  deleteAction,
}: GetExclusionButtonProps) => {
  const exclusionState = getProductExclusionState(product);
  switch (exclusionState) {
    case ProductExclusionState.ClientProduct:
      return {
        label: translation.deleteButton,
        action: () => {
          deleteAction(product.id);
        },
        icon: mdiTrashCanOutline,
      };
    case ProductExclusionState.Excluded:
      return {
        label: translation.includeButton,
        action: () => {
          editAction({ id: product.id, isExcluded: false, productId: product.productId, quantity: product.quantity });
        },
        icon: mdiPlusCircleOutline,
      };
    case ProductExclusionState.Included:
      return {
        label: translation.excludeButton,
        action: () => {
          editAction({ id: product.id, isExcluded: true, productId: product.productId, quantity: product.quantity });
        },
        icon: mdiCancel,
      };
  }
};
