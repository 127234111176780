import { DEFAULT_DISTANCE_BOX_WITH_PREFIX_WIDTH, getDistanceBoxPath, vertexPath } from './constants';
import { Palette } from '@mui/material';

type GetVertexMarkerIconStyleProps = {
  selected: boolean;
  palette: Palette;
};
export const getVertexMarkerIconStyle = ({ selected, palette }: GetVertexMarkerIconStyleProps): google.maps.Symbol => ({
  path: vertexPath,
  anchor: new google.maps.Point(12, 12),
  strokeColor: selected ? palette.rexelEnergy.selectedBlue : palette.rexelEnergy.ctaBlue,
  strokeWeight: 4,
  fillOpacity: 1,
  fillColor: palette.common.white,
});

const EDGE_STYLING = {
  STROKE_SELECTED: `#ebc634`,
  STROKE_UNSELECTED: `#0072ce`,
};

type GetEdgePolylineStyleProps = {
  selected: boolean;
  isHovered: boolean;
  canChangeEdge: boolean;
};
export const getEdgePolylineStyle = ({
  selected,
  isHovered,
  canChangeEdge,
}: GetEdgePolylineStyleProps): google.maps.PolylineOptions => ({
  strokeColor: `${
    selected || (canChangeEdge && isHovered) ? EDGE_STYLING.STROKE_SELECTED : EDGE_STYLING.STROKE_UNSELECTED
  }`,
  strokeWeight: selected ? 6 : 4,
  zIndex: 2,
});

type GetDistanceMarkerIconStyleProps = {
  selected: boolean;
  palette: Palette;
};
export const getDistanceMarkerIconStyle = ({
  selected,
  palette,
}: GetDistanceMarkerIconStyleProps): google.maps.Symbol => ({
  path: getDistanceBoxPath(selected ? DEFAULT_DISTANCE_BOX_WITH_PREFIX_WIDTH : undefined),
  fillColor: selected ? palette.rexelEnergy.selectedBlue : palette.rexelEnergy.ctaBlue,
  fillOpacity: 1,
  strokeOpacity: 1,
  strokeColor: selected ? palette.rexelEnergy.selectedBlue : palette.rexelEnergy.ctaBlue,
});

type GetPolygonStyleProps = {
  hidePolygon: boolean;
  highlightEdges: boolean;
  isHovered: boolean;
  palette: Palette;
};
export const getPolygonStyle = ({
  hidePolygon,
  highlightEdges,
  palette,
  isHovered,
}: GetPolygonStyleProps): google.maps.PolygonOptions => ({
  strokeOpacity: hidePolygon ? 0 : highlightEdges ? 0 : 1,
  fillOpacity: hidePolygon ? 0 : 0.16,
  fillColor: palette.common.white,
  strokeWeight: 2,
  strokeColor: isHovered ? palette.rexelEnergy.ctaBlue : palette.common.white,
});
