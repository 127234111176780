import { useGetOfferBasketItems } from '@services/api/offers/offers';
import { Box, Stack, Typography } from '@mui/material';
import { OfferBidBasketItemDto } from '@generatedTypes/data-contracts';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { useFormContext } from 'react-hook-form';
import { BasketItemsZodSchema } from './zodSettings';
import { CheckboxList } from '@components/controls/react-hook-form-friendly/smart';
import { ProductListItem } from '@pages/NewLeads/common/ProductListItem';

const BasketItemsHeader = () => {
  const {
    translate,
    translations: {
      leads: {
        offer: { cart },
      },
    },
  } = useTranslations();

  return (
    <Stack>
      <Stack justifyContent="center" sx={(theme) => ({ height: theme.spacing(6) })}>
        <Typography variant="h2">{translate(cart.changeCartHeader)}</Typography>
      </Stack>
      <Typography>{translate(cart.changeCartDescription)}</Typography>
    </Stack>
  );
};

const ProjectProductsList = ({ projectProducts }: { projectProducts: OfferBidBasketItemDto[] }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { cart },
      },
    },
  } = useTranslations();

  const formContext = useFormContext<BasketItemsZodSchema>();

  const excludableOptions = projectProducts
    .filter((product) => product.canBeExcludedFromBasket)
    .map(({ projectProductId, ...item }) => ({
      value: projectProductId,
      label: <ProductListItem key={projectProductId} product={{ id: projectProductId, ...item }} />,
    }));

  return (
    <Stack>
      <Stack ml={1}>
        {projectProducts
          .filter((product) => !product.canBeExcludedFromBasket)
          .map(({ projectProductId, ...item }) => (
            <ProductListItem key={projectProductId} product={{ id: projectProductId, ...item }} />
          ))}
      </Stack>
      {excludableOptions.length ? (
        <Box mt={2}>
          <Typography variant="body2" mb={2}>
            {translate(cart.optionalMaterials)}
          </Typography>
          <Box ml={2}>
            <CheckboxList
              control={formContext?.control}
              name="excludedProductsIds"
              options={excludableOptions}
              isLabelOnRight
              reversedSelection
            />
          </Box>
        </Box>
      ) : null}
    </Stack>
  );
};

export const BasketItemSelectionList = () => {
  const { offerBasketItems, isLoadingOfferBasketItems } = useGetOfferBasketItems();

  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={(theme) => ({
        margin: theme.spacing(3),
        [theme.breakpoints.down(`md`)]: { margin: theme.spacing(0, 3) },
      })}
    >
      {isLoadingOfferBasketItems ? (
        <div>Loading...</div>
      ) : (
        <Stack spacing={2} width="100%">
          <BasketItemsHeader />
          <HorizontalLine />
          {offerBasketItems?.map((project, index) => (
            <Stack key={`${project.projectName}-${index}`} spacing={2}>
              <Typography variant="h3">{project.projectName}</Typography>
              <ProjectProductsList projectProducts={project.projectProducts} />
              <HorizontalLine />
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
