import { Dropdown } from '@components/Dropdown/Dropdown';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { LeadOfferStatus } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import {
  mdiCheck,
  mdiCheckboxBlankBadgeOutline,
  mdiFileCheckOutline,
  mdiFilterVariant,
  mdiPencil,
  mdiSendVariantOutline,
  mdiClose,
  mdiTimerAlertOutline,
} from '@mdi/js';
import { Divider, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { FC } from 'react';
import { buttonMediumSize } from 'src/styles/theme/theme';
import { selectIsPartnerUser } from '@redux/reducers/slices/user';
import { useSelector } from 'react-redux';

interface LeadsFilterProps {
  leadsFilter: LeadOfferStatus | undefined;
  setLeadsFilter: (value: LeadOfferStatus | undefined) => void;
}

export const LeadsFilter: FC<LeadsFilterProps> = ({ setLeadsFilter, leadsFilter }) => {
  const {
    translate,
    translations: {
      leads: {
        list: { filters },
      },
      common: { viewAll },
    },
  } = useTranslations();

  const isOperationOrDealer = !useSelector(selectIsPartnerUser);

  const selectedFilterText =
    leadsFilter && translate(filters[leadsFilter.toLowerCase() as Lowercase<typeof leadsFilter>]);
  const [isFilterDropdownOpen, toggleIsFilterDropdownOpen] = useToggle();

  const onFilterClick = (filter?: LeadOfferStatus) => {
    setLeadsFilter(filter);
    toggleIsFilterDropdownOpen();
  };

  return (
    <Dropdown
      buttonText={selectedFilterText ?? ``}
      iconButtonIconPath={mdiFilterVariant}
      isDropdownOpen={isFilterDropdownOpen}
      toggleIsDropdownOpen={toggleIsFilterDropdownOpen}
      placeIconAfterText={true}
      styles={{
        padding: leadsFilter ? 1.5 : 1,
        minWidth: buttonMediumSize,
        height: buttonMediumSize,
        minHeight: buttonMediumSize,
      }}
      transformLeft
    >
      <MenuItem
        onClick={() => onFilterClick(LeadOfferStatus.Created)}
        selected={leadsFilter === LeadOfferStatus.Created}
      >
        <ListItemIcon>
          <MdiIconWrapper path={mdiCheckboxBlankBadgeOutline} iconColor="black" />
        </ListItemIcon>
        {translate(filters.created)}
      </MenuItem>
      <MenuItem
        onClick={() => onFilterClick(LeadOfferStatus.Projecting)}
        selected={leadsFilter === LeadOfferStatus.Projecting}
      >
        <ListItemIcon>
          <MdiIconWrapper path={mdiPencil} iconColor="black" />
        </ListItemIcon>
        {translate(filters.projecting)}
      </MenuItem>
      {isOperationOrDealer && (
        <MenuItem
          onClick={() => onFilterClick(LeadOfferStatus.Projected)}
          selected={leadsFilter === LeadOfferStatus.Projected}
        >
          <ListItemIcon>
            <MdiIconWrapper path={mdiFileCheckOutline} iconColor="black" />
          </ListItemIcon>
          {translate(filters.projected)}
        </MenuItem>
      )}
      <MenuItem onClick={() => onFilterClick(LeadOfferStatus.Sent)} selected={leadsFilter === LeadOfferStatus.Sent}>
        <ListItemIcon>
          <MdiIconWrapper path={mdiSendVariantOutline} iconColor="black" />
        </ListItemIcon>
        {translate(filters.sent)}
      </MenuItem>
      <MenuItem
        onClick={() => onFilterClick(LeadOfferStatus.Accepted)}
        selected={leadsFilter === LeadOfferStatus.Accepted}
      >
        <ListItemIcon>
          <MdiIconWrapper path={mdiCheck} iconColor="black" />
        </ListItemIcon>
        {translate(filters.accepted)}
      </MenuItem>
      <MenuItem
        onClick={() => onFilterClick(LeadOfferStatus.Expired)}
        selected={leadsFilter === LeadOfferStatus.Expired}
      >
        <ListItemIcon>
          <MdiIconWrapper path={mdiTimerAlertOutline} iconColor="black" />
        </ListItemIcon>
        {translate(filters.expired)}
      </MenuItem>
      <MenuItem onClick={() => onFilterClick(LeadOfferStatus.Lost)} selected={leadsFilter === LeadOfferStatus.Lost}>
        <ListItemIcon>
          <MdiIconWrapper path={mdiClose} iconColor="black" />
        </ListItemIcon>
        {translate(filters.lost)}
      </MenuItem>
      <Divider />
      <MenuItem sx={{ justifyContent: `center` }} onClick={() => onFilterClick()}>
        <Typography variant="h4" color="primary" lineHeight={2.5}>
          {translate(viewAll)}
        </Typography>
      </MenuItem>
    </Dropdown>
  );
};
