import { FC } from 'react';
import { useCreateLead } from '@services/api/leads/lead-create';
import { LeadForm, LeadFormProps } from './LeadForm/LeadForm';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useSelector } from 'react-redux';
import { selectSelectedDealerId } from '@redux/reducers/slices/dealerUser';

export const NewLeadForm: FC<Omit<LeadFormProps, `onSubmit` | `errors` | `isSubmitting`>> = ({ onClose, isOpen }) => {
  const selectedDealerId = useSelector(selectSelectedDealerId);
  const { createLead, isCreatingLeadLoading, validationErrors } = useCreateLead();
  const pushDataLayer = useAnalytics();

  return (
    <LeadForm
      onSubmit={(data) => {
        pushDataLayer({ event: AnalyticEvents.createLead });
        return createLead({ ...data, assignedDealerId: selectedDealerId }).then(onClose);
      }}
      isSubmitting={isCreatingLeadLoading}
      errors={validationErrors}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};
