import { useCallback, useContext, useEffect, useMemo } from 'react';
import { UseFormGetValues } from 'react-hook-form/dist/types/form';
import { useFormContext } from 'react-hook-form';
import { Orientation, SolarEnergyProjectDto } from '@generatedTypes/data-contracts';
import { setSolarEnergySelectedRoofId } from '@redux/actions/lead';
import { store } from '@redux/store';
import { SolarEnergyProjectValues } from '../../../SolarEnergyProject';
import { SolarEnergyProject } from '../../roofVisualisationTypes';
import { getProjectionForMap, getSelectedRoofAndPatch } from '../accessors';
import { calculateMeterInPixels } from '../calculations';
import { SOLAR_MAP_EDIT_STATE } from '../constants';
import { createInitialSolarProject } from '../createInitialSolarProject';
import { recalculateAllPanels } from '../panel';
import { actions, isLoadingPassedState, getDispatchSolarEnergyProject, SolarVisualisationLoadingState } from './store';
import { useHandleAsynchronousMapEvents } from './utils';
import { getPanelOrientationsForResolver } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/useSolarMapVisualisation/dataUtils';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { SolarMapVisualisationContext, SolarMapVisualisationDispatchContext } from './context';
import { EnqueuePatchContext } from '../useSavePatchQueue';

export const MIN_ZOOM_FOR_SHOWING_PANELS = 19;

type UseGetOrientationForPatchProps = {
  getValues?: UseFormGetValues<SolarEnergyProjectValues>;
  solarPanelsOrientationsObject: Record<number, Orientation>;
};
export const useGetOrientationForPatch =
  ({ getValues, solarPanelsOrientationsObject }: UseGetOrientationForPatchProps) =>
  (roofIndex: number, patchIndex: number): Orientation | null => {
    const orientationId = getValues?.(`roofs.${roofIndex}.patches.${patchIndex}.solarPanelOrientationId`);
    return orientationId ? solarPanelsOrientationsObject[orientationId] : null;
  };
export type GetOrientationForPatch = ReturnType<typeof useGetOrientationForPatch>;

export type SolarMapPositionType = { lat: number; lng: number };

export const recalculatePanels = (
  map: google.maps.Map | null,
  loadingState: SolarVisualisationLoadingState,
  solarEnergyProject: SolarEnergyProject,
) => {
  if (map && isLoadingPassedState(loadingState, `loadingFinished`)) {
    return recalculateAllPanels(map, solarEnergyProject);
  }
  return solarEnergyProject;
};

interface UseSolarMapVisualisationProps {
  bounds?: google.maps.LatLngBounds;
  projectDetails: SolarEnergyProjectDto | null;
}

export const useSolarMapVisualisation = ({ bounds, projectDetails }: UseSolarMapVisualisationProps) => {
  const enqueuePatch = useContext(EnqueuePatchContext);
  const formFunctions = useFormContext<SolarEnergyProjectValues>();
  const { solarPanelOrientation } = useGetSolarEnergyProjectSettings();
  const mappedPanelOrientations = getPanelOrientationsForResolver(solarPanelOrientation);
  const getOrientationForPatch = useGetOrientationForPatch({
    getValues: formFunctions?.getValues,
    solarPanelsOrientationsObject: mappedPanelOrientations,
  });

  const { mapEditState, loadingState, map, solarEnergyProject } = useContext(SolarMapVisualisationContext);
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  const { selectedRoof, selectedPatch, selectedRoofIndex, selectedPatchIndex } = useMemo(
    () => getSelectedRoofAndPatch(solarEnergyProject.roofs),
    [solarEnergyProject],
  );
  const dispatchSolarEnergyProject = getDispatchSolarEnergyProject(dispatch);

  const selectedPatchIsStraightType =
    formFunctions?.watch(`roofs.${selectedRoofIndex}.patches.${selectedPatchIndex}.shapeType`) === `straight`;

  useEffect(() => {
    if (selectedRoof && mapEditState === SOLAR_MAP_EDIT_STATE.BASE) {
      dispatch(actions.setMapEditState(SOLAR_MAP_EDIT_STATE.ROOF));
    }
  }, [dispatch, mapEditState, selectedRoof]);

  useEffect(() => {
    store.dispatch(setSolarEnergySelectedRoofId(selectedRoofIndex >= 0 ? selectedRoofIndex : null));
  }, [selectedRoofIndex]);

  const setMapProjection = useCallback(() => {
    dispatch(actions.setLoadingState(`loadingMapProjection`));
    setTimeout(() => {
      const newMapProjectionExists = map && getProjectionForMap(map);
      if (newMapProjectionExists) {
        dispatch(actions.setLoadingState(`mapProjectionLoaded`));
      } else {
        setMapProjection();
      }
    }, 200);
  }, [dispatch, map]);

  const setInitialProjectData = useCallback(() => {
    if (map && projectDetails) {
      dispatch(actions.setLoadingState(`loadingProjectData`));
      dispatchSolarEnergyProject(createInitialSolarProject({ ...projectDetails, getOrientationForPatch }));
      if (bounds && map) {
        map.fitBounds(bounds);
      }
    }
    dispatch(actions.setLoadingState(`projectDataLoaded`));
  }, [map, projectDetails, dispatch, dispatchSolarEnergyProject, getOrientationForPatch, bounds]);

  const reloadProjectOnMap = useCallback(() => {
    if (!map) {
      return;
    }
    dispatch(actions.setLoadingState(`reloadingProjectsOnMap`));
    dispatch(actions.setMeterInPixels(calculateMeterInPixels(map)));
    const newSolarEnergyProject = recalculateAllPanels(map, solarEnergyProject);
    dispatchSolarEnergyProject(newSolarEnergyProject);
    dispatch(actions.setLoadingState(`loadingFinished`));
  }, [map, dispatch, solarEnergyProject, dispatchSolarEnergyProject]);

  useEffect(() => {
    switch (loadingState) {
      case `mapLoaded`:
        setInitialProjectData();
        break;
      case `projectDataLoaded`:
        setMapProjection();
        break;
      case `mapProjectionLoaded`:
        reloadProjectOnMap();
        break;
    }
  }, [loadingState, reloadProjectOnMap, setInitialProjectData, setMapProjection]);

  const selectedRooOrientation = useMemo(() => {
    if (selectedRoof && selectedPatch) {
      return getOrientationForPatch(selectedRoofIndex, selectedPatchIndex);
    }
    return null;
  }, [getOrientationForPatch, selectedPatch, selectedPatchIndex, selectedRoof, selectedRoofIndex]);

  useEffect(() => {
    const orientationOnPatchMatchWithForm = selectedRooOrientation === selectedPatch?.panelOrientation;
    if (selectedPatch && !orientationOnPatchMatchWithForm) {
      enqueuePatch({ ...selectedPatch, panelOrientation: selectedRooOrientation });
    }
  }, [enqueuePatch, selectedPatch, selectedRooOrientation]);

  useHandleAsynchronousMapEvents({
    selectedPatchIsStraightType,
    dispatch,
    selectedPatch,
    enqueuePatch,
  });

  useEffect(() => {
    dispatch(actions.setParentHeight(map?.getDiv().offsetHeight ?? 0));
    dispatch(actions.setParentWidth(map?.getDiv().offsetWidth ?? 0));
  }, [dispatch, map]);
};
