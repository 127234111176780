import { UserType } from '@generatedTypes/data-contracts';
import { fetchOwnUser, fetchOwnUserPartner } from './users';
import { getDealerUserDetails } from '../dealers/dealers';
import { UnreachableCaseError } from '@utils/typeSafeCheck';
import { useSelector } from 'react-redux';
import { USER } from '@variables/queryKeys';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { selectUserId, selectUserType } from '@redux/reducers/slices/user';

export const getUniversalUserData = (userType: UserType | null) => (userId: number | null) => {
  let apiMethod = fetchOwnUser;
  if (!userType) {
    return fetchOwnUser(userId);
  }
  switch (userType) {
    case UserType.Dealer:
      apiMethod = getDealerUserDetails;
      break;
    case UserType.Operation:
      apiMethod = fetchOwnUser;
      break;
    case UserType.Partner:
      apiMethod = fetchOwnUserPartner;
      break;
    case UserType.None:
      apiMethod = fetchOwnUser;
      break;
    default:
      UnreachableCaseError(userType);
  }
  return apiMethod(userId);
};

export const useGetUserData = () => {
  const userType = useSelector(selectUserType);
  const loggedInUserId = useSelector(selectUserId);

  const { data, isLoading, refetch } = useCustomQuery({
    queryKey: [USER, userType, loggedInUserId],
    queryFn: () => getUniversalUserData(userType)(loggedInUserId),
    enabled: Boolean(userType && loggedInUserId),
  });

  return { user: data ?? null, isLoadingUserData: isLoading, refetchUserData: refetch };
};
