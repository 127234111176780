import { ProductAttributeRowDto, ProductDto } from '@generatedTypes/data-contracts';
import { filterProducts } from './filterProducts';

import { AppliedFilters } from './types';

// This function returns a map of all available values for each attribute, based on the applied filters
// the availability of a value is determined by the products that are available after applying each filter
export const getAttributesMapForFilteredProducts = (
  allAttributes: ProductAttributeRowDto[],
  appliedFilters: AppliedFilters,
  allProducts: ProductDto[],
) => {
  const attributeValuesMap = new Map<number, Set<number>>();

  for (const attribute of allAttributes) {
    if (attribute.id) {
      const filtersToUseInCurrentIteration = getFiltersToUse(attributeValuesMap, appliedFilters);
      const filteredProducts = filterProducts(allProducts, filtersToUseInCurrentIteration);
      const attributeValuesFromFilteredProducts = getValuesForAttributeFromProducts(attribute, filteredProducts);
      attributeValuesMap.set(attribute.id, attributeValuesFromFilteredProducts);
    }
  }

  return attributeValuesMap;
};

// This function returns an array of filters to use for a given attribute, based on the attributeValuesMap and the appliedFilters
const getFiltersToUse = (attributeValuesMap: Map<number, Set<number>>, appliedFilters: AppliedFilters) => {
  return appliedFilters.filter((filter) => attributeValuesMap.has(filter.attributeId));
};

const getValuesOfAttributeFromProduct =
  (attribute: ProductAttributeRowDto) =>
  (product: ProductDto): number[] =>
    product.productAttributes
      .filter((productAttribute) => productAttribute.id === attribute.id)
      .map((attribute) => attribute.values)
      .flat()
      .map((attributeValue) => attributeValue.id);

// This function returns a set of all values for a given attribute that are present in the filtered products
const getValuesForAttributeFromProducts = (
  attribute: ProductAttributeRowDto,
  filteredProducts: ProductDto[],
): Set<number> => new Set(filteredProducts.map(getValuesOfAttributeFromProduct(attribute)).flat());
