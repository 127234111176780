import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { useToggle } from '@hooks/useToggle';
import { mdiChevronDown } from '@mdi/js';
import { Box, Divider, InputLabel, MenuItem, FormHelperText } from '@mui/material';
import MuiSelect from '@mui/material/Select';
import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export interface Option<TValue extends number | string = string | number> {
  label: string | React.ReactNode;
  value: TValue;
}

export type Divider = `divider`;

export interface SelectProps<T extends FieldValues> {
  label: string;
  name: Path<T>;
  id?: string;
  options: (Option | Divider)[];
  control: Control<T>;
  defaultValue?: PathValue<T, Path<T>>;
  disabled?: boolean;
  dataTestId?: string;
  sx?: SxProps<Theme>;
  required?: boolean;
}

const mapOptions = (options: (Option | Divider)[]) =>
  options.map((option, i) => {
    if (option === `divider`) {
      return <Divider key={`divider-${i.toString()}`} />;
    }
    return (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  });

export const Select = <T extends FieldValues>({
  label,
  name,
  id,
  options,
  control,
  defaultValue,
  disabled,
  dataTestId,
  sx,
  required,
}: SelectProps<T>) => {
  const [isSelectOpen, toggleIsSelectOpen] = useToggle();

  const { field, fieldState } = useController({ name, control, defaultValue });

  const isError = Boolean(fieldState.error?.message);

  return (
    <Box sx={sx}>
      <InputLabel htmlFor={name || id} error={isError} required={required}>
        {label}
      </InputLabel>
      <MuiSelect
        required={required}
        data-testid={dataTestId}
        disabled={disabled}
        id={id || name}
        name={name}
        value={field.value}
        onChange={(e) => {
          if (!disabled) {
            field.onChange(e);
            field.onBlur();
          }
        }}
        onBlur={field.onBlur}
        open={!disabled && isSelectOpen}
        onClose={toggleIsSelectOpen}
        onOpen={!disabled ? toggleIsSelectOpen : undefined}
        IconComponent={() => (
          <Box p={1} lineHeight={1} onClick={toggleIsSelectOpen}>
            <MdiIconWrapper size="large" path={mdiChevronDown} />
          </Box>
        )}
        error={isError}
        fullWidth
      >
        {mapOptions(options)}
      </MuiSelect>
      {fieldState.error?.message && <FormHelperText error={isError}>{fieldState.error?.message}</FormHelperText>}
    </Box>
  );
};
