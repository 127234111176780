import { ReactNode } from 'react';

interface ConditionalWrapperProps {
  wrapper: (children: React.ReactNode) => ReactNode;
  children: ReactNode;
  renderWrapper?: boolean;
}

export const WrapperRenderer = ({ wrapper, children, renderWrapper = true }: ConditionalWrapperProps) =>
  renderWrapper ? <>{wrapper(children)}</> : <>{children}</>;
