import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SolarEnergyProject } from '../../roofVisualisationTypes';
import { DEFAULT_SOLAR_PANEL_PROJECT, DEFAULT_ZOOM, SOLAR_MAP_EDIT_STATE } from '../constants';
import { useCallback } from 'react';
import { recalculatePanels } from './useSolarMapVisualisation';
import { addVertex } from '../vertex';

const SOLAR_VISUALISATION_LOADING_STATES = [
  `initial`,
  `mapLoaded`,
  `loadingProjectData`,
  `projectDataLoaded`,
  `loadingMapProjection`,
  `mapProjectionLoaded`,
  `reloadingProjectsOnMap`,
  `loadingFinished`,
] as const;
export type SolarVisualisationLoadingState = (typeof SOLAR_VISUALISATION_LOADING_STATES)[number];

export type UseSolarMapVisualisationState = {
  loadingState: SolarVisualisationLoadingState;
  mapEditState: SOLAR_MAP_EDIT_STATE;
  lastZoom: number | null;
  hidePanels: boolean;
  meterInPixels: number | null;
  map: google.maps.Map | null;
  solarEnergyProject: SolarEnergyProject;
  parentWidth: number;
  parentHeight: number;
};

export const initialState: UseSolarMapVisualisationState = {
  loadingState: `initial`,
  mapEditState: SOLAR_MAP_EDIT_STATE.BASE,
  lastZoom: DEFAULT_ZOOM,
  hidePanels: false,
  meterInPixels: null,
  map: null,
  solarEnergyProject: DEFAULT_SOLAR_PANEL_PROJECT,
  parentWidth: 0,
  parentHeight: 0,
};

const UseSolarMapVisualisationSlice = createSlice({
  name: `USE_SOLAR_MAP_VISUALISATION`,
  initialState,
  reducers: {
    setLoadingState: (state, action: PayloadAction<SolarVisualisationLoadingState>) => {
      state.loadingState = action.payload;
    },
    setMapEditState: (state, action: PayloadAction<SOLAR_MAP_EDIT_STATE>) => {
      state.mapEditState = action.payload;
    },
    setLastZoom: (state, action: PayloadAction<number>) => {
      state.lastZoom = action.payload;
    },
    setHidePanels: (state, action: PayloadAction<boolean>) => {
      state.hidePanels = action.payload;
    },
    setMeterInPixels: (state, action: PayloadAction<number | null>) => {
      state.meterInPixels = action.payload;
    },
    setMap: (state, action: PayloadAction<google.maps.Map | null>) => {
      state.map = action.payload;
    },
    setSolarEnergyProject: (state, action: PayloadAction<SolarEnergyProject>) => {
      state.solarEnergyProject = action.payload;
    },
    onMapClick: (state, action: PayloadAction<google.maps.MapMouseEvent>) => {
      if (!action.payload.latLng) {
        return;
      }
      const solarEnergyProjectWithNewVertex = addVertex(action.payload.latLng, state.solarEnergyProject);
      if (solarEnergyProjectWithNewVertex) {
        state.solarEnergyProject = solarEnergyProjectWithNewVertex;
      }
    },
    onDragStart: (state) => {
      state.hidePanels = true;
    },
    onDragEnd: (state) => {
      state.solarEnergyProject = recalculatePanels(state.map, state.loadingState, state.solarEnergyProject);
      state.hidePanels = false;
    },
    onBoundsChanged: (state) => {
      if (!state.hidePanels) {
        state.solarEnergyProject = recalculatePanels(state.map, state.loadingState, state.solarEnergyProject);
      }
    },
    setParentWidth: (state, action: PayloadAction<number>) => {
      state.parentWidth = action.payload;
    },
    setParentHeight: (state, action: PayloadAction<number>) => {
      state.parentHeight = action.payload;
    },
  },
});

const { reducer: useSolarMapVisualisationReducer } = UseSolarMapVisualisationSlice;
const actions = UseSolarMapVisualisationSlice.actions;
export { useSolarMapVisualisationReducer, actions };

export const isLoadingPassedState = (
  currentState: SolarVisualisationLoadingState,
  loadingState: SolarVisualisationLoadingState,
) =>
  SOLAR_VISUALISATION_LOADING_STATES.indexOf(currentState) >= SOLAR_VISUALISATION_LOADING_STATES.indexOf(loadingState);

export const getDispatchSolarEnergyProject = (
  dispatch: React.Dispatch<ReturnType<typeof actions.setSolarEnergyProject>>,
) =>
  useCallback(
    (solarEnergyProject: SolarEnergyProject) => {
      dispatch(actions.setSolarEnergyProject(solarEnergyProject));
    },
    [dispatch],
  );

export const getDispatchOnLoadData = (dispatch: React.Dispatch<AnyAction>) =>
  useCallback(
    (map: google.maps.Map, meterInPixels: number | null) => {
      dispatch(actions.setMap(map));
      dispatch(actions.setMeterInPixels(meterInPixels));
      dispatch(actions.setMapEditState(SOLAR_MAP_EDIT_STATE.BASE));
      dispatch(actions.setLoadingState(`mapLoaded`));
    },
    [dispatch],
  );
