import { getOverlayForMap } from './accessors';
import { AddressPosition, Edge, Vertex } from '../roofVisualisationTypes';
import { AddressDto, RoofPatchShape, RoofPatchShapeVertex } from '@generatedTypes/data-contracts';
import { DEFAULT_ADDRESS_POSITION } from './constants';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/SolarEnergyProject';
import { PanelsResolverResult } from './panelsResolver/panelsResolver';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';

export const mToMm = (distanceInM: number): number => Number((distanceInM * 1000).toFixed(0));

export const mmToM = (distanceInMm: number, precision = 2): number => Number((distanceInMm / 1000).toFixed(precision));

type Vertices = { [K in keyof Required<RoofPatchShape>]: Required<RoofPatchShapeVertex> };

const MM_IN_METER = 1000;
export const panelSizeToPixels = (panelSize: number, meterInPixels: number | null): number =>
  (panelSize * Number((meterInPixels ?? 0).toFixed(3)) * MM_IN_METER) / MM_IN_METER / MM_IN_METER;

export const calculateMeterInPixels = (map: google.maps.Map | null): number | null => {
  if (map) {
    const overlay = getOverlayForMap(map);
    const projection = overlay.getProjection();
    const centerLatLng = map.getCenter();
    if (projection && centerLatLng) {
      const meterFromCenter = google.maps.geometry.spherical.computeOffset(centerLatLng, 1, 0);
      const centerInPixels = projection.fromLatLngToContainerPixel(centerLatLng);
      const meterFromCenterInPixels = projection.fromLatLngToContainerPixel(meterFromCenter);
      return (centerInPixels?.y ?? 0) - (meterFromCenterInPixels?.y ?? 0);
    }
  }
  return null;
};

export const addressDtoToAddressPosition = (addressDto: AddressDto | null): AddressPosition => ({
  lat: addressDto?.indicatedLatitude ?? addressDto?.latitude ?? DEFAULT_ADDRESS_POSITION.lat,
  lng: addressDto?.indicatedLongitude ?? addressDto?.longitude ?? DEFAULT_ADDRESS_POSITION.lng,
});

export const areRotationsEqual = (rotation1: number, rotation2: number): boolean => {
  const convertedRotation1 = rotation1 || 360;
  const convertedRotation2 = rotation2 || 360;
  return convertedRotation1 === convertedRotation2;
};

export const getCorrectRotationValue = (rotation: number): number => {
  const fullCircleInDegrees = 360;
  if (rotation < 0) {
    return rotation + fullCircleInDegrees;
  }
  if (rotation > fullCircleInDegrees) {
    return rotation - fullCircleInDegrees;
  }
  return rotation;
};

export const getVertices = (vertices: Vertex[]) => {
  return vertices.reduce<Vertices>(
    (accumulator, value, index) => ({
      ...accumulator,
      [`v${index + 1}`]: {
        latitude: value.latLng.lat(),
        longitude: value.latLng.lng(),
      },
    }),
    {} as Vertices,
  );
};

export const getEdgesForForm = (edges: Edge[]) => ({
  topWidth: edges?.at(0)?.distance,
  leftHeight: edges?.at(1)?.distance,
  rightHeight: edges?.at(-1)?.distance,
  bottomWidth: edges.length === 4 ? edges?.at(2)?.distance : undefined,
});

type PatchDataForFormResults = Pick<
  SolarEnergyProjectValues[`roofs`][number][`patches`][number],
  `solarPanelGrid` | `height` | `width` | `numberOfColumns` | `numberOfRows`
>;
const getPatchDataForForm = (panelsResolverResults: PanelsResolverResult): PatchDataForFormResults => ({
  solarPanelGrid:
    panelsResolverResults.panels.map(({ active, insideShape, pointOnCalculationArea }) => ({
      isActive: active,
      isInShape: insideShape,
      col: pointOnCalculationArea?.col ?? 0,
      row: pointOnCalculationArea?.row ?? 0,
    })) ?? [],
  height: panelsResolverResults.height,
  width: panelsResolverResults.width,
  numberOfColumns: panelsResolverResults.columns,
  numberOfRows: panelsResolverResults.rows,
});

type SetResolverPatchDataToFormProps = {
  panelsResolverResults: PanelsResolverResult;
  setValue: UseFormSetValue<SolarEnergyProjectValues>;
  roofIndex: number;
  patchIndex: number;
};
export const setResolverPatchDataToForm = ({
  panelsResolverResults,
  setValue,
  roofIndex,
  patchIndex,
}: SetResolverPatchDataToFormProps) => {
  const formData = getPatchDataForForm(panelsResolverResults);
  (Object.keys(formData) as (keyof PatchDataForFormResults)[]).forEach((key) => {
    setValue(`roofs.${roofIndex}.patches.${patchIndex}.${key}`, formData[key as keyof typeof formData]);
  });
};
