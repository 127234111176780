import { useFormContext } from 'react-hook-form';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import Stack from '@mui/material/Stack';
import { SolarEnergyProjectValues } from '../../SolarEnergyProject';
import { useEffectOnRoofPropertiesChange } from './useEffectOnRoofPropertiesChange';
import { MuiNumberInput } from '@components/controls/react-hook-form-friendly/smart/MuiNumberInput';
import InputAdornment from '@mui/material/InputAdornment';

export interface TileDimensionsInputsProps {
  roofIndex: number;
  defaultValues: [number, number];
}

export const TileDimensionsInputs = ({ roofIndex, defaultValues }: TileDimensionsInputsProps) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: { solarEnergy },
          },
        },
      },
    },
  } = useTranslations();

  const { control } = useFormContext<SolarEnergyProjectValues>();

  useEffectOnRoofPropertiesChange({
    roofIndex,
    path: `tileWidth`,
    defaultValue: defaultValues[0],
  });
  useEffectOnRoofPropertiesChange({
    roofIndex,
    path: `tileHeight`,
    defaultValue: defaultValues[1],
  });

  return (
    <Stack gap={1} direction="row">
      <MuiNumberInput
        control={control}
        label={translate(solarEnergy.width)}
        name={`roofs.${roofIndex}.tileWidth`}
        required
        InputProps={{
          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
        }}
        decimalScale={0}
      />
      <MuiNumberInput
        control={control}
        label={translate(solarEnergy.height)}
        name={`roofs.${roofIndex}.tileHeight`}
        required
        InputProps={{
          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
        }}
        decimalScale={0}
      />
    </Stack>
  );
};
