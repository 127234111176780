import { useCallback, useEffect, useState } from 'react';

const DEFAULT_ANIMATION_DURATION = 20;

export const useFadingMarker = (visible: boolean, position?: unknown) => {
  const [markerOpacity, setMarkerOpacity] = useState(visible ? 1 : 0);

  const fadeInMarker = useCallback(() => {
    setMarkerOpacity((prev) => {
      if (prev < 1) {
        setTimeout(fadeInMarker, DEFAULT_ANIMATION_DURATION);
        return Number((prev + 0.1).toFixed(1));
      }
      return prev;
    });
  }, []);

  const fadeOutMarker = useCallback(() => {
    setMarkerOpacity((prev) => {
      if (prev > 0) {
        setTimeout(fadeOutMarker, DEFAULT_ANIMATION_DURATION);
        return Number((prev - 0.1).toFixed(1));
      }
      return prev;
    });
  }, []);

  useEffect(() => {
    if (visible) {
      setMarkerOpacity(0);
      fadeInMarker();
    } else {
      fadeOutMarker();
    }
  }, [fadeInMarker, fadeOutMarker, visible, position]);

  return markerOpacity;
};
