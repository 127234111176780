import { ProjectDeductionDto, UpdateProjectDeductionRequest } from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useQueryClient } from 'react-query';
import { LEAD, LEADS, OFFER_PRICE_OVERVIEW, PROJECTS } from '@variables/queryKeys';

export type PutProjectDeductionProps = UpdateProjectDeductionRequest & {
  id: ProjectDeductionDto[`id`];
};

const putProjectDeduction = async ({ id, ...rest }: PutProjectDeductionProps) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/project-deductions/${id}`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify({
      ...rest,
    } as UpdateProjectDeductionRequest),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

export const useUpdateProjectDeduction = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: putProjectDeduction,
    onSuccess: () => {
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries([OFFER_PRICE_OVERVIEW]);
    },
  });

  return {
    updateProjectDeduction: mutate,
    updateProjectDeductionAsync: mutateAsync,
    isUpdatingProjectDeduction: isLoading,
    updateProjectDeductionBeValidationErrors: validationErrors,
  };
};
