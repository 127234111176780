import { TeaserContentDto } from '@generatedTypes/data-contracts';
import { useGet } from '@services/hooks/api/useGet';
import { authFetch } from '../utils';

const fetchTeasers = async () => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/contents/teasers`, {
    method: `GET`,
    mode: `cors`,
  });
  const json = await response.json();
  if (response.ok) {
    return json as TeaserContentDto[];
  }
  throw new Error(json.message);
};

export const useGetTeasers = useGet(fetchTeasers);
