import { FC, ReactNode } from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { useTheme } from '@mui/material/styles';

import { ListItemIcon, Typography } from '@mui/material';
export interface ListItemContentProps {
  title: ReactNode;
  subtitle?: ReactNode;
  icon?: ReactNode;
  avatar?: ReactNode;
  interactiveIcon?: boolean;
  isIconTop?: boolean;
}

type ItemIconProps = Pick<ListItemContentProps, `icon` | `interactiveIcon`>;

const ItemIcon = ({ icon, interactiveIcon }: ItemIconProps) => {
  const theme = useTheme();

  if (!icon) return null;

  return (
    <ListItemIcon>
      {typeof icon === `string` ? (
        <MdiIconWrapper
          path={icon}
          iconColor={interactiveIcon ? theme.palette.rexelEnergy.ctaBlue : theme.palette.rexelEnergy.blackBlue}
        />
      ) : (
        icon
      )}
    </ListItemIcon>
  );
};

const ItemAvatar = ({ avatar }: { avatar: React.ReactNode }) =>
  avatar ? <ListItemAvatar>{avatar}</ListItemAvatar> : null;

export const ListItemContent: FC<ListItemContentProps> = ({ title, subtitle, icon, avatar, interactiveIcon }) => (
  <>
    <ItemIcon icon={icon} interactiveIcon={!!interactiveIcon} />
    <ItemAvatar avatar={avatar} />
    <ListItemText
      primary={title}
      secondary={
        <Typography variant="subtitle1" component="div">
          {subtitle}
        </Typography>
      }
      disableTypography
    />
  </>
);
