import { Button, Stack } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';

type EditSubmitRowProps = {
  onClose: () => void;
  onSubmit?: () => void;
  disabled?: boolean;
  disabledSubmit?: boolean;
};

export const EditSubmitButtons: React.FC<EditSubmitRowProps> = ({
  onClose,
  onSubmit,
  disabled = false,
  disabledSubmit = false,
}) => {
  const {
    translate,
    translations: {
      common: { buttons },
    },
  } = useTranslations();
  return (
    <Stack direction="row" justifyContent="space-between" spacing={2} maxWidth={350} flex={1}>
      <Button variant="outlined" onClick={onClose} disabled={disabled} sx={{ flex: 1 }}>
        {translate(buttons.cancel)}
      </Button>
      <Button variant="contained" onClick={onSubmit} disabled={disabled || disabledSubmit} sx={{ flex: 1 }}>
        {translate(buttons.save)}
      </Button>
    </Stack>
  );
};
