import { ReactNode } from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { CheckboxFormControlLabelStyled } from '@components/controls/react-hook-form-friendly/dumb/Checkbox/checkbox.styled';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiCheck } from '@mdi/js';

export type CheckBoxProps = {
  name: string;
  value: string | number;
  label: string | ReactNode;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  isLabelOnRight?: boolean;
};
export const Checkbox: React.FC<CheckBoxProps> = ({
  name,
  value,
  label,
  checked,
  onChange,
  isDisabled = false,
  isLabelOnRight = false,
}) => {
  return (
    <CheckboxFormControlLabelStyled
      control={
        <MuiCheckbox
          checked={checked}
          onChange={onChange}
          value={value}
          name={name}
          checkedIcon={<MdiIconWrapper path={mdiCheck} />}
        />
      }
      label={label}
      disabled={isDisabled}
      labelPlacement={isLabelOnRight ? `end` : `start`}
      isLabelOnRight={isLabelOnRight}
    />
  );
};
