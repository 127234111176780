import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import EmptyTemplateImage from '@assets/svg/EmptyComponentIcons/EmptyTemplate.svg';
import { Empty } from '@components/Empty/Empty';
import { StringTranslationObject } from '@assets/translations/translations';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiChevronRight } from '@mdi/js';

type EmptyTemplateListProps = {
  noTemplatesDescription: StringTranslationObject;
  noTemplatesButton: StringTranslationObject;
};
export const EmptyTemplateList: React.FC<EmptyTemplateListProps> = ({ noTemplatesDescription, noTemplatesButton }) => {
  const { translate } = useTranslations();
  return (
    <Empty
      description={translate(noTemplatesDescription)}
      callToActionButton={
        <Link to="/system-settings/economy" style={{ textDecoration: `none` }}>
          <Typography
            sx={(theme) => ({ color: theme.palette.rexelEnergy.ctaBlue, display: `flex`, alignItems: `center` })}
          >
            {translate(noTemplatesButton)} <MdiIconWrapper path={mdiChevronRight} />
          </Typography>
        </Link>
      }
      icon={EmptyTemplateImage}
    />
  );
};
