import { authFetch } from '@services/api/utils';
import { ProjectProductTemplateDto } from '@generatedTypes/data-contracts';
import { CAR_CHARGING_PROJECT_TEMPLATES } from '@variables/queryKeys';
import { mapAttributeRowToOption } from '@pages/NewLeads/Projects/solarEnergyProject/utils';
import { useSelector } from 'react-redux';
import { selectFeatureEnabled } from '@redux/reducers/slices/user';
import { useCustomQuery } from '@hooks/useCustomQuery';

const fetchCarChargingProjectTemplates = async () => {
  return authFetch(`${process.env.PROTECTED_API_URL}/car-charging-projects/product-templates`, {
    method: `GET`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    })
    .then((json) => json as ProjectProductTemplateDto[])
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

export const useGetCarChargingProjectTemplates = () => {
  const carChargingFeatureEnabled = useSelector(selectFeatureEnabled(`car-charging-project`));
  const { data } = useCustomQuery({
    queryKey: CAR_CHARGING_PROJECT_TEMPLATES,
    queryFn: fetchCarChargingProjectTemplates,
    staleTime: Infinity,
    enabled: carChargingFeatureEnabled,
  });
  return (data ?? []).map(mapAttributeRowToOption);
};
