import { authFetch, getErrorFromFormRequest } from '@services/api/utils';
import {
  CreateProjectAdditionTemplateRequest,
  ProjectAdditionTemplateDto,
  UpdateProjectAdditionTemplateRequest,
} from '@generatedTypes/data-contracts';

export const fetchPartnerAdditionTemplate = async (id: number) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/project-addition-templates/${id}`, {
    method: `GET`,
    mode: `cors`,
  });
  if (response.ok) {
    const json = await response.json();
    return json as ProjectAdditionTemplateDto;
  }
  throw new Error(response.statusText);
};

export const postProjectAdditionTemplate = async (additionTemplate: CreateProjectAdditionTemplateRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/project-addition-templates`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(additionTemplate),
  });
  if (response.ok) {
    return (await response.json()) as number;
  }
  throw await getErrorFromFormRequest(response);
};

export type PutPartnerAdditionTemplate = UpdateProjectAdditionTemplateRequest & {
  id: number;
};
export const putProjectAdditionTemplate = async (additionTemplate: PutPartnerAdditionTemplate) => {
  const response = await authFetch(
    `${process.env.PROTECTED_API_URL}/project-addition-templates/${additionTemplate.id}`,
    {
      method: `PUT`,
      mode: `cors`,
      body: JSON.stringify(additionTemplate),
    },
  );
  if (response.ok) {
    return additionTemplate.id as number;
  }
  throw await getErrorFromFormRequest(response);
};

export const deleteProjectAdditionTemplate = async (id: number) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/project-addition-templates/${id}`, {
    method: `DELETE`,
    mode: `cors`,
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};
