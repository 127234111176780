import { FC, ReactNode } from 'react';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiClose } from '@mdi/js';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import { useTranslations } from '@services/hooks/translations/useTranslations';

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  cancelText?: string;
  confirmText?: string;
  onConfirm?: () => void;
  confirmationDataTestId?: string;
}

export const ConfirmationPopup: FC<PopupProps> = ({
  isOpen,
  onClose,
  title,
  children,
  cancelText,
  confirmText,
  onConfirm,
  confirmationDataTestId,
}) => {
  const {
    translate,
    translations: {
      common: { buttons },
    },
  } = useTranslations();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogActions disableSpacing sx={{ p: 0 }}>
        <IconButton onClick={onClose}>
          <MdiIconWrapper path={mdiClose} />
        </IconButton>
      </DialogActions>
      <DialogTitle textAlign="center" variant="h3">
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="outlined" fullWidth onClick={onClose}>
          {cancelText || translate(buttons.cancel)}
        </Button>
        <Button fullWidth onClick={onConfirm} data-testid={confirmationDataTestId}>
          {confirmText || translate(buttons.confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
