import { Months } from '@assets/translations/translations';
import { Translation } from '@services/hooks/translations/useTranslations';

const THREE_LETTERS_LONG = 3;

export const createMonthRange = (
  monthsTranslation: Months,
  translate: (translation: Translation) => string,
  monthStartIndex: keyof Months,
  monthEndIndex: keyof Months,
) =>
  `${translate(monthsTranslation[monthStartIndex]).substring(0, THREE_LETTERS_LONG)} - ${translate(
    monthsTranslation[monthEndIndex],
  ).substring(0, THREE_LETTERS_LONG)}`;
