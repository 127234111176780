import { BatchCreateProjectDeductionRequest, CreateProjectDeductionRequest } from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { useQueryClient } from 'react-query';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { LEAD, LEADS, OFFER_PRICE_OVERVIEW, PROJECTS } from '@variables/queryKeys';

const postProjectDeduction = async (product: CreateProjectDeductionRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/project-deductions`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(product),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

export const useCreateProjectDeduction = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: postProjectDeduction,
    onSuccess: () => {
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries([OFFER_PRICE_OVERVIEW]);
    },
  });

  return {
    addProjectDeduction: mutate,
    addProjectDeductionAsync: mutateAsync,
    isAddingProjectDeduction: isLoading,
    addProjectDeductionBeValidationErrors: validationErrors,
  };
};

const postProjectDeductionBatch = async (product: BatchCreateProjectDeductionRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/project-deductions/batch-create`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(product),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

export const useCreateProjectDeductionBatch = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: postProjectDeductionBatch,
    onSuccess: () => {
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries([OFFER_PRICE_OVERVIEW]);
    },
  });

  return {
    addProjectDeductionBatch: mutate,
    addProjectDeductionBatchAsync: mutateAsync,
    isAddingProjectDeductionBatch: isLoading,
    addProjectDeductionBatchBeValidationErrors: validationErrors,
  };
};
