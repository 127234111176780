import { selectUserType } from '@redux/reducers/slices/user';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AnalyticEvents } from './analyticEvents';

type PushObject = { event: AnalyticEvents; [key: string]: unknown };

export const useAnalytics = () => {
  const userType = useSelector(selectUserType);

  const pushEvent = useCallback(
    (value: PushObject) => {
      dataLayer.push({
        ...value,
        userType,
      });
    },
    [userType],
  );

  return pushEvent;
};
