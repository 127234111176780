import * as S from './LeftNavLink.styled';
import { SingleRow } from './SingleRow';

export interface SettingsLeftNavLinkProps {
  title: string;
  selected?: boolean;
  href: string;
}

export const SettingsLeftNavLink = ({ href, title, selected }: SettingsLeftNavLinkProps) => {
  return (
    <S.StyledLink to={href} selected={selected}>
      <SingleRow title={title} selected={selected} />
    </S.StyledLink>
  );
};
