import List from '@mui/material/List';
import { Theme } from '@mui/material/styles';

export type TemplatesListContentWrapperProps = {
  children?: React.ReactNode;
};
const listSxOverrides = (theme: Theme) => ({
  marginInline: -3,
  '& .MuiListItemSecondaryAction-root': {
    right: theme.spacing(4),
  },
});
export const TemplatesListContentWrapper: React.FC<TemplatesListContentWrapperProps> = ({ children }) => (
  <List sx={listSxOverrides}>{children}</List>
);
