import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

type SolarPanelStyledProps = {
  selected: boolean;
  disabled: boolean;
};
export const SolarPanelStyled = styled(Box)<SolarPanelStyledProps>(({ selected, disabled, theme }) => ({
  display: `flex`,
  position: `absolute`,
  transformOrigin: `top left`,
  zIndex: 11,
  cursor: `pointer`,
  border: `1px solid ${theme.palette.background.paper}`,

  '& img': {
    objectFit: `cover`,
    width: `100%`,
  },

  ...(!selected && {
    transition: `border 0.2s ease-in-out`,
    border: `1px solid ${theme.palette.rexelEnergy.ctaBlue}`,
  }),

  ...(disabled && {
    border: `1px solid rgba(0, 37, 67, 0.3)`,
    pointerEvents: `none`,
  }),
}));
