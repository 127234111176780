import Button from '@mui/material/Button';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ActionWithConfirmation } from '@components/ActionWithConfirmation';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiAccountMultipleOutline, mdiClose } from '@mdi/js';
import Typography from '@mui/material/Typography';
import { selectIsDealerUser, selectIsOperationUser, selectIsPartnerUser } from '@redux/reducers/slices/user';
import { useDeleteLead } from '@services/api/leads/lead-delete';
import { useGetLead } from '@services/api/leads/lead-info';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { theme } from 'src/styles/theme/theme';
import { Skeleton, Stack } from '@mui/material';
import { CommaSeparatedTitle } from '../../components/CommaSeparatedTitle/CommaSeparatedTitle';

interface LeadAssignProps {
  onAssignButtonClick: () => void;
}

export const LeadAssignButtons: FC<LeadAssignProps> = ({ onAssignButtonClick }) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          deletePopup,
          assign: { assign },
        },
      },
    },
  } = useTranslations();
  const isPartnerUser = useSelector(selectIsPartnerUser);
  const isOperationUser = useSelector(selectIsOperationUser);
  const isDealerUser = useSelector(selectIsDealerUser);

  const { deleteLead } = useDeleteLead();
  const { lead, isLoadingLead } = useGetLead();

  const dealerName = lead?.assignedDealer?.name;
  const partnerName = lead?.assignedPartner?.name;

  const isOperationUserWithFullData = isOperationUser && dealerName && partnerName;
  const isOperationWithDealerName = isOperationUser && dealerName;
  const isDealerUserWithPartnerName = isDealerUser && partnerName;
  const isOperationUserWithAssignedPartnerAccountWithPartnerName =
    isOperationUser && lead?.assignedPartner && dealerName;

  const buttonText = isOperationUserWithFullData ? (
    <CommaSeparatedTitle firstPart={partnerName} secondPart={dealerName} />
  ) : isOperationUserWithAssignedPartnerAccountWithPartnerName && lead?.assignedPartner ? (
    <CommaSeparatedTitle firstPart={lead?.assignedPartner.name || ``} secondPart={dealerName} />
  ) : isOperationWithDealerName ? (
    dealerName
  ) : isDealerUserWithPartnerName ? (
    <CommaSeparatedTitle firstPart={partnerName} secondPart={lead.assignedPartner?.accountId ?? ``} />
  ) : (
    lead?.assignedPartner?.accountId || translate(assign)
  );

  if (isLoadingLead)
    return (
      <>
        <Skeleton variant="rectangular" height={32} width={100} sx={{ marginInlineStart: 0.8 }} />
        <Skeleton variant="rectangular" height={32} width={100} />
      </>
    );

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ [theme.breakpoints.down(`md`)]: { gap: 0 } }}>
      {!isPartnerUser && (
        <Button
          size="small"
          variant="text"
          onClick={onAssignButtonClick}
          startIcon={<MdiIconWrapper path={mdiAccountMultipleOutline} />}
          sx={{ textAlign: `left` }}
        >
          {buttonText}
        </Button>
      )}
      {lead && lead.isDeletable && (
        <ActionWithConfirmation
          confirmationPopupTitle={translate(deletePopup.header)}
          confirmationPopupContent={<Typography textAlign="center">{translate(deletePopup.content)}</Typography>}
          onConfirm={deleteLead}
          buttonIconPath={mdiClose}
          confirmText={translate(deletePopup.header)}
        >
          {translate(deletePopup.header)}
        </ActionWithConfirmation>
      )}
    </Stack>
  );
};
