import { selectMapEditState } from '@redux/reducers/slices/leadsPage';
import { useSelector } from 'react-redux';
import { useGetLead } from '@services/api/leads/lead-info';
import ArrowedMarker from '@assets/svg/maps/ArrowedMarker.svg';
import { Box } from '@mui/material';
import { useFadingMarker } from '@components/maps/GoogleMaps/Markers/useFadingMarker';

export const CenterPointMarker = () => {
  const mapEditState = useSelector(selectMapEditState);
  const { lead } = useGetLead();
  const opacity = useFadingMarker(mapEditState !== `VIEW`);

  if (!lead || !opacity) return null;

  return (
    <Box
      id="address-point-marker"
      sx={{ zIndex: 1, position: `absolute`, left: `calc(50% - 14px)`, top: `calc(50% - 40px)`, opacity }}
    >
      <img src={ArrowedMarker} alt={``} />
    </Box>
  );
};
