import { Orientation } from '@generatedTypes/data-contracts';

import { SolarEnergyProductionDto } from '@generatedTypes/data-contracts';

export type AddressPosition = {
  lat: number;
  lng: number;
};

export type Vertex = {
  id: string;
  latLng: google.maps.LatLng;
  marker?: google.maps.Marker;
  selected: boolean;
};

export type Edge = {
  id: string;
  start: google.maps.LatLng;
  startId: string;
  end: google.maps.LatLng;
  endId: string;
  distance: number;
  center: google.maps.LatLng;
  distanceLabelPosition: google.maps.LatLng;
  rotation: number;
  reversed: boolean;
  selected: boolean;
};

export type Patch = {
  id: string;
  groupId?: string;
  vertices: Vertex[];
  edges: Edge[];
  polygon?: google.maps.Polygon | null;
  panels: Panel[];
  selected: boolean;
  width: number;
  height: number;
  columns: number;
  rows: number;
  panelOrientation: Orientation | null;
  solarEnergyProduction: SolarEnergyProductionDto;
};

export type Roof = {
  id: string;
  patches: Patch[];
  selected: boolean;
  roofMaterialId?: number;
  mountingMaterialManufacturerId?: number;
  solarPanelManufacturerId?: number;
};

export type Panel = {
  id: number;
  latLng?: google.maps.LatLng;
  pointOnParent?: google.maps.Point | null;
  pointOnCalculationArea?: { col: number; row: number } | null;
  active: boolean;
  insideShape: boolean;
  reversed?: boolean;
  rotation?: number;
};

export type SolarEnergyProject = {
  roofs: Roof[];
  panelsResolverVersion: number;
};

export type MapSize = {
  width: number | string;
  height: number | string;
};

export function isPathsVertexArray(
  paths:
    | google.maps.MVCArray<google.maps.LatLng>
    | google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>>
    | google.maps.LatLng[]
    | google.maps.LatLng[][]
    | google.maps.LatLngLiteral[]
    | google.maps.LatLngLiteral[][]
    | undefined,
): paths is google.maps.LatLng[] {
  return (
    (paths &&
      Array.isArray(paths) &&
      paths.length &&
      typeof (paths[0] as google.maps.LatLng)?.lat === `function` &&
      typeof (paths[0] as google.maps.LatLng)?.lng === `function`) ||
    false
  );
}
