import { FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

type CheckboxFormControlLabelStyledProps = {
  isLabelOnRight: boolean;
};
export const CheckboxFormControlLabelStyled = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== `isLabelOnRight`,
})<CheckboxFormControlLabelStyledProps>(({ isLabelOnRight, theme }) => ({
  margin: 0,
  padding: 0,
  width: `100%`,
  justifyContent: isLabelOnRight ? `flex-start` : `space-between`,
  gap: theme.spacing(2),
  '& .MuiFormControlLabel-label': {
    width: `100%`,
    display: `flex`,
    justifyContent: `space-between`,
  },
}));
