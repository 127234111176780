import { authFetch, getErrorFromFormRequest } from '@services/api/utils';
import {
  CreateDeductionTemplateRequest,
  DeductionTemplateDto,
  UpdateDeductionTemplateRequest,
} from '@generatedTypes/data-contracts';

export const fetchDeductionTemplate = async (id: number) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/deduction-templates/${id}`, {
    method: `GET`,
    mode: `cors`,
  });
  if (response.ok) {
    const json = await response.json();
    return json as DeductionTemplateDto;
  }
  throw new Error(response.statusText);
};

export const postDeductionTemplate = async (deductionTemplate: CreateDeductionTemplateRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/deduction-templates`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(deductionTemplate),
  });
  if (response.ok) {
    return (await response.json()) as number;
  }
  throw await getErrorFromFormRequest(response);
};

export type PutDeductionTemplate = UpdateDeductionTemplateRequest & {
  id: number;
};
export const putDeductionTemplate = async (deductionTemplate: PutDeductionTemplate) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/deduction-templates/${deductionTemplate.id}`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify(deductionTemplate),
  });
  if (response.ok) {
    return deductionTemplate.id;
  }
  throw await getErrorFromFormRequest(response);
};

export const deleteDeductionTemplate = async (id: number) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/deduction-templates/${id}`, {
    method: `DELETE`,
    mode: `cors`,
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};
