import { useCreatePartner } from '@services/api/partners';
import { PartnerDataValues, PartnerForm } from './PartnerForm';
import { useCallback } from 'react';
import { parseZipCodeInputToPlainValue } from '@utils/parsers';

export interface NewPartnerFormProps {
  onClose: () => void;
}

export const NewPartnerForm = ({ onClose }: NewPartnerFormProps) => {
  const createPartner = useCreatePartner();

  const handleSubmit = useCallback(
    (values: PartnerDataValues) => {
      createPartner.mutate({ ...values, zipCode: parseZipCodeInputToPlainValue(values.zipCode) ?? `` });
      onClose();
    },
    [createPartner, onClose],
  );

  return (
    <PartnerForm
      onSubmit={handleSubmit}
      disableForm={createPartner.isLoading}
      beValidationResults={createPartner.validationErrors}
      goBackHandler={onClose}
    />
  );
};
