import { useGetPartners } from '@services/api/partners';
import { useEffect, useMemo, useState } from 'react';
import { SelectType, buildDropdownItem, filterValidDealerPartners } from '../utils';
import { useSelector } from 'react-redux';
import { selectIsDealerUser } from '@redux/reducers/slices/user';
import { useGetDealers } from '@services/api/dealers';
import { useGetLead } from '@services/api/leads/lead-info';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { AssignEntitiesZodObjectSchema } from './AssignEntitiesPanel';
import { Divider } from '@components/controls/react-hook-form-friendly/smart/MuiSelect';
import { Control, UseFormSetValue, useWatch, useFormState } from 'react-hook-form';

const NONE_OPTION_PARTNER: SelectType = { label: `Välj kund`, value: Infinity };
export const NONE_OPTION_DEALER: SelectType = { label: `Välj filial`, value: Infinity };
export const clientNumber = `clientNumber`;

const divider: Divider = `divider`;

export interface UseGetAssignEntitiesPanelProps {
  control: Control<AssignEntitiesZodObjectSchema>;
  setValue: UseFormSetValue<AssignEntitiesZodObjectSchema>;
  defaultPartnerAccount?: string | null;
}

export const useAvaliableOptions = ({ control, setValue, defaultPartnerAccount }: UseGetAssignEntitiesPanelProps) => {
  const {
    translate,
    translations: {
      leads: { details },
    },
  } = useTranslations();
  const { touchedFields } = useFormState({ control });

  const isDealerUser = useSelector(selectIsDealerUser);
  const { partners } = useGetPartners();
  const { dealers, isLoadingDealers } = useGetDealers();
  const { lead } = useGetLead();
  const dealersIds = useMemo(() => dealers?.map(({ id }) => id) ?? [], [dealers]);

  const clientNumberOption = useMemo(
    () => ({ label: translate(details.assign.useClientNumber), value: clientNumber }),
    [details.assign.useClientNumber, translate],
  );
  const [availablePartners, setAvailablePartners] = useState(() => {
    let filteredItems = partners.filter(filterValidDealerPartners);
    if (isDealerUser) {
      filteredItems = filteredItems.filter(({ dealerId }) => dealerId && dealersIds.includes(dealerId));
    }
    if (lead?.assignedDealer?.id) {
      filteredItems = filteredItems.filter(({ dealerId }) => dealerId === lead?.assignedDealer?.id);
    }
    const filteredPartners = filteredItems.map(buildDropdownItem);
    return [clientNumberOption, divider, NONE_OPTION_DEALER, ...filteredPartners];
  });

  const availableDealers = useMemo(() => {
    let filteredItems = dealers.filter(filterValidDealerPartners);
    if (isDealerUser) {
      filteredItems = filteredItems.filter((item) => dealersIds.includes(item.id));
    }
    const isSelectedPartnerWithDealer = partners.find(
      (partner) => partner.id === lead?.assignedPartner?.id && partner.id,
    );
    if (isSelectedPartnerWithDealer) {
      return filteredItems.map(buildDropdownItem);
    }
    const filteredDealers = filteredItems.map(buildDropdownItem);
    return [NONE_OPTION_DEALER, divider, ...filteredDealers];
  }, [dealers, dealersIds, isDealerUser, lead?.assignedPartner?.id, partners]);

  const [partnerIdSelected, setPartnerIdSelected] = useState(false);
  const selectedPartnerId = useWatch({ control, name: `partnerId` });
  const selectedDealerId = useWatch({ control, name: `dealerId` });

  useEffect(() => {
    if (String(selectedPartnerId) !== clientNumber) {
      setPartnerIdSelected(true);
      const accountIdForSelectedPartner = partners.find((partner) => partner.id === selectedPartnerId)?.accountId;
      setValue(`partnerAccount`, accountIdForSelectedPartner ?? ``);
    } else {
      setPartnerIdSelected(false);
      setValue(`partnerAccount`, defaultPartnerAccount ?? ``);
    }
  }, [dealers, defaultPartnerAccount, partners, selectedPartnerId, setValue]);

  useEffect(() => {
    const beginingOfPartnerOptions = [clientNumberOption, divider, NONE_OPTION_PARTNER, divider];
    if (selectedDealerId === NONE_OPTION_DEALER.value) {
      setAvailablePartners([...beginingOfPartnerOptions, ...partners.map(buildDropdownItem)]);
    } else {
      const partnersAvailableForDealer = partners.filter((partner) => partner.dealerId === selectedDealerId);
      touchedFields.dealerId && setValue(`partnerId`, Infinity);
      setAvailablePartners([...beginingOfPartnerOptions, ...partnersAvailableForDealer.map(buildDropdownItem)]);
    }
  }, [clientNumberOption, partners, selectedDealerId, setValue, touchedFields.dealerId]);

  return { availableDealers, partnerIdSelected, availablePartners, isLoadingDealers };
};
