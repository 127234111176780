import { FC } from 'react';
import { OfferRoofDto, OfferRoofPatchDto } from '@generatedTypes/data-contracts';
import { kiloWattHourFormatter } from '@pages/NewLeads/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { TO_KILO_SI } from '@utils/math';
import { roundNumber } from '@utils/round-number';
import { PositionAndNumberOfPanels } from './PositionAndNumberOfPanels';
import './roof.css';
import { ListItem } from '@components/ListMui/ListItem';
import { mdiLightningBoltOutline } from '@mdi/js';
import { Stack } from '@mui/material';
import { WebTypography } from '@components/Typography/WebTypography';

function isPatchWithFullData(
  patch: OfferRoofPatchDto,
): patch is OfferRoofPatchDto & { angle: number; direction: number; solarPanelGrid: number[] } {
  return (
    typeof patch.angle === `number` &&
    typeof patch.direction === `number` &&
    Array.isArray(patch.solarPanelGrid) &&
    patch.solarPanelGrid.length > 0
  );
}

export interface RoofProps {
  roof: OfferRoofDto;
}
export const Roof: FC<RoofProps> = ({ roof }) => {
  const {
    translate,
    translations: {
      common: { year },
      leads: {
        offer: {
          solarEnergyOffer: { peakPower2 },
        },
      },
    },
  } = useTranslations();

  return (
    <Stack spacing={1}>
      <Stack>
        <WebTypography variant="h4">{roof.name}</WebTypography>
        <WebTypography>{`${roof.solarPanelManufacturerName}, ${roof.roofMaterialName}`}</WebTypography>
      </Stack>
      <Stack spacing={1} component="ul" sx={{ padding: 0, margin: 0 }}>
        <ListItem
          title={`${kiloWattHourFormatter(
            roof.solarEnergyProduction?.yearlyProductionInkWh ?? 0,
          )} kWh/${translate(year)}`}
          subtitle={`${translate(peakPower2)} ${roundNumber(
            (roof.solarEnergyProduction?.peakPower ?? 0) / TO_KILO_SI,
            1,
          )} kWp`}
          icon={mdiLightningBoltOutline}
        />
        {roof.patches?.map(
          (patch) =>
            isPatchWithFullData(patch) && (
              <PositionAndNumberOfPanels
                key={patch.id}
                direction={patch.direction}
                slope={patch.angle}
                numberOfPanels={patch.solarPanelGrid.reduce(
                  (accumulator, { isActive }) => accumulator + Number(isActive),
                  0,
                )}
              />
            ),
        )}
      </Stack>
    </Stack>
  );
};
