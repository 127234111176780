import { CreateLeadRequest } from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { LEADS } from '@variables/queryKeys';

const postLead = async (lead: CreateLeadRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/leads`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(lead),
  });
  if (!response.ok) {
    const error = await getErrorFromFormRequest(response);
    throw error;
  }

  return (await response.json()) as number;
};

export const useCreateLead = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: postLead,
    onSuccess: (id) => {
      queryClient.invalidateQueries(LEADS);
      navigate(`/leads/${id}`);
    },
  });

  return {
    createLead: mutateAsync,
    isCreatingLeadLoading: isLoading,
    validationErrors,
  };
};
