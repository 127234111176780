import { FC } from 'react';
import { LeadDto } from '@generatedTypes/data-contracts';
import { NullableToUndefindableObject } from '@utils/types/NullableToUndefindable';
import { useGetLead } from '@services/api/leads/lead-info';
import { useUpdateLead } from '@services/api/leads/lead-edit';
import { LeadForm } from './LeadForm/LeadForm';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';

export interface UpdateLeadFormProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LeadEditForm: FC<UpdateLeadFormProps> = ({ isOpen, onClose }) => {
  const pushDataLayer = useAnalytics();
  const { lead } = useGetLead();

  const { updateLeadAsync, isLoading, validationErrors } = useUpdateLead();

  if (!lead) return null;

  const { address, ...restData } = lead as NullableToUndefindableObject<LeadDto> & {
    address: NullableToUndefindableObject<LeadDto[`address`]>;
  };

  return (
    <LeadForm
      initialValues={{ ...address, ...restData }}
      onSubmit={(data) => {
        pushDataLayer({ event: AnalyticEvents.customerInfo, customerInfo: data.customerType });
        return updateLeadAsync({ ...data, id: lead.id }).then(onClose);
      }}
      isSubmitting={isLoading}
      errors={validationErrors}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};
