import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { authFetch } from '@services/api/utils';
import { LEAD, LEADS, OFFER_PRICE_OVERVIEW, PROJECTS } from '@variables/queryKeys';
import { useQueryClient } from 'react-query';

export const deleteProjectDeduction = async (projectDeductionId: number) =>
  authFetch(`${process.env.PROTECTED_API_URL}/project-deductions/${projectDeductionId}`, {
    method: `DELETE`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.clone().ok) {
        throw await res.clone().json();
      }
    })
    .catch((error) => {
      throw new Error(error);
    });

export const useDeleteProjectDeduction = (options?: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading } = useCustomMutation({
    mutationFn: deleteProjectDeduction,
    onSuccess: () => {
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries([OFFER_PRICE_OVERVIEW]);
      options?.onSuccess();
    },
  });

  return {
    deleteProjectDeduction: mutate,
    deleteProjectDeductionAsync: mutateAsync,
    isDeletingProjectDeduction: isLoading,
  };
};
