import { useEffect, useMemo, useState } from 'react';
import './validated-wrapper.css';
import { Numeric, NumericProps } from './numeric';
import { InputErrorElement } from './input-error-element';

export enum LABEL_POSITION {
  TOP = `TOP`,
  LEFT = `LEFT`,
  STRETCH_FULL_LINE = `STRETCH_FULL_LINE`,
}

type NumericWrapperProps = NumericProps & {
  label: string;
  isRequired?: boolean;
  validateLive?: boolean;
  errorMsg?: string;
  placeholder?: string;
  labelPosition?: LABEL_POSITION;
  noBackground?: boolean;
  suffix?: string;
  isDisabled?: boolean;
  smallVersion?: boolean;
};

export function NumericWrapper(props: NumericWrapperProps) {
  const [errorMsg, setErrorMsg] = useState(props.errorMsg);

  useEffect(() => {
    setErrorMsg(props.errorMsg);
  }, [props.errorMsg]);

  const labelPositionClass = useMemo(() => {
    switch (props.labelPosition) {
      case LABEL_POSITION.TOP:
        return `column`;
      case LABEL_POSITION.STRETCH_FULL_LINE:
        return `row align-center space-between`;
      case LABEL_POSITION.LEFT:
      default:
        return `row align-center`;
    }
  }, [props.labelPosition]);

  return (
    <div className="validated-wrapper fw numeric-container">
      <div
        className={`${labelPositionClass} fw${props.errorMsg ? ` error` : ``}${props.isRequired ? ` required` : ``}`}
      >
        <label htmlFor={props.name}>{props.label}</label>
        <Numeric
          name={props.name}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          value={props.value}
          max={props.max}
          min={props.min}
          precision={props.precision}
          step={props.step}
          classesForContainer={`${props.noBackground ? `no-background` : ``}${props.smallVersion ? ` small` : ``}`}
          suffix={props.suffix}
        />
      </div>
      <InputErrorElement errorMsg={errorMsg} />
    </div>
  );
}
