import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const BreadcrumbButton = styled(Button)(({ theme }) => ({
  backgroundColor: `transparent`,
  padding: 0,
  color: theme.palette.primary.main,
  lineHeight: 1.5,
  minWidth: `fit-content`,
  minHeight: `fit-content`,
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightRegular,
  '&:hover, &.Mui-focusVisible': {
    textDecoration: `underline`,
    backgroundColor: `transparent`,
  },
  '&.Mui-disabled': {
    backgroundColor: `transparent`,
    color: theme.palette.text.disabled,
  },
}));
