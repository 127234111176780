import { CopyLinkButton } from '@components/CopyLinkButton';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { FC, ReactNode } from 'react';
import { theme } from 'src/styles/theme/theme';
import { ActionInformationModal } from '@components/ActionInformationModal/ActionInformationModal';
import { useToggle } from '@hooks/useToggle';
import { BasketItemSelectionList } from '@components/OfferManagementDropdown/BasketItemSelectionList';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { BasketItemsZodSchema, zodBasketItemsObject } from './zodSettings';
import { getFullBasketUrl, useGetCartUrlForExcludedProducts } from './useGetCartUrlForExcludedProducts';
import { DevTool } from '@hookform/devtools';

const openCartPage = (cartPageUrl: string) => {
  window.open(cartPageUrl, `_blank`, ``);
};

export interface AcceptedOfferWrapperProps {
  children: ReactNode;
  offerHash?: string;
  toggleDropdown: () => void;
}

const defaultValues: BasketItemsZodSchema = {
  excludedProductsIds: [],
};

export const AcceptedOfferWrapper: FC<AcceptedOfferWrapperProps> = ({ children, offerHash, toggleDropdown }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { cart },
      },
    },
  } = useTranslations();

  const getCartUrl = useGetCartUrlForExcludedProducts();

  const formFunctions = useForm({
    resolver: zodResolver(zodBasketItemsObject),
    defaultValues,
  });

  const onClose = () => {
    toggleModal();
    toggleDropdown();
  };

  const onSubmit = async (values: BasketItemsZodSchema) => {
    if (offerHash) {
      const cartUrl = await getCartUrl(offerHash, values.excludedProductsIds);
      onClose();
      openCartPage(cartUrl.fullUrl);
    }
  };

  const [isModalOpen, toggleModal] = useToggle();

  return (
    <FormProvider {...formFunctions}>
      <Stack gap={1} padding={theme.spacing(3, 3, 2, 3)}>
        {children}
        <Typography>{translate(cart.createdMaterialBasket)}</Typography>
        <Button disabled={!offerHash} onClick={toggleModal} sx={{ marginBlockEnd: 1 }}>
          {translate(cart.addToCart)}
        </Button>
        <CopyLinkButton url={offerHash ? getFullBasketUrl(offerHash) : ``} disabled={!offerHash} />
        <ActionInformationModal
          header={translate(cart.cartSelectionHeader)}
          onConfirm={formFunctions.handleSubmit(onSubmit)}
          isOpen={isModalOpen}
          onClose={onClose}
        >
          <BasketItemSelectionList />
        </ActionInformationModal>
      </Stack>
      <DevTool control={formFunctions.control} />
    </FormProvider>
  );
};
