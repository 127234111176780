import {
  ComplexFormFieldType,
  ComponentsFieldType,
  DropdownOption,
  FieldValue,
  SimpleFormFieldType,
  UseFormSchema,
} from './useFormTypes';
import { DropdownIndicator } from '@components/dropdownIndicator/dropdownIndicator';

export function fieldIsDropdownOption(
  field: FieldValue<SimpleFormFieldType | ComplexFormFieldType>,
): field is DropdownOption {
  return field !== null && typeof field === `object` && Object.hasOwn(field, `value`) && Object.hasOwn(field, `label`);
}

export const getValueFromFormValues = (valueData: FieldValue<SimpleFormFieldType | ComplexFormFieldType>) =>
  fieldIsDropdownOption(valueData) ? valueData?.value : valueData;

export const getComponentsForFieldType = <Schema extends UseFormSchema>(
  formType: Schema[string][`type`],
): ComponentsFieldType<Schema, typeof formType> =>
  (formType === ComplexFormFieldType.dropdown ? { DropdownIndicator } : undefined) as ComponentsFieldType<
    Schema,
    typeof formType
  >;

export const getMinnedMaxedValue = (newVal: string | number, min?: number, max?: number) => {
  if (String(newVal).endsWith(`,`) || String(newVal).endsWith(`.`)) {
    return newVal;
  }
  const newValAsNumber = Number(String(newVal).replace(`,`, `.`));
  if (isNaN(newValAsNumber)) {
    return newVal;
  }
  const minnedValue = typeof min === `undefined` ? newValAsNumber : Math.max(newValAsNumber, min);
  return typeof max === `undefined` ? minnedValue : Math.min(minnedValue, max);
};
