import { useToggle } from '@hooks/useToggle';
import { Drawer } from '@mui/material';
import Stack from '@mui/material/Stack';
import { OfferIntroCard } from '@pages/NewLeads/offerIntroCard';
import { ProjectCardForOffer } from '@pages/NewLeads/Projects/solarEnergyProject/project-card-for-offer';
import { useGetOffersBids } from '@services/api/offers/offers';
import { TermsAndContactCard } from '../TermsAndContactCard/TermsAndContactCard';
import { useDispatch } from 'react-redux';
import { setCurrentOfferBidId } from '@redux/actions/lead';
import { OfferProductsForm } from './OfferProductsForm';
import { OfferTabLoader } from './OfferTabLoader';
import { CenteredCircularProgress } from '@components/Loading/CenteredCircularProgress';
import { AttachmentsCard } from '@pages/NewLeads/OfferTab/uploadAttachment/AttachmentsCard';
import { UploadOfferAttachment } from '@pages/NewLeads/OfferTab/uploadAttachment/UploadOfferAttachment';
import { setSelectedOfferAttachment } from '@redux/reducers/slices/offerTab';
import { OfferAttachmentRowDto } from '@generatedTypes/data-contracts';
import { PayoffCard } from '@pages/NewLeads/OfferTab/PayoffCard';

export const OfferTab = () => {
  const dispatch = useDispatch();
  const { offersBids, isLoadingOffersBids } = useGetOffersBids();
  const [isProductsFormOpen, toggleIsProductsFormOpen] = useToggle();
  const [isOfferAttachmentFormOpen, toggleIsOfferAttachmentFormOpen] = useToggle();

  const closeOfferAttachmentForm = () => {
    dispatch(setSelectedOfferAttachment(undefined));
    if (isOfferAttachmentFormOpen) {
      toggleIsOfferAttachmentFormOpen();
    }
  };

  const openAttachmentForm = (attachment?: OfferAttachmentRowDto) => {
    dispatch(setSelectedOfferAttachment(attachment));
    toggleIsOfferAttachmentFormOpen();
  };

  if (isLoadingOffersBids) return <OfferTabLoader />;

  return (
    <Stack spacing={2}>
      <TermsAndContactCard />
      <OfferIntroCard />
      <AttachmentsCard openAttachmentForm={openAttachmentForm} />
      <PayoffCard />
      {isLoadingOffersBids ? (
        <CenteredCircularProgress />
      ) : (
        offersBids?.map((bidAKAproject) => (
          <ProjectCardForOffer
            key={bidAKAproject.id}
            project={bidAKAproject}
            onEditProjectProductsClick={() => {
              dispatch(setCurrentOfferBidId(bidAKAproject.id));
              toggleIsProductsFormOpen();
            }}
          />
        ))
      )}
      <Drawer open={isProductsFormOpen} onClose={toggleIsProductsFormOpen} anchor="right">
        <OfferProductsForm onCancel={toggleIsProductsFormOpen} />
      </Drawer>
      <Drawer open={isOfferAttachmentFormOpen} onClose={closeOfferAttachmentForm} anchor="right">
        <UploadOfferAttachment goBackHandler={closeOfferAttachmentForm} />
      </Drawer>
    </Stack>
  );
};
