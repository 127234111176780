import { styled } from '@mui/material/styles';
import Icon from '@mdi/react';

export interface MdiIconWrapperStyled {
  hoverColor?: string;
  iconColor?: string | null;
}

export const MdiIconWrapperStyled = styled(Icon, {
  shouldForwardProp: (prop) => prop !== `iconColor`,
})<MdiIconWrapperStyled>(({ hoverColor, iconColor }) => ({
  color: iconColor ? `${iconColor} !important` : undefined,

  '&:hover': { color: hoverColor },
}));
