import { Patch, Roof, SolarEnergyProject, Vertex } from '../roofVisualisationTypes';
import { getSelectedRoofAndPatch } from './accessors';
import { MAX_NUMBER_OF_VERTEX } from './constants';

export const addVertex = (
  latLng: google.maps.LatLng,
  solarEnergyProject: SolarEnergyProject,
): SolarEnergyProject | null => {
  const { selectedPatch, selectedRoof } = getSelectedRoofAndPatch(solarEnergyProject.roofs);
  if (selectedPatch && selectedRoof) {
    const vertices = selectedPatch.vertices;
    const polygonNotFull = latLng && vertices.length < MAX_NUMBER_OF_VERTEX;
    if (polygonNotFull) {
      const newVertices = [
        ...vertices.map((vertex) => ({ ...vertex, selected: false })),
        { latLng, id: `${vertices.length}`, selected: true } as Vertex,
      ];
      const newSelectedPatch: Patch = { ...selectedPatch, vertices: newVertices };
      const newPatches = [
        ...selectedRoof.patches.map((patch) => (patch.id === newSelectedPatch.id ? newSelectedPatch : patch)),
      ];
      const newSelectedRoof: Roof = { ...selectedRoof, patches: newPatches };
      const newRoofs = [
        ...solarEnergyProject.roofs.map((roof) => (roof.id === newSelectedRoof.id ? newSelectedRoof : roof)),
      ];
      return { ...solarEnergyProject, roofs: newRoofs };
    }
  }
  return null;
};

export const selectVertex = (vertexIndex: number, patch: Patch) => {
  const newVertices = patch.vertices.map((vertex, index) => ({ ...vertex, selected: index === vertexIndex }));
  return { ...patch, vertices: newVertices };
};

export function assertVerticesList(list: (Vertex | undefined)[]): asserts list is Vertex[] {
  if (list.some((vertex) => vertex === undefined)) {
    throw new Error(`Some vertices are undefined`);
  }
}
