import './compass.css';

type CompassProps = {
  rotation?: number | null;
};

export const Compass: React.FC<CompassProps> = ({ rotation = 0 }) => (
  <div className="compass">
    <div className="compass__arrow" style={{ transform: `translate(-50%, -50%) rotate(${rotation}deg)` }}></div>
  </div>
);
