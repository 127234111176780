import { WebTypography } from '@components/Typography/WebTypography';
import { OfferAgreementSenderDto } from '@generatedTypes/data-contracts';
import { Link, Stack } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { theme } from 'src/styles/theme/theme';
import { ProfilePicture } from '@components/ProfilePicture/ProfilePicture.styled';

export type ContactForOfferProps = {
  senderDetails: OfferAgreementSenderDto;
};

export const ContactForOffer: React.FC<ContactForOfferProps> = ({ senderDetails }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { acceptOffer },
      },
    },
  } = useTranslations();

  const name = `${senderDetails.firstName} ${senderDetails.lastName}`;
  const phoneNumber = senderDetails.phoneNumber || ``;
  const email = senderDetails.email;

  return (
    <Stack gap={1}>
      {senderDetails.logotypeUri && (
        <ProfilePicture src={senderDetails.logotypeUri} alt={translate(acceptOffer.profilePictureAlt)} />
      )}
      <WebTypography variant="h2">{translate(acceptOffer.contactTitle)}</WebTypography>
      <WebTypography variant="body1" className="display-linebreak">{`${translate(
        acceptOffer.contactText1,
      )}`}</WebTypography>
      <Stack gap={1}>
        <WebTypography variant="body1">{name}</WebTypography>
        <Link color={theme.palette.primary.main} underline="none" href={`tel:${phoneNumber}`}>
          {phoneNumber}
        </Link>
        <Link color={theme.palette.primary.main} underline="none" href={`mailto:${email}`}>
          {email}
        </Link>
      </Stack>
    </Stack>
  );
};
