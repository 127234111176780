import { Patch } from '../roofVisualisationTypes';
import { assertVerticesList } from './vertex';

export const selectEdge = (edgeId: string, patch: Patch): Patch => {
  const newEdges = patch.edges.map((edge) => ({ ...edge, selected: edge.id === edgeId }));
  const selectedEdgeIndex = newEdges.findIndex((edge) => edge.selected);
  const newEdgesSorted = [
    ...newEdges.slice(selectedEdgeIndex, newEdges.length),
    ...newEdges.slice(0, selectedEdgeIndex),
  ];
  const newVertices = newEdgesSorted.map(({ startId }) => {
    return patch.vertices.find(({ id }) => id === startId);
  });
  assertVerticesList(newVertices);
  return { ...patch, edges: newEdgesSorted, vertices: newVertices };
};
