import { CreateProjectAdditionRequest } from '@generatedTypes/data-contracts';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { AddEditAdditionForm, AdditionValuesType } from './AddEditAdditionForm';
import { selectCurrentProjectId } from '@redux/selectors/lead';
import { useGetLeadProjects } from '@services/api/leads/lead-info';
import { useGetOffer } from '@services/api/offers/offers';
import { useCreateProjectAddition } from '@services/api/projectAdditions/projectAdditionsAdd';
import { useDeleteProjectAddition } from '@services/api/projectAdditions/projectAdditionsDelete';
import { PutProjectAdditionProps, useUpdateProjectAddition } from '@services/api/projectAdditions/projectAdditionsEdit';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useGetProjectAddition } from '@services/api/projectAdditions/projectAdditionsGet';
import { parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';

export interface EditProjectAdditionsWrapperProps {
  onClose: () => void;
}

export const AddEditProjectAdditionWrapper = ({ onClose }: EditProjectAdditionsWrapperProps) => {
  const pushDataLayer = useAnalytics();
  const currentProjectId = useSelector(selectCurrentProjectId);
  const { leadProjects } = useGetLeadProjects();
  const { isOfferLocked } = useGetOffer();
  const { deleteProjectAddition } = useDeleteProjectAddition();
  const { updateProjectAdditionAsync, isUpdatingProjectAddition, updateProjectAdditionBeValidationErrors } =
    useUpdateProjectAddition();
  const { addProjectAdditionAsync, isAddingProjectAddition, addProjectAdditionBeValidationErrors } =
    useCreateProjectAddition();
  const { projectAddition } = useGetProjectAddition();

  const onSubmitProjectAddition = useCallback(
    ({ netPrice: formNetPrice, ...formData }: AdditionValuesType) => {
      pushDataLayer({
        event: AnalyticEvents.costAdd,
        projectType: leadProjects?.find((project) => project.id === currentProjectId)?.type,
      });

      const netPrice = Number(parseInputValueToSubmitNumberValue(formNetPrice));
      if (projectAddition?.id) {
        updateProjectAdditionAsync({
          ...formData,
          id: projectAddition.id,
          netPrice,
          isDeductible: formData.isDeductible === `true`,
          hourPrice: Number(formData.hourPrice),
        } as PutProjectAdditionProps).then(onClose);
      } else if (currentProjectId) {
        addProjectAdditionAsync({
          ...formData,
          netPrice,
          projectId: currentProjectId,
          isDeductible: formData.isDeductible === `true`,
          hourPrice: Number(formData.hourPrice),
        } as CreateProjectAdditionRequest).then(onClose);
      }
    },
    [
      pushDataLayer,
      leadProjects,
      projectAddition?.id,
      currentProjectId,
      updateProjectAdditionAsync,
      onClose,
      addProjectAdditionAsync,
    ],
  );

  return (
    <AddEditAdditionForm
      onDelete={deleteProjectAddition}
      closeModal={onClose}
      onSubmitAddEditAddition={onSubmitProjectAddition}
      disableForm={isUpdatingProjectAddition || isAddingProjectAddition || isOfferLocked}
      beValidationResults={addProjectAdditionBeValidationErrors || updateProjectAdditionBeValidationErrors}
      initialProjectAddition={projectAddition}
    />
  );
};
