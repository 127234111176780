import {
  ProductAttributeRowDto,
  ProductAttributeType,
  ProductCategoryRowDto,
  ProductCategoryType,
} from '@generatedTypes/data-contracts';

//did not refactor this functions as it's part of CarCharging project. It will be rebuilt in the future.

export const filterSelectedProductAttributes = ({
  selectedProductCategoryType,
  productCategoryRows,
  selectedProductAttributesTypes,
}: {
  selectedProductCategoryType: ProductCategoryType;
  productCategoryRows?: ProductCategoryRowDto[];
  selectedProductAttributesTypes: ProductAttributeType[];
}) => {
  const productCategoryRowFromCategory = productCategoryRows?.find(
    (category) => category.type === selectedProductCategoryType,
  );

  if (!productCategoryRowFromCategory) {
    return;
  }

  const filterProductCategoryRows: ProductCategoryRowDto = {
    ...productCategoryRowFromCategory,
    productsAttributes: productCategoryRowFromCategory.productsAttributes?.filter(
      (attr) => attr.type && selectedProductAttributesTypes.includes(attr.type),
    ),
    products: productCategoryRowFromCategory.products?.map((product) => ({
      ...product,
      productAttributes: product.productAttributes?.filter(
        (attr) => attr.type && selectedProductAttributesTypes.includes(attr.type),
      ),
    })),
  };
  return filterProductCategoryRows;
};

export const calculateProductCountBaseOnSelection = ({
  filterProductCategoryRows,
  selectedProductAttributeRow,
}: {
  filterProductCategoryRows?: ProductCategoryRowDto;
  selectedProductAttributeRow: ProductAttributeRowDto;
}) => {
  const restProductCategories = filterProductCategoryRows?.productsAttributes?.find(
    (attr) => attr.type !== selectedProductAttributeRow.type,
  );

  const productCategoriesWithCounters = restProductCategories?.values?.map((value) => ({
    ...value,
    counter: 0,
  }));

  if (!productCategoriesWithCounters) {
    return;
  }

  const productsTuple = filterProductCategoryRows?.products?.map((product) =>
    product.productAttributes?.map((attr) => attr.values?.map((attributes) => attributes.id)),
  );
  const productArrayIds = productsTuple
    ?.map((tuple) => tuple?.flat())
    .filter((tuple) => tuple && tuple.includes(selectedProductAttributeRow?.values?.[0].id));

  if (!productArrayIds) {
    return;
  }

  return productArrayIds.reduce((productCounter, idTuple) => {
    if (!idTuple) {
      return productCounter;
    }
    const item = productCounter?.find((product) =>
      [product.id, selectedProductAttributeRow.values?.[0].id].every((id) => idTuple.includes(id)),
    );
    if (!item) {
      return productCounter;
    }
    const restProducts = productCounter.filter((product) => !idTuple.includes(product.id));

    const productWithNewCount = {
      ...item,
      counter: item.counter + 1,
    };
    return [...restProducts, productWithNewCount];
  }, productCategoriesWithCounters);
};
