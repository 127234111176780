import { showNext, VIEW_TYPES } from '@components/columnLayout/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export function ForgotPasswordConfirmation() {
  const { email } = useParams();
  const {
    translate,
    translations: {
      resetPasswordConfirmation: { description, emailSent, header },
    },
  } = useTranslations();

  useEffect(() => {
    showNext(VIEW_TYPES.INFO)();
  }, []);

  return (
    <div className="container column gap reset-password-confirmation-wrapper">
      <div className="h1 bold">{translate(header)}</div>
      <div className="h2">{translate(emailSent)}</div>
      <div className="container column gap-extra-small">
        <div>{translate(description)}</div>
        <div>{email}</div>
      </div>
    </div>
  );
}
