import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, TooltipOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { rexelEnergy } from 'src/styles/theme/theme';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale);

const defaultSize = 121;
type ArcsColors = keyof typeof rexelEnergy;

const dataPlacing = {
  sumOfData: (data: number[]) => data.reduce((sum, value) => (sum += value)),
  calculateCenterOfMainData: function (data: number[]) {
    const sum = this.sumOfData(data);
    return (-(data[0] / 2) / sum) * 360;
  },
  Onleft: function (data: number[]) {
    return this.calculateCenterOfMainData(data) + 270;
  },
};

export interface PieChartProps {
  data: number[];
  arcsColors: ArcsColors[];
  firstDataPlacing?: keyof Omit<typeof dataPlacing, `sumOfData` | `calculateCenterOfMainData`>;
  size?: number | string;
  tooltipLabelRenderer?: TooltipOptions<`doughnut`>[`callbacks`][`label`];
  middleText?: string[];
}

export const DougnutChart = ({
  data,
  arcsColors,
  firstDataPlacing: mainDataPlacing = `Onleft`,
  size = defaultSize,
  tooltipLabelRenderer,
  middleText,
}: PieChartProps) => {
  const theme = useTheme();

  const colors = arcsColors.map((color) => theme.palette.rexelEnergy[color]);

  const isTooltipEnabled = Boolean(tooltipLabelRenderer);

  return (
    <Box sx={{ width: size, height: size }}>
      <Doughnut
        redraw
        options={{
          hover: { mode: isTooltipEnabled ? `nearest` : (null as never) },
          animation: false,
          cutout: `75%`,
          rotation: dataPlacing[mainDataPlacing](data),
          plugins: {
            tooltip: {
              enabled: isTooltipEnabled,
              position: `nearest`,
              callbacks: {
                label: tooltipLabelRenderer,
              },
            },
          },
        }}
        data={{
          datasets: [
            {
              data: data,
              backgroundColor: colors,
              borderColor: colors,
            },
          ],
        }}
        plugins={[
          {
            id: `textCenter`,
            beforeDatasetDraw(chart) {
              if ((middleText?.length as number) > 2 || !middleText?.length) return;
              const { ctx } = chart;
              const fontSize = 11;

              ctx.font = `${fontSize}px ${theme.typography.fontFamily}`;
              ctx.fillStyle = theme.palette.rexelEnergy.blackBlue;
              ctx.textAlign = `center`;
              ctx.textBaseline = `middle`;

              middleText?.[0] &&
                ctx.fillText(
                  `${middleText[0]}`,
                  chart.getDatasetMeta(0).data[0].x,
                  chart.getDatasetMeta(0).data[0].y - 13 / middleText.length,
                );
              middleText?.[1] &&
                ctx.fillText(
                  `${middleText[1]}`,
                  chart.getDatasetMeta(0).data[0].x,
                  chart.getDatasetMeta(0).data[0].y + 13 / 2,
                );
            },
          },
        ]}
      />
    </Box>
  );
};
