import { MapOptionButton } from '@components/controls/roofVisualisation/mapOptionButton';
import { mdiPencil, mdiTrashCanOutline } from '@mdi/js';
import React from 'react';
import { useHandleEditShape } from '../../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/patch';

export type OnMapPanelsStateButtonProps = {
  onRemovePatch: () => void;
};

export const OnMapPanelsStateButton: React.FC<OnMapPanelsStateButtonProps> = ({ onRemovePatch }) => {
  const handleEditShape = useHandleEditShape();

  return (
    <>
      <MapOptionButton icon={mdiTrashCanOutline} alt="Remove patch" onClick={onRemovePatch} />
      <MapOptionButton icon={mdiPencil} alt="Edit shape" onClick={handleEditShape} />
    </>
  );
};
