import { GoogleMapsWithPositionEdit } from '@components/maps/GoogleMaps/GoogleMapsWithPositionEdit';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { useToggle } from '@hooks/useToggle';
import { Drawer, Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/system/Box';
import { LeadInfoCards } from '@pages/NewLeads/LeadInfoCards';
import { LeadEditForm } from '@pages/NewLeads/LeadUpdateForm';
import { ContactPersonEdit } from './contactPersonEdit';
import { useUpdateLead } from '@services/api/leads/lead-edit';
import { useGetOffer } from '@services/api/offers/offers';

export const ClientTab = () => {
  const pushDataLayer = useAnalytics();
  const { isOfferLocked, lead } = useGetOffer();
  const { updateLeadAsync, isLoading } = useUpdateLead();
  const [isEditLeadFormOpen, toggleIsEditLeadFormOpen] = useToggle();
  const [isContactFormOpen, toggleIsContactFormOpen] = useToggle();

  if (!lead)
    return (
      <>
        <Box sx={{ aspectRatio: `16/9`, mb: 2 }}>
          <Skeleton variant="rectangular" height="100%" />
        </Box>
        <Stack direction="row" gap={2} width="100%">
          <Skeleton variant="rectangular" width="100%" height={130} />
          <Skeleton variant="rectangular" width="100%" height={130} />
        </Stack>
      </>
    );

  return (
    <Stack spacing={2}>
      {lead.address && (
        <Box sx={{ aspectRatio: `16/9` }}>
          <GoogleMapsWithPositionEdit key={lead.address.street} address={lead.address} />
        </Box>
      )}
      <LeadInfoCards
        onShowEditContactPersonDetails={toggleIsContactFormOpen}
        onOpenEditLeadForm={toggleIsEditLeadFormOpen}
        isOfferLocked={isOfferLocked}
      />
      <LeadEditForm isOpen={isEditLeadFormOpen} onClose={toggleIsEditLeadFormOpen} />
      <Drawer open={isContactFormOpen} onClose={toggleIsContactFormOpen} anchor="right">
        <ContactPersonEdit
          updateContactPerson={(data) => {
            pushDataLayer({ event: AnalyticEvents.contactInfo });
            updateLeadAsync({ ...lead, ...lead.address, contactPerson: data }).then(toggleIsContactFormOpen);
          }}
          disableForm={isLoading}
          contactPersonDetails={lead?.contactPerson || null}
          closeModal={toggleIsContactFormOpen}
        />
      </Drawer>
    </Stack>
  );
};
