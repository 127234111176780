import React, { useCallback, useEffect, useMemo } from 'react';
import { CreateEmptyProjectRequest, EmptyProjectDto, UpdateEmptyProjectRequest } from '@generatedTypes/data-contracts';
import { ParsedBackendValidationResults } from '@components/controls/validations';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { GridLayoutWithMargins } from '@components/gridLayout/gridLayoutWithMargins';
import { DeleteHeader } from '@components/DeleteHeader/DeleteHeader';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { RichTextInputWrapper } from '@components/controls/react-hook-form-friendly/smart/richTextInputWrapper';
import { SlideInViewFormWrapper } from '@components/slideInView/SlideInViewFormWrapper';
import { FormWrapper } from '@components/forms/form-wrapper';

export type FormInputs = Omit<CreateEmptyProjectRequest | UpdateEmptyProjectRequest, `id` | `leadId`>;

const energyStorageProjectZodObject = {
  name: z.string(),
  comment: z.string().optional(),
};

const energyStorageProjectZodSchema = z.object(energyStorageProjectZodObject);

export type EnergyStorageProjectValues = z.infer<typeof energyStorageProjectZodSchema>;

type AddEditEmptyProjectProps = {
  onSubmit: (inputs: FormInputs) => void;
  onDelete: (projectId: number) => void;
  onClose: () => void;
  projectDetails: EmptyProjectDto | null;
  beValidationResults?: ParsedBackendValidationResults | null;
  isDisabled?: boolean;
};

export const EnergyStorageProjectForm: React.FC<AddEditEmptyProjectProps> = ({
  onSubmit,
  onDelete,
  onClose,
  projectDetails,
  beValidationResults,
  isDisabled,
}) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: { header, energyStorage },
          },
        },
      },
    },
  } = useTranslations();

  const defaultValues: EnergyStorageProjectValues = {
    name: translate(energyStorage.header),
    comment: ``,
  };

  const { handleSubmit, setError, control } = useForm<EnergyStorageProjectValues>({
    values: {
      name: projectDetails?.name ?? defaultValues.name,
      comment: projectDetails?.comment ?? defaultValues.comment,
    },
    resolver: zodResolver(energyStorageProjectZodSchema),
    disabled: isDisabled,
  });

  useEffect(() => {
    if (beValidationResults) {
      Object.entries(beValidationResults.errors).forEach(([key, error]) => {
        setError(key as keyof EnergyStorageProjectValues, { message: error[0] });
      });
    }
  }, [beValidationResults, setError]);

  const submitHandler = useCallback(
    (values: EnergyStorageProjectValues) => {
      const forSubmit: FormInputs = {
        comment: values.comment,
        name: values.name,
      };

      onSubmit(forSubmit);
    },
    [onSubmit],
  );

  const handleDelete = useMemo(() => {
    if (projectDetails?.id && !isDisabled) {
      return () => onDelete(projectDetails?.id ?? -1);
    }
    return null;
  }, [isDisabled, onDelete, projectDetails?.id]);

  return (
    <SlideInViewFormWrapper
      onSubmit={handleSubmit(submitHandler)}
      onClose={onClose}
      header={translate(header)}
      isDisabled={isDisabled}
    >
      <GridLayoutWithMargins>
        <DeleteHeader title={translate(energyStorage.header)} onDelete={handleDelete} />
      </GridLayoutWithMargins>
      <GridLayoutWithMargins>
        <FormWrapper>
          <h2 className="bold">{translate(energyStorage.commentsHeader)}</h2>
          <RichTextInputWrapper label={translate(energyStorage.comments)} control={control} name="comment" />
          <hr className="horizontal-line dark fw" />
        </FormWrapper>
      </GridLayoutWithMargins>
    </SlideInViewFormWrapper>
  );
};
