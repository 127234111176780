import { mdiArrowRight, mdiContentCopy, mdiDotsHorizontal } from '@mdi/js';
import { buttonMediumSize } from '../../../../../../styles/theme/theme';
import { ListItemIcon, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { Dropdown } from '@components/Dropdown/Dropdown';
import { useToggle } from '@hooks/useToggle';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { getExclusionButtonProps } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/productContextMenu/utils';
import { ProjectProductRowDto } from '@generatedTypes/data-contracts';
import { useDeleteProjectProduct } from '@services/api/projectProducts/projectProductDelete';
import { useUpdateProjectProductMutation } from '@services/api/projectProducts/projectProductEdit';
import { useCopyProjectProduct } from '@services/api/projectProducts/projectProductCopy';
import { useGetLeadProjects } from '@services/api/leads/lead-info';
import { useMoveProjectProduct } from '@services/api/projectProducts/projectProductMove';

type ProductContextMenuProps = {
  product: ProjectProductRowDto;
  projectId: number;
};

export const ProductContextMenu: React.FC<ProductContextMenuProps> = ({ product, projectId }) => {
  const theme = useTheme();

  const {
    translate,
    translations: {
      leads: {
        details: {
          project: { productList },
        },
      },
    },
  } = useTranslations();
  const [isContextMenuOpen, toggleIsFilterDropdownOpen] = useToggle();

  const { leadProjects } = useGetLeadProjects();

  const { deleteProjectProduct, isDeletingProjectProduct } = useDeleteProjectProduct({
    onSuccess: () => {
      toggleIsFilterDropdownOpen();
    },
  });
  const { updateProjectProduct, isUpdatingProjectProduct } = useUpdateProjectProductMutation({
    onSuccess: () => {
      toggleIsFilterDropdownOpen();
    },
  });
  const { copyProjectProduct, isCopyingProjectProduct } = useCopyProjectProduct({
    onSuccess: () => {
      toggleIsFilterDropdownOpen();
    },
  });
  const { moveProjectProduct, isMovingProjectProduct } = useMoveProjectProduct({
    onSuccess: () => {
      toggleIsFilterDropdownOpen();
    },
  });

  const exclusionButtonProps = getExclusionButtonProps({
    product,
    translation: productList,
    editAction: updateProjectProduct,
    deleteAction: deleteProjectProduct,
  });

  const projectsRowToCopy =
    leadProjects
      ?.filter((project) => project.id !== projectId)
      .map((project) => ({
        id: project.id,
        name: project.name,
      })) ?? [];

  const buttonsDisabled =
    isDeletingProjectProduct || isUpdatingProjectProduct || isCopyingProjectProduct || isMovingProjectProduct;

  return (
    <Dropdown
      buttonText=""
      iconButtonIconPath={mdiDotsHorizontal}
      isDropdownOpen={isContextMenuOpen}
      toggleIsDropdownOpen={toggleIsFilterDropdownOpen}
      placeIconAfterText={true}
      styles={{
        flex: 1,
        maxWidth: buttonMediumSize,
        maxHeight: buttonMediumSize,
        minWidth: buttonMediumSize,
        '& svg': {
          color: theme.palette.rexelEnergy.selectedBlue,
        },
      }}
      transformLeft
    >
      <MenuItem
        onClick={() => copyProjectProduct({ projectProductId: product.id, targetProjectId: projectId })}
        disabled={buttonsDisabled}
      >
        <ListItemIcon>
          <MdiIconWrapper path={mdiContentCopy} />
        </ListItemIcon>
        <Typography>{translate(productList.copyButton)}</Typography>
      </MenuItem>
      {projectsRowToCopy.length > 0 && (
        <>
          <HorizontalLine />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            sx={(theme) => ({ margin: theme.spacing(0, 3, 0, 2) })}
          >
            <ListItemIcon>
              <MdiIconWrapper path={mdiArrowRight} color="black" />
            </ListItemIcon>
            <Typography variant="h4">{translate(productList.moveToLabel)}</Typography>
          </Stack>
        </>
      )}
      {projectsRowToCopy?.map((project) => (
        <MenuItem
          key={project.id}
          onClick={() => moveProjectProduct({ projectProductId: product.id, targetProjectId: project.id })}
          disabled={buttonsDisabled}
        >
          <ListItemIcon></ListItemIcon>
          <Typography>{project.name}</Typography>
        </MenuItem>
      ))}
      <HorizontalLine />
      <MenuItem onClick={exclusionButtonProps.action} disabled={buttonsDisabled}>
        <ListItemIcon>
          <MdiIconWrapper path={exclusionButtonProps.icon} />
        </ListItemIcon>
        <Typography>{translate(exclusionButtonProps.label)}</Typography>
      </MenuItem>
    </Dropdown>
  );
};
