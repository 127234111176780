export const addScript = (scriptUrl: string, callback?: () => void) => {
  const script = document.createElement(`script`);
  script.setAttribute(`data-panelista-id`, `641d5fc21e9914dfb2e5cfa9`);
  script.src = scriptUrl;
  script.async = false;
  script.onload = function () {
    callback && callback();
  };
  document.head.appendChild(script);
};
