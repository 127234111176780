import { useGetOffer, useGetOfferAttachments } from '@services/api/offers/offers';
import { Card } from '@components/Card/Card';
import { ListItem } from '@components/ListMui/ListItem';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { mdiFileOutline, mdiPlus } from '@mdi/js';
import { OfferAttachmentRowDto } from '@generatedTypes/data-contracts';
import { useSelector } from 'react-redux';
import { selectIsPartnerUser } from '@redux/reducers/slices/user';

type AttachmentsCardProps = {
  openAttachmentForm: (attachment?: OfferAttachmentRowDto) => void;
};

export const AttachmentsCard: React.FC<AttachmentsCardProps> = ({ openAttachmentForm }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { attachmentsHeader },
      },
    },
  } = useTranslations();

  const { offerAttachments } = useGetOfferAttachments();
  const { isOfferLocked } = useGetOffer();
  const isUserPartner = useSelector(selectIsPartnerUser);

  const canEditAttachments = !isOfferLocked && isUserPartner;

  return (
    <Card
      title={translate(attachmentsHeader)}
      titleAction={canEditAttachments ? openAttachmentForm : undefined}
      titleActionIcon={mdiPlus}
    >
      {offerAttachments?.map((attachment) => (
        <ListItem
          icon={mdiFileOutline}
          key={attachment.id}
          title={`${attachment.name} (${attachment.extension})`}
          onClick={canEditAttachments ? () => openAttachmentForm(attachment) : undefined}
        />
      ))}
    </Card>
  );
};
