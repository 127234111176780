import { forwardRef } from 'react';
import { LeftNavLinkWrapper, LeftNavLinkWrapperProps } from './LeftNavLinkWrapper';
import { LeftNavLinkContentTwoRowsProps } from './types';
import { LeftNavLinkContentTwoRows } from './LeftNavLinkContentTwoRows';

export const LeftNavLinkTwoRows = forwardRef<
  HTMLAnchorElement,
  Omit<LeftNavLinkWrapperProps, `children`> & LeftNavLinkContentTwoRowsProps
>(({ href, selected, ...leftNavLinkContentTwoRowsProps }, ref) => {
  return (
    <LeftNavLinkWrapper href={href} selected={selected} ref={ref}>
      <LeftNavLinkContentTwoRows selected={selected} {...leftNavLinkContentTwoRowsProps} />
    </LeftNavLinkWrapper>
  );
});

LeftNavLinkTwoRows.displayName = `LeftNavLinkTwoRows`;
