import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { SolarEnergyProjectValues } from '../../SolarEnergyProject';
import { Patch, Roof, SolarEnergyProject } from '../roofVisualisationTypes';
import { getRoofIdFromPatch } from './accessors';
import { setResolverPatchDataToForm } from './calculations';
import { defaultPanelsResolverResult, resolvePanels } from './panelsResolver/panelsResolver';

export interface TogglePanelArg {
  solarEnergyProject: SolarEnergyProject;
  patchId: string;
  panelId: number;
  setValue: UseFormSetValue<SolarEnergyProjectValues>;
}
export const togglePanel = ({
  solarEnergyProject,
  patchId,
  panelId,
  setValue,
}: TogglePanelArg): SolarEnergyProject | null => {
  const roofId = getRoofIdFromPatch(patchId);
  const roofIndex = solarEnergyProject.roofs.findIndex(({ id }) => id === roofId);

  const selectedRoof = solarEnergyProject.roofs[roofIndex];
  if (selectedRoof) {
    const patchIndex = selectedRoof.patches.findIndex(({ id }) => id === patchId);
    const clickedPatch = selectedRoof.patches[patchIndex];

    if (clickedPatch) {
      const newPanels = [...(clickedPatch.panels ?? [])];
      if (newPanels.length > panelId) {
        const panelIndex = newPanels.findIndex(({ id }) => id === panelId);
        newPanels[panelIndex] = {
          ...newPanels[panelIndex],
          active: !newPanels[panelIndex].active,
        };
      }
      const newPatch: Patch = {
        ...clickedPatch,
        panels: newPanels,
      };
      setResolverPatchDataToForm({
        panelsResolverResults: newPatch,
        setValue,
        roofIndex,
        patchIndex,
      });
      const newPatches = [...selectedRoof.patches.map((patch) => (patch.id === newPatch.id ? newPatch : patch))];
      const newSelectedRoof: Roof = { ...selectedRoof, patches: newPatches };
      return {
        ...solarEnergyProject,
        roofs: [...solarEnergyProject.roofs.map((roof) => (roof.id === newSelectedRoof.id ? newSelectedRoof : roof))],
      } as SolarEnergyProject;
    }
  }
  return null;
};

export const recalculateAllPanels = (
  map: google.maps.Map,
  solarEnergyProject: SolarEnergyProject,
): SolarEnergyProject => {
  const newRoofs: Roof[] = [...solarEnergyProject.roofs].map((roof) => {
    const newPatches: Patch[] = [
      ...roof.patches.map((patch) => {
        const panelOrientation = patch.panelOrientation;
        const panelsResolverResults =
          panelOrientation !== null
            ? resolvePanels({
                map,
                patch,
                resolverFunctionVersion: solarEnergyProject.panelsResolverVersion,
              })
            : { ...defaultPanelsResolverResult, solarPanelGrid: patch.panels };
        return { ...patch, ...panelsResolverResults };
      }),
    ];
    return { ...roof, patches: newPatches };
  });
  return { ...solarEnergyProject, roofs: newRoofs };
};

export const removeAllPanels = (solarEnergyProject: SolarEnergyProject): SolarEnergyProject => {
  const newRoofs = [...solarEnergyProject.roofs].map((roof) => {
    const newPatches = [
      ...roof.patches.map((patch) => {
        return { ...patch, panels: [] };
      }),
    ];
    return { ...roof, patches: newPatches };
  });
  return { ...solarEnergyProject, roofs: newRoofs };
};
