import { forwardRef } from 'react';
import { LeftNavLinkContentThreeRows } from './LeftNavLinkContentThreeRows';
import { LeftNavLinkWrapper, LeftNavLinkWrapperProps } from './LeftNavLinkWrapper';
import { LeftNavLinkContentThreeRowsProps } from './types';

export const LeftNavLinkThreeRows = forwardRef<
  HTMLAnchorElement,
  Omit<LeftNavLinkWrapperProps, `children`> & LeftNavLinkContentThreeRowsProps
>(({ href, selected, leftBottom, ...leftNavLinkContentThreeRowProps }, ref) => {
  return (
    <LeftNavLinkWrapper href={href} selected={selected} ref={ref} isThreeRows={!!leftBottom}>
      <LeftNavLinkContentThreeRows selected={selected} leftBottom={leftBottom} {...leftNavLinkContentThreeRowProps} />
    </LeftNavLinkWrapper>
  );
});

LeftNavLinkThreeRows.displayName = `LeftNavLinkLeadsPage`;
