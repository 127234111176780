import { useContext } from 'react';
import { actions, getDispatchSolarEnergyProject } from '../store';
import { calculateMeterInPixels } from '../../calculations';
import { recalculateAllPanels } from '../../panel';
import { SolarMapVisualisationContext, SolarMapVisualisationDispatchContext } from '../context';

export const useReloadProjectOnMap = () => {
  const { solarEnergyProject, map } = useContext(SolarMapVisualisationContext);
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  const dispatchSolarEnergyProject = getDispatchSolarEnergyProject(dispatch);

  return () => {
    if (!map) {
      return;
    }
    dispatch(actions.setLoadingState(`reloadingProjectsOnMap`));
    dispatch(actions.setMeterInPixels(calculateMeterInPixels(map)));
    const newSolarEnergyProject = recalculateAllPanels(map, solarEnergyProject);
    dispatchSolarEnergyProject(newSolarEnergyProject);
    dispatch(actions.setLoadingState(`loadingFinished`));
  };
};
