import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPartnerId } from '@redux/reducers/slices/user';
import { selectIsPartnerUser } from '@redux/selectors/user';
import { useQueryClient } from 'react-query';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { DEDUCTION_TEMPLATES, PARTNER, UPDATE } from '@variables/queryKeys';
import { putDeductionTemplate } from '@services/api/deductionTemplates/deductionTempaltes';

export const useUpdateDeductionTemplate = (options?: { onSuccess?: () => void }) => {
  const { partnerId: partnerIdParams } = useParams<{ partnerId: string }>();
  const partnerId = useSelector(selectPartnerId);
  const isPartnerUser = useSelector(selectIsPartnerUser);

  const selectedPartnerId = isPartnerUser ? partnerId : Number(partnerIdParams);
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors, parsedBackendError } = useCustomMutation({
    mutationKey: [UPDATE, DEDUCTION_TEMPLATES],
    mutationFn: putDeductionTemplate,
    onSuccess: (updatedDeductionTemplateId) => {
      queryClient.invalidateQueries([PARTNER, DEDUCTION_TEMPLATES, selectedPartnerId]);
      queryClient.invalidateQueries([DEDUCTION_TEMPLATES, updatedDeductionTemplateId]);
      options?.onSuccess?.();
    },
  });

  return {
    isUpdatingDeductionTemplate: isLoading,
    updateDeductionTemplate: mutate,
    updateDeductionTemplateAsync: mutateAsync,
    updateDeductionTemplateValidationErrors: validationErrors,
    updateDeductionTemplateValidationErrorsParsedBackendError: parsedBackendError,
  };
};
