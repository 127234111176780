import { Patch } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/roofVisualisationTypes';
import { selectVertex } from '../../vertex';
import { useHandleUpdatePatch } from './patch';

export const useHandleVertexSelect = () => {
  const handleUpdatePatch = useHandleUpdatePatch();

  return (vertexIndex: number, patch: Patch) => () => {
    handleUpdatePatch(selectVertex(vertexIndex, patch));
  };
};

export const useHandleVertexDragEnd = () => {
  const handleUpdatePatch = useHandleUpdatePatch();

  return (vertexIndex: number, patch: Patch) => (mapMouseEvent: google.maps.MapMouseEvent) => {
    if (mapMouseEvent?.latLng) {
      const newVertices = patch.vertices.map((vertex, index) => ({ ...vertex, selected: index === vertexIndex }));
      newVertices[vertexIndex].latLng = mapMouseEvent.latLng;
      handleUpdatePatch({ ...patch, vertices: newVertices });
    }
  };
};
