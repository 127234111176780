import { CreateCarChargingProjectRequest, UpdateCarChargingProjectRequest } from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useQueryClient } from 'react-query';
import { CAR_CHARGING_PROJECT, LEAD, LEADS, OFFER_PRICE_OVERVIEW, PROJECTS } from '@variables/queryKeys';

const postCarChargingProject = async (data: CreateCarChargingProjectRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/car-charging-projects`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

export interface PutCarChargingSite extends UpdateCarChargingProjectRequest {
  id: number;
}

const putCarChargingProject = async ({ id, ...data }: PutCarChargingSite) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/car-charging-projects/${id}`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

const deleteCarChargingProject = async (projectId: number) => {
  if (projectId === -1) {
    return null;
  }
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/car-charging-projects/${projectId}`, {
    method: `DELETE`,
    mode: `cors`,
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
};

export const usePostCarChargingProject = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: postCarChargingProject,
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_CHARGING_PROJECT);
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(LEAD);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
    },
  });

  return {
    postCarChargingProject: mutate,
    postCarCharginProjectAsync: mutateAsync,
    isCreateCarChargingProjectLoading: isLoading,
    createCarChargingError: validationErrors,
  };
};
export const usePutCarChargingProject = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: putCarChargingProject,
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_CHARGING_PROJECT);
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
    },
  });

  return {
    putCarChargingProject: mutate,
    putCarChargingProjectAsync: mutateAsync,
    isUpdateingCarChargingProject: isLoading,
    updateCarChargingError: validationErrors,
  };
};

export const useDeleteCarChargingProject = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading } = useCustomMutation({
    mutationFn: deleteCarChargingProject,
    onSuccess: () => {
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(LEAD);
    },
  });

  return {
    deleteCarCharginProject: mutate,
    deleteCarChargingProjectAsync: mutateAsync,
    isDeletingCarChargingProject: isLoading,
  };
};
