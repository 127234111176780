import { ProjectAdditionDto, ProjectAdditionTemplateDto } from '@generatedTypes/data-contracts';
import { Typography } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useGetPartner } from '@services/api/partners';
import { ProjectAdditionsTemplates } from '@assets/translations/translations';
import { AdditionsListContent } from './AdditionsListContent';

export type AdditionsListProps = {
  projectAdditions: (Omit<ProjectAdditionDto, `projectId`> | ProjectAdditionTemplateDto)[];
  onShowAddEditAdditionForm?: (projectAdditionId?: ProjectAdditionDto[`id`]) => void;
  projectAdditionsTranslations: ProjectAdditionsTemplates;
};

export const AdditionsList: React.FC<AdditionsListProps> = (props) => {
  const { translate } = useTranslations();
  const { partner } = useGetPartner();

  const netCostPerHour = partner?.netCostPerHour ?? 0;

  const deductibleAdditions = props.projectAdditions.filter(({ isDeductible }) => isDeductible);
  const nonDeductibleAdditions = props.projectAdditions.filter(({ isDeductible }) => !isDeductible);

  return (
    <>
      {Boolean(deductibleAdditions.length) && (
        <Typography variant="h3" sx={{ marginLeft: 3, mb: 1 }}>
          {translate(props.projectAdditionsTranslations.installationCosts)}
        </Typography>
      )}
      <AdditionsListContent
        additions={deductibleAdditions}
        netCostPerHour={netCostPerHour}
        onShowAddEditAdditionForm={props.onShowAddEditAdditionForm}
      />
      {Boolean(nonDeductibleAdditions.length) && (
        <Typography variant="h3" sx={{ marginLeft: 3, marginTop: 3, mb: 1 }}>
          {translate(props.projectAdditionsTranslations.nonDeductibleCosts)}
        </Typography>
      )}
      <AdditionsListContent
        additions={nonDeductibleAdditions}
        netCostPerHour={netCostPerHour}
        onShowAddEditAdditionForm={props.onShowAddEditAdditionForm}
      />
    </>
  );
};
