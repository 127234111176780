import { WebTypography } from '@components/Typography/WebTypography';
import { OfferAgreementDto } from '@generatedTypes/data-contracts';
import { Box, Stack, Typography } from '@mui/material';
import { dateFormatter } from '@pages/NewLeads/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';

export type OfferCardPartnerLeadInformationProps = {
  offerDetails: OfferAgreementDto;
};

export const OfferCardPartnerLeadInformation: React.FC<OfferCardPartnerLeadInformationProps> = ({ offerDetails }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { offerIntro },
      },
    },
  } = useTranslations();

  const offerDateFrom = `${translate(offerIntro.dateFrom)}: ${
    offerDetails.details?.validFromDate !== null ? dateFormatter(offerDetails.details?.validFromDate) : `-`
  }`;
  const offerDateTo = `${translate(offerIntro.dateTo)}: ${
    offerDetails.details?.validToDate !== null ? dateFormatter(offerDetails.details?.validToDate) : `-`
  }`;

  return (
    <Stack direction="row" gap={2}>
      {offerDetails.partner?.logotypeUri && (
        <Box sx={{ '& > img': { maxWidth: 144, maxHeight: 108 } }}>
          <img src={offerDetails.partner?.logotypeUri} alt="logo" />
        </Box>
      )}
      <Stack gap={1}>
        <Stack>
          <WebTypography variant="h3">{offerDetails.partner?.name}</WebTypography>
          <WebTypography>
            {translate(offerIntro.orgNumberLabel)} {offerDetails.partner?.organizationNumber}
          </WebTypography>
        </Stack>

        <Stack>
          <Typography variant="subtitle2">
            <span>{offerDateFrom}</span>
          </Typography>
          <Typography variant="subtitle2">
            <span>{offerDateTo}</span>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
