import { InputErrorElement } from '@components/controls/input-error-element';
import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import {
  FileInputFileName,
  FileInputInput,
  FileInputLabel,
  FileInputResetButton,
} from '@components/controls/react-hook-form-friendly/dumb/FileInput/FileInput.styled';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiClose } from '@mdi/js';

export type FileInputProps = {
  label?: string;
  buttonText: string;
  onChange: (file: File | null) => void;
  disabled?: boolean;
  errorMessage?: string;
  required?: boolean;
  description?: string;
  selectedFileName?: string;
};
export const FileInput: React.FC<FileInputProps> = ({
  buttonText,
  label,
  onChange,
  disabled,
  required,
  errorMessage,
  description,
  selectedFileName,
}) => {
  const theme = useTheme();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && !selectedFileName) {
      onChange(file);
    }
  };

  const resetFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onChange(null);
  };

  return (
    <Stack spacing={2} className={`validated-wrapper${required ? ` required` : ``}`}>
      {label && <label>{label}</label>}
      <FileInputLabel htmlFor="file-uploader-input">
        <FileInputFileName color={selectedFileName ? `black` : theme.palette.rexelEnergy.ctaBlue}>
          {selectedFileName ?? buttonText}
        </FileInputFileName>
        {selectedFileName && (
          <FileInputResetButton onMouseUp={resetFile}>
            <MdiIconWrapper path={mdiClose} />
          </FileInputResetButton>
        )}
        <FileInputInput
          type="file"
          name="file-uploader-input"
          id="file-uploader-input"
          onChange={handleChange}
          disabled={disabled || !!selectedFileName}
          required={required}
        />
      </FileInputLabel>

      {description && <Typography variant="subtitle2">{description}</Typography>}
      {errorMessage && <InputErrorElement errorMsg={errorMessage} />}
    </Stack>
  );
};
