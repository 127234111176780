import { SystemSettingsDto } from '@generatedTypes/data-contracts';
import { TAction } from '@redux/reducers';
import { SystemSettingsState } from '@redux/reducers/systemSettings';

export const SYSTEM_SETTINGS_ACTIONS = {
  GET_SYSTEM_SETTINGS: `GET_SYSTEM_SETTINGS`,
} as const;

export type SystemSettingsAction = TAction<typeof SYSTEM_SETTINGS_ACTIONS, SystemSettingsState>;

export const setSettingsTemplate = (value: SystemSettingsDto): SystemSettingsAction => ({
  type: SYSTEM_SETTINGS_ACTIONS.GET_SYSTEM_SETTINGS,
  payload: { systemSettings: value },
});
