import Stack from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { IconTooltip, IconTooltipProps } from './IconTooltip';
import { WebTypography } from '@components/Typography/WebTypography';

export interface TypographyWithIconTooltipProps extends IconTooltipProps {
  children: string;
  leftIconTooltip?: boolean;
  typographyVariant?: TypographyProps[`variant`];
  web?: boolean;
}

export const TypographyWithIconTooltip = ({
  children,
  leftIconTooltip = false,
  typographyVariant,
  web = false,
  ...iconTooltipProps
}: TypographyWithIconTooltipProps) => {
  const TypographyComponent = web ? WebTypography : Typography;
  return (
    <Stack direction={leftIconTooltip ? `row-reverse` : `row`} alignItems="center" gap={1}>
      <TypographyComponent variant={typographyVariant}>{children}</TypographyComponent>
      <IconTooltip {...iconTooltipProps} />
    </Stack>
  );
};
