import './resetPassword.css';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useSearchParams } from '@utils/useSearchParams';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import { requiredString } from '@variables/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useResetPassword } from '@services/api/accounts';
import { Button } from '@mui/material';

const passwordValidationPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])/;
const passwordIsValid = (password: string) => passwordValidationPattern.test(password);

const useResetPasswordZodSchema = () => {
  const {
    translations: {
      validationErrors: { passwordFormatIncorrect, passwordDontMatch },
    },
    translate,
  } = useTranslations();

  return z
    .object({
      newPassword: requiredString().refine((value) => passwordIsValid(value), {
        message: translate(passwordFormatIncorrect),
      }),
      confirmPassword: requiredString(),
    })
    .refine(({ newPassword, confirmPassword }) => newPassword === confirmPassword, {
      message: translate(passwordDontMatch),
      path: [`confirmPassword`],
    });
};

function NoParams() {
  const { translations, translate } = useTranslations();
  const tr = translations.resetPassword;

  return (
    <div className="box box-large col-1-mobile-small col-desktop-4 col-desktop-offset-5 col-12">
      <div className="column fw fh gap-big align-center">
        <h2>{translate(tr.invalidLink)}</h2>
        <span>{translate(tr.invalidLinkMessage)}</span>
      </div>
    </div>
  );
}

export const ResetPassword = () => {
  const { translations, translate, translateWithParams } = useTranslations();
  const btr = translations.common.buttons;
  const tr = translations.resetPassword;
  const searchParams = useSearchParams();
  const email = useMemo(() => searchParams.get(`email`) ?? ``, [searchParams]);
  const token = useMemo(() => searchParams.get(`token`)?.replace(/ /g, `+`) ?? ``, [searchParams]);
  const resetPasswordZodSchema = useResetPasswordZodSchema();

  const { resetPassword, isResettingPassword } = useResetPassword();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(resetPasswordZodSchema),
    values: { newPassword: ``, confirmPassword: `` },
  });

  const resetPasswordPage = useMemo(
    () => (
      <form
        className="box box-large col-1-mobile-small col-desktop-4 col-desktop-offset-5 col-12 column gap-big"
        onSubmit={handleSubmit(({ newPassword }) => resetPassword({ email, token, password: newPassword }))}
      >
        <fieldset disabled={isResettingPassword} className="column fw gap-big align-center">
          <h2>{translateWithParams(tr.changePasswordFor)(email)}</h2>
          <div className="form-group column fw">
            <InputWrapper
              control={control}
              name="newPassword"
              label={translate(tr.newPassword)}
              type="password"
              isRequired
            />
          </div>
          <div className="form-group column fw">
            <InputWrapper
              control={control}
              name="confirmPassword"
              label={translate(tr.confirmNewPassword)}
              type="password"
              isRequired
              errorMessage={errors.confirmPassword?.message}
            />
          </div>
        </fieldset>
        <div className="buttons-wrapper column stretch gap fw">
          <Button type="submit" variant="contained" disabled={isResettingPassword}>
            {translate(btr.save)}
          </Button>
        </div>
      </form>
    ),
    [
      btr.save,
      control,
      email,
      errors.confirmPassword?.message,
      handleSubmit,
      isResettingPassword,
      resetPassword,
      token,
      tr.changePasswordFor,
      tr.confirmNewPassword,
      tr.newPassword,
      translate,
      translateWithParams,
    ],
  );

  return (
    <div className="reset-password-form-wrapper container container-center fh fw">
      {token && email ? resetPasswordPage : <NoParams />}
    </div>
  );
};
