import { MdiIconWrapper, MdiIconWrapperProps } from '@components/MdiIconWrapper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';

export interface TypographyWIthIconProps {
  iconPath: string;
  children: string;
  iconOnTheRight?: boolean;
  iconSize?: MdiIconWrapperProps[`size`];
  href?: string;
  typographyVariant?: TypographyProps[`variant`];
  component?: TypographyProps[`component`];
  fontSize?: TypographyProps[`fontSize`];
  color?: TypographyProps[`color`];
}

export const TypographyWithIcon = ({
  iconOnTheRight,
  iconPath,
  children,
  iconSize,
  href,
  typographyVariant,
  component,
  fontSize,
  color,
}: TypographyWIthIconProps) => {
  return (
    <Stack direction={iconOnTheRight ? `row-reverse` : `row`} gap={1}>
      <Box>
        <MdiIconWrapper path={iconPath} size={iconSize} />
      </Box>
      <Typography
        variant={typographyVariant}
        component={href ? `a` : component || `p`}
        fontSize={fontSize}
        color={color}
      >
        {children}
      </Typography>
    </Stack>
  );
};
