import { Card } from '@components/Card/Card';
import { UserType } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import { mdiPencil } from '@mdi/js';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useDeleteUserProfilePicture, useUpdateUserProfilePicture } from '@services/api/users';
import { UniversalUser } from '@services/api/users/types';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback } from 'react';
import { ProfilePicture } from '@components/ProfilePicture/ProfilePicture.styled';
import { EditProfilePicture } from '@pages/Users/EditProfilePicture';

export interface UserProfilePictureProps {
  user: UniversalUser | null;
}

export const UserProfilePicture = ({ user }: UserProfilePictureProps) => {
  const {
    translate,
    translations: {
      users: { details },
    },
  } = useTranslations();

  const [isEditProfilePictureVisible, toggleIsProfilePictureVisible] = useToggle();
  const updateUserProfilePicture = useUpdateUserProfilePicture();
  const deleteUserProfilePicture = useDeleteUserProfilePicture();

  const onPictureUpdated = useCallback(
    (userId: number, userType: UserType, profilePicture?: File) => {
      if (profilePicture) {
        updateUserProfilePicture.mutate({ userId, userType, profilePicture });
      } else {
        deleteUserProfilePicture.mutate({ userId, userType });
      }

      toggleIsProfilePictureVisible();
    },
    [toggleIsProfilePictureVisible, updateUserProfilePicture, deleteUserProfilePicture],
  );

  return (
    <Card
      title={translate(details.profilePicture.header)}
      titleAction={toggleIsProfilePictureVisible}
      titleActionIcon={mdiPencil}
      titleActionTestId="edit-profile-picture"
    >
      <Stack spacing={2}>
        <Typography variant="h4">{translate(details.profilePicture.content)}</Typography>
        {user?.logotypeUri ? (
          <>
            <p>{translate(details.profilePicture.subContent)}</p>
            <ProfilePicture src={user?.logotypeUri} />
          </>
        ) : (
          <></>
        )}
      </Stack>
      <Drawer open={isEditProfilePictureVisible} onClose={toggleIsProfilePictureVisible} anchor="right">
        <EditProfilePicture
          closeModal={toggleIsProfilePictureVisible}
          user={user}
          onPictureUpdated={onPictureUpdated}
        />
      </Drawer>
    </Card>
  );
};
