import { LeadRowDto } from '@generatedTypes/data-contracts';
import { TAction } from '@redux/reducers';
import { LeadsState } from '@redux/reducers/leads';

export const LEADS_ACTIONS = {
  LEADS_LIST: `LEADS_LIST`,
} as const;

export type LeadsAction = TAction<typeof LEADS_ACTIONS, LeadsState>;

export const setLeadsList = (value: LeadRowDto[]): LeadsAction => ({
  type: LEADS_ACTIONS.LEADS_LIST,
  payload: { leadsList: value },
});
