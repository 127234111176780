import DOMPurify from 'dompurify';
import './rich-text-preview.css';

const createMarkup = (html: string) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

type RichTextPreviewProps = {
  richTextAsHtml: string;
  innerClassName?: string;
};

export const RichTextPreview: React.FC<RichTextPreviewProps> = ({ richTextAsHtml, innerClassName }) => (
  <div
    className={`rich-text-preview${innerClassName ? ` ${innerClassName}` : ``}`}
    dangerouslySetInnerHTML={createMarkup(richTextAsHtml)}
  />
);
