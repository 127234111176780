import { FC, useEffect, useMemo } from 'react';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import { AddressAutocompleteGoogle } from '@components/maps/GoogleMaps/Autocomplate';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useForm, useWatch } from 'react-hook-form';
import { LeadZodSchema, leadZodObject } from './zodSettings';
import { zodResolver } from '@hookform/resolvers/zod';
import Stack from '@mui/material/Stack';
import { ChipList } from '@components/controls/react-hook-form-friendly/smart/ChipList';
import { ParsedBackendValidationResults } from '@components/controls/validations';

import { getLeadTypeOptionsWithTranslations } from '../../NewLeads/utils';
import { LeadCustomerType } from '@generatedTypes/data-contracts';
import { NewLeadType } from './LeadType';
import { DevTool } from '@hookform/devtools';
import * as S from './styles';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { FormSection } from '@components/forms/MuiFormSection';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { NullableToUndefindableObject } from '@utils/types/NullableToUndefindable';

export interface LeadFormProps {
  onSubmit: (data: LeadZodSchema) => Promise<void>;
  isSubmitting: boolean;
  errors: ParsedBackendValidationResults | null;
  initialValues?: NullableToUndefindableObject<LeadZodSchema>;
  isOpen: boolean;
  onClose: () => void;
}

const defaultValues: LeadZodSchema = {
  city: ``,
  zipCode: ``,
  street: ``,
  customerType: LeadCustomerType.None,
  firstName: ``,
  lastName: ``,
  email: ``,
  phoneNumber: ``,
  propertyDesignation: ``,
  organizationName: ``,
  organizationNumber: ``,
};

export const LeadForm: FC<LeadFormProps> = ({ onSubmit, isSubmitting, errors, isOpen, onClose, initialValues }) => {
  const {
    translate,
    translations: {
      common: { customer },
      leads: {
        details: { customerDetails },
      },
    },
  } = useTranslations();
  const leadTypeOptions = getLeadTypeOptionsWithTranslations({ withNoneValue: true });

  const values = useMemo(
    () =>
      initialValues
        ? {
            city: initialValues.city,
            zipCode: initialValues.zipCode,
            street: initialValues.street,
            latitude: initialValues.latitude,
            longitude: initialValues.longitude,
            customerType: initialValues.customerType || defaultValues.customerType,
            firstName: initialValues.firstName ?? defaultValues.firstName,
            lastName: initialValues.lastName ?? defaultValues.lastName,
            email: initialValues.email ?? defaultValues.email,
            phoneNumber: initialValues.phoneNumber ?? defaultValues.phoneNumber,
            propertyDesignation: initialValues.propertyDesignation ?? defaultValues.propertyDesignation,
            organizationName: initialValues.organizationName ?? defaultValues.organizationName,
            organizationNumber: initialValues.organizationNumber ?? defaultValues.organizationNumber,
          }
        : defaultValues,
    [initialValues],
  );

  const { control, handleSubmit, reset, setError, getValues } = useForm({
    values,
    resolver: zodResolver(leadZodObject),
  });

  const leadType = useWatch({ control, name: `customerType` });

  useEffect(() => {
    if (errors) {
      Object.entries(errors.errors).forEach(([key, value]) => {
        setError(key as keyof LeadZodSchema, { message: value.join(`, `) });
      });
    }
  }, [errors, setError]);

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <FormWrapper
        title={translate(customer)}
        disabled={isSubmitting}
        onCancel={() => {
          onClose();
        }}
        onSubmit={handleSubmit((values) => onSubmit(values).then(() => reset(defaultValues)))}
      >
        <FormSection title={translate(customerDetails.address)}>
          <AddressAutocompleteGoogle
            handleUpdateAddress={({ street, zipCode, city, latitude, longitude }) =>
              reset({ ...getValues(), street, zipCode: zipCode, city, latitude, longitude })
            }
            name="street-autocomplete"
            placeholder={translate(customerDetails.searchAndChooseAdress)}
          />
          <InputWrapper
            control={control}
            label={translate(customerDetails.street)}
            name="street"
            isDisabled
            placeholder={translate(customerDetails.searchAddressToAdd)}
            isRequired
            inputProps={{ autoComplete: `off` }}
          />
          <Stack direction="row" spacing={3}>
            <Box sx={{ flex: 3 }}>
              <InputWrapper
                label={translate(customerDetails.postCode)}
                mask="999 99"
                control={control}
                name="zipCode"
                isDisabled
                isRequired
                inputProps={{ autoComplete: `off` }}
              />
            </Box>
            <Box sx={{ flex: 3.9 }}>
              <InputWrapper
                label={translate(customerDetails.city)}
                control={control}
                name="city"
                isDisabled
                isRequired
                inputProps={{ autoComplete: `off` }}
              />
            </Box>
          </Stack>
        </FormSection>
        <S.ChipsWrapper>
          <Typography mb={2} variant="h2" component="h4">
            {translate(customerDetails.customerData)}
          </Typography>
          <ChipList
            boldLabel
            version="large"
            control={control}
            name="customerType"
            chips={leadTypeOptions}
            key={`mockup`}
            wrapChips
          />
        </S.ChipsWrapper>
        <Divider />
        <NewLeadType leadType={leadType} control={control} />
        <DevTool control={control} />
      </FormWrapper>
    </Drawer>
  );
};
