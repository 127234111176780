import { ProjectAdditionTemplateDto } from '@generatedTypes/data-contracts';
import { CheckBoxProps as CheckBoxDumbProps } from '@components/controls/react-hook-form-friendly/dumb/Checkbox/Checkbox';
import { Stack, Typography } from '@mui/material';

type TemplateCheckboxRowProps = {
  currencyTranslation: string;
  pieceTranslation: string;
  hourTranslation: string;
  netCostPerHour: number;
};

export const getMapAdditionTemplateToCheckboxRow =
  ({ currencyTranslation, pieceTranslation, hourTranslation, netCostPerHour }: TemplateCheckboxRowProps) =>
  (
    additionsTemplate: ProjectAdditionTemplateDto,
  ): Omit<CheckBoxDumbProps, `name` | `onChange` | `checked` | `isLabelOnRight`> => ({
    label: (
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography>{additionsTemplate.name}</Typography>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography noWrap>
            {additionsTemplate.netPrice * additionsTemplate.quantity +
              netCostPerHour * additionsTemplate.numberOfWorkHours}
            {` `}
            {currencyTranslation}
          </Typography>
          <Typography noWrap>
            {additionsTemplate.numberOfWorkHours ? `${additionsTemplate.numberOfWorkHours} ${hourTranslation}, ` : ``}
            {additionsTemplate.quantity ? `${additionsTemplate.quantity} ${pieceTranslation}` : ``}
          </Typography>
        </Stack>
      </Stack>
    ),
    value: additionsTemplate.id,
  });
