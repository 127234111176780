import { Card } from '@components/Card/Card';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import { mdiPencil } from '@mdi/js';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { EditPartner } from './EditPartner';

export interface PartnerCardProps {
  partner: PartnerDto | null;
}

export const PartnerCard = ({ partner }: PartnerCardProps) => {
  const {
    translate,
    translations: {
      partners: {
        details: { partnerHeader },
      },
    },
  } = useTranslations();
  const [showEditPanel, toggleShowEditPanel] = useToggle();

  return (
    <Card title={translate(partnerHeader)} titleAction={toggleShowEditPanel} titleActionIcon={mdiPencil}>
      <Stack spacing={3}>
        <div>
          <b>{partner?.name}</b>
          <div className="small">{partner?.accountId}</div>
        </div>
        <div>{partner?.organizationNumber}</div>
        <div>{`${partner?.address?.street || ``}${(partner?.address?.street && partner?.address?.city && `, `) || ``}${
          partner?.address?.city || ``
        }`}</div>
        <div>{partner?.email}</div>
        <div>{partner?.phoneNumber}</div>
      </Stack>
      {partner && (
        <Drawer anchor="right" open={showEditPanel} onClose={toggleShowEditPanel}>
          <EditPartner partner={partner} onClose={toggleShowEditPanel} />
        </Drawer>
      )}
    </Card>
  );
};
