import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const RoundingToggleButton = styled(Button)(({ theme }) => ({
  margin: `0`,
  padding: `0`,
  backgroundColor: `transparent`,
  color: theme.palette.primary.main,
  height: `auto`,
  minHeight: `auto`,
  fontFamily: theme.typography.fontFamily,

  '&:hover': {
    backgroundColor: `transparent`,
  },

  '&:disabled': {
    color: theme.palette.text.primary,
    backgroundColor: `transparent`,
  },
}));
