import React, { useState } from 'react';
import { Tabs as MUITabs, Tab, Box, Stack } from '@mui/material';
import { theme } from 'src/styles/theme/theme';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import IconButton from '@mui/material/IconButton';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

function a11yProps(index: number, tab: string) {
  return {
    id: `tab-${tab + index}`,
    'aria-controls': `tabpanel-${tab + index}`,
  };
}

interface TabsProps {
  /** The tab buttons */
  tabs: string[];
  /** Tab title action that will be displayed on the right side of the tab */
  /** customAction allows to pass ReactNode instead of icon and action **/
  tabsActions?: {
    icon?: string;
    action?: () => void;
    customAction?: React.ReactNode;
  }[];
  /** The panels that will show when switching between tabs */
  tabPanels: React.ReactNode[];
  /** Label for aria attribute */
  label: string;
  tabStyle?: SxProps<Theme>;
  wrapperStyle?: SxProps<Theme>;
}

export default function Tabs({
  tabs,
  tabPanels,
  label,
  tabsActions = [],
  tabStyle,
  wrapperStyle,
}: Readonly<TabsProps>) {
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Stack sx={{ mb: `11px`, justifyContent: `space-between` }} direction="row">
        <MUITabs
          value={value}
          onChange={handleChange}
          aria-label={label}
          sx={{ '.MuiTabs-indicator': { backgroundColor: theme.palette.primary.dark }, ...wrapperStyle }}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab} {...a11yProps(index, tab)} sx={{ ...tabStyle }} data-testid="tab" />
          ))}
        </MUITabs>
        {tabsActions?.length - 1 >= value &&
          (tabsActions?.[value]?.customAction ?? (
            <IconButton onClick={() => tabsActions?.[value].action?.()} type={`button`}>
              <MdiIconWrapper path={tabsActions?.[value].icon ?? ``} />
            </IconButton>
          ))}
      </Stack>
      {tabPanels.map((tabPanel, index) => (
        <TabPanel value={value} index={index} attachedTab={tabs[index]} key={index}>
          {tabPanel}
        </TabPanel>
      ))}
    </Box>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  attachedTab: string;
}

function TabPanel(props: Readonly<TabPanelProps>) {
  const { children, index, value, attachedTab, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${attachedTab + index}`}
      aria-labelledby={`tab-${attachedTab + index}`}
      {...other}
      className="tab-panel"
    >
      {value === index && <Box sx={{ width: `100%` }}>{children}</Box>}
    </Box>
  );
}
