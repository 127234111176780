import { ProjectAdditionDto, UpdateProjectAdditionRequest } from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useQueryClient } from 'react-query';
import { LEAD, LEADS, OFFER_PRICE_OVERVIEW, PROJECTS } from '@variables/queryKeys';

export type PutProjectAdditionProps = UpdateProjectAdditionRequest & {
  id: ProjectAdditionDto[`id`];
};

const putProjectAddition = async ({ id, ...rest }: PutProjectAdditionProps) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/project-additions/${id}`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify({
      ...rest,
    } as UpdateProjectAdditionRequest),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

export const useUpdateProjectAddition = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: putProjectAddition,
    onSuccess: () => {
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries([OFFER_PRICE_OVERVIEW]);
    },
  });

  return {
    updateProjectAddition: mutate,
    updateProjectAdditionAsync: mutateAsync,
    isUpdatingProjectAddition: isLoading,
    updateProjectAdditionBeValidationErrors: validationErrors,
  };
};
