import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DropdownOption } from '@hooks/useForm/useFormTypes';
import { AppState } from '@redux/reducers';

export type OptionWithAvailability<ValType extends number | string = number> = {
  value: ValType;
  label: string;
  disabled: boolean;
  icon?: string;
};

type SolarEnergyProjectPageState = {
  slopeChipsOptions: DropdownOption<number>[];
  directionChipsOptions: DropdownOption<number>[];
  availablePanelOrientations: OptionWithAvailability[];
  availableRailOrientations: OptionWithAvailability[];
  availableMountingMaterialManufacturers: OptionWithAvailability[];
  isProductionLoading: boolean;
};

const initialState: SolarEnergyProjectPageState = {
  slopeChipsOptions: [],
  directionChipsOptions: [],
  availablePanelOrientations: [],
  availableRailOrientations: [],
  availableMountingMaterialManufacturers: [],
  isProductionLoading: false,
};

const solarEnergyProjectPageSlice = createSlice({
  name: `solarEnergyProjectPage`,
  initialState,
  reducers: {
    setSlopeChipsOptions: (state, action: PayloadAction<DropdownOption<number>[]>) => {
      state.slopeChipsOptions = action.payload;
    },
    setDirectionChipsOptions: (state, action: PayloadAction<DropdownOption<number>[]>) => {
      state.directionChipsOptions = action.payload;
    },
    setAvailablePanelOrientations: (state, action: PayloadAction<OptionWithAvailability[]>) => {
      state.availablePanelOrientations = action.payload;
    },
    setAvailableRailOrientations: (state, action: PayloadAction<OptionWithAvailability[]>) => {
      state.availableRailOrientations = action.payload;
    },
    setAvailableMountingMaterialManufacturers: (state, action: PayloadAction<OptionWithAvailability[]>) => {
      state.availableMountingMaterialManufacturers = action.payload;
    },
    setIsProductionLoading: (state, action: PayloadAction<boolean>) => {
      state.isProductionLoading = action.payload;
    },
  },
});

export const {
  setSlopeChipsOptions,
  setDirectionChipsOptions,
  setAvailableRailOrientations,
  setAvailablePanelOrientations,
  setAvailableMountingMaterialManufacturers,
  setIsProductionLoading,
} = solarEnergyProjectPageSlice.actions;

export const { reducer: solarEnergyProjectPageReducer } = solarEnergyProjectPageSlice;

export const selectSlopeChipsOptions = (state: AppState) => state.solarEnergyProjectPageReducer.slopeChipsOptions;
export const selectDirectionChipsOptions = (state: AppState) =>
  state.solarEnergyProjectPageReducer.directionChipsOptions;
export const selectAvailablePanelOrientations = (state: AppState) =>
  state.solarEnergyProjectPageReducer.availablePanelOrientations;
export const selectAvailableRailOrientations = (state: AppState) =>
  state.solarEnergyProjectPageReducer.availableRailOrientations;
export const selectAvailableMountingMaterialManufacturers = (state: AppState) =>
  state.solarEnergyProjectPageReducer.availableMountingMaterialManufacturers;

export const selectIsProductionLoading = (state: AppState) => state.solarEnergyProjectPageReducer.isProductionLoading;
