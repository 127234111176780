import { ChangeEventHandler, FC, HTMLInputTypeAttribute } from 'react';
import InputMask, { Props } from 'react-input-mask';
import { InputErrorElement } from '../../input-error-element';
import * as React from 'react';

export type InputWrapperProps = {
  classesForContainer?: string;
  classesInput?: string;
  errorMessage?: string;
  id?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  label: string;
  mask?: Props[`mask`];
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  validateLive?: boolean;
  value?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
};

// eslint-disable-next-line react/display-name
export const InputWrapper: FC<InputWrapperProps> = ({
  classesInput,
  errorMessage,
  id,
  isDisabled,
  isRequired,
  label,
  mask,
  name,
  onChange,
  placeholder,
  type,
  value,
  inputProps = {},
}) => (
  <div className="validated-wrapper fw">
    <div className={`column fw ${errorMessage ? ` error` : ``}${isRequired ? ` required` : ``}`}>
      <label htmlFor={id || name}>{label}</label>
      <InputMask
        data-testid={`${name}-input`}
        className={classesInput || `input-main`}
        disabled={isDisabled}
        id={id || name}
        mask={mask ?? ``}
        maskPlaceholder={null}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
        {...inputProps}
      />
    </div>
    <InputErrorElement errorMsg={errorMessage} />
  </div>
);
