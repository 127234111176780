import { Marker } from '@react-google-maps/api';
import React from 'react';
import { mmToM } from '../utils/calculations';
import { getDistanceMarkerIconStyle } from '../utils/style';
import { useTheme } from '@mui/material/styles';
import { useTranslations } from '@services/hooks/translations/useTranslations';

type DisanceMarkerType = {
  position: google.maps.LatLng;
  distance: number;
  selected: boolean;
  onClick?: () => void;
  firstEdge?: boolean;
  showDistanceMarker: boolean;
};

export const DistanceMarker: React.FC<DisanceMarkerType> = ({
  position,
  distance,
  selected,
  onClick,
  showDistanceMarker,
}) => {
  const theme = useTheme();
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: { mainEdgePrefix },
            },
          },
        },
      },
    },
  } = useTranslations();

  const text = `${showDistanceMarker && selected ? `${translate(mainEdgePrefix)} ` : ``}${showDistanceMarker ? `${mmToM(distance)} m` : ``}`;

  return (
    text && (
      <Marker
        position={position}
        label={{
          text,
          color: `#ffffff`,
        }}
        icon={{
          ...getDistanceMarkerIconStyle({ selected, palette: theme.palette }),
        }}
        zIndex={1}
        onClick={onClick}
      />
    )
  );
};
