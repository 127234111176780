import { FC, ReactNode } from 'react';
import Button from '@mui/material/Button';
import { mdiClose } from '@mdi/js';

import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { ConfirmationPopup } from '@components/ConfirmationPopup';
import { useToggle } from '@hooks/useToggle';

export interface ActionWithConfirmationProps {
  children: string;
  buttonIconPath?: string;
  confirmationPopupTitle: string;
  onConfirm: () => void;
  confirmationPopupContent: ReactNode;
  cancelText?: string;
  confirmText?: string;
  dataTestId?: string;
}

export const ActionWithConfirmation: FC<ActionWithConfirmationProps> = ({
  children,
  buttonIconPath,
  confirmationPopupContent,
  confirmationPopupTitle,
  cancelText,
  confirmText,
  onConfirm,
  dataTestId,
}) => {
  const [isPopupOpen, toggleIsPopupOpen] = useToggle();
  const handleConfirm = () => {
    onConfirm();
    toggleIsPopupOpen();
  };

  return (
    <>
      <Button
        size="small"
        variant="text"
        startIcon={buttonIconPath && <MdiIconWrapper path={mdiClose} />}
        onClick={toggleIsPopupOpen}
        sx={{ flexShrink: 0 }}
        data-testid={dataTestId}
      >
        {children}
      </Button>
      <ConfirmationPopup
        isOpen={isPopupOpen}
        title={confirmationPopupTitle}
        onClose={toggleIsPopupOpen}
        cancelText={cancelText}
        confirmText={confirmText}
        onConfirm={handleConfirm}
        confirmationDataTestId={`${dataTestId}-confirm`}
      >
        {confirmationPopupContent}
      </ConfirmationPopup>
    </>
  );
};
