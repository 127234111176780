import { Edge } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/roofVisualisationTypes';
import { mmToM } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/calculations';
import { clamp } from '@utils/math';
import { useCallback, useEffect, useState } from 'react';

export const MIN_EDGE_MEASUREMENT = 0.5;
export const MAX_EDGE_MEASUREMENT = 150;

export const useEdgeLengthsDataHandler = ({
  edges,
  updateEdgeLength,
}: {
  edges: Edge[];
  updateEdgeLength: (edgeIndex: number, length: number) => void;
}) => {
  const [edgeLengths, setEdgeLengths] = useState<number[]>(edges.map((edge) => mmToM(edge.distance ?? 0)));

  useEffect(() => {
    setEdgeLengths(edges.map((edge) => mmToM(edge.distance ?? 0)));
  }, [edges]);

  /**
   * Only update the form when blurring the input fields, to prevent flickering when typing.
   */
  const getOnBlur = useCallback(
    (edgeIndex: number) => (event: React.FocusEvent<HTMLInputElement>) => {
      const newLength = clamp(Number(event.target.value), MIN_EDGE_MEASUREMENT, MAX_EDGE_MEASUREMENT);
      setEdgeLengths((previousEdgeLengths) => {
        const newEdgeLengths = [...previousEdgeLengths];
        newEdgeLengths[edgeIndex] = newLength;
        updateEdgeLength(edgeIndex, newLength);
        return newEdgeLengths;
      });
    },
    [updateEdgeLength],
  );

  return { edgeLengths, getOnBlur };
};
