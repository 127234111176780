import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { buttonMediumSize } from 'src/styles/theme/theme';

export type TabButtonStyledProps = {
  selected: boolean;
  buttonType?: `primary` | `secondary`;
};
export const TabButtonStyled = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== `buttonType`,
})<TabButtonStyledProps>(({ theme, selected, buttonType = `primary` }) => ({
  backgroundColor: `transparent`,
  boxShadow: selected
    ? `inset 0 -2px 0px ${theme.palette.rexelEnergy.selectedBlue}`
    : buttonType === `secondary`
      ? `inset 0 -1px 0px ${theme.palette.action.disabledBackground}`
      : `none`,
  flex: buttonType === `primary` ? 0 : 1,
  height: buttonMediumSize,
  minWidth: buttonMediumSize,
  aspectRatio: `initial`,
  gap: theme.spacing(1),
  color: selected ? theme.palette.rexelEnergy.selectedBlue : theme.palette.rexelEnergy.ctaBlue,
  transition: `box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;`,
  '& .MuiTypography-root': {
    color: `inherit`,
  },
  '&:hover': {
    boxShadow: selected
      ? `inset 0 -2px 0px ${theme.palette.rexelEnergy.selectedBlue}`
      : `inset 0 -2px 0px ${theme.palette.rexelEnergy.ctaBlue}`,
    '& svg': {
      color: selected ? theme.palette.rexelEnergy.selectedBlue : theme.palette.rexelEnergy.ctaBlue,
    },
  },
  '&.Mui-disabled': {
    color: theme.palette.action.disabledBackground,
    boxShadow: selected
      ? `inset 0 -2px 0px ${theme.palette.action.disabledBackground}`
      : buttonType === `secondary`
        ? `inset 0 -1px 0px ${theme.palette.action.disabledBackground}`
        : `none`,
  },
}));
