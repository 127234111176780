import { useContext, useMemo } from 'react';
import { SolarMapVisualisationContext, SolarMapVisualisationDispatchContext } from '../context';
import { actions, getDispatchOnLoadData, isLoadingPassedState, SolarVisualisationLoadingState } from '../store';
import { calculateMeterInPixels } from '../../calculations';
import { DEFAULT_ZOOM, MAP_ZOOMING_ANIMATION_DURATION, SOLAR_MAP_EDIT_STATE } from '../../constants';
import { MIN_ZOOM_FOR_SHOWING_PANELS, SolarMapPositionType } from '../useSolarMapVisualisation';
import { useSelectedRoofAndPatch } from '../../accessors';
import { useReloadProjectOnMap } from './panelCalculations';
import { AnyAction } from '@reduxjs/toolkit';
import { Patch } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/roofVisualisationTypes';
import { useHandleUpdatePatch } from './patch';

type MapOptionsNativeProps = {
  bounds?: google.maps.LatLngBounds;
  position: SolarMapPositionType;
  disableMapUi?: boolean;
};

type MapOptionsHookProps = {
  mapEditState: SOLAR_MAP_EDIT_STATE;
  map: google.maps.Map | null;
  shapeIsFinishedSquare: boolean;
};
const useMapOptions = ({
  bounds,
  position,
  disableMapUi,
  map,
  mapEditState,
  shapeIsFinishedSquare,
}: MapOptionsNativeProps & MapOptionsHookProps): google.maps.MapOptions => {
  return useMemo(
    () => ({
      zoom: bounds && map ? map?.getZoom() : map?.getZoom() ?? DEFAULT_ZOOM,
      center: map?.getCenter() ?? new window.google.maps.LatLng(position),
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      tilt: 0,
      fullscreenControl: false,
      streetViewControl: false,
      gestureHandling: disableMapUi ? `none` : `cooperative`,
      disableDefaultUI: true,
      draggableCursor: mapEditState === SOLAR_MAP_EDIT_STATE.SHAPE && !shapeIsFinishedSquare ? `crosshair` : undefined,
    }),
    [bounds, disableMapUi, map, mapEditState, position, shapeIsFinishedSquare],
  );
};
export const onLoad = (dispatchOnLoadData: ReturnType<typeof getDispatchOnLoadData>) => (map: google.maps.Map) => {
  dispatchOnLoadData(map, calculateMeterInPixels(map));
};

const onMapClick = (dispatch: React.Dispatch<AnyAction>) => (event: google.maps.MapMouseEvent) => {
  dispatch(actions.onMapClick(event));
};

type OnZoomChangedNativeProps = {
  skipAnimationDuration?: boolean;
};

type OnZoomChangedHookProps = {
  map: google.maps.Map | null;
  loadingState: SolarVisualisationLoadingState;
  lastZoom: number | null;
  dispatch: React.Dispatch<AnyAction>;
  reloadProjectOnMap: () => void;
};
const onZoomChanged =
  ({
    lastZoom,
    map,
    reloadProjectOnMap,
    loadingState,
    skipAnimationDuration,
    dispatch,
  }: OnZoomChangedNativeProps & OnZoomChangedHookProps) =>
  () => {
    if (map && isLoadingPassedState(loadingState, `mapProjectionLoaded`)) {
      const zoom = map.getZoom();
      if (zoom && zoom !== lastZoom) {
        dispatch(actions.setLastZoom(zoom));
        !skipAnimationDuration && dispatch(actions.setHidePanels(true));
        // Set timeout is needed here because the onZoomChanged event is fired in the moment of zoom change,
        // but before the zooming animation ends.
        // We have to wait till the end of the animation,
        // because the projection calculates distances based of the actual look of the map, not zoom value.
        if (skipAnimationDuration) {
          reloadProjectOnMap();
        } else {
          setTimeout(() => {
            reloadProjectOnMap();
            dispatch(actions.setHidePanels((lastZoom ?? DEFAULT_ZOOM) < MIN_ZOOM_FOR_SHOWING_PANELS));
          }, MAP_ZOOMING_ANIMATION_DURATION);
        }
      }
    }
  };

const useOnBoundsChanged = (dispatch: React.Dispatch<AnyAction>) => () => {
  dispatch(actions.onBoundsChanged());
};

const onDragStart = (dispatch: React.Dispatch<AnyAction>) => () => {
  dispatch(actions.onDragStart());
};

const onDragEnd = (dispatch: React.Dispatch<AnyAction>) => () => {
  dispatch(actions.onDragEnd());
};

type UseSolarEnergyMapProps = MapOptionsNativeProps & OnZoomChangedNativeProps;

export const useSolarEnergyMapProps = ({
  disableMapUi,
  skipAnimationDuration,
  position,
  bounds,
}: UseSolarEnergyMapProps) => {
  const { mapEditState, map, loadingState, lastZoom } = useContext(SolarMapVisualisationContext);
  const { selectedPatch } = useSelectedRoofAndPatch();
  const shapeIsFinishedSquare = selectedPatch?.polygon?.getPath().getArray()?.length === 4;
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  const reloadProjectOnMap = useReloadProjectOnMap();

  return {
    options: useMapOptions({ bounds, position, disableMapUi, map, mapEditState, shapeIsFinishedSquare }),
    onZoomChanged: onZoomChanged({ lastZoom, map, reloadProjectOnMap, loadingState, skipAnimationDuration, dispatch }),
    onClick: onMapClick(dispatch),
    onDragStart: onDragStart(dispatch),
    onDragEnd: onDragEnd(dispatch),
    onBoundsChanged: useOnBoundsChanged(dispatch),
    onLoad: onLoad(getDispatchOnLoadData(dispatch)),
  };
};

export const useHandlePolygonLoad = () => {
  const handleUpdatePatch = useHandleUpdatePatch();
  return (patch: Patch) => (newPolygon: google.maps.Polygon) => {
    handleUpdatePatch({ ...patch, polygon: newPolygon });
  };
};

export const useHandleMarkerLoad = () => {
  const handleUpdatePatch = useHandleUpdatePatch();
  return (vertexIndex: number, patch: Patch) => (newMarker: google.maps.Marker) => {
    const newVertices = [...patch.vertices];
    newVertices[vertexIndex] = {
      ...newVertices[vertexIndex],
      marker: newMarker,
    };
    handleUpdatePatch({ ...patch, vertices: newVertices });
  };
};
