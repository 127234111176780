import { useGetPartnerDeductionTemplates } from '@services/api/partners';
import { useCreateProjectDeductionBatch } from '@services/api/projectDeductions/projectDeductionsAdd';
import { useSelector } from 'react-redux';
import { selectCurrentProjectId } from '@redux/selectors/lead';
import { ProjectDeductionTemplateSelection } from './ProjectDeductionTemplateSelection';

type ProjectDeductionTemplateSelectionWrapperProps = {
  onClickNewDeduction: () => void;
  onClose: () => void;
};

export const ProjectDeductionTemplateSelectionWrapper: React.FC<ProjectDeductionTemplateSelectionWrapperProps> = ({
  onClickNewDeduction,
  onClose,
}) => {
  const { partnerDeductionTemplates } = useGetPartnerDeductionTemplates();
  const { addProjectDeductionBatch } = useCreateProjectDeductionBatch();
  const currentProjectId = useSelector(selectCurrentProjectId);

  const onSubmitTemplates = (templateIds: number[]) => {
    onClose();
    if (!currentProjectId) return;
    const projectDeductionsToAdd = partnerDeductionTemplates?.filter((template) => templateIds.includes(template.id));
    addProjectDeductionBatch({ projectDeduction: projectDeductionsToAdd, projectId: currentProjectId });
  };

  return (
    <ProjectDeductionTemplateSelection
      templates={partnerDeductionTemplates}
      onClickNewDeduction={onClickNewDeduction}
      onSubmitTemplates={onSubmitTemplates}
      onClose={onClose}
    />
  );
};
