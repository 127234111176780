import { Box, Stack, Typography } from '@mui/material';
import { Numeric } from '@components/controls/react-hook-form-friendly/smart';
import { DevTool } from '@hookform/devtools';
import { Control } from 'react-hook-form';
import { SolarEnergyReturnOfInvestmentType } from './ReturnForInvestmentForm';
import { LABEL_POSITION } from '@components/controls/react-hook-form-friendly/dumb';
import { useTranslations } from '@services/hooks/translations/useTranslations';

const CHART_FONT_SIZE = 13;

type PricesFormTabProps = {
  control: Control<SolarEnergyReturnOfInvestmentType>;
  formHeight: number;
  purchasePrice: number;
  sellingPrice: number;
  disable?: boolean;
};

export const PricesFormTab: React.FC<PricesFormTabProps> = ({
  control,
  formHeight,
  purchasePrice,
  sellingPrice,
  disable,
}) => {
  const {
    translate,
    translations: {
      common: { units },
      leads: {
        offer: { solarEnergyOffer },
      },
    },
  } = useTranslations();

  return (
    <>
      <Stack component="form" spacing={1} height={formHeight} alignItems="center" justifyContent="center">
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Stack flex={1}>
            <Typography fontSize={CHART_FONT_SIZE}>{translate(solarEnergyOffer.purchased)}</Typography>
            <Typography
              fontSize={CHART_FONT_SIZE}
            >{`${purchasePrice} ${translate(units.currencyCrowns)} / ${translate(units.kiloWattHour)}`}</Typography>
          </Stack>
          <Box flex={1}>
            <Numeric
              classesInput="to-clear"
              classesForContainer="small"
              control={control}
              name="purchasePrice"
              min={0}
              step={0.1}
              precision={1}
              isRefreshable
              labelPosition={LABEL_POSITION.RIGHT}
              isDisabled={disable}
            />
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Stack flex={1}>
            <Typography fontSize={CHART_FONT_SIZE}>{translate(solarEnergyOffer.sold)}</Typography>
            <Typography
              fontSize={CHART_FONT_SIZE}
            >{`${sellingPrice} ${translate(units.currencyCrowns)} / ${translate(units.kiloWattHour)}`}</Typography>
          </Stack>
          <Box flex={1}>
            <Numeric
              classesInput="to-clear"
              classesForContainer="small"
              control={control}
              name="sellingPrice"
              min={0}
              step={0.1}
              precision={1}
              labelPosition={LABEL_POSITION.RIGHT}
              isRefreshable
              isDisabled={disable}
            />
          </Box>
        </Stack>
      </Stack>
      <DevTool control={control} />
    </>
  );
};
