import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { ColumnItem } from '@components/columnLayout/utils';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { LeadContactPersonDto, UpdateLeadContactPersonRequest } from '@generatedTypes/data-contracts';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { FormSection } from '@components/forms/MuiFormSection';
import { requiredEmail } from '@variables/zod';

type ContactPersonEditProps = {
  closeModal: () => void;
  updateContactPerson: (contactPersonDetails: UpdateLeadContactPersonRequest) => void;
  contactPersonDetails: LeadContactPersonDto | null;
  disableForm: boolean;
};

const contactPersonZodObject = {
  email: requiredEmail,
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
};

const contactPersonZodSchema = z.object(contactPersonZodObject);

export type ContactPersonValues = z.infer<typeof contactPersonZodSchema>;

export const ContactPersonEdit: ColumnItem<ContactPersonEditProps> = ({
  closeModal,
  contactPersonDetails,
  updateContactPerson,
  disableForm,
}) => {
  const initialValues: ContactPersonValues = {
    email: contactPersonDetails?.email || ``,
    firstName: contactPersonDetails?.firstName || ``,
    lastName: contactPersonDetails?.lastName || ``,
    phoneNumber: contactPersonDetails?.phoneNumber || ``,
  };

  const {
    translate,
    translations: {
      leads: {
        details: { contactPerson },
      },
    },
  } = useTranslations();

  const { control, reset, handleSubmit } = useForm({
    values: initialValues,
    resolver: zodResolver(contactPersonZodSchema),
  });

  const onCancel = useCallback(() => {
    reset();
    closeModal();
  }, [closeModal, reset]);

  return (
    <>
      <FormWrapper onCancel={onCancel} onSubmit={handleSubmit(updateContactPerson)} disabled={disableForm}>
        <FormSection title={translate(contactPerson.personHeader)}>
          <InputWrapper control={control} name="firstName" label={translate(contactPerson.firstName)} />
          <InputWrapper control={control} name="lastName" label={translate(contactPerson.lastName)} />
          <HorizontalLine />
          <h2>{translate(contactPerson.detailsHeader)}</h2>
          <InputWrapper control={control} name="email" type="email" label={translate(contactPerson.email)} isRequired />
          <InputWrapper control={control} name="phoneNumber" type="tel" label={translate(contactPerson.telephone)} />
        </FormSection>
      </FormWrapper>
    </>
  );
};
