import * as S from './LeftNavLink.styled';
import { LeftNavLinkContentThreeRowsProps } from './types';

export const LeftNavLinkContentThreeRows = ({
  selected,
  title,
  rightBottom,
  rightTop,
  leftBottom,
  leftTop,
  middleRight,
  options,
}: LeftNavLinkContentThreeRowsProps) => {
  return (
    <S.Wrapper selected={selected} isThreeRows>
      <S.LeftTopThreeRows selected={selected} italic={options?.leftTop?.italic}>
        {leftTop}
      </S.LeftTopThreeRows>
      <div>
        {rightTop && (
          <S.RightTopText selected={selected} textAlign="right" fontSize={13} italic={options?.rightTop?.italic}>
            {rightTop}
          </S.RightTopText>
        )}
      </div>
      <div>
        <S.Title selected={selected} variant="body2">
          {title}
        </S.Title>
      </div>
      <S.MiddleRight>
        <S.StyledTypography fontSize={12} selected={selected} textAlign="end" italic={options?.middleRight?.italic}>
          {middleRight}
        </S.StyledTypography>
      </S.MiddleRight>
      <S.leftBottomThreeRows isBottomRight={Boolean(rightBottom)}>
        {leftBottom && (
          <S.StyledTypography selected={selected} fontSize={13} italic={options?.leftBottom?.italic}>
            {leftBottom}
          </S.StyledTypography>
        )}
      </S.leftBottomThreeRows>
      {rightBottom && (
        <div>
          <S.StyledTypography textAlign="right" selected={selected} fontSize={14} italic={options?.rightBottom?.italic}>
            {rightBottom}
          </S.StyledTypography>
        </div>
      )}
    </S.Wrapper>
  );
};
