import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { AddressDto } from '@generatedTypes/data-contracts';
import { MainPointMarker } from '@components/maps/GoogleMaps/Markers/MainPointMarker';
import { AddressPointMarker } from '@components/maps/GoogleMaps/Markers/AddressPointMarker';
import { CenterPointMarker } from '@components/maps/GoogleMaps/Markers/CenterPointMarker';
import { setMapEditState } from '@redux/reducers/slices/leadsPage';
import { useDispatch } from 'react-redux';

const containerStyle = {
  width: `100%`,
  height: `100%`,
};

const FOUND_LOCATION_ZOOM_LEVEL = 18;
const DEFAULT_MAP_VIEW_ANGLE = 0;

export type GoogleMapsCompProps = {
  address: AddressDto;
  disbaleMapUi?: boolean;
  OnMapPrimaryButtons?: React.ReactNode;
  OnMapSecondaryButtons?: (props: { map: google.maps.Map | null }) => React.ReactNode;
};

const GoogleMapComp = ({ disbaleMapUi, address, OnMapSecondaryButtons, OnMapPrimaryButtons }: GoogleMapsCompProps) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const mapRef = useRef<any>(null);
  const addressPosition = useMemo(() => ({ lat: address?.latitude || 0, lng: address?.longitude || 0 }), [address]);
  const precisePosition = useMemo(
    () => ({ lat: address?.indicatedLatitude || 0, lng: address?.indicatedLongitude || 0 }),
    [address],
  );
  const mainPosition = address?.indicatedLatitude && address?.indicatedLongitude ? precisePosition : addressPosition;
  const bounds = useMemo(() => new google.maps.LatLngBounds(mainPosition), [mainPosition]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMapEditState(`VIEW`));
  }, [address, dispatch]);

  const onLoad = useCallback(
    function callback(map: google.maps.Map) {
      mapRef.current = map;
      map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
      map.setTilt(DEFAULT_MAP_VIEW_ANGLE);
      map.setZoom(FOUND_LOCATION_ZOOM_LEVEL);
      map.setCenter(mainPosition);

      setMap(map);
    },
    [mainPosition],
  );
  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const handleZoomChanged = () => {
    if (!mapRef.current) return;
    mainPosition && mapRef.current?.setCenter(mainPosition);
    bounds && mapRef.current?.panToBounds(bounds);
  };

  const googleMapOptions: google.maps.MapOptions = useMemo(
    () => ({
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      draggable: true,
      gestureHandling: `cooperative`,
      disableDefaultUI: disbaleMapUi,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
    }),
    [disbaleMapUi],
  );

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onZoomChanged={handleZoomChanged}
      options={googleMapOptions}
      ref={mapRef}
    >
      {OnMapPrimaryButtons}
      {OnMapSecondaryButtons && <OnMapSecondaryButtons map={map} />}
      <MainPointMarker position={mainPosition} />
      <AddressPointMarker />
      <CenterPointMarker />
    </GoogleMap>
  );
};

export const GoogleMaps = memo(GoogleMapComp);
