import { authFetch, getErrorFromFormRequest } from '../utils';
import {
  CreatePartnerRequest,
  DeductionTemplateDto,
  PartnerDto,
  PartnerRowDto,
  PartnerUserRowDto,
  ProjectAdditionTemplateDto,
  UpdatePartnerRequest,
} from '@generatedTypes/data-contracts';
import { useQueryClient } from 'react-query';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { DELETE, PARTNER, PARTNER_LOGO, UPDATE } from '@variables/queryKeys';

export type TemplateType = keyof Pick<
  PartnerDto,
  | `offerIntroTextTemplate`
  | `carChargingProjectOfferTextTemplate`
  | `solarEnergyProjectOfferTextTemplate`
  | `emptyProjectOfferTextTemplate`
  | `energyStorageProjectOfferTextTemplate`
>;

export const fetchPartners = async () => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/partners`, {
    method: `GET`,
    mode: `cors`,
  });
  if (response.ok) {
    const json = await response.json();
    return json as PartnerRowDto[];
  }
  throw new Error(response.statusText);
};

export const fetchPartner = async (id: number) => {
  if (id > 0) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/partners/${id}`, {
      method: `GET`,
      mode: `cors`,
    });
    if (response.ok) {
      const json = await response.json();
      return json as PartnerDto;
    }
    throw new Error(response.statusText);
  }
  return null;
};

export const fetchPartnerUsers = async (id: number | null) => {
  if (id && id > 0) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/partners/${id}/users`, {
      method: `GET`,
      mode: `cors`,
    });
    if (response.ok) {
      const json = await response.json();
      return json as PartnerUserRowDto[];
    }
    throw new Error(response.statusText);
  }
  return null;
};

export const fetchPartnerAdditionTemplates = async (id: number | null) => {
  if (id && id > 0) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/partners/${id}/project-addition-templates`, {
      method: `GET`,
      mode: `cors`,
    });
    if (response.ok) {
      const json = await response.json();
      return json as ProjectAdditionTemplateDto[];
    }
    throw new Error(response.statusText);
  }
  return null;
};

export const fetchPartnerDeductionTemplates = async (id: number | null) => {
  if (id && id > 0) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/partners/${id}/deduction-templates`, {
      method: `GET`,
      mode: `cors`,
    });
    if (response.ok) {
      const json = await response.json();
      return json as DeductionTemplateDto[];
    }
    throw new Error(response.statusText);
  }
  return null;
};

export const postPartner = async (partner: CreatePartnerRequest) =>
  await authFetch(`${process.env.PROTECTED_API_URL}/partners`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(partner),
  })
    .then(async (res) => {
      if (!res.clone().ok) {
        const json = await res.clone().json();
        throw json;
      }
      return (await res.json()) as number;
    })
    .catch((error) => {
      throw getErrorFromFormRequest(error);
    });

export interface PutPartner extends UpdatePartnerRequest {
  id: number;
}

export const putPartner = async ({ id, ...partner }: PutPartner) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/partners/${id}`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify(partner),
  });
  if (!response.ok) {
    const error = await getErrorFromFormRequest(response);
    throw error;
  }
};

export const uploadLogoForPartner = async ({ partnerId, file }: { partnerId: number; file: File }) => {
  const formData = new FormData();
  formData.append(`file`, file);

  return authFetch(`${process.env.PROTECTED_API_URL}/partners/${partnerId}/logotype`, {
    method: `PUT`,
    mode: `cors`,
    body: formData,
    contentType: `multipart/form-data`,
  })
    .then(async (res) => {
      if (!res.clone().ok) {
        throw await res.clone().json();
      }
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const deleteLogoForPartner = async (partnerId: number) =>
  authFetch(`${process.env.PROTECTED_API_URL}/partners/${partnerId}/logotype`, {
    method: `DELETE`,
    mode: `cors`,
    contentType: ``,
    accept: ``,
  })
    .then(async (res) => {
      if (!res.clone().ok) {
        const json = await res.clone().json();
        throw json;
      }
    })
    .catch((error) => {
      throw new Error(error);
    });

export const deletePartner = async (partnerId: number) =>
  authFetch(`${process.env.PROTECTED_API_URL}/partners/${partnerId}`, {
    method: `DELETE`,
    mode: `cors`,
    contentType: ``,
    accept: ``,
  })
    .then(async (res) => {
      if (!res.clone().ok) {
        const json = await res.clone().json();
        throw json;
      }
    })
    .catch((error) => {
      throw new Error(error);
    });

export const useUpdatePartnerLogo = (options?: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();
  const data = useCustomMutation({
    mutationKey: [UPDATE, PARTNER_LOGO],
    mutationFn: uploadLogoForPartner,
    onSuccess: () => {
      queryClient.invalidateQueries(PARTNER);
      options?.onSuccess();
    },
  });

  return {
    isLoading: data.isLoading,
    mutate: data.mutate,
    validationErrors: data.validationErrors,
    parsedBackendError: data.parsedBackendError,
  };
};

export const useDeletePartnerLogo = (options?: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();
  const data = useCustomMutation({
    mutationKey: [DELETE, PARTNER_LOGO],
    mutationFn: deleteLogoForPartner,
    onSuccess: () => {
      queryClient.invalidateQueries(PARTNER);
      options?.onSuccess();
    },
  });

  return {
    isLoading: data.isLoading,
    mutate: data.mutate,
    validationErrors: data.validationErrors,
    parsedBackendError: data.parsedBackendError,
  };
};
