import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import { Chip, ChipProps as ChipPropsDumb } from '../dumb/chip';
import classNames from 'classnames';
import Stack from '@mui/material/Stack';

export interface ChipListProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  chips: Omit<ChipPropsDumb, `checked` | `name` | `onChange` | `version`>[];
  version?: ChipPropsDumb[`version`];
  defaultValue?: PathValue<T, Path<T>>;
  wrapChips?: boolean;
  label?: string;
  boldLabel?: boolean;
  disabled?: boolean;
}

export const ChipList = <T extends FieldValues>({
  chips,
  control,
  name,
  version = `small`,
  defaultValue,
  wrapChips = false,
  label,
  boldLabel,
  disabled,
}: ChipListProps<T>) => {
  const { field } = useController({ control, name, defaultValue });

  if (!chips.length) return null;

  return (
    <Stack spacing={0}>
      {label && <label className={classNames({ bold: boldLabel }, `margin-bottom-10`, `display-block`)}>{label}</label>}
      <Stack spacing={1} direction="row" flexWrap={wrapChips ? `wrap` : `nowrap`} className="chips-container">
        {chips.map((chip) => (
          <Chip
            key={`${name}-${chip.value}`}
            {...chip}
            onClick={field.onBlur}
            checked={String(field.value) === String(chip.value)}
            name={field.name}
            onChange={field.onChange}
            version={version}
            type="radio"
            disabled={chip.disabled || disabled}
          />
        ))}
      </Stack>
    </Stack>
  );
};
