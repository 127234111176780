import { JsonWebToken, UserType } from '@generatedTypes/data-contracts';
import { useEffect, useState } from 'react';
import { store } from '@redux/store';
import { Features, setExpirationDate, setIsLoggedIn, setLoggedInUserData } from '@redux/reducers/slices/user';

type TokenType = {
  jti: string;
  userId: string;
  userType: UserType;
  isAdminUser: string;
  partnerId: string;
  userFirstname: string;
  userLastname: string;
  enabledFeatures: string;
  nbf: number;
  exp: number;
  iss: string;
  aud: string;
};

export const parseJsonWebTokenToTokenObject = (jsonToken: JsonWebToken): TokenObject | null => {
  let newTokenObject = null;
  if (jsonToken && typeof jsonToken === `object`) {
    newTokenObject = {
      token: jsonToken?.token ?? ``,
      expires: Number(jsonToken?.expires || NaN) * 1000,
    };
  }
  return isTokenObject(newTokenObject) ? newTokenObject : null;
};

export function isTokenObject(tokenObject: unknown): tokenObject is TokenObject {
  return (
    !!tokenObject &&
    typeof tokenObject === `object` &&
    Object.hasOwn(tokenObject, `token`) &&
    Object.hasOwn(tokenObject, `expires`) &&
    typeof (tokenObject as TokenObject).token === `string` &&
    (tokenObject as TokenObject).token !== `` &&
    typeof (tokenObject as TokenObject).expires === `number` &&
    !isNaN((tokenObject as TokenObject).expires)
  );
}

export type TokenObject = {
  token: string;
  expires: number;
};

export function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem(`token`);
    const expiryTime = Number(localStorage.getItem(`expires`));
    const isExpired = expiryTime < new Date().getTime();

    if (isExpired) {
      localStorage.removeItem(`token`);
      localStorage.removeItem(`expires`);

      return null;
    }

    return tokenString && expiryTime
      ? {
          token: tokenString,
          expires: expiryTime,
        }
      : null;
  };

  const [token, setToken] = useState(getToken());

  useEffect(() => {
    let expirationDate: number | null = null;
    let userId: number | null = null;
    let partnerId: number | null = null;
    let userType: UserType = UserType.None;
    let enabledFeatures: Features[] = [];
    let firstName: string | undefined;
    let lastName: string | undefined;
    let isAdmin: boolean | undefined;
    if (isTokenObject(token)) {
      expirationDate = token.expires;
      const decodedTokenStringData = window.atob(encodeURIComponent(token.token).split(`.`)[1]);
      const tokenData = JSON.parse(decodedTokenStringData) as TokenType;
      userId = Number(tokenData.userId);
      partnerId = Number(tokenData.partnerId);
      userType = tokenData.userType;
      enabledFeatures = tokenData.enabledFeatures?.split(`,`) as Features[];
      firstName = tokenData.userFirstname;
      lastName = tokenData.userLastname;
      isAdmin = tokenData.isAdminUser === `True`;
    }
    store.dispatch(setExpirationDate(expirationDate));
    store.dispatch(
      setLoggedInUserData({
        userId,
        partnerId: isNaN(Number(partnerId)) ? null : partnerId,
        userType,
        enabledFeatures,
        firstName,
        lastName,
        isAdmin,
      }),
    );
  }, [token]);

  const saveToken = (userTokenObject: TokenObject | null) => {
    if (isTokenObject(userTokenObject)) {
      store.dispatch(setIsLoggedIn(true));
      localStorage.setItem(`token`, userTokenObject.token);
      localStorage.setItem(`expires`, JSON.stringify(userTokenObject.expires));
      setToken(userTokenObject);
    } else {
      localStorage.removeItem(`token`);
      localStorage.removeItem(`expires`);
      setToken(null);
    }
  };

  return {
    setToken: saveToken,
    token,
  };
}
