import { components, DropdownIndicatorProps } from 'react-select';
import Icons from '@assets/svg/svgDefs.svg';

export function DropdownIndicator<T>(props: DropdownIndicatorProps<T>) {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <svg className="icon icon-medium option-icon">
          <use href={`${Icons}#chevron-down`} />
        </svg>
      </components.DropdownIndicator>
    )
  );
}
