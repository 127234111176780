import {
  DEDUCTION_TEMPLATES,
  DELETE,
  PARTNER,
  PARTNERS,
  PROJECT_ADDITION_TEMPLATES,
  UPDATE,
  USER,
  USERS,
} from '@variables/queryKeys';
import { useQueryClient } from 'react-query';
import {
  deletePartner,
  fetchPartner,
  fetchPartnerUsers,
  fetchPartners,
  postPartner,
  putPartner,
  fetchPartnerAdditionTemplates,
  fetchPartnerDeductionTemplates,
} from './partners';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { VIEW_TYPES, showNext } from '@components/columnLayout/utils';
import { selectIsPartnerUser } from '@redux/selectors/user';
import { useNavigate, useParams } from 'react-router-dom';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { selectPartnerId } from '@redux/reducers/slices/user';
import { setSelectedPartnerId } from '@redux/reducers/slices/partner';

export const useGetPartners = (options?: { enabled?: boolean }) => {
  const isPartnerUser = useSelector(selectIsPartnerUser);
  const { data, isLoading, error } = useCustomQuery({
    queryKey: [PARTNERS],
    queryFn: fetchPartners,
    enabled: !isPartnerUser,
  });
  return {
    partners: data ?? [],
    isFetchingPartners: isLoading,
    errorFetchingPartners: error as Error | undefined,
    enabled: options?.enabled,
  };
};

export const useGetPartner = (options?: { partnerId?: number }) => {
  const { partnerId: partnerIdParams } = useParams<{ partnerId: string }>();
  // I am nit sure why I put it but I will keep it for now.
  const userPartnerId = useSelector(selectPartnerId);
  const partnerId = options?.partnerId ?? userPartnerId ?? Number(partnerIdParams);
  const isPartnerId = partnerId > 0;

  const { data, isLoading, error } = useCustomQuery({
    queryKey: [PARTNER, partnerId],
    queryFn: () => fetchPartner(partnerId),
    enabled: isPartnerId,
  });

  return {
    partner: data || null,
    isFetchingPartner: isLoading,
    errorFetchingPartner: error as Error | undefined,
  };
};

export const useGetPartnerUsers = () => {
  const { partnerId: partnerIdParams } = useParams<{ partnerId: string }>();
  const partnerId = useSelector(selectPartnerId);
  const isPartnerUser = useSelector(selectIsPartnerUser);

  const selectedPartnerId = isPartnerUser ? partnerId : Number(partnerIdParams);

  const { data, isLoading, error } = useCustomQuery({
    queryKey: [PARTNER, USERS, selectedPartnerId],
    queryFn: () => fetchPartnerUsers(selectedPartnerId),
    enabled: (partnerId ?? Number(partnerIdParams)) > -1,
  });

  return {
    partnerUsers: data || [],
    isFetchingPartnerUsers: isLoading,
    errorFetchingPartnerUsers: error as Error | undefined,
  };
};

export const useGetPartnerProjectAdditionsTemplates = () => {
  const { partnerId: partnerIdParams } = useParams<{ partnerId: string }>();
  const partnerId = useSelector(selectPartnerId);
  const isPartnerUser = useSelector(selectIsPartnerUser);

  const selectedPartnerId = isPartnerUser ? partnerId : Number(partnerIdParams);

  const { data, isLoading, error } = useCustomQuery({
    queryKey: [PARTNER, PROJECT_ADDITION_TEMPLATES, selectedPartnerId],
    queryFn: () => fetchPartnerAdditionTemplates(selectedPartnerId),
    enabled: (partnerId ?? Number(partnerIdParams)) > -1,
  });

  return {
    partnerProjectAdditionsTemplates: data || [],
    isFetchingPartnerProjectAdditionsTemplates: isLoading,
    errorFetchingPartnerProjectAdditionsTemplates: error as Error | undefined,
  };
};

export const useGetPartnerDeductionTemplates = () => {
  const { partnerId: partnerIdParams } = useParams<{ partnerId: string }>();
  const partnerId = useSelector(selectPartnerId);
  const isPartnerUser = useSelector(selectIsPartnerUser);

  const selectedPartnerId = isPartnerUser ? partnerId : Number(partnerIdParams);

  const { data, isLoading, error } = useCustomQuery({
    queryKey: [PARTNER, DEDUCTION_TEMPLATES, selectedPartnerId],
    queryFn: () => fetchPartnerDeductionTemplates(selectedPartnerId),
    enabled: (partnerId ?? Number(partnerIdParams)) > -1,
  });

  return {
    partnerDeductionTemplates: data || [],
    isFetchingPartnerDeductionTemplates: isLoading,
    errorFetchingPartnerDeductionTemplates: error as Error | undefined,
  };
};

export const useCreatePartner = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { mutate, isLoading, validationErrors, parsedBackendError } = useCustomMutation({
    mutationKey: [PARTNER],
    mutationFn: postPartner,
    onSuccess: (createdPartnerId) => {
      queryClient.invalidateQueries(PARTNERS);
      dispatch(setSelectedPartnerId(createdPartnerId));
      queryClient.invalidateQueries(PARTNER);
      showNext(VIEW_TYPES.INFO)();
      navigate(`/partners/${createdPartnerId}`);
    },
  });

  return {
    isLoading,
    mutate,
    validationErrors,
    parsedBackendError,
  };
};

export const useDeletePartner = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, validationErrors, parsedBackendError } = useCustomMutation({
    mutationKey: [DELETE, USER],
    mutationFn: deletePartner,
    onSuccess: () => {
      queryClient.invalidateQueries(PARTNERS);
      showNext(VIEW_TYPES.LIST)();
    },
  });

  return {
    isLoading,
    mutate,
    validationErrors,
    parsedBackendError,
  };
};

export const useUpdatePartner = (options?: { onSuccess?: () => void }) => {
  const { mutate, isLoading, validationErrors, parsedBackendError } = useCustomMutation({
    mutationKey: [UPDATE, PARTNER],
    mutationFn: putPartner,
    onSuccess: options?.onSuccess,
  });

  return {
    isLoading,
    mutate,
    validationErrors,
    parsedBackendError,
  };
};
