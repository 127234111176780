import { DealerRowDto } from '@generatedTypes/data-contracts';
import { TAction } from '@redux/reducers';
import { DealersState } from '@redux/reducers/dealers';

export const DEALERS_ACTIONS = {
  DEALERS_LIST: `DEALERS_LIST`,
  SELECTED_DEALER_ID: `SELECTED_DEALER_ID`,
  SELECTED_DEALER_DETAILS: `SELECTED_DEALER_DETAILS`,
  SELECTED_DEALER_USERS: `SELECTED_DEALER_USERS`,
} as const;

export type DealersAction = TAction<typeof DEALERS_ACTIONS, DealersState>;

export const setDealersList = (value: DealerRowDto[] | null): DealersAction => ({
  type: DEALERS_ACTIONS.DEALERS_LIST,
  payload: { dealersList: value || [] },
});

export const setSelectedDealerID = (value = -1): DealersAction => ({
  type: DEALERS_ACTIONS.SELECTED_DEALER_ID,
  payload: { selectedDealerID: value },
});
