import { authFetch } from '@services/api/utils';
import { ProjectProductTemplateDto } from '@generatedTypes/data-contracts';
import { SOLAR_ENERGY_PROJECT_TEMPLATES } from '@variables/queryKeys';
import { mapAttributeRowToOption } from '@pages/NewLeads/Projects/solarEnergyProject/utils';
import { useCustomQuery } from '@hooks/useCustomQuery';

const fetchSolarEnergyProjectTemplates = async () => {
  return authFetch(`${process.env.PROTECTED_API_URL}/solar-energy-projects/product-templates`, {
    method: `GET`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    })
    .then((json) => json as ProjectProductTemplateDto[])
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

export const useGetSolarEnergyProjectTemplates = () => {
  const { data } = useCustomQuery({
    queryFn: fetchSolarEnergyProjectTemplates,
    queryKey: SOLAR_ENERGY_PROJECT_TEMPLATES,
    staleTime: Infinity,
  });
  return (data ?? []).map(mapAttributeRowToOption);
};
