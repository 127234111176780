import { FC } from 'react';
import { Control } from 'react-hook-form';
import { LeadZodSchema } from './zodSettings';
import { ContactDetails } from '@components/controls/react-hook-form-friendly/smart/ContactDetails';

export interface PrivateSubformProps {
  control: Control<LeadZodSchema>;
}

export const PrivateSubform: FC<PrivateSubformProps> = ({ control }) => <ContactDetails control={control} />;
