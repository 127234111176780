import { Typography } from '@mui/material';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { OnMapButtonStyled } from '@components/maps/GoogleMaps/OnMapButton.styled';

type OnMapButtonProps = {
  icon: string;
  onClick: () => void;
  label?: string;
  disabled?: boolean;
};
export const OnMapButton: React.FC<OnMapButtonProps> = ({ onClick, label, icon, disabled }) => {
  return (
    <OnMapButtonStyled onClick={onClick} id="on-map-button" disabled={disabled}>
      <MdiIconWrapper path={icon} />
      {label && <Typography variant="subtitle2">{label}</Typography>}
    </OnMapButtonStyled>
  );
};
