import { EditSubmitButtons } from '@components/editSubmitRow/editSubmitRow';
import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface SlideInViewFormWrapperProps {
  header?: string;
  onClose?: () => void;
  onSubmit?: () => void;
  children: ReactNode;
  isDisabled?: boolean;
}

export const SlideInViewFormWrapper: React.FC<SlideInViewFormWrapperProps> = ({
  children,
  header,
  onSubmit,
  onClose,
  isDisabled,
}) => {
  return (
    <>
      {header && (
        <Stack direction="row" justifyContent="space-between" className="slide-in-view-header hide-sm" padding={2}>
          <Typography variant="h2">{header}</Typography>
        </Stack>
      )}
      <Box className="slide-in-view-content-wrapper">
        <Stack gap={2}>
          {children}
          {onClose && onSubmit && (
            <section className="grid-container w980-layout">
              <Box className="edit-submit-buttons-wrapper col-12 col-desktop-offset-3 col-desktop-8" marginBlockEnd={4}>
                <EditSubmitButtons onSubmit={onSubmit} onClose={onClose} disabledSubmit={isDisabled} />
              </Box>
            </section>
          )}
        </Stack>
      </Box>
    </>
  );
};
