import { TabButton } from '@components/button/TabButton';
import { mdiAngleAcute, mdiSelectGroup } from '@mdi/js';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import React from 'react';
import { Patch } from '../../roofVisualisationTypes';
import { getNumberOfSelectedPanels, useSelectedPatchAngleValue } from '../utils';
import { useHandleSelectPatch } from '../../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/navigation';

export type SelectionGroupStateButtonsProps = {
  patches: Patch[];
};

export const SelectionGroupStateButtons: React.FC<SelectionGroupStateButtonsProps> = ({ patches }) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: { panelSettingsHeader },
            },
          },
        },
      },
    },
  } = useTranslations();
  const onSelectPatch = useHandleSelectPatch();

  const angle = useSelectedPatchAngleValue();

  return (
    <Stack spacing={3} marginBlockEnd={1}>
      <Typography variant="h2">{translate(panelSettingsHeader)}</Typography>
      <Stack direction="row">
        {patches.map(({ id, panels }) => (
          <TabButton
            key={id}
            alt="Edit patch"
            onClick={onSelectPatch(id)}
            title={getNumberOfSelectedPanels(panels)}
            icon={mdiSelectGroup}
            buttonType="secondary"
          />
        ))}
        <TabButton icon={mdiAngleAcute} alt="Select group" buttonType="secondary" selected title={angle} />
      </Stack>
    </Stack>
  );
};
