import React, { useContext, useMemo } from 'react';
import { SOLAR_MAP_EDIT_STATE } from '../../utils/constants';
import { CustomMapControlsContainer } from './CustomMapControlsContainer';
import { OnMapPanelsStateButton } from './OnMapPanelsStateButton';
import { OnMapRoofStateButton } from './OnMapRoofStateButtons';
import { OnMapShapeStateButton } from './OnMapShapeStateButtons';
import { SolarMapVisualisationContext } from '../../utils/useSolarMapVisualisation/context';
import { useSelectedRoofAndPatch } from '../../utils/accessors';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/SolarEnergyProject';
import {
  useHandleCreatePatch,
  useHandleRemovePatch,
} from '../../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/patch';

export const OnMapButtons: React.FC = () => {
  const { solarEnergyProject, mapEditState } = useContext(SolarMapVisualisationContext);
  const { selectedRoofIndex } = useSelectedRoofAndPatch();
  const formFunctions = useFormContext<SolarEnergyProjectValues>();
  const { remove, append } = useFieldArray<SolarEnergyProjectValues, `roofs.${number}.patches`>({
    control: formFunctions?.control,
    name: `roofs.${selectedRoofIndex}.patches`,
  });
  const selectedRoofHasNoPatches = solarEnergyProject.roofs[selectedRoofIndex]?.patches?.length === 0;
  const handleCreatePatch = useHandleCreatePatch()(append);
  const handleRemovePatch = useHandleRemovePatch()(remove);

  const buttons = useMemo(() => {
    switch (mapEditState) {
      case SOLAR_MAP_EDIT_STATE.BASE:
        return null;
      case SOLAR_MAP_EDIT_STATE.ROOF:
        return (
          selectedRoofHasNoPatches && (
            <OnMapRoofStateButton onCreatePatch={handleCreatePatch} animate={selectedRoofHasNoPatches} />
          )
        );
      case SOLAR_MAP_EDIT_STATE.SHAPE:
        return <OnMapShapeStateButton onRemovePatch={handleRemovePatch} />;
      case SOLAR_MAP_EDIT_STATE.PANELS:
        return <OnMapPanelsStateButton onRemovePatch={handleRemovePatch} />;
      default:
        return null;
    }
  }, [mapEditState, handleCreatePatch, handleRemovePatch, selectedRoofHasNoPatches]);

  return <CustomMapControlsContainer>{buttons}</CustomMapControlsContainer>;
};
