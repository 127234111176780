import { styled } from '@mui/material/styles';
import { IconButton, Typography } from '@mui/material';

export const FileInputLabel = styled(`label`)(({ theme }) => ({
  minHeight: theme.spacing(6),
  border: `1px solid ${theme.palette.rexelEnergy.ctaBlue}`,
  backgroundColor: `white`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `center`,
  position: `relative`,
  color: theme.palette.rexelEnergy.ctaBlue,
  cursor: `pointer`,
  padding: 0,
  textWrap: `nowrap`,
}));

export const FileInputFileName = styled(Typography)(({ theme }) => ({
  maxWidth: `80%`,
  marginLeft: theme.spacing(2),
  overflow: `hidden`,
  textOverflow: `ellipsis`,
  whiteSpace: `nowrap`,
}));

export const FileInputResetButton = styled(IconButton)({
  marginLeft: `auto`,
});

export const FileInputInput = styled(`input`)({
  position: `absolute`,
  opacity: 0,
  zIndex: -1,
});
