import Bold from '@assets/svg/richTextEditorIcons/bold.svg';
import Italic from '@assets/svg/richTextEditorIcons/italic.svg';
import Underline from '@assets/svg/richTextEditorIcons/underline.svg';
import List from '@assets/svg/richTextEditorIcons/list.svg';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import React, { ChangeEvent, HTMLInputTypeAttribute, useEffect, useState } from 'react';
import { InputErrorElement } from '@components/controls/input-error-element';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './rich-text-input-wrapper.css';

const parseEmptyLinesToWhiteSpace = (html: string) => html.replaceAll(`<p></p>`, `<p>&zwnj;</p>`);

export type RichTextWrapperProps = {
  name: string;
  value?: string | number;
  label: string;
  type?: HTMLInputTypeAttribute;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  classesForContainer?: string;
  classesInput?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  validateLive?: boolean;
  errorMsg?: string;
  placeholder?: string;
};

export const RichTextInputWrapper: React.FC<RichTextWrapperProps> = (props) => {
  const [previousName, setPreviousName] = useState(props.name);
  const [previousValue, setPreviousValue] = useState(props.value);
  const [initialValueLoaded, setInitialValueLoaded] = useState(false);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    let shouldCreateNewEditor = false;
    if (!initialValueLoaded) {
      setPreviousValue(undefined);
      setInitialValueLoaded(true);
      shouldCreateNewEditor = true;
    }
    const isNewValue = props.value !== previousValue;

    if (isNewValue || shouldCreateNewEditor) {
      setPreviousValue(props.value);
      setInitialValueLoaded(true);
      const blocksFromHTML = convertFromHTML(String(props.value ?? ``));
      const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      const newEditorState = EditorState.createWithContent(contentState);

      setEditorState(newEditorState);
    }
  }, [initialValueLoaded, previousName, previousValue, props.name, props.value]);

  useEffect(() => {
    const fieldChanged = props.name !== previousName;
    if (fieldChanged) {
      setInitialValueLoaded(false);
      setPreviousName(props.name);
    }
  }, [previousName, props.name]);

  useEffect(() => {
    const html = parseEmptyLinesToWhiteSpace(convertToHTML(editorState.getCurrentContent()));

    if ((props.value === `` || typeof props.value === `undefined`) && html === `<p></p>`) {
      return;
    }
    props.onChange?.({ target: { value: html, name: props.name } } as ChangeEvent<HTMLInputElement>);
    setPreviousValue(html);
    // TODO: check full list of useEffect dependencies to add or remove
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState, props.name]);

  return (
    <div className="validated-wrapper fw">
      <div className={`column fw ${props.errorMsg ? ` error` : ``}${props.isRequired ? ` required` : ``}`}>
        <label htmlFor={props.name}>{props.label}</label>
        <Editor
          placeholder={props.placeholder}
          editorState={editorState}
          onEditorStateChange={setEditorState}
          readOnly={props.isDisabled}
          wrapperClassName={props.isDisabled ? `disabled` : ``}
          editorClassName={`rdw-editor`}
          toolbar={
            props.isDisabled
              ? { options: [] }
              : {
                  options: [`inline`, `list`],
                  inline: {
                    options: [`bold`, `italic`, `underline`],
                    bold: {
                      icon: Bold,
                    },
                    italic: {
                      icon: Italic,
                    },
                    underline: {
                      icon: Underline,
                    },
                  },
                  list: {
                    options: [`unordered`],
                    unordered: {
                      icon: List,
                    },
                  },
                }
          }
          customStyleMap={{
            BOLD: {
              fontFamily: `var(--fonts-family-bold)`,
            },
          }}
        />
      </div>
      <InputErrorElement errorMsg={props.errorMsg} />
    </div>
  );
};
