import { Card } from '@components/Card/Card';
import { useToggle } from '@hooks/useToggle';
import { mdiPlus } from '@mdi/js';
import { useCreateDealerUser, useGetDealerUsers } from '@services/api/dealers';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { ListItem } from '@components/ListMui/ListItem';
import { NewUser } from '@pages/Users/new-user';
import { useSelector } from 'react-redux';
import { selectIsUserAdmin } from '@redux/reducers/slices/user';
import { EditUser } from '@pages/Users/EditUser';
import { useDeleteUser, useGetUser, useUpdateUser } from '@services/api/users';
import { useState } from 'react';
import { DEALERS, USERS } from '@variables/queryKeys';
import { useQueryClient } from 'react-query';
import { UserType } from '@generatedTypes/data-contracts';
import { useParams } from 'react-router-dom';

export const DealerUsersTab = () => {
  const {
    translate,
    translations: {
      common: { administrator },
      dealer: { info },
    },
  } = useTranslations();
  const { dealerId } = useParams<{ dealerId: string }>();
  const queryClient = useQueryClient();
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(undefined);

  const canModifyUsers = useSelector(selectIsUserAdmin);

  const [showNewUserForm, toggleShowNewUserForm] = useToggle();
  const [isEditUserOpen, toggleIsEditUserOpen] = useToggle();

  const { user } = useGetUser({ userId: String(selectedUserId), userType: UserType.Dealer });

  const { dealerUsers } = useGetDealerUsers();

  const createDealerUser = useCreateDealerUser({ onSuccess: toggleShowNewUserForm });
  const updateUser = useUpdateUser({
    onSuccess: () => {
      queryClient.invalidateQueries(DEALERS);
      queryClient.invalidateQueries(USERS);
    },
  });
  const deleteUser = useDeleteUser({
    onSuccess: () => {
      queryClient.invalidateQueries(DEALERS);
      queryClient.invalidateQueries(USERS);
    },
  });

  return (
    <>
      <Card
        title={translate(info.allUsers)}
        titleAction={canModifyUsers ? toggleShowNewUserForm : undefined}
        titleActionIcon={canModifyUsers ? mdiPlus : undefined}
        titleActionTestId="create-new-dealer-user"
      >
        <List>
          {dealerUsers.map((user) => (
            <ListItem
              key={user.id}
              title={`${user.firstName} ${user.lastName}`}
              subtitle={user.email}
              secondaryAction={user.isAdmin ? translate(administrator) : undefined}
              onClick={() => {
                setSelectedUserId(user.id);
                toggleIsEditUserOpen();
              }}
            />
          ))}
        </List>
      </Card>
      {canModifyUsers && (
        <>
          <Drawer open={showNewUserForm} onClose={toggleShowNewUserForm} anchor="right">
            <NewUser
              createUser={createDealerUser.mutate}
              disableForm={createDealerUser.isLoading}
              beValidationResults={createDealerUser.validationErrors}
              userTypeMode={`Dealer`}
              onNewUserExit={toggleShowNewUserForm}
              hideAssignments
              predefinedUser={{
                userType: UserType.Dealer,
                dealerId: Number(dealerId),
              }}
            />
          </Drawer>
          <Drawer anchor="right" open={isEditUserOpen} onClose={toggleIsEditUserOpen}>
            <EditUser
              updateUser={(values) => {
                updateUser.mutate(values);
                toggleIsEditUserOpen();
              }}
              deleteUser={() => {
                deleteUser.mutate(user);
                toggleIsEditUserOpen();
              }}
              user={user}
              disableForm={updateUser.isLoading}
              closeModal={toggleIsEditUserOpen}
              hideAssignments
            />
          </Drawer>
        </>
      )}
    </>
  );
};
