import {
  ProductAttributeDataType,
  ProductAttributeType,
  ProductAttributeValueRowDto,
  ProductCategoryType,
  ProjectType,
} from '@generatedTypes/data-contracts';
import { CarChargingProject } from '@pages/NewLeads/project/carChargingProject/carChargingProject';
import {
  calculateProductCountBaseOnSelection,
  filterSelectedProductAttributes,
} from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/productsAttributeFilters/utils/externalComponentsUtils/carChargingUtils';
import { selectCarChargingProductGroups, selectCurrentOfferBidId } from '@redux/selectors/lead';
import {
  useDeleteCarChargingProject,
  usePostCarChargingProject,
  usePutCarChargingProject,
} from '@services/api/carChargingProjects/carChargingProjects';
import { useGetCarChargingSitesForLead } from '@services/api/leads/lead-car-charging-site';
import { useGetOffer } from '@services/api/offers/offers';
import { useGetSubscriptions } from '@services/api/subscriptions/subscriptions';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormInputs as CarChargingFormInputs } from '@pages/NewLeads/project/carChargingProject/carChargingProject';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';

type ProductAttributeValueRowWithCounter = ProductAttributeValueRowDto & { counter: number };
export type CarChargingResultProductCount = {
  carChargingPowerProductCount?: ProductAttributeValueRowWithCounter[];
  carChargingRouterAndSwitchProductCount?: ProductAttributeValueRowWithCounter[];
};

export interface CarChargingFormProps {
  onClose: () => void;
}

export const CarCharginForm = ({ onClose }: CarChargingFormProps) => {
  const pushDataLayer = useAnalytics();
  const [productResultCounter, setProductResultCounter] = useState<CarChargingResultProductCount | null>(null);
  const carChargingProductRows = useSelector(selectCarChargingProductGroups);
  const currentProjectID = useSelector(selectCurrentOfferBidId);
  const { lead, isOfferLocked } = useGetOffer();
  const { subscriptions } = useGetSubscriptions();
  const { carChargingSites } = useGetCarChargingSitesForLead();
  const { postCarCharginProjectAsync, isCreateCarChargingProjectLoading, createCarChargingError } =
    usePostCarChargingProject();
  const { putCarChargingProjectAsync, isUpdateingCarChargingProject, updateCarChargingError } =
    usePutCarChargingProject();
  const { deleteCarChargingProjectAsync } = useDeleteCarChargingProject();

  const carChargerSelectedAttributes = useMemo(
    () =>
      filterSelectedProductAttributes({
        selectedProductCategoryType: ProductCategoryType.CarCharger,
        productCategoryRows: carChargingProductRows,
        selectedProductAttributesTypes: [
          ProductAttributeType.CarChargerSocketsPerCharger,
          ProductAttributeType.CarChargerSocketPower,
        ],
      }),
    [carChargingProductRows],
  );

  const setChargerProductsCounter = useCallback(
    (carChargerId: number) => {
      if (!carChargerSelectedAttributes) {
        return;
      }
      const { id, name } = carChargerSelectedAttributes;
      const carChargingPowerProductCount = calculateProductCountBaseOnSelection({
        filterProductCategoryRows: carChargerSelectedAttributes,
        selectedProductAttributeRow: {
          type: ProductAttributeType.CarChargerSocketsPerCharger,
          values: [
            {
              id: Number(carChargerId),
            },
          ],
          name,
          id,
          dataType: ProductAttributeDataType.List,
        },
      });

      const firstProductWithNoZeroCounter = carChargingPowerProductCount?.find((charger) => charger.counter !== 0)?.id;

      setProductResultCounter((results) => ({
        ...results,
        carChargingPowerProductCount,
      }));

      return firstProductWithNoZeroCounter;
    },
    [carChargerSelectedAttributes],
  );

  const routerAndSwitchSelectedAttributes = useMemo(
    () =>
      filterSelectedProductAttributes({
        selectedProductCategoryType: ProductCategoryType.RouterAndSwitch,
        productCategoryRows: carChargingProductRows,
        selectedProductAttributesTypes: [ProductAttributeType.RouterSwitchType, ProductAttributeType.RouterSwitchPorts],
      }),
    [carChargingProductRows],
  );

  const setCarChargingRouterPortsCounter = useCallback(
    (carChargingRouterTypeId: number) => {
      if (!routerAndSwitchSelectedAttributes) {
        return;
      }
      const { name, id } = routerAndSwitchSelectedAttributes;
      const carChargingRouterAndSwitchProductCount = calculateProductCountBaseOnSelection({
        filterProductCategoryRows: routerAndSwitchSelectedAttributes,
        selectedProductAttributeRow: {
          type: ProductAttributeType.RouterSwitchType,
          values: [
            {
              id: Number(carChargingRouterTypeId),
            },
          ],
          name,
          id,
          dataType: ProductAttributeDataType.List,
        },
      });
      const firstProductWithNoZeroCounter = carChargingRouterAndSwitchProductCount?.find(
        (switchPorts) => switchPorts.counter !== 0,
      )?.id;

      setProductResultCounter((results) => ({
        ...results,
        carChargingRouterAndSwitchProductCount,
      }));

      return firstProductWithNoZeroCounter;
    },
    [routerAndSwitchSelectedAttributes],
  );

  const onAddEditProjectSubmit = (values: CarChargingFormInputs) => {
    pushDataLayer({
      event: AnalyticEvents.saveProject,
      projectType: ProjectType.CarCharging,
    });
    if (currentProjectID) {
      putCarChargingProjectAsync({
        ...values,
        id: currentProjectID,
        subscriptionId: values.subscriptionId,
        projectProductTemplateId: values.projectProductTemplateId,
      }).then(() => {
        onClose();
      });
    } else {
      postCarCharginProjectAsync({
        ...values,
        leadId: lead?.id ?? -1,
        subscriptionId: values.subscriptionId,
        projectProductTemplateId: values.projectProductTemplateId,
      }).then(() => {
        onClose();
      });
    }
  };

  return (
    <CarChargingProject
      onSubmit={onAddEditProjectSubmit}
      onDelete={(id) => deleteCarChargingProjectAsync(id).then(() => onClose())}
      onClose={() => {
        onClose();
        pushDataLayer({ event: AnalyticEvents.cancelProject, projectType: ProjectType.CarCharging });
      }}
      projectDetails={carChargingSites}
      leadPropertyDetails={lead?.propertyInfo ?? null}
      beValidationResults={createCarChargingError || updateCarChargingError}
      isDisabled={isCreateCarChargingProjectLoading || isUpdateingCarChargingProject || isOfferLocked}
      subscriptions={subscriptions}
      layout="full-width"
      productResultCounter={productResultCounter}
      setCarChargingPowerCount={setChargerProductsCounter}
      setCarChargingRouterPortsCounter={setCarChargingRouterPortsCounter}
    />
  );
};
