import { styled } from '@mui/material/styles';
import { theme } from '../../styles/theme/theme';
import Stack from '@mui/material/Stack';

const propsWithNoForward = [`buttonsShadow`, `disablePadding`];
const shouldForwardProp = (prop: string) => !propsWithNoForward.includes(prop);

export interface StyledFormWrapperStyles {
  width?: string | number;
  disablePadding?: boolean;
  whiteBackground?: boolean;
  buttonsShadow?: boolean;
}

export const defaultWidthForm = `391px`;

export const StyledFormWrapper = styled(`div`, {
  shouldForwardProp,
})<StyledFormWrapperStyles>`
  padding: ${({ disablePadding }) => (disablePadding ? `0 0 ${theme.spacing(2)}` : `${theme.spacing(3, 3, 0)}`)};
  width: ${({ width = defaultWidthForm }) => width};
  flex: 1;
  overflow: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    max-width: 100%;
    width: auto;
  }
`;

export const StyledButtonWrapper = styled(Stack, {
  shouldForwardProp,
})<StyledFormWrapperStyles>`
  padding: ${theme.spacing(2, 3)};
  ${({ buttonsShadow = true }) => buttonsShadow && `box-shadow: 0px -2px 4px 0px #00000014;`}
  width: ${({ width = defaultWidthForm }) => width};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    max-width: 100%;
    width: auto;
  }
`;
