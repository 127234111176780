import { DealerDto, DealerRowDto, DealerUserRowDto } from '@generatedTypes/data-contracts';
import { DealersAction, DEALERS_ACTIONS } from '@redux/actions/dealers';

export type DealersState = {
  dealersList: DealerRowDto[];
  selectedDealerID: number | null;
  selectedDealerDetails: DealerDto | null;
  selectedDealerUsers: DealerUserRowDto[] | null;
};

export const initState: DealersState = {
  dealersList: [],
  selectedDealerID: null,
  selectedDealerDetails: null,
  selectedDealerUsers: [],
};

export const DealersReducer = (state = initState, action: DealersAction) => {
  switch (action.type) {
    case DEALERS_ACTIONS.DEALERS_LIST:
      return { ...state, dealersList: action.payload.dealersList ?? [] };
    case DEALERS_ACTIONS.SELECTED_DEALER_ID:
      return { ...state, selectedDealerID: action.payload.selectedDealerID ?? initState.selectedDealerID };
    case DEALERS_ACTIONS.SELECTED_DEALER_DETAILS:
      return { ...state, selectedDealerDetails: action.payload.selectedDealerDetails ?? null };
    case DEALERS_ACTIONS.SELECTED_DEALER_USERS:
      return { ...state, selectedDealerUsers: action.payload.selectedDealerUsers ?? [] };
    default:
      return state;
  }
};
