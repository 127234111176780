import { ColumnLayoutContent } from '@components/Layout/ColumnLayout/ColumnLayoutContent';
import { PageHeader } from '@components/PageHeader/PageHeader';
import { useDeleteUser, useGetUser } from '@services/api/users';
import { UserDetailsInfo } from './UserDetailsInfo';
import { UserProfilePicture } from './UserProfilePicture';
import Stack from '@mui/material/Stack';
import { ResetUserPassword } from './ResetUserPassword';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { ActionWithConfirmation } from '@components/ActionWithConfirmation';
import { mdiClose } from '@mdi/js';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserId } from '@redux/reducers/slices/user';

export const UserDetails = () => {
  const navigate = useNavigate();
  const {
    translate,
    translations: {
      users: {
        details: { deletePopup },
      },
      common: {
        buttons: { delete: deleteText },
      },
    },
  } = useTranslations();
  const loggedInUserId = useSelector(selectUserId);
  const { user } = useGetUser();
  const deleteUser = useDeleteUser();
  const disableDelete = loggedInUserId === user?.id;

  return (
    <ColumnLayoutContent
      isOpen
      header={
        <PageHeader
          title={`${user?.firstName} ${user?.lastName}`}
          actions={
            disableDelete ? undefined : (
              <ActionWithConfirmation
                onConfirm={() => {
                  deleteUser.mutate(user);
                  navigate(`/users`);
                }}
                confirmationPopupTitle={translate(deletePopup.header)}
                confirmationPopupContent={translate(deletePopup.content)}
                buttonIconPath={mdiClose}
              >
                {translate(deleteText)}
              </ActionWithConfirmation>
            )
          }
        />
      }
    >
      <Stack gap={2} className="cards__wrapper">
        <UserDetailsInfo user={user} />
        <UserProfilePicture user={user} />
        {user && <ResetUserPassword user={user} />}
      </Stack>
    </ColumnLayoutContent>
  );
};
