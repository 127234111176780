import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import { ChipImage, ChipImageProps as ChipImagePropsDumb } from '../dumb/ChipImage';
import Stack, { StackProps } from '@mui/material/Stack';
import { useCallback } from 'react';
import { Typography } from '@mui/material';

export type ChipListChipType = Omit<ChipImagePropsDumb, `selected` | `onClick`>;
export interface ChipListProps<T extends FieldValues> {
  chips: ChipListChipType[];
  control: Control<T>;
  name: Path<T>;
  wrapChips?: boolean;
  disabled?: boolean;
  defaultValue?: PathValue<T, Path<T>>;
  gap?: number;
  direction?: StackProps[`direction`];
  wrap?: StackProps[`flexWrap`];
  title?: string;
  onChipClick?: (value: ChipListChipType[`value`]) => void;
}

export const ChipImageList = <T extends FieldValues>({
  chips,
  control,
  name,
  disabled,
  defaultValue,
  gap = 1,
  direction = `row`,
  wrap = `wrap`,
  title,
  onChipClick,
}: ChipListProps<T>) => {
  const { field } = useController({ control, name, defaultValue });

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      field.onBlur();
      field.onChange(Number(e.currentTarget.id));
      onChipClick?.(Number(e.currentTarget.id));
    },
    [field, onChipClick],
  );

  if (!chips.length) return null;

  return (
    <div>
      {title && <Typography variant="body1">{title}</Typography>}
      <Stack gap={gap} direction={direction} flexWrap={wrap} sx={{ mt: title && 0.5 }}>
        {chips.map((chip) => (
          <ChipImage
            key={chip.value}
            {...chip}
            onClick={handleClick}
            selected={field.value == chip.value}
            disabled={disabled || chip.disabled}
          />
        ))}
      </Stack>
    </div>
  );
};
