import { useController, FieldValues, Control, Path } from 'react-hook-form';
import { InputWrapper as InputWrapperDumb, InputWrapperProps as InputWrapperPropsDumb } from '../dumb';
import { useTranslations } from '@services/hooks/translations/useTranslations';

export interface InputWrapperProps<T extends FieldValues>
  extends Omit<InputWrapperPropsDumb, `onChange` | `name` | `value`> {
  control: Control<T>;
  name: Path<T>;
}

export const InputWrapper = <T extends FieldValues>({ name, control, ...props }: InputWrapperProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name });

  const {
    translate,
    translations: { validationErrors },
  } = useTranslations();

  return (
    <InputWrapperDumb
      errorMessage={translate(validationErrors[error?.message as keyof typeof validationErrors]) || error?.message}
      name={field.name}
      onChange={field.onChange}
      value={field.value}
      {...props}
    />
  );
};
