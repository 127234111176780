import { ChangeEvent, FocusEvent, HTMLInputTypeAttribute, KeyboardEvent } from 'react';
import './InputSuffix.css';

export type InputSuffixProps = {
  name: string;
  value?: string | number;
  defaultValue?: string | number;
  label?: string | React.ReactNode;
  suffix: string;
  type: HTMLInputTypeAttribute;
  classesForContainer?: string;
  classesInput?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  min?: number;
  max?: number;
};

export const InputSuffix: React.FC<InputSuffixProps> = ({
  name,
  value,
  defaultValue,
  label,
  suffix,
  type,
  onChange,
  classesForContainer,
  classesInput,
  isDisabled = false,
  isRequired = false,
  onBlur,
  onKeyUp,
  onFocus,
  min,
  max,
}) => (
  <div className={`input-suffix ${classesForContainer}`}>
    <label className={`${isDisabled ? `disabled` : ``}`} htmlFor={`${name}-${value}`}>
      {label}
    </label>
    <div className="input-group">
      <input
        type={type}
        name={name}
        id={`${name}-${value}`}
        className={`${classesInput} ${isDisabled ? `disabled` : ``}`}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={isDisabled}
        onBlur={onBlur}
        required={isRequired}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        min={min}
        max={max}
      />
      {suffix ? (
        <div className={`suffix ${isDisabled ? `disabled` : ``}`}>
          <span>{suffix}</span>
        </div>
      ) : null}
    </div>
  </div>
);
