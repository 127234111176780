import { useEffect } from 'react';
import { addScript } from '@components/external/TouchPointFeedback/addScript';
import { TouchPointFeedback } from './TouchPointFeedback';
import './TouchPointFeedback.css';

export const PageTouchPointWrapper = () => {
  useEffect(() => {
    addScript(`https://panelista.com/embeds/widgets/641d5fc21e9914dfb2e5cfa9.js`);
    addScript(`https://panelista.com/embeds/widget.js`);
  }, []);

  return (
    <div className={`touch-point-feedback no-print`}>
      <TouchPointFeedback />
    </div>
  );
};
