import { ReactNode, forwardRef } from 'react';
import * as S from './LeftNavLink.styled';

export interface LeftNavLinkWrapperProps {
  children: ReactNode;
  href: string;
  selected?: boolean;
  isThreeRows?: boolean;
}

export const LeftNavLinkWrapper = forwardRef<HTMLAnchorElement, LeftNavLinkWrapperProps>(
  ({ href, children, selected, isThreeRows }, ref) => (
    <S.StyledLink ref={ref} to={href} selected={selected} isThreeRows={isThreeRows}>
      {children}
    </S.StyledLink>
  ),
);

LeftNavLinkWrapper.displayName = `LeftNavLink`;
