import { useGetOfferBasketItemsHash } from '@services/api/offers/offers';
import { useCallback } from 'react';

const getBasketPath = (offerHash: string, itemsHash?: string) =>
  `/basket/${offerHash}` + (itemsHash ? `?itemsHash=${itemsHash}` : ``);
export const getFullBasketUrl = (offerHash: string, itemsHash?: string) =>
  `${window.location.protocol + `//`}${window.location.host}${getBasketPath(offerHash, itemsHash)}`;
export const useGetCartUrlForExcludedProducts = () => {
  const { isLoadingOfferBasketItemsHash, fetchOfferBasketItemsHashAsync } = useGetOfferBasketItemsHash();

  return useCallback(
    async (offerHash: string, excludedIds: number[]) => {
      const itemsHash = await fetchOfferBasketItemsHashAsync(excludedIds);
      return {
        fullUrl: getFullBasketUrl(offerHash, itemsHash),
        basketPath: getBasketPath(offerHash, itemsHash),
        isLoading: isLoadingOfferBasketItemsHash,
      };
    },
    [fetchOfferBasketItemsHashAsync, isLoadingOfferBasketItemsHash],
  );
};
