import { AppState } from '@redux/reducers';
import { createSelector } from 'reselect';

const selectGlobal = (state: AppState) => state.Global;

export const selectIsMobile = createSelector(selectGlobal, (global) => global.isMobileView);

export const selectLastLocation = createSelector(selectGlobal, (global) => global.lastLocation);

export const selectPreviousLocation = createSelector(selectGlobal, (global) => global.previousLocation);

export const selectPreviousPageView = createSelector(
  (state: AppState) => state.Global,
  (global) => global.previousPageView,
);

export const selectCurrentLeadID = createSelector(selectGlobal, (global) => global.currentLeadID);

export const selectChosenLanguage = createSelector(selectGlobal, (global) => global.chosenLanguage);
