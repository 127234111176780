import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TooltipOptions,
  AnimationOptions,
  SubTitle,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, SubTitle);

interface Data {
  labels: string[];
  datasets: {
    label?: string;
    data: number[];
    backgroundColor: string;
  }[];
}

export interface BarChartProps {
  data: Data;
  tooltipOptions?: Partial<TooltipOptions<`bar`>[`callbacks`]>;
  animation?: AnimationOptions<`bar`>[`animation`];
  barPercentage?: number;
  borderRadius?: number;
  showXScale?: boolean;
  showYScale?: boolean;
  showLegend?: boolean;
  title?: string;
  subtitle?: string;
  maxBarThickness?: number;
  yTicksCallback?: CategoryScale[`options`][`ticks`][`callback`];
  yTicksDash?: number[];
}

export const BarChart = ({
  data,
  animation = false,
  barPercentage,
  borderRadius,
  showXScale,
  showYScale,
  showLegend,
  tooltipOptions,
  title,
  subtitle,
  maxBarThickness,
  yTicksCallback,
  yTicksDash,
}: BarChartProps) => (
  <Bar
    options={{
      responsive: true,
      maintainAspectRatio: false,
      animation,
      datasets: { bar: { barPercentage, borderRadius, maxBarThickness } },
      scales: {
        x: {
          border: { display: showXScale },
          ticks: { display: showXScale },
          grid: { display: showXScale, drawTicks: showXScale },
        },
        y: {
          border: {
            display: showYScale,
            dash: yTicksDash,
          },
          ticks: {
            display: showYScale,
            callback: yTicksCallback,
          },
          grid: { drawTicks: showYScale },
        },
      },
      plugins: {
        subtitle: { display: !!subtitle, text: subtitle, position: `bottom`, padding: 8 },
        title: {
          display: !!title,
          text: title,
        },
        legend: { display: showLegend },
        tooltip: {
          position: `nearest`,
          callbacks: tooltipOptions,
        },
      },
    }}
    data={data}
  />
);
