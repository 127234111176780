import { FC } from 'react';
import { mdiLinkVariant } from '@mdi/js';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import Button from '@mui/material/Button';

interface CopyLinkButtonProps {
  url: string;
  children?: string;
  disabled?: boolean;
}

export const CopyLinkButton: FC<CopyLinkButtonProps> = ({ url, children, disabled }) => {
  const {
    translate,
    translations: {
      common: { copyLink },
    },
  } = useTranslations();
  const handleCopy = () => {
    navigator.clipboard.writeText(url);
  };
  return (
    <Button
      disabled={disabled}
      startIcon={<MdiIconWrapper path={mdiLinkVariant} />}
      fullWidth
      onClick={handleCopy}
      variant="outlined"
    >
      {children || translate(copyLink)}
    </Button>
  );
};
