import { SolarEnergyProductionDto } from '@generatedTypes/data-contracts';
import { authFetch, parseObjectToStringValues } from '@services/api/utils';
import { usePost } from '@services/hooks/api/useGet';

export type SolarEnergyProjectPatchProductionProps = {
  latitude: number;
  longitude: number;
  solarPanelManufacturerId: number;
  numberOfSolarPanels: number;
  angle: number;
  roofId?: number;
};

export const fetchSolarEnergyProjectPatchProduction = async (
  props: SolarEnergyProjectPatchProductionProps,
  signal?: AbortSignal,
): Promise<SolarEnergyProductionDto> => {
  const url =
    `${process.env.PROTECTED_API_URL}/solar-energy-projects/solar-energy-production?` +
    new URLSearchParams(parseObjectToStringValues(props));
  const response = await authFetch(url, {
    method: `GET`,
    mode: `cors`,
    signal,
  });
  const json = await response.json();
  if (response.ok) {
    return json as SolarEnergyProductionDto;
  }
  return { yearlyProduction: 0, peakPower: 0, yearlyProductionInkWh: 0 };
};

export const useFetchSolarEnergyProjectPatchProduction = usePost(fetchSolarEnergyProjectPatchProduction);
