import { authFetch } from '@services/api/utils';
import { CarChargingProjectSettingsDto } from '@generatedTypes/data-contracts';
import { useQuery } from 'react-query';
import { CAR_CHARGING_PROJECT_SETTINGS } from '@variables/queryKeys';
import { useSelector } from 'react-redux';
import { selectFeatureEnabled } from '@redux/reducers/slices/user';

const fetchCarChargingProjectSettings = async () => {
  return authFetch(`${process.env.PROTECTED_API_URL}/car-charging-projects/settings`, {
    method: `GET`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    })
    .then((json) => json as CarChargingProjectSettingsDto)
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

const defaultCarChargingProjectSettings: Partial<CarChargingProjectSettingsDto> = {
  carChargerMounting: undefined,
  carChargerPlacement: undefined,
  carChargerSocketPower: undefined,
  carChargerSocketsPerCharger: undefined,
  routerSwitchPorts: undefined,
  routerSwitchType: undefined,
};

export const useGetCarChargingProjectSettings = () => {
  const carChargingFeatureEnabled = useSelector(selectFeatureEnabled(`car-charging-project`));
  const { data: solarEnergyProjectSettings } = useQuery<Partial<CarChargingProjectSettingsDto>>(
    CAR_CHARGING_PROJECT_SETTINGS,
    fetchCarChargingProjectSettings,
    {
      staleTime: Infinity,
      enabled: carChargingFeatureEnabled,
    },
  );
  return solarEnergyProjectSettings ?? defaultCarChargingProjectSettings;
};
