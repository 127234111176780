import { useForm } from 'react-hook-form';
import { ColumnItem } from '@components/columnLayout/utils';
import { PutPartner } from '@services/api/partners/partners';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback } from 'react';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { parsePartnerForUpdate } from '@services/api/partners/utils';
import { ParsedBackendValidationResults } from '@components/controls/validations';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { FormSection } from '@components/forms/MuiFormSection';
import { MuiNumberInput } from '@components/controls/react-hook-form-friendly/smart/MuiNumberInput';
import InputAdornment from '@mui/material/InputAdornment';
import { parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';

type EconomySettingsEditProps = {
  disableForm: boolean;
  goBackHandler: () => void;
  updatePartner: (partner: PutPartner) => void;
  partner: PartnerDto | null;
  beValidationResults: ParsedBackendValidationResults | null;
};

export const EconomySettingsEdit: ColumnItem<EconomySettingsEditProps> = ({
  disableForm,
  goBackHandler,
  partner,
  updatePartner,
}) => {
  const {
    translate,
    translations: {
      myAccount: {
        economySettings: {
          hourlyRate: { header, hourlyRateLabel },
        },
      },
    },
  } = useTranslations();

  const { handleSubmit, reset, control } = useForm({
    defaultValues: {
      netCostPerHour: partner?.netCostPerHour ?? 0,
    },
  });

  const onCancel = useCallback(() => {
    goBackHandler();
    reset();
  }, [goBackHandler, reset]);

  return (
    <FormWrapper
      onCancel={onCancel}
      onSubmit={handleSubmit((values) => {
        if (partner) {
          updatePartner({
            ...parsePartnerForUpdate(partner),
            netCostPerHour: parseInputValueToSubmitNumberValue(values.netCostPerHour),
          });
          goBackHandler();
        }
      })}
      disabled={disableForm}
    >
      <FormSection title={translate(header)}>
        <MuiNumberInput
          name="netCostPerHour"
          control={control}
          label={translate(hourlyRateLabel)}
          InputProps={{
            endAdornment: <InputAdornment position="end">{`kr`}</InputAdornment>,
          }}
        />
      </FormSection>
    </FormWrapper>
  );
};
