import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  color: ${({ theme }) => theme.palette.rexelEnergy.blackBlue};
  background-color: transparent;
  min-height: 24px;
  width: 24px;
  height: 24px;

  &:hover {
    background-color: transparent;
  }

  &:hover path {
    color: ${({ theme }) => theme.palette.rexelEnergy.ctaBlue};
  }
`;
