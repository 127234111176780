import { LeadDto } from '@generatedTypes/data-contracts';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '@redux/reducers';
import { createSelector } from 'reselect';

type MapEditState = `VIEW` | `SET_NEW_POSITION` | `EDIT_POSITION`;

export interface LeadsPageState {
  mapEditState: MapEditState;
  isEditLeadFormOpen: boolean;
  isNewLeadFormOpen: boolean;
  selectedLead?: LeadDto;
  priceVisibility: boolean;
}

// README: this is a begining of rewriting old reducers to redux toolkit,
// TODO: make a task for rewriting leads.ts to this file, it will not be that easy because of types interference

export const initialState: LeadsPageState = {
  mapEditState: `VIEW`,
  isNewLeadFormOpen: false,
  isEditLeadFormOpen: false,
  priceVisibility: true,
};

const leadsPageSlice = createSlice({
  name: `leads-page`,
  initialState,
  reducers: {
    setIsEditLeadFormOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditLeadFormOpen = action.payload;
    },
    setIsNewLeadFormOpen: (state, action: PayloadAction<boolean>) => {
      state.isNewLeadFormOpen = action.payload;
    },
    setSelectedLead: (state, action: PayloadAction<LeadDto | undefined>) => {
      state.selectedLead = action.payload;
    },
    setMapEditState: (state, action: PayloadAction<MapEditState>) => {
      state.mapEditState = action.payload;
    },
    setPriceVisibility: (state, action: PayloadAction<boolean>) => {
      state.priceVisibility = action.payload;
    },
  },
});

export const { setIsNewLeadFormOpen, setSelectedLead, setIsEditLeadFormOpen, setMapEditState, setPriceVisibility } =
  leadsPageSlice.actions;
export const { reducer: leadsPageReducer } = leadsPageSlice;

export const selectLeadsPageState = (state: AppState) => state.leadsPageReducer;

export const selectMapEditState = createSelector(selectLeadsPageState, (leadsPage) => leadsPage.mapEditState);

export const selectPriceVisibility = createSelector(selectLeadsPageState, (leadsPage) => leadsPage.priceVisibility);
