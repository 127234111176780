import { useEffect, useRef } from 'react';
import { useGetBasketData } from '@services/api/offerAgreements/offerAgreements';

const webshopUrl = `https://www.rexel.se/swe/getHtmlFormContent`;

export const BasketForm: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { basketData, isLoading } = useGetBasketData();

  useEffect(() => {
    if (formRef.current && !isLoading && basketData) {
      formRef.current.submit();
    }
  }, [basketData, isLoading]);

  if (!basketData) return null;

  return (
    <form method="post" action={webshopUrl} name="Interface" ref={formRef}>
      <input type="HIDDEN" name="NumCli" id="NumCli" value={basketData.NumCli} />
      <input type="HIDDEN" name="LstRefQte" id="IstRefQte" value={basketData.LstRefQte} />
      <input type="HIDDEN" name="FlagLIV" id="FlagLIV" value={basketData.FlagLIV} />
      <input type="HIDDEN" name="FlagRAZ" id="FlagRAZ" value={basketData.FlagRAZ} />
      <input type="HIDDEN" name="flagGroupingID" id="flagGroupingID" value={basketData.FlagGroupingID} />
      <input type="HIDDEN" name="campaignName" id="flagGroupingID" value={basketData.CampaignName} />
    </form>
  );
};
