import { formatCurrency, parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';
import { OfferPriceCard } from './OfferPriceCard';
import { Dropdown } from '@components/Dropdown/Dropdown';
import { useToggle } from '@hooks/useToggle';
import { useGetOffer } from '@services/api/offers/offers';
import { isOfferLocked } from '@pages/NewLeads/Projects/solarEnergyProject/utils';
import { SlideInView } from '@components/slideInView/slideInView';
import { OfferPriceOverviewPage } from './OfferPriceOverviewPage';
import { useEditOfferPriceOverview, useGetOfferPriceOverview } from '@services/api/offers/priceOverview';
import { useSelector } from 'react-redux';
import { selectIsPartnerUser } from '@redux/reducers/slices/user';

export const PriceDropdown: React.FC = () => {
  const [isDropdownOpen, toggleIsDropdownOpen] = useToggle();
  const [isEditOfferPriceVisible, toggleIsEditPriceVisible] = useToggle();
  const { offerPriceOverview } = useGetOfferPriceOverview();
  const { offer } = useGetOffer();
  const { editOfferPriceOverview, isEditOfferPriceOverviewLoading } = useEditOfferPriceOverview();

  const offerLocked = isOfferLocked(offer);
  const isPartnerUser = useSelector(selectIsPartnerUser);

  const handleUpdateRounding = (priceRounding: number) => {
    if (offer?.id) {
      const bids =
        offerPriceOverview?.bids?.map(({ id, margin, deductions }) => ({
          id,
          margin: margin.value,
          deductions,
        })) ?? [];
      editOfferPriceOverview({
        bids,
        priceRounding: parseInputValueToSubmitNumberValue(priceRounding),
        offerId: offer.id,
      });
    }
  };

  if (!offer) return null;

  return (
    <>
      <Dropdown
        isDropdownOpen={isDropdownOpen}
        toggleIsDropdownOpen={toggleIsDropdownOpen}
        buttonText={formatCurrency(offerPriceOverview?.totalNetPrice ?? 0)}
        complexMenu
        transformLeft
      >
        <OfferPriceCard
          projectTypes={offer.bids.map((bid) => bid.type).join(`, `)}
          offerPriceOverview={offerPriceOverview}
          onShowOfferPriceDetails={() => {
            toggleIsDropdownOpen();
            toggleIsEditPriceVisible();
          }}
          offerId={offer.hashedId}
          onUpdateRounding={handleUpdateRounding}
          isDisabled={isEditOfferPriceOverviewLoading}
          offerLocked={offerLocked}
        />
      </Dropdown>
      {isPartnerUser && (
        <SlideInView isVisible={isEditOfferPriceVisible}>
          <OfferPriceOverviewPage onClose={toggleIsEditPriceVisible} isDisabled={offerLocked} />
        </SlideInView>
      )}
    </>
  );
};
