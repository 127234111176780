import { SlopeDefaults, SolarPanelsDirections } from '@assets/translations/translations';
import { Translation } from '@services/hooks/translations/useTranslations';
import {
  ProductAttributeType,
  RoofMaterialAttributeValueDto,
  SolarEnergyProjectSettingsDto,
} from '@generatedTypes/data-contracts';
import { DropdownOption } from '@hooks/useForm/useFormTypes';
import { mapAttributeRowToOption } from '@pages/NewLeads/Projects/solarEnergyProject/utils';

export const getSlopeChipsOptions = (translate: (translation: Translation) => string, translations: SlopeDefaults) => [
  {
    value: 4,
    label: `${translate(translations.flat)} 4°`,
  },
  {
    value: 22,
    label: `${translate(translations.lowSlope)} 22°`,
  },
  {
    value: 27,
    label: `${translate(translations.normal)} 27°`,
  },
  {
    value: 33,
    label: `${translate(translations.steep)} 33°`,
  },
  {
    value: 45,
    label: `${translate(translations.verySteep)} 45°`,
  },
];

export const getDirectionChipsOptions = (
  translate: (translation: Translation) => string,
  translations: SolarPanelsDirections,
) => [
  {
    value: 0,
    label: `${translate(translations.N)} 0°`,
  },
  {
    value: 45,
    label: `${translate(translations.N)}${translate(translations.E)} 45°`,
  },
  {
    value: 90,
    label: `${translate(translations.E)} 90°`,
  },
  {
    value: 135,
    label: `${translate(translations.S)}${translate(translations.E)} 135°`,
  },
  {
    value: 180,
    label: `${translate(translations.S)} 180°`,
  },
  {
    value: 225,
    label: `${translate(translations.S)}${translate(translations.W)} 225°`,
  },
  {
    value: 270,
    label: `${translate(translations.W)} 270°`,
  },
  {
    value: 315,
    label: `${translate(translations.N)}${translate(translations.W)} 315°`,
  },
];

const mapRoofMaterialChipsOptions = (roofMaterial: RoofMaterialAttributeValueDto): DropdownOption<number> => ({
  value: Number(roofMaterial.id),
  label: String(roofMaterial.name),
});

export const getOptionsFromSettings = (settings?: Partial<SolarEnergyProjectSettingsDto> | undefined | null) => {
  if (!settings) {
    return null;
  }
  const options: Partial<Record<ProductAttributeType, DropdownOption<number>[]>> = {};
  options[ProductAttributeType.SolarPanelManufacturer] =
    settings.solarPanelManufacturer?.values?.map(mapAttributeRowToOption) ?? [];
  options[ProductAttributeType.SolarPanelOrientation] =
    settings.solarPanelOrientation?.values?.map(mapAttributeRowToOption) ?? [];
  options[ProductAttributeType.RoofMaterial] = settings.roofMaterial?.values?.map(mapRoofMaterialChipsOptions) ?? [];
  options[ProductAttributeType.MountingMaterialManufacturer] =
    settings.mountingMaterialManufacturer?.values?.map(mapAttributeRowToOption) ?? [];
  options[ProductAttributeType.RailOrientation] = settings.railOrientation?.values?.map(mapAttributeRowToOption) ?? [];
  return options;
};
