import { ColumnLayoutContent } from '@components/Layout/ColumnLayout/ColumnLayoutContent';
import { PageHeader } from '@components/PageHeader/PageHeader';
import Tabs from '@components/Tabs/Tabs';
import { useDeleteDealer, useGetDealer } from '@services/api/dealers';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useNavigate } from 'react-router-dom';
import { DealerTab } from './DealerTab';
import { DealerUsersTab } from './DealerUsersTab';
import { useSelector } from 'react-redux';
import { selectIsDealerUser } from '@redux/reducers/slices/user';
import { ActionWithConfirmation } from '@components/ActionWithConfirmation';
import { mdiClose } from '@mdi/js';

export const DealerInfo = () => {
  const navigate = useNavigate();
  const {
    translate,
    translations: {
      dealer: {
        info,
        info: { removeDealerModalContent },
      },
      common: {
        buttons: { delete: deleteButton },
      },
    },
  } = useTranslations();
  const { dealer, params, isLoadingDealer } = useGetDealer();
  const deleteDealer = useDeleteDealer();
  const isDealerUser = useSelector(selectIsDealerUser);

  return (
    <ColumnLayoutContent
      isOpen={Boolean(params.dealerId)}
      header={
        <PageHeader
          title={dealer?.name || ``}
          isLoading={isLoadingDealer}
          actions={
            !isDealerUser ? (
              <ActionWithConfirmation
                dataTestId="delete-dealer"
                buttonIconPath={mdiClose}
                confirmationPopupTitle={translate(deleteButton)}
                confirmationPopupContent={translate(removeDealerModalContent)}
                onConfirm={() => {
                  deleteDealer.mutate(Number(params.dealerId));
                  navigate(`/dealers`);
                }}
              >
                {translate(deleteButton)}
              </ActionWithConfirmation>
            ) : undefined
          }
        />
      }
    >
      <Tabs
        label="Dealer Tabs"
        tabs={[translate(info.dealer), translate(info.users)]}
        tabPanels={[<DealerTab key="dealer-tab" dealer={dealer} />, <DealerUsersTab key="dealer-users-tab" />]}
      />
    </ColumnLayoutContent>
  );
};
