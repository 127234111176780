import { useToken } from '@services/hooks/authentication/useToken';
import { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectExpirationDate, selectIsLoggedIn } from '@redux/reducers/slices/user';

type AuthRouteComponentProps = {
  requireAuth?: boolean;
  children: React.ReactNode;
};

const AuthenticateRouteComponent: React.FC<AuthRouteComponentProps> = ({ requireAuth = true, children }) => {
  const location = useLocation();
  const { setToken } = useToken();

  const isLogedIn = useSelector(selectIsLoggedIn);
  const expirationDate = useSelector(selectExpirationDate);

  useEffect(() => {
    if (requireAuth && expirationDate && isLogedIn && expirationDate < new Date().getTime()) {
      setToken(null);
    }
  }, [expirationDate, isLogedIn, requireAuth, setToken]);

  if (!isLogedIn && requireAuth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children as ReactElement;
};

export const AuthenticateRoute = AuthenticateRouteComponent;
