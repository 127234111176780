import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const FormWrapper = styled(`form`)`
  padding: 24px 24px 16px;
  width: 100%;
  max-width: 391px;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    max-width: 100%;
  }
`;

export const ChipsWrapper = styled(`div`)(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));
export const AddressWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));
