import { ViewType, VIEW_TYPES } from '@components/columnLayout/utils';

export const getPreviousView = (currentView: ViewType) => {
  switch (currentView) {
    case VIEW_TYPES.INFO:
      return VIEW_TYPES.LIST;
    case VIEW_TYPES.EDIT:
      return VIEW_TYPES.INFO;
    default:
      return VIEW_TYPES.LIST;
  }
};
