import React, { useCallback, useEffect, useMemo } from 'react';
import { CreateEmptyProjectRequest, EmptyProjectDto, UpdateEmptyProjectRequest } from '@generatedTypes/data-contracts';
import { COMMON_CONSTRAINTS, ParsedBackendValidationResults } from '@components/controls/validations';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { FormWrapper } from '@components/forms/form-wrapper';
import { useForm } from '@hooks/useForm/useForm';
import { SimpleFormFieldType } from '@hooks/useForm/useFormTypes';
import { GridLayoutWithMargins } from '@components/gridLayout/gridLayoutWithMargins';
import { InputWrapper } from '@components/controls/input-wrapper';
import { RichTextInputWrapper } from '@components/controls/rich-text-input-wrapper';
import { SlideInViewFormWrapper } from '@components/slideInView/SlideInViewFormWrapper';
import { DeleteHeader } from '@components/DeleteHeader/DeleteHeader';

export type FormInputs = Omit<CreateEmptyProjectRequest | UpdateEmptyProjectRequest, `id` | `leadId`>;

type AddEditEmptyProjectProps = {
  onSubmit: (inputs: FormInputs) => void;
  onDelete: (projectId: number) => void;
  onClose: () => void;
  projectDetails: EmptyProjectDto | null;
  beValidationResults?: ParsedBackendValidationResults | null;
  isDisabled?: boolean;
};

export const EmptyProjectForm: React.FC<AddEditEmptyProjectProps> = ({
  onSubmit,
  onDelete,
  onClose,
  projectDetails,
  beValidationResults,
  isDisabled,
}) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: { header, emptyProject },
          },
        },
      },
    },
  } = useTranslations();

  const { formFields, validate, valuesForSubmit, setAllValues, resetAllValues, refreshSchema } = useForm(
    {
      name: {
        constraints: [COMMON_CONSTRAINTS.required],
        type: SimpleFormFieldType.text,
        isRequired: true,
      },
      comment: {
        type: SimpleFormFieldType.text,
      },
    },
    { beValidationResults, isDisabled },
  );

  useEffect(() => {
    if (projectDetails) {
      setAllValues({
        name: projectDetails.name,
        comment: projectDetails?.comment ?? ``,
      });
    } else {
      resetAllValues();
    }
  }, [resetAllValues, projectDetails, setAllValues]);

  useEffect(() => {
    refreshSchema();
    // TODO: check full list of useEffect dependencies to add or remove
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled]);

  const handleSubmit = useCallback(() => {
    const isValid = validate();
    if (!isValid) {
      return;
    }

    onSubmit(valuesForSubmit);
  }, [onSubmit, validate, valuesForSubmit]);

  const handleDelete = useMemo(() => {
    if (projectDetails?.id && !isDisabled) {
      return () => onDelete(projectDetails?.id ?? -1);
    }
    return null;
  }, [isDisabled, onDelete, projectDetails?.id]);

  return (
    <SlideInViewFormWrapper
      header={translate(header)}
      onClose={onClose}
      onSubmit={handleSubmit}
      isDisabled={isDisabled}
    >
      <GridLayoutWithMargins>
        <DeleteHeader title={projectDetails?.name ?? translate(emptyProject.header)} onDelete={handleDelete} />
      </GridLayoutWithMargins>

      <GridLayoutWithMargins>
        <FormWrapper>
          <InputWrapper label={translate(emptyProject.projectName)} {...formFields.name.inputFields} />
          <hr className="horizontal-line dark fw" />
          <h2 className="bold">{translate(emptyProject.commentsHeader)}</h2>
          <RichTextInputWrapper label={translate(emptyProject.comments)} {...formFields.comment.inputFields} />
          <hr className="horizontal-line dark fw" />
        </FormWrapper>
      </GridLayoutWithMargins>
    </SlideInViewFormWrapper>
  );
};
