import { ProjectProductDto } from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '@services/api/utils';
import { useSelector } from 'react-redux';
import { selectCurrentProjectProductId } from '@redux/selectors/lead';
import { PROJECT_PRODUCT } from '@variables/queryKeys';
import { useCustomQuery } from '@hooks/useCustomQuery';

const fetchProjectProductById = async (id: number | null) => {
  if (!id) {
    return null;
  }
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/project-products/${id}`, {
    method: `GET`,
    mode: `cors`,
  });
  if (response.ok) {
    return (await response.json()) as ProjectProductDto;
  }
  throw await getErrorFromFormRequest(response);
};

export const useGetProjectProductById = () => {
  const currentProjectProductId = useSelector(selectCurrentProjectProductId);
  const { data, isLoading } = useCustomQuery({
    queryFn: () => fetchProjectProductById(currentProjectProductId),
    queryKey: [PROJECT_PRODUCT, currentProjectProductId],
    enabled: Boolean(currentProjectProductId),
  });

  return { projectProduct: data ?? null, isLoadingProjectProduct: isLoading };
};
