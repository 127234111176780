import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SOLAR_MAP_EDIT_STATE } from '../../utils/constants';
import { SelectionGroupStateButtons, SelectionGroupStateButtonsProps } from './SelectionGroupStateButton';
import { SelectionPanelsStateButtons, SelectionPanelsStateButtonsProps } from './SelectionPanelsStateButtons';
import { SelectionBaseStateButtonsProps, SelectionRoofStateButtons } from './SelectionRoofStateButtons';
import { SolarMapVisualisationContext } from '../../utils/useSolarMapVisualisation/context';
import { selectSelectedRoofId } from '@redux/selectors/lead';

export type SelectionButtonsProps = SelectionBaseStateButtonsProps &
  Omit<
    SelectionPanelsStateButtonsProps,
    `selectedRoof` | `disableButtons` | `patches` | `onClose` | `shouldSelectPatch`
  > &
  Omit<SelectionGroupStateButtonsProps, `selectedRoof` | `patches` | `onClose`>;

export const SelectionButtons: React.FC<SelectionButtonsProps> = () => {
  const { solarEnergyProject, mapEditState } = useContext(SolarMapVisualisationContext);
  const selectedRoofIndex = useSelector(selectSelectedRoofId) ?? -1;
  const selectedRoof = solarEnergyProject.roofs[selectedRoofIndex];

  const buttons = useMemo(() => {
    switch (mapEditState) {
      case SOLAR_MAP_EDIT_STATE.BASE:
      case SOLAR_MAP_EDIT_STATE.ROOF_SETTINGS:
        return <></>;
      case SOLAR_MAP_EDIT_STATE.ROOF:
        return <SelectionRoofStateButtons />;
      case SOLAR_MAP_EDIT_STATE.PANELS:
      case SOLAR_MAP_EDIT_STATE.SHAPE: {
        const patchesInGroup = selectedRoof?.patches.filter(({ selected }) => selected);
        if (!patchesInGroup) {
          return null;
        }
        return (
          <SelectionPanelsStateButtons
            patches={patchesInGroup}
            shouldSelectPatch={mapEditState === SOLAR_MAP_EDIT_STATE.PANELS}
            disableButtons={mapEditState === SOLAR_MAP_EDIT_STATE.SHAPE}
          />
        );
      }
      case SOLAR_MAP_EDIT_STATE.GROUP: {
        const patchesInGroup = selectedRoof?.patches.filter(({ selected }) => selected);
        if (!patchesInGroup) {
          return null;
        }
        return <SelectionGroupStateButtons patches={patchesInGroup} />;
      }
      default:
        return null;
    }
  }, [mapEditState, selectedRoof?.patches]);

  return <div>{buttons}</div>;
};
