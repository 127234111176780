import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import {
  deleteDealer,
  deleteDealersPartners,
  getDealer,
  getDealerUsers,
  getDealers,
  postDealer,
  putDealer,
  putDealerUser,
  putDealersPartners,
} from './dealers';
import { useQueryClient } from 'react-query';
import { CREATE, DEALER, DEALERS, PARTNER, USERS } from '@variables/queryKeys';
import { useSelector } from 'react-redux';
import { VIEW_TYPES, showNext } from '@components/columnLayout/utils';
import { useParams } from 'react-router-dom';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { selectSelectedDealerID } from '@redux/selectors/dealers';
import { selectIsPartnerUser } from '@redux/selectors/user';

export const useCreateDealer = () => {
  const queryClient = useQueryClient();
  const { isLoading, parsedBackendError, validationErrors, mutateAsync } = useCustomMutation({
    mutationFn: postDealer,
    onSuccess: () => {
      queryClient.invalidateQueries(DEALERS);
    },
  });
  return { mutateAsync, isLoading, parsedBackendError, validationErrors };
};

export const useGetDealers = (options?: { enabled?: boolean }) => {
  const isPartnerUser = useSelector(selectIsPartnerUser);
  const { data, isLoading, error } = useCustomQuery({
    queryKey: DEALERS,
    queryFn: getDealers,
    enabled: options?.enabled || !isPartnerUser,
  });
  return { dealers: data ?? [], isLoadingDealers: isLoading, dealersError: error as Error | undefined };
};

export const useDeleteDealer = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, parsedBackendError, validationErrors } = useCustomMutation({
    mutationFn: deleteDealer,
    onSuccess: () => {
      queryClient.invalidateQueries(DEALERS);
    },
  });
  return { mutate, isLoading, parsedBackendError, validationErrors };
};

export const useCreateDealerUser = (options?: { onSuccess?: () => void }) => {
  const selectedDealerId = useSelector(selectSelectedDealerID);
  const queryClient = useQueryClient();
  const { mutate, isLoading, parsedBackendError, validationErrors } = useCustomMutation({
    mutationKey: [CREATE, DEALER, USERS, selectedDealerId],
    mutationFn: putDealerUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [DEALERS] });
      queryClient.invalidateQueries({ queryKey: [DEALER] });
      showNext(VIEW_TYPES.INFO)();
      options?.onSuccess?.();
    },
  });

  return { mutate, isLoading, parsedBackendError, validationErrors };
};

export const useGetDealer = () => {
  const { dealerId } = useParams<{ dealerId: string }>();

  const { data, isLoading, error } = useCustomQuery({
    queryKey: [DEALER, dealerId],
    queryFn: () => getDealer(Number(dealerId)),
    enabled: Number(dealerId) > 0,
  });
  return { dealer: data, isLoadingDealer: isLoading, dealerError: error as Error | undefined, params: { dealerId } };
};

export const useGetDealerUsers = () => {
  const { dealerId } = useParams<{ dealerId: string }>();
  const { data, isLoading, error } = useCustomQuery({
    queryKey: [DEALERS, USERS, dealerId],
    queryFn: () => getDealerUsers(Number(dealerId)),
    enabled: Number(dealerId) > -1,
  });
  return { dealerUsers: data || [], isLoadingDealerUSers: isLoading, dealerUsersError: error as Error | undefined };
};

export const useUpdateDealer = () => {
  const queryClient = useQueryClient();
  const { mutate, parsedBackendError, validationErrors, isLoading } = useCustomMutation({
    mutationFn: putDealer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [DEALERS] });
      queryClient.invalidateQueries({ queryKey: [DEALER] });
    },
  });
  return { mutate, parsedBackendError, validationErrors, isLoading };
};

export const useDeleteDealersPartners = () => {
  const queryClient = useQueryClient();
  const { mutate, parsedBackendError, validationErrors, isLoading } = useCustomMutation({
    mutationFn: deleteDealersPartners,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PARTNER] });
      showNext(VIEW_TYPES.INFO)();
    },
  });
  return { mutate, parsedBackendError, validationErrors, isLoading };
};

export const useUpdateDealersPartners = (options?: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();
  const { mutate, parsedBackendError, validationErrors, isLoading } = useCustomMutation({
    mutationFn: putDealersPartners,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PARTNER] });
      options?.onSuccess?.();
    },
  });
  return { mutate, parsedBackendError, validationErrors, isLoading };
};
