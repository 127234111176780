import { authFetch } from '@services/api/utils';
import { useQueryClient } from 'react-query';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { LEAD, LEADS, OFFER_PRICE_OVERVIEW, PROJECTS } from '@variables/queryKeys';

export const deleteProjectProduct = async (projectProductId: number) =>
  authFetch(`${process.env.PROTECTED_API_URL}/project-products/${projectProductId}`, {
    method: `DELETE`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.clone().ok) {
        throw await res.clone().json();
      }
    })
    .catch((error) => {
      throw new Error(error);
    });
export const useDeleteProjectProduct = (options?: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading } = useCustomMutation({
    mutationFn: deleteProjectProduct,
    onSuccess: () => {
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries([OFFER_PRICE_OVERVIEW]);
      options?.onSuccess();
    },
  });

  return {
    deleteProjectProduct: mutate,
    deleteProjectProductAsync: mutateAsync,
    isDeletingProjectProduct: isLoading,
  };
};
