import { Panel, Patch } from '../../roofVisualisationTypes';
import { panelsResolverV10 } from './v10/panelsResolverV10';

export const CURRENT_PANELS_RESOLVER_VERSION = 10;

const resolversMap = {
  10: panelsResolverV10,
} as const;

function assertVersionIsSupported(version: number): asserts version is keyof typeof resolversMap {
  if (!Object.keys(resolversMap).includes(String(version))) {
    throw new Error(`Unsupported panel resolver version: ${version}`);
  }
}

export type PanelsResolverResult = {
  panels: Panel[];
  width: number;
  height: number;
  columns: number;
  rows: number;
};

export const defaultPanelsResolverResult: PanelsResolverResult = {
  panels: [],
  width: 0,
  height: 0,
  columns: 0,
  rows: 0,
};

export const resolvePanels = ({
  map,
  patch,
  resolverFunctionVersion,
}: {
  map: google.maps.Map;
  patch: Patch;
  resolverFunctionVersion: number;
}): PanelsResolverResult => {
  assertVersionIsSupported(resolverFunctionVersion);

  return resolversMap[resolverFunctionVersion](map, patch);
};
