import { EditSubmitButtons } from '@components/editSubmitRow/editSubmitRow';
import './form.css';

type FormWrapperProps = {
  id?: string;
  classes?: string;
  children: React.ReactNode;
  onSubmit?: () => void;
  fullHeight?: boolean;
  onClose?: () => void;
  disabled?: boolean;
  saveBar?: boolean;
};

/** @deprecated please use MuiFormWrapper instead */
export const FormWrapper: React.FC<FormWrapperProps> = ({
  id = ``,
  classes = ``,
  children,
  onSubmit,
  onClose,
  fullHeight = true,
  disabled = false,
  saveBar = false,
}) => {
  const defaultClasses = `form-wrapper inner-wrapper column gap gap-small`;
  const mergedClasses = `${defaultClasses} ${classes}`;

  return (
    <form id={id} className={mergedClasses + (fullHeight ? ` fh` : ``)} data-testid="form-wrapper">
      {children}
      {saveBar && (
        <EditSubmitButtons
          onClose={() => onClose?.()}
          onSubmit={onSubmit}
          disabled={disabled}
          disabledSubmit={!onSubmit}
        />
      )}
    </form>
  );
};
