import { Card } from '@components/Card/Card';
import { ColumnLayoutContent } from '@components/Layout/ColumnLayout/ColumnLayoutContent';
import { PageHeader } from '@components/PageHeader/PageHeader';
import { useToggle } from '@hooks/useToggle';
import { mdiPencil } from '@mdi/js';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import {
  useGetPartner,
  useGetPartnerDeductionTemplates,
  useGetPartnerProjectAdditionsTemplates,
  useUpdatePartner,
} from '@services/api/partners';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { PARTNER } from '@variables/queryKeys';
import { useQueryClient } from 'react-query';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getFormattedNumber } from '@pages/NewLeads/utils';
import { setSelectedDeductionTemplateId, setSelectedPartnerAdditionTemplateId } from '@redux/reducers/slices/partner';
import { AdditionTemplatesListCard } from './AdditionsAndDeductions/Additions/AdditionTemplatesListCard';
import { EditProductAdditionTemplateWrapper } from './AdditionsAndDeductions/Additions/EditProductAdditionTemplateWrapper';
import { EditDeductionTemplateWrapper } from './AdditionsAndDeductions/Deductions/EditDeductionTemplateWrapper';
import { DeductionsTemplatesListCard } from './AdditionsAndDeductions/Deductions/DeductionTemplatesListCard';
import { EconomySettingsEdit } from './EconomySettingsEdit';
import { EconomySettingsEditMargin } from './EconomySettingsEditMargin';

export const Economy = () => {
  const queryClient = useQueryClient();
  const {
    translate,
    translations: {
      myAccount: { economySettings },
    },
  } = useTranslations();
  const updatePartner = useUpdatePartner({
    onSuccess: () => {
      queryClient.invalidateQueries(PARTNER);
    },
  });
  const dispatch = useDispatch();
  const { partner } = useGetPartner();
  const { partnerProjectAdditionsTemplates } = useGetPartnerProjectAdditionsTemplates();
  const { partnerDeductionTemplates } = useGetPartnerDeductionTemplates();
  const [isEditHourleRateOpen, toggleIsEditHourlyRateOpen] = useToggle();
  const [isMarginOpen, toggleIsMarginOpen] = useToggle();
  const [isAdditionalCostsOpen, toggleIsAdditionalCostsOpen] = useToggle();
  const [isDeductionTemplateOpen, toggleIsDeductionTemplateOpen] = useToggle();

  const handleAddEditAdditionTemplate = useCallback(
    (selectedAdditionTemplateId: number | undefined) => {
      toggleIsAdditionalCostsOpen();
      dispatch(setSelectedPartnerAdditionTemplateId(selectedAdditionTemplateId));
    },
    [dispatch, toggleIsAdditionalCostsOpen],
  );

  const handleAddEditDeductionTemplate = useCallback(
    (selectedDeductionTemplateId: number | undefined) => {
      toggleIsDeductionTemplateOpen();
      dispatch(setSelectedDeductionTemplateId(selectedDeductionTemplateId));
    },
    [dispatch, toggleIsDeductionTemplateOpen],
  );

  return (
    <ColumnLayoutContent isOpen header={<PageHeader title={translate(economySettings.header)} />}>
      <Stack gap={2} className="cards__wrapper">
        <Card
          title={translate(economySettings.hourlyRate.header)}
          titleAction={toggleIsEditHourlyRateOpen}
          titleActionIcon={mdiPencil}
        >
          {getFormattedNumber(partner?.netCostPerHour ?? 0)} {translate(economySettings.hourlyRate.displaySuffix)}
        </Card>
        <Drawer anchor="right" onClose={toggleIsEditHourlyRateOpen} open={isEditHourleRateOpen}>
          <EconomySettingsEdit
            partner={partner}
            disableForm={updatePartner.isLoading}
            goBackHandler={toggleIsEditHourlyRateOpen}
            updatePartner={updatePartner.mutate}
            beValidationResults={updatePartner.validationErrors}
          />
        </Drawer>
        <Card
          title={translate(economySettings.margin.header)}
          titleAction={toggleIsMarginOpen}
          titleActionIcon={mdiPencil}
        >
          {getFormattedNumber(partner?.defaultProjectMargin ?? 0)}%
        </Card>
        <Drawer anchor="right" onClose={toggleIsMarginOpen} open={isMarginOpen}>
          <EconomySettingsEditMargin
            partner={partner}
            disableForm={updatePartner.isLoading}
            updatePartner={updatePartner.mutate}
            goBackHandler={toggleIsMarginOpen}
            beValidationResults={updatePartner.validationErrors}
          />
        </Drawer>
        <DeductionsTemplatesListCard
          deductionsTemplates={partnerDeductionTemplates ?? []}
          onShowAddEditDeductionTemplateForm={handleAddEditDeductionTemplate}
        />
        <AdditionTemplatesListCard
          projectAdditions={partnerProjectAdditionsTemplates ?? []}
          onShowAddEditAdditionForm={handleAddEditAdditionTemplate}
        />
      </Stack>
      <Drawer anchor="right" onClose={toggleIsAdditionalCostsOpen} open={isAdditionalCostsOpen}>
        <EditProductAdditionTemplateWrapper onClose={toggleIsAdditionalCostsOpen} />
      </Drawer>
      <Drawer anchor="right" onClose={toggleIsDeductionTemplateOpen} open={isDeductionTemplateOpen}>
        <EditDeductionTemplateWrapper onClose={toggleIsDeductionTemplateOpen} />
      </Drawer>
    </ColumnLayoutContent>
  );
};
