import { Orientation, ProductAttributeRowDto, SolarPanelOrientationAttributeDto } from '@generatedTypes/data-contracts';
import { getValuesFromAttributeRow } from '@pages/NewLeads/project/solarEnergyProject/utils/utils';

export const getMountingMaterialMappedAsObject = (mountingMaterialManufacturer: ProductAttributeRowDto | undefined) =>
  getValuesFromAttributeRow(mountingMaterialManufacturer).reduce<Record<number, string | undefined | null>>(
    (acc, { id, name }) => ({ ...acc, [id]: name }),
    {},
  );

export const getPanelOrientationsForResolver = (solarPanelOrientation: SolarPanelOrientationAttributeDto | undefined) =>
  getValuesFromAttributeRow(solarPanelOrientation).reduce<Record<number, Orientation>>((acc, { id, orientation }) => {
    if (orientation) {
      acc[id] = orientation;
    }
    return acc;
  }, {});
