import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Sender } from './Sender';
import { ValidityPeriod } from './ValidityPeriod';
import { Card } from '@components/Card/Card';

export const TermsAndContactCard = () => {
  const {
    translate,
    translations: { leads },
  } = useTranslations();

  return (
    <Card title={translate(leads.offer.termsAndContact.header)}>
      <ValidityPeriod />
      <Sender />
    </Card>
  );
};
