import { DealerRowDto, UserType } from '@generatedTypes/data-contracts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UniversalUser } from '@services/api/users/types';
import { AppState } from '@redux/reducers';
import { createSelector } from 'reselect';

export type Features = `car-charging-project` | `energy-storage-project`;

export interface UserState {
  isLoggedIn: boolean;
  expirationDate: number | null;
  userId: number | null;
  partnerId: number | null;
  userDetails: UniversalUser | null;
  userType: UserType;
  enabledFeatures: Features[];
  dealers: DealerRowDto[];
  firstName?: string;
  lastName?: string;
  isAdmin?: boolean;
}

const checkLogin = () => {
  const isToken = Boolean(localStorage.getItem(`token`));
  const isStringExpired = Number(localStorage.getItem(`expires`)) < new Date().getTime();

  return isToken && !isStringExpired;
};

export const initialState: UserState = {
  isLoggedIn: checkLogin(),
  expirationDate: null,
  userId: null,
  partnerId: null,
  userDetails: null,
  userType: UserType.None,
  enabledFeatures: [],
  dealers: [],
  isAdmin: false,
};

const userSlice = createSlice({
  name: `user`,
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setExpirationDate: (state, action: PayloadAction<number | null>) => {
      state.expirationDate = action.payload;
    },
    setLoggedInUserData: (
      state,
      action: PayloadAction<{
        userId?: number | null;
        partnerId?: number | null;
        userType?: UserType;
        enabledFeatures: Features[];
        firstName?: string;
        lastName?: string;
        isAdmin?: boolean;
      }>,
    ) => {
      state.userId = action.payload.userId ?? null;
      state.partnerId = action.payload.partnerId ?? null;
      state.userType = action.payload.userType ?? UserType.None;
      state.enabledFeatures = action.payload.enabledFeatures ?? [];
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.isAdmin = action.payload.isAdmin ?? false;
    },
    setLoggedInUserDetails: (state, action: PayloadAction<UniversalUser>) => {
      state.userDetails = action.payload;
    },
  },
});

export const { setIsLoggedIn, setExpirationDate, setLoggedInUserData, setLoggedInUserDetails } = userSlice.actions;
export const { reducer: userReducer } = userSlice;

export const selectIsLoggedIn = (state: AppState) => state.User.isLoggedIn;
export const selectExpirationDate = (state: AppState) => state.User.expirationDate;
export const selectUserDetails = (state: AppState) => state.User.userDetails;
export const selectUserId = (state: AppState) => state.User.userId;
export const selectPartnerId = (state: AppState) => state.User.partnerId;
export const selectUserType = (state: AppState) => state.User.userType;
export const selectEnabledFeatures = (state: AppState) => state.User.enabledFeatures;
export const selectDealers = (state: AppState) => state.User.dealers;

export const selectIsOperationUser = createSelector(selectUserType, (userType) => userType === UserType.Operation);
export const selectIsDealerUser = createSelector(selectUserType, (userType) => userType === UserType.Dealer);
export const selectIsPartnerUser = createSelector(selectUserType, (userType) => userType === UserType.Partner);

export const selectIsUserAdmin = (state: AppState) => state.User.isAdmin;

export const selectUserIdForApi = createSelector(
  selectUserId,
  selectUserType,
  (userId, userType) => `${userType}/${userId ?? `-1`}`,
);

export const selectFeatureEnabled = (feature: Features) =>
  createSelector(selectEnabledFeatures, (features) => features.includes(feature));
