import { AuthenticateRoute } from '@components/app/RequireAuth';
import { NotFound } from '@pages/fallbacks/notFound';
import { ForgotPassword } from '@pages/forgot-password/forgotPassword';
import { ForgotPasswordConfirmation } from '@pages/forgot-password/forgotPasswordConfirmation';
import { Login } from '@pages/login/login';
import { OfferPage } from '@pages/offer/OfferPage';
import { LeadsInfo } from '@pages/NewLeads/LeadsInfo';
import { Leads } from '@pages/NewLeads/Leads';
import { ResetPassword } from '@pages/resetPassword/resetPassword';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { BasketForm } from '@pages/BasketForm/BasketForm';
import { DealersPage } from '@pages/dealers/DealersPage';
import { DealerInfo } from '@pages/dealers/DealerInfo';
import { PartnersPage } from '@pages/partners/PartnersPage';
import { SystemSettingsPage } from '@pages/systemSettings/SystemSettingsPage';
import { CompanyInformation } from '@pages/systemSettings/CompanyInformation';
import { Offer } from '@pages/systemSettings/Offer';
import { Economy } from '@pages/systemSettings/economy/Economy';
import { SystemSettings } from '@pages/systemSettings/SystemSettings';
import { MyAccountPage } from '@pages/MyAccount/MyAccountPage';
import { MyAccountDetails } from '@pages/MyAccount/MyAccountDetails';
import { UsersPage } from '@pages/Users/UsersPage';
import { UserDetails } from '@pages/Users/UserDetails';
import { PartnerDetails } from '@pages/partners/PartnerDetails';
import { AuthorizedUserTypes } from '@components/app/AuthorizedUserTypes';
import { UserType } from '@generatedTypes/data-contracts';
import { ClientTab } from '@pages/NewLeads/ClientTab';
import { ProjectTab } from '@pages/NewLeads/ProjectTab';
import { OfferTab } from '@pages/NewLeads/OfferTab/OfferTab';

export const router = createBrowserRouter([
  {
    path: `/reset-password`,
    element: <ResetPassword />,
  },
  {
    path: `/forgot-password-confirmation/:email`,
    element: <ForgotPasswordConfirmation />,
  },
  {
    path: `/forgot-password`,
    element: <ForgotPassword />,
  },
  {
    path: `/login`,
    element: (
      <AuthenticateRoute requireAuth={false}>
        <Login />
      </AuthenticateRoute>
    ),
  },
  {
    path: `/partners`,
    element: (
      <AuthenticateRoute>
        <AuthorizedUserTypes userTypes={[UserType.Operation, UserType.Dealer]}>
          <PartnersPage />
        </AuthorizedUserTypes>
      </AuthenticateRoute>
    ),
    children: [
      {
        path: `:partnerId`,
        element: <PartnerDetails />,
      },
    ],
  },
  {
    path: `/users`,
    element: (
      <AuthenticateRoute>
        <AuthorizedUserTypes userTypes={[UserType.Operation]}>
          <UsersPage />
        </AuthorizedUserTypes>
      </AuthenticateRoute>
    ),
    children: [{ path: `:userId`, element: <UserDetails /> }],
  },
  {
    path: `/dealers`,
    element: (
      <AuthenticateRoute>
        <AuthorizedUserTypes userTypes={[UserType.Operation, UserType.Dealer]}>
          <DealersPage />
        </AuthorizedUserTypes>
      </AuthenticateRoute>
    ),
    children: [
      {
        path: `:dealerId`,
        element: <DealerInfo />,
      },
    ],
  },
  {
    path: `/my-account`,
    element: (
      <AuthenticateRoute>
        <MyAccountPage />
      </AuthenticateRoute>
    ),
    children: [{ index: true, element: <MyAccountDetails /> }],
  },
  {
    path: `/system-settings`,
    element: (
      <AuthenticateRoute>
        <AuthorizedUserTypes userTypes={[UserType.Operation, UserType.Partner]}>
          <SystemSettingsPage />
        </AuthorizedUserTypes>
      </AuthenticateRoute>
    ),
    children: [
      { element: <SystemSettings />, index: true },
      {
        path: `company-information`,
        element: (
          <AuthorizedUserTypes userTypes={[UserType.Partner]}>
            <CompanyInformation />
          </AuthorizedUserTypes>
        ),
      },
      {
        path: `offer`,
        element: (
          <AuthorizedUserTypes userTypes={[UserType.Partner]}>
            <Offer />
          </AuthorizedUserTypes>
        ),
      },
      {
        path: `economy`,
        element: (
          <AuthorizedUserTypes userTypes={[UserType.Partner]}>
            <Economy />
          </AuthorizedUserTypes>
        ),
      },
    ],
  },
  {
    path: `/leads`,
    element: (
      <AuthenticateRoute>
        <Leads />
      </AuthenticateRoute>
    ),
    children: [
      {
        path: `:leadId`,
        element: <LeadsInfo />,
        children: [
          {
            index: true,
            element: <ClientTab />,
          },
          {
            path: `project`,
            element: <ProjectTab />,
          },
          {
            path: `offer`,
            element: <OfferTab />,
          },
        ],
      },
    ],
  },
  {
    path: `/`,
    element: <Navigate to="/leads" replace />,
  },
  {
    path: `/offer/:seed`,
    element: <OfferPage />,
  },
  {
    path: `/404`,
    element: <NotFound />,
  },
  {
    path: `/basket/:offerHash`,
    element: <BasketForm />,
  },
  {
    path: `*`,
    element: <Navigate replace to="/404" />,
  },
]);
