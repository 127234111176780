import React from 'react';

type GridLayoutWithMarginsProps = {
  children?: React.ReactNode;
  bgColor?: `bg-brown-1` | `bg-brown-2` | `bg-brown-3` | `bg-gray` | `bg-beige` | undefined;
  gridContainerOnParent?: boolean;
  mainSize?: number;
  sideSticky?: boolean;
};

const DEFAULT_MAIN_SIZE = 8;

export const GridLayoutWithSideElementAndMargins: React.FC<GridLayoutWithMarginsProps> = (props) => {
  const childrenList = React.Children.toArray(props.children);
  return (
    <div className={`section bg ${props.bgColor ? props.bgColor : ``}`}>
      <div className={props.gridContainerOnParent ? `grid-container` : `grid-container w980-layout`}>
        <div className={`col-12 col-desktop-${props.mainSize ?? DEFAULT_MAIN_SIZE}`}>{childrenList?.[0] ?? null}</div>
        <div
          className={`col-12 col-desktop-${12 - (props.mainSize ?? DEFAULT_MAIN_SIZE)}${
            props.sideSticky ? ` sticky` : ``
          }`}
        >
          {childrenList?.[1] ?? null}
        </div>
      </div>
    </div>
  );
};
