import React from 'react';
import { Polyline } from '@react-google-maps/api';
import { getEdgePolylineStyle } from '../utils/style';
import { Edge } from '../roofVisualisationTypes';
import { DistanceMarker } from './DistanceMarker';
import { useHover } from './utils';

type PatchEdgeProps = {
  edge: Edge;
  selected: boolean;
  onEdgeClick?: () => void;
  showDistanceMarker: boolean;
  highlightEdges?: boolean;
};

export const PatchEdge: React.FC<PatchEdgeProps> = ({
  edge: { start, end, distanceLabelPosition, distance },
  selected,
  onEdgeClick,
  showDistanceMarker,
  highlightEdges,
}) => {
  const [isHovered, hoverFunctions] = useHover();

  return (
    <>
      {highlightEdges && (
        <Polyline
          path={[start, end]}
          options={{
            ...getEdgePolylineStyle({ selected, isHovered, canChangeEdge: !!onEdgeClick }),
          }}
          onClick={onEdgeClick}
          {...hoverFunctions}
        />
      )}

      <DistanceMarker
        position={distanceLabelPosition}
        distance={distance}
        selected={selected}
        onClick={onEdgeClick}
        showDistanceMarker={showDistanceMarker}
      />
    </>
  );
};
