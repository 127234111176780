import { useForm } from 'react-hook-form';
import { ColumnItem } from '@components/columnLayout/utils';
import { PutPartner } from '@services/api/partners/partners';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { ParsedBackendValidationResults } from '@components/controls/validations';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { FormSection } from '@components/forms/MuiFormSection';
import { MuiNumberInput } from '@components/controls/react-hook-form-friendly/smart/MuiNumberInput';
import InputAdornment from '@mui/material/InputAdornment';
import { parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';

type EconomySettingsEditMarginProps = {
  disableForm: boolean;
  goBackHandler: () => void;
  updatePartner: (partner: PutPartner) => void;
  partner: PartnerDto | null;
  beValidationResults: ParsedBackendValidationResults | null;
};

export const EconomySettingsEditMargin: ColumnItem<EconomySettingsEditMarginProps> = ({
  disableForm,
  goBackHandler,
  partner,
  updatePartner,
}) => {
  const {
    translate,
    translations: {
      myAccount: { economySettings },
    },
  } = useTranslations();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      defaultProjectMargin: partner?.defaultProjectMargin ?? 0,
    },
  });

  return (
    <FormWrapper
      onCancel={goBackHandler}
      onSubmit={handleSubmit(({ defaultProjectMargin, ...values }) => {
        if (partner) {
          updatePartner({
            ...partner,
            ...partner.address,
            defaultProjectMargin: parseInputValueToSubmitNumberValue(defaultProjectMargin),
            ...values,
          });
          goBackHandler();
          reset();
        }
      })}
      disabled={disableForm}
    >
      <FormSection title={translate(economySettings.margin.header)}>
        <MuiNumberInput
          label={translate(economySettings.margin.margin)}
          control={control}
          name="defaultProjectMargin"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <p>{translate(economySettings.margin.inputInfo)}</p>
      </FormSection>
    </FormWrapper>
  );
};
