import { useTranslations } from '@services/hooks/translations/useTranslations';
import List from '@mui/material/List';
import { theme } from 'src/styles/theme/theme';
import { Empty } from '@components/Empty/Empty';
import emptyLeadInformation from '@assets/svg/EmptyComponentIcons/emptyLeadInformation.svg';
import { DeductionsList, DeductionsListProps } from './ProjectDeductions/Cards/DeductionsList';
import { AdditionsList, AdditionsListProps } from './ProjectAdditions/Cards/AdditionsList';

export type AdditionsAndDeductionsTabProps = Omit<AdditionsListProps, `projectAdditionsTranslations`> &
  Omit<DeductionsListProps, `projectDeductionHeaderTranslation`>;

const listSxOverrides = {
  marginInline: -3,
  '& .MuiListItemSecondaryAction-root': {
    right: theme.spacing(4),
  },
};

export const AdditionsAndDeductionsTab: React.FC<AdditionsAndDeductionsTabProps> = (props) => {
  const {
    translate,
    translations: {
      editProduct: {
        deductionTemplate: { header: deductionHeader },
      },
      leads: {
        details: {
          project: { otherCosts },
        },
      },
    },
  } = useTranslations();

  return props.projectAdditions.length === 0 && props.projectDeductions?.length === 0 ? (
    <Empty description={translate(otherCosts.noCostsAdded)} icon={emptyLeadInformation} />
  ) : (
    <List sx={listSxOverrides}>
      <AdditionsList
        projectAdditions={props.projectAdditions}
        projectAdditionsTranslations={otherCosts}
        onShowAddEditAdditionForm={props.onShowAddEditAdditionForm}
      />
      <DeductionsList
        projectDeductions={props.projectDeductions}
        onShowAddEditDeductionForm={props.onShowAddEditDeductionForm}
        projectDeductionHeaderTranslation={deductionHeader}
      />
    </List>
  );
};
