import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { ColumnItem } from '@components/columnLayout/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { DealerRowDto, UserType } from '@generatedTypes/data-contracts';
import { Radio, SingleCheckbox } from '@components/controls/react-hook-form-friendly/smart';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import { ParsedBackendValidationResults } from '@components/controls/validations';
import { Select } from '@components/controls/react-hook-form-friendly/smart/MuiSelect';
import { ICON_COLOR, SvgIcon } from '@components/controls/svgIcon';
import { RightPanelViewSubForCreatingUser } from './rightPanelViewSubForCreatingUser';
import { CreateUserRequestBodyWithType } from '@services/api/users/users';
import { zodResolver } from '@hookform/resolvers/zod';
import { requiredEmail, requiredString } from '@variables/zod';
import { z } from 'zod';
import { useGetPartners } from '@services/api/partners';
import { useGetDealers } from '@services/api/dealers';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { FormSection } from '@components/forms/MuiFormSection';
import { Box } from '@mui/material';
import { DevTool } from '@hookform/devtools';
import { CheckBox } from '@components/controls/check-box';

type MyOption = { value: number; label: string };

const newUserZodObject = {
  firstName: requiredString(),
  lastName: requiredString(),
  email: requiredEmail,
  phoneNumber: z.string().optional(),
  userType: z.string(),
  partnerId: z.number(),
  isAdmin: z.boolean(),
};

const newUserZodSchema = z.object(newUserZodObject);

export type NewUserValuesType = z.infer<typeof newUserZodSchema>;

type NewUserProps = {
  onNewUserExit: () => void;
  createUser: (user: CreateUserRequestBodyWithType) => void;
  disableForm: boolean;
  initialPartner?: number | null;
  userTypeMode: keyof typeof UserType | `all`;
  beValidationResults: ParsedBackendValidationResults | null;
  hideAssignments?: boolean;
  predefinedUser?:
    | {
        userType: UserType.Partner;
        partnerId: number;
      }
    | {
        userType: UserType.Dealer;
        dealerId: number;
      };
};

export const NewUser: ColumnItem<NewUserProps> = ({
  onNewUserExit,
  createUser,
  disableForm,
  initialPartner,
  userTypeMode,
  beValidationResults,
  hideAssignments,
  predefinedUser,
}) => {
  const initialValues: NewUserValuesType = useMemo(
    () => ({
      firstName: ``,
      lastName: ``,
      email: ``,
      phoneNumber: ``,
      userType: userTypeMode,
      partnerId: 0,
      isAdmin: false,
    }),
    [userTypeMode],
  );
  const {
    translate,
    translations: {
      users: { create },
      dealer,
    },
  } = useTranslations();

  const { partners } = useGetPartners();
  const { isLoadingDealers } = useGetDealers();

  const [dealerListVisibility, setDealerListVisibility] = useState(false);
  const [selectedDealers, setSelectedDealers] = useState<DealerRowDto[]>([]);

  const optionsForSelectControl = useMemo(
    () =>
      partners?.map(
        (el) =>
          ({
            value: el.id,
            label: el.name,
          }) as MyOption,
      ),
    [partners],
  );
  const optionsForSelectControlString = JSON.stringify(optionsForSelectControl);

  const radioControlsUserTypesVisibilityLogic = useMemo(() => {
    switch (userTypeMode) {
      case `all`:
        return false;
      case `Partner`:
        return true;
      case `Dealer`:
        return true;
      case `Operation`:
        return true;
      default:
        return false;
    }
  }, [userTypeMode]);

  const { control, getValues, setError, reset, handleSubmit, setValue } = useForm({
    resolver: zodResolver(newUserZodSchema),
    defaultValues: initialValues,
  });
  const userType = useWatch({ control, name: `userType` });

  useEffect(() => {
    if (userType !== UserType.Dealer) {
      setSelectedDealers([]);
    }
  }, [userType]);

  const selectedDealrsHTML = useMemo(() => {
    return (
      selectedDealers.length > 0 && (
        <Box marginInlineStart={4}>
          {selectedDealers.map((el) => {
            return (
              <CheckBox
                key={el.id}
                label={el.name || ``}
                checked={true}
                onChange={() => null}
                name={el.name || `` + el.id}
                value={el.id + ``}
                isLabelOnRight
                isDisabled={true}
              />
            );
          })}
        </Box>
      )
    );
  }, [selectedDealers]);

  const isDealerOptionSelected = getValues().userType === UserType.Dealer;

  useEffect(() => {
    if (beValidationResults) {
      Object.entries(beValidationResults.errors).forEach(([keyName, error]) => {
        setError(keyName as keyof NewUserValuesType, { message: error[0] });
      });
    }
  }, [beValidationResults, setError]);

  const onCancel = useCallback(() => {
    reset();
    onNewUserExit();
  }, [onNewUserExit, reset]);

  const onSubmit = useCallback(
    (values: NewUserValuesType) => {
      let dataForCreate;

      if (values.userType === UserType.Dealer || predefinedUser?.userType === UserType.Dealer) {
        dataForCreate = {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          dealerIds:
            (predefinedUser?.userType === UserType.Dealer && [predefinedUser?.dealerId]) ||
            selectedDealers.map((el) => el.id),
          userType: values.userType,
          isAdmin: values.isAdmin,
        };
      } else {
        dataForCreate = {
          ...values,
          partnerId:
            predefinedUser?.partnerId || (values.userType === UserType.Partner ? Number(values.partnerId) : undefined),
        };
      }

      createUser(dataForCreate as CreateUserRequestBodyWithType);
    },
    [createUser, selectedDealers, predefinedUser],
  );

  const dealerListConfirmCallback = useCallback((list: DealerRowDto[]) => {
    setSelectedDealers(list);
    setDealerListVisibility(false);
  }, []);

  const dealerListCancelCallback = useCallback(() => setDealerListVisibility(false), []);

  useEffect(() => {
    reset();
    // TODO: check full list of useEffect dependencies to add or remove
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsForSelectControlString]);

  useEffect(() => {
    if (initialPartner && optionsForSelectControl) {
      setValue(`partnerId`, optionsForSelectControl.find((el) => el.value === initialPartner)?.value ?? 0);
    }
    // array in dependency list is causing infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPartner, optionsForSelectControlString, setValue]);

  return (
    <>
      <FormWrapper
        title={translate(create.createHeader)}
        onCancel={onCancel}
        onSubmit={handleSubmit(onSubmit)}
        disabled={disableForm}
        width="350px"
      >
        <FormSection>
          <InputWrapper label={translate(create.firstName)} control={control} name="firstName" isRequired />
          <InputWrapper label={translate(create.lastName)} control={control} name="lastName" isRequired />
          <InputWrapper label={translate(create.email)} control={control} name="email" type="email" isRequired />
          <InputWrapper label={translate(create.phone)} control={control} name="phoneNumber" type="tel" />
        </FormSection>
        <FormSection title={translate(create.competenceHeader)}>
          <SingleCheckbox
            label={translate(create.competenceAdmin)}
            value="administrator"
            control={control}
            name="isAdmin"
            defaultValue={false}
            isLabelOnRight
          />
        </FormSection>
        {!hideAssignments && (
          <FormSection>
            <div className="form-group column gap-small">
              <h2>{translate(dealer.users.create.role)}</h2>
              <div id="user-type">
                <div className="radio-group column gap gap-small">
                  <div className="row align-center">
                    <Radio
                      control={control}
                      name="userType"
                      value={UserType.Operation}
                      label={translate(create.userTypeOperation)}
                      classesForContainer="row align-center reversed-direction to-the-end"
                      isDisabled={radioControlsUserTypesVisibilityLogic}
                    />
                  </div>
                  <div className="row align-center space-between">
                    <Radio
                      control={control}
                      name="userType"
                      value={UserType.Dealer}
                      label={translate(dealer.info.dealer)}
                      classesForContainer="row align-center reversed-direction to-the-end"
                      isDisabled={radioControlsUserTypesVisibilityLogic}
                    />
                    {userTypeMode === `all` && (
                      <SvgIcon
                        iconId="chevron-right"
                        color={ICON_COLOR.COLOR_BLUE}
                        onClick={() => setDealerListVisibility(true)}
                        isDisabled={!isDealerOptionSelected && isLoadingDealers}
                      />
                    )}
                  </div>

                  {selectedDealrsHTML}

                  <div className="row align-center">
                    <Radio
                      control={control}
                      name="userType"
                      value={UserType.Partner}
                      classesForContainer="row align-center reversed-direction to-the-end"
                      label={translate(create.userTypePartner)}
                      isDisabled={radioControlsUserTypesVisibilityLogic}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group column">
              <Select
                label=""
                control={control}
                name="partnerId"
                options={optionsForSelectControl}
                disabled={userType !== UserType.Partner || radioControlsUserTypesVisibilityLogic}
              />
            </div>
          </FormSection>
        )}
      </FormWrapper>
      {!hideAssignments && (userTypeMode === `Dealer` || userTypeMode === `all`) && (
        <RightPanelViewSubForCreatingUser
          onCancel={dealerListCancelCallback}
          isVisible={dealerListVisibility}
          confirmCb={dealerListConfirmCallback}
        />
      )}
      <DevTool control={control} />
    </>
  );
};
