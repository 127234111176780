import { selectMapEditState } from '@redux/reducers/slices/leadsPage';
import { useSelector } from 'react-redux';
import { useGetLead } from '@services/api/leads/lead-info';
import { Marker } from '@react-google-maps/api';
import { useFadingMarker } from '@components/maps/GoogleMaps/Markers/useFadingMarker';

const OutlinedMarkerSvg = `<svg width="26" height="38" viewBox="0 0 26 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 0C9.55219 0 6.24558 1.36964 3.80761 3.80761C1.36964 6.24558 0 9.55219 0 13C0 19.9011 4.64286 23.2143 8.35714 29.7143C12.0714 36.2143 12.0714 37.1429 13 37.1429C13.9286 37.1429 13.9286 36.2143 17.6429 29.7143C21.3571 23.2143 26 19.5493 26 13C26 9.55219 24.6304 6.24558 22.1924 3.80761C19.7544 1.36964 16.4478 0 13 0Z" fill="#FFCD00"/>
<g opacity="0.2">
<path d="M12.9451 17.57C11.7184 17.57 10.5421 17.0827 9.6747 16.2154C8.80734 15.348 8.32007 14.1716 8.32007 12.945C8.32007 11.7184 8.80734 10.542 9.6747 9.67464C10.5421 8.80728 11.7184 8.32001 12.9451 8.32001C14.1717 8.32001 15.3481 8.80728 16.2154 9.67464C17.0828 10.542 17.5701 11.7184 17.5701 12.945C17.5701 13.5524 17.4504 14.1538 17.218 14.7149C16.9856 15.276 16.6449 15.7859 16.2154 16.2154C15.786 16.6448 15.2761 16.9855 14.715 17.218C14.1538 17.4504 13.5524 17.57 12.9451 17.57Z" fill="black"/>
<path d="M12.9451 17.57C11.7184 17.57 10.5421 17.0827 9.6747 16.2154C8.80734 15.348 8.32007 14.1716 8.32007 12.945C8.32007 11.7184 8.80734 10.542 9.6747 9.67464C10.5421 8.80728 11.7184 8.32001 12.9451 8.32001C14.1717 8.32001 15.3481 8.80728 16.2154 9.67464C17.0828 10.542 17.5701 11.7184 17.5701 12.945C17.5701 13.5524 17.4504 14.1538 17.218 14.7149C16.9856 15.276 16.6449 15.7859 16.2154 16.2154C15.786 16.6448 15.2761 16.9855 14.715 17.218C14.1538 17.4504 13.5524 17.57 12.9451 17.57Z" stroke="black"/>
</g>
<path opacity="0.2" d="M4.46421 4.46421C6.72804 2.20038 9.79846 0.928571 13 0.928571C16.2015 0.928571 19.272 2.20038 21.5358 4.46421C23.7996 6.72804 25.0714 9.79846 25.0714 13C25.0714 16.0257 24.0093 18.4027 22.4314 20.8763C21.8122 21.8469 21.1269 22.8129 20.397 23.8416C20.187 24.1376 19.9733 24.4389 19.7564 24.7469C18.7939 26.1138 17.7872 27.59 16.8366 29.2536C15.5425 31.5182 14.677 33.1475 14.0861 34.2596C13.8325 34.7369 13.6296 35.119 13.4651 35.4151C13.2302 35.8379 13.0897 36.0551 13 36.1688C12.9103 36.0551 12.7698 35.8379 12.5349 35.4151C12.3704 35.119 12.1674 34.7369 11.9139 34.2596C11.323 33.1475 10.4575 31.5182 9.16337 29.2536C8.21081 27.5866 7.20214 26.1301 6.23843 24.7891C6.00611 24.4658 5.77768 24.1509 5.55368 23.8421C4.8398 22.8581 4.17089 21.9361 3.56459 21.002C1.99519 18.5842 0.928571 16.2069 0.928571 13C0.928571 9.79846 2.20038 6.72804 4.46421 4.46421Z" stroke="black" stroke-width="1.85714"/>
</svg>`;

export const AddressPointMarker = () => {
  const mapEditState = useSelector(selectMapEditState);
  const { lead } = useGetLead();
  const position = { lat: lead?.address.latitude ?? 0, lng: lead?.address.longitude ?? 0 };
  const markerOpacity = useFadingMarker(mapEditState !== `VIEW`, JSON.stringify(position));

  const svgDataUrl = `data:image/svg+xml;charset=UTF-8;base64,` + btoa(OutlinedMarkerSvg);

  if (!lead || markerOpacity === 0) {
    return null;
  }

  return (
    <div id="address-point-marker">
      <Marker
        position={position}
        icon={{
          url: svgDataUrl,
        }}
        opacity={markerOpacity}
      />
    </div>
  );
};
