import { Card } from '@components/Card/Card';
import { RichTextPreview } from '@components/controls/rich-text-preview';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import { mdiPencil } from '@mdi/js';
import Drawer from '@mui/material/Drawer';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { EditTermsAndConditions } from './EditTermsAndConditions';
import { useUpdatePartner } from '@services/api/partners';
import { PARTNER } from '@variables/queryKeys';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { selectIsPartnerUser } from '@redux/reducers/slices/user';

export interface TermsAndConditionsTabProps {
  partner: PartnerDto | null;
}

export const TermsAndConditionsTab = ({ partner }: TermsAndConditionsTabProps) => {
  const {
    translate,
    translations: {
      users: { details },
    },
  } = useTranslations();
  const isPartnerUser = useSelector(selectIsPartnerUser);
  const queryClient = useQueryClient();
  const [showEditTermsAndConditions, toggleShowEditTermsAndConditions] = useToggle();
  const updatePartner = useUpdatePartner({
    onSuccess: () => {
      queryClient.invalidateQueries(PARTNER);
      toggleShowEditTermsAndConditions();
    },
  });

  return (
    <Card
      title={translate(details.termsAndConditions.header)}
      titleAction={isPartnerUser ? toggleShowEditTermsAndConditions : undefined}
      titleActionIcon={mdiPencil}
    >
      <RichTextPreview richTextAsHtml={partner?.termsAndConditions ?? ``} />
      <Drawer anchor="right" onClose={toggleShowEditTermsAndConditions} open={showEditTermsAndConditions}>
        <EditTermsAndConditions
          partner={partner}
          disableForm={updatePartner.isLoading}
          goBackHandler={toggleShowEditTermsAndConditions}
          updatePartner={updatePartner.mutate}
        />
      </Drawer>
    </Card>
  );
};
