import { selectMapEditState } from '@redux/reducers/slices/leadsPage';
import { useSelector } from 'react-redux';
import { useGetLead } from '@services/api/leads/lead-info';
import { Marker } from '@react-google-maps/api';
import { useFadingMarker } from '@components/maps/GoogleMaps/Markers/useFadingMarker';

type MainPointMarkerProps = {
  position: { lat: number; lng: number };
};
export const MainPointMarker: React.FC<MainPointMarkerProps> = ({ position }) => {
  const mapEditState = useSelector(selectMapEditState);
  const { lead } = useGetLead();
  const markerOpacity = useFadingMarker(mapEditState === `VIEW`, JSON.stringify(position));

  if (!lead || markerOpacity === 0) return null;

  return <Marker position={position} opacity={markerOpacity} />;
};
