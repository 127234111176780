import { FC } from 'react';
import { useGetTranslations } from './useGetWordings';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { ListItem } from '@components/ListMui/ListItem';
import { mdiSelectGroup } from '@mdi/js';

export interface PositionAndNumberOfPanelsProps {
  direction: number;
  slope: number;
  numberOfPanels: number;
}

export const PositionAndNumberOfPanels: FC<PositionAndNumberOfPanelsProps> = ({ direction, slope, numberOfPanels }) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: {
                mapVisualisation: { panelsAmount },
              },
            },
          },
        },
      },
    },
  } = useTranslations();
  const { direction: directionTranslation, slope: slopeTranslation } = useGetTranslations(direction);

  return (
    <ListItem
      title={`${numberOfPanels} ${translate(panelsAmount)}`}
      subtitle={`${directionTranslation} ${direction}°, ${slopeTranslation} ${slope}°`}
      icon={mdiSelectGroup}
    />
  );
};
