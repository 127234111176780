import { SystemSettingsDto, UpdateSystemSettingsRequest } from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { useQueryClient } from 'react-query';
import { SYSTEM_SETTINGS_TEMPLATES } from '@variables/queryKeys';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useCustomQuery } from '@hooks/useCustomQuery';

export type SettingsTemplateFieldsType = keyof Pick<
  SystemSettingsDto,
  | `offerIntroDefaultText`
  | `carChargingProjectDefaultOfferText`
  | `solarEnergyProjectDefaultOfferText`
  | `emptyProjectDefaultOfferText`
  | `energyStorageProjectDefaultOfferText`
>;

const fetchSystemSettings = async () => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/system-settings`, {
    method: `GET`,
    mode: `cors`,
  });
  if (response.ok) {
    const json = await response.json();
    return json as SystemSettingsDto;
  }
  throw getErrorFromFormRequest(response);
};

const putSystemSettings = async (systemSettingsBody: UpdateSystemSettingsRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/system-settings`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify(systemSettingsBody),
  });
  if (!response.ok) {
    const error = await getErrorFromFormRequest(response);
    throw error;
  }
};

export const useGetSettingsTemplates = () => {
  const { data, isLoading } = useCustomQuery({
    queryKey: [SYSTEM_SETTINGS_TEMPLATES],
    queryFn: fetchSystemSettings,
  });

  return {
    settingsTemplates: data,
    isFetchingSettingsTemplates: isLoading,
  };
};

export const useUpdateSettingsTemplates2 = () => {
  const queryClient = useQueryClient();

  const mutation = useCustomMutation({
    mutationFn: putSystemSettings,
    onSuccess: () => {
      queryClient.invalidateQueries(SYSTEM_SETTINGS_TEMPLATES);
    },
  });

  return mutation;
};
