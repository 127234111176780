import { ChipImageList, ChipListChipType } from '@components/controls/react-hook-form-friendly/smart/ChipImageList';
import { ChipList } from '@components/controls/react-hook-form-friendly/smart/ChipList';
import { RailImageDto } from '@generatedTypes/data-contracts';
import Stack from '@mui/material/Stack';
import {
  selectAvailablePanelOrientations,
  selectAvailableRailOrientations,
} from '@redux/reducers/slices/solarEnergyProjectPage';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { SolarEnergyProjectValues } from '../../../SolarEnergyProject';
import { PropertiesFormButtons } from '../PropertiesFormButtons/PropertiesFormButtons';
import { EdgeForm } from './EdgeFormComponents';
import {
  getIdsFromAvailableOptions,
  getValuesFromAttributeRow,
} from '@pages/NewLeads/project/solarEnergyProject/utils/utils';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { mapAttributeRowToOption } from '@pages/NewLeads/Projects/solarEnergyProject/utils';
import { useHandleRemovePatch } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/patch';
import { TypographyWithIconTooltip } from '@components/Tooltip/TypographyWithIconTooltip';

export type PatchStatePropertiesProps = {
  roofIndex: number;
  patchIndex: number;
  disabled?: boolean;
  onContinue: () => void;
  disableContinue?: boolean;
};

export const PatchStateProperties: React.FC<PatchStatePropertiesProps> = ({
  roofIndex,
  patchIndex,
  disabled = false,
  onContinue,
  disableContinue,
}) => {
  const {
    translate,
    translations: {
      common: { recommendedChoices },
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: {
                panelsPlacementHeader,
                panelOrientation,
                numberOfRails: numberOfRailsTranslation,
                railOrientations,
                verticalOrientationOfPanels2RailsWithHorizontalRail,
              },
            },
          },
        },
      },
    },
  } = useTranslations();
  const { control, watch } = useFormContext<SolarEnergyProjectValues>();
  const { remove } = useFieldArray<SolarEnergyProjectValues, `roofs.${number}.patches`>({
    control,
    name: `roofs.${roofIndex}.patches`,
  });
  const selectedPanelOrientation = watch(`roofs.${roofIndex}.patches.${patchIndex}.solarPanelOrientationId`) || 8;
  const selectedNumberOfRails = watch(`roofs.${roofIndex}.patches.${patchIndex}.numberOfRailsId`);

  const { railOrientation, numberOfRails } = useGetSolarEnergyProjectSettings();
  const panelOrientationOptions = useSelector(selectAvailablePanelOrientations);
  const numberOfRailsOptions = getValuesFromAttributeRow(numberOfRails).map(mapAttributeRowToOption);
  const railOrientationOptionsWithAvailability = useSelector(selectAvailableRailOrientations);
  const availableRailOrientationIds = getIdsFromAvailableOptions(railOrientationOptionsWithAvailability);

  const handleRemovePatch = useHandleRemovePatch();

  const getRailOrientationOptionImage = (railImage: RailImageDto) => {
    return (
      railImage.numberOfRailsId === +selectedNumberOfRails &&
      railImage.solarPanelOrientationId === +selectedPanelOrientation
    );
  };

  const railOrientationOptions: ChipListChipType[] = getValuesFromAttributeRow(railOrientation).map(
    ({ id, name, imageUris }) => ({
      src: imageUris.find(getRailOrientationOptionImage)?.imageUri ?? ``,
      value: id,
      title: name ?? ``,
      disabled: !availableRailOrientationIds.includes(id),
    }),
  );

  return (
    <Stack spacing={2}>
      <Stack spacing={4}>
        <EdgeForm roofIndex={roofIndex} patchIndex={patchIndex} disabled={disabled} />
        <Stack spacing={2}>
          <TypographyWithIconTooltip
            typographyVariant="h3"
            title={translate(recommendedChoices)}
            text={translate(verticalOrientationOfPanels2RailsWithHorizontalRail)}
          >
            {translate(panelsPlacementHeader)}
          </TypographyWithIconTooltip>

          <ChipList
            label={translate(panelOrientation)}
            // TODO: hack for https://github.com/react-hook-form/react-hook-form/pull/10490. Remove after version update.
            key={`roofs.${roofIndex}.patches.${patchIndex}.solarPanelOrientationId`}
            chips={panelOrientationOptions.map(({ label, value, disabled }) => ({ label, value, disabled }))}
            control={control}
            name={`roofs.${roofIndex}.patches.${patchIndex}.solarPanelOrientationId`}
            version="large"
            disabled={disabled}
          />
        </Stack>
        <ChipList
          label={translate(numberOfRailsTranslation)}
          // TODO: hack for https://github.com/react-hook-form/react-hook-form/pull/10490. Remove after version update.
          key={`roofs.${roofIndex}.patches.${patchIndex}.numberOfRailsId`}
          chips={numberOfRailsOptions}
          control={control}
          name={`roofs.${roofIndex}.patches.${patchIndex}.numberOfRailsId`}
          version="large"
          disabled={disabled}
        />
        <ChipImageList
          title={translate(railOrientations)}
          // TODO: hack for https://github.com/react-hook-form/react-hook-form/pull/10490. Remove after version update.
          key={`roofs.${roofIndex}.patches.${patchIndex}.railOrientationId`}
          chips={railOrientationOptions}
          control={control}
          name={`roofs.${roofIndex}.patches.${patchIndex}.railOrientationId`}
          disabled={disabled}
        />
        <PropertiesFormButtons
          disableContinue={disableContinue}
          onContinue={onContinue}
          onRemove={handleRemovePatch(remove)}
        />
      </Stack>
    </Stack>
  );
};
