import { MapOptionButton } from '@components/controls/roofVisualisation/mapOptionButton';
import { mdiPlus } from '@mdi/js';
import React from 'react';

export type OnMapRoofStateButtonProps = {
  onCreatePatch: () => void;
  animate?: boolean;
};

export const OnMapRoofStateButton: React.FC<OnMapRoofStateButtonProps> = ({ onCreatePatch, animate }) => (
  <MapOptionButton primaryButton icon={mdiPlus} alt="Add patch" onClick={onCreatePatch} animate={animate} />
);
