import { Months } from '@assets/translations/translations';
import { SolarEnergyProductionMonthlyDto } from '@generatedTypes/data-contracts';
import { Translation } from '@services/hooks/translations/useTranslations';
import { rexelEnergy } from 'src/styles/theme/theme';

export const prepareChartData = (
  monthlyProduction: SolarEnergyProductionMonthlyDto[],
  monthsTranslation: Months,
  translate: (translation: Translation) => string,
) => {
  const result = {
    labels: [] as string[],
    datasets: [
      {
        label: `datasetForMonthsChart`,
        data: [] as number[],
        backgroundColor: rexelEnergy.hover,
      },
    ],
  };

  monthlyProduction &&
    monthlyProduction.forEach((monthProduction) => {
      result.labels.push(translate(monthsTranslation[monthProduction.month as keyof Months]));
      result.datasets[0].data.push(monthProduction.production ? monthProduction.production / 1000 : 0) as number;
    });
  return result;
};
