import { StringTranslationObject } from '@assets/translations/translations';
import { ActionWithConfirmation } from '@components/ActionWithConfirmation';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { Dialog, DialogProps } from '@components/dialog/dialog';
import { mdiTrashCanOutline } from '@mdi/js';
import { IconButton } from '@mui/material';
import { setDialog } from '@redux/actions/global';
import { AppState } from '@redux/reducers';
import { store } from '@redux/store';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import React, { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

type IconDeleteDialogProps = {
  onDelete: () => void;
  iconComponent: React.ReactNode;
  iconColor?: string;
  translationNode: {
    header?: StringTranslationObject;
    content?: StringTranslationObject;
  };
  dataTestId?: string;
};

type TextDeleteDialogProps = {
  onDelete: () => void;
  deleteText?: StringTranslationObject;
  iconPath?: string;
  translationNode: {
    header?: StringTranslationObject;
    content?: StringTranslationObject;
  };
  dataTestId?: string;
};

export const DeleteDialog: React.FC<IconDeleteDialogProps | TextDeleteDialogProps> = (props) => {
  const {
    onDelete,
    translationNode: { header, content },
  } = props;

  const {
    translate,
    translations: {
      common: {
        buttons: { delete: deleteDefaultText },
      },
    },
  } = useTranslations();

  const iconColor = `iconColor` in props ? props.iconColor : undefined;
  const iconComponent =
    `iconComponent` in props ? props.iconComponent : <MdiIconWrapper path={mdiTrashCanOutline} iconColor={iconColor} />;
  const iconPath = `iconPath` in props ? props.iconPath : mdiTrashCanOutline;

  const dialog = useSelector<AppState, DialogProps>((state) => state.Global.dialog);

  const openModalHandler = useCallback(() => {
    if (!dialog.isOpen) {
      store.dispatch(
        setDialog({
          isOpen: true,
          header: header && translate(header),
          content: content && translate(content),
        }),
      );
    }
  }, [dialog.isOpen, header, translate, content]);

  if (`deleteText` in props && header) {
    return (
      <ActionWithConfirmation
        buttonIconPath={iconPath}
        onConfirm={onDelete}
        confirmationPopupTitle={translate(header)}
        confirmationPopupContent={content && translate(content)}
        dataTestId={props.dataTestId}
      >
        {translate(props.deleteText || deleteDefaultText)}
      </ActionWithConfirmation>
    );
  }

  return (
    <>
      <div className="right delete-wrapper">
        <IconButton onClick={openModalHandler} data-testid={props.dataTestId}>
          {iconComponent}
        </IconButton>
      </div>
      {createPortal(
        <Dialog callback={onDelete} dataTestId={props.dataTestId} />,
        document.getElementById(`root`) as HTMLElement,
      )}
    </>
  );
};

export const TextDeleteButton: React.FC<TextDeleteDialogProps> = (props) => {
  return <DeleteDialog {...props} />;
};

export const IconDeleteButton: React.FC<Omit<IconDeleteDialogProps, `iconComponent`>> = ({ iconColor, ...props }) => (
  <DeleteDialog
    iconComponent={<MdiIconWrapper path={mdiTrashCanOutline} iconColor={iconColor} />}
    iconColor={iconColor}
    {...props}
  />
);
