import short from 'short-uuid';
import { Roof, SolarEnergyProject } from '../roofVisualisationTypes';
import { getSolarEnergyProjectWithDeselectedComponents } from './accessors';

export const selectRoof = (solarEnergyProject: SolarEnergyProject, roofId: string): SolarEnergyProject => {
  const cleanSolarEnergyProject = getSolarEnergyProjectWithDeselectedComponents(solarEnergyProject);
  const newRoofs = [...cleanSolarEnergyProject.roofs].map((roof) => ({ ...roof, selected: roof.id === roofId }));
  return { ...cleanSolarEnergyProject, roofs: newRoofs };
};

export const addRoof = (solarEnergyProject: SolarEnergyProject): SolarEnergyProject => {
  const newRoofId = `r${short.generate()}`;
  const newRoof: Roof = {
    id: newRoofId,
    patches: [],
    selected: true,
  };
  const cleanSolarEnergyProject = getSolarEnergyProjectWithDeselectedComponents(solarEnergyProject);
  return {
    ...cleanSolarEnergyProject,
    roofs: [...cleanSolarEnergyProject.roofs.map((roof) => ({ ...roof, selected: false })), newRoof],
  };
};

export const removeRoof = (solarEnergyProject: SolarEnergyProject, idToRemove: string): SolarEnergyProject => {
  const cleanSolarEnergyProject = getSolarEnergyProjectWithDeselectedComponents(solarEnergyProject);
  const newRoofs = cleanSolarEnergyProject.roofs.filter(({ id }) => id !== idToRemove);
  if (newRoofs.length > 0) {
    newRoofs[0].selected = true;
  }
  return {
    ...cleanSolarEnergyProject,
    roofs: newRoofs,
  };
};

export const getNewRoofName = (currentRoofNames: string[], roofNamePrefix: string): string => {
  const highestRoofNameNumber = currentRoofNames.reduce((highestNumber, roofName) => {
    const roofNameNumber = parseInt(roofName.split(` `)[1]);
    if (isNaN(roofNameNumber)) {
      return highestNumber;
    }
    return roofNameNumber > highestNumber ? roofNameNumber : highestNumber;
  }, currentRoofNames.length);
  return `${roofNamePrefix} ${highestRoofNameNumber + 1}`;
};
