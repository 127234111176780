import { TAction } from '@redux/reducers';
import { initState, SelectedUserId, UsersState } from '@redux/reducers/users';

export const USERS_ACTIONS = {
  USERS_LIST: `USERS_LIST`,
  SELECTED_USER_ID: `SELECTED_USER_ID`,
  SELECTED_USER_DETAILS: `SELECTED_USER_DETAILS`,
} as const;

export type UsersAction = TAction<typeof USERS_ACTIONS, UsersState>;

export const setSelectedUserID = (value: SelectedUserId = initState.selectedUserID): UsersAction => ({
  type: USERS_ACTIONS.SELECTED_USER_ID,
  payload: { selectedUserID: value },
});
