import { MapOptionButton } from '@components/controls/roofVisualisation/mapOptionButton';
import { mdiCheck, mdiTrashCanOutline } from '@mdi/js';
import React from 'react';
import {
  useIsSelectedPatch,
  useHandleApplyShape,
} from '../../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/patch';

export type OnMapShapeStateButtonProps = {
  onRemovePatch: () => void;
};

export const OnMapShapeStateButton: React.FC<OnMapShapeStateButtonProps> = ({ onRemovePatch }) => {
  const shapeValid = useIsSelectedPatch();
  const handleApplyShape = useHandleApplyShape();

  return (
    <>
      <MapOptionButton icon={mdiTrashCanOutline} alt="Remove patch" onClick={onRemovePatch} />
      <MapOptionButton
        icon={mdiCheck}
        alt="Apply shape"
        onClick={shapeValid ? handleApplyShape : undefined}
        disabled={!shapeValid}
        primaryButton={true}
      />
    </>
  );
};
