import { FC } from 'react';
import { Control } from 'react-hook-form';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import { ContactDetails } from '@components/controls/react-hook-form-friendly/smart/ContactDetails';
import { LeadZodSchema } from './zodSettings';
import { FormSection } from '@components/forms/MuiFormSection';

export interface BRFAndBusinessSubformProps {
  control: Control<LeadZodSchema>;
}

export const BRFSubform: FC<BRFAndBusinessSubformProps> = ({ control }) => {
  const {
    translate,
    translations: {
      leads: {
        details: { customerDetails },
      },
    },
  } = useTranslations();
  return (
    <>
      <FormSection title={translate(customerDetails.organization)}>
        <InputWrapper control={control} name="organizationName" label={translate(customerDetails.organizationName)} />
        <InputWrapper
          control={control}
          name="organizationNumber"
          label={translate(customerDetails.organizationNumber)}
          mask="999999-9999"
        />
        <InputWrapper
          control={control}
          name="propertyDesignation"
          label={translate(customerDetails.propertyDesignation)}
        />
      </FormSection>
      <ContactDetails control={control} />
    </>
  );
};
