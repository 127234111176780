import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiChevronRight } from '@mdi/js';
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { FC } from 'react';
import { BreadcrumbButton } from './BreadcrumbButton.styled';

export interface BreadCrumb {
  text: string;
  href?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export interface BreadcrumbsProps {
  crumbs: BreadCrumb[];
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ crumbs }) => {
  return (
    <MuiBreadcrumbs separator={<MdiIconWrapper path={mdiChevronRight} />} aria-label="länkstig">
      {crumbs.map((crumb, index) => {
        const last = index === crumbs.length - 1;

        return last || (!crumb.onClick && !crumb.href) ? (
          <Typography key={index} variant="body1">
            {crumb.text}
          </Typography>
        ) : crumb.onClick ? (
          <BreadcrumbButton key={index} onClick={crumb.onClick} disabled={crumb.disabled}>
            {crumb.text}
          </BreadcrumbButton>
        ) : crumb.href ? (
          <BreadcrumbButton key={index} href={crumb.href} disabled={crumb.disabled}>
            {crumb.text}
          </BreadcrumbButton>
        ) : null;
      })}
    </MuiBreadcrumbs>
  );
};
