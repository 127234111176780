import { ProjectAdditionDto, ProjectAdditionTemplateDto } from '@generatedTypes/data-contracts';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { ListItem } from '@components/ListMui/ListItem';
import { Typography } from '@mui/material';

type AdditionsListContentProps = {
  additions: (Omit<ProjectAdditionDto, `projectId`> | ProjectAdditionTemplateDto)[];
  netCostPerHour: number;
  onShowAddEditAdditionForm?: (projectAdditionId?: ProjectAdditionDto[`id`]) => void;
};

export const AdditionsListContent: React.FC<AdditionsListContentProps> = ({
  additions,
  netCostPerHour,
  onShowAddEditAdditionForm,
}) => {
  const {
    translate,
    translations: {
      common: { units },
    },
  } = useTranslations();

  return (
    <>
      {additions.map((addition) => {
        const { id, name, quantity, netPrice, numberOfWorkHours } = addition;
        const hourPrice = (addition as ProjectAdditionDto)?.hourPrice ?? netCostPerHour;
        return (
          <ListItem
            sx={{ paddingInlineStart: 3 }}
            key={id}
            title={<Typography>{name}</Typography>}
            subtitle={
              <Typography variant="subtitle1">
                {quantity ? `${quantity} ${translate(units.piece)}` : ``}
                {numberOfWorkHours ? `, ${numberOfWorkHours} ${translate(units.hour)}` : ``}
              </Typography>
            }
            onClick={() => onShowAddEditAdditionForm?.(id)}
            secondaryAction={<Typography>{netPrice * quantity + hourPrice * numberOfWorkHours} kr</Typography>}
          />
        );
      })}
    </>
  );
};
