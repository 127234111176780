import React, { ChangeEvent, HTMLInputTypeAttribute, useEffect, useState } from 'react';
import InputMask, { Props } from 'react-input-mask';
import { InputErrorElement } from './input-error-element';
import './validated-wrapper.css';
import { emailValidation } from './validations';

type InputWrapperProps = {
  name: string;
  value?: string | number;
  label: string;
  type: HTMLInputTypeAttribute;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  classesForContainer?: string;
  classesInput?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  validateLive?: boolean;
  errorMsg?: string;
  mask?: Props[`mask`];
  placeholder?: string;
};

export const InputWrapper: React.FC<InputWrapperProps> = (props) => {
  const [inputClasses, setInputClasses] = useState(`input-main`);
  const [errorMsg, setErrorMsg] = useState(props.errorMsg);

  useEffect(() => {
    setErrorMsg(props.errorMsg);
  }, [props.errorMsg]);

  useEffect(() => {
    props.classesInput && setInputClasses(props.classesInput);
  }, [props.classesInput]);

  function validateOnChange() {
    if (props.validateLive) {
      const validationResult = emailValidation(`${props.value}`);
      if (!validationResult) {
        setErrorMsg(`Error email incorect`);
      }
    }
  }

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    validateOnChange();
    props.onChange && props.onChange(e);
  };

  return (
    <div className="validated-wrapper fw">
      <div className={`column fw ${props.errorMsg ? ` error` : ``}${props.isRequired ? ` required` : ``}`}>
        <label htmlFor={props.name}>{props.label}</label>
        <InputMask
          type={props.type}
          name={props.name}
          id={props.name}
          className={inputClasses}
          value={props.value}
          onChange={onChangeHandler}
          required={props.isRequired}
          disabled={props.isDisabled}
          mask={props.mask ?? ``}
          maskPlaceholder={null}
          placeholder={props.placeholder}
        />
      </div>
      <InputErrorElement errorMsg={errorMsg} />
    </div>
  );
};
