import { Dropdown } from '@components/Dropdown/Dropdown';
import { KeyValueDisplay } from '@components/KeyValueDisplay';
import { LeadOfferStatus } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import { MenuItem } from '@mui/material';
import { selectIsPartnerUser } from '@redux/selectors/user';
import { useGetOffer, useUpdateOffer } from '@services/api/offers/offers';
import { useGetPartnerUsers } from '@services/api/partners';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';

export const Sender = () => {
  const {
    translate,
    translations: {
      common,
      partners: {
        create: { contactPerson },
      },
    },
  } = useTranslations();
  const [isSenderDropdownOpen, toggleIsSenderDropdownOpen] = useToggle();
  const { offer, isLoading, isOfferLocked } = useGetOffer();
  const isPartner = useSelector(selectIsPartnerUser);
  const { updateOffer, isUpdatingOffer } = useUpdateOffer();
  const { partnerUsers } = useGetPartnerUsers();

  const isOfferCreated = offer?.status === LeadOfferStatus.Created;

  if (!offer && isLoading) return <div>Loading</div>;
  if (!offer) return <div>Error fetching offer</div>;

  const handleClick: MouseEventHandler<HTMLLIElement> = (e) => {
    updateOffer({
      offerId: offer.id,
      introText: offer.introText,
      offerSenderId: Number(e.currentTarget.id),
      numberOfDaysBeforeExpiry: offer.numberOfDaysBeforeExpiry,
    });
    toggleIsSenderDropdownOpen();
  };

  return (
    <KeyValueDisplay
      displayKey={translate(contactPerson)}
      value={
        <Dropdown
          isDropdownOpen={isSenderDropdownOpen}
          toggleIsDropdownOpen={toggleIsSenderDropdownOpen}
          buttonText={
            offer.sender ? `${offer.sender.firstName} ${offer.sender.lastName}` : translate(common.noneSingular)
          }
          disabled={!isPartner || isOfferCreated || isUpdatingOffer || isOfferLocked}
          styles={{ marginInlineEnd: -1 }}
        >
          {partnerUsers.map((user) => (
            <MenuItem
              key={user.id}
              id={String(user.id)}
              onClick={handleClick}
              disabled={isUpdatingOffer}
            >{`${user.firstName} ${user.lastName}`}</MenuItem>
          ))}
        </Dropdown>
      }
    />
  );
};
