import { Stack } from '@mui/material';
import { OnMapButton } from '@components/maps/GoogleMaps/OnMapButton';
import { mdiCheck, mdiClose, mdiPencil, mdiRedo } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { selectMapEditState, setMapEditState } from '@redux/reducers/slices/leadsPage';
import { useGetLead } from '@services/api/leads/lead-info';
import { PutLead, useUpdateLead } from '@services/api/leads/lead-edit';
import { LeadDto } from '@generatedTypes/data-contracts';
import { useTranslations } from '@services/hooks/translations/useTranslations';

const getUpateLeadFieldsFromLeadDto = (lead: LeadDto): PutLead => {
  return {
    id: lead.id,
    customerType: lead.customerType,
    firstName: lead.firstName,
    lastName: lead.lastName,
    email: lead.email,
    phoneNumber: lead.phoneNumber,
    street: lead.address.street,
    city: lead.address.city,
    zipCode: lead.address.zipCode,
    latitude: lead.address.latitude,
    longitude: lead.address.longitude,
    indicatedLatitude: lead.address.indicatedLatitude,
    indicatedLongitude: lead.address.indicatedLongitude,
    propertyDesignation: lead.propertyDesignation,
    organizationName: lead.organizationName,
    organizationNumber: lead.organizationNumber,
  };
};

const EditMapButton = () => {
  const {
    translate,
    translations: {
      leads: {
        details: { mapEdit },
      },
    },
  } = useTranslations();
  const { lead } = useGetLead();
  const mapEditState = useSelector(selectMapEditState);
  const dispatch = useDispatch();
  const addressAlreadySet = lead?.address.indicatedLatitude && lead.address.indicatedLongitude;

  return (
    <OnMapButton
      icon={mdiPencil}
      onClick={() => {
        dispatch(setMapEditState(addressAlreadySet ? `EDIT_POSITION` : `SET_NEW_POSITION`));
      }}
      label={translate(mapEdit.editMapButton)}
      disabled={mapEditState !== `VIEW`}
    />
  );
};

const CancelEditMapButton = () => {
  const dispatch = useDispatch();
  return (
    <OnMapButton
      icon={mdiClose}
      onClick={() => {
        dispatch(setMapEditState(`VIEW`));
      }}
      label={``}
    />
  );
};

type SaveEditMapButtonProps = {
  map: google.maps.Map | null;
};
const SaveEditMapButton: React.FC<SaveEditMapButtonProps> = ({ map }) => {
  const {
    translate,
    translations: {
      leads: {
        details: { mapEdit },
      },
    },
  } = useTranslations();
  const dispatch = useDispatch();
  const { lead } = useGetLead();
  const { updateLead, isLoading } = useUpdateLead({
    onSuccess: () => {
      dispatch(setMapEditState(`VIEW`));
    },
  });

  if (!map || !lead) return null;

  return (
    <OnMapButton
      icon={mdiCheck}
      onClick={() => {
        updateLead({
          ...getUpateLeadFieldsFromLeadDto(lead),
          indicatedLatitude: map.getCenter()?.lat(),
          indicatedLongitude: map.getCenter()?.lng(),
        });
      }}
      label={translate(mapEdit.acceptButton)}
      disabled={isLoading}
    />
  );
};

const ResetEditMapButton: React.FC<SaveEditMapButtonProps> = ({ map }) => {
  const {
    translate,
    translations: {
      leads: {
        details: { mapEdit },
      },
    },
  } = useTranslations();
  const dispatch = useDispatch();
  const { lead } = useGetLead();
  const { updateLead, isLoading } = useUpdateLead({
    onSuccess: () => {
      if (map && lead?.address.latitude && lead?.address.longitude) {
        map.panTo({ lat: lead.address.latitude, lng: lead.address.longitude });
      }
      dispatch(setMapEditState(`VIEW`));
    },
  });

  if (!lead) return null;

  return (
    <OnMapButton
      icon={mdiRedo}
      onClick={() => {
        updateLead({
          ...getUpateLeadFieldsFromLeadDto(lead),
          indicatedLatitude: null,
          indicatedLongitude: null,
        });
      }}
      label={translate(mapEdit.revertButton)}
      disabled={isLoading}
    />
  );
};

export const MapEditPrimaryButtons = () => (
  <Stack direction="row" spacing={2} flexWrap="wrap" m="10px">
    <EditMapButton />
  </Stack>
);

type MapEditSecondaryButtonsProps = {
  map: google.maps.Map | null;
};
export const MapEditSecondaryButtons: React.FC<MapEditSecondaryButtonsProps> = ({ map }) => {
  const mapEditState = useSelector(selectMapEditState);

  if (mapEditState === `VIEW`) {
    return null;
  }

  return (
    <Stack
      direction="row"
      justifyContent="center"
      spacing={2}
      flexWrap="wrap"
      m="10px"
      position="absolute"
      bottom={24}
      left={0}
      width="100%"
      margin={0}
    >
      <CancelEditMapButton />
      <SaveEditMapButton map={map} />
      {mapEditState === `EDIT_POSITION` && <ResetEditMapButton map={map} />}
    </Stack>
  );
};
