import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import {
  InputSuffixWrapper as InputSuffixWrapperDumb,
  InputSuffixWrapperProps as InputSuffixWrapperDumbProps,
} from '../../InputSuffixWrapper';
import { removeLeadingZero } from '@utils/removeLeadingZero';

export interface InputSuffixWrapperSmartProps<T extends FieldValues>
  extends Omit<InputSuffixWrapperDumbProps, `value` | `onChange` | `name` | `onBlur`> {
  control: Control<T>;
  name: Path<T>;
  defaultValue?: PathValue<T, Path<T>>;
}

export const InputSuffixWrapperSmart = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  ...restProps
}: InputSuffixWrapperSmartProps<T>) => {
  const { field } = useController({ control, name, defaultValue });

  return (
    <InputSuffixWrapperDumb
      value={field.value}
      onChange={(event) => field.onChange(removeLeadingZero(event.target.value))}
      name={name}
      onBlur={field.onBlur}
      {...restProps}
    />
  );
};
