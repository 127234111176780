import { CardWithSlidingContent } from '@components/CardWithSlideContent/CardWithSlideContent';
import { DougnutChart } from '@components/charts/DoughnutChart/DoughnutChart';
import { Stack, Typography } from '@mui/material';
import { formatCurrency } from '@pages/NewLeads/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';

type ReturnOfInvestementChartTabProps = {
  returnOfInvestmentData: number[];
  percentageReturn: number;
  reducedCost: number;
  earning: number;
  chartHeight: number;
};

export const ReturnOfInvestementChartTab: React.FC<ReturnOfInvestementChartTabProps> = ({
  returnOfInvestmentData,
  percentageReturn,
  earning,
  reducedCost,
  chartHeight,
}) => {
  const {
    translate,
    translations: {
      common: { totalCost },
      leads: {
        offer: { solarEnergyOffer },
      },
    },
  } = useTranslations();

  return (
    <Stack key="roi-chart" direction="row" alignItems="center" alignContent="space-between" width="100%">
      <Stack flex={1} spacing={1}>
        <div>
          <Typography variant="subtitle2">{translate(solarEnergyOffer.ownElectricity)}</Typography>
          <Typography variant="subtitle2">{formatCurrency(reducedCost)}</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">{translate(solarEnergyOffer.soldElectricty)}</Typography>
          <Typography variant="subtitle2">{formatCurrency(earning)}</Typography>
        </div>
      </Stack>
      <CardWithSlidingContent.FormSizeIndicator>
        <DougnutChart
          data={returnOfInvestmentData}
          arcsColors={[`hover`, `lightBase`]}
          middleText={[`${percentageReturn}% av`, translate(totalCost)]}
          size={chartHeight}
        />
      </CardWithSlidingContent.FormSizeIndicator>
    </Stack>
  );
};
