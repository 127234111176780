import { styled } from '@mui/material/styles';

export const Wrapper = styled(`div`)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${({ theme }) => theme.spacing(2)};
  margin: ${({ theme }) => theme.spacing(7.5)} auto;
  max-width: 980px;

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    display: flex;
    flex-direction: column;
    margin: ${({ theme }) => theme.spacing(7.5, 0)};
    overflow: hidden;
    max-width: 100vw;
    padding: ${({ theme }) => theme.spacing(0, 2)};
  }
`;

export const LeftColumn = styled(`div`)`
  grid-column: 1 / span 8;
`;

export const RightColumn = styled(`div`)`
  grid-column: 9 / span 4;
`;
