import { WebTypography } from '@components/Typography/WebTypography';
import { Box, Skeleton } from '@mui/material';

import * as S from './OfferPage.styled';

export const OfferPageLoader = () => (
  <S.Wrapper>
    <S.LeftColumn>
      <WebTypography variant="h1" py={3}>
        <Skeleton variant="text" width="70%" />
      </WebTypography>
      <WebTypography variant="h2" mb={1}>
        <Skeleton variant="text" width="50%" />
      </WebTypography>
      <WebTypography mb={1}>
        <Skeleton variant="text" width="40%" />
      </WebTypography>
      <WebTypography variant="subtitle1" mb={0.5}>
        <Skeleton variant="text" width="30%" />
      </WebTypography>
      <WebTypography variant="subtitle1" mb={2}>
        <Skeleton variant="text" width="30%" />
      </WebTypography>
      <WebTypography mb={3}>
        <Skeleton variant="text" width="45%" />
      </WebTypography>
      <WebTypography variant="h3" mb={1}>
        <Skeleton variant="text" width="60%" />
      </WebTypography>
      <WebTypography mb={1}>
        <Skeleton variant="text" width="40%" />
      </WebTypography>
      <WebTypography mb={1}>
        <Skeleton variant="text" width="40%" />
      </WebTypography>
      <WebTypography mb={1}>
        <Skeleton variant="text" width="40%" />
      </WebTypography>
      <Box sx={{ aspectRatio: `16/9` }}>
        <Skeleton variant="rectangular" height="100%" />
      </Box>
    </S.LeftColumn>
    <S.RightColumn>
      <Skeleton height={312} />
    </S.RightColumn>
  </S.Wrapper>
);
