import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const StyledColumnLayout = styled(`div`)(({ theme }) => ({
  display: `grid`,
  gridTemplateColumns: `repeat(12, 1fr)`,
  gridGap: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  height: `100%`,
  overflow: `hidden`,
}));

export const ListColumn = styled(`div`)(({ theme }) => ({
  padding: theme.spacing(2),
  gridColumn: `span 3`,
  backgroundColor: theme.palette.background.paper,
  overflow: `hidden`,
  display: `flex`,
  flexDirection: `column`,
  minHeight: 0,
  position: `relative`,

  [theme.breakpoints.down(`lg`)]: {
    gridColumn: `span 4`,
  },

  [theme.breakpoints.down(`md`)]: {
    gridColumn: `span 12`,
  },
}));

export const ListWrapper = styled(Stack)`
  flex: 1;
  overflow-y: auto;
  height: 100%;
`;
export const Nav = styled(`nav`)(({ theme }) => ({
  display: `flex`,
  alignItems: `center`,
  paddingBottom: theme.spacing(3),
  justifyContent: `space-between`,
}));

export interface ContentStyleProps {
  isOpen: boolean;
}

export const Content = styled(`main`, { shouldForwardProp: (prop) => prop !== `isOpen` })<ContentStyleProps>(
  ({ theme, isOpen }) => ({
    gridColumnEnd: `span 9`,
    padding: theme.spacing(2, 2, 10, 2),
    paddingInlineStart: 0,
    overflowY: `scroll`,

    [theme.breakpoints.down(`lg`)]: {
      gridColumnEnd: `span 8`,
    },
    [theme.breakpoints.down(`md`)]: {
      paddingLeft: theme.spacing(2),
      width: `100%`,
      gridColumnEnd: `unset`,
      position: `absolute`,
      left: 0,
      top: 0,
      zIndex: 1,
      backgroundColor: theme.palette.background.default,
      height: `100%`,
      transform: `translateX(${isOpen ? 0 : `100%`})`,
      transition: `transform 5s ease-in-out`,
    },
  }),
);

export const Header = styled(`header`)(({ theme }) => ({
  paddingBlockEnd: theme.spacing(3),
  paddingInline: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBlockEnd: theme.spacing(1),
  position: `relative`,
  zIndex: 1,
}));

export const FabWrapper = styled(`div`)(({ theme }) => ({
  position: `absolute`,
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

export const ColumnLayoutTopMobileBar = styled(`div`)(({ theme }) => ({
  display: `none`,
  [theme.breakpoints.down(`md`)]: {
    display: `block`,
  },
}));

export const ColumnLayoutMobileScrollHeaderWrapper = styled(`div`, {
  shouldForwardProp: (prop) => prop !== `visible`,
})<{ visible: boolean }>(({ theme, visible }) => ({
  display: `none`,
  [theme.breakpoints.down(`md`)]: {
    display: `block`,
    position: `fixed`,
    transition: `transform 0.1s ease-in-out`,
    transform: `translateY(${visible ? 0 : `-100%`})`,
    zIndex: 2,
    width: `100%`,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
}));
