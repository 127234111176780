import { useDispatch, useSelector } from 'react-redux';

import { DropdownControl, DropdownValue } from '@components/controls/dropdown-control';
import { useFetchDealer } from './useFetchDealer';
import { selectSelectedDealerId, setSelectedDealerId } from '@redux/reducers/slices/dealerUser';
import { Box, Typography } from '@mui/material';
import { useGetUserData } from '@services/api/users/getUniversalUserData';

export const SelectDealers = () => {
  const { user } = useGetUserData();
  const selectedDealerIdFromStore = useSelector(selectSelectedDealerId);
  const { isDealerUser, isLoading, values } = useFetchDealer();
  const selectedDealerIndex = values.findIndex((value) => value.value === selectedDealerIdFromStore);
  const dispatch = useDispatch();
  const name = `${user?.firstName} ${user?.lastName}`;

  if (!values.length || !isDealerUser) {
    return null;
  }

  const handleClick = (dropdownValue: DropdownValue) => {
    dispatch(setSelectedDealerId(dropdownValue.value));
  };

  return (
    <Box sx={{ marginBlockStart: 2, marginInline: 2 }}>
      <Typography variant="h2" className="bold">
        {name}
      </Typography>
      <DropdownControl
        values={values}
        onValueClick={handleClick}
        isDisabled={isLoading}
        selectedValueIndex={selectedDealerIndex}
      />
    </Box>
  );
};
