import { CarChargingProjectSettingsDto, ProductAttributeType } from '@generatedTypes/data-contracts';
import { DropdownOption } from '@hooks/useForm/useFormTypes';
import { mapAttributeRowToOption } from '@pages/NewLeads/Projects/solarEnergyProject/utils';

export const getOptionsFromSettings = (settings: Partial<CarChargingProjectSettingsDto> | null) => {
  if (!settings) {
    return null;
  }
  const options: Partial<Record<ProductAttributeType, DropdownOption[]>> = {};
  options[ProductAttributeType.CarChargerSocketPower] =
    settings.carChargerSocketPower?.values?.map(mapAttributeRowToOption) ?? [];
  options[ProductAttributeType.CarChargerSocketsPerCharger] =
    settings.carChargerSocketsPerCharger?.values?.map(mapAttributeRowToOption) ?? [];
  options[ProductAttributeType.RouterSwitchType] =
    settings.routerSwitchType?.values?.map(mapAttributeRowToOption) ?? [];
  options[ProductAttributeType.RouterSwitchPorts] =
    settings.routerSwitchPorts?.values?.map(mapAttributeRowToOption) ?? [];
  options[ProductAttributeType.CarChargerMounting] =
    settings.carChargerMounting?.values?.map(mapAttributeRowToOption) ?? [];
  options[ProductAttributeType.CarChargerPlacement] =
    settings.carChargerPlacement?.values?.map(mapAttributeRowToOption) ?? [];
  return options;
};
