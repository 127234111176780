import { CreateDealerRequest } from '@generatedTypes/data-contracts';
import { DealerForm } from './DealerForm';
import { useCreateDealer } from '@services/api/dealers';
import { useNavigate } from 'react-router-dom';

interface NewDealerProps {
  onCancel: () => void;
}

export const NewDealer = ({ onCancel }: NewDealerProps) => {
  const createDealer = useCreateDealer();
  const navigate = useNavigate();

  const handleSubmit = async (dealer: CreateDealerRequest) => {
    const response = await createDealer.mutateAsync(dealer);
    navigate(`/dealers/${response}`);
  };

  return (
    <DealerForm
      onSubmit={handleSubmit}
      closeModal={onCancel}
      errors={createDealer.validationErrors?.errors}
      isLoading={createDealer.isLoading}
    />
  );
};
