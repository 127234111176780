import Stack from '@mui/material/Stack';
import { Skeleton } from '@mui/material';

const ProductListItemSkeletonRow = () => (
  <Stack direction="row" spacing={11} width="100%">
    <Skeleton variant="rectangular" height={40} sx={{ flex: 5 }} />
    <Skeleton variant="rectangular" height={40} sx={{ flex: 1 }} />
  </Stack>
);

export const ProductsListSkeleton = () => (
  <Stack spacing={2}>
    <ProductListItemSkeletonRow />
    <ProductListItemSkeletonRow />
    <ProductListItemSkeletonRow />
  </Stack>
);
