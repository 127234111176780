import { SubscriptionDto } from '@generatedTypes/data-contracts';
import { authFetch } from '../utils';
import { SUBSCRIPTIONS } from '@variables/queryKeys';
import { useCustomQuery } from '@hooks/useCustomQuery';

const fetchSubscriptions = async () => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/subscriptions`, {
    method: `GET`,
    mode: `cors`,
  });
  const json = await response.json();
  if (response.ok) {
    return json as SubscriptionDto[];
  }
  throw new Error(json.message);
};

export const useGetSubscriptions = () => {
  const { data, isLoading, error } = useCustomQuery({
    queryFn: fetchSubscriptions,
    queryKey: [SUBSCRIPTIONS],
  });

  return {
    subscriptions: data ?? null,
    isLoading: isLoading,
    error: error,
  };
};
