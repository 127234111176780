import { ColumnLayoutContent } from '@components/Layout/ColumnLayout/ColumnLayoutContent';
import { PageHeader } from '@components/PageHeader/PageHeader';
import Stack from '@mui/material/Stack';
import { useGetSettingsTemplates } from '@services/api/settingsTemplates/settingsTemplates';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { OfferSettingsCardWithEdit } from './OfferSettingCardWithEdit';
import { selectFeatureEnabled, selectIsPartnerUser } from '@redux/reducers/slices/user';

export const SystemSettings = () => {
  const {
    translate,
    translations: {
      systemSettings: {
        systemSettingsInfoHeader,
        systemSettingsInfoCards: {
          offerIntroDefaultText,
          carChargingProjectDefaultOfferText,
          solarEnergyProjectDefaultOfferText,
          emptyProjectDefaultOfferText,
          energyStorageProjectDefaultOfferText,
        },
      },
    },
  } = useTranslations();
  const isPartnerUser = useSelector(selectIsPartnerUser);
  const { settingsTemplates } = useGetSettingsTemplates();
  const isCarChargingEnabled = useSelector(selectFeatureEnabled(`car-charging-project`));
  const isEnergyStorageEnabled = useSelector(selectFeatureEnabled(`energy-storage-project`));

  if (isPartnerUser) {
    return <Navigate to="/system-settings/company-information" />;
  }

  return (
    <ColumnLayoutContent isOpen header={<PageHeader title={translate(systemSettingsInfoHeader)} />}>
      <Stack gap={2} className="cards__wrapper">
        <OfferSettingsCardWithEdit
          settingsTemplates={settingsTemplates}
          templateToEdit="offerIntroDefaultText"
          title={translate(offerIntroDefaultText)}
        />
        {isCarChargingEnabled && (
          <OfferSettingsCardWithEdit
            settingsTemplates={settingsTemplates}
            templateToEdit="carChargingProjectDefaultOfferText"
            title={translate(carChargingProjectDefaultOfferText)}
          />
        )}
        <OfferSettingsCardWithEdit
          settingsTemplates={settingsTemplates}
          templateToEdit="solarEnergyProjectDefaultOfferText"
          title={translate(solarEnergyProjectDefaultOfferText)}
        />
        {isEnergyStorageEnabled && (
          <OfferSettingsCardWithEdit
            settingsTemplates={settingsTemplates}
            templateToEdit="energyStorageProjectDefaultOfferText"
            title={translate(energyStorageProjectDefaultOfferText)}
          />
        )}
        <OfferSettingsCardWithEdit
          settingsTemplates={settingsTemplates}
          templateToEdit="emptyProjectDefaultOfferText"
          title={translate(emptyProjectDefaultOfferText)}
        />
      </Stack>
    </ColumnLayoutContent>
  );
};
