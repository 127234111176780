import { AriaRole, FC, MouseEventHandler, ReactNode } from 'react';
import { BorderedListItem, ListItemButtonBorder } from './ListItem.styled';
import { ListItemContent, ListItemContentProps } from './ListItemContent';

export interface ListItemBorderProps extends ListItemContentProps {
  onClick?: MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
  selected?: boolean;
  secondaryAction?: ReactNode;
  role?: AriaRole;
  id?: string;
  backgroundColor?: string;
}

export const ListItemBordered: FC<ListItemBorderProps> = ({
  onClick,
  disabled,
  selected,
  secondaryAction,
  role,
  id,
  backgroundColor,
  ...listItemContentProps
}) => (
  <BorderedListItem secondaryAction={secondaryAction} role={role} backgroundColor={backgroundColor}>
    <ListItemButtonBorder onClick={onClick} disabled={disabled} selected={selected} id={id}>
      <ListItemContent {...listItemContentProps} />
    </ListItemButtonBorder>
  </BorderedListItem>
);
