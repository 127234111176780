import { authFetch, getErrorFromFormRequest } from '@services/api/utils';
import { AssignSubjectToLeadRequest } from '@generatedTypes/data-contracts';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useQueryClient } from 'react-query';
import { LEAD, LEADS } from '@variables/queryKeys';

interface PutAssignments extends AssignSubjectToLeadRequest {
  id: number;
}

const assignPartnerAndDealerToLead = async ({ id, ...leadDetails }: PutAssignments) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/leads/${id}/assignments`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify(leadDetails),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

export const useAssignPartnerAndDealerToLead = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: assignPartnerAndDealerToLead,
    onSuccess: () => {
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(LEAD);
    },
  });

  return {
    assignPartnerAndDealerToLead: mutate,
    assignPartnerAndDealerToLeadAsync: mutateAsync,
    isLoadingAssignPartnerAndDealerToLead: isLoading,
    assignPartnerAndDealerToLeadValidationError: validationErrors,
  };
};
