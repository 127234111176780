import { authFetch } from '@services/api/utils';
import { ProductCategoryRowDto } from '@generatedTypes/data-contracts';
import { PRODUCT_CATEGORIES } from '@variables/queryKeys';
import { useSelector } from 'react-redux';
import { selectCurrentProductCategoryId } from '@redux/selectors/lead';
import { useCustomQuery } from '@hooks/useCustomQuery';

export const fetchProductCategories = async () => {
  const url = `${process.env.PROTECTED_API_URL}/product-categories?`;
  const response = await authFetch(url, {
    method: `GET`,
    mode: `cors`,
  });
  const json = await response.json();
  if (response.ok) {
    return json as ProductCategoryRowDto[];
  }
  throw new Error(json.message);
};

export const useGetProductCategories = () => {
  const { data, isLoading } = useCustomQuery({
    queryKey: PRODUCT_CATEGORIES,
    queryFn: fetchProductCategories,
  });

  return {
    productCategories: data || [],
    isLoadingProductCategories: isLoading,
  };
};

export const useGetProductCategory = () => {
  const productCategory = useSelector(selectCurrentProductCategoryId);

  const { productCategories } = useGetProductCategories();

  return productCategories.find((category: ProductCategoryRowDto) => category.id === productCategory);
};
