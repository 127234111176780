import * as S from './LeftNavLink.styled';
import { LeftNavLinkContentTwoRowsProps } from './types';

export const LeftNavLinkContentTwoRows = ({
  selected,
  title,
  rightBottom,
  rightTop,
  leftBottom,
}: LeftNavLinkContentTwoRowsProps) => {
  return (
    <S.Wrapper selected={selected}>
      <S.TitleWrapper>
        <S.Title selected={selected} variant="body2">
          {title}
        </S.Title>
      </S.TitleWrapper>
      {rightTop && (
        <S.RightTop>
          <S.StyledTypography selected={selected} textAlign="right" fontSize={12}>
            {rightTop}
          </S.StyledTypography>
        </S.RightTop>
      )}
      {leftBottom && (
        <S.LeftBottom>
          <S.StyledTypography selected={selected} fontSize={14}>
            {leftBottom}
          </S.StyledTypography>
        </S.LeftBottom>
      )}
      {rightBottom && (
        <S.RightBottom>
          <S.StyledTypography textAlign="right" selected={selected} fontSize={14}>
            {rightBottom}
          </S.StyledTypography>
        </S.RightBottom>
      )}
    </S.Wrapper>
  );
};
