import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SolarEnergyProjectValues } from '../../SolarEnergyProject';

interface UseEffectOnRoofPropertiesChangeProps {
  roofIndex: number;
  path: keyof Pick<
    SolarEnergyProjectValues[`roofs`][number],
    | `distanceBetweenFolds`
    | `tinOrMetalThicknessId`
    | `tileWidth`
    | `tileHeight`
    | `solarPanelAttachmentId`
    | `solarPanelAttachmentId`
    | `lathDimensionId`
    | `distanceBetweenTopsTinOrMetal`
    | `distanceBetweenFolds`
  >;
  defaultValue: number | null | undefined;
}

export const useEffectOnRoofPropertiesChange = ({
  roofIndex,
  path,
  defaultValue,
}: UseEffectOnRoofPropertiesChangeProps) => {
  const { setValue, control, getValues } = useFormContext<SolarEnergyProjectValues>();
  const roofMaterialId = useWatch({ control, name: `roofs.${roofIndex}.roofMaterialId` });
  const [oldRoofMaterialId, setOldRoofMaterialId] = useState(roofMaterialId);
  const isValueNull = getValues(`roofs.${roofIndex}.${path}`) === null;
  useEffect(() => {
    if (oldRoofMaterialId !== roofMaterialId || isValueNull) {
      setOldRoofMaterialId(roofMaterialId);
      setValue(`roofs.${roofIndex}.${path}`, defaultValue);
    }
  }, [defaultValue, isValueNull, oldRoofMaterialId, path, roofIndex, roofMaterialId, setValue]);

  return { formControl: control };
};
