import TextFieldCore, { TextFieldProps as TextFieldPropsCore } from '@mui/material/TextField';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

export interface TextFieldProps<T extends FieldValues>
  extends Omit<TextFieldPropsCore, `value` | `onChange` | `errorMessage` | `name` | `onBlur` | `type`> {
  control: Control<T>;
  name: Path<T>;
}

export const MuiInput = <T extends FieldValues>({ control, name, helperText, ...restProps }: TextFieldProps<T>) => {
  const { field, fieldState } = useController({ control, name });
  return (
    <TextFieldCore
      {...restProps}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      helperText={helperText || fieldState.error?.message}
      error={!!fieldState.error}
    />
  );
};
