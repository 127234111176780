import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiCursorMove, mdiMagnifyPlusOutline, mdiTrashCanOutline } from '@mdi/js';
import { Box, IconButton, Slider, Stack } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Cropper, { Area, MediaSize } from 'react-easy-crop';
import { theme } from 'src/styles/theme/theme';
import './ImageUploader.css';
import { getCroppedImageFile } from './imageUtilities';

export type AspectRatio = `16:9` | `4:3`;

interface ImageUploaderProps {
  onImageUpload: (image: File) => void;
  onImageDelete: () => void;
  currentImage: string | null | undefined;
  aspectRatio: AspectRatio;
}

const minZoom = 0.5;
const maxZoom = 3;
const imageContainerWidth = 342;

export const ImageUploader = ({ onImageUpload, onImageDelete, currentImage, aspectRatio }: ImageUploaderProps) => {
  const [currentlySavedImage, setCurrentlySavedImage] = useState(currentImage);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(``);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const aspectRatioDivision = aspectRatio === `16:9` ? 16 / 9 : 4 / 3;

  const getCroppedImage = useCallback(
    async (areaPixels: Area | undefined) => {
      if (areaPixels) {
        try {
          const croppedImage = await getCroppedImageFile(uploadedImageUrl, areaPixels, 0);
          if (croppedImage) {
            onImageUpload(croppedImage);
          }
        } catch (_error) {
          // Do nothing on error
        }
      }
    },
    [onImageUpload, uploadedImageUrl],
  );

  const {
    translate,
    translations: {
      common: { deleteImage },
      partners: { create },
    },
  } = useTranslations();

  useEffect(() => {
    setCurrentlySavedImage(currentImage);
  }, [currentImage]);

  function inputFileOnchangeHandler(event: ChangeEvent<HTMLInputElement>): void {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedImageUrl(URL.createObjectURL(file));
    }
  }

  function removeUploadedImage(): void {
    if (uploadedImageUrl || currentlySavedImage) {
      setUploadedImageUrl(``);
      setCurrentlySavedImage(undefined);
      onImageDelete();
    }
  }

  function onCropComplete(_croppedArea: Area, croppedAreaPixels: Area) {
    getCroppedImage(croppedAreaPixels);
  }

  function handleZoomSliderChange(_event: Event, newValue: number | number[]) {
    setZoom(newValue as number);
  }

  const initialCropSize = useMemo(() => {
    const calculatedWidth = imageContainerWidth - imageContainerWidth * 0.1;
    const calculatedHeight = (1 / aspectRatioDivision) * calculatedWidth;

    return {
      width: calculatedWidth,
      height: calculatedHeight,
    };
  }, [aspectRatioDivision]);

  function onImageLoaded(mediaSize: MediaSize) {
    const mediaSizeAspectRatio = mediaSize.width / mediaSize.height;
    const calculatedZoom =
      mediaSize.width > mediaSize.height && mediaSizeAspectRatio >= aspectRatioDivision
        ? initialCropSize.width / mediaSize.width
        : initialCropSize.height / mediaSize.height;

    setCrop({ x: 0, y: 0 });
    setZoom(calculatedZoom);
  }

  return (
    <div className="image-uploader-wrapper">
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
        <p>{translate(create.logoTypeHeading)}</p>
        <Stack direction="row" spacing={1}>
          <IconButton aria-label={translate(deleteImage)} onClick={removeUploadedImage}>
            <MdiIconWrapper path={mdiTrashCanOutline} />
          </IconButton>
        </Stack>
      </Stack>
      {uploadedImageUrl && !currentlySavedImage ? (
        <div className="cropper-wrapper">
          <Cropper
            image={uploadedImageUrl}
            onMediaLoaded={onImageLoaded}
            crop={crop}
            cropSize={initialCropSize}
            zoom={zoom}
            minZoom={minZoom}
            maxZoom={maxZoom}
            aspect={aspectRatio === `16:9` ? 16 / 9 : 4 / 3}
            showGrid={false}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            restrictPosition={false}
          />
        </div>
      ) : currentlySavedImage ? (
        <img src={currentlySavedImage} alt="Uploaded image" className="logotype" />
      ) : (
        <label htmlFor="image-uploader-input" className="image-uploader-label">
          {translate(create.logoTypeUploaderText)}
          <input
            type="file"
            name="image-uploader-input"
            id="image-uploader-input"
            onChange={(event) => inputFileOnchangeHandler(event)}
          />
        </label>
      )}
      <Stack sx={{ mt: 1, fontSize: theme.typography.subtitle2 }} spacing={2}>
        <span>{translate(create.logoTypeFileTypes)}</span>
        {uploadedImageUrl && !currentlySavedImage ? (
          <>
            <Stack direction="row" spacing={1} alignItems="center">
              <MdiIconWrapper path={mdiMagnifyPlusOutline}></MdiIconWrapper>
              {translate(create.logoTypeInstructions1)}
              <MdiIconWrapper path={mdiCursorMove}></MdiIconWrapper>
              {translate(create.logoTypeInstructions2)}
            </Stack>
            <Box>
              <Slider onChange={handleZoomSliderChange} min={minZoom} max={maxZoom} step={0.0001} value={zoom} />
            </Box>
          </>
        ) : (
          <></>
        )}
      </Stack>
    </div>
  );
};
