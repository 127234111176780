import { useGetPartnerProjectAdditionsTemplates } from '@services/api/partners';
import { useCreateProjectAdditionBatch } from '@services/api/projectAdditions/projectAdditionsAdd';
import { useSelector } from 'react-redux';
import { selectCurrentProjectId } from '@redux/selectors/lead';
import { ProjectAdditionTemplateSelection } from './ProjectAdditionTemplateSelection';

type ProjectAdditionTemplateSelectionWrapperProps = {
  onClickNewAddition: () => void;
  onClose: () => void;
};

export const ProjectAdditionTemplateSelectionWrapper: React.FC<ProjectAdditionTemplateSelectionWrapperProps> = ({
  onClickNewAddition,
  onClose,
}) => {
  const { partnerProjectAdditionsTemplates } = useGetPartnerProjectAdditionsTemplates();
  const { addProjectAdditionBatch } = useCreateProjectAdditionBatch();
  const currentProjectId = useSelector(selectCurrentProjectId);

  const onSubmitTemplates = (templateIds: number[]) => {
    onClose();
    if (!currentProjectId) return;
    const projectAdditionsToAdd = partnerProjectAdditionsTemplates?.filter((template) =>
      templateIds.includes(template.id),
    );
    addProjectAdditionBatch({ projectAddition: projectAdditionsToAdd, projectId: currentProjectId });
  };

  return (
    <ProjectAdditionTemplateSelection
      templates={partnerProjectAdditionsTemplates}
      onClickNewAddition={onClickNewAddition}
      onSubmitTemplates={onSubmitTemplates}
      onClose={onClose}
    />
  );
};
