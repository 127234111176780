import { ImageUploader } from '@components/ImageUploader/ImageUploader';
import { ColumnItem } from '@components/columnLayout/utils';
import { FormSection } from '@components/forms/MuiFormSection';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { useDeletePartnerLogo, useUpdatePartnerLogo } from '@services/api/partners/partners';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback, useState } from 'react';

type UploadPartnerLogoProps = {
  goBackHandler: () => void;
  disableForm: boolean;
  partner: PartnerDto | null;
};

export const UploadPartnerLogo: ColumnItem<UploadPartnerLogoProps> = ({ partner, goBackHandler, disableForm }) => {
  const [image, setImage] = useState<File | undefined>();
  const { mutate: uploadLogoForPartner } = useUpdatePartnerLogo({ onSuccess: goBackHandler });
  const { mutate: deleteLogoForPartner } = useDeletePartnerLogo({ onSuccess: goBackHandler });

  const {
    translate,
    translations: {
      partners: { create },
    },
  } = useTranslations();

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (partner) {
      if (image) {
        uploadLogoForPartner({ partnerId: partner?.id, file: image });
      } else {
        deleteLogoForPartner(partner?.id);
      }
    }
  }

  const imageUploaded = useCallback((uploadedImage: File) => {
    setImage(uploadedImage);
  }, []);

  const imageDeleted = useCallback(() => {
    setImage(undefined);
  }, []);

  return (
    <FormWrapper onCancel={goBackHandler} onSubmit={onSubmit} disabled={disableForm}>
      <FormSection title={translate(create.logoType)}>
        <ImageUploader
          onImageUpload={imageUploaded}
          onImageDelete={imageDeleted}
          currentImage={partner?.logotypeUri}
          aspectRatio="4:3"
          key={`partner-logotype-uploader-${partner?.id}`}
        />
      </FormSection>
    </FormWrapper>
  );
};
