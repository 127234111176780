import { Control, FieldValues, Path } from 'react-hook-form';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { InputWrapper } from './input-wrapper';
import { FormSection } from '@components/forms/MuiFormSection';

interface SubformValues extends FieldValues {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

export interface ContactDetailsProps<T extends SubformValues> {
  control: Control<T>;
}

export const ContactDetails = <T extends SubformValues>({ control }: ContactDetailsProps<T>) => {
  const {
    translate,
    translations: {
      leads: {
        details: { customerDetails },
      },
    },
  } = useTranslations();
  return (
    <FormSection title={translate(customerDetails.contactHeader)}>
      <InputWrapper control={control} name={`firstName` as Path<T>} label={translate(customerDetails.firstName)} />
      <InputWrapper control={control} name={`lastName` as Path<T>} label={translate(customerDetails.lastName)} />
      <InputWrapper control={control} name={`email` as Path<T>} label={translate(customerDetails.email)} />
      <InputWrapper
        control={control}
        name={`phoneNumber` as Path<T>}
        label={translate(customerDetails.phone)}
        type="tel"
      />
    </FormSection>
  );
};
