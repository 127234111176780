import { useEffect } from 'react';
import { ProductionSimpleColumnChart } from '@components/charts/ProductionSimpleColumn/ProductionSimpleColumnChart';
import { OfferAgreementSolarEnergyBidDto } from '@generatedTypes/data-contracts';
import { bigNumberFormatter, kiloWattHourFormatter } from '@pages/NewLeads/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { TO_KILO_SI } from '@utils/math';
import Typography from '@mui/material/Typography';

import * as S from './offer.styled';
import { useQueryClient } from 'react-query';
import { useToggle } from '@hooks/useToggle';
import { TypographyWithIconTooltip } from '@components/Tooltip/TypographyWithIconTooltip';
import { ReturnFromInvestmentForm } from '@pages/offer/components/SolarEnergyProject/ReturnOfInvestementForm/ReturnForInvestmentForm';
import { Stack } from '@mui/material';
import { useGetOfferAgreement } from '@services/api/offerAgreements/offerAgreements';

type SolarCalculationProps = {
  solarEnergyBid: OfferAgreementSolarEnergyBidDto | undefined;
  projectGrossPrice: number;
  showPayoff: boolean;
};

export const SolarCalculation: React.FC<SolarCalculationProps> = ({
  solarEnergyBid,
  projectGrossPrice,
  showPayoff,
}) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { solarEnergyOffer },
      },
    },
  } = useTranslations();

  const queryClient = useQueryClient();
  const [isFormOpen, toggleIsFormOpen] = useToggle();
  const { offerAgreement } = useGetOfferAgreement();

  const payoffInitValues =
    showPayoff && offerAgreement?.details?.payoff
      ? {
          sellingPrice: offerAgreement.details.payoff.sellingPrice,
          purchasePrice: offerAgreement.details.payoff.purchasePrice,
          soldEnergyFactor: offerAgreement.details.payoff.ownToSoldEnergyFactor,
        }
      : undefined;

  useEffect(() => {
    if (!queryClient.isMutating()) {
      isFormOpen && toggleIsFormOpen();
    }
    // trigger only on queryClient.isMutating
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient.isMutating()]);

  return (
    <Stack direction="row" spacing={2}>
      <S.Card
        title={
          <TypographyWithIconTooltip
            typographyVariant="h3"
            web
            text={translate(solarEnergyOffer.calculationProductionTooltip)}
          >
            {translate(solarEnergyOffer.calculationProduction)}
          </TypographyWithIconTooltip>
        }
      >
        <Stack spacing={3}>
          <Stack>
            <Stack direction="row" alignItems="flex-end">
              <S.GreenText variant="h1" component="span">{`${kiloWattHourFormatter(
                solarEnergyBid?.solarEnergyProduction?.yearlyProductionInkWh ?? 0,
              )} kWh`}</S.GreenText>
              &nbsp;
              <Typography component="span">{translate(solarEnergyOffer.perYear)}</Typography>
            </Stack>
            <Typography>{`${translate(solarEnergyOffer.peakPower)} ${bigNumberFormatter(
              (solarEnergyBid?.solarEnergyProduction?.peakPower ?? 0) / TO_KILO_SI,
            )} kWp`}</Typography>
          </Stack>
          <Stack>
            {solarEnergyBid?.solarEnergyProduction?.monthlyProductions && (
              <ProductionSimpleColumnChart
                monthlyProduction={solarEnergyBid.solarEnergyProduction.monthlyProductions}
              />
            )}
          </Stack>
        </Stack>
      </S.Card>
      {showPayoff && (
        <S.Card
          title={
            <TypographyWithIconTooltip
              typographyVariant="h3"
              web
              text={translate(solarEnergyOffer.returnInvestmentTooltip)}
            >
              {translate(solarEnergyOffer.returnInvestmentHeader)}
            </TypographyWithIconTooltip>
          }
        >
          <ReturnFromInvestmentForm
            yearlyProductionInkWh={solarEnergyBid?.solarEnergyProduction?.yearlyProductionInkWh}
            projectGrossPrice={projectGrossPrice}
            initValues={payoffInitValues}
          />
        </S.Card>
      )}
    </Stack>
  );
};
