import { FC } from 'react';
import { LeadCustomerType } from '@generatedTypes/data-contracts';
import { PrivateSubform } from './PrivateSubform';
import { Control } from 'react-hook-form';
import { LeadZodSchema } from './zodSettings';
import { BusinessSubform } from './BusinessSubform';
import { BRFSubform } from './BRFSubform';

export interface NewLeadTypeProps {
  leadType: LeadCustomerType;
  control: Control<LeadZodSchema>;
}

export const NewLeadType: FC<NewLeadTypeProps> = ({ leadType, control }) => {
  if (leadType === LeadCustomerType.Private) return <PrivateSubform control={control} />;

  if (leadType === LeadCustomerType.Business) return <BusinessSubform control={control} />;

  if (leadType === LeadCustomerType.HousingCooperative) return <BRFSubform control={control} />;

  return null;
};
