import React, { useCallback } from 'react';
import { ComplexFormFieldType, UseFormFormFieldReturnTypeCheckboxRadio } from '@hooks/useForm/useFormTypes';
import './chips.css';

type ChipProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  version: `small` | `large` | `square`;
  label: string;
  disabled?: boolean;
};

export const Chip: React.FC<ChipProps> = ({
  version,
  label,
  disabled = false,
  type,
  name,
  checked,
  onChange,
  onClick,
  value,
}) => (
  <div className={`chips chips--${version}`}>
    <input
      id={`chip-radio-${name}-${value}`}
      type={type}
      name={name}
      className="chips-radio"
      disabled={disabled}
      checked={checked}
      onChange={!disabled ? onChange : undefined}
      onClick={!disabled ? onClick : undefined}
    />
    <label htmlFor={`chip-radio-${name}-${value}`} className={`${disabled ? `disabled` : ``}`}>
      {label}
    </label>
  </div>
);

type Chips = {
  classesForContainer?: string;
  isDisabled?: boolean;
  isLabelOnRight?: boolean;
  version?: `small` | `large` | `square`;
  inputFields: UseFormFormFieldReturnTypeCheckboxRadio<ComplexFormFieldType.radio>;
  onClickCallback?: (value: string) => void;
  label?: string;
};

export const Chips: React.FC<Chips> = ({
  inputFields,
  classesForContainer,
  isDisabled = false,
  version = `large`,
  onClickCallback,
  label,
}) => {
  return (
    <div className="chips-wrapper">
      {label ? <span>{label}</span> : null}
      <fieldset disabled={isDisabled} radioGroup="">
        <div className={`chips-container row gap-extra-small ${classesForContainer}`}>
          {inputFields.fields.map((field) => {
            const disabled = isDisabled || field.isDisabled;
            const onClick = useCallback(() => {
              onClickCallback?.(field.value);
              field.onChange();
            }, [field]);

            return (
              <Chip
                key={`${field.name}-${field.value}`}
                type={field.type}
                name={field.name}
                version={version}
                label={field.label}
                value={field.value}
                checked={field.checked}
                disabled={disabled}
                onChange={onClick}
              />
            );
          })}
        </div>
      </fieldset>
    </div>
  );
};
