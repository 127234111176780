import { Card } from '@components/Card/Card';
import { RichTextPreview } from '@components/controls/rich-text-preview';
import { mdiPencil } from '@mdi/js';

interface OfferSettingsCardProps {
  richTextAsHtml: string;
  title: string;
  titleAction?: () => void;
}

export const OfferSettingsCard = ({ richTextAsHtml, title, titleAction }: OfferSettingsCardProps) => (
  <Card title={title} titleAction={titleAction} titleActionIcon={mdiPencil}>
    <RichTextPreview richTextAsHtml={richTextAsHtml} />
  </Card>
);
