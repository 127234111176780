import { authFetch } from '@services/api/utils';
import { useQueryClient } from 'react-query';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { LEAD, LEADS, OFFER_PRICE_OVERVIEW, PROJECTS } from '@variables/queryKeys';

type MoveProjectProductProps = {
  projectProductId: number;
  targetProjectId: number;
};

export const moveProjectProduct = async ({ projectProductId, targetProjectId }: MoveProjectProductProps) =>
  authFetch(`${process.env.PROTECTED_API_URL}/project-products/${projectProductId}/move/${targetProjectId}`, {
    method: `PUT`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.clone().ok) {
        throw await res.clone().json();
      }
    })
    .catch((error) => {
      throw new Error(error);
    });
export const useMoveProjectProduct = (options?: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading } = useCustomMutation({
    mutationFn: moveProjectProduct,
    onSuccess: () => {
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries([OFFER_PRICE_OVERVIEW]);
      options?.onSuccess();
    },
  });

  return {
    moveProjectProduct: mutate,
    moveProjectProductAsync: mutateAsync,
    isMovingProjectProduct: isLoading,
  };
};
