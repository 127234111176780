import { useCallback, useState } from 'react';
import { roundNumber } from '@utils/round-number';

function isNumber(prop: number | undefined | null): prop is number {
  return typeof prop === `number` && !isNaN(prop);
}

interface ReturnOfInvestment {
  purchasePrice: number;
  sellingPrice: number;
  reducedCost: number;
  earning: number;
  saving: number;
  totalProjectGrossPrice: number;
  percentageReturn: number;
}

const calculateReducedCost = (soldEnergyFactor: number, yearlyProduction: number, purchasePrice: number): number => {
  return yearlyProduction * soldEnergyFactor * purchasePrice;
};

const calculateEarnings = (soldEnergyFactor: number, yearlyProduction: number, sellingPrice: number): number => {
  return yearlyProduction * (1 - soldEnergyFactor) * sellingPrice;
};

const calculateSaving = (reducedCost: number, earning: number): number => {
  return reducedCost + earning;
};

const calculatePercentageReturn = (reducedCost: number, earning: number, totalProjectGrossPrice: number): number => {
  return ((reducedCost + earning) / totalProjectGrossPrice) * 100;
};

type CalculateROIProps = {
  soldEnergyFactor?: number;
  totalProjectGrossPrice?: number;
  yearlyEnergyProductionInWh?: number | null;
  purchasePrice?: number;
  sellingPrice?: number;
};

const calculateROI = ({
  soldEnergyFactor,
  totalProjectGrossPrice,
  yearlyEnergyProductionInWh,
  purchasePrice,
  sellingPrice,
}: CalculateROIProps): ReturnOfInvestment => {
  if (
    !isNumber(soldEnergyFactor) ||
    !isNumber(totalProjectGrossPrice) ||
    !isNumber(yearlyEnergyProductionInWh) ||
    !isNumber(purchasePrice) ||
    !isNumber(sellingPrice)
  ) {
    return {
      purchasePrice: 0,
      sellingPrice: 0,
      reducedCost: 0,
      earning: 0,
      saving: 0,
      totalProjectGrossPrice: 0,
      percentageReturn: 0,
    };
  }
  const yearlyEnergyProductionInKWh = yearlyEnergyProductionInWh;
  const soldEnergyFactorPercentage = soldEnergyFactor / 100;
  if (totalProjectGrossPrice < 1) totalProjectGrossPrice = 1;

  const reducedCost = roundNumber(
    calculateReducedCost(soldEnergyFactorPercentage, yearlyEnergyProductionInKWh, purchasePrice),
    2,
  );
  const earning = roundNumber(
    calculateEarnings(soldEnergyFactorPercentage, yearlyEnergyProductionInKWh, sellingPrice),
    2,
  );
  const saving = roundNumber(calculateSaving(reducedCost, earning), 2);
  const percentageReturn = roundNumber(calculatePercentageReturn(reducedCost, earning, totalProjectGrossPrice), 2);

  return {
    purchasePrice,
    sellingPrice,
    reducedCost,
    earning,
    saving,
    totalProjectGrossPrice,
    percentageReturn,
  };
};

export const useCalculateReturnOfInvestment = (initialData?: CalculateROIProps) => {
  const [returnOfInvestmentCalculation, setReturnOfInvestmentCalculation] = useState<ReturnOfInvestment>(
    initialData
      ? calculateROI(initialData)
      : {
          purchasePrice: 0,
          sellingPrice: 0,
          reducedCost: 0,
          earning: 0,
          saving: 0,
          totalProjectGrossPrice: 0,
          percentageReturn: 0,
        },
  );

  const calculateReturnOfInvestment = useCallback((props: CalculateROIProps) => {
    const result = calculateROI(props);
    setReturnOfInvestmentCalculation(result);
  }, []);

  return {
    returnOfInvestmentCalculation,
    calculateReturnOfInvestment,
  };
};
