import { LeadRowDto } from '@generatedTypes/data-contracts';
import { authFetch } from '../utils';
import { LEADS } from '@variables/queryKeys';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { useSelector } from 'react-redux';

export const fetchLeads = async (dealerId?: number) =>
  authFetch(`${process.env.PROTECTED_API_URL}/leads?dealerId=${dealerId ?? ``}`, {
    method: `GET`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        const json = await res.json();
        throw json;
      }
      return res.json();
    })
    .then((json) => json as LeadRowDto[])
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });

export const useGetLeads = () => {
  const currentDealerId = useSelector((state) => state.DealerUserReducer.selectedDealerId);
  const { data, isLoading, refetch, error } = useCustomQuery({
    queryKey: [LEADS, currentDealerId],
    queryFn: () => fetchLeads(currentDealerId),
  });

  return { leadsList: data ?? [], isLoadingLeadsList: isLoading, refetch, error: error as Error };
};
