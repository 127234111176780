import { UpdateLeadRequest } from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useQueryClient } from 'react-query';
import { LEAD, LEADS, OFFER_PRICE_OVERVIEW } from '@variables/queryKeys';

export interface PutLead extends UpdateLeadRequest {
  id: number;
}

const putLead = async ({ id, ...leadDetails }: PutLead) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/leads/${id}`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify(leadDetails),
  });
  switch (response.status) {
    case 200:
      return response;
    default:
      throw await getErrorFromFormRequest(response);
  }
};

export const useUpdateLead = (options?: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: putLead,
    onSuccess: () => {
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(LEAD);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
      options?.onSuccess?.();
    },
  });

  return { updateLead: mutate, updateLeadAsync: mutateAsync, isLoading, validationErrors };
};
