import { ProjectAdditionDto, ProjectAdditionTemplateDto } from '@generatedTypes/data-contracts';
import { ColumnItem } from '@components/columnLayout/utils';
import { ParsedBackendValidationResults } from '@components/controls/validations';
import { z } from 'zod';
import { requiredNumericString, requiredString } from '@variables/zod';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import {
  InputWrapper,
  Numeric,
  InputSuffixWrapperSmart,
  Radio,
} from '@components/controls/react-hook-form-friendly/smart';
import { IconDeleteButton } from '@components/deleteItem/DeleteButton';
import { LABEL_POSITION } from '@components/controls/react-hook-form-friendly/dumb';
import { useCallback } from 'react';
import { DEFAULT_NET_PRICE, DEFAULT_NUMBERS_OF_WORK_HOURS, DEFAULT_QUANTITY_COUNT } from './consts';
import { TotalAdditionsCost } from './TotalAdditionsCost';
import { DevTool } from '@hookform/devtools';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { useGetPartner } from '@services/api/partners';
import { MuiNumberInput } from '@components/controls/react-hook-form-friendly/smart/MuiNumberInput';
import InputAdornment from '@mui/material/InputAdornment';
import { TypographyWithIconTooltip } from '@components/Tooltip/TypographyWithIconTooltip';
import { FormSection } from '@components/forms/MuiFormSection';

type AddEditAdditionProps = {
  closeModal: () => void;
  onDelete: (projectAdditionId: number) => void;
  onSubmitAddEditAddition: (formData: AdditionValuesType) => void;
  beValidationResults?: ParsedBackendValidationResults | null;
  disableForm?: boolean;
  initialProjectAddition?: Omit<ProjectAdditionDto, `projectId`> | ProjectAdditionTemplateDto;
  disableHourPrice?: boolean;
};

const additionZodObject = {
  name: requiredString(),
  netPrice: requiredNumericString(),
  quantity: z.number(),
  numberOfWorkHours: z.number(),
  hourPrice: requiredNumericString(),
  isDeductible: z.string(),
};

const additionZodSchema = z.object(additionZodObject);

export type AdditionValuesType = z.infer<typeof additionZodSchema>;

export const AddEditAdditionForm: ColumnItem<AddEditAdditionProps> = ({
  closeModal,
  onDelete,
  onSubmitAddEditAddition,
  disableForm,
  initialProjectAddition,
  disableHourPrice = false,
}) => {
  const {
    translate,
    translations: {
      editProduct: { additions },
      common: { deletePopup },
    },
  } = useTranslations();
  const { partner } = useGetPartner();

  const theme = useTheme();

  const workingCostPerHour = partner?.netCostPerHour || 0;

  const initialValues: AdditionValuesType = {
    name: initialProjectAddition?.name || ``,
    netPrice: String(initialProjectAddition?.netPrice || DEFAULT_NET_PRICE),
    numberOfWorkHours: initialProjectAddition?.numberOfWorkHours || DEFAULT_NUMBERS_OF_WORK_HOURS,
    quantity: initialProjectAddition?.quantity || DEFAULT_QUANTITY_COUNT,
    hourPrice: (initialProjectAddition as ProjectAdditionDto)?.hourPrice?.toString() ?? String(workingCostPerHour),
    isDeductible: initialProjectAddition?.isDeductible ? `true` : `false`,
  };

  const { control, handleSubmit, watch, resetField } = useForm({
    resolver: zodResolver(additionZodSchema),
    values: initialValues,
    defaultValues: initialValues,
  });

  const hourPrice = useWatch({ control, name: `hourPrice` });

  const handleDelete = useCallback(() => {
    if (!initialProjectAddition?.id) {
      return;
    }
    onDelete(initialProjectAddition.id);
  }, [onDelete, initialProjectAddition?.id]);

  const resetHourPrice = useCallback(() => {
    resetField(`hourPrice`);
  }, [resetField]);

  return (
    <FormWrapper
      onCancel={() => {
        closeModal();
      }}
      onSubmit={handleSubmit((formData) => {
        onSubmitAddEditAddition(formData);
      })}
      disabled={disableForm}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" height={theme.spacing(6)}>
        <Typography variant="h2">{translate(additions.header)}</Typography>
        {!disableForm && initialProjectAddition?.id ? (
          <IconDeleteButton onDelete={handleDelete} translationNode={deletePopup} />
        ) : null}
      </Stack>
      <FormSection>
        <InputWrapper label={translate(additions.name)} control={control} name="name" isRequired />
      </FormSection>
      <FormSection paddingTop={0}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Typography variant="h3">{translate(additions.articleHeader)}</Typography>
          <Box>
            <Numeric
              name="quantity"
              control={control}
              min={DEFAULT_QUANTITY_COUNT}
              labelPosition={LABEL_POSITION.STRETCH_FULL_LINE}
            />
          </Box>
        </Stack>
        <MuiNumberInput
          label={translate(additions.costWithoutTheTax)}
          name="netPrice"
          control={control}
          InputProps={{
            endAdornment: <InputAdornment position="end">kr</InputAdornment>,
          }}
        />
      </FormSection>
      <FormSection paddingTop={0}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Typography variant="h3">{translate(additions.costPerHourHeader)}</Typography>
          <Box>
            <Numeric
              name="numberOfWorkHours"
              control={control}
              min={DEFAULT_NUMBERS_OF_WORK_HOURS}
              labelPosition={LABEL_POSITION.STRETCH_FULL_LINE}
            />
          </Box>
        </Stack>
        <InputSuffixWrapperSmart
          label={
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {translate(additions.costPerHour)}
              {String(hourPrice) !== String(workingCostPerHour) && (
                <Button
                  variant="text"
                  onClick={resetHourPrice}
                  sx={(theme) => ({
                    margin: theme.spacing(0, -3, 0, 0),
                    padding: theme.spacing(0, 0, 0, 0),
                    height: `auto`,
                    minHeight: 0,
                    color: theme.palette.rexelEnergy.ctaBlue,
                    fontFamily: theme.typography.fontFamily,
                  })}
                >
                  {translate(additions.timePerHourReset)}
                </Button>
              )}
            </Stack>
          }
          name="hourPrice"
          control={control}
          type="number"
          suffix="kr"
          isDisabled={disableHourPrice}
        />
      </FormSection>
      <FormSection>
        <TypographyWithIconTooltip typographyVariant="h3" title="" text={translate(additions.workTooltip)}>
          {translate(additions.workHeader)}
        </TypographyWithIconTooltip>
        <Radio
          control={control}
          name="isDeductible"
          value="true"
          label={translate(additions.deductibleCost)}
          classesForContainer="row align-center reversed-direction to-the-end"
        />
        <Radio
          control={control}
          name="isDeductible"
          value="false"
          label={translate(additions.nonDeductibleCost)}
          classesForContainer="row align-center reversed-direction to-the-end"
        />
      </FormSection>
      <FormSection>
        <TotalAdditionsCost initialValues={initialValues} watch={watch} />
      </FormSection>
      <DevTool control={control} />
    </FormWrapper>
  );
};
