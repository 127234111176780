import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart/input-wrapper';
import { ValidationErrors } from '@components/controls/validations';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { FormSection } from '@components/forms/MuiFormSection';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { CreateDealerRequest, DealerDto } from '@generatedTypes/data-contracts';
import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { PutDealer } from '@services/api/dealers/dealers';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { requiredEmail } from '@variables/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const dealerZodObject = {
  name: z.string(),
  street: z.string().min(1, { message: `NOT_NULL` }),
  zipCode: z.string().min(5, { message: `MIN_LENGTH` }),
  city: z.string().min(1, { message: `NOT_NULL` }),
  email: requiredEmail,
  phoneNumber: z.string(),
};

const dealerZodSchema = z.object(dealerZodObject);

export type DealerFormValuesType = z.infer<typeof dealerZodSchema>;

export interface DealerFormProps {
  dealer?: DealerDto | null;
  onSubmit: (dealerData: CreateDealerRequest | PutDealer) => void;
  closeModal: () => void;
  errors?: ValidationErrors;
  isLoading: boolean;
}

export const DealerForm = ({ dealer, closeModal, onSubmit, errors, isLoading }: DealerFormProps) => {
  const initialValues: DealerFormValuesType = {
    name: dealer?.name || ``,
    street: dealer?.address?.street || ``,
    zipCode: dealer?.address?.zipCode || ``,
    city: dealer?.address?.city || ``,
    email: dealer?.email || ``,
    phoneNumber: dealer?.phoneNumber || ``,
  };

  const {
    translate,
    translations: {
      dealer: { create },
    },
  } = useTranslations();

  const { handleSubmit, formState, reset, control, setError } = useForm<DealerFormValuesType>({
    resolver: zodResolver(dealerZodSchema),
    values: initialValues,
  });

  const handleClose = () => {
    reset();
    closeModal();
  };

  useEffect(() => {
    if (errors) {
      Object.entries(errors).forEach(([fieldName, errors]) => {
        setError(fieldName as keyof DealerFormValuesType, { type: `custom`, message: errors[0] });
      });
    }
  }, [errors, setError]);

  return (
    <>
      <FormWrapper
        onSubmit={handleSubmit(({ zipCode, ...restValues }) => {
          if (dealer) {
            onSubmit({ ...restValues, zipCode: zipCode.replace(` `, ``), dealerId: dealer.id });
            handleClose();
          } else {
            onSubmit({ ...restValues, zipCode: zipCode.replace(` `, ``) });
            handleClose();
          }
        })}
        onCancel={handleClose}
        disabled={formState.isSubmitting || isLoading}
      >
        <FormSection title={translate(create.header)}>
          <InputWrapper label={translate(create.name)} name="name" control={control} />
          <HorizontalLine />
          <h2>{translate(create.section2)}</h2>
          <InputWrapper label={translate(create.streeAdress)} name="street" control={control} />
          <div className="grid-container">
            <div className="col-6">
              <InputWrapper label={translate(create.zipCode)} name="zipCode" control={control} mask="999 99" />
            </div>
            <div className="col-6">
              <InputWrapper label={translate(create.city)} name="city" control={control} />
            </div>
          </div>
          <HorizontalLine />
          <h2>{translate(create.section3)}</h2>
          <InputWrapper label={translate(create.email)} name="email" control={control} type="email" />
          <InputWrapper label={translate(create.telephone)} name="phoneNumber" control={control} type="tel" />
        </FormSection>
      </FormWrapper>
      <DevTool control={control} />
    </>
  );
};
