import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiClose } from '@mdi/js';
import { Divider, Box, Button, Stack } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { FC } from 'react';

export interface PropertiesFormButtonsProps {
  onRemove: () => void;
  onContinue: () => void;
  disableContinue?: boolean;
}

export const PropertiesFormButtons: FC<PropertiesFormButtonsProps> = ({ onRemove, onContinue, disableContinue }) => {
  const {
    translate,
    translations: {
      common: { buttons },
    },
  } = useTranslations();

  return (
    <Stack gap={1}>
      <Divider />
      <Box display="flex" justifyContent="space-between">
        <Button variant="contained" onClick={onContinue} disabled={disableContinue}>
          {translate(buttons.continue)}
        </Button>
        <Button variant="text" startIcon={<MdiIconWrapper path={mdiClose} />} onClick={onRemove}>
          {translate(buttons.delete)}
        </Button>
      </Box>
    </Stack>
  );
};
