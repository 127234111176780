import { AdditionPriceDto } from '@generatedTypes/data-contracts';
import { getFormattedNumberWithStaticFraction } from '@pages/NewLeads/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { divideToDeductibleAndNonDeductibleCosts } from './utils';
import { Stack, Typography } from '@mui/material';

type AdditionalCostsCardProps = {
  additionPriceFromBid: AdditionPriceDto[];
};

export const AdditionalCostsCard = ({ additionPriceFromBid }: AdditionalCostsCardProps) => {
  const {
    translate,
    translations: {
      leads: {
        offer: {
          priceOverviewPage: { otherCosts },
        },
      },
    },
  } = useTranslations();

  const bids = divideToDeductibleAndNonDeductibleCosts(additionPriceFromBid);

  if (!bids.deductible.length && !bids.nonDeductible.length) return null;

  return (
    <Stack spacing={4}>
      {Boolean(bids.deductible.length) && (
        <Stack>
          {bids.deductible.map((addition, index) => (
            <div key={`${addition.name}-${index}`} className="row space-between price-row">
              <Typography>{addition.name}</Typography>
              <Typography>{getFormattedNumberWithStaticFraction(addition.netPrice ?? 0, 2)}</Typography>
            </div>
          ))}
        </Stack>
      )}
      {Boolean(bids.nonDeductible.length) && (
        <Stack>
          <div className="row space-between price-row">
            <Typography variant="h3">{translate(otherCosts)}</Typography>
          </div>
          {bids.nonDeductible.map((addition, index) => (
            <div key={`${addition.name}-${index}`} className="row space-between price-row">
              <Typography>{addition.name}</Typography>
              <Typography>{getFormattedNumberWithStaticFraction(addition.netPrice ?? 0, 2)}</Typography>
            </div>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
