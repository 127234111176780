import { Card } from '@components/Card/Card';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import {
  ProductCategoryRowDto,
  ProjectAdditionDto,
  ProjectDto,
  ProjectProductRowDto,
  ProjectType,
} from '@generatedTypes/data-contracts';
import { ProductsAdditionsAndDeductionsListCard } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/ProductsAdditionsAndDeductionsListCard';
import { useGetProductCategories } from '@services/api/productCategories/productCategories';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import React from 'react';
import { Stack } from '@mui/material';
import {
  useProjectHeader,
  useProjectImage,
  useRefetchUnloadedProject,
} from '@pages/NewLeads/Projects/ProjectRow/utils';
import { ProjectRowEditCard } from './ProjectRowEditCard';

type ProjectRowProps = {
  project: ProjectDto;
  onShowAddNewProductPanel: () => void;
  onShowAddEditProductPanel: (
    categoryId: ProductCategoryRowDto[`id`],
    projectProductId?: ProjectProductRowDto[`id`],
  ) => void;
  onShowAddEditAdditionPanel: (projectAdditionId?: ProjectAdditionDto[`id`]) => void;
  onShowAddEditDeductionPanel: (projectDeductionId?: number) => void;
  onProjectClick: () => void;
};

export const ProjectRow: React.FC<ProjectRowProps> = ({
  project,
  onShowAddNewProductPanel,
  onShowAddEditAdditionPanel,
  onShowAddEditDeductionPanel,
  onShowAddEditProductPanel,
  onProjectClick,
}) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: { serviceHeader, subscriptionHeader },
        },
      },
    },
  } = useTranslations();

  useRefetchUnloadedProject(project.id, project.isSavingInProgress);

  const { productCategories } = useGetProductCategories();

  const header = useProjectHeader(project.type, project.name);
  const Image = useProjectImage(project.type);

  return (
    <Stack gap={0.5}>
      <ProjectRowEditCard header={header} project={project} onProjectClick={onProjectClick} image={Image} />
      <ProductsAdditionsAndDeductionsListCard
        onShowAddNewProductPanel={onShowAddNewProductPanel}
        onShowAddEditProductPanel={onShowAddEditProductPanel}
        onShowAddEditAdditionForm={onShowAddEditAdditionPanel}
        onShowAddEditDeductionForm={onShowAddEditDeductionPanel}
        projectProducts={project.projectProducts ?? []}
        projectAdditions={project.additions ?? []}
        projectDeductions={project.deductions ?? []}
        productCategories={productCategories}
        projectType={project.type || ProjectType.None}
        lastModifiedDate={project.lastModifiedDate ?? project.createdDate}
        projectId={project.id}
        solarEnergyProductionForRoof={project.solarEnergyProjectDetails?.solarEnergyProductionForRoof ?? []}
        isSavingInProgress={project.isSavingInProgress}
      />
      {project.subscriptionName && (
        <Card title={translate(serviceHeader)}>
          <div className="column gap-extra-small">
            <div className="row space-between">{translate(subscriptionHeader)}</div>
            <div className="color-grey-1 small">{project.subscriptionName}</div>
            <HorizontalLine />
          </div>
        </Card>
      )}
    </Stack>
  );
};
