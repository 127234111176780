import { styled } from '@mui/material/styles';
import { fontFamilyMedium } from '../../../styles/theme/theme';

export const OnMapButtonStyled = styled(`button`)(({ theme }) => ({
  display: `flex`,
  flexDirection: `row`,
  alignItems: `center`,
  padding: `0 17px`,
  gap: `1rem`,
  width: `auto`,
  height: 40,
  maxHeight: 40,
  backgroundColor: `#fff`,
  boxShadow: `rgba(0, 0, 0, 0.3) 0px 1px 4px -1px`,
  borderRadius: 2,
  border: 0,
  zIndex: 1,
  cursor: `pointer`,
  '&:hover': {
    backgroundColor: `#EBEBEB`,
  },
  '& svg': {
    color: theme.palette.rexelEnergy.ctaBlue,
  },
  '& .MuiTypography-root': {
    fontFamily: fontFamilyMedium,
  },
  '&:disabled': {
    cursor: `default`,
    '& svg': {
      color: theme.palette.rexelEnergy.grey,
    },
    '& .MuiTypography-root': {
      color: theme.palette.rexelEnergy.grey,
    },
    '&:hover': {
      backgroundColor: `#fff`,
    },
  },
}));
