import { InputWrapper, SingleCheckbox } from '@components/controls/react-hook-form-friendly/smart';
import { ChipList } from '@components/controls/react-hook-form-friendly/smart/ChipList';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import React, { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SolarEnergyProjectValues } from '../../SolarEnergyProject';
import { PropertiesFormButtons } from './PropertiesFormButtons/PropertiesFormButtons';
import { RoofMaterialsWrapper } from './RoofMaterialsWrapper';
import { RoofType } from './RoofType';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { mapAttributeRowToOption } from '@pages/NewLeads/Projects/solarEnergyProject/utils';
import { getValuesFromAttributeRow } from '../../utils/utils';
import { useHandleDeleteRoof } from '../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/roof';
import { MuiNumberInput } from '@components/controls/react-hook-form-friendly/smart/MuiNumberInput';

export type RoofSettingsStatePropertiesProps = {
  roofIndex: number;
  onContinue: () => void;
  disableContinue?: boolean;
};

export const RoofSettingsStateProperties: React.FC<RoofSettingsStatePropertiesProps> = ({
  roofIndex,
  onContinue,
  disableContinue,
}) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: {
                mountingMaterialManufacturer,
                solarPanelsHeader,
                ceilingInformation,
                roofFoundation,
                roofName,
                newRoofNamePrefix,
                roofType,
                ridgeHeight,
                snowProtection,
                obstaclesOnTheRoof,
                roofSettingsHeader,
              },
            },
          },
        },
      },
    },
  } = useTranslations();

  const { solarPanelManufacturer, lathDimension } = useGetSolarEnergyProjectSettings();
  const solarPanelManufacturerOptions = getValuesFromAttributeRow(solarPanelManufacturer).map(mapAttributeRowToOption);

  const handleDeleteRoof = useHandleDeleteRoof();

  const defaultLathDimensionId = useMemo(
    () =>
      lathDimension?.values.map(({ name, id }) => ({
        label: name as string,
        value: id,
      }))?.[0].value ?? null,
    [lathDimension?.values],
  );

  const { control } = useFormContext<SolarEnergyProjectValues>();
  const { remove, append } = useFieldArray<SolarEnergyProjectValues>({
    control,
    name: `roofs`,
  });

  return (
    <Stack spacing={2}>
      <Typography variant="h2">{translate(roofSettingsHeader)}</Typography>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Typography variant="h3">{translate(ceilingInformation)}</Typography>
          <InputWrapper control={control} name={`roofs.${roofIndex}.name`} label={translate(roofName)} isRequired />
        </Stack>
        <RoofType control={control} name={`roofs.${roofIndex}.roofTypeId`} label={translate(roofType)} />
        <MuiNumberInput
          control={control}
          name={`roofs.${roofIndex}.ridgeHeight`}
          label={translate(ridgeHeight)}
          InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
          required
          size="small"
          decimalScale={0}
        />
        <Stack spacing={2}>
          <Typography variant="h3">{translate(obstaclesOnTheRoof)}</Typography>
          <SingleCheckbox
            label={translate(snowProtection)}
            value="snowProtection"
            control={control}
            name={`roofs.${roofIndex}.snowProtection`}
            defaultValue={false}
            isLabelOnRight
          />
        </Stack>
        <Stack spacing={2}>
          <Typography variant="h3">{translate(roofFoundation)}</Typography>
          <RoofMaterialsWrapper roofIndex={roofIndex} />
        </Stack>
        <Stack spacing={2}>
          <Divider />
          <Typography variant="h3">{translate(solarPanelsHeader)}</Typography>
          <ChipList
            // TODO: hack for https://github.com/react-hook-form/react-hook-form/pull/10490. Remove after version update.
            key={`roofs.${roofIndex}.solarPanelManufacturerId`}
            chips={solarPanelManufacturerOptions}
            control={control}
            name={`roofs.${roofIndex}.solarPanelManufacturerId`}
            version="large"
            wrapChips={true}
            label={translate(mountingMaterialManufacturer)}
          />
        </Stack>
        <PropertiesFormButtons
          disableContinue={disableContinue}
          onContinue={onContinue}
          onRemove={handleDeleteRoof(remove, append, roofIndex, translate(newRoofNamePrefix), defaultLathDimensionId)}
        />
      </Stack>
    </Stack>
  );
};
