import {
  OfferAgreementBasketItems,
  OfferAgreementDto,
  UpdateOfferAgreementRequest,
} from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { useQueryClient } from 'react-query';
import {
  LEAD,
  LEADS,
  OFFER,
  OFFER_AGREEMENT,
  OFFER_PRICE_OVERVIEW,
  PROJECTS,
  BASKET_ITEMS,
} from '@variables/queryKeys';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useParams } from 'react-router-dom';
import { useCustomQuery, UseCustomQueryOptions } from '@hooks/useCustomQuery';
import { useSearchParams } from '@utils/useSearchParams';
import { useMemo } from 'react';

const fetchOfferAgreement = async (hashedId?: string) => {
  if (hashedId) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/offer-agreements/${hashedId}`, {
      method: `GET`,
      mode: `cors`,
    });
    const json = await response.json();
    if (response.ok) {
      return json as OfferAgreementDto;
    }
    throw new Error(json.message);
  }
  return null;
};

export type EditOfferAgreementProps = UpdateOfferAgreementRequest & {
  hashedId: string;
};

const editOfferAgreement = async ({ hashedId, ...data }: EditOfferAgreementProps) => {
  if (hashedId) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/offer-agreements/${hashedId}`, {
      method: `PUT`,
      mode: `cors`,
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw await getErrorFromFormRequest(response);
    }
  }
};

export type EditOfferAgreementPropsReturnOfInvestments = {
  hashedId: string | undefined | null;
  projectGrossPrice: number | undefined | null;
  yearlyEnergyProduction: number | undefined | null;
  sellingPrice: number | undefined | null;
  purchasePrice: number | undefined | null;
  bidId: number | undefined | null;
};

const getBasketData = async (offerHash?: string, itemsHash?: string) => {
  if (offerHash) {
    const baseSearchUrl = `${process.env.PROTECTED_API_URL}/offer-agreements/${offerHash}/basket-items`;
    const basketItemsUrl = itemsHash ? `${baseSearchUrl}?itemsHash=${itemsHash}` : baseSearchUrl;
    const response = await authFetch(basketItemsUrl, {
      method: `GET`,
      mode: `cors`,
    });
    const json = await response.json();
    if (response.ok) {
      return json as OfferAgreementBasketItems;
    }
    throw new Error(json.message);
  }
  return null;
};

export const useGetOfferAgreement = (options?: UseCustomQueryOptions) => {
  const { seed } = useParams<{ seed: string }>();
  const { data, isLoading, refetch } = useCustomQuery({
    isLoginRequired: options?.isLoginRequired,
    queryFn: () => fetchOfferAgreement(seed),
    queryKey: [OFFER_AGREEMENT, seed],
  });

  return { offerAgreement: data === undefined ? null : data, isLoading, refetch };
};

export const useUpdateOfferAgreement = () => {
  const queryClient = useQueryClient();
  const { seed } = useParams<{ seed: string }>();
  const { mutate, mutateAsync, isLoading } = useCustomMutation({
    mutationFn: editOfferAgreement,
    onSuccess: () => {
      queryClient.invalidateQueries(PROJECTS);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(LEAD);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(OFFER);
      if (seed) {
        queryClient.invalidateQueries([OFFER_AGREEMENT, seed]);
      }
    },
  });

  return { updateOfferAgreement: mutate, updateOfferAgreementAsync: mutateAsync, isUpdatingOfferAgreement: isLoading };
};

export const useGetBasketData = () => {
  const { offerHash } = useParams<{ offerHash: string }>();
  const searchParams = useSearchParams();
  const itemsHash = useMemo(() => searchParams.get(`itemsHash`) ?? ``, [searchParams]);
  const { data, isLoading } = useCustomQuery({
    queryFn: () => getBasketData(offerHash, itemsHash),
    queryKey: [BASKET_ITEMS, offerHash],
  });

  return { basketData: data === undefined ? null : data, isLoading };
};
