import { Card as CardMain } from '@components/Card/Card';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const Card = styled(CardMain)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  flex: 1,
  maxHeight: theme.spacing(46.5),

  '& .MuiCardContent-root': {
    paddingTop: theme.spacing(0),
  },
  '& .MuiCardHeader-content': {
    height: theme.spacing(4),
  },
}));

interface GreenTextProps {
  component: string;
}

export const GreenText = styled(Typography)<GreenTextProps>(({ theme }) => ({
  '&.MuiTypography-h1': {
    color: theme.palette.rexelEnergy.hover,
  },
}));
