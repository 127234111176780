import React, { MouseEventHandler } from 'react';
import { TabButtonStyled, TabButtonStyledProps } from '@components/button/TabButton.styled';
import Typography from '@mui/material/Typography';
import { MdiIconWrapper } from '@components/MdiIconWrapper';

type RectangleButtonProps = Partial<TabButtonStyledProps> & {
  icon?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  alt?: string;
  title?: string | null;
  disabled?: boolean;
  id?: string;
};

export const TabButton: React.FC<RectangleButtonProps> = ({
  icon,
  onClick,
  selected = false,
  title,
  disabled = false,
  buttonType = `primary`,
  id,
}) => (
  <TabButtonStyled
    onClick={onClick}
    disabled={disabled}
    type="button"
    selected={selected}
    buttonType={buttonType}
    id={id}
  >
    {icon && <MdiIconWrapper path={icon} />}
    {title && <Typography variant="h4">{title}</Typography>}
  </TabButtonStyled>
);
