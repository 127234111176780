import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import * as S from './ChipImage.styled';

export interface ChipImageProps extends S.ChipWrapperProps {
  src: string;
  subtitle?: string;
  title: string;
  cardMediaComponent?: `img` | `svg`;
  value: number;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ChipImage: React.FC<ChipImageProps> = ({
  src,
  subtitle,
  title,
  selected,
  disabled,
  cardMediaComponent = `img`,
  value,
  onClick,
}) => (
  <S.ChipWrapper
    onClick={onClick}
    role="radio"
    sx={{ textAlign: `inherit` }}
    disabled={disabled}
    selected={selected}
    id={String(value)}
  >
    <S.ChipCard>
      <CardMedia
        component={cardMediaComponent}
        src={src}
        sx={{ height: (theme) => theme.spacing(8), width: (theme) => theme.spacing(8), objectFit: `contain` }}
        alt="" // These images are just decorative
      />
      <S.ChipContent>
        <Typography variant="h4" component="p">
          {title}
        </Typography>
        {subtitle && <Typography fontSize={13}>{subtitle}</Typography>}
      </S.ChipContent>
    </S.ChipCard>
  </S.ChipWrapper>
);
