import { BatchCreateProjectAdditionRequest, CreateProjectAdditionRequest } from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { useQueryClient } from 'react-query';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { LEAD, LEADS, OFFER_PRICE_OVERVIEW, PROJECTS } from '@variables/queryKeys';

const postProjectAddition = async (product: CreateProjectAdditionRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/project-additions`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(product),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

export const useCreateProjectAddition = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: postProjectAddition,
    onSuccess: () => {
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries([OFFER_PRICE_OVERVIEW]);
    },
  });

  return {
    addProjectAddition: mutate,
    addProjectAdditionAsync: mutateAsync,
    isAddingProjectAddition: isLoading,
    addProjectAdditionBeValidationErrors: validationErrors,
  };
};

const postProjectAdditionBatch = async (product: BatchCreateProjectAdditionRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/project-additions/batch-create`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(product),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

export const useCreateProjectAdditionBatch = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: postProjectAdditionBatch,
    onSuccess: () => {
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries([OFFER_PRICE_OVERVIEW]);
    },
  });

  return {
    addProjectAdditionBatch: mutate,
    addProjectAdditionBatchAsync: mutateAsync,
    isAddingProjectAdditionBatch: isLoading,
    addProjectAdditionBatchBeValidationErrors: validationErrors,
  };
};
