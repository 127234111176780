import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const WebTypographyStyled = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-h1': {
    ...theme.typography.h1,
    fontSize: `40px`,
    lineHeight: `40px`,
  },
  '&.MuiTypography-h2': {
    ...theme.typography.h1,
    fontSize: `32px`,
    lineHeight: `38px`,
  },
  '&.MuiTypography-h3': {
    ...theme.typography.h3,
    fontSize: `24px`,
    lineHeight: `32px`,
  },
  '&.MuiTypography-h4': {
    ...theme.typography.h4,
    fontSize: `16px`,
    lineHeight: `24px`,
  },
  '&.MuiTypography-subtitle1': {
    ...theme.typography.subtitle1,
    fontSize: `16px`,
    lineHeight: `24px`,
  },
  '&.MuiTypography-subtitle2': {
    ...theme.typography.subtitle2,
    fontSize: `16px`,
    lineHeight: `24px`,
  },
  '&.MuiTypography-caption': {
    fontSize: `11px`,
    lineHeight: `16px`,
    opacity: 0.6,
  },
}));
