import Icons from '@assets/svg/svgDefs.svg';

export enum ICON_SIZE {
  ICON_SMALL = `icon-small`,
  ICON_SMALL_2 = `icon-small-2`,
  ICON_MEDIUM = `icon-medium`,
  ICON_LARGE = `icon-large`,
}

export enum ICON_COLOR {
  COLOR_BLUE = `color-blue`,
  COLOR_GREY = `color-grey`,
  COLOR_GREEN = `color-green`,
  COLOR_DARK = `color-dark`,
  COLOR_WHITE = `color-white`,
}

type SvgIconProps = {
  iconId: string;
  onClick?: () => void;
  color?: ICON_COLOR;
  size?: ICON_SIZE;
  isDisabled?: boolean;
  svgClasses?: string;
};

export const SvgIcon: React.FC<SvgIconProps> = ({
  iconId,
  onClick,
  size = ICON_SIZE.ICON_MEDIUM,
  color = ICON_COLOR.COLOR_BLUE,
  isDisabled,
  svgClasses = ``,
}) => (
  <svg
    className={`icon ${iconId} ${size ?? ``} ${isDisabled ? ICON_COLOR.COLOR_GREY + ` disabled` : color} ${svgClasses}`}
    onClick={onClick}
  >
    <use href={`${Icons}#${iconId}`} />
  </svg>
);
