import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const propsWithNoForward = [`selected`, `isThreeRows`, `italic`];
const shouldForwardProp = (prop: string) => !propsWithNoForward.includes(prop);

interface StyleProps {
  selected?: boolean;
  isThreeRows?: boolean;
  isBottomRight?: boolean;
  italic?: boolean;
}

export const SingleRowWrapper = styled(`div`, { shouldForwardProp })<StyleProps>(({ theme, selected }) => ({
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${selected ? theme.palette.primary.dark : theme.palette.divider}`,
}));

export const Wrapper = styled(`div`, { shouldForwardProp })<StyleProps>(({ theme, isThreeRows, selected }) => ({
  display: `grid`,
  gridTemplateColumns: `minmax(40%, 70%) auto`,
  gridTemplateRows: `auto auto`,
  gap: isThreeRows ? theme.spacing(0.5, 1) : theme.spacing(1),
  borderBottom: `1px solid ${selected ? theme.palette.primary.dark : theme.palette.divider}`,
  paddingBottom: isThreeRows ? theme.spacing(2) : theme.spacing(3),
  alignItems: `end`,
}));

export const LeftTopThreeRows = styled(`div`)<StyleProps>(({ theme, selected, italic }) => ({
  fontSize: 13,
  color: selected ? theme.palette.rexelEnergy.lightGrey : theme.palette.rexelEnergy.grey,
  fontStyle: italic ? `italic` : `normal`,
}));

export const leftBottomThreeRows = styled(`div`)<StyleProps>(({ isBottomRight }) => ({
  gridColumn: !isBottomRight ? `1/3` : `1/2`,
  minHeight: 15,
}));

export const MiddleRight = styled(`div`)`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  margin: auto 0;
`;

export const TitleWrapper = styled(`div`)`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  overflow: hidden;
`;
export const RightTop = styled(`div`)`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
`;
export const LeftBottom = styled(`div`)`
  grid-column: span 2;
  grid-row: 2 / 3;
`;
export const RightBottom = styled(`div`)`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
`;

export const StyledTypography = styled(Typography, { shouldForwardProp })<StyleProps>(
  ({ theme, selected, italic }) => ({
    lineHeight: `16px`,
    fontSize: 13,
    whiteSpace: `nowrap`,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    color: selected ? theme.palette.common.white : theme.palette.text.primary,
    textDecoration: `none`,
    fontStyle: italic ? `italic` : `normal`,
  }),
);

export const RightTopText = styled(StyledTypography)<StyleProps>(({ theme, selected, italic }) => ({
  color: selected ? theme.palette.rexelEnergy.lightGrey : theme.palette.rexelEnergy.grey,
  fontStyle: italic ? `italic` : `normal`,
}));

export const Title = styled(StyledTypography)`
  font-family: 'Gotham Medium', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
`;

export const StyledLink = styled(Link, { shouldForwardProp })<StyleProps>(({ theme, selected, isThreeRows }) => ({
  padding: isThreeRows ? theme.spacing(2, 2, 0, 2) : theme.spacing(3, 2, 0, 2),
  backgroundColor: selected ? theme.palette.primary.dark : theme.palette.background.paper,
  '&:first-of-type': {
    borderTop: `1px solid ${theme.palette.divider}`,
  },

  textDecoration: `none`,

  '&:focus-visible, &:hover': {
    outline: `none`,

    backgroundColor: !selected && theme.palette.rexelEnergy.beige,
  },
}));
