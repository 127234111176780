import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '@redux/reducers';
import { OfferAttachmentRowDto } from '@generatedTypes/data-contracts';

export interface OfferTabState {
  selectedOfferAttachment?: OfferAttachmentRowDto;
}

export const initialState: OfferTabState = {};

const offerTabSlice = createSlice({
  name: `offer-tab`,
  initialState,
  reducers: {
    setSelectedOfferAttachment: (state, action: PayloadAction<OfferAttachmentRowDto | undefined>) => {
      state.selectedOfferAttachment = action.payload;
    },
  },
});

export const { setSelectedOfferAttachment } = offerTabSlice.actions;
export const { reducer: offerTabReducer } = offerTabSlice;

export const selectSelectedOfferAttachment = (state: AppState) => state.offerTabReducer.selectedOfferAttachment;
