import React, { useCallback, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { loginUser } from '@services/api/login/login';
import './login.css';
import { parseJsonWebTokenToTokenObject, useToken } from '@services/hooks/authentication/useToken';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import { requiredString } from '@variables/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useToggle } from '@hooks/useToggle';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';

const loginZodSchema = z.object({
  userName: requiredString(),
  password: requiredString(),
});

type LoginValuesType = z.infer<typeof loginZodSchema>;

export const Login: React.FC = () => {
  const { token, setToken } = useToken();
  const { enqueueSnackbar } = useSnackbar();
  const {
    translations: { login, forgotPassword },
    translate,
  } = useTranslations();
  const navigate = useNavigate();
  const location = useLocation();
  const [disableSubmitButton, toggleDisableSubmitButton] = useToggle();

  const { control, handleSubmit } = useForm({
    resolver: zodResolver(loginZodSchema),
    values: { userName: ``, password: `` },
  });

  useEffect(() => {
    if (token?.token) {
      navigate(location.state?.from || `/`);
    }
  }, [location.state?.from, navigate, token?.token]);

  const handleLoginSubmit = useCallback(
    async ({ userName, password }: LoginValuesType) => {
      toggleDisableSubmitButton();
      try {
        const response = await loginUser({ userName, password });
        localStorage.setItem(`token`, response.token);
        setToken(parseJsonWebTokenToTokenObject(response));
      } catch (error) {
        const err = error as string;

        enqueueSnackbar(err, { variant: `error`, anchorOrigin: { horizontal: `right`, vertical: `top` } });
      } finally {
        toggleDisableSubmitButton();
      }
    },
    [toggleDisableSubmitButton, setToken, enqueueSnackbar],
  );

  return (
    <div id="login-view" className="container container-center fh fw">
      <div className="login-wrapper fw fh column center col-1-mobile-small col-4-desktop">
        <div className="header logo">
          <div className="left" />
          <div className="right">Rexel Energy</div>
        </div>
        <form className="column left-center gap gap-small fw" onSubmit={handleSubmit(handleLoginSubmit)}>
          <InputWrapper
            control={control}
            label={translate(login.username)}
            type="text"
            name="userName"
            placeholder={translate(forgotPassword.email)}
          />
          <InputWrapper
            control={control}
            label={translate(login.password)}
            type="password"
            name="password"
            placeholder={translate(login.password)}
          />
          <Button type="submit" variant="contained" disabled={disableSubmitButton}>
            {translate(login.login)}
          </Button>
          <Link className="nav-link" to="/forgot-password">
            {translate(login.resetPassword)}
          </Link>
        </form>
      </div>
    </div>
  );
};
