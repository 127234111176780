import { Link } from 'react-router-dom';

export type NavLinkItemProps = {
  name: string;
  path: string;
  active: boolean;
  profileItem?: boolean;
  onActivate?: () => void;
};
export const NavLinkItem: React.FC<NavLinkItemProps> = ({ name, path, active, profileItem = false, onActivate }) => (
  <div className={`nav-item${active ? ` active` : ``}${profileItem ? ` profile-item` : ``}`}>
    <Link className="nav-link" to={path} onClick={onActivate}>
      {name}
    </Link>
  </div>
);
