import { DeductionType } from '@generatedTypes/data-contracts';
import { roundNumber } from '@utils/round-number';

export const getSumValues = (percentageValue: string, basePrice: number): string => {
  const sumValue = (basePrice * Number(percentageValue)) / 100;
  return String(roundNumber(sumValue, 2));
};

export const getPercentageValues = (sumValue: string, basePrice: number): string => {
  const percentageValue = (Number(sumValue) * 100) / basePrice;
  return String(roundNumber(percentageValue, 2));
};

export const getSumAndPercentageValues = (
  value: string,
  type: DeductionType,
  basePrice: number,
): { sum: string; percentage: string } => {
  if (type === DeductionType.Percentage) {
    return { sum: getSumValues(value, basePrice), percentage: value };
  }
  return { sum: value, percentage: getPercentageValues(value, basePrice) };
};
