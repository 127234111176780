import { createSelector } from 'reselect';
import { ProductCategoryRowDto, ProductGroupType, ProjectType } from '@generatedTypes/data-contracts';
import { AppState } from '@redux/reducers';

export const getSelectorForProjectType = (type: ProjectType | undefined) => {
  switch (type) {
    case ProjectType.CarCharging:
      return selectCarChargingProductGroups;
    case ProjectType.SolarEnergy:
      return selectSolarEnergyProductGroups;
    case ProjectType.Empty:
      return selectEmptyProductGroups;
    default:
      return () => [];
  }
};

const selectLead = (state: AppState) => state.Lead;

const selectProductCategories = createSelector(selectLead, (lead) => lead.productCategories);

export const selectProjectCategories = createSelector(selectLead, (lead) => {
  return lead?.currentOfferBidProductCategories;
});

export const selectLeadsProjects = createSelector(selectLead, (lead) => lead.projects);

export const selectCurrentProjectId = createSelector(selectLead, (lead) => lead.currentProjectId);

export const selectCurrentProject = createSelector(selectCurrentProjectId, selectLeadsProjects, (projectId, projects) =>
  projects.find((project) => project.id === projectId),
);

export const selectCarChargingProductGroups = createSelector(
  selectLead,
  (lead) => lead.productCategories.filter(({ groupType }) => groupType === ProductGroupType.CarCharging) || [],
);

export const selectSolarEnergyProductGroups = createSelector(
  selectLead,
  (lead) => lead.productCategories.filter(({ groupType }) => groupType === ProductGroupType.SolarEnergy) || [],
);

export const selectEmptyProductGroups = createSelector(selectLead, (lead) => lead.productCategories);

export const selectCurrentProductCategoryId = createSelector(selectLead, (lead) => lead.currentProductCategoryId);

export const selectCurrentProductCategory = createSelector(
  selectProductCategories,
  selectCurrentProductCategoryId,
  (categories, categoryId) => {
    return categories.find((category: ProductCategoryRowDto) => category.id === categoryId);
  },
);

export const selectCurrentProjectProductId = createSelector(selectLead, (lead) => lead.currentProjectProductId);
export const selectCurrentProjectProductProductId = createSelector(
  selectLead,
  (lead) => lead.currentProjectProductProductId,
);

export const selectCurrentProjectProduct = createSelector(
  selectCurrentProject,
  selectCurrentProjectProductId,
  (project, selectedProjectProductId) => {
    return project?.projectProducts?.find((product) => product.id === selectedProjectProductId);
  },
);

export const selectCurrentProductsAttributesFilters = createSelector(
  selectLead,
  (lead) => lead.currentProductsAttributesFilters,
);

export const selectCurrentProjectAdditionId = createSelector(selectLead, (lead) => lead.currentProjectAdditionId);
export const selectCurrentProjectDeductionId = createSelector(selectLead, (lead) => lead.currentProjectDeductionId);

export const selectCurrentProjectAddition = createSelector(
  selectCurrentProject,
  selectCurrentProjectAdditionId,
  (project, selectedProjectAdditionId) =>
    project?.additions?.find((projectAddition) => projectAddition.id === selectedProjectAdditionId),
);

export const selectCurrentOfferId = createSelector(selectLead, (lead) => lead.currentOfferId);
export const selectCurrentOfferBidId = createSelector(selectLead, (lead) => lead.currentOfferBidId);
export const selectBidsForOffer = createSelector(selectLead, (lead) => lead.bidsForOffer);
export const selectCurrentOfferData = createSelector(selectLead, (lead) => lead.currentOfferData);
export const selectOfferPriceOverview = createSelector(selectLead, (lead) => lead.offerPriceOverview);

export const selectSelectedRoofId = createSelector(selectLead, (lead) => lead.solarEnergySelectedRoofId);
