import { ValueOf } from './typeUtils';

export const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;

export const getOptionsFromObject = <T extends Record<string | number, ValueOf<T>>>(options: T | undefined) =>
  options
    ? Object.keys(options).reduce(
        (acc, key) => {
          acc.push({ value: key as keyof T, label: options[key] });
          return acc;
        },
        [] as { value: keyof T; label: ValueOf<T> }[],
      )
    : undefined;

export const getFirstObjectOption = <T extends Record<string | number, ValueOf<T>>>(options: T): keyof T =>
  Object.keys(options)[0];

export const getFirstSelectOption = <T extends Record<string | number, ValueOf<T>>, F = null>(
  options: T | null,
  fallbackValue: F | null = null,
): { value: keyof T; label: ValueOf<T> } | F | null => {
  if (options) {
    return getOptionsFromObject(options)?.[0] || null;
  }
  return fallbackValue || null;
};
