import { FC, ReactNode } from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';

export interface KeyValueDisplayProps {
  displayKey: ReactNode;
  value: ReactNode;
  direction?: StackProps[`direction`];
  justifyContent?: StackProps[`justifyContent`];
  alignItems?: StackProps[`alignItems`];
  keyTypographyVariant?: TypographyProps[`variant`];
  valueTypographyVariant?: TypographyProps[`variant`];
}

/**
 * @param displayKey - has to be named like this becasue key is reseved in react
 */
export const KeyValueDisplay: FC<KeyValueDisplayProps> = ({
  displayKey,
  value,
  direction = `row`,
  justifyContent = `space-between`,
  alignItems = `center`,
  keyTypographyVariant = `body1`,
  valueTypographyVariant = `body1`,
}) => (
  <Stack direction={direction} justifyContent={justifyContent} alignItems={alignItems} width="100%">
    <Typography display="block" variant={keyTypographyVariant}>
      {displayKey}
    </Typography>
    {typeof value === `string` ? (
      <Typography display="block" variant={valueTypographyVariant}>
        {value}
      </Typography>
    ) : (
      value
    )}
  </Stack>
);
