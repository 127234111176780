import { useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash.isequal';
import { TAction, AppState } from '@redux/reducers';
import { ACTION_TYPES } from '@redux/actions';
import { store } from '@redux/store';
import {
  isParsedValidationErrors,
  ParsedBackendValidationResults,
  useParseBackendErrors,
} from '@components/controls/validations';
import { enqueueSnackbar, useSnackbar } from 'notistack';

type GetRefetchProps<TResponse, TPayload> = {
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
  setData: (value: React.SetStateAction<TResponse | null>) => void;
  setError: (value: React.SetStateAction<Error | null>) => void;
  fetchFun: TFetchFun<TResponse | null, TPayload>;
  storeAction?: StoreActionFun<TResponse>;
};

export interface UseGetOptions {
  fetchOnMount?: boolean;
  onError?: (error: Error) => void;
}

const defaultUseGetOptions: UseGetOptions = {
  fetchOnMount: true,
};

const getRefetch =
  <TResponse, TPayload>({
    setIsLoading,
    setData,
    fetchFun,
    storeAction,
    setError,
  }: GetRefetchProps<TResponse, TPayload>) =>
  (props: TPayload) => {
    setIsLoading(true);
    setData(null);
    fetchFun(props)
      .then((data) => {
        setData(data);
        if (storeAction) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          store.dispatch(storeAction(data));
        }
      })
      .catch((error: Error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

type TFetchFun<TResponse, TPayload> = (props: TPayload) => Promise<TResponse>;
type StoreActionFun<TResponse> = (data: TResponse) => TAction<Partial<typeof ACTION_TYPES>, AppState[keyof AppState]>;
type TUseGetReturn<TResponse, TPayload> = {
  data: TResponse | null;
  isLoading: boolean;
  error: Error | null;
  refetch: (props: TPayload) => void;
};
type TUseGet = <TResponse, TPayload>(
  fetchFun: TFetchFun<TResponse, TPayload>,
) => (props: TPayload, options?: UseGetOptions) => TUseGetReturn<TResponse, TPayload>;

export const useGet: TUseGet =
  <TResponse, TPayload>(fetchFun: TFetchFun<TResponse, TPayload>) =>
  (props: TPayload, passedOptions) => {
    const [data, setData] = useState<TResponse | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lastProps, setLastProps] = useState<TPayload | undefined>(undefined);
    const options = useMemo(
      () => (passedOptions ? { ...defaultUseGetOptions, ...passedOptions } : defaultUseGetOptions),
      [passedOptions],
    );

    const refetch = useMemo(
      () =>
        getRefetch({
          setData,
          setError,
          setIsLoading,
          fetchFun,
        }),
      [],
    );

    useEffect(() => {
      if (error) {
        options.onError
          ? options.onError(error)
          : enqueueSnackbar(error.message, { variant: `error`, preventDuplicate: true });
      }
    }, [error, options]);

    useEffect(() => {
      if (!isEqual(props, lastProps)) {
        setLastProps(props);
        options.fetchOnMount && refetch(props);
      }
    }, [options.fetchOnMount, lastProps, props, refetch]);

    return { data, error, isLoading, refetch };
  };

type TUseGetToStore = <TResponse, TPayload>(
  fetchFun: TFetchFun<TResponse | null, TPayload>,
  storeAction: StoreActionFun<TResponse>,
) => (props: TPayload, options?: UseGetOptions) => TUseGetReturn<TResponse, TPayload>;
export const useGetToStore: TUseGetToStore =
  <TResponse, TPayload>(fetchFun: TFetchFun<TResponse | null, TPayload>, storeAction: StoreActionFun<TResponse>) =>
  (props: TPayload, passedOptions) => {
    const [data, setData] = useState<TResponse | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lastProps, setLastProps] = useState<TPayload | undefined>(undefined);
    const options = useMemo(
      () => (passedOptions ? { ...defaultUseGetOptions, ...passedOptions } : defaultUseGetOptions),
      [passedOptions],
    );

    useEffect(() => {
      if (error) {
        options.onError
          ? options.onError(error)
          : enqueueSnackbar(error.message, { variant: `error`, preventDuplicate: true });
      }
    }, [error, options]);

    const refetch = useMemo(
      () =>
        getRefetch({
          setData,
          setError,
          setIsLoading,
          fetchFun,
          storeAction,
        }),
      [],
    );

    useEffect(() => {
      if (!isEqual(props, lastProps)) {
        setLastProps(props);
        options.fetchOnMount && refetch(props);
      }
    }, [options, lastProps, props, refetch]);

    return { data, error, isLoading, refetch };
  };

type TPostFun<Payload, Results> = (payload: Payload, signal?: AbortSignal) => Promise<Results>;
type TOnSuccess<Payload, Results> = (response: Results, props: Payload) => void;
type TUsePostProps<Payload, Results> = {
  onSuccess?: TOnSuccess<Payload, Results>;
  onError?: (error: Error) => void;
};
type TUsePostResults<Payload> = {
  isLoading: boolean;
  error: Error | null;
  parsedBeError: ParsedBackendValidationResults | null;
  post: (payload: Payload, callback?: () => void) => AbortController;
};

export const usePost =
  <TPayload, TResponse = Response>(postFun: TPostFun<TPayload, TResponse>) =>
  ({ onSuccess, onError }: TUsePostProps<TPayload, TResponse>): TUsePostResults<TPayload> => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [parsedBeError, setParsedBackendError] = useState<ParsedBackendValidationResults | null>(null);
    const checkAndParseValidationErrors = useParseBackendErrors();

    const post = useCallback(
      (payload: TPayload, callback?: () => void) => {
        const controller = new AbortController();
        setIsLoading(true);
        setError(null);
        setParsedBackendError(null);
        postFun(payload, controller.signal)
          .then((response) => {
            onSuccess && onSuccess(response, payload);
            callback && callback();
          })
          .catch((error) => {
            if (error.name === `AbortError`) return;
            const parsedError = checkAndParseValidationErrors(error);
            if (isParsedValidationErrors(parsedError)) {
              setParsedBackendError(parsedError);
            }
            setError(error);

            if (!onError) {
              error.errors.forEach((err: Error) => {
                enqueueSnackbar(err.message, { variant: `error`, preventDuplicate: true });
              });
            } else if (onError) {
              onError(error);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
        return controller;
      },
      [checkAndParseValidationErrors, enqueueSnackbar, onError, onSuccess],
    );

    return { isLoading, error, post, parsedBeError };
  };
