import { ColumnLayout } from '@components/Layout/ColumnLayout/ColumnLayout';
import { LeftNavLinkLoaderList } from '@components/LeftNavLink/LeftNavLinkLoaderList';
import { LeftNavLinkTwoRows } from '@components/LeftNavLink/LeftNavLinkTwoRows';
import { useToggle } from '@hooks/useToggle';
import Drawer from '@mui/material/Drawer';
import { useGetPartners } from '@services/api/partners';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Outlet, useParams } from 'react-router-dom';
import { NewPartnerForm } from './NewPartnerForm';

export const PARTNER_EDIT_VIEWS = {
  createPartner: `create-partner`,
  editPartner: `edit-partner`,
  newUser: `new-user`,
  editTermsAndConditions: `edit-tac`,
  editClientInformation: `edit-client-information`,
} as const;

export const PartnersPage = () => {
  const { partnerId } = useParams<{ partnerId: string }>();
  const {
    translate,
    translations: {
      partners: {
        list: { header },
      },
    },
  } = useTranslations();
  const { partners, isFetchingPartners } = useGetPartners();
  const [isNewPartnerFormOpen, toggleIsNewPartnerOpen] = useToggle();

  return (
    <ColumnLayout
      onNewClick={toggleIsNewPartnerOpen}
      title={translate(header)}
      list={
        isFetchingPartners
          ? LeftNavLinkLoaderList
          : partners.map(({ id, name, city, organizationNumber }) => (
              <LeftNavLinkTwoRows
                selected={Number(partnerId) === id}
                key={id}
                title={name}
                href={`/partners/${id}`}
                leftBottom={organizationNumber}
                rightTop={city}
              />
            ))
      }
    >
      <Outlet />
      <Drawer open={isNewPartnerFormOpen} anchor="right" onClose={toggleIsNewPartnerOpen}>
        <NewPartnerForm onClose={toggleIsNewPartnerOpen} />
      </Drawer>
    </ColumnLayout>
  );
};
