import { useSelector } from 'react-redux';
import { selectSelectedDeductionTemplateId } from '@redux/reducers/slices/partner';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { DEDUCTION_TEMPLATES } from '@variables/queryKeys';
import { fetchDeductionTemplate } from '@services/api/deductionTemplates/deductionTempaltes';

export const useGetDeductionTemplate = () => {
  const selectedDeductionTemplateId = useSelector(selectSelectedDeductionTemplateId) ?? -1;

  const { data, isLoading, error } = useCustomQuery({
    queryKey: [DEDUCTION_TEMPLATES, selectedDeductionTemplateId],
    queryFn: () => fetchDeductionTemplate(selectedDeductionTemplateId),
    enabled: selectedDeductionTemplateId > -1,
  });

  return {
    deductionTemplate: data,
    isFetchingDeductionTemplateTemplate: isLoading,
    errorFetchingDeductionTemplate: error as Error | undefined,
  };
};
