import { GoogleMaps } from '@components/maps/GoogleMaps/GoogleMaps';
import { AddressDto } from '@generatedTypes/data-contracts';
import { MapEditPrimaryButtons, MapEditSecondaryButtons } from '@components/maps/GoogleMaps/MapEditButtons';
import { Box } from '@mui/material';
import { useGetOffer } from '@services/api/offers/offers';

type GoogleMapsWithPositionEditProps = {
  address: AddressDto;
};

export const GoogleMapsWithPositionEdit: React.FC<GoogleMapsWithPositionEditProps> = ({ address }) => {
  const { isOfferLocked } = useGetOffer();

  return (
    <Box position="relative" height="100%" width="100%">
      <GoogleMaps
        address={address}
        OnMapPrimaryButtons={!isOfferLocked && <MapEditPrimaryButtons />}
        OnMapSecondaryButtons={!isOfferLocked ? MapEditSecondaryButtons : undefined}
      />
    </Box>
  );
};
