import { MdiIconWrapper } from '@components/MdiIconWrapper';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';

import { useToggle } from '@hooks/useToggle';

import * as S from './CardWithSlideContent.styled';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { CardContent } from '@components/Card/Card.styled';
import { ClickAwayListener } from '@mui/material';
import { mdiPencil } from '@mdi/js';
import classNames from 'classnames';

export interface FormSizeIndicatorProps {
  children: ReactNode;
  className?: string;
}

export interface CardWithSlidingContentProps extends S.CardProps {
  children: ReactNode;
  title: ReactNode;
  slidingContent: ReactNode;
  addTopPadding?: boolean;
  buttonIconPath?: string;
  isOpen?: boolean;
  toggleIsOpen?: () => void;
  isOpenCloseDisabled?: boolean;
}

const FormSizeIndicator = ({ children, className }: FormSizeIndicatorProps) => {
  return (
    <div id="form-size" className={className}>
      {children}
    </div>
  );
};

export const CardWithSlidingContent = ({
  backgroundColor,
  title,
  slidingContent,
  children,
  addTopPadding,
  buttonIconPath,
  isOpen,
  toggleIsOpen,
  isOpenCloseDisabled,
}: CardWithSlidingContentProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [isVisibleSlidingContent, toggleIsVisibleSlidingContent] = useToggle();
  const [overlayHeight, setOverlayHeight] = useState(0);
  const openButtonRef = useRef<HTMLButtonElement>(null);

  const toggleFunction = toggleIsOpen || toggleIsVisibleSlidingContent;
  const isOpened = isOpen || isVisibleSlidingContent;

  useEffect(() => {
    if (!cardRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const cardHeight = entries[0].borderBoxSize[0].blockSize;
      const elementToOverlapHeight = cardRef?.current?.querySelector<HTMLElement>(`#form-size`)?.offsetTop || 0;
      setOverlayHeight(cardHeight - elementToOverlapHeight);
    });
    resizeObserver.observe(cardRef.current);
    return () => resizeObserver.disconnect();
  }, [isOpened]);

  return (
    <S.Card ref={cardRef} backgroundColor={backgroundColor} className="no-print">
      <CardHeader
        sx={{ mt: -1 }}
        title={title}
        action={
          <IconButton
            ref={openButtonRef}
            className={classNames({ [`Mui-selected`]: isOpened })}
            onClick={toggleFunction}
            disabled={isOpenCloseDisabled}
          >
            <MdiIconWrapper path={buttonIconPath || mdiPencil} />
          </IconButton>
        }
      />
      <CardContent addTopPadding={addTopPadding}>{children}</CardContent>
      {isOpened && <S.DimOverlay />}
      <ClickAwayListener
        onClickAway={(event) => {
          if (isOpened && !openButtonRef.current?.contains(event.target as Node | null)) {
            toggleFunction();
          }
        }}
      >
        <S.SlidingContentWrapper visible={isOpened} height={overlayHeight}>
          {slidingContent}
        </S.SlidingContentWrapper>
      </ClickAwayListener>
    </S.Card>
  );
};

CardWithSlidingContent.FormSizeIndicator = FormSizeIndicator;
