import { ListItem } from '@components/ListMui/ListItem';
import { SxProps } from '@mui/system';
import { getFormattedNumberWithStaticFraction, getFormattedPriceWithStaticFraction } from '@pages/NewLeads/utils';
import { Stack, Typography, useTheme } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';

export type ProductListItemProps = {
  key: string | number;
  product: {
    id: number;
    productName: string;
    productCategoryName: string;
    isExcluded?: boolean;
    quantity: number;
    productNetPrice: number;
    unit: string;
  };
  onClick?: () => void | undefined;
  contextMenu?: React.ReactNode;
  sx?: SxProps;
  showPrices?: boolean;
};

export const ProductListItem: React.FC<ProductListItemProps> = ({
  product,
  onClick,
  sx,
  contextMenu,
  showPrices = true,
}) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            productList: { excludedLabel },
          },
        },
      },
    },
  } = useTranslations();
  const theme = useTheme();

  if (!product.productName || !product.id) {
    return null;
  }
  return (
    <ListItem
      key={`product-item-${product.id}-${product.productName}`}
      onClick={onClick}
      title={product.productCategoryName}
      subtitle={product.productName}
      parentSx={{
        ...(product.isExcluded ? { color: theme.palette.rexelEnergy.grey } : {}),
        [theme.breakpoints.up(`lg`)]: {
          '& #-button:not([aria-expanded="true"])': {
            opacity: 0,
            visibility: `hidden`,
            transition: `opacity 0.2s ease-in-out`,
          },
        },
        [theme.breakpoints.down(`lg`)]: {
          '& #-button:not([aria-expanded="true"])': {
            backgroundColor: `white`,
          },
        },
        '&:hover #-button:not([aria-expanded="true"])': {
          opacity: 100,
          visibility: `visible`,
          backgroundColor: theme.palette.rexelEnergy.beige,
        },
      }}
      secondaryAction={
        <Stack direction="row-reverse" spacing={1} width="7.8rem" justifyContent="space-between" alignItems="center">
          <Stack flex={3}>
            <Typography
              textAlign="right"
              color={product.isExcluded ? theme.palette.rexelEnergy.grey : theme.palette.rexelEnergy.blackBlue}
              sx={{
                textWrap: `nowrap`,
                direction: product.isExcluded ? `rtl` : `ltr`,
                overflow: `visible`,
                maxWidth: `100%`,
              }}
            >
              {product.isExcluded
                ? translate(excludedLabel)
                : getFormattedNumberWithStaticFraction(product.quantity, null, product.unit)}
            </Typography>
            {!!product.productNetPrice && showPrices && (
              <Typography variant="subtitle1" textAlign="right" noWrap>
                {getFormattedPriceWithStaticFraction(product.productNetPrice, 0)}
              </Typography>
            )}
          </Stack>
          {contextMenu}
        </Stack>
      }
      sx={sx}
    />
  );
};
