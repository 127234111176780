import { z } from 'zod';
import { LeadOfferStatus } from '@generatedTypes/data-contracts';

const zodOfferObject = z.object({
  status: z.enum([
    LeadOfferStatus.Created,
    LeadOfferStatus.Sent,
    LeadOfferStatus.Accepted,
    LeadOfferStatus.Projected,
    LeadOfferStatus.Expired,
    LeadOfferStatus.Lost,
  ]),
});

export type OfferStatusZodSchema = z.infer<typeof zodOfferObject>;

const zodBasketItemsObject = z.object({
  excludedProductsIds: z.array(z.number()),
});

export type BasketItemsZodSchema = z.infer<typeof zodBasketItemsObject>;

export { zodOfferObject, zodBasketItemsObject };
