import { FC } from 'react';
import Icon from '@mdi/react';
import { PropsFrom } from '@utils/types/propFrom';
import { useTheme } from '@mui/material/styles';

import * as S from './MdiIconWrapper.styled';

type MDIIconWrapperProps = PropsFrom<typeof Icon>;

export interface MdiIconWrapperProps extends MDIIconWrapperProps {
  size?: `normal` | `small` | `large`;
}

export const MdiIconWrapper: FC<MdiIconWrapperProps & S.MdiIconWrapperStyled> = ({ size = `normal`, ...props }) => {
  const theme = useTheme();
  return <S.MdiIconWrapperStyled {...props} size={theme.icons.size[size]} />;
};
