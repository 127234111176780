import { ProjectProductRowDto, UpdateProjectProductRequest } from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { useQueryClient } from 'react-query';
import { LEAD, LEADS, OFFER_PRICE_OVERVIEW, PROJECTS } from '@variables/queryKeys';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';

export type PutProjectProductProps = UpdateProjectProductRequest & {
  id: ProjectProductRowDto[`id`];
};

const putProjectProduct = async ({ productId, id, quantity, isExcluded }: PutProjectProductProps) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/project-products/${id}`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify({
      productId,
      quantity,
      isExcluded,
    } as UpdateProjectProductRequest),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

export const useUpdateProjectProductMutation = (options?: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: putProjectProduct,
    onSuccess: () => {
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
      options?.onSuccess();
    },
  });

  return {
    updateProjectProductAsync: mutateAsync,
    updateProjectProduct: mutate,
    isUpdatingProjectProduct: isLoading,
    updateProjectProductBeValidationErrors: validationErrors,
  };
};
