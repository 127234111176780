import { UserDto, UserType } from '@generatedTypes/data-contracts';
import { UsersAction, USERS_ACTIONS } from '@redux/actions/users';
import { UniversalUser } from '@services/api/users/types';

export type SelectedUserId = `${UserType | `null`}/${number}`;

export type UsersState = {
  usersList: UserDto[];
  selectedUserID: SelectedUserId;
  selectedUserDetails: UniversalUser | null;
};

export const initState: UsersState = {
  usersList: [],
  selectedUserID: `null/-1`,
  selectedUserDetails: null,
};

export const UsersReducer = (state = initState, action: UsersAction) => {
  switch (action.type) {
    case USERS_ACTIONS.USERS_LIST:
      return { ...state, usersList: action.payload.usersList ?? [] };
    case USERS_ACTIONS.SELECTED_USER_ID:
      return { ...state, selectedUserID: action.payload.selectedUserID ?? initState.selectedUserID };
    case USERS_ACTIONS.SELECTED_USER_DETAILS:
      return { ...state, selectedUserDetails: action.payload.selectedUserDetails ?? null };
    default:
      return state;
  }
};
