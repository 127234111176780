import { Autocomplete } from '@react-google-maps/api';
import { useRef } from 'react';
import { AddressDto } from '@generatedTypes/data-contracts';

type AddressAutocompleteGoogleProps = {
  handleUpdateAddress: (addressData: Omit<AddressDto, `snowAndWindLoadManuallySet`>) => void;
  name: string;
  label?: string;
  placeholder?: string;
};

export const AddressAutocompleteGoogle = ({
  handleUpdateAddress,
  name,
  label,
  placeholder,
}: AddressAutocompleteGoogleProps) => {
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const options = {
    componentRestrictions: {
      country: [`se`],
    },
    fields: [`address_components`, `geometry`],
    types: [`address`],
  };

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    autoCompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    if (autoCompleteRef.current !== null) {
      const place = autoCompleteRef.current.getPlace();
      let street = ``;
      let zipCode = ``;
      let city = ``;

      if (!place.address_components) {
        return;
      }

      for (const component of place.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case `premise`: {
            street = component.long_name;
            break;
          }

          case `locality`: {
            street = `${component.long_name} ${street}`;
            break;
          }

          case `street_number`: {
            street = `${street} ${component.long_name}`;
            break;
          }

          case `route`: {
            street = `${component.long_name} ${street}`;
            break;
          }

          case `postal_code`: {
            zipCode = `${component.long_name}${zipCode}`;
            break;
          }

          case `postal_code_suffix`: {
            zipCode = `${zipCode}-${component.long_name}`;
            break;
          }
          case `postal_town`:
            city = component.long_name;
            break;
        }
      }
      const latitude = autoCompleteRef?.current?.getPlace()?.geometry?.location?.lat();
      const longitude = autoCompleteRef?.current?.getPlace()?.geometry?.location?.lng();

      handleUpdateAddress({
        city,
        street,
        zipCode,
        latitude,
        longitude,
      });
    }
  };

  const handleChangeAutocompleteInput = () => {
    handleUpdateAddress({
      city: ``,
      street: ``,
      zipCode: ``,
    });
  };

  return (
    <div>
      {label && <label htmlFor={name}>{label}</label>}
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} options={options}>
        <input
          className={`input-main`}
          id={`autocomplete-gmaps`}
          name={name}
          onChange={handleChangeAutocompleteInput}
          placeholder={placeholder}
          autoComplete="nope"
        />
      </Autocomplete>
    </div>
  );
};
