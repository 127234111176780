import { AddressPosition, SolarEnergyProject } from '../roofVisualisationTypes';
import { CURRENT_PANELS_RESOLVER_VERSION } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/panelsResolver/panelsResolver';

export enum SOLAR_MAP_EDIT_STATE {
  BASE = `base`,
  ROOF = `roof`,
  ROOF_SETTINGS = `roofSettings`,
  SHAPE = `shape`,
  PANELS = `panels`,
  GROUP = `group`,
}

export const DEFAULT_ADDRESS_POSITION = {
  lat: 59.334591,
  lng: 18.06324,
} as AddressPosition;

export const DEFAULT_SOLAR_PANEL_PROJECT: SolarEnergyProject = {
  roofs: [],
  panelsResolverVersion: CURRENT_PANELS_RESOLVER_VERSION,
};

export const PANEL_SIZE = {
  width: 1134,
  height: 1762,
  marginBetween: 20,
};

export const MAP_ZOOMING_ANIMATION_DURATION = 500;
export const DEFAULT_ZOOM = 20;
export const MAX_NUMBER_OF_VERTEX = 4;
export const DEFAULT_DISTANCE_BOX_WIDTH = 50;
export const DEFAULT_DISTANCE_BOX_HEIGHT = 14;
export const DEFAULT_DISTANCE_BOX_WITH_PREFIX_WIDTH = DEFAULT_DISTANCE_BOX_WIDTH + 40;

// DistanceBoxPath is a SVG patch. It's also dynamic so it's kept here inside the function.
export const getDistanceBoxPath = (w = DEFAULT_DISTANCE_BOX_WIDTH, h = DEFAULT_DISTANCE_BOX_HEIGHT): string =>
  `m -${w / 2 + 2} -${h / 2} a 2 2 0 0 1 2 -2 h ${w} a 2 2 0 0 1 2 2 V ${
    h / 2
  } a 2 2 0 0 1 -2 2 h -${w} a 2 2 0 0 1 -2 -2 z`;

export const vertexPath = `M12.2056 18.0068C8.89186 18.0068 6.20557 15.3205 6.20557 12.0068C6.20557 8.69313 8.89186 6.00684 12.2056 6.00684C15.5193 6.00684 18.2056 8.69313 18.2056 12.0068C18.2056 15.3205 15.5193 18.0068 12.2056 18.0068Z`;

export const enum RoofMaterialGroupsNames {
  tile = `Pannor`,
  steel = `Stål`,
  aluminium = `Aluminium`,
  ground = `Mark`,
}

export const enum RoofMaterialNames {
  concretePanel = `Betongpannor`,
}
