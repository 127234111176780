import Select, { MenuPlacement, SingleValue } from 'react-select';
import { DropdownIndicator } from '@components/dropdownIndicator/dropdownIndicator';
import './validated-wrapper.css';
import { InputErrorElement } from './input-error-element';

export type SelectWrapperProps<T> = {
  name: string;
  label: string;
  onChange?: (newValue: SingleValue<T>) => void;
  classesForContainer?: string;
  classesInput?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  validateLive?: boolean;
  errorMsg?: string;
  options?: T[];
  placeholder?: string;
  value?: T;
  menuPlacement?: MenuPlacement;
};

export function SelectWrapper<T>({ errorMsg = ``, ...props }: SelectWrapperProps<T>) {
  return (
    <div className="validated-wrapper fw">
      <div className={`column fw ${errorMsg && ` error`}${props.isRequired ? ` required` : ``}`}>
        <label htmlFor={props.name}>{props.label}</label>
        <Select<T>
          name={props.name}
          id={props.name}
          className={`react-select input-main ${props.classesInput ? props.classesInput : `input-main`}`}
          classNamePrefix="react-select"
          placeholder={props.placeholder ?? null}
          options={props.options}
          components={{ DropdownIndicator }}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
          value={props.value}
          menuPlacement={props.menuPlacement || `auto`}
        />
      </div>
      <InputErrorElement errorMsg={errorMsg} />
    </div>
  );
}
