import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export interface EmptyStyles {
  padding?: number;
  gap?: number;
}

export const EmptyStyled = styled(Box)<EmptyStyles>(({ theme, padding = 4.5, gap = 2 }) => ({
  padding: theme.spacing(padding),
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
  textAlign: `center`,
  gap: theme.spacing(gap),

  '& img': {
    width: `100%`,
    maxWidth: `250px`,
    height: `auto`,
    maxHeight: `200px`,
  },
}));
