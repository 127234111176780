import { ColumnItem, VIEW_TYPES, showNext } from '@components/columnLayout/utils';
import { PartnerForm, PartnerDataValues } from './PartnerForm';
import { useCallback } from 'react';
import { parsePartnerForUpdate } from '@services/api/partners/utils';
import { parseZipCodeInputToPlainValue } from '@utils/parsers';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { useUpdatePartner } from '@services/api/partners';
import { useQueryClient } from 'react-query';
import { PARTNER, PARTNERS } from '@variables/queryKeys';

type EditPartnerProps = {
  partner: PartnerDto;
  onClose: () => void;
};

export const EditPartner: ColumnItem<EditPartnerProps> = ({ partner, onClose }) => {
  const queryClient = useQueryClient();
  const updatePartner = useUpdatePartner({
    onSuccess: () => {
      queryClient.invalidateQueries(PARTNERS);
      queryClient.invalidateQueries(PARTNER);
      showNext(VIEW_TYPES.INFO)();
    },
  });

  const handleSubmit = useCallback(
    (values: PartnerDataValues) => {
      updatePartner.mutate({
        ...parsePartnerForUpdate({
          ...partner,
          ...values,
        }),
        ...values,
        zipCode: parseZipCodeInputToPlainValue(values.zipCode) ?? ``,
      });
      onClose();
    },
    [onClose, partner, updatePartner],
  );

  return (
    <PartnerForm
      partner={partner}
      onSubmit={handleSubmit}
      goBackHandler={onClose}
      disableForm={updatePartner.isLoading}
      beValidationResults={updatePartner.validationErrors}
    />
  );
};
