import { mdiPlus } from '@mdi/js';
import MenuItem from '@mui/material/MenuItem';
import { Dropdown } from '@components/Dropdown/Dropdown';
import { useToggle } from '@hooks/useToggle';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { ProjectAdditionDto } from '@generatedTypes/data-contracts';
import { useGetOffer } from '@services/api/offers/offers';

type CustomAdditionsAndDeductionsTabActionProps = {
  onShowAddEditDeductionForm?: (projectDeductionId?: number) => void;
  onShowAddEditAdditionForm?: (projectAdditionId?: ProjectAdditionDto[`id`]) => void;
};
export const CustomAdditionsAndDeductionsTabAction: React.FC<CustomAdditionsAndDeductionsTabActionProps> = (props) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: { otherCosts },
        },
      },
    },
  } = useTranslations();
  const { isOfferLocked } = useGetOffer();
  const [isNewDeductionOrCostDropdownOpen, toggleIsNewDeductionOrCostDropdownOpen] = useToggle();

  return (
    <Dropdown
      isDropdownOpen={isNewDeductionOrCostDropdownOpen}
      toggleIsDropdownOpen={toggleIsNewDeductionOrCostDropdownOpen}
      iconButtonIconPath={mdiPlus}
      disabled={isOfferLocked}
      transformLeft
      styles={{ alignSelf: `flex-start`, maxWidth: 48, minWidth: 48, padding: 0 }}
    >
      {[
        <MenuItem
          key={translate(otherCosts.costsOption)}
          onClick={() => {
            toggleIsNewDeductionOrCostDropdownOpen();
            props.onShowAddEditAdditionForm?.();
          }}
          sx={{ minWidth: 140 }}
        >
          {translate(otherCosts.costsOption)}
        </MenuItem>,
        <MenuItem
          key={translate(otherCosts.deductionsOption)}
          onClick={() => {
            toggleIsNewDeductionOrCostDropdownOpen();
            props.onShowAddEditDeductionForm?.();
          }}
        >
          {translate(otherCosts.deductionsOption)}
        </MenuItem>,
      ]}
    </Dropdown>
  );
};
