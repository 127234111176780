import { IconButton, Stack, Typography, TypographyProps, StackProps, IconButtonProps } from '@mui/material';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { ElementType } from 'react';

export interface TypographyWithIconButtonProps {
  children: string;
  iconPath: string;
  onClick: () => void;
  className?: string;
  direction?: StackProps[`direction`];
  justifyContent?: StackProps[`justifyContent`];
  typographyVariant?: TypographyProps[`variant`];
  typographyComponent?: ElementType<unknown>;
  gap?: StackProps[`spacing`];
  alignItems?: StackProps[`alignItems`];
  lineHeight?: TypographyProps[`lineHeight`];
  iconButtonSx?: IconButtonProps[`sx`];
}

export const TypographyWithIconButton = ({
  children,
  iconPath,
  onClick,
  className,
  direction = `row`,
  justifyContent = `space-between`,
  typographyVariant,
  typographyComponent,
  gap,
  alignItems = `center`,
  lineHeight = `40px`,
  iconButtonSx,
}: TypographyWithIconButtonProps) => (
  <Stack className={className} direction={direction} justifyContent={justifyContent} gap={gap} alignItems={alignItems}>
    <Typography
      variant={typographyVariant}
      component={typographyComponent as ElementType<unknown>}
      lineHeight={lineHeight}
    >
      {children}
    </Typography>
    <IconButton onClick={onClick} sx={iconButtonSx}>
      <MdiIconWrapper path={iconPath} />
    </IconButton>
  </Stack>
);
