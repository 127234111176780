import { useMemo } from 'react';
import { TerrainTypeAttributeValueDto } from '@generatedTypes/data-contracts';
import { Box, Typography } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';

interface TerrainTypeHelperTextProps {
  terrainTypes: TerrainTypeAttributeValueDto[];
  selectedTerrainType: string;
}

export const TerrainTypeHelperText = ({ terrainTypes, selectedTerrainType }: TerrainTypeHelperTextProps) => {
  const {
    translate,
    translations: {
      common: { terrainTypes: terrainTypesTranslations },
    },
  } = useTranslations();
  const mappedTerrainTypes = useMemo(
    () =>
      terrainTypes.reduce<Record<string, TerrainTypeAttributeValueDto>>(
        (acc, { id, ...rest }) => ({ ...acc, [String(id)]: { ...rest, id } }),
        {},
      ),
    [terrainTypes],
  );

  const type = translate(
    terrainTypesTranslations[
      `type${mappedTerrainTypes[selectedTerrainType]?.type.at(-1) || 0}` as keyof typeof terrainTypesTranslations
    ],
  );

  return (
    <>
      <Typography variant="h4">
        {mappedTerrainTypes[selectedTerrainType]?.name || terrainTypes[0].name} - {type}
      </Typography>
      <Box minHeight={72} paddingBlockEnd={3}>
        <Typography>{mappedTerrainTypes[selectedTerrainType]?.description || terrainTypes[0]?.description}</Typography>
      </Box>
    </>
  );
};
