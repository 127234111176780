import { Card } from '@components/Card/Card';
import { ProjectDto, ProjectType, SolarEnergyProjectRowDto } from '@generatedTypes/data-contracts';
import { mdiPencil } from '@mdi/js';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import React from 'react';
import { theme } from 'src/styles/theme/theme';
import { kiloWattHourFormatter } from '../../utils';

interface SolarProjectHeaderProps {
  solarEnergyDetails?: SolarEnergyProjectRowDto;
  projectReady?: boolean;
}

const SolarProjectHeader: React.FC<SolarProjectHeaderProps> = ({ solarEnergyDetails, projectReady }) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: { productionIsCalculated },
        },
      },
      common: { roof },
    },
  } = useTranslations();

  return solarEnergyDetails && projectReady ? (
    <Typography variant="subtitle2" component="p">
      {`${solarEnergyDetails?.numberOfRoofs} ${translate(roof)}, 
  ${kiloWattHourFormatter(solarEnergyDetails?.totalYearlyProductionInkWh)} kWh`}
    </Typography>
  ) : (
    <Stack spacing={1} direction="row" alignItems="center">
      <CircularProgress size={16} />
      <Typography>{translate(productionIsCalculated)}</Typography>
    </Stack>
  );
};

interface ProjectRowEditCardProps {
  header: string;
  project: ProjectDto;
  onProjectClick?: () => void;
  image: string | undefined;
}

export const ProjectRowEditCard: React.FC<ProjectRowEditCardProps> = ({ header, project, onProjectClick, image }) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: { notComplete },
        },
      },
    },
  } = useTranslations();

  return (
    <Card
      sx={{
        display: `flex`,
        minHeight: 92,
        '& .MuiCardContent-root': { paddingBlockStart: 0 },
        '& .MuiCardMedia-root': {
          maxWidth: 92,
          width: 92,
          marginInlineStart: `-2px`,
          backgroundColor: theme.palette.rexelEnergy.lightBrown,
        },
      }}
      title={
        <Stack>
          <Typography variant="h2">{header}</Typography>
          {project.type === ProjectType.SolarEnergy && (
            <SolarProjectHeader
              solarEnergyDetails={project.solarEnergyProjectDetails}
              projectReady={!project.isSavingInProgress}
            />
          )}
        </Stack>
      }
      key={project.id}
      titleAction={!project.isSavingInProgress ? onProjectClick : undefined}
      titleActionIcon={!project.isSavingInProgress && onProjectClick ? mdiPencil : undefined}
      cardMedia={image ? { src: image, alt: `` } : undefined}
    >
      {project.type === ProjectType.SolarEnergy && !project.isCompleted && (
        <Typography variant="subtitle1" marginBlockStart={1}>
          {translate(notComplete)}
        </Typography>
      )}
    </Card>
  );
};
