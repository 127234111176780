import SolarPanelSelected from '@assets/svg/roofVisualisation/SolarPanelSelected.svg';
import SolarPanelHovered from '@assets/svg/roofVisualisation/SolarPanelHovered.svg';
import { Orientation } from '@generatedTypes/data-contracts';
import { Panel } from '../roofVisualisationTypes';
import { SolarPanelStyled } from './SolarPanel.styled';
import { useHover } from './utils';

type PanelProps = {
  panel: Panel;
  width: number;
  height: number;
  onClick?: () => void;
  disable?: boolean;
  orientation: Orientation | null;
  parentWidth: number;
  parentHeight: number;
  isOfferPage?: boolean;
};

export const MapSolarPanel = ({
  panel,
  width,
  height,
  onClick,
  disable = false,
  orientation,
  parentWidth,
  parentHeight,
  isOfferPage,
}: PanelProps) => {
  const { pointOnParent, rotation, reversed, active } = panel;
  const { x, y } = pointOnParent ?? { x: 0, y: 0 };
  const isHorizontal = orientation === Orientation.Horizontal;
  const rotationAfterOrientation = isHorizontal ? (rotation ?? 0) + 90 : rotation;
  const printSolarPanel = active && !disable;
  const [isHovered, hoverFunctions] = useHover();
  const biggerSide = Math.max(width, height);
  const visibleOnTheMap =
    Math.min(x, y) + biggerSide > 0 && x - biggerSide < parentWidth && y - biggerSide < parentHeight;

  if (!visibleOnTheMap) {
    return null;
  }

  const solarPanelStyle = {
    top: y,
    left: x,
    width,
    height,
    transform: `rotate(${rotationAfterOrientation}deg)
    translateX(${reversed && !isHorizontal ? -width : 0}px)
    translateY(${isHorizontal && !reversed ? -height : 0}px)`,
  };
  const style = {
    transform: `rotate(${0}deg)`,
  };

  return (
    <SolarPanelStyled
      style={solarPanelStyle}
      onClick={onClick}
      selected={active}
      disabled={disable}
      {...hoverFunctions}
    >
      {printSolarPanel ? (
        <img src={!isOfferPage && isHovered ? SolarPanelHovered : SolarPanelSelected} alt="solar panel" style={style} />
      ) : null}
    </SolarPanelStyled>
  );
};
