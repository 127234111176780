import { ColumnItem } from '@components/columnLayout/utils';
import { ICON_COLOR, SvgIcon } from '@components/controls/svgIcon';
import { convertFormValuesToAppliedFilters } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/productsAttributeFilters/utils/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback, useMemo, useState } from 'react';

import { ProductAttributeDataType, ProductCategoryRowDto } from '@generatedTypes/data-contracts';
import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Stack } from '@mui/material';
import { getFieldValues } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/productsAttributeFilters/utils/getFieldValues';
import {
  AppliedFilters,
  FormValues,
  ProductsAttributeFiltersSchemaType,
} from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/productsAttributeFilters/utils/types';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { ProductAttributeRows } from './productAttributeRows';
import { filterProducts } from './utils/filterProducts';
import { getAttributesMapForFilteredProducts } from './utils/getAttributesMapForFilteredProducts';
import { defaultWidthForm } from '@components/forms/forms.styled';

export const productsAttributeFiltersZodObject = {
  attributeId: z.number(),
  attributeName: z.string(),
  valueName: z.string(),
  active: z.boolean(),
};
export const listProductAttributeZodObject = z.object({
  ...productsAttributeFiltersZodObject,
  value: z.number(),
  type: z.literal(ProductAttributeDataType.List),
  options: z
    .object({
      value: z.number(),
      label: z.string(),
      disabled: z.boolean(),
    })
    .array(),
});

export const numberProductAttributeZodObject = z.object({
  ...productsAttributeFiltersZodObject,
  value: z.number(),
  type: z.literal(ProductAttributeDataType.Number),
  min: z.number(),
  max: z.number(),
});

export const productsAttributeFiltersZodSchema = z.array(
  listProductAttributeZodObject.or(numberProductAttributeZodObject),
);

type ProductsAttributeFiltersProps = {
  productCategory: ProductCategoryRowDto | undefined;
  initialFilters: AppliedFilters;
  submitFilters: (newFilters: AppliedFilters) => void;
  onCancel: () => void;
};

export const ProductAttributeFilters: ColumnItem<ProductsAttributeFiltersProps> = ({
  productCategory,
  onCancel,
  submitFilters,
  initialFilters,
}) => {
  const {
    translate,
    translations: {
      editProduct: { filtering },
    },
  } = useTranslations();

  const [filters, setFilters] = useState<AppliedFilters>(initialFilters);
  const attributesMap = useMemo(
    () =>
      getAttributesMapForFilteredProducts(
        productCategory?.productsAttributes ?? [],
        filters,
        productCategory?.products ?? [],
      ),
    [filters, productCategory?.products, productCategory?.productsAttributes],
  );
  const availableFilters = useMemo(() => filters.filter((filter) => filter.active), [filters]);

  const formValues = useMemo<ProductsAttributeFiltersSchemaType>(
    () =>
      productCategory
        ? getFieldValues({
            attributesMap,
            appliedFilters: filters,
            initialFilters,
            productCategory,
          })
        : [],
    [attributesMap, filters, initialFilters, productCategory],
  );

  const formFunctions = useForm<ProductsAttributeFiltersSchemaType>({
    defaultValues: formValues,
    resolver: zodResolver(productsAttributeFiltersZodSchema),
    values: formValues,
  });
  const { control, watch } = formFunctions;

  const filteredProducts = useMemo(
    () => filterProducts(productCategory?.products, filters),
    [filters, productCategory?.products],
  );
  const productsCount = filteredProducts.length;

  watch((formValues) => {
    const newFilters = convertFormValuesToAppliedFilters(formValues as FormValues);
    setFilters(newFilters);
  });

  const reset = useCallback(() => {
    setFilters([]);
  }, []);

  const onSubmit = useCallback(() => {
    submitFilters(availableFilters);
  }, [availableFilters, submitFilters]);

  return (
    <Stack sx={{ p: 3, width: defaultWidthForm }} gap={3}>
      <FormProvider {...formFunctions}>
        <div className="row gap-small">
          <SvgIcon iconId="chevron-left" color={ICON_COLOR.COLOR_DARK} onClick={onCancel} />
          <h1 className="bold">{translate(filtering.filterProductHeader)}</h1>
        </div>
        <div className="row space-between">
          <p className="center-vertically">
            {productsCount} {translate(filtering.resultsNumberLabel)}
          </p>
          {availableFilters.length ? (
            <Button variant="outlined" onClick={reset}>
              {translate(filtering.reset)}
            </Button>
          ) : null}
        </div>

        {filters.length ? <ProductAttributeRows /> : null}
        <Button variant="contained" onClick={onSubmit}>
          {translate(filtering.filterButton)}
        </Button>
        <DevTool control={control} />
      </FormProvider>
    </Stack>
  );
};
