import { ViewType } from '@components/columnLayout/utils';
import { TAction } from '@redux/reducers';
import { GlobalState } from '@redux/reducers/global';
import { DialogProps } from '@components/dialog/dialog';

export const GLOBAL_ACTIONS = {
  IS_MOBILE_VIEW: `IS_MOBILE_VIEW`,
  LAST_LOCATION: `LAST_LOCATION`, //used for RequireAuth, in fact current pathname excluded login/
  PREVIOUS_LOCATION: `PREVIOUS_LOCATION`, //pathname of previous window
  CURRENT_LEAD_ID: `CURRENT_LEAD_ID`,
  CURRENT_USER_ID: `CURRENT_USER_ID`,
  CURRENT_PAGE_VIEW: `CURRENT_PAGE_VIEW`,
  PREVIOUS_PAGE_VIEW: `PREVIOUS_PAGE_VIEW`,
  DIALOG: `DIALOG`,
} as const;

export type GlobalAction = TAction<typeof GLOBAL_ACTIONS, GlobalState>;

export const setIsMobile = (value = false): GlobalAction => ({
  type: GLOBAL_ACTIONS.IS_MOBILE_VIEW,
  payload: { isMobileView: value },
});

export const setLastLocation = (value = `/`): GlobalAction => ({
  type: GLOBAL_ACTIONS.LAST_LOCATION,
  payload: { lastLocation: value },
});

export const setPreviousLocation = (value = `/`): GlobalAction => ({
  type: GLOBAL_ACTIONS.PREVIOUS_LOCATION,
  payload: { previousLocation: value },
});

export const setCurrentLeadID = (value: number): GlobalAction => ({
  type: GLOBAL_ACTIONS.CURRENT_LEAD_ID,
  payload: { currentLeadID: value },
});

export const setCurrentPageView = (value: ViewType): GlobalAction => ({
  type: GLOBAL_ACTIONS.CURRENT_PAGE_VIEW,
  payload: { currentPageView: value },
});

export const setPreviousPageView = (value: ViewType): GlobalAction => ({
  type: GLOBAL_ACTIONS.PREVIOUS_PAGE_VIEW,
  payload: { previousPageView: value },
});

export const setDialog = (value: DialogProps): GlobalAction => ({
  type: GLOBAL_ACTIONS.DIALOG,
  payload: { dialog: value },
});
