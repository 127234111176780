import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { useTranslations } from '@services/hooks/translations/useTranslations';

import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';

import './forgotPassword.css';
import { useForgotPassword } from '@services/api/accounts';
import { Button } from '@mui/material';
import { requiredEmail } from '@variables/zod';

const forgotPasswordZodObject = {
  email: requiredEmail,
};

const forgotPasswordSchema = z.object(forgotPasswordZodObject);

export type ForgotPasswordValuesType = z.infer<typeof forgotPasswordSchema>;

const initialValues = { email: `` };

export function ForgotPassword() {
  const { translations, translate } = useTranslations();
  const navigate = useNavigate();
  const tr = translations.forgotPassword;

  const { control, handleSubmit, getValues } = useForm({
    values: initialValues,
    resolver: zodResolver(forgotPasswordSchema),
  });

  const { resetPassword, isResettingPassword } = useForgotPassword(() => {
    navigate(`/forgot-password-confirmation/${getValues().email}`);
  });

  const handleResetPaswordSubmit = useCallback(
    (values: ForgotPasswordValuesType) => {
      resetPassword(values);
    },
    [resetPassword],
  );

  return (
    <div className="forgot-password-form-wrapper fh fw grid-container">
      <form
        className="box box-large column gap col-desktop-4 col-desktop-offset-5 space-between"
        onSubmit={handleSubmit(handleResetPaswordSubmit)}
      >
        <fieldset disabled={isResettingPassword} className="column gap">
          <h3>{translate(tr.emailHeader)}</h3>
          <InputWrapper control={control} name="email" label={translate(tr.email)} />
        </fieldset>
        <div className="buttons-wrapper column stretch gap fw">
          <Button variant="contained" type="submit" disabled={isResettingPassword}>
            {translate(tr.send)}
          </Button>
        </div>
      </form>
    </div>
  );
}
