import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import { Checkbox, CheckBoxProps } from '../dumb/Checkbox/Checkbox';

interface SingleCheckboxProps<T extends FieldValues> extends Omit<CheckBoxProps, `name` | `checked` | `onChange`> {
  control: Control<T>;
  name: Path<T>;
  defaultValue?: PathValue<T, Path<T>>;
}

export const SingleCheckbox = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  ...restProps
}: SingleCheckboxProps<T>) => {
  const { field } = useController({ control, name, defaultValue });

  return <Checkbox {...restProps} name={field.name} checked={field.value} onChange={field.onChange} />;
};
