import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPartnerId } from '@redux/reducers/slices/user';
import { selectIsPartnerUser } from '@redux/selectors/user';
import { useQueryClient } from 'react-query';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { DEDUCTION_TEMPLATES, DELETE, PARTNER } from '@variables/queryKeys';
import { deleteDeductionTemplate } from '@services/api/deductionTemplates/deductionTempaltes';

export const useDeleteDeductionTemplate = (options?: { onSuccess?: () => void }) => {
  const { partnerId: partnerIdParams } = useParams<{ partnerId: string }>();
  const partnerId = useSelector(selectPartnerId);
  const isPartnerUser = useSelector(selectIsPartnerUser);

  const selectedPartnerId = isPartnerUser ? partnerId : Number(partnerIdParams);
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading } = useCustomMutation({
    mutationKey: [DELETE, DEDUCTION_TEMPLATES],
    mutationFn: deleteDeductionTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries([PARTNER, DEDUCTION_TEMPLATES, selectedPartnerId]);
      options?.onSuccess?.();
    },
  });

  return {
    isDeletingDeductionTemplate: isLoading,
    deleteDeductionTemplate: mutate,
    deleteDeductionTemplateAsync: mutateAsync,
  };
};
