import { Orientation } from '@generatedTypes/data-contracts';
import { Edge, Panel } from '../../../roofVisualisationTypes';
import { getProjectionForMap } from '../../accessors';
import { PANEL_SIZE } from '../../constants';
import {
  CALCULATION_STARTING_POINT_OFFSET,
  getPanelDimensions,
  getPanelsHeadings,
  wholePanelIsInsidePolygon,
} from './utils';

type ResolverFunctionsParams = {
  map: google.maps.Map;
  edges: Edge[];
  polygon: google.maps.Polygon;
  currentPanels: Panel[];
  calculationArea: google.maps.Polygon;
  panelOrientation?: Orientation;
};

export const resolverFunctions = ({
  map,
  edges,
  polygon,
  currentPanels,
  calculationArea,
  panelOrientation,
}: ResolverFunctionsParams) => {
  const newPanels: Panel[] = [];
  const projection = getProjectionForMap(map);
  const panelDimensions = getPanelDimensions(PANEL_SIZE, panelOrientation);

  const calculationAreaPath = calculationArea.getPath().getArray();
  const headings = getPanelsHeadings(edges);
  const { headingRow, headingColumn } = headings;

  let startingPointInColumn = google.maps.geometry.spherical.computeOffset(
    calculationAreaPath[0],
    CALCULATION_STARTING_POINT_OFFSET,
    headingRow,
  );
  startingPointInColumn = google.maps.geometry.spherical.computeOffset(
    startingPointInColumn,
    panelDimensions.margin + CALCULATION_STARTING_POINT_OFFSET,
    headingColumn,
  );
  let panelEndPointInRow = google.maps.geometry.spherical.computeOffset(startingPointInColumn, 0, 0);
  let panelPosition = google.maps.geometry.spherical.computeOffset(panelEndPointInRow, 0, 0);

  const movePanelInRowToNextPosition = () => {
    panelPosition = google.maps.geometry.spherical.computeOffset(
      panelEndPointInRow,
      panelDimensions.margin,
      headingRow,
    );
    panelEndPointInRow = google.maps.geometry.spherical.computeOffset(
      panelPosition,
      panelDimensions.panelWidth,
      headingRow,
    );
  };

  const movePanelInColumnToNextPosition = () => {
    startingPointInColumn = google.maps.geometry.spherical.computeOffset(
      startingPointInColumn,
      panelDimensions.panelHeight + panelDimensions.margin,
      headingColumn,
    );
    panelEndPointInRow = google.maps.geometry.spherical.computeOffset(startingPointInColumn, 0, 0);
  };

  const isPanelInCalculationArea = () => google.maps.geometry.poly.containsLocation(panelPosition, calculationArea);

  const isNextRowInCalculationArea = () =>
    google.maps.geometry.poly.containsLocation(panelEndPointInRow, calculationArea);

  const isPanelInOriginPolygon = () =>
    wholePanelIsInsidePolygon({
      panelPosition,
      headings,
      dimensions: panelDimensions,
      polygon,
    });

  const pushCurrentPanel = (insideShape: boolean, pointOnCalculationArea: { col: number; row: number }) => {
    projection &&
      newPanels.push({
        id: newPanels.length,
        latLng: panelPosition,
        active: insideShape,
        reversed: edges[0].reversed,
        pointOnParent: projection.fromLatLngToContainerPixel(panelPosition),
        pointOnCalculationArea,
        rotation: edges[0 ?? 0].rotation + -90,
        insideShape,
      });
  };

  const getNewPanels = () =>
    newPanels.filter((panel) => panel.insideShape).length ===
      currentPanels.filter((panel) => panel.insideShape).length && newPanels.length === currentPanels.length
      ? [...newPanels.map((panel, index) => ({ ...panel, active: currentPanels[index].active }))]
      : newPanels;

  return {
    movePanelInRowToNextPosition,
    movePanelInColumnToNextPosition,
    isPanelInCalculationArea,
    isNextRowInCalculationArea,
    isPanelInOriginPolygon,
    pushCurrentPanel,
    getNewPanels,
    projectionLoaded: projection !== null,
  };
};
