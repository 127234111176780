import { Card } from '@components/Card/Card';
import { ListItem } from '@components/ListMui/ListItem';
import { useToggle } from '@hooks/useToggle';
import { mdiPlus } from '@mdi/js';
import { Drawer, List } from '@mui/material';
import { NewUser } from '@pages/Users/new-user';
import { selectIsDealerUser, selectIsUserAdmin } from '@redux/reducers/slices/user';
import { useGetPartner, useGetPartnerUsers } from '@services/api/partners';
import { useCreateUser, useDeleteUser, useGetUser, useUpdateUser } from '@services/api/users';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { PARTNER, PARTNERS } from '@variables/queryKeys';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { EditUser } from '@pages/Users/EditUser';
import { useState } from 'react';
import { UserType } from '@generatedTypes/data-contracts';

export const PartnersUsersTab = () => {
  const { partner } = useGetPartner();
  const queryClient = useQueryClient();
  const { partnerUsers } = useGetPartnerUsers();
  const {
    translate,
    translations: {
      common: { administrator },
      partners: {
        details: { listHeader },
      },
    },
  } = useTranslations();
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(undefined);
  const [isNewPartnerUserOpen, toggleIsNewPartnerUserOpen] = useToggle();
  const [isEditUserOpen, toggleIsEditUserOpen] = useToggle();

  const { user } = useGetUser({ userId: String(selectedUserId), userType: UserType.Partner });

  const isDealerUser = useSelector(selectIsDealerUser);
  const isAdmin = useSelector(selectIsUserAdmin);

  const canModifyUsers = !isDealerUser && isAdmin;

  const createUser = useCreateUser({
    onSuccess: () => {
      queryClient.invalidateQueries(PARTNERS);
      queryClient.invalidateQueries(PARTNER);
      toggleIsNewPartnerUserOpen();
    },
  });
  const updateUser = useUpdateUser({
    onSuccess: () => {
      queryClient.invalidateQueries(PARTNERS);
      queryClient.invalidateQueries(PARTNER);
    },
  });
  const deleteUser = useDeleteUser({
    onSuccess: () => {
      queryClient.invalidateQueries(PARTNERS);
      queryClient.invalidateQueries(PARTNER);
    },
  });

  return (
    <Card
      title={translate(listHeader)}
      titleAction={canModifyUsers ? toggleIsNewPartnerUserOpen : undefined}
      titleActionIcon={canModifyUsers ? mdiPlus : undefined}
    >
      <List>
        {partnerUsers.map(({ firstName, lastName, id, isAdmin, email }) => (
          <ListItem
            key={id}
            title={`${firstName} ${lastName}`}
            subtitle={email}
            secondaryAction={isAdmin ? translate(administrator) : undefined}
            onClick={() => {
              setSelectedUserId(id);
              toggleIsEditUserOpen();
            }}
          />
        ))}
      </List>
      {canModifyUsers && (
        <>
          <Drawer anchor="right" open={isNewPartnerUserOpen} onClose={toggleIsNewPartnerUserOpen}>
            <NewUser
              onNewUserExit={toggleIsNewPartnerUserOpen}
              createUser={createUser.mutate}
              disableForm={createUser.isLoading}
              initialPartner={partner?.id}
              beValidationResults={createUser.validationErrors}
              userTypeMode={`Partner`}
              hideAssignments
              predefinedUser={{
                userType: UserType.Partner,
                partnerId: partner?.id ?? -1,
              }}
            />
          </Drawer>
          <Drawer anchor="right" open={isEditUserOpen} onClose={toggleIsEditUserOpen}>
            <EditUser
              updateUser={(values) => {
                updateUser.mutate(values);
                toggleIsEditUserOpen();
              }}
              deleteUser={() => {
                deleteUser.mutate(user);
                toggleIsEditUserOpen();
              }}
              user={user}
              disableForm={updateUser.isLoading}
              closeModal={toggleIsEditUserOpen}
              hideAssignments
            />
          </Drawer>
        </>
      )}
    </Card>
  );
};
