import { Card } from '@components/Card/Card';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import { mdiPencil } from '@mdi/js';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { UploadPartnerLogo } from './UploadPartnerLogo';
import { ProfilePicture } from '@components/ProfilePicture/ProfilePicture.styled';

export interface LogoCardProps {
  partner: PartnerDto | null;
}

export const LogoCard = ({ partner }: LogoCardProps) => {
  const {
    translate,
    translations: {
      users: { details },
    },
  } = useTranslations();
  const [showUploadLogoPanel, toggleShowUploadLogoPanel] = useToggle();

  const logoUri = partner?.logotypeUri;

  return (
    <Card title={translate(details.logo.logoType)} titleAction={toggleShowUploadLogoPanel} titleActionIcon={mdiPencil}>
      <Stack spacing={3}>
        <Typography variant="h4" component="b">
          {translate(details.logo.tabs.tabPanel1.header)}
        </Typography>
        {logoUri ? <div className="row">{translate(details.logo.tabs.tabPanel1.text)}</div> : <></>}
        <HorizontalLine />
        <Stack direction="row" alignItems="center" spacing={3} flexWrap="wrap">
          {logoUri && <ProfilePicture src={logoUri} alt="partner logo" loading="lazy" />}
          <Stack alignItems="center" flexGrow={1}>
            <Typography component="b" variant="h2">
              {partner?.name}
            </Typography>
            <div>{partner?.organizationNumber}</div>
          </Stack>
        </Stack>
      </Stack>
      <Drawer open={showUploadLogoPanel} anchor="right" onClose={toggleShowUploadLogoPanel}>
        <UploadPartnerLogo partner={partner} goBackHandler={toggleShowUploadLogoPanel} disableForm={false} />
      </Drawer>
    </Card>
  );
};
