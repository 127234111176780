import TextFieldCore from '@mui/material/TextField';
import { NumericFormat } from 'react-number-format';
import { FieldValues, useController } from 'react-hook-form';
import { TextFieldProps } from '@components/controls/react-hook-form-friendly/smart/MuiInput';

const DEFAULT_DECIMAL_SCALE = 2;

type MuiNumberInputProps<T extends FieldValues> = TextFieldProps<T> & {
  decimalScale?: number;
};

export const MuiNumberInput = <T extends FieldValues>({
  control,
  name,
  helperText,
  decimalScale = DEFAULT_DECIMAL_SCALE,
  ...restProps
}: MuiNumberInputProps<T>) => {
  const { field, fieldState } = useController({ control, name });
  return (
    <NumericFormat
      {...restProps}
      defaultValue={restProps.defaultValue as number | string | null}
      customInput={TextFieldCore}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      helperText={helperText || fieldState.error?.message}
      error={!!fieldState.error}
      decimalSeparator=","
      decimalScale={decimalScale}
    />
  );
};
