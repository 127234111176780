import { UserType } from '@generatedTypes/data-contracts';
import { AppState } from '@redux/reducers';
import { createSelector } from 'reselect';

export const selectUserType = createSelector(
  (state: AppState) => state.User,
  (user) => user.userType,
);

export const selectUser = (state: AppState) => state.User;

export const selectIsOperationUser = createSelector(selectUserType, (userType) => userType === UserType.Operation);
export const selectIsPartnerUser = createSelector(selectUserType, (userType) => userType === UserType.Partner);

export const selectIsLoggedIn = createSelector(selectUser, (user) => user.isLoggedIn);
