import { SwitchDumb, SwitchDumbProps } from '@components/controls/react-hook-form-friendly/dumb/Switch/Switch';
import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';

type SwitchProps<T extends FieldValues> = Omit<SwitchDumbProps, `checked` | `onClick`> & {
  control: Control<T>;
  name: Path<T>;
  defaultValue?: PathValue<T, Path<T>>;
};

export const Switch = <T extends FieldValues>({ name, defaultValue, control, label, disabled }: SwitchProps<T>) => {
  const { field } = useController({ control, name, defaultValue });

  return <SwitchDumb checked={field.value} disabled={disabled} onClick={field.onChange} label={label} />;
};
