import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { ColumnItem } from '@components/columnLayout/utils';
import { InputWrapper, SingleCheckbox } from '@components/controls/react-hook-form-friendly/smart';
import { PutUser } from '@services/api/users/users';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { UniversalUser } from '@services/api/users/types';
import { useSelector } from 'react-redux';
import { CheckboxList } from '@components/controls/react-hook-form-friendly/smart';
import { UserType } from '@generatedTypes/data-contracts';
import { requiredEmail, requiredNumericString, requiredString } from '@variables/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { DevTool } from '@hookform/devtools';
import { useGetDealers } from '@services/api/dealers';
import {
  selectIsDealerUser,
  selectIsOperationUser,
  selectIsPartnerUser,
  selectUserId,
} from '@redux/reducers/slices/user';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { FormSection } from '@components/forms/MuiFormSection';
import { mdiTrashCanOutline } from '@mdi/js';

const editUserZodObject = {
  firstName: requiredString(),
  lastName: requiredString(),
  email: requiredEmail,
  phoneNumber: requiredNumericString().optional(),
  dealerIds: z.number().array().optional(),
  isAdmin: z.boolean(),
};

const editUserZodSchema = z.object(editUserZodObject);

type EditUserValuesType = z.infer<typeof editUserZodSchema>;

type EditUserProps = {
  user: UniversalUser | null;
  updateUser: (user: PutUser) => void;
  deleteUser?: () => void;
  disableForm: boolean;
  closeModal: () => void;
  hideAssignments?: boolean;
};

export const EditUser: ColumnItem<EditUserProps> = ({
  user,
  updateUser,
  deleteUser,
  disableForm,
  closeModal,
  hideAssignments,
}) => {
  const {
    translate,
    translations: {
      users: { create },
      dealer: {
        users: {
          create: { branchOffice },
        },
      },
    },
  } = useTranslations();

  const currentUserId = useSelector(selectUserId);
  const isLoggedInUserPartner = useSelector(selectIsPartnerUser);
  const isLoggedInUserDealer = useSelector(selectIsDealerUser);
  const isLoggedInUserOperation = useSelector(selectIsOperationUser);
  const isEditingOwnUser = user?.id === currentUserId;
  const { dealers, isLoadingDealers } = useGetDealers({ enabled: !isLoggedInUserPartner });

  const isSelectedUserDealer = user?.type === UserType.Dealer;
  const shouldDisplayDealers = isSelectedUserDealer && !hideAssignments;

  const canRemoveAdmin = !(user?.isLastAdmin || isEditingOwnUser);

  const dealersOptions = useMemo(
    () =>
      dealers?.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [dealers],
  );
  const { reset, handleSubmit, control } = useForm({
    defaultValues: {
      firstName: ``,
      lastName: ``,
      email: ``,
      phoneNumber: ``,
      dealerIds: [],
      isAdmin: false,
    } as EditUserValuesType,
    resolver: zodResolver(editUserZodSchema),
  });

  useEffect(() => {
    if (user) {
      const values: EditUserValuesType = {
        firstName: user?.firstName || ``,
        lastName: user?.lastName || ``,
        email: user?.email || ``,
        phoneNumber: user?.phoneNumber || ``,
        dealerIds: user?.dealers?.map(({ id }) => id) || [],
        isAdmin: user?.isAdmin || false,
      };
      reset(values);
    }
  }, [user, reset]);

  const onCancel = useCallback(() => {
    reset();
    closeModal();
  }, [closeModal, reset]);

  const onSubmit = useCallback(
    (values: EditUserValuesType) => {
      if (user?.id && user?.type) {
        updateUser({
          ...values,
          id: user.id,
          type: user.type,
          phoneNumber: values.phoneNumber,
          dealerIds: isSelectedUserDealer && isLoggedInUserOperation ? (values.dealerIds as number[]) : undefined,
        });
      }
    },
    [user, updateUser, isSelectedUserDealer, isLoggedInUserOperation],
  );

  return (
    <FormWrapper
      disabled={disableForm}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      titleAction={canRemoveAdmin ? deleteUser : undefined}
      iconPath={deleteUser && canRemoveAdmin ? mdiTrashCanOutline : undefined}
      title={translate(create.editHeader)}
    >
      <FormSection>
        <InputWrapper
          control={control}
          isDisabled={isLoadingDealers}
          isRequired
          label={translate(create.firstName)}
          name="firstName"
        />
        <InputWrapper
          control={control}
          isDisabled={isLoadingDealers}
          isRequired
          label={translate(create.lastName)}
          name="lastName"
        />
        <InputWrapper
          control={control}
          isDisabled
          isRequired
          label={translate(create.email)}
          name="email"
          type="email"
        />
        <InputWrapper
          control={control}
          isDisabled={isLoadingDealers}
          label={translate(create.phone)}
          name="phoneNumber"
          type="tel"
        />
      </FormSection>
      <FormSection title={translate(create.competenceHeader)}>
        <SingleCheckbox
          label={translate(create.competenceAdmin)}
          value="administrator"
          control={control}
          name="isAdmin"
          defaultValue={false}
          isLabelOnRight
          isDisabled={!canRemoveAdmin}
        />
      </FormSection>
      {shouldDisplayDealers && (
        <FormSection>
          <h3 className="bold">{translate(branchOffice)}</h3>
          <CheckboxList
            control={control}
            isDisabled={isLoggedInUserDealer || isLoadingDealers}
            name="dealerIds"
            options={dealersOptions}
          />
        </FormSection>
      )}
      <DevTool control={control} />
    </FormWrapper>
  );
};
