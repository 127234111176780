import { AppState } from '@redux/reducers';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { managePageViews, VIEW_TYPES, ViewType } from './utils';
import './columnLayout.css';
import { selectIsMobile, selectPreviousPageView } from '@redux/selectors/global';

type ColumnLayoutProps = {
  children: React.ReactNode;
  className?: string;
};

const ColumnLayoutComponent: React.FC<ColumnLayoutProps> = ({ children, className }) => {
  const currentView = useSelector<AppState, ViewType>((state) => state.Global.currentPageView);
  const previousView = useSelector(selectPreviousPageView);
  const [blocked, setBlocked] = useState<boolean>(false);

  const isMobileView = useSelector(selectIsMobile);
  const appRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (appRef.current) {
      managePageViews(previousView, currentView, appRef.current);
      // store.dispatch(setPreviousPageView(currentView));
    }
    setBlocked(currentView === VIEW_TYPES.EDIT);
    // TODO: check full list of useEffect dependencies to add or remove
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView]);

  return (
    <div id="app" className={className} ref={appRef}>
      <div className="page-view-wrapper">
        <div className={`overlay${blocked ? ` active` : ``}`} />
        <div className="page-view container gap">
          <div
            id="page-view-port"
            className="grid-container no-wrap fw "
            style={!isMobileView ? { transform: `` } : {}}
          >
            {children}
          </div>
        </div>
      </div>
      <div id="dialog-container">{/* <Dialog callback={resetForm}/> */}</div>
    </div>
  );
};

/**@deprecated */
export const ColumnLayout = ColumnLayoutComponent;
