import { FormControlLabel, Switch } from '@mui/material';

export type SwitchDumbProps = {
  checked: boolean;
  label?: string;
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLLabelElement>;
};

export const SwitchDumb: React.FC<SwitchDumbProps> = ({ checked, label, disabled, onClick }) => {
  return (
    <FormControlLabel
      sx={(theme) => ({ display: `inline-flex`, gap: theme.spacing(1), margin: 0, padding: 0 })}
      control={<Switch />}
      label={label}
      disabled={disabled}
      checked={checked}
      onClick={onClick}
    />
  );
};
