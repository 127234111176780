import { Option } from '@components/controls/react-hook-form-friendly/smart/MuiSelect';
import { ProductAttributeValueRowDto } from '@generatedTypes/data-contracts';
import { Box, Stack, Typography } from '@mui/material';

const snowWindLoadsColorsMap: Record<number, string> = {
  1: `#795D3F`,
  1.5: `#C4BEB1`,
  2: `#D4DE8F`,
  2.5: `#87B644`,
  3: `#B9CBD6`,
  3.5: `#8999A9`,
  4: `#4F687C`,
  4.5: `#48697E`,
  5.5: `#153658`,
  21: `#4E697A`,
  22: `#B9CBD6`,
  23: `#C3BFB1`,
  24: `#EFCE46`,
  25: `#E1854A`,
  26: `#B1282B`,
};

const mapAttributeValueToDropdownOption = ({ name }: ProductAttributeValueRowDto): Option<number> => ({
  value: Number(name?.replace(`,`, `.`)),
  label: (
    <Stack direction="row" spacing={1} height={(theme) => theme.spacing(5)} alignItems="center">
      <Box sx={{ width: 24, height: 24, backgroundColor: snowWindLoadsColorsMap[Number(name?.replace(`,`, `.`))] }} />
      <Typography>{name}</Typography>
    </Stack>
  ),
});

export const mapLoadsOptions = ({
  snowLoadAttributeValues,
  windLoadAttributeValues,
}: {
  snowLoadAttributeValues: ProductAttributeValueRowDto[];
  windLoadAttributeValues: ProductAttributeValueRowDto[];
}) => ({
  snowLoadOptions: snowLoadAttributeValues.map(mapAttributeValueToDropdownOption),
  windLoadOptions: windLoadAttributeValues.map(mapAttributeValueToDropdownOption),
});
