import { ColumnItem } from '@components/columnLayout/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { FormEvent, useCallback, useEffect, useMemo } from 'react';
import { DealerRowDto, PartnerDto, UserType } from '@generatedTypes/data-contracts';
import { COMMON_CONSTRAINTS, ParsedBackendValidationResults } from '@components/controls/validations';
import { useForm } from '@hooks/useForm/useForm';
import { ComplexFormFieldType, DropdownOption, SimpleFormFieldType } from '@hooks/useForm/useFormTypes';
import { useSelector } from 'react-redux';
import { SelectWrapper } from '@components/controls/select-wrapper';
import { PartnerDealerIds } from '@services/api/dealers/dealers';
import { selectUserType } from '@redux/reducers/slices/user';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { FormSection } from '@components/forms/MuiFormSection';

type EditPartnerProps = {
  goBackHandler: () => void;
  deletePartnerDealer: (partnerDealerIds: PartnerDealerIds) => void;
  updatePartnerDealer: (partnerDealerIds: PartnerDealerIds) => void;
  dealerRows?: DealerRowDto[];
  partner: PartnerDto | null;
  beValidationResults: ParsedBackendValidationResults | null;
};

const DELETE_DEALER_OPTION_VALUE = -100;

export const EditClientInformation: ColumnItem<EditPartnerProps> = ({
  partner,
  goBackHandler,
  deletePartnerDealer,
  updatePartnerDealer,
  beValidationResults,
  dealerRows,
}) => {
  const {
    translate,
    translations: {
      users: { details },
    },
  } = useTranslations();

  const userType = useSelector(selectUserType);

  const noneSelection = useMemo(
    () => ({
      label: translate(details.clientInformation.noneDealerOption),
      value: DELETE_DEALER_OPTION_VALUE,
    }),
    [details.clientInformation.noneDealerOption, translate],
  );

  const dealersDropdownValues: DropdownOption[] = useMemo(() => {
    const dealersOptions =
      dealerRows?.map(
        ({ id, name }) =>
          ({
            value: id,
            label: name,
          }) as DropdownOption,
      ) ?? [];
    return [noneSelection, ...dealersOptions];
  }, [dealerRows, noneSelection]);

  const { validate, resetErrorFields, setAllValues, resetToStartingValues, valuesForSubmit, formFields } = useForm(
    {
      partnerId: {
        type: SimpleFormFieldType.text,
        constraints: [COMMON_CONSTRAINTS.required],
        isDisabled: userType !== UserType.Operation,
      },
      dealerId: {
        type: ComplexFormFieldType.dropdown,
        options: dealersDropdownValues,
      },
    },
    {
      beValidationResults,
    },
  );

  useEffect(() => {
    if (partner) {
      const selectedDealerOption = dealersDropdownValues.find((dealer) => dealer.value === partner.dealer?.id);

      setAllValues({
        partnerId: partner?.id ?? ``,
        dealerId: selectedDealerOption ?? noneSelection,
      });
    }
  }, [dealersDropdownValues, partner, setAllValues, noneSelection]);

  const onCancel = useCallback(() => {
    resetToStartingValues();
    resetErrorFields();
    goBackHandler();
  }, [goBackHandler, resetErrorFields, resetToStartingValues]);

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const dealerIsDeselected = Number(valuesForSubmit.dealerId) === DELETE_DEALER_OPTION_VALUE;
      const noPreviousDealerSet = !partner?.dealer?.id;

      if (partner?.id) {
        const isValid = validate();

        if (
          !isValid ||
          !valuesForSubmit.dealerId ||
          !valuesForSubmit.partnerId ||
          (dealerIsDeselected && noPreviousDealerSet)
        ) {
          return;
        }

        if (dealerIsDeselected && partner?.dealer?.id) {
          deletePartnerDealer({
            ...valuesForSubmit,
            dealerId: String(partner.dealer.id),
            partnerId: valuesForSubmit.partnerId,
          });
          return;
        }

        updatePartnerDealer({
          ...valuesForSubmit,
          dealerId: valuesForSubmit.dealerId,
          partnerId: valuesForSubmit.partnerId,
        });
      }
    },
    [partner?.id, partner?.dealer?.id, validate, valuesForSubmit, updatePartnerDealer, deletePartnerDealer],
  );

  return (
    <FormWrapper onCancel={onCancel} onSubmit={onSubmit}>
      <FormSection title={translate(details.clientInformation.header)}>
        <SelectWrapper
          label={translate(details.clientInformation.associatedDealerHeader)}
          {...formFields.dealerId.inputFields}
        />
      </FormSection>
    </FormWrapper>
  );
};
