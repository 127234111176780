import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

type UseHandleSolarEnergyDataChangeState = {
  currentRoofMaterialId: number | null;
  currentMountingMaterialId: number | null;
  currentAttachmentId: number | null;
  currentPanelOrientations: number[];
  currentRailOrientations: number[];
  panelOrientationsString: string;
  railOrientationsString: string;
  previousRoofMaterialId: number | undefined;
  previousAttachmentId: number | undefined | null;
  initialOptionsLoaded: boolean;
  optionsForNewSelectionLoadingState: `LOADING` | `LOADED` | `FINISHED`;
  lastSuccessfullyLoadedRoofId: number | null;
  previousSelectedRoofId: number | null;
};

export const initialState: UseHandleSolarEnergyDataChangeState = {
  currentRoofMaterialId: null,
  currentMountingMaterialId: null,
  currentAttachmentId: null,
  currentPanelOrientations: [],
  currentRailOrientations: [],
  panelOrientationsString: ``,
  railOrientationsString: ``,
  previousRoofMaterialId: undefined,
  previousAttachmentId: undefined,
  initialOptionsLoaded: false,
  optionsForNewSelectionLoadingState: `LOADED`,
  lastSuccessfullyLoadedRoofId: null,
  previousSelectedRoofId: null,
};

const UseHandleSolarEnergyDataChangeSlice = createSlice({
  name: `USE_HANDLE_SOLAR_ENERGY_DATA_CHANGE`,
  initialState,
  reducers: {
    setCurrentRoofMaterialId: (state, action: PayloadAction<number | null>) => {
      state.currentRoofMaterialId = action.payload;
    },
    setCurrentMountingMaterialId: (state, action: PayloadAction<number | null>) => {
      state.currentMountingMaterialId = action.payload;
    },
    setCurrentAttachmentId: (state, action: PayloadAction<number | null>) => {
      state.currentAttachmentId = action.payload;
    },
    setCurrentPanelOrientations: (state, action: PayloadAction<number[]>) => {
      state.currentPanelOrientations = action.payload;
    },
    setCurrentRailOrientations: (state, action: PayloadAction<number[]>) => {
      state.currentRailOrientations = action.payload;
    },
    setPanelOrientationsString: (state, action: PayloadAction<string>) => {
      state.panelOrientationsString = action.payload;
    },
    setRailOrientationsString: (state, action: PayloadAction<string>) => {
      state.railOrientationsString = action.payload;
    },
    setPreviousRoofMaterialId: (state, action: PayloadAction<number | undefined>) => {
      state.previousRoofMaterialId = action.payload;
    },
    setPreviousAttachmentId: (state, action: PayloadAction<number | undefined | null>) => {
      state.previousAttachmentId = action.payload;
    },
    setInitialOptionsLoaded: (state, action: PayloadAction<boolean>) => {
      state.initialOptionsLoaded = action.payload;
    },
    setOptionsForNewSelectionLoadingState: (
      state,
      action: PayloadAction<UseHandleSolarEnergyDataChangeState[`optionsForNewSelectionLoadingState`]>,
    ) => {
      state.optionsForNewSelectionLoadingState = action.payload;
    },
    setLastSuccessfullyLoadedRoofId: (state, action: PayloadAction<number | null>) => {
      state.lastSuccessfullyLoadedRoofId = action.payload;
    },
    setPreviousSelectedRoofId: (state, action: PayloadAction<number | null>) => {
      state.previousSelectedRoofId = action.payload;
    },
  },
});

const { reducer: useHandleSolarEnergyDataChangeReducer } = UseHandleSolarEnergyDataChangeSlice;
const actions = UseHandleSolarEnergyDataChangeSlice.actions;

type DispatchMaterialIdOrOrientationsDataProps = {
  roofMaterialId: number | null;
  attachmentId: number | null;
  panelOrientations: number[];
  railOrientations: number[];
  mountingMaterialId: number | null;
};
export const getDispatchMaterialIdOrOrientationsData =
  (dispatch: React.Dispatch<AnyAction>) =>
  ({
    panelOrientations,
    railOrientations,
    attachmentId,
    mountingMaterialId,
    roofMaterialId,
  }: DispatchMaterialIdOrOrientationsDataProps) => {
    dispatch(actions.setCurrentRoofMaterialId(roofMaterialId));
    dispatch(actions.setCurrentAttachmentId(attachmentId));
    dispatch(actions.setCurrentPanelOrientations(panelOrientations));
    dispatch(actions.setCurrentRailOrientations(railOrientations));
    dispatch(actions.setCurrentMountingMaterialId(mountingMaterialId));
  };

export { useHandleSolarEnergyDataChangeReducer, actions };
