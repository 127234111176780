import React from 'react';
import { Marker } from '@react-google-maps/api';
import { useTheme } from '@mui/material/styles';
import { getVertexMarkerIconStyle } from '../utils/style';
import { useHover } from './utils';

type PatchVertexProps = {
  position: google.maps.LatLng;
  onMarkerLoad: (newMarker: google.maps.Marker) => void;
  onVertexClick: () => void;
  onVertexDragEnd: (e: google.maps.MapMouseEvent) => void;
  selected: boolean;
  onVertexDragStart: () => void;
};

export const PatchVertex: React.FC<PatchVertexProps> = ({
  position,
  onVertexClick,
  onMarkerLoad,
  onVertexDragEnd,
  onVertexDragStart,
  selected,
}) => {
  const [isHovered, hoverFunctions] = useHover();
  const theme = useTheme();

  return (
    <Marker
      position={position}
      draggable
      onDragEnd={onVertexDragEnd}
      icon={{
        ...getVertexMarkerIconStyle({ selected: selected || isHovered, palette: theme.palette }),
      }}
      zIndex={2}
      onLoad={onMarkerLoad}
      onClick={onVertexClick}
      onDragStart={onVertexDragStart}
      {...hoverFunctions}
    />
  );
};
