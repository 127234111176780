import SliderCore, { SliderProps as SliderPropsCore } from '@mui/material/Slider';
import { SliderSmall } from '@components/controls/react-hook-form-friendly/dumb/Slider/SliderSmall.styled';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiMinus } from '@mdi/js';

export interface SliderProps<Value extends number | [number, number]> extends Omit<SliderPropsCore, `onChange`> {
  minLabel?: string;
  maxLabel?: string;
  min: number;
  max: number;
  value: Value;
  onChange: (value: Value) => void;
  hideInputs?: boolean;
  variant?: `rexel` | `simple`;
}

export const Slider = <Value extends number | [number, number]>({
  minLabel = `Min`,
  maxLabel = `Max`,
  min,
  max,
  value,
  track = `inverted`,
  onChange,
  hideInputs = false,
  variant = `rexel`,
  ...props
}: SliderProps<Value>) => {
  const isDoubleThumb = Array.isArray(value);
  const isNormalTrack = track === `normal`;

  const isMinDisabled = !isDoubleThumb && isNormalTrack;
  const isMaxDisabled = !isDoubleThumb && !isNormalTrack;

  const isOutOfMinMaxScope = (value: number) => value > max || value < min;

  const SliderToRender = variant === `rexel` ? SliderCore : SliderSmall;

  return (
    <Stack spacing={4}>
      <SliderToRender
        {...props}
        min={min}
        max={max}
        value={value}
        track={track}
        onChange={(_, event) => onChange(event as Value)}
      />
      {!hideInputs && (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={6}>
          <TextField
            label={minLabel}
            disabled={isMinDisabled}
            value={isDoubleThumb ? value[0] : isNormalTrack ? min : value}
            sx={{ input: { backgroundColor: isMinDisabled ? `rexelEnergy.lightBrown` : `common.white` } }}
            onChange={(e) => {
              const mappedValue = Number(e.target.value);

              if (!isOutOfMinMaxScope(mappedValue)) {
                onChange(isDoubleThumb ? ([mappedValue, value[1]] as Value) : (mappedValue as Value));
              } else {
                onChange(isDoubleThumb ? ([max, value[1]] as Value) : (max as Value));
              }
            }}
          />
          <MdiIconWrapper path={mdiMinus} />
          <TextField
            label={maxLabel}
            disabled={isMaxDisabled}
            value={isDoubleThumb ? value[1] : !isNormalTrack ? max : value}
            sx={{ input: { backgroundColor: isMaxDisabled ? `rexelEnergy.lightBrown` : `common.white` } }}
            onChange={(e) => {
              const mappedValue = Number(e.target.value);

              if (!isOutOfMinMaxScope(mappedValue)) {
                onChange(isDoubleThumb ? ([value[0], mappedValue] as Value) : (mappedValue as Value));
              }
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};
