import { authFetch } from '@services/api/utils';
import { ProjectDeductionDto } from '@generatedTypes/data-contracts';
import { useSelector } from 'react-redux';
import { selectCurrentProjectDeductionId } from '@redux/selectors/lead';
import { PROJECT_DEDUCTION } from '@variables/queryKeys';
import { useCustomQuery } from '@hooks/useCustomQuery';

const fetchProjectDeductions = async (projectDeductionId: ProjectDeductionDto[`id`]) => {
  const url = `${process.env.PROTECTED_API_URL}/project-deductions/${projectDeductionId}`;
  const response = await authFetch(url, {
    method: `GET`,
    mode: `cors`,
  });
  const json = await response.json();
  if (response.ok) {
    return json as ProjectDeductionDto;
  }
  throw new Error(json.message);
};

export const useGetProjectDeduction = () => {
  const currentProjectDeductionId = useSelector(selectCurrentProjectDeductionId);
  const { data, isLoading } = useCustomQuery({
    queryFn: () => fetchProjectDeductions(currentProjectDeductionId as number),
    enabled: Boolean(currentProjectDeductionId),
    queryKey: [PROJECT_DEDUCTION, currentProjectDeductionId],
  });

  return { projectDeduction: data, isLoadingProjectDeduction: isLoading, currentProjectDeductionId };
};
