import { Card } from '@components/Card/Card';
import { RichTextPreview } from '@components/controls/rich-text-preview';
import { RichTextInputWrapper } from '@components/controls/react-hook-form-friendly/smart/richTextInputWrapper';
import { zodResolver } from '@hookform/resolvers/zod';
import { useToggle } from '@hooks/useToggle';
import { mdiCheck, mdiPencil } from '@mdi/js';
import { useGetOffer, useUpdateOffer } from '@services/api/offers/offers';
import { parseOfferForUpdate } from '@services/api/offers/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { CircularProgress } from '@mui/material';

const offerIntroCardZodSchema = z.object({
  offerIntroText: z.string(),
});

export const OfferIntroCard = () => {
  const pushDataLayer = useAnalytics();
  const {
    translate,
    translations: {
      leads: {
        offer: {
          offerIntro: { title, offerIntroLabel },
        },
      },
    },
  } = useTranslations();
  const [isEdditingOfferIntro, toggleIsEdditingOfferIntro] = useToggle();

  const { offer, isLoading } = useGetOffer();
  const { updateOfferAsync, isUpdatingOffer } = useUpdateOffer();

  const { handleSubmit, control } = useForm({
    values: {
      offerIntroText: offer?.introText ?? ``,
    },
    resolver: zodResolver(offerIntroCardZodSchema),
  });

  if (!offer && isLoading) return <CircularProgress />;
  if (!offer) return <>No offer</>;

  return (
    <Card
      disabled={isUpdatingOffer}
      title={translate(title)}
      titleAction={!isEdditingOfferIntro ? toggleIsEdditingOfferIntro : undefined}
      titleActionIcon={isEdditingOfferIntro ? mdiCheck : mdiPencil}
      wrapperComponent={isEdditingOfferIntro ? `form` : undefined}
      buttonType={isEdditingOfferIntro ? `submit` : undefined}
      onSubmit={
        isEdditingOfferIntro
          ? handleSubmit((values) => {
              pushDataLayer({ event: AnalyticEvents.offerIntroduction });
              updateOfferAsync({
                ...parseOfferForUpdate(offer),
                offerId: offer.id,
                introText: values.offerIntroText,
              }).then(toggleIsEdditingOfferIntro);
            })
          : undefined
      }
    >
      {isEdditingOfferIntro ? (
        <RichTextInputWrapper control={control} name="offerIntroText" label={translate(offerIntroLabel)} />
      ) : (
        <RichTextPreview richTextAsHtml={offer?.introText ?? ``} />
      )}
    </Card>
  );
};
