import { Card } from '@components/Card/Card';
import { UserType } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import { mdiPencil } from '@mdi/js';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import { EditUser } from './EditUser';
import { useUpdateUser } from '@services/api/users';
import { UniversalUser } from '@services/api/users/types';
import { useTranslations } from '@services/hooks/translations/useTranslations';

export interface UserDetailsInfoProps {
  user: UniversalUser | null;
}

export const UserDetailsInfo = ({ user }: UserDetailsInfoProps) => {
  const {
    translate,
    translations: {
      users: { details },
    },
  } = useTranslations();
  const [isEditUserOpen, toggleIsEditUserOpen] = useToggle();
  const updateUser = useUpdateUser();

  const userTypeToDisplay = user?.type !== UserType.None ? user?.type : ``;

  return (
    <Card
      title={translate(details.contactInformation)}
      titleAction={toggleIsEditUserOpen}
      titleActionIcon={mdiPencil}
      titleActionTestId="edit-profile"
    >
      <Stack spacing={3}>
        <b data-testid="userNameAndSurname">
          {user?.firstName} {user?.lastName}
        </b>
        <Stack spacing={1}>
          <div data-testid="userPhoneNumber">{user?.phoneNumber}</div>
          <div data-testid="userEmail">{user?.email}</div>
          <div data-testid="userType">{userTypeToDisplay}</div>
        </Stack>
      </Stack>
      <Drawer anchor="right" open={isEditUserOpen} onClose={toggleIsEditUserOpen}>
        <EditUser
          updateUser={(values) => {
            updateUser.mutate(values);
            toggleIsEditUserOpen();
          }}
          user={user}
          disableForm={updateUser.isLoading}
          closeModal={toggleIsEditUserOpen}
        />
      </Drawer>
    </Card>
  );
};
