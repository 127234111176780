import { SetStateAction } from 'react';
import { BaseFormInputs, ComplexFormFieldType, FieldValue, SimpleFormFieldType, UseFormSchema } from './useFormTypes';
import { getMinnedMaxedValue } from '@hooks/useForm/utils';

export const useOnChange =
  <Schema extends UseFormSchema>(setInputs: React.Dispatch<SetStateAction<BaseFormInputs<Schema>>>) =>
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

export const useOnNumericChange =
  <Schema extends UseFormSchema>(
    setInputs: React.Dispatch<SetStateAction<BaseFormInputs<Schema>>>,
    min?: number,
    max?: number,
  ) =>
  (newValue: number | string, name: string) => {
    setInputs((prevState) => ({ ...prevState, [name]: getMinnedMaxedValue(newValue, min, max) }));
  };

export const useOnNumberStringInputChange =
  <Schema extends UseFormSchema>(
    setInputs: React.Dispatch<React.SetStateAction<BaseFormInputs<Schema>>>,
    precision?: number,
  ) =>
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    let newValue = value.replace(`.`, `,`);
    if (newValue[0] === `,`) {
      newValue = `0${newValue}`;
    }
    if (typeof precision !== `undefined` && newValue.includes(`,`)) {
      const [whole, decimal] = newValue.split(`,`);
      newValue = precision ? `${whole},${decimal.substring(0, precision)}` : whole;
    }
    const validNumericSymbols = new RegExp(/^(-?)(\d|,|\.)*$/).test(newValue);
    setInputs((prevState) => ({
      ...prevState,
      [name]: validNumericSymbols ? newValue.replace(`.`, `,`) : prevState[name as keyof typeof prevState],
    }));
  };

export const useOnSelectChange =
  <Schema extends UseFormSchema>(
    setInputs: React.Dispatch<React.SetStateAction<BaseFormInputs<Schema>>>,
    name: keyof Schema,
  ) =>
  (newValue: FieldValue<Schema[typeof name][`type`]>) => {
    setInputs((prevState) => ({ ...prevState, [name]: newValue }));
  };

export const useOnRadioChange =
  <Schema extends UseFormSchema>(
    setInputs: React.Dispatch<React.SetStateAction<BaseFormInputs<Schema>>>,
    name: keyof Schema,
    value: FieldValue<SimpleFormFieldType>,
  ) =>
  () => {
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

export const useOnCheckboxChange =
  <Schema extends UseFormSchema>(
    setInputs: React.Dispatch<React.SetStateAction<BaseFormInputs<Schema>>>,
    name: keyof Schema,
    value: FieldValue<SimpleFormFieldType>,
  ) =>
  () => {
    setInputs((prevState) => {
      const prevVal = `${prevState[name]}`?.split(`,`).filter((val) => !!val);
      const prevContains = Array.isArray(prevVal) && prevVal.includes(String(value) as never);
      const nevVal = prevContains
        ? prevVal.filter((val: string) => val !== String(value))
        : [...prevVal, String(value)];
      return { ...prevState, [name]: nevVal.join(`,`) };
    });
  };

export type OnChangeType<Type extends SimpleFormFieldType | ComplexFormFieldType> = Type extends
  | SimpleFormFieldType.text
  | SimpleFormFieldType.numericString
  ? ReturnType<typeof useOnChange>
  : Type extends SimpleFormFieldType.numeric
    ? ReturnType<typeof useOnNumericChange>
    : Type extends ComplexFormFieldType.checkbox
      ? ReturnType<typeof useOnCheckboxChange>
      : Type extends ComplexFormFieldType.dropdown
        ? ReturnType<typeof useOnSelectChange>
        : ReturnType<typeof useOnRadioChange>;

type AdditionalGetOnChangeFunctionForTypeOptions = {
  precision?: number;
  min?: number;
  max?: number;
};

export const getOnChangeFunctionForType = <Schema extends UseFormSchema>(
  formType: Schema[string][`type`],
  setInputs: React.Dispatch<React.SetStateAction<BaseFormInputs<Schema>>>,
  name: keyof Schema,
  value: FieldValue<SimpleFormFieldType>,
  options?: AdditionalGetOnChangeFunctionForTypeOptions,
): OnChangeType<Schema[string][`type`]> => {
  let returnOnCallback;
  switch (formType) {
    case SimpleFormFieldType.text:
      returnOnCallback = useOnChange(setInputs);
      break;
    case SimpleFormFieldType.numericString:
      returnOnCallback = useOnNumberStringInputChange(setInputs, options?.precision);
      break;
    case SimpleFormFieldType.numeric:
      returnOnCallback = useOnNumericChange(setInputs, options?.min, options?.max);
      break;
    case ComplexFormFieldType.checkbox:
      returnOnCallback = useOnCheckboxChange(setInputs, name, value);
      break;
    case ComplexFormFieldType.dropdown:
      returnOnCallback = useOnSelectChange(setInputs, name);
      break;
    case ComplexFormFieldType.radio:
      returnOnCallback = useOnRadioChange(setInputs, name, value);
      break;
  }
  return returnOnCallback as OnChangeType<Schema[string][`type`]>;
};
