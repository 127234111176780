import { selectIsLoggedIn } from '@redux/reducers/slices/user';
import { useQuery, UseQueryOptions, QueryKey } from 'react-query';
import { useSelector } from 'react-redux';

export interface UseCustomQueryOptions {
  isLoginRequired?: boolean;
}

export const useCustomQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>({
  isLoginRequired = true,
  enabled,
  ...restOptions
}: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & UseCustomQueryOptions) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return useQuery({ ...restOptions, enabled: (isLoginRequired ? isLoggedIn : true) && enabled });
};
