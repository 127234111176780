import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { SliderProps } from '@components/controls/react-hook-form-friendly/dumb/Slider';
import { Slider as SliderDumb } from '@components/controls/react-hook-form-friendly/dumb/Slider';

type SmartSliderProps<Value extends number | [number, number], F extends FieldValues> = Omit<
  SliderProps<Value>,
  `value` | `onChange`
> & {
  control: Control<F>;
  name: Path<F>;
};

export const Slider = <Value extends number | [number, number], F extends FieldValues>({
  control,
  name,
  ...dumbProps
}: SmartSliderProps<Value, F>) => {
  const { field: valueField } = useController({ control, name });
  return <SliderDumb value={valueField.value} onChange={valueField.onChange} {...dumbProps} />;
};
