import { Box, Stack } from '@mui/material';
import { theme } from 'src/styles/theme/theme';

type CustomMapControlsContainerProps = {
  children: React.ReactNode;
};

export const CustomMapControlsContainer: React.FC<CustomMapControlsContainerProps> = ({ children }) => (
  <Box position="absolute" bottom={theme.spacing(5)} right={theme.spacing(3)}>
    <Stack spacing={2}>{children}</Stack>
  </Box>
);
