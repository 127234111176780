import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { DropdownOption } from '@hooks/useForm/useFormTypes';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { getDirectionChipsOptions, getSlopeChipsOptions } from '../optionsWithTranslations';
import { setDirectionChipsOptions, setSlopeChipsOptions } from '@redux/reducers/slices/solarEnergyProjectPage';

export const useSolarEnergyFormDataProvider = () => {
  const dispatch = useDispatch();

  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: { solarEnergy },
          },
        },
      },
    },
  } = useTranslations();

  const slopeChipsOptions: DropdownOption<number>[] = useMemo(
    () => getSlopeChipsOptions(translate, solarEnergy.slopeDefaults),
    [solarEnergy.slopeDefaults, translate],
  );
  const directionChipsOptions: DropdownOption<number>[] = useMemo(
    () => getDirectionChipsOptions(translate, solarEnergy.directions),
    [solarEnergy.directions, translate],
  );

  dispatch(setSlopeChipsOptions(slopeChipsOptions));
  dispatch(setDirectionChipsOptions(directionChipsOptions));
};
