import { useMemo } from 'react';
import { useTranslations } from '@services/hooks/translations/useTranslations';

const FULL_CIRCLE_ANGLE = 360;

export const useGetTranslations = (direction: number) => {
  const {
    translate,
    translations: {
      common: { geographicDirections },
      leads: {
        offer: {
          solarEnergyOffer: { tiltSuffix },
        },
      },
    },
  } = useTranslations();

  const angle = direction % FULL_CIRCLE_ANGLE;

  const foundDirection = useMemo(() => {
    const directions = [
      { from: 338, to: 23 + FULL_CIRCLE_ANGLE, wording: geographicDirections.north },
      { from: 23, to: 68, wording: geographicDirections.northEast },
      { from: 68, to: 113, wording: geographicDirections.east },
      { from: 113, to: 158, wording: geographicDirections.southEast },
      { from: 158, to: 203, wording: geographicDirections.south },
      { from: 203, to: 248, wording: geographicDirections.southWest },
      { from: 248, to: 293, wording: geographicDirections.west },
      { from: 293, to: 338, wording: geographicDirections.northWest },
    ];
    return directions.find(
      ({ from, to }) =>
        (from < angle && angle <= to) || (from < angle + FULL_CIRCLE_ANGLE && angle + FULL_CIRCLE_ANGLE <= to),
    );
    // only when angle will change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [angle]);

  if (!foundDirection) {
    throw new Error(`Something went wrong with finding direction`);
  }

  return { direction: translate(foundDirection.wording), slope: translate(tiltSuffix) };
};
