import { Card } from '@components/Card/Card';
import { DealerDto } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import { mdiPencil } from '@mdi/js';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import { EditDealer } from './EditDealer';
import { useTranslations } from '@services/hooks/translations/useTranslations';

export interface DealerTabProps {
  dealer?: DealerDto | null;
}

export const DealerTab = ({ dealer }: DealerTabProps) => {
  const {
    translate,
    translations: {
      dealer: { create },
    },
  } = useTranslations();

  const [showEditPanel, toggleShowEditPanel] = useToggle();

  if (!dealer) {
    return <div>No dealer</div>;
  }

  return (
    <>
      <Card
        key="dealer-tab"
        title={translate(create.header)}
        titleAction={toggleShowEditPanel}
        titleActionIcon={mdiPencil}
        titleActionTestId="dealer-edit-button"
      >
        <Stack spacing={3}>
          <b>{dealer?.name}</b>
          <b>{translate(create.streeAdress)}</b>
          <div>
            {dealer?.address?.zipCode || ``} {dealer?.address?.city || ``}
          </div>
          <b>{translate(create.section3)}</b>
          <div>{dealer?.email}</div>
          <div>{dealer?.phoneNumber}</div>
        </Stack>
      </Card>
      <Drawer open={showEditPanel} onClose={toggleShowEditPanel} anchor="right">
        <EditDealer dealer={dealer} onClose={toggleShowEditPanel} />
      </Drawer>
    </>
  );
};
