import { authFetch } from '@services/api/utils';
import { ProjectAdditionDto } from '@generatedTypes/data-contracts';
import { useSelector } from 'react-redux';
import { selectCurrentProjectAdditionId } from '@redux/selectors/lead';
import { PROJECT_ADDITION } from '@variables/queryKeys';
import { useCustomQuery } from '@hooks/useCustomQuery';

const fetchProjectAdditions = async (projectAdditionId: ProjectAdditionDto[`id`]) => {
  const url = `${process.env.PROTECTED_API_URL}/project-additions/${projectAdditionId}`;
  const response = await authFetch(url, {
    method: `GET`,
    mode: `cors`,
  });
  const json = await response.json();
  if (response.ok) {
    return json as ProjectAdditionDto;
  }
  throw new Error(json.message);
};

export const useGetProjectAddition = () => {
  const currentProjectAdditionId = useSelector(selectCurrentProjectAdditionId);
  const { data, isLoading } = useCustomQuery({
    queryFn: () => fetchProjectAdditions(currentProjectAdditionId as number),
    enabled: Boolean(currentProjectAdditionId),
    queryKey: [PROJECT_ADDITION, currentProjectAdditionId],
  });

  return { projectAddition: data, isLoadingProjectAddition: isLoading, currentProjectAdditionId };
};
