import { useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { theme } from 'src/styles/theme/theme';

export interface PageHeaderProps {
  title: string | JSX.Element;
  actions?: JSX.Element | JSX.Element[];
  isLoading?: boolean;
}

export const PageHeader: FC<PageHeaderProps> = ({ title, actions, isLoading }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down(`md`));
  return (
    <Stack spacing={1} marginBlockStart={0.8} sx={{ [theme.breakpoints.down(`md`)]: { marginBlockStart: 2 } }}>
      <Typography variant="h1">
        {isLoading ? <Skeleton variant="text" width={isMobile ? `100%` : 400} /> : title}
      </Typography>
      {actions && (
        <Stack direction="row" spacing={2} style={{ marginInlineStart: -6 }}>
          {actions}
        </Stack>
      )}
    </Stack>
  );
};
