import { authFetch, BackendValidationResult, parseObjectToURLSearchParams } from '@services/api/utils';
import { SOLAR_ENERGY_PROJECT_MOUNTING_MATERIAL_MANUFACTURERS } from '@variables/queryKeys';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useGetLead } from '@services/api/leads/lead-info';

export type SolarEnergyProjectMountingMaterialManufacturersProps = {
  roofMaterialId: number;
  attachmentId: number;
  solarPanelOrientationId: number[];
  railOrientationId: number[];
  snowLoad: number;
};

export const fetchSolarEnergyProjectMountingMaterialManufacturers = async (
  props: SolarEnergyProjectMountingMaterialManufacturersProps,
): Promise<number[]> => {
  const url =
    `${process.env.PROTECTED_API_URL}/solar-energy-projects/mounting-material-manufacturers?` +
    parseObjectToURLSearchParams(props);
  const response = await authFetch(url, {
    method: `GET`,
    mode: `cors`,
  });
  const json = await response.json();
  if (response.ok) {
    return json as number[];
  }
  return [];
};

export const useFetchSolarEnergyProjectMountingMaterialManufacturers = () => {
  const leadData = useGetLead();
  const snowLoad = leadData.lead?.address?.snowLoad ?? 0;
  const { mutate, error, data, isLoading } = useCustomMutation<
    number[],
    BackendValidationResult,
    Omit<SolarEnergyProjectMountingMaterialManufacturersProps, `snowLoad`>
  >({
    mutationKey: SOLAR_ENERGY_PROJECT_MOUNTING_MATERIAL_MANUFACTURERS,
    mutationFn: (props: Omit<SolarEnergyProjectMountingMaterialManufacturersProps, `snowLoad`>) =>
      fetchSolarEnergyProjectMountingMaterialManufacturers({ ...props, snowLoad }),
  });

  return {
    fetchSolarEnergyProjectMountingMaterialManufacturers: mutate,
    solarEnergyProjectMountingMaterialManufacturers: data ?? [],
    isLoadingSolarEnergyProjectMountingMaterialManufacturers: isLoading,
    solarEnergyProjectMountingMaterialManufacturersError: error,
  };
};
