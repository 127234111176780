import { useCallback, useContext, useEffect, useState } from 'react';
import {
  SolarEnergyProjectPatchProductionProps,
  useFetchSolarEnergyProjectPatchProduction,
} from '@services/api/solarEnergyProjects/solarEnergyProjectPatchProduction';
import { useSelectedRoofAndPatch } from '../roofVisualisation/utils/accessors';
import { isFetchSolarEnergyProjectPatchProductionProps, UseUpdateProductionProps } from './utils';
import { EnqueuePatchContext } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/useSavePatchQueue';
import { useFormContext } from 'react-hook-form';
import { SolarEnergyProjectValues } from '../SolarEnergyProject';
import { parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';
import { useDispatch } from 'react-redux';
import { setIsProductionLoading } from '@redux/reducers/slices/solarEnergyProjectPage';

export const useUpdateProduction = ({ position }: UseUpdateProductionProps) => {
  const formFunctions = useFormContext<SolarEnergyProjectValues>();
  const dispatch = useDispatch();
  const enqueuePatch = useContext(EnqueuePatchContext);
  const { selectedPatch, selectedRoofIndex, selectedPatchIndex, selectedRoof } = useSelectedRoofAndPatch();
  const [previousAbortController, setPreviousAbortController] = useState<AbortController | null>(null);
  const [solarPanelManufacturerId, angle] = formFunctions?.watch?.([
    `roofs.${selectedRoofIndex}.solarPanelManufacturerId`,
    `roofs.${selectedRoofIndex}.patches.${selectedPatchIndex}.angle`,
  ]) ?? [null, null];
  const numberOfSolarPanels =
    selectedPatch?.panels?.filter(({ active, pointOnParent }) => active && pointOnParent).length ?? 0;

  const { post: fetchSolarEnergyProjectPatchProduction, isLoading } = useFetchSolarEnergyProjectPatchProduction({
    onSuccess: (production, props) => {
      if (
        selectedPatch &&
        props.numberOfSolarPanels === numberOfSolarPanels &&
        props.angle === Number(parseInputValueToSubmitNumberValue(angle)) &&
        props.solarPanelManufacturerId === solarPanelManufacturerId
      ) {
        enqueuePatch({
          ...selectedPatch,
          solarEnergyProduction: production,
        });
      }
    },
  });

  const updateProduction = useCallback(() => {
    const fetchProps = {
      angle: Number(parseInputValueToSubmitNumberValue(angle)),
      latitude: position.lat,
      longitude: position.lng,
      numberOfSolarPanels,
      solarPanelManufacturerId,
      roofId: !isNaN(Number(selectedRoof?.id)) ? selectedRoof?.id : undefined,
    } as SolarEnergyProjectPatchProductionProps;
    if (isFetchSolarEnergyProjectPatchProductionProps(fetchProps)) {
      previousAbortController?.abort();
      setPreviousAbortController(fetchSolarEnergyProjectPatchProduction(fetchProps));
    }
    //skip fetchSolarEnergyProjectPatchProduction in dependency array to avoid infinite loop
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [angle, numberOfSolarPanels, position.lat, position.lng, solarPanelManufacturerId]);

  useEffect(() => {
    updateProduction();
  }, [updateProduction]);

  useEffect(() => {
    dispatch(setIsProductionLoading(isLoading));
  }, [dispatch, isLoading]);
};
