import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const Wrapper = styled(`div`)`
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;
export const CenteredCircularProgress = () => (
  <Wrapper>
    <CircularProgress sx={{ mx: `auto`, display: `inline-block` }} />
  </Wrapper>
);
