import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import { Outlet, useLocation } from 'react-router-dom';

export interface TabProps {
  href: string;
  label: string;
}

export interface LinkTabsProps {
  tabs: TabProps[];
  ariaLabel?: string;
}

export const LinkTabs = ({ tabs, ariaLabel }: LinkTabsProps) => {
  const location = useLocation();
  const theme = useTheme();
  return (
    <>
      <Box mb={2}>
        <Tabs
          value={tabs.findIndex(({ href }) => href.includes(location.pathname))}
          aria-label={ariaLabel}
          sx={{ '.MuiTabs-indicator': { backgroundColor: theme.palette.primary.dark } }}
        >
          {tabs.map(({ href, label }) => (
            <Tab key={href} href={href} label={label} data-testid={`tab-${label}`} />
          ))}
        </Tabs>
      </Box>
      <div className="tab-panel">
        <Outlet />
      </div>
    </>
  );
};
