import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPartnerId } from '@redux/reducers/slices/user';
import { selectIsPartnerUser } from '@redux/selectors/user';
import { useQueryClient } from 'react-query';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { DEDUCTION_TEMPLATES, PARTNER } from '@variables/queryKeys';
import { postDeductionTemplate } from '@services/api/deductionTemplates/deductionTempaltes';

export const useCreateDeductionTemplate = (options?: { onSuccess?: () => void }) => {
  const { partnerId: partnerIdParams } = useParams<{ partnerId: string }>();
  const partnerId = useSelector(selectPartnerId);
  const isPartnerUser = useSelector(selectIsPartnerUser);

  const selectedPartnerId = isPartnerUser ? partnerId : Number(partnerIdParams);

  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors, parsedBackendError } = useCustomMutation({
    mutationKey: [DEDUCTION_TEMPLATES],
    mutationFn: postDeductionTemplate,
    onSuccess: (createdDeductionTemplateId) => {
      queryClient.invalidateQueries([PARTNER, DEDUCTION_TEMPLATES, selectedPartnerId]);
      queryClient.invalidateQueries([DEDUCTION_TEMPLATES, createdDeductionTemplateId]);
      options?.onSuccess?.();
    },
  });

  return {
    isAddingDeductionTemplate: isLoading,
    addDeductionTemplate: mutate,
    addDeductionTemplateAsync: mutateAsync,
    addDeductionTemplateValidationErrors: validationErrors,
    addDeductionTemplateValidationErrorsParsedBackendError: parsedBackendError,
  };
};
