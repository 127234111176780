import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import * as S from './ColumnLayout.styled';

export interface ColumnLayoutProps extends S.ContentStyleProps {
  header: ReactNode;
  children: ReactNode;
  mobileTopHeader?: ReactNode;
  mobileScrollHeader?: ReactNode;
}

export const ColumnLayoutContent = ({
  header,
  children,
  isOpen,
  mobileTopHeader,
  mobileScrollHeader,
}: ColumnLayoutProps) => {
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const observingElement = useRef<HTMLDivElement>(null);
  const [isVisibleMobileScrollHeader, setIsVisibleMobileScrollHeader] = useState(false);
  const observer = useMemo(() => {
    return new IntersectionObserver(
      (entries) => {
        setIsVisibleMobileScrollHeader(!entries[0].isIntersecting);
      },
      { root: scrollAreaRef.current },
    );
  }, [setIsVisibleMobileScrollHeader]);
  useEffect(() => {
    const intersectingElement = observingElement.current;
    if (intersectingElement) {
      observer.observe(intersectingElement);
    }
    return () => {
      if (intersectingElement) {
        observer.unobserve(intersectingElement);
      }
    };
  }, [observer]);

  return (
    <>
      <S.ColumnLayoutMobileScrollHeaderWrapper visible={isVisibleMobileScrollHeader}>
        {mobileScrollHeader}
      </S.ColumnLayoutMobileScrollHeaderWrapper>
      <S.Content isOpen={isOpen} ref={scrollAreaRef}>
        <S.ColumnLayoutTopMobileBar ref={observingElement}>{mobileTopHeader}</S.ColumnLayoutTopMobileBar>
        <S.Header>{header}</S.Header>
        {children}
      </S.Content>
    </>
  );
};
