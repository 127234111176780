import './radio.css';

export type RadioProps = {
  value: string;
  label: React.ReactNode;
  checked: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  name: string;
  classesForContainer?: string;
  isDisabled?: boolean;
  bigRadio?: boolean;
};

export const Radio: React.FC<RadioProps> = ({
  name,
  value,
  label,
  checked,
  onChange,
  classesForContainer = ``,
  isDisabled,
}) => (
  <div className={`radio-wrapper gap-small ${classesForContainer}`}>
    <label htmlFor={`${name}-${value}`}>{label}</label>
    <div className="input-wrapper">
      <input
        type="radio"
        name={name}
        id={`${name}-${value}`}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={isDisabled}
      />
      <span />
    </div>
  </div>
);
