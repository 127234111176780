import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { AdditionValuesType } from './AddEditAdditionForm';
import { DEFAULT_NET_PRICE, DEFAULT_NUMBERS_OF_WORK_HOURS, DEFAULT_QUANTITY_COUNT } from './consts';
import { getFormattedPriceWithStaticFraction, parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';
import { Stack, Typography } from '@mui/material';

type TotalAdditionsCostProps = {
  initialValues: AdditionValuesType;
  watch: UseFormWatch<AdditionValuesType>;
};

const calculateCosts = (values: AdditionValuesType) => {
  const costSum =
    (Number(parseInputValueToSubmitNumberValue(values.netPrice)) || DEFAULT_NET_PRICE) *
    (values.quantity || DEFAULT_QUANTITY_COUNT);
  const workSum = Number(values.hourPrice) * (values.numberOfWorkHours || DEFAULT_NUMBERS_OF_WORK_HOURS);
  const totalCost = costSum + workSum;
  return {
    costSum,
    totalCost,
    workSum,
  };
};

export const TotalAdditionsCost = ({ watch, initialValues }: TotalAdditionsCostProps) => {
  const {
    translate,
    translations: {
      editProduct: { additions },
    },
  } = useTranslations();
  const [costs, setCosts] = useState(calculateCosts(initialValues));

  useEffect(() => {
    const subscription = watch((value) => {
      setCosts(calculateCosts(value as AdditionValuesType));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Stack spacing={2}>
      <Typography variant="h3">{translate(additions.total)}</Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography>{translate(additions.sumOfCosts)}</Typography>
        <Typography>{getFormattedPriceWithStaticFraction(costs.costSum)}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography>{translate(additions.sumOfWork)}</Typography>
        <Typography>{getFormattedPriceWithStaticFraction(costs.workSum)}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">{translate(additions.sumTotal)}</Typography>
        <Typography variant="h4">{getFormattedPriceWithStaticFraction(costs.totalCost)}</Typography>
      </Stack>
    </Stack>
  );
};
