import './horizontal-line.css';

type HorizontalLineProps = {
  classes?: string;
};

export const HorizontalLine: React.FC<HorizontalLineProps> = (props) => {
  const cssClasses = props?.classes && props?.classes?.length > 0 ? ` ${props.classes}` : ``;
  return <hr className={`horizontal-line fw${cssClasses}`} />;
};
