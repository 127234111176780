import { ProjectDto, ProjectType } from '@generatedTypes/data-contracts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import CarChargingImage from '@assets/svg/ProjectCarCharging.svg';
import SolarEnergyImage from '@assets/svg/Solar-projection.svg';
import EmptyProjectImage from '@assets/svg/New project.svg';
import { useGetLeadProject } from '@services/api/leads/lead-info';
import { useQueryClient } from 'react-query';
import { OFFER_PRICE_OVERVIEW } from '@variables/queryKeys';

export const useProjectHeader = (projectType: ProjectType, projectName: string) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: { projectNames },
        },
      },
    },
  } = useTranslations();

  return useMemo(() => {
    switch (projectType) {
      case ProjectType.CarCharging:
        return translate(projectNames.carCharging);
      case ProjectType.SolarEnergy:
        return translate(projectNames.solarEnergy);
      case ProjectType.Empty:
        return projectName;
      case ProjectType.EnergyStorage:
        return translate(projectNames.energyStorage);
      default:
        return translate(projectNames.none);
    }
  }, [
    projectType,
    translate,
    projectNames.carCharging,
    projectNames.solarEnergy,
    projectNames.energyStorage,
    projectNames.none,
    projectName,
  ]);
};

export const useProjectImage = (projectType: ProjectType) =>
  useMemo(() => {
    switch (projectType) {
      case ProjectType.CarCharging:
        return CarChargingImage;
      case ProjectType.SolarEnergy:
        return SolarEnergyImage;
      case ProjectType.Empty:
        return EmptyProjectImage;
      case ProjectType.EnergyStorage:
        return EmptyProjectImage;
      default:
        return undefined;
    }
  }, [projectType]);

export const useRefetchUnloadedProject = (projectId: number, isInitialSavingInProgress: boolean) => {
  const [isSavingInProgress, setIsSavingInProgress] = useState(isInitialSavingInProgress);
  const [time, setTime] = useState(500);
  const queryClient = useQueryClient();

  const onSuccess = useCallback((project: ProjectDto | null) => {
    if (project?.isSavingInProgress) {
      setTime((prev) => prev * 2);
      setIsSavingInProgress(true);
    } else {
      setIsSavingInProgress(false);
    }
  }, []);

  const { fetchLeadProject } = useGetLeadProject({
    onSuccess,
  });

  useEffect(() => {
    setIsSavingInProgress(isInitialSavingInProgress);
  }, [isInitialSavingInProgress]);

  useEffect(() => {
    if (!projectId || !isSavingInProgress) {
      queryClient.invalidateQueries([OFFER_PRICE_OVERVIEW]);
      return;
    }
    const timeout = setTimeout(() => {
      fetchLeadProject(projectId);
    }, time);
    return () => clearTimeout(timeout);
  }, [projectId, isSavingInProgress, time, fetchLeadProject, queryClient]);
};
