import { useTranslations } from '@services/hooks/translations/useTranslations';

export interface SentSubtitleProps {
  name?: string | null;
  surname?: string | null;
  email?: string | null;
}

export const SendSubtitle = ({ name, surname, email }: SentSubtitleProps) => {
  const {
    translate,
    translations: {
      common: { missingEmail, missingName },
    },
  } = useTranslations();
  return (
    <>
      {!name && !surname ? translate(missingName) : `${name ? name + ` ` : ``}${surname ? surname : ``}`}
      <br /> {email ? email : translate(missingEmail)}
    </>
  );
};
