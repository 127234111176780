import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { Checkbox as CheckBoxDumb, CheckBoxProps as CheckBoxDumbProps } from '../dumb/Checkbox/Checkbox';
import Stack from '@mui/material/Stack';

export interface CheckboxListProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  options: Omit<CheckBoxDumbProps, `name` | `onChange` | `checked` | `isLabelOnRight`>[];
  wrapElements?: boolean;
  label?: React.ReactNode;
  isDisabled?: boolean;
  isLabelOnRight?: boolean;
  direction?: `row` | `column`;
  reversedSelection?: boolean;
}

export const CheckboxList = <T extends FieldValues>({
  control,
  name,
  options,
  wrapElements = false,
  label,
  isDisabled = false,
  isLabelOnRight = false,
  direction = `column`,
  reversedSelection,
}: CheckboxListProps<T>) => {
  const { field } = useController({ control, name });

  return (
    <Stack spacing={0}>
      {label && <label>{label}</label>}
      <Stack spacing={2} direction={direction} flexWrap={wrapElements ? `wrap` : `nowrap`}>
        {options.map((option) => (
          <CheckBoxDumb
            key={`${name}-${option.value}`}
            name={name}
            checked={reversedSelection ? !field.value.includes(option.value) : field.value.includes(option.value)}
            value={option.value}
            onChange={() => {
              if (field.value.includes(option.value)) {
                field.onChange(field.value.filter((val: string | number) => val !== option.value));
              } else {
                field.onChange([...field.value, option.value]);
              }
            }}
            label={option.label}
            isDisabled={isDisabled || option.isDisabled}
            isLabelOnRight={isLabelOnRight}
          />
        ))}
      </Stack>
    </Stack>
  );
};
