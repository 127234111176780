import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '@redux/reducers';
import { createSelector } from 'reselect';

export interface PartnerState {
  selectedPartnerId?: number;
  selectedPartnerAdditionTemplateId?: number;
  selectedDeductionTemplateId?: number;
}

const initialState: PartnerState = {};

const partnerSlice = createSlice({
  name: `PARTNER`,
  initialState,
  reducers: {
    setSelectedPartnerId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedPartnerId = action.payload;
    },
    setSelectedPartnerAdditionTemplateId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedPartnerAdditionTemplateId = action.payload;
    },
    setSelectedDeductionTemplateId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedDeductionTemplateId = action.payload;
    },
  },
});

export const { setSelectedPartnerId, setSelectedPartnerAdditionTemplateId, setSelectedDeductionTemplateId } =
  partnerSlice.actions;
export const { reducer: partnerReducer } = partnerSlice;

export const selectPartnerState = (state: AppState) => state.Partner;
export const selectSelectedPartnerId = createSelector(selectPartnerState, (partner) => partner.selectedPartnerId);

export const selectSelectedPartnerAdditionTemplateId = createSelector(
  selectPartnerState,
  (partner) => partner.selectedPartnerAdditionTemplateId,
);

export const selectSelectedDeductionTemplateId = createSelector(
  selectPartnerState,
  (partner) => partner.selectedDeductionTemplateId,
);
