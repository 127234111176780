import { DealerForm } from './DealerForm';
import { DealerDto } from '@generatedTypes/data-contracts';
import { useUpdateDealer } from '@services/api/dealers';

interface EditDealerProps {
  dealer?: DealerDto | null;
  onClose: () => void;
}

export const EditDealer = ({ dealer, onClose }: EditDealerProps) => {
  const updateDealer = useUpdateDealer();

  return (
    <DealerForm
      dealer={dealer}
      onSubmit={(value) => {
        if (`dealerId` in value) {
          updateDealer.mutate(value);
        }
      }}
      closeModal={onClose}
      errors={updateDealer.validationErrors?.errors}
      isLoading={updateDealer.isLoading}
    />
  );
};
