import { useCallback, useEffect } from 'react';
import { ColumnItem } from '@components/columnLayout/utils';
import { RichTextInputWrapper } from '@components/controls/rich-text-input-wrapper';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { useForm } from '@hooks/useForm/useForm';
import { SimpleFormFieldType } from '@hooks/useForm/useFormTypes';
import { PutPartner } from '@services/api/partners/partners';
import { parsePartnerForUpdate } from '@services/api/partners/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { FormSection } from '@components/forms/MuiFormSection';

type EditTermsAndConditionsProps = {
  disableForm: boolean;
  goBackHandler: () => void;
  updatePartner: (partner: PutPartner) => void;
  partner: PartnerDto | null;
};

export const EditTermsAndConditions: ColumnItem<EditTermsAndConditionsProps> = ({
  disableForm,
  goBackHandler,
  partner,
  updatePartner,
}) => {
  const {
    translate,
    translations: {
      users: {
        details: {
          termsAndConditions: { header, description },
        },
      },
    },
  } = useTranslations();

  const { validate, resetErrorFields, setAllValues, resetToStartingValues, valuesForSubmit, formFields } = useForm({
    termsAndConditions: {
      type: SimpleFormFieldType.text,
    },
  });

  useEffect(() => {
    if (partner) {
      setAllValues({
        termsAndConditions: partner?.termsAndConditions ?? ``,
      });
    }
  }, [partner, setAllValues]);

  const onCancel = useCallback(() => {
    goBackHandler();
    resetErrorFields();
    resetToStartingValues();
  }, [goBackHandler, resetErrorFields, resetToStartingValues]);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const isValid = validate();

      if (!isValid) {
        return;
      }

      if (partner) {
        updatePartner({
          ...parsePartnerForUpdate(partner),
          ...valuesForSubmit,
        });
      }
    },
    [partner, updatePartner, validate, valuesForSubmit],
  );

  return (
    <FormWrapper onCancel={onCancel} onSubmit={onSubmit} disabled={disableForm}>
      <FormSection title={translate(header)}>
        <RichTextInputWrapper {...formFields.termsAndConditions.inputFields} label={translate(description)} />
        <div />
        <HorizontalLine classes="dark" />
        <div />
      </FormSection>
    </FormWrapper>
  );
};
