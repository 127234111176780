import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiTrayArrowDown } from '@mdi/js';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { FC } from 'react';
import { useWaitForPdf } from './useWaitForPdf';

export const WindCalculationButton: FC<{
  pdfReportUri?: string | null;
  lastProjectModificationDate: string;
}> = ({ pdfReportUri, lastProjectModificationDate }) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: { windLoadCalculation },
        },
      },
    },
  } = useTranslations();

  const pdfReady = useWaitForPdf(lastProjectModificationDate);

  return (
    <Box marginBlockEnd={1}>
      {pdfReportUri ? (
        <Button
          variant="text"
          size="small"
          startIcon={!pdfReady ? <CircularProgress size={24} /> : <MdiIconWrapper path={mdiTrayArrowDown} />}
          href={pdfReportUri}
          download
          sx={{ marginInlineStart: -0.8 }}
          disabled={!pdfReady}
        >
          {translate(windLoadCalculation.description)}
        </Button>
      ) : (
        <Typography variant="subtitle1">{translate(windLoadCalculation.notAvailable)}</Typography>
      )}
    </Box>
  );
};
