import { useEffect, useState } from 'react';

const BUTTON_ACTIVATION_TIME_IN_SECONDS = 10;

export const useWaitForPdf = (lastProjectModificationDate: string) => {
  const [pdfReady, setPdfReady] = useState(false);

  useEffect(() => {
    const date = new Date(lastProjectModificationDate);
    const currentDate = new Date();
    const timezoneOffset = currentDate.getTimezoneOffset();
    const difference = currentDate.getTime() - date.getTime() + timezoneOffset * 60 * 1000;
    const seconds = Math.floor(difference / 1000);
    const shouldWaitForPdf = seconds < BUTTON_ACTIVATION_TIME_IN_SECONDS;
    if (shouldWaitForPdf) {
      setPdfReady(false);
    }
    const timer = setInterval(
      () => {
        setPdfReady(true);
      },
      shouldWaitForPdf ? (BUTTON_ACTIVATION_TIME_IN_SECONDS - seconds) * 1000 : 0,
    );
    return () => clearInterval(timer);
  }, [lastProjectModificationDate]);

  return pdfReady;
};
