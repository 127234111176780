import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { mdiHomeEditOutline } from '@mdi/js';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { ChipList } from '@components/controls/react-hook-form-friendly/smart/ChipList';
import { kiloWattHourFormatter } from '@pages/NewLeads/utils';
import {
  selectAvailableMountingMaterialManufacturers,
  selectIsProductionLoading,
} from '@redux/reducers/slices/solarEnergyProjectPage';
import { TO_KILO_SI } from '@utils/math';
import { roundNumber } from '@utils/round-number';
import { Roof } from '../roofVisualisationTypes';
import { CircularProgress, List, Stack, Typography } from '@mui/material';
import { ListItem } from '@components/ListMui/ListItem';
import { mdiSelectGroup } from '@mdi/js';
import { getNumberOfSelectedPanels } from '../mapControlButtons/utils';
import { Empty } from '@components/Empty/Empty';
import PanelsEmpty from '@assets/svg/PanelsEmpty.svg';
import { theme } from 'src/styles/theme/theme';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { getValuesFromAttributeRow } from '../../utils/utils';
import {
  useHandleOpenRoofSettings,
  useHandleSelectPatch,
} from '../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/navigation';
import { TypographyWithIconTooltip } from '@components/Tooltip/TypographyWithIconTooltip';

export type RoofStatePropertiesProps = {
  roof: Roof;
  roofIndex: number;
};

export const RoofStateProperties: React.FC<RoofStatePropertiesProps> = ({ roof, roofIndex }) => {
  const {
    translate,
    translations: {
      common: {
        year,
        units: { kiloWattPeak },
      },
      leads: {
        details: {
          project: {
            productionIsCalculated,
            addEditPage: {
              solarEnergy: {
                mapVisualisation: { panelsAmount },
                manufacturer,
                mountingMaterialHeader,
                mountingMaterialNote,
                drawPanelSurface,
              },
            },
          },
        },
        offer: {
          solarEnergyOffer: { projectHeader },
        },
      },
    },
  } = useTranslations();
  const { solarPanelManufacturer } = useGetSolarEnergyProjectSettings();
  const mountingMaterialManufacturerOptions = useSelector(selectAvailableMountingMaterialManufacturers);
  const isProductionLoading = useSelector(selectIsProductionLoading);

  const { watch, control } = useFormContext();

  const [{ label: solarPanelManufacturerLabel } = { label: 0 }, solarPanelManufacturerId, roofName] = watch([
    `roofs.${roofIndex}.roofMaterialId`,
    `roofs.${roofIndex}.solarPanelManufacturerId`,
    `roofs.${roofIndex}.name`,
  ]);
  const selectedSolarPanelManufacturer = useMemo(
    () =>
      getValuesFromAttributeRow(solarPanelManufacturer).find(
        ({ id }) => Number(id) === Number(solarPanelManufacturerId),
      )?.name,
    [solarPanelManufacturerId, solarPanelManufacturer],
  );
  const { peakPower, yearlyProduction } = useMemo(() => {
    return {
      yearlyProduction: roof.patches.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.solarEnergyProduction.yearlyProductionInkWh;
      }, 0),
      peakPower: roof.patches.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.solarEnergyProduction.peakPower;
      }, 0),
    };
  }, [roof.patches]);
  const buttonDescription = [solarPanelManufacturerLabel, selectedSolarPanelManufacturer].filter(Boolean).join(`, `);
  const handleSelectPatch = useHandleSelectPatch();
  const handleOpenRoofSettings = useHandleOpenRoofSettings();

  return (
    <Stack spacing={1}>
      <Stack>
        <Typography variant="h3">{translate(projectHeader)}</Typography>
        {isProductionLoading ? (
          <Stack spacing={1} direction="row" alignItems="center">
            <CircularProgress size={24} />
            <Typography>{translate(productionIsCalculated)}</Typography>
          </Stack>
        ) : (
          <Stack direction="row">
            <Typography variant="body1" component="span" marginInlineEnd={2}>
              {kiloWattHourFormatter(yearlyProduction)} kWh/{translate(year)}
            </Typography>
            <Typography variant="body1" color={theme.palette.text.secondary} component="span">
              {`${roundNumber(peakPower / TO_KILO_SI, 1)} ${translate(kiloWattPeak)}`}
            </Typography>
          </Stack>
        )}
      </Stack>
      <List>
        <ListItem
          title={roofName}
          subtitle={buttonDescription}
          onClick={handleOpenRoofSettings}
          icon={mdiHomeEditOutline}
        />
        {roof.patches.length > 0 ? (
          roof.patches.map(({ id, panels }) => (
            <ListItem
              key={id}
              title={`${getNumberOfSelectedPanels(panels)} ${translate(panelsAmount)}`}
              onClick={handleSelectPatch(id)}
              icon={mdiSelectGroup}
            />
          ))
        ) : (
          <Empty description={translate(drawPanelSurface)} icon={PanelsEmpty} />
        )}
      </List>
      {roof.patches.length > 0 && (
        <>
          <TypographyWithIconTooltip text={translate(mountingMaterialNote)} typographyVariant="h3">
            {translate(mountingMaterialHeader)}
          </TypographyWithIconTooltip>
          <ChipList
            // TODO: hack for https://github.com/react-hook-form/react-hook-form/pull/10490. Remove after version update.
            key={`roofs.${roofIndex}.mountingMaterialManufacturerId`}
            label={translate(manufacturer)}
            chips={mountingMaterialManufacturerOptions}
            control={control}
            name={`roofs.${roofIndex}.mountingMaterialManufacturerId`}
            version="large"
            wrapChips={true}
            defaultValue={mountingMaterialManufacturerOptions.find(({ disabled }) => !disabled)?.value}
          />
        </>
      )}
    </Stack>
  );
};
