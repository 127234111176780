import { styled } from '@mui/material/styles';
import MuiCardContent from '@mui/material/CardContent';
import { ListItem, ListItemButton } from '@mui/material';

export type CardContentProps = {
  addTopPadding?: boolean;
};
export const CardContent = styled(MuiCardContent, {
  shouldForwardProp: (prop) => prop !== `addTopPadding`,
})<CardContentProps>(({ theme, addTopPadding = true }) =>
  !addTopPadding
    ? {
        paddingTop: theme.spacing(1),
      }
    : undefined,
);

export const CardListItem = styled(ListItem)(({ theme }) => ({
  height: theme.spacing(6.75),
  display: `flex`,
  flexDirection: `column`,
  margin: 0,
  padding: 0,
  width: `calc(100% + ${theme.spacing(1)})`,
  marginTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
  justifyContent: `space-between`,
  '&:after': {
    content: `""`,
    width: `calc(100% - ${theme.spacing(2)})`,
    height: 1,
    backgroundColor: theme.palette.divider,
    position: `absolute`,
    bottom: 0,
    left: 0,
  },
}));

export const CardListItemButton = styled(ListItemButton)(({ theme }) => ({
  display: `flex`,
  flexDirection: `column`,
  width: `calc(100% + ${theme.spacing(2)})`,
  alignItems: `flex-start`,
  justifyContent: `center`,
  padding: 0,
  paddingLeft: theme.spacing(1),
  marginLeft: theme.spacing(-1),
  paddingRight: 0,
}));
