import { ColumnLayout } from '@components/Layout/ColumnLayout/ColumnLayout';
import { LeftNavLinkTwoRows } from '@components/LeftNavLink/LeftNavLinkTwoRows';
import { useCreateUser, useGetUsers } from '@services/api/users';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { mdiEmailOutline } from '@mdi/js';
import { TypographyWithIcon } from '@components/TypographyWithIcon/TypographyWithIcon';
import { useToggle } from '@hooks/useToggle';
import Drawer from '@mui/material/Drawer';
import { useQueryClient } from 'react-query';
import { USERS } from '@variables/queryKeys';
import { NewUser } from './new-user';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useSnackbar } from 'notistack';

export const UsersPage = () => {
  const {
    translate,
    translations: {
      users: {
        list: { header, newUserConfirmationText },
      },
    },
  } = useTranslations();

  const queryClient = useQueryClient();
  const { userId } = useParams<{ userId: string }>();
  const { users } = useGetUsers();
  const [isNewUserOpen, toggleIsNewUserOpen] = useToggle();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const createUser = useCreateUser({
    onSuccess: (userKey: string) => {
      queryClient.invalidateQueries(USERS);
      toggleIsNewUserOpen();
      navigate(`/users/${userKey}`);
      enqueueSnackbar(`${translate(newUserConfirmationText)}`, { variant: `success` });
    },
  });

  return (
    <ColumnLayout
      onNewClick={toggleIsNewUserOpen}
      title={translate(header)}
      list={users.map(({ id, type, lastName, firstName, email }) => {
        const selected = userId?.split(`-`)[1] === String(id);
        return (
          <LeftNavLinkTwoRows
            selected={selected}
            key={id}
            title={`${firstName} ${lastName}`}
            href={`/users/${type}-${id}`}
            rightTop={type}
            leftBottom={
              <TypographyWithIcon
                color={selected ? `white` : undefined}
                iconPath={mdiEmailOutline}
                fontSize={13}
                iconSize="small"
              >
                {email}
              </TypographyWithIcon>
            }
          />
        );
      })}
    >
      <Outlet />
      <Drawer anchor="right" open={isNewUserOpen} onClose={toggleIsNewUserOpen}>
        <NewUser
          onNewUserExit={toggleIsNewUserOpen}
          createUser={createUser.mutate}
          disableForm={createUser.isLoading}
          beValidationResults={createUser.validationErrors}
          userTypeMode={`all`}
        />
      </Drawer>
    </ColumnLayout>
  );
};
