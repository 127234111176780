import { authFetch, getErrorFromFormRequest } from '../utils';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { DELETE, LEAD, LEADS } from '@variables/queryKeys';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const deleteLead = async (leadId: number) => {
  if (leadId === -1) {
    return null;
  }
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/leads/${leadId}`, {
    method: `DELETE`,
    mode: `cors`,
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

export const useDeleteLead = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const params = useParams<{ leadId: string }>();

  const { mutate } = useCustomMutation({
    mutationFn: () => deleteLead(Number(params.leadId)),
    mutationKey: [LEAD, DELETE],
    onSuccess: () => {
      navigate(`/leads`);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(LEAD);
    },
  });

  return { deleteLead: mutate };
};
