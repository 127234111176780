import { authFetch } from '@services/api/utils';

import { useQueryClient } from 'react-query';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { CREATE, DELETE, LEADS, OFFER_ATTACHMENTS, UPDATE } from '@variables/queryKeys';

type OfferAttachment = {
  id?: number;
  offerId: number;
  file: File;
  name: string;
};
export const postOfferAttachment = async (offerAttachment: OfferAttachment) => {
  const formData = new FormData();
  formData.append(`File`, offerAttachment.file);
  formData.append(`FileName`, offerAttachment.name);
  formData.append(`OfferId`, offerAttachment.offerId.toString());

  const response = await authFetch(`${process.env.PROTECTED_API_URL}/offer-attachments`, {
    method: `POST`,
    mode: `cors`,
    body: formData,
    contentType: `multipart/form-data`,
  });

  if (!response.ok) {
    throw new Error(await response.json());
  }
};

export const putOfferAttachment = async (offerAttachment: OfferAttachment) => {
  if (!offerAttachment.id) throw new Error(`OfferAttachment id is required`);
  const formData = new FormData();
  formData.append(`File`, offerAttachment.file);
  formData.append(`FileName`, offerAttachment.name);
  formData.append(`AttachmentId`, offerAttachment?.id.toString());

  const response = await authFetch(`${process.env.PROTECTED_API_URL}/offer-attachments/${offerAttachment.id}`, {
    method: `PUT`,
    mode: `cors`,
    body: formData,
    contentType: `multipart/form-data`,
  });

  if (!response.ok) {
    throw new Error(await response.json());
  }
};

export const deleteOfferAttachment = async (attachmentId: number) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/offer-attachments/${attachmentId}`, {
    method: `DELETE`,
    mode: `cors`,
  });

  if (!response.ok) {
    throw new Error(await response.json());
  }
};

export const useUploadOfferAttachment = (options?: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();
  const data = useCustomMutation({
    mutationKey: [CREATE, OFFER_ATTACHMENTS],
    mutationFn: postOfferAttachment,
    onSuccess: () => {
      queryClient.invalidateQueries(OFFER_ATTACHMENTS);
      queryClient.invalidateQueries(LEADS);
      options?.onSuccess();
    },
  });

  return {
    isLoading: data.isLoading,
    mutate: data.mutate,
    validationErrors: data.validationErrors,
    parsedBackendError: data.parsedBackendError,
  };
};

export const useUpdateOfferAttachment = (options?: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();
  const data = useCustomMutation({
    mutationKey: [UPDATE, OFFER_ATTACHMENTS],
    mutationFn: putOfferAttachment,
    onSuccess: () => {
      queryClient.invalidateQueries(OFFER_ATTACHMENTS);
      queryClient.invalidateQueries(LEADS);
      options?.onSuccess();
    },
  });

  return {
    isLoading: data.isLoading,
    mutate: data.mutate,
    validationErrors: data.validationErrors,
    parsedBackendError: data.parsedBackendError,
  };
};

export const useDeleteOfferAttachment = (options?: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();
  const data = useCustomMutation({
    mutationKey: [DELETE, OFFER_ATTACHMENTS],
    mutationFn: deleteOfferAttachment,
    onSuccess: () => {
      queryClient.invalidateQueries(OFFER_ATTACHMENTS);
      queryClient.invalidateQueries(LEADS);
      options?.onSuccess();
    },
  });

  return {
    isLoading: data.isLoading,
    mutate: data.mutate,
    validationErrors: data.validationErrors,
    parsedBackendError: data.parsedBackendError,
  };
};
