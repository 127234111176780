import { UserType } from '@generatedTypes/data-contracts';
import { AppState } from '@redux/reducers';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const ROUTES = {
  leads: `leads`,
  partners: `partners`,
  users: `users`,
  dealers: `dealers`,
  myAccount: `myAccount`,
  systemSettings: `systemSettings`,
} as const;

type Routes = (typeof ROUTES)[keyof typeof ROUTES];

const RoutesPerUserType: {
  base: {
    [key in UserType]: Routes[];
  };
  userSection: {
    [key in UserType]: Routes[];
  };
} = {
  base: {
    [UserType.Operation]: [ROUTES.leads, ROUTES.partners],
    [UserType.Partner]: [ROUTES.leads],
    [UserType.Dealer]: [ROUTES.leads, ROUTES.partners, ROUTES.dealers],
    [UserType.None]: [],
  },
  userSection: {
    [UserType.Operation]: [ROUTES.users, ROUTES.dealers, ROUTES.systemSettings, ROUTES.myAccount],
    [UserType.Partner]: [ROUTES.myAccount, ROUTES.systemSettings],
    [UserType.Dealer]: [ROUTES.myAccount],
    [UserType.None]: [],
  },
};

export type Route = {
  path: string;
  name: string;
};

export const useRoutesWithTranslations = (): {
  baseRoutes: Route[];
  userSectionRoutes: Route[];
} => {
  const {
    translate,
    translations: {
      leads: {
        list: { header: leadsHeader },
        details,
      },
      partners: {
        list: { header: partnersHeader },
      },
      users: {
        list: { header: usersHeader },
      },
      myAccount: { header: myAccountHeader },
      systemSettings: {
        list: { header: systemSettingsHeader },
      },
    },
  } = useTranslations();

  const userType = useSelector<AppState, UserType>((state) => state.User.userType);

  const allRoutes = useMemo(
    () => ({
      leads: {
        name: translate(leadsHeader),
        path: `/leads`,
      },
      partners: {
        name: translate(partnersHeader),
        path: `/partners`,
      },
      users: {
        name: translate(usersHeader),
        path: `/users`,
      },
      dealers: {
        name: translate(details.assign.dealers),
        path: `/dealers`,
      },
      myAccount: {
        name: translate(myAccountHeader),
        path: `/my-account`,
      },
      systemSettings: {
        name: translate(systemSettingsHeader),
        path: `/system-settings`,
      },
    }),
    [
      details.assign.dealers,
      leadsHeader,
      myAccountHeader,
      partnersHeader,
      systemSettingsHeader,
      translate,
      usersHeader,
    ],
  );

  const baseRoutes = useMemo(
    () =>
      RoutesPerUserType.base[userType].reduce((acc, elem: Routes) => {
        const route: Route = allRoutes[elem];
        if (route) {
          acc.push(route);
        }
        return acc;
      }, [] as Route[]),
    [allRoutes, userType],
  );

  const userSectionRoutes = useMemo(
    () =>
      RoutesPerUserType.userSection[userType].reduce((acc, elem: Routes) => {
        const route: Route = allRoutes[elem];
        if (route) {
          acc.push(route);
        }
        return acc;
      }, [] as Route[]),
    [allRoutes, userType],
  );

  return {
    baseRoutes,
    userSectionRoutes,
  };
};
