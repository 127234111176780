import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useSnackbar } from 'notistack';
import { postForgotPassword, postResetPassword } from './accounts';
import { FORGOT_PASSWORD, RESET_PASSWORD } from '@variables/queryKeys';
import { VIEW_TYPES, showNext } from '@components/columnLayout/utils';
import { useNavigate } from 'react-router-dom';
import { useToken } from '@services/hooks/authentication/useToken';

export const useForgotPassword = (onSuccess?: () => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    translate,
    translations: {
      users: {
        details: { resetPasswordLinkHasBeenSent },
      },
    },
  } = useTranslations();
  const data = useCustomMutation({
    mutationFn: postForgotPassword,
    mutationKey: [FORGOT_PASSWORD],
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      } else {
        showNext(VIEW_TYPES.INFO)();
        enqueueSnackbar(translate(resetPasswordLinkHasBeenSent), {
          preventDuplicate: true,
          variant: `success`,
          anchorOrigin: { vertical: `bottom`, horizontal: `center` },
        });
      }
    },
  });
  return { resetPassword: data.mutate, isResettingPassword: data.isLoading };
};

export const useResetPassword = () => {
  const navigate = useNavigate();
  const { setToken } = useToken();
  const data = useCustomMutation({
    mutationFn: postResetPassword,
    mutationKey: [RESET_PASSWORD],
    onSuccess: () => {
      navigate(`/`);
      setToken(null);
    },
  });
  return { resetPassword: data.mutate, isResettingPassword: data.isLoading };
};
