import { ReactNode, useEffect, useRef } from 'react';
import * as S from './ColumnLayout.styled';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { mdiMenu, mdiPlus } from '@mdi/js';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import Stack from '@mui/material/Stack';
import { Menu } from '../Menu/Menu';
import { useToggle } from '@hooks/useToggle';
import { Fab } from '@mui/material';

export interface ColumnLayoutProps {
  list: ReactNode[];
  title: string;
  titleComponents?: ReactNode;
  children: ReactNode;
  onNewClick?: () => void;
  scrollTopOnNewListElement?: boolean;
  scrollBottomOnNewListElement?: boolean;
  createButtonTestId?: string;
}

export const ColumnLayout = ({
  list,
  title,
  titleComponents,
  children,
  onNewClick,
  scrollTopOnNewListElement,
  scrollBottomOnNewListElement,
  createButtonTestId = `create-fab`,
}: ColumnLayoutProps) => {
  const [isMenuOpen, toggleIsMenuOpen] = useToggle();
  const listWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (listWrapperRef.current && scrollTopOnNewListElement) {
      listWrapperRef.current.scrollTop = 0;
    } else if (listWrapperRef.current && scrollBottomOnNewListElement) {
      listWrapperRef.current.scrollTop = listWrapperRef.current.scrollHeight;
    }
  }, [list?.length, scrollTopOnNewListElement, scrollBottomOnNewListElement]);

  return (
    <>
      <Menu isDrawerOpen={isMenuOpen} toggleIsDrawerOpen={toggleIsMenuOpen} />
      <S.StyledColumnLayout id="page-view-port">
        <S.ListColumn>
          <S.Nav>
            <Stack direction="row" alignItems="center">
              <IconButton onClick={toggleIsMenuOpen} data-testid="menu-button">
                <MdiIconWrapper color="black" path={mdiMenu} />
              </IconButton>
              <Typography fontWeight="bold" ml={2} variant="h3" component="h1">
                {title}
              </Typography>
            </Stack>
            {titleComponents}
          </S.Nav>
          <S.ListWrapper ref={listWrapperRef}>{list}</S.ListWrapper>
          {onNewClick && (
            <S.FabWrapper>
              <Fab
                onClick={onNewClick}
                color="primary"
                sx={{ boxShadow: `none` }}
                size="medium"
                data-testid={createButtonTestId}
              >
                <MdiIconWrapper path={mdiPlus} />
              </Fab>
            </S.FabWrapper>
          )}
        </S.ListColumn>
        {children}
      </S.StyledColumnLayout>
    </>
  );
};
