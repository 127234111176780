import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { PartnerDto, UserType } from '@generatedTypes/data-contracts';
import { ColumnItem } from '@components/columnLayout/utils';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { requiredEmail, requiredNumericString, requiredString } from '@variables/zod';
import { z } from 'zod';
import { ParsedBackendValidationResults } from '@components/controls/validations';
import { zodResolver } from '@hookform/resolvers/zod';
import { selectUserType } from '@redux/reducers/slices/user';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { FormSection } from '@components/forms/MuiFormSection';

const partnerDataZodObject = {
  accountId: requiredString(),
  name: requiredString(),
  organizationNumber: requiredString(),
  contactPerson: requiredString(),
  email: requiredEmail,
  phoneNumber: requiredNumericString(),
  street: requiredString(),
  zipCode: requiredString(),
  city: requiredString(),
};

const partnerDataZodSchema = z.object(partnerDataZodObject);

export type PartnerDataValues = z.infer<typeof partnerDataZodSchema>;

type PartnerFormProps = {
  goBackHandler: () => void;
  onSubmit: (partner: PartnerDataValues) => void;
  disableForm: boolean;
  partner?: PartnerDto;
  beValidationResults?: ParsedBackendValidationResults | null;
};

export const PartnerForm: ColumnItem<PartnerFormProps> = ({
  partner,
  goBackHandler,
  onSubmit,
  disableForm,
  beValidationResults,
}) => {
  const {
    translate,
    translations: {
      partners: { create },
    },
  } = useTranslations();

  const initialValues: PartnerDataValues = {
    accountId: partner?.accountId || ``,
    name: partner?.name || ``,
    organizationNumber: partner?.organizationNumber || ``,
    contactPerson: partner?.contactPerson || ``,
    email: partner?.email || ``,
    phoneNumber: partner?.phoneNumber || ``,
    street: partner?.address.street || ``,
    zipCode: partner?.address.zipCode || ``,
    city: partner?.address.city || ``,
  };

  const userType = useSelector(selectUserType);

  const { handleSubmit, reset, control, setError } = useForm({
    values: initialValues,
    resolver: zodResolver(partnerDataZodSchema),
  });

  useEffect(() => {
    if (beValidationResults) {
      Object.entries(beValidationResults.errors).forEach(([key, error]) => {
        setError(key as keyof PartnerDataValues, { message: error[0] });
      });
    }
  }, [beValidationResults, setError]);

  const onCancel = useCallback(() => {
    reset();
    goBackHandler();
  }, [goBackHandler, reset]);

  return (
    <FormWrapper onCancel={onCancel} onSubmit={handleSubmit(onSubmit)} disabled={disableForm}>
      <FormSection title={translate(create.header)}>
        <InputWrapper
          control={control}
          name="accountId"
          label={translate(create.customerId)}
          type="text"
          isDisabled={userType !== UserType.Operation}
          isRequired
        />
        <InputWrapper control={control} name="name" label={translate(create.companyName)} type="text" isRequired />
        <InputWrapper
          control={control}
          name="organizationNumber"
          label={translate(create.organizationNumber)}
          mask="999999-9999"
          type="text"
          isRequired
        />
        <InputWrapper
          control={control}
          name="contactPerson"
          label={translate(create.contactPerson)}
          type="text"
          isRequired
        />
        <InputWrapper control={control} name="street" label={translate(create.street)} type="text" isRequired />
        <div className="gap-extra-small grid-container">
          <div className="col-6">
            <InputWrapper
              control={control}
              name="zipCode"
              label={translate(create.postCode)}
              mask="999 99"
              type="text"
              isRequired
            />
          </div>
          <div className="col-6">
            <InputWrapper control={control} name="city" label={translate(create.city)} type="text" isRequired />
          </div>
        </div>
        <InputWrapper control={control} name="email" label={translate(create.email)} type="email" isRequired />
        <InputWrapper control={control} name="phoneNumber" label={translate(create.phone)} type="tel" isRequired />
      </FormSection>
    </FormWrapper>
  );
};
