import { ForgotPasswordRequest, ResetPasswordRequest } from '@generatedTypes/data-contracts';

export const postResetPassword = async ({ email, password, token }: ResetPasswordRequest) => {
  try {
    const response = await fetch(`${process.env.PROTECTED_API_URL}/accounts/reset-password`, {
      method: `POST`,
      mode: `cors`,
      headers: {
        'Content-Type': `application/json`,
        Accept: `application/json`,
      },
      body: JSON.stringify({ email, password, token }),
    });
    if (!response.ok) {
      const responseJson = await response.json();
      throw new Error(responseJson.Message);
    }
  } catch (error) {
    throw new Error(`${error}`);
  }
};

export const postForgotPassword = async ({ email }: ForgotPasswordRequest) => {
  try {
    const response = await fetch(`${process.env.PROTECTED_API_URL}/accounts/forgot-password`, {
      method: `POST`,
      mode: `cors`,
      headers: {
        'Content-Type': `application/json`,
        Accept: `application/json`,
      },
      body: JSON.stringify({ email }),
    });
    if (!response.ok) {
      const responseJson = await response.json();
      throw new Error(responseJson.Message);
    }
  } catch (error) {
    throw new Error(`${error}`);
  }
};
