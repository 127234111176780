import { ParsedBackendValidationResults } from '@components/controls/validations';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

export const useErrorHandling = (validationErrors: ParsedBackendValidationResults | null | undefined) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (validationErrors) {
      Object.keys(validationErrors.errors).forEach((errorPath) => {
        const errorsForPath = validationErrors.errors[errorPath];
        errorsForPath.forEach((error) => {
          enqueueSnackbar(`${error}`, { variant: `error` });
        });
      });
    }
  }, [validationErrors, enqueueSnackbar]);
};
