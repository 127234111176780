import {
  BidDto,
  BidRowDto,
  OfferAttachmentRowDto,
  OfferBidBasketItemsDto,
  OfferDto,
  UpdateBidRequest,
  UpdateOfferRequest,
} from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { fileDownloadActions } from '@utils/fileDownload';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { selectOfferAgreements } from '@redux/selectors/offerPage';
import {
  OFFER,
  OFFERS_BIDS,
  OFFER_BIDS,
  OFFER_PRICE_OVERVIEW,
  OFFER_ATTACHMENTS,
  OFFER_BASKET_ITEMS,
  LEADS,
} from '@variables/queryKeys';
import { useGetLead } from '../leads/lead-info';
import { isOfferLocked } from '@pages/NewLeads/Projects/solarEnergyProject/utils';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { selectCurrentOfferBidId } from '@redux/selectors/lead';
import { setCurrentOfferBidId } from '@redux/actions/lead';
import { useCustomQuery } from '@hooks/useCustomQuery';

const fetchOffer = async (offerId: number) => {
  if (offerId > 0) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/offers/${offerId}`, {
      method: `GET`,
      mode: `cors`,
    });
    if (response.status === 401) {
      return null;
    }
    const json = await response.json();
    if (response.ok) {
      return json as OfferDto;
    }
    throw new Error(json.message);
  }
  return null;
};

export type EditOfferProps = UpdateOfferRequest & {
  offerId: number;
};

export type EditOfferBids = UpdateBidRequest & {
  bidId: number;
};

const editOffer = async ({ offerId, ...data }: EditOfferProps) => {
  if (offerId > 0) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/offers/${offerId}`, {
      method: `PUT`,
      mode: `cors`,
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw getErrorFromFormRequest(response);
    }
  }
};

const getOffersBids = async (offerId: number) => {
  if (offerId > 0) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/offers/${offerId}/bids`, {
      method: `GET`,
      mode: `cors`,
    });
    const json = await response.json();
    if (response.ok) {
      return json as BidRowDto[];
    }
    throw new Error(json.message);
  }
  return null;
};

const getOfferBids = async (bidId: number) => {
  if (bidId > 0) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/offer-bids/${bidId}`, {
      method: `GET`,
      mode: `cors`,
    });
    const json = await response.json();
    if (response.ok) {
      return json as BidDto;
    }
    throw new Error(json.message);
  }
  return null;
};

const editOfferBid = async ({ bidId, ...data }: EditOfferBids) => {
  if (bidId > 0) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/offer-bids/${bidId}`, {
      method: `PUT`,
      mode: `cors`,
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw getErrorFromFormRequest(response);
    }
  }
};

export type GetOfferProductsSpreadsheetProps = {
  offerId: number;
  offerCity: string;
  offerStreet: string;
};

export const getOfferProductsSpreadsheet = async ({
  offerId,
  offerCity,
  offerStreet,
}: GetOfferProductsSpreadsheetProps) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/offers/${offerId}/offer-specification`, {
    method: `GET`,
    mode: `cors`,
  });
  const blob = await response.blob();
  if (response.ok) {
    fileDownloadActions(
      blob,
      `Materiallista_${offerCity.replace(/(\W+)/gi, `-`)}_${offerStreet.replace(/(\W+)/gi, `-`)}.xlsx`,
    );
  }
};

const getOfferAttachments = async (offerId: number) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/offers/${offerId}/attachments`, {
    method: `GET`,
    mode: `cors`,
  });
  const json = await response.json();
  if (response.ok) {
    return json as OfferAttachmentRowDto[];
  }
  throw new Error(json.message);
};

const getOfferBasketItems = async (offerId: number) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/offers/${offerId}/basket-items`, {
    method: `GET`,
    mode: `cors`,
  });
  const json = await response.json();
  if (response.ok) {
    return json as OfferBidBasketItemsDto[];
  }
  throw new Error(json.message);
};

type GetOfferBasketItemsHashProps = {
  excludedProjectProductIds: number[];
  offerId: number;
};

const getOfferBasketItemsHash = async ({ offerId, excludedProjectProductIds }: GetOfferBasketItemsHashProps) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/offers/${offerId}/basket-items-hash`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify({ excludedProjectProductIds }),
  });
  const json = await response.json();
  if (response.ok) {
    return json as string;
  }
  throw new Error(json.message);
};

export const useGetOffer = (options?: { enabled?: boolean }) => {
  const offerAgreementDetails = useSelector(selectOfferAgreements);
  const { lead, isLoadingLead } = useGetLead();

  const { data, isLoading, refetch } = useCustomQuery({
    queryFn: ({ queryKey }) => fetchOffer(queryKey[1]),
    queryKey: [OFFER, lead?.offerId || (offerAgreementDetails?.id as number)] as const,
    enabled: options?.enabled || Boolean(lead?.offerId || offerAgreementDetails?.id),
  });

  const offerLocked = isOfferLocked(data ?? null);
  return {
    offer: data === undefined ? null : data,
    isLoading: isLoading || isLoadingLead,
    refetch,
    lead,
    isOfferLocked: offerLocked,
  };
};

export const useUpdateOffer = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: editOffer,
    onSuccess: () => {
      queryClient.invalidateQueries(OFFER);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
    },
  });

  return {
    updateOffer: mutate,
    updateOfferAsync: mutateAsync,
    isUpdatingOffer: isLoading,
    updateOfferValidationErrors: validationErrors,
  };
};
export const useGetOfferBids = () => {
  const currentOfferBidId = useSelector(selectCurrentOfferBidId);
  const { data, isLoading } = useCustomQuery({
    queryFn: () => getOfferBids(currentOfferBidId),
    enabled: Boolean(currentOfferBidId),
    queryKey: [OFFER_BIDS, currentOfferBidId],
  });

  return { offerBids: data ?? null, isLoadingBids: isLoading };
};

export const useUpdateOfferBids = () => {
  const queryClient = useQueryClient();
  const currentOfferBidId = useSelector(selectCurrentOfferBidId);
  const dispatch = useDispatch();
  const { mutate, mutateAsync, isLoading } = useCustomMutation({
    mutationFn: editOfferBid,
    onSuccess: () => {
      dispatch(setCurrentOfferBidId(-1));
      queryClient.invalidateQueries([OFFER_BIDS, currentOfferBidId]);
      queryClient.invalidateQueries(OFFERS_BIDS);
      queryClient.invalidateQueries(LEADS);
    },
  });

  return { updateOfferBid: mutate, updateOfferBidAsync: mutateAsync, isUpdatingOfferBids: isLoading };
};

export const useGetOffersBids = () => {
  const { offer, isLoading: isLoadingOffer } = useGetOffer();
  const {
    data,
    refetch,
    isLoading: isLoadingOfferBids,
  } = useCustomQuery({
    queryFn: () => getOffersBids(offer?.id ?? -1),
    queryKey: [OFFERS_BIDS, offer?.id],
    enabled: Boolean(offer?.id),
  });

  return {
    offersBids: data ?? [],
    refetchOffersBids: refetch,
    isLoadingOffersBids: isLoadingOffer || isLoadingOfferBids,
  };
};

export const useGetOfferAttachments = () => {
  const { offer, isLoading } = useGetOffer();
  const { data, refetch } = useCustomQuery({
    queryFn: () => getOfferAttachments(offer?.id ?? -1),
    queryKey: [OFFER_ATTACHMENTS, offer?.id],
    enabled: Boolean(offer?.id),
  });

  return { offerAttachments: data ?? [], refetchOfferAttachments: refetch, isLoadingOfferAttachments: isLoading };
};

export const useGetOfferBasketItems = () => {
  const { offer } = useGetOffer();
  const { data, refetch, isLoading } = useCustomQuery({
    queryFn: () => getOfferBasketItems(offer?.id ?? -1),
    queryKey: [OFFER_BASKET_ITEMS, offer?.id],
    enabled: Boolean(offer?.id),
  });

  return {
    offerBasketItems: data ?? [],
    refetchOfferBasketItems: refetch,
    isLoadingOfferBasketItems: isLoading,
  };
};

export const useGetOfferBasketItemsHash = () => {
  const { offer } = useGetOffer();
  const {
    data,
    isLoading: isItemsHashLoading,
    mutate,
    mutateAsync,
  } = useCustomMutation({
    mutationFn: (excludedProjectProductIds: GetOfferBasketItemsHashProps[`excludedProjectProductIds`]) =>
      getOfferBasketItemsHash({ offerId: offer?.id ?? -1, excludedProjectProductIds }),
  });

  return {
    offerBasketItemsHash: data ?? ``,
    fetchOfferBasketItemsHash: mutate,
    fetchOfferBasketItemsHashAsync: mutateAsync,
    isLoadingOfferBasketItemsHash: isItemsHashLoading,
  };
};
