import { AppState } from '@redux/reducers';
import { createSelector } from 'reselect';

const selectDealers = (state: AppState) => state.Dealers;

export const selectDealersList = createSelector(selectDealers, (dealers) => dealers.dealersList);

export const selectSelectedDealerID = createSelector(selectDealers, (dealers) => dealers.selectedDealerID);

export const selectSelectedDealerDetails = createSelector(selectDealers, (dealers) => dealers.selectedDealerDetails);

export const selectCurrentDealerId = createSelector(
  selectSelectedDealerDetails,
  (dealerDetails) => dealerDetails?.id ?? -1,
);
