/**
 * The case with `toFixed` is that it always returns a string and gives sometimes too many decimal places
 * fe: 1.toFixed(3) will return 1.000; 2.2.toFixed(4) will return 2.2000 etc
 * this function will return type number and it will not add extra 0 at the end
 * @param number number that you want to round
 * @param precision number of decimal places
 * @returns rounded number
 */
export const roundNumber = (number: number, decimals = 0) =>
  Math.round((number + Number.EPSILON) * 10 ** decimals) / 10 ** decimals;
