import { useController, FieldValues, Control, Path } from 'react-hook-form';
import { Radio as RadioDumb, RadioProps as RadioPropsDumb } from '@components/controls/radio';

export interface RadioProps<T extends FieldValues> extends Omit<RadioPropsDumb, `checked` | `name` | `onChange`> {
  control: Control<T>;
  name: Path<T>;
}

export const Radio = <T extends FieldValues>({ control, name, value, ...radioProps }: RadioProps<T>) => {
  const { field } = useController({ control, name });

  return (
    <RadioDumb name={name} onChange={field.onChange} checked={field.value === value} value={value} {...radioProps} />
  );
};
