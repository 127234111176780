import { Roof, SolarEnergyProject } from '../roofVisualisationTypes';
import { SolarMapVisualisationContext } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/useSolarMapVisualisation/context';
import { useContext, useMemo } from 'react';

export const getRoofIdFromPatch = (patchId: string): string => {
  const [roofId] = patchId.split(`-`);
  return roofId;
};

export const getSelectedRoofAndPatch = (roofs: Roof[]) => {
  const selectedRoofIndex = roofs.findIndex(({ selected }) => selected);
  const selectedRoof = selectedRoofIndex >= 0 ? roofs[selectedRoofIndex] : null;
  const selectedPatchIndex = selectedRoof?.patches.findIndex(({ selected }) => selected) ?? -1;
  const selectedPatch = (selectedPatchIndex >= 0 && selectedRoof?.patches[selectedPatchIndex]) || null;
  return {
    selectedRoof,
    selectedPatch,
    selectedRoofIndex,
    selectedPatchIndex,
  };
};

export const useSelectedRoofAndPatch = () => {
  const { solarEnergyProject } = useContext(SolarMapVisualisationContext);
  return useMemo(() => getSelectedRoofAndPatch(solarEnergyProject.roofs), [solarEnergyProject]);
};

export const getOverlayForMap = (map: google.maps.Map): google.maps.OverlayView => {
  const overlay = new google.maps.OverlayView();
  overlay.setMap(map);
  return overlay;
};

export const getProjectionForMap = (map: google.maps.Map): google.maps.MapCanvasProjection => {
  const overlay = getOverlayForMap(map);
  return overlay.getProjection();
};

export const getSolarEnergyProjectWithDeselectedComponents = ({
  roofs,
  ...rest
}: SolarEnergyProject): SolarEnergyProject => ({
  ...rest,
  roofs: roofs.map((roof) => ({
    ...roof,
    patches: roof.patches.map((patch) => ({
      ...patch,
      selected: false,
      vertices: patch.vertices.map((vertex) => ({
        ...vertex,
        selected: false,
      })),
    })),
    selected: false,
  })),
});
