import { OfferSettingsTemplatesHeaders } from '@assets/translations/translations';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import Drawer from '@mui/material/Drawer';
import { useUpdatePartner } from '@services/api/partners';
import { TemplateType } from '@services/api/partners/partners';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { PARTNER } from '@variables/queryKeys';
import { useQueryClient } from 'react-query';
import { OfferTemplateEdit } from './OfferTemplateEdit';
import { OfferSettingsCard } from './OfferSettingsCard';

type RichTextInputPicks = keyof Pick<
  PartnerDto,
  | `offerIntroTextTemplate`
  | `carChargingProjectOfferTextTemplate`
  | `solarEnergyProjectOfferTextTemplate`
  | `emptyProjectOfferTextTemplate`
  | `energyStorageProjectOfferTextTemplate`
>;

export interface OfferRichTextPreviewWithEditProps {
  partner: PartnerDto | null;
  templateToEdit: TemplateType;
  richTextInputPick: RichTextInputPicks;
  offerSettingsTemplateHeaders: keyof OfferSettingsTemplatesHeaders;
}

export const OfferRichTextPreviewWithEdit = ({
  partner,
  templateToEdit,
  richTextInputPick,
  offerSettingsTemplateHeaders,
}: OfferRichTextPreviewWithEditProps) => {
  const queryClient = useQueryClient();
  const [isEditOpen, toggleIsEditOpen] = useToggle();
  const {
    translate,
    translations: {
      myAccount: { offerSettings },
    },
  } = useTranslations();

  const updatePartner = useUpdatePartner({
    onSuccess: () => {
      queryClient.invalidateQueries(PARTNER);
      toggleIsEditOpen();
    },
  });

  if (!partner) return null;

  return (
    <>
      <OfferSettingsCard
        richTextAsHtml={partner?.[richTextInputPick] ?? ``}
        title={translate(offerSettings.templates.headers[offerSettingsTemplateHeaders])}
        titleAction={toggleIsEditOpen}
      />
      <Drawer open={isEditOpen} onClose={toggleIsEditOpen} anchor="right">
        <OfferTemplateEdit
          partner={partner}
          disableForm={updatePartner.isLoading}
          updatePartner={updatePartner.mutate}
          goBackHandler={toggleIsEditOpen}
          templateToEdit={templateToEdit}
          beValidationResults={updatePartner.validationErrors}
        />
      </Drawer>
    </>
  );
};
