import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { FileInput as FileInputDumb, FileInputProps as FileInputPropsDumb } from '../dumb/FileInput/FileInput';
import { useTranslations } from '@services/hooks/translations/useTranslations';

type FileInputProps<T extends FieldValues> = Omit<FileInputPropsDumb, `onChange` | `error`> & {
  control: Control<T>;
  name: Path<T>;
};

export const FileInput = <T extends FieldValues>({ control, name, ...rest }: FileInputProps<T>) => {
  const {
    translate,
    translations: { validationErrors },
  } = useTranslations();

  const {
    field,
    fieldState: { error },
  } = useController({ control, name });

  // instanceof requires any before
  // eslint-disable-next-line
  const selectedFileName = (field.value as any) instanceof File ? field.value.name : undefined;

  return (
    <FileInputDumb
      onChange={field.onChange}
      errorMessage={translate(validationErrors[error?.message as keyof typeof validationErrors]) || error?.message}
      selectedFileName={selectedFileName}
      {...rest}
    />
  );
};
