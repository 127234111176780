import { MdiIconWrapper } from '@components/MdiIconWrapper';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { mdiInformationOutline } from '@mdi/js';
import Typography from '@mui/material/Typography';
import { useToggle } from '@hooks/useToggle';
import * as S from './tooltip.styled';

export interface IconTooltipProps extends Omit<TooltipProps, `children` | `title`> {
  iconPath?: string;
  text: string;
  title?: string;
}

export const IconTooltip = ({ iconPath = mdiInformationOutline, title, text }: IconTooltipProps) => {
  const [isOpen, toggleIsOpen] = useToggle();

  return (
    <Tooltip
      PopperProps={{ modifiers: [{ name: `offset`, options: { offset: [0, -10] } }] }}
      onClose={toggleIsOpen}
      open={isOpen}
      title={
        <>
          {title && (
            <Typography variant="h3" component="p" fontSize={13}>
              {title}
            </Typography>
          )}
          <Typography fontSize={13}>{text}</Typography>
        </>
      }
    >
      <S.StyledIconButton onClick={toggleIsOpen}>
        <MdiIconWrapper path={iconPath} />
      </S.StyledIconButton>
    </Tooltip>
  );
};
