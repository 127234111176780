import './check-box.css';
import { ReactNode } from 'react';
import Icons from '@assets/svg/svgDefs.svg';

export type CheckBoxProps = {
  name: string;
  value: string;
  label: string | ReactNode;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  classesForContainer?: string;
  isDisabled?: boolean;
  isLabelOnRight?: boolean;
};

export const CheckBox: React.FC<CheckBoxProps> = ({
  name,
  value,
  label,
  checked,
  onChange,
  classesForContainer = ``,
  isDisabled = false,
  isLabelOnRight = false,
}) => {
  return (
    <div className={`checkbox-wrapper ${isLabelOnRight && `reversed-direction to-the-end`} ${classesForContainer}`}>
      <label htmlFor={`${name}-${value}`}>{label}</label>
      <div className="input-wrapper">
        <input
          type="checkbox"
          name={name}
          value={value}
          id={`${name}-${value}`}
          disabled={isDisabled}
          checked={checked}
          onChange={onChange}
        />
        <span>
          <svg className="icon">
            <use href={`${Icons}#check`} />
          </svg>
        </span>
      </div>
    </div>
  );
};
