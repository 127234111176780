import { Box } from '@mui/material';

type GridLayoutWithMarginsProps = {
  children?: React.ReactNode;
  bgColor?: `bg-brown-1` | `bg-brown-2` | `bg-brown-3` | `bg-gray` | `bg-beige` | undefined;
  gridContainerOnParent?: boolean;
  offset?: number;
  size?: number;
  paddingBlockStart?: number;
};

export const GridLayoutWithMargins: React.FC<GridLayoutWithMarginsProps> = (props) => {
  return (
    <Box className={`section bg ${props.bgColor ? props.bgColor : ``}`} paddingBlockStart={props.paddingBlockStart}>
      <div className={props.gridContainerOnParent ? `grid-container` : `grid-container w980-layout`}>
        <div className={`col-12 col-desktop-offset-${props.offset ?? 3} col-desktop-${props.size ?? 8}`}>
          {props.children}
        </div>
      </div>
    </Box>
  );
};
