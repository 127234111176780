import { useFormContext } from 'react-hook-form';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/SolarEnergyProject';
import { Translation, useTranslations } from '@services/hooks/translations/useTranslations';
import { ChipList } from '@components/controls/react-hook-form-friendly/smart/ChipList';
import { OptionWithAvailability } from '@redux/reducers/slices/solarEnergyProjectPage';
import { PatchStateProperties } from '@assets/translations/translations';
import { SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { mdiCropFree, mdiSquareOutline } from '@mdi/js';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { InputSuffixWrapper } from '@components/controls/InputSuffixWrapper';
import { MAX_EDGE_MEASUREMENT, MIN_EDGE_MEASUREMENT, useEdgeLengthsDataHandler } from './useEdgeLengthsDataHandler';
import { useHandleUpdateEdgeLength } from '../../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/edge';
import { SolarMapVisualisationContext } from '../../utils/useSolarMapVisualisation/context';

const TRIANGLE_EDGES_COUNT = 3;
export const RECTANGLE_EDGES_COUNT = 4;

function onInputChanged(setEdgeLengths: React.Dispatch<SetStateAction<number[]>>, index: number) {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setEdgeLengths((current) => {
      const newEdgeLengths = [...current];
      newEdgeLengths[index] = Number(value);
      return newEdgeLengths;
    });
  };
}

type PatchStatePropertiesProps = {
  formDisabled: boolean;
  isRectangle: boolean;
  translate: (translation: Translation | null) => string;
  translation: PatchStateProperties;
};
const getFormTypeOptions = ({
  formDisabled,
  translation,
  translate,
  isRectangle,
}: PatchStatePropertiesProps): OptionWithAvailability<string>[] => [
  {
    label: translate(translation.shapeTypeStraight),
    value: `straight`,
    icon: mdiSquareOutline,
    disabled: formDisabled || !isRectangle,
  },
  { label: translate(translation.shapeTypeFree), value: `free`, icon: mdiCropFree, disabled: formDisabled },
];

type ShapeSpecificFormProps = Omit<EdgeFormProps, `updateEdgeLength` | `edges`> & {
  edgeLengths: number[];
  getOnBlur: (edgeIndex: number) => (event: React.FocusEvent<HTMLInputElement>) => void;
};

export const TrianglePatchForm: React.FC<ShapeSpecificFormProps> = ({
  patchIndex,
  roofIndex,
  disabled = false,
  getOnBlur,
  edgeLengths,
}) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: { patchStateProperties },
            },
          },
        },
      },
    },
  } = useTranslations();
  const { widthBase, heightB, heightV } = patchStateProperties;
  const [_edgeLengths, _setEdgeLengths] = useState<number[]>(edgeLengths);

  useEffect(() => {
    _setEdgeLengths(edgeLengths);
  }, [edgeLengths]);

  return (
    <Grid container rowSpacing={1} columnSpacing={4}>
      <Grid item xs={6}>
        <InputSuffixWrapper
          name={`roofs.${roofIndex}.patches.${patchIndex}.edges.l1`}
          label={translate(widthBase)}
          value={_edgeLengths[0] || ``} // Allow empty field
          type="number"
          isDisabled={disabled}
          suffix="m"
          onBlur={getOnBlur(0)}
          onChange={onInputChanged(_setEdgeLengths, 0)}
          isRequired
          min={MIN_EDGE_MEASUREMENT}
          max={MAX_EDGE_MEASUREMENT}
        />
      </Grid>
      <Grid item xs={6}>
        <InputSuffixWrapper
          name={`roofs.${roofIndex}.patches.${patchIndex}.edges.l2`}
          label={translate(heightB)}
          value={_edgeLengths[1] || ``} // Allow empty field
          type="number"
          isDisabled={disabled}
          suffix="m"
          onBlur={getOnBlur(1)}
          onChange={onInputChanged(_setEdgeLengths, 1)}
          isRequired
          min={MIN_EDGE_MEASUREMENT}
          max={MAX_EDGE_MEASUREMENT}
        />
      </Grid>
      <Grid item xs={6}>
        <InputSuffixWrapper
          name={`roofs.${roofIndex}.patches.${patchIndex}.edges.l3`}
          label={translate(heightV)}
          value={_edgeLengths[2] || ``} // Allow empty field
          type="number"
          isDisabled={disabled}
          suffix="m"
          onBlur={getOnBlur(2)}
          onChange={onInputChanged(_setEdgeLengths, 2)}
          isRequired
          min={MIN_EDGE_MEASUREMENT}
          max={MAX_EDGE_MEASUREMENT}
        />
      </Grid>
    </Grid>
  );
};

export const RectanglePatchForm: React.FC<ShapeSpecificFormProps & { isShapeFree: boolean }> = ({
  patchIndex,
  roofIndex,
  disabled = false,
  isShapeFree,
  edgeLengths,
  getOnBlur,
}) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: { patchStateProperties },
            },
          },
        },
      },
    },
  } = useTranslations();
  const { widthBase, heightV, heightH, width } = patchStateProperties;
  const [_edgeLengths, _setEdgeLengths] = useState<number[]>(edgeLengths);

  useEffect(() => {
    _setEdgeLengths(edgeLengths);
  }, [edgeLengths]);

  return (
    <Grid container rowSpacing={1} columnSpacing={4}>
      <Grid item xs={6}>
        <InputSuffixWrapper
          name={`roofs.${roofIndex}.patches.${patchIndex}.edges.l1`}
          label={translate(widthBase)}
          value={_edgeLengths[0] || ``} // Allow empty field
          type="number"
          isDisabled={disabled}
          suffix="m"
          onBlur={getOnBlur(0)}
          onChange={onInputChanged(_setEdgeLengths, 0)}
          isRequired
          min={MIN_EDGE_MEASUREMENT}
          max={MAX_EDGE_MEASUREMENT}
        />
      </Grid>
      <Grid item xs={6}>
        {isShapeFree && (
          <InputSuffixWrapper
            name={`roofs.${roofIndex}.patches.${patchIndex}.edges.l2`}
            label={translate(heightH)}
            value={_edgeLengths[1] || ``} // Allow empty field
            type="number"
            isDisabled={disabled}
            suffix="m"
            onBlur={getOnBlur(1)}
            onChange={onInputChanged(_setEdgeLengths, 1)}
            isRequired
            min={MIN_EDGE_MEASUREMENT}
            max={MAX_EDGE_MEASUREMENT}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <InputSuffixWrapper
          name={`roofs.${roofIndex}.patches.${patchIndex}.edges.${isShapeFree ? `l3` : `l1`}`}
          label={translate(heightV)}
          value={_edgeLengths[isShapeFree ? 3 : 1] || ``} // Allow empty field
          type="number"
          isDisabled={disabled}
          suffix="m"
          onBlur={getOnBlur(isShapeFree ? 3 : 1)}
          onChange={onInputChanged(_setEdgeLengths, isShapeFree ? 3 : 1)}
          isRequired
          min={MIN_EDGE_MEASUREMENT}
          max={MAX_EDGE_MEASUREMENT}
        />
      </Grid>
      <Grid item xs={6}>
        {isShapeFree && (
          <InputSuffixWrapper
            name={`roofs.${roofIndex}.patches.${patchIndex}.edges.l4`}
            label={translate(width)}
            value={_edgeLengths[2] || ``}
            type="number"
            isDisabled={disabled}
            suffix="m"
            onBlur={getOnBlur(2)}
            onChange={onInputChanged(_setEdgeLengths, 2)}
            isRequired
            min={MIN_EDGE_MEASUREMENT}
            max={MAX_EDGE_MEASUREMENT}
          />
        )}
      </Grid>
    </Grid>
  );
};

type EdgeFormProps = {
  roofIndex: number;
  patchIndex: number;
  disabled?: boolean;
};

export const EdgeForm: React.FC<EdgeFormProps> = ({ roofIndex, patchIndex, disabled = false }) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: { patchStateProperties },
            },
          },
        },
      },
    },
  } = useTranslations();
  const { measurementHeader } = patchStateProperties;
  const { control, watch } = useFormContext<SolarEnergyProjectValues>();

  const isShapeFree = watch(`roofs.${roofIndex}.patches.${patchIndex}.shapeType`) === `free`;

  const { solarEnergyProject } = useContext(SolarMapVisualisationContext);
  const updateEdgeLength = useHandleUpdateEdgeLength();
  const edges = solarEnergyProject.roofs[roofIndex].patches[patchIndex].edges;

  const isTriangle = edges.length === TRIANGLE_EDGES_COUNT;
  const isRectangle = edges.length === RECTANGLE_EDGES_COUNT;

  const chipsOptions = useMemo(
    () => getFormTypeOptions({ formDisabled: disabled, translation: patchStateProperties, translate, isRectangle }),
    [disabled, isRectangle, patchStateProperties, translate],
  );

  const { edgeLengths, getOnBlur } = useEdgeLengthsDataHandler({ edges, updateEdgeLength });

  return (
    <Stack spacing={2}>
      <Typography variant="h3">{translate(measurementHeader)}</Typography>
      <ChipList
        key={`${roofIndex}-${patchIndex}-shapeType`}
        control={control}
        name={`roofs.${roofIndex}.patches.${patchIndex}.shapeType`}
        chips={chipsOptions}
        version="large"
        disabled={disabled}
      />
      <Box>
        {isTriangle && (
          <TrianglePatchForm
            roofIndex={roofIndex}
            patchIndex={patchIndex}
            disabled={disabled}
            getOnBlur={getOnBlur}
            edgeLengths={edgeLengths}
          />
        )}
        {isRectangle && (
          <RectanglePatchForm
            roofIndex={roofIndex}
            patchIndex={patchIndex}
            disabled={disabled}
            getOnBlur={getOnBlur}
            edgeLengths={edgeLengths}
            isShapeFree={isShapeFree}
          />
        )}
      </Box>
    </Stack>
  );
};
