import { ColumnItem } from '@components/columnLayout/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { FormSection } from '@components/forms/MuiFormSection';
import { selectIsDealerUser, selectIsPartnerUser } from '@redux/reducers/slices/user';
import { Typography } from '@mui/material';
import { Select } from '@components/controls/react-hook-form-friendly/smart/MuiSelect';
import { MuiInput } from '@components/controls/react-hook-form-friendly/smart/MuiInput';
import { useGetLead } from '@services/api/leads/lead-info';
import { useAssignPartnerAndDealerToLead } from '@services/api/leads/assignments';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { clientNumber, useAvaliableOptions } from './useAvaliableOptions';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';

type AssignEntitiesPanelProps = {
  onClose: () => void;
};

const AssignEntitiesZodObject = z.object({
  partnerId: z.number().nonnegative().or(z.literal(clientNumber)),
  partnerAccount: z.string(),
  dealerId: z.number().nonnegative(),
});

export type AssignEntitiesZodObjectSchema = z.infer<typeof AssignEntitiesZodObject>;

export const AssignEntitiesPanel: ColumnItem<AssignEntitiesPanelProps> = ({ onClose }) => {
  const {
    translate,
    translations: {
      users,
      partners: { create },
      leads: { details },
    },
  } = useTranslations();
  const pushDataLayer = useAnalytics();
  const { assignPartnerAndDealerToLeadAsync, isLoadingAssignPartnerAndDealerToLead } =
    useAssignPartnerAndDealerToLead();

  const isDealerUser = useSelector(selectIsDealerUser);
  const isPartnerUser = useSelector(selectIsPartnerUser);
  const { lead } = useGetLead();

  const { control, setValue, reset, handleSubmit } = useForm<AssignEntitiesZodObjectSchema>({
    resolver: zodResolver(AssignEntitiesZodObject),
    values: {
      partnerId: lead?.assignedPartner?.accountId ? clientNumber : lead?.assignedPartner?.id || Infinity,
      partnerAccount: lead?.assignedPartner?.accountId ?? ``,
      dealerId: lead?.assignedDealer?.id || Infinity,
    },
  });

  const { availableDealers, availablePartners, isLoadingDealers, partnerIdSelected } = useAvaliableOptions({
    setValue,
    control,
    defaultPartnerAccount: lead?.assignedPartner?.accountId,
  });

  const onCancel = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  if (isPartnerUser) {
    return null;
  }

  return (
    <FormWrapper
      onCancel={onCancel}
      onSubmit={handleSubmit(({ partnerId, dealerId, partnerAccount }) => {
        pushDataLayer({ event: AnalyticEvents.assignLead, partner: partnerIdSelected ? `partner` : `customer number` });
        lead?.id &&
          assignPartnerAndDealerToLeadAsync({
            id: lead?.id,
            partnerAccount,
            partnerId: partnerId === clientNumber ? undefined : partnerId,
            dealerId,
          }).then(onClose);
      })}
      disabled={isLoadingAssignPartnerAndDealerToLead}
    >
      <FormSection title={translate(details.assign.assignCase)}>
        <Typography variant="h2">{translate(details.assign.area)}</Typography>
        <Select
          dataTestId="assign-dealer"
          label={translate(details.assign.dealer)}
          name="dealerId"
          options={availableDealers}
          control={control}
          disabled={isPartnerUser || isDealerUser || isLoadingDealers}
        />
        <Typography variant="h2">{translate(users.create.userTypePartner)}</Typography>
        <Select
          dataTestId="assign-partner"
          label={translate(users.create.userTypePartner)}
          name="partnerId"
          options={availablePartners}
          control={control}
          disabled={isPartnerUser || availablePartners.length === 1}
        />
        <MuiInput
          data-testid="assign-partner-account"
          label={translate(create.customerId)}
          control={control}
          name="partnerAccount"
          disabled={partnerIdSelected}
          placeholder={!partnerIdSelected ? translate(details.assign.enterCustomerNumber) : undefined}
        />
      </FormSection>
    </FormWrapper>
  );
};
