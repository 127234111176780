import { useState } from 'react';
import { MutationOptions, useMutation } from 'react-query';
import {
  ParsedBackendValidationResults,
  ValidationCodes,
  isParsedValidationErrors,
  useParseBackendErrors,
} from '@components/controls/validations';
import { BackendValidationResult, VALIDATION_ERROR, VALIDATION_ERROR_CODE } from '@services/api/utils';
import { useSnackbar } from 'notistack';

export const useCustomMutation = <
  TData = unknown,
  TError extends {
    name: typeof VALIDATION_ERROR;
    errors: { code: typeof VALIDATION_ERROR_CODE; message: string; path: string; constraint: ValidationCodes }[];
  } = BackendValidationResult,
  TVariables = void,
  TContext = unknown,
>(
  options: MutationOptions<TData, TError, TVariables, TContext>,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const parseBackendErrors = useParseBackendErrors();
  const [validationErrors, setBackendErrors] = useState<ParsedBackendValidationResults | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const mutation = useMutation({
    ...options,
    onError: (err, variables, context) => {
      const parsedBackendError = parseBackendErrors(err);

      if (isParsedValidationErrors(parsedBackendError)) {
        setBackendErrors(parsedBackendError);
      } else {
        setError(parsedBackendError);
        err.errors.forEach((err) => {
          enqueueSnackbar(err.message, { variant: `error`, preventDuplicate: true });
        });
      }

      options.onError && options.onError(err, variables, context);
    },
  });

  return { ...mutation, validationErrors, parsedBackendError: error };
};
