import { Control } from 'react-hook-form';
import { SolarEnergyProjectValues } from '../../SolarEnergyProject';
import { TabButton } from '@components/button/TabButton';
import { ChipImageList } from '@components/controls/react-hook-form-friendly/smart/ChipImageList';

export interface RoofMaterialsChipsProps {
  control: Control<SolarEnergyProjectValues>;
  roofIndex: number;
  roofMaterialGroups: { name?: string | null; id: number }[];
  selectedTab: number;
  setSelectedTab: (id: number) => void;
  roofMaterials: { title: string; src: string; value: number }[];
  materialsLabel: string;
  toggleIsTabTouched: () => void;
  isTabTouched: boolean;
}

export const RoofMaterialsChips = ({
  roofMaterialGroups,
  selectedTab,
  setSelectedTab,
  roofMaterials,
  materialsLabel,
  control,
  roofIndex,
  isTabTouched,
  toggleIsTabTouched,
}: RoofMaterialsChipsProps) => (
  <>
    <div>
      {roofMaterialGroups.map(({ name, id }) => (
        <TabButton
          selected={selectedTab === id}
          key={id}
          title={name}
          id={String(id)}
          onClick={(e) => {
            !isTabTouched && toggleIsTabTouched();
            return setSelectedTab(Number(e.currentTarget.id));
          }}
          buttonType="secondary"
        />
      ))}
    </div>
    <ChipImageList
      title={materialsLabel}
      chips={roofMaterials}
      control={control}
      name={`roofs.${roofIndex}.roofMaterialId`}
    />
  </>
);
