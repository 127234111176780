import { useState, useRef, useEffect } from 'react';
import './StickyBar.css';
import { createPortal } from 'react-dom';
import { useMountTransition } from '@hooks/useMountTransition';

function isInViewport(element: HTMLDivElement | null) {
  if (!element) {
    return true;
  }
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

type StickyBarProps = {
  children: React.ReactNode;
  scrollingContainer: React.RefObject<HTMLDivElement>;
};

export const StickyBar: React.FC<StickyBarProps> = ({ children, scrollingContainer }) => {
  const [isSticky, setIsSticky] = useState(false);
  const hasTransitionedIn = useMountTransition(isSticky, 500);
  const stickyBarAnchor = useRef<HTMLDivElement>(null);
  const [currentScrollingContainer, setCurrentScrollingContainer] = useState<HTMLDivElement | null>(
    scrollingContainer?.current,
  );
  useEffect(() => {
    if (currentScrollingContainer) {
      currentScrollingContainer.removeEventListener(`scroll`, handleScroll);
    }
    if (scrollingContainer?.current) {
      scrollingContainer.current.addEventListener(`scroll`, handleScroll);
      setCurrentScrollingContainer(scrollingContainer.current);
    }
    setCurrentScrollingContainer(null);
  }, [currentScrollingContainer, scrollingContainer]);

  const handleScroll = () => {
    setIsSticky(!isInViewport(stickyBarAnchor.current));
  };

  return (
    <div className="sticky-bar__wrapper">
      <div ref={stickyBarAnchor} />

      {hasTransitionedIn || isSticky
        ? createPortal(
            <div className={`sticky-bar ${isSticky && hasTransitionedIn ? `visible` : ``}`}>
              <div className="sticky-content">{children}</div>
            </div>,
            document.getElementById(`page-view-port`) as HTMLElement,
          )
        : null}
    </div>
  );
};
