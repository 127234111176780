import { z } from 'zod';
import { zodAddress, zodLocation } from '@variables/zod';
import { LeadCustomerType } from '@generatedTypes/data-contracts';

const leadZodObject = zodAddress.merge(zodLocation).extend({
  customerType: z.nativeEnum(LeadCustomerType),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().optional(),
  phoneNumber: z.string().optional(),
  propertyDesignation: z.string().optional(),
  organizationNumber: z.string().optional(),
  organizationName: z.string().optional(),
});
export type LeadZodSchema = z.infer<typeof leadZodObject>;
export { leadZodObject };
