import { FC, ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

export interface FormSectionProps {
  children: ReactNode;
  title?: string;
  spacing?: number;
  paddingTop?: number;
  paddingBottom?: number;
}

export const FormSection: FC<FormSectionProps> = ({ children, title, paddingBottom, paddingTop, spacing }) => {
  return (
    <>
      <Stack spacing={spacing ?? 2} pt={paddingTop ?? 2} pb={paddingBottom ?? 2} component="section">
        {title && (
          <Typography variant="h3" component="h3">
            {title}
          </Typography>
        )}
        {children}
      </Stack>
      <Divider />
    </>
  );
};
