import { ProjectType } from '@generatedTypes/data-contracts';
import { CarCharginForm } from './CarChargingForm';
import { SolarEnergyForm } from './SolarEnergyForm';
import { EmptyProjectFormWrapper } from './EmptyProjectForm';
import { EnergyStorageFormWrapper } from '@pages/NewLeads/Projects/EnergyStorageForm';

export interface NewProjectProps {
  projectType: ProjectType | undefined;
  onClose: () => void;
}

export const NewProject = ({ projectType, onClose }: NewProjectProps) => {
  if (projectType === ProjectType.CarCharging) {
    return <CarCharginForm onClose={onClose} />;
  }

  if (projectType === ProjectType.SolarEnergy) {
    return <SolarEnergyForm onClose={onClose} />;
  }

  if (projectType === ProjectType.Empty) {
    return <EmptyProjectFormWrapper onClose={onClose} />;
  }

  if (projectType === ProjectType.EnergyStorage) {
    return <EnergyStorageFormWrapper onClose={onClose} />;
  }

  return null;
};
