import { Dropdown } from '@components/Dropdown/Dropdown';
import { KeyValueDisplay } from '@components/KeyValueDisplay';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { LeadOfferStatus } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import { mdiCheck } from '@mdi/js';
import { ListItemIcon, MenuItem } from '@mui/material';
import { useGetOffer, useUpdateOffer } from '@services/api/offers/offers';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { MouseEventHandler } from 'react';

const numberOfDaysBeforeExpiryOptions = [30, 25, 20, 15, 10, 5];

export const ValidityPeriod = () => {
  const {
    translate,
    translations: {
      leads,
      common: { days },
    },
  } = useTranslations();
  const { lead, offer, isOfferLocked } = useGetOffer();
  const { updateOfferAsync, isUpdatingOffer } = useUpdateOffer();
  const [isValidityPeriodOpen, toggleIsValidityPeriodOpen] = useToggle();

  const isOfferCreated = offer?.status === LeadOfferStatus.Created;

  if (!lead?.assignedPartner?.id) return null;
  if (!offer) return <div>Error fetching offer</div>;

  const handleClick: MouseEventHandler<HTMLLIElement> = (e) => {
    updateOfferAsync({
      offerId: offer.id,
      introText: offer.introText,
      offerSenderId: offer.sender?.id,
      numberOfDaysBeforeExpiry: Number(e.currentTarget.id),
    }).then(toggleIsValidityPeriodOpen);
  };

  const daysTranslation = translate(days);

  return (
    <KeyValueDisplay
      displayKey={translate(leads.offer.termsAndContact.validityPeriod)}
      value={
        <Dropdown
          disabled={isUpdatingOffer || isOfferCreated || isOfferLocked}
          isDropdownOpen={isValidityPeriodOpen}
          toggleIsDropdownOpen={toggleIsValidityPeriodOpen}
          buttonText={`${offer.numberOfDaysBeforeExpiry} ${translate(days)}`}
          styles={{ marginInlineEnd: -1 }}
        >
          {numberOfDaysBeforeExpiryOptions.map((numberOfDays) => (
            <MenuItem key={numberOfDays} id={String(numberOfDays)} onClick={handleClick}>
              <ListItemIcon sx={{ margin: 0 }}>
                {numberOfDays === offer.numberOfDaysBeforeExpiry && <MdiIconWrapper path={mdiCheck} />}
              </ListItemIcon>
              {numberOfDays} {daysTranslation}
            </MenuItem>
          ))}
        </Dropdown>
      }
    />
  );
};
