import {
  CustomValidationErrors,
  CustomValidationErrorsTypes,
  StringTranslationObject,
  StringTranslationWithParamsObject,
  ValidationErrorsTypes,
  ValidationErrorsTypesWithParams,
} from '@assets/translations/translations';
import { BackendValidationResult, isBackendValidationResult, PARSED_VALIDATION_ERROR } from '@services/api/utils';
import { Translation, TranslationWithParams, useTranslations } from '@services/hooks/translations/useTranslations';
import { ComplexFormFieldType, FieldValue, SimpleFormFieldType } from '@hooks/useForm/useFormTypes';
import { getValueFromFormValues } from '@hooks/useForm/utils';
import { useCallback } from 'react';

export enum COMMON_CONSTRAINTS {
  email = `email`,
  required = `required`,
  zipCode = `zipCode`,
  organizationNumber = `organizationNumber`,
}

type Constraint =
  | COMMON_CONSTRAINTS
  | ((
      value: string,
      data: Record<string | symbol, FieldValue<SimpleFormFieldType | ComplexFormFieldType>>,
    ) => string | null);

function isCommonConstraint(constraint: Constraint): constraint is COMMON_CONSTRAINTS {
  return typeof constraint === `string` && Object.values(COMMON_CONSTRAINTS).includes(constraint);
}

const getCommonConstraintErrorMessage = (
  constraint: Constraint,
  value: ValidateFieldValue,
  validationMessages: string[],
  translate: (translation: Translation) => string,
  validationErrors: ValidationErrorsTypes,
) => {
  switch (constraint) {
    case COMMON_CONSTRAINTS.required:
      if (value === undefined || value === null || value === ``) {
        validationMessages.push(translate(validationErrors.NOT_EMPTY));
      }
      return true;
    case COMMON_CONSTRAINTS.email:
      if (!emailValidation(value)) {
        validationMessages.push(translate(validationErrors.VALID_EMAIL_FORMAT));
      }
      return true;
    case COMMON_CONSTRAINTS.zipCode:
      if (!zipCodeValidation(value)) {
        validationMessages.push(translate(validationErrors.zipCodeIncorrect));
      }
      return true;
    case COMMON_CONSTRAINTS.organizationNumber:
      if (!OrganizationNumberValidation(value)) {
        validationMessages.push(translate(validationErrors.organizationNumber));
      }
      return true;

    default:
      return false;
  }
};

const EMAIL_VALIDATION_REGEX = new RegExp(/^[^@\s]+@[^@\s]+\.[^@\s]+$/);
const ZIP_CODE_REGEX = new RegExp(/[0-9]{3} ?[0-9]{2}/);
const ORGANIZATION_NUMBER_REGEX = new RegExp(/[0-9]{6}-[0-9]{4}/);

export function emailValidation(data: ValidateFieldValue) {
  return !data || EMAIL_VALIDATION_REGEX.test(`${data}`);
}

export function zipCodeValidation(data: ValidateFieldValue) {
  return !data || ZIP_CODE_REGEX.test(`${data}`);
}

export function OrganizationNumberValidation(data: ValidateFieldValue) {
  return !data || ORGANIZATION_NUMBER_REGEX.test(`${data}`);
}

type FormValidationFunctionResults = ReturnType<ReturnType<typeof useFormValidation>[`validate`]>;
export type FormValidationArgs = {
  [symbol: string | symbol]: {
    constraints: (
      | COMMON_CONSTRAINTS
      | ((
          value: string,
          data: Record<string | symbol, FieldValue<SimpleFormFieldType | ComplexFormFieldType>>,
        ) => string | null)
    )[];
    setter: (value: React.SetStateAction<string>) => void;
  };
};
type CheckValidationAndAssignProps = {
  errors: FormValidationFunctionResults;
  joint?: string;
};
type ValidateFieldValue = string | number | undefined | null;
export function useFormValidation(schema: FormValidationArgs): {
  validate: (data: { [key: keyof typeof schema]: FieldValue<SimpleFormFieldType | ComplexFormFieldType> }) => null | {
    [key: keyof typeof schema]: string[];
  };
  resetErrorFields: () => void;
  checkValidationAndAssign: (props: CheckValidationAndAssignProps) => boolean;
} {
  const {
    translate,
    translations: { validationErrors },
  } = useTranslations();

  const validate = useCallback(
    (data: { [key: keyof typeof schema]: FieldValue<SimpleFormFieldType | ComplexFormFieldType> }) => {
      const errors: FormValidationFunctionResults = {};
      for (const key in schema) {
        const validationMessages: string[] = [];
        const { constraints } = schema[key];
        const valueData = data[key] ? `${data[key]}` : data[key];
        const value = getValueFromFormValues(valueData);

        constraints.forEach((constraint) => {
          if (isCommonConstraint(constraint)) {
            getCommonConstraintErrorMessage(constraint, value, validationMessages, translate, validationErrors);
          } else {
            const validationResult = constraint(`${value}`, data);
            if (validationResult) {
              if (
                !getCommonConstraintErrorMessage(
                  validationResult as COMMON_CONSTRAINTS,
                  value,
                  validationMessages,
                  translate,
                  validationErrors,
                )
              ) {
                validationMessages.push(validationResult);
              }
            }
          }
        });
        if (validationMessages.length > 0) {
          errors[key] = validationMessages;
        }
      }
      return Object.keys(errors).length > 0 ? errors : null;
    },
    [schema, translate, validationErrors],
  );

  const resetErrorFields = useCallback(() => {
    Object.keys(schema).forEach((key) => {
      schema[key].setter(``);
    });
  }, [schema]);

  const checkValidationAndAssign = useCallback(
    ({ errors, joint = `, ` }: CheckValidationAndAssignProps) => {
      resetErrorFields();
      if (!errors) {
        return true;
      }
      Object.keys(errors).forEach((key) => {
        if (Object.hasOwn(schema, key)) {
          schema[key].setter(errors[key].join(joint));
        }
      });
      return false;
    },
    [resetErrorFields, schema],
  );

  return {
    validate,
    resetErrorFields,
    checkValidationAndAssign,
  };
}

export enum ValidationCodes {
  NOT_NULL = `NOT_NULL`,
  NOT_EMPTY = `NOT_EMPTY`,
  NOT_EQUAL_X = `NOT_EQUAL_X`,
  EQUAL_X = `EQUAL_X`,
  LENGTH_BETWEEN_X_AND_Y = `LENGTH_BETWEEN_X_AND_Y`,
  MIN_LENGTH_X = `MIN_LENGTH_X`,
  MAX_LENGTH_X = `MAX_LENGTH_X`,
  LESS_THAN_X = `LESS_THAN_X`,
  GREATER_THAN_X = `GREATER_THAN_X`,
  GREATER_THAN_OR_EQUAL_X = `GREATER_THAN_OR_EQUAL_X`,
  LESS_THAN_OR_EQUAL_X = `LESS_THAN_OR_EQUAL_X`,
  MUST_SATISFY_PREDICATE = `MUST_SATISFY_PREDICATE`,
  VALID_CREDIT_CARD_FORMAT = `VALID_CREDIT_CARD_FORMAT`,
  VALID_EMAIL_FORMAT = `VALID_EMAIL_FORMAT`,
  MATCH_REGULAR_EXPRESSION = `MATCH_REGULAR_EXPRESSION`,
  MUST_BE_NULL = `MUST_BE_NULL`,
  BETWEEN_X_AND_Y = `BETWEEN_X_AND_Y`,
  ERR_UserEmailAlreadyTaken = `ERR_UserEmailAlreadyTaken`,
  PARTNER_NOT_VALID_FOR_DEALER = `PARTNER_NOT_VALID_FOR_DEALER`,
  ROOF_NAMES_NOT_UNIQUE = `ROOF_NAMES_NOT_UNIQUE`,
}

type GetValidationErrorMessageProps = {
  code: ValidationCodes;
  field: string | CustomValidationErrorsTypes;
  translations: Record<keyof ValidationErrorsTypes, StringTranslationObject | StringTranslationWithParamsObject>;
  translateWithParams: (translation: TranslationWithParams) => (...props: string[]) => string;
  translate: (translation: Translation) => string;
};
const getValidationErrorMessage = ({
  code,
  field,
  translations,
  translate,
  translateWithParams,
}: GetValidationErrorMessageProps) => {
  if (Object.values(CustomValidationErrors).includes(field as keyof CustomValidationErrorsTypes)) {
    return translate(translations[field as keyof CustomValidationErrorsTypes]);
  }
  switch (code) {
    case ValidationCodes.NOT_NULL:
    case ValidationCodes.NOT_EMPTY:
    case ValidationCodes.MUST_SATISFY_PREDICATE:
    case ValidationCodes.VALID_CREDIT_CARD_FORMAT:
    case ValidationCodes.VALID_EMAIL_FORMAT:
    case ValidationCodes.MATCH_REGULAR_EXPRESSION:
    case ValidationCodes.MUST_BE_NULL:
    case ValidationCodes.ERR_UserEmailAlreadyTaken:
    case ValidationCodes.PARTNER_NOT_VALID_FOR_DEALER:
    case ValidationCodes.ROOF_NAMES_NOT_UNIQUE: {
      return translate(translations[code as keyof ValidationErrorsTypes]);
    }
    default: {
      const paramsArray = Array.from(code.matchAll(/(_[0-9]+)/g));
      const codeAndParams = paramsArray.reduce(
        (acc, paramMatch) => {
          acc.code = (acc.code || code).replace(paramMatch[0], ``) as keyof ValidationErrorsTypesWithParams;
          acc.params.push(paramMatch[0].replace(`_`, ``));
          return acc;
        },
        { code: null, params: [] } as { code: keyof ValidationErrorsTypesWithParams | null; params: string[] },
      );

      return codeAndParams.code
        ? translateWithParams(translations[codeAndParams.code] as StringTranslationWithParamsObject)(
            ...codeAndParams.params,
          )
        : ``;
    }
  }
};

export type ValidationErrors = {
  [key: string]: string[];
  [key: number]: string[];
  [key: symbol]: string[];
};
export const parseBackendValidationResults = (
  result: BackendValidationResult,
  translationParams: Omit<GetValidationErrorMessageProps, `code` | `field`>,
) =>
  result.errors.reduce((acc, error) => {
    acc = acc ? acc : {};
    const currentField = `${error.path.at(0)?.toLowerCase()}${error.path.slice(1)}`;
    const currentValidationObj = Object.hasOwn(acc, currentField) ? acc[currentField] : [];
    currentValidationObj.push(
      getValidationErrorMessage({
        code: error.constraint ?? error.code,
        field: currentField,
        ...translationParams,
      }),
    );
    acc[currentField] = currentValidationObj;
    return acc;
  }, {} as ValidationErrors);

export type ParsedBackendValidationResults = {
  name: typeof PARSED_VALIDATION_ERROR;
  errors: ValidationErrors;
};
export const useParseBackendErrors = () => {
  const {
    translate,
    translateWithParams,
    translations: { validationErrors },
  } = useTranslations();

  return (results: Error | BackendValidationResult): Error | ParsedBackendValidationResults =>
    isBackendValidationResult(results)
      ? {
          name: PARSED_VALIDATION_ERROR,
          errors: parseBackendValidationResults(results, {
            translate,
            translateWithParams,
            translations: validationErrors,
          }),
        }
      : results;
};

export function isParsedValidationErrors(
  results: Error | ParsedBackendValidationResults,
): results is ParsedBackendValidationResults {
  return results.name === PARSED_VALIDATION_ERROR;
}
