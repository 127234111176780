import { InputSuffix, InputSuffixProps } from '@components/controls/InputSuffix';
import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { InputErrorElement } from './input-error-element';
import './validated-wrapper.css';
import { Box } from '@mui/material';

export type InputSuffixWrapperProps = InputSuffixProps & {
  errorMsg?: string;
};

export const InputSuffixWrapper: React.FC<InputSuffixWrapperProps> = (props) => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(e);
  };

  return (
    <Box className={classNames(`validated-wrapper`, { error: props.errorMsg, required: props.isRequired })}>
      <InputSuffix
        type={props.type}
        name={props.name}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={onChangeHandler}
        onBlur={props.onBlur}
        isRequired={props.isRequired}
        isDisabled={props.isDisabled}
        classesForContainer="column fw"
        classesInput={`input-main ${props.classesInput}`}
        label={props.label}
        suffix={props.suffix}
        onKeyUp={props.onKeyUp}
        onFocus={props.onFocus}
        min={props.min}
        max={props.max}
      />
      <InputErrorElement errorMsg={props.errorMsg} />
    </Box>
  );
};
