import { ColumnLayout } from '@components/Layout/ColumnLayout/ColumnLayout';
import { useToggle } from '@hooks/useToggle';
import Drawer from '@mui/material/Drawer';
import { useGetDealers } from '@services/api/dealers';
import { Outlet, useParams } from 'react-router-dom';
import { NewDealer } from './NewDealer';
import { LeftNavLinkTwoRows } from '@components/LeftNavLink/LeftNavLinkTwoRows';
import { useEffect, useState } from 'react';
import { useTranslations } from '@services/hooks/translations/useTranslations';

export const DealersPage = () => {
  const {
    translate,
    translations: {
      dealer: {
        list: { header },
      },
    },
  } = useTranslations();

  const { dealerId } = useParams<{ dealerId: string }>();
  const { dealers } = useGetDealers();
  const [isNewDealerOpen, toggleIsNewDealerOpen] = useToggle();
  const [leftLink, setLeftLink] = useState<HTMLAnchorElement | null>(null);
  const [isFirstLoad, toggleIsFirstLoad] = useToggle(true);

  useEffect(() => {
    if (leftLink) {
      leftLink.scrollIntoView({ block: `center` });
      toggleIsFirstLoad();
      setLeftLink(null);
    }
    // watch only for leftLink
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftLink]);

  return (
    <ColumnLayout
      list={dealers.map(({ name, address, id }) => (
        <LeftNavLinkTwoRows
          key={id}
          title={name}
          href={`/dealers/${id}`}
          leftBottom={address.street}
          selected={String(id) === dealerId}
          ref={isFirstLoad && String(id) === dealerId ? setLeftLink : undefined}
        />
      ))}
      title={translate(header)}
      onNewClick={toggleIsNewDealerOpen}
      scrollBottomOnNewListElement
    >
      <Outlet />
      <Drawer open={isNewDealerOpen} onClose={toggleIsNewDealerOpen} anchor="right">
        <NewDealer onCancel={toggleIsNewDealerOpen} />
      </Drawer>
    </ColumnLayout>
  );
};
