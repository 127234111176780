import { UserType } from '@generatedTypes/data-contracts';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectUserType } from '@redux/reducers/slices/user';

type SystemUsers = Exclude<`${UserType}`, `None`>;

type UserTypeBaseRendererProps = {
  include?: SystemUsers[];
  exclude?: SystemUsers[];
  children: ReactNode;
};

export const UserTypeBaseRenderer: React.FC<UserTypeBaseRendererProps> = ({ include, exclude, children }) => {
  const userType = useSelector(selectUserType);
  if (include) {
    const userIncluded = new Set<SystemUsers>(include).has(userType as SystemUsers);
    return userIncluded ? children : null;
  } else if (exclude) {
    const userIncluded = new Set<SystemUsers>(exclude).has(userType as SystemUsers);
    return userIncluded ? null : children;
  }
  return null;
};
