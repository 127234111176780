import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback } from 'react';
import { ProjectAdditionDto, ProjectAdditionTemplateDto } from '@generatedTypes/data-contracts';
import { Card } from '@components/Card/Card';
import { mdiPlus } from '@mdi/js';
import { Empty } from '@components/Empty/Empty';
import emptyLeadInformation from '@assets/svg/EmptyComponentIcons/emptyLeadInformation.svg';
import { AdditionsList } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/ProjectAdditions/Cards/AdditionsList';
import { TemplatesListContentWrapper } from '../TemplatesListContentWrapper';
import { useGetOffer } from '@services/api/offers/offers';

export type AdditionTemplatesListCardProps = {
  projectAdditions: (Omit<ProjectAdditionDto, `projectId`> | ProjectAdditionTemplateDto)[];
  onShowAddEditAdditionForm?: (projectAdditionId?: ProjectAdditionDto[`id`]) => void;
};

export const AdditionTemplatesListCard: React.FC<AdditionTemplatesListCardProps> = (props) => {
  const {
    translate,
    translations: {
      myAccount: {
        economySettings: { header, projectAdditionsTemplates },
      },
    },
  } = useTranslations();

  const { isOfferLocked } = useGetOffer();

  const showSpecificAdditionPanel = useCallback(
    (projectAdditionId?: ProjectAdditionDto[`id`]) =>
      props.onShowAddEditAdditionForm ? () => props.onShowAddEditAdditionForm?.(projectAdditionId) : undefined,
    [props],
  );

  return (
    <Card
      title={translate(header)}
      titleAction={!isOfferLocked ? showSpecificAdditionPanel() : undefined}
      titleActionIcon={!isOfferLocked ? mdiPlus : ``}
    >
      {props.projectAdditions.length === 0 ? (
        <Empty description={translate(projectAdditionsTemplates.noCostsAdded)} icon={emptyLeadInformation} />
      ) : (
        <TemplatesListContentWrapper>
          <AdditionsList
            projectAdditionsTranslations={projectAdditionsTemplates}
            projectAdditions={props.projectAdditions}
            onShowAddEditAdditionForm={props.onShowAddEditAdditionForm}
          />
        </TemplatesListContentWrapper>
      )}
    </Card>
  );
};
