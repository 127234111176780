import { AriaRole, FC } from 'react';
import { ListItemButtonStyled } from '@components/ListMui/ListItem.styled';
import { ListItemContent, ListItemContentProps } from './ListItemContent';
import { SxProps } from '@mui/system';
import MuiListItem from '@mui/material/ListItem';

export interface ListItemProps extends ListItemContentProps {
  secondaryAction?: React.ReactNode;
  role?: AriaRole;
  onClick?: () => void;
  disabled?: boolean;
  selected?: boolean;
  sx?: SxProps;
  parentSx?: SxProps;
}

export const ListItem: FC<ListItemProps> = ({
  secondaryAction,
  role,
  onClick,
  disabled,
  selected,
  sx,
  parentSx,
  ...listItemContentProps
}) => (
  <MuiListItem secondaryAction={secondaryAction} role={role} sx={parentSx}>
    <ListItemButtonStyled onClick={onClick} disabled={disabled} selected={selected} interactive={!!onClick} sx={sx}>
      <ListItemContent interactiveIcon={!!onClick} {...listItemContentProps} />
    </ListItemButtonStyled>
  </MuiListItem>
);
