import {
  CreateEmptyProjectRequest,
  EmptyProjectDto,
  ProjectType,
  UpdateEmptyProjectRequest,
} from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useGetLeadProjects } from '../leads/lead-info';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { EMPTY_PROJECT, LEAD, LEADS, OFFER_PRICE_OVERVIEW, PROJECTS } from '@variables/queryKeys';
import { selectCurrentOfferBidId } from '@redux/selectors/lead';

const fetchEmptyProject = async (id: number) => {
  if (id < 0) {
    return null;
  }

  return authFetch(`${process.env.PROTECTED_API_URL}/empty-projects/${id}`, {
    method: `GET`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    })
    .then((json) => json as EmptyProjectDto)
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

const postEmptyProject = async (data: CreateEmptyProjectRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/empty-projects`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

export interface PutEmptySite extends UpdateEmptyProjectRequest {
  id: number;
}

const putEmptyProject = async ({ id, ...data }: PutEmptySite) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/empty-projects/${id}`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

const deleteEmptyProject = async (projectId: number) => {
  if (projectId === -1) {
    return null;
  }
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/empty-projects/${projectId}`, {
    method: `DELETE`,
    mode: `cors`,
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
};

export const useGetEmptyProject = () => {
  const currentProjectID = useSelector(selectCurrentOfferBidId);
  const { leadProjects } = useGetLeadProjects();
  const projectType = useMemo(
    () => leadProjects?.find((project) => project.id === currentProjectID)?.type ?? ProjectType.None,
    [currentProjectID, leadProjects],
  );
  const { data, refetch } = useQuery({
    queryFn: () => fetchEmptyProject(currentProjectID),
    queryKey: [EMPTY_PROJECT, currentProjectID],
    enabled: currentProjectID > 0 && projectType === ProjectType.Empty,
  });

  return { emptyProjectDetails: data ?? null, refetchEmptyProjectDetails: refetch };
};

export const usePostEmptyProject = () => {
  const queryClient = useQueryClient();

  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: postEmptyProject,
    onSuccess: () => {
      queryClient.invalidateQueries(EMPTY_PROJECT);
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(LEAD);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
    },
  });

  return {
    createEmptyProject: mutate,
    createEmptyProjectAsync: mutateAsync,
    isCreateEmptyProjectLoading: isLoading,
    createEmptyProjectError: validationErrors,
  };
};

export const usePutEmptyProject = () => {
  const queryClient = useQueryClient();

  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: putEmptyProject,
    onSuccess: () => {
      queryClient.invalidateQueries(EMPTY_PROJECT);
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
    },
  });

  return {
    updateEmptyProject: mutate,
    updateEmptyProjectAsync: mutateAsync,
    isUpdateEmptyProjectLoading: isLoading,
    updateEmptyProjectError: validationErrors,
  };
};
export const useDeleteEmptyProject = () => {
  const queryClient = useQueryClient();
  const currentProjectID = useSelector(selectCurrentOfferBidId);

  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: deleteEmptyProject,
    onSuccess: () => {
      queryClient.invalidateQueries([EMPTY_PROJECT, currentProjectID]);
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(LEAD);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
    },
  });

  return {
    deleteEmptyProject: mutate,
    deleteEmptyProjectAsync: mutateAsync,
    isDeleteEmptyProjectLoading: isLoading,
    deleteEmptyProjectError: validationErrors,
  };
};
