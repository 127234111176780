import {
  CreateDealerRequest,
  DealerDto,
  DealerRowDto,
  DealerUserRowDto,
  UpdateDealerRequest,
} from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { UniversalUser } from '../users/types';
import { CreateUserRequestBodyWithType } from '../users/users';

export type PartnerDealerIds = { partnerId: string; dealerId: string };

export const getDealers = async () => {
  return authFetch(`${process.env.PROTECTED_API_URL}/dealers`, {
    method: `GET`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        const json = await res.json();
        throw json;
      }
      return res.json();
    })
    .then((json) => json as DealerRowDto[])
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

export const getDealerUserDetails = async (userId: number | null) => {
  return authFetch(`${process.env.PROTECTED_API_URL}/dealer-users/${userId}`, {
    method: `GET`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        const json = await res.json();
        throw json;
      }
      return res.json();
    })
    .then((json) => json as UniversalUser)
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

export const getDealer = async (dealerId: number) => {
  if (dealerId >= 0) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/dealers/${dealerId}`, {
      method: `GET`,
      mode: `cors`,
    });
    if (response.status === 200) {
      const json = await response.json();
      return json as DealerDto;
    }
    throw getErrorFromFormRequest(response);
  }
  return null;
};

export const postDealer = async (dealer: CreateDealerRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/dealers`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(dealer),
  });
  if (!response.ok) {
    const error = await getErrorFromFormRequest(response);
    throw error;
  }

  return (await response.json()) as number;
};

export interface PutDealer extends UpdateDealerRequest {
  dealerId: number;
}

export const putDealer = async ({ dealerId, ...dealer }: PutDealer) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/dealers/${dealerId}`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify(dealer),
  });
  if (!response.ok) {
    const error = await getErrorFromFormRequest(response);
    throw error;
  }
};

export const deleteDealer = async (dealerId: number) => {
  if (dealerId < 0) {
    return;
  }

  return authFetch(`${process.env.PROTECTED_API_URL}/dealers/${dealerId}`, {
    method: `DELETE`,
    mode: `cors`,
  })
    .then(async (response) => {
      if (!response.clone().ok) {
        throw await response.clone().json();
      }
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const getDealerUsers = async (dealerId: number) => {
  if (dealerId >= 0) {
    return authFetch(`${process.env.PROTECTED_API_URL}/dealers/${dealerId}/users`, {
      method: `GET`,
      mode: `cors`,
    })
      .then(async (res) => {
        if (!res.ok) {
          const json = await res.json();
          throw json;
        }
        return res.json();
      })
      .then((json) => json as DealerUserRowDto[])
      .catch((error) => {
        throw new Error(error.errors[0].Message);
      });
  }
  return null;
};

export const putDealerUser = async (data: CreateUserRequestBodyWithType) => {
  return authFetch(`${process.env.PROTECTED_API_URL}/dealer-users`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      if (!res.ok) {
        const json = await res.json();
        throw json;
      }
    })
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

export const putDealersPartners = async ({ partnerId, dealerId }: PartnerDealerIds) => {
  return authFetch(`${process.env.PROTECTED_API_URL}/dealers/${dealerId}/partners/${partnerId}`, {
    method: `PUT`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        const json = await res.json();
        throw json;
      }
    })
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

export const deleteDealersPartners = async ({ partnerId, dealerId }: PartnerDealerIds) => {
  return authFetch(`${process.env.PROTECTED_API_URL}/dealers/${dealerId}/partners/${partnerId}`, {
    method: `DELETE`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        const json = await res.json();
        throw json;
      }
    })
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};
