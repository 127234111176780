import { AdditionPriceDto, DeductionDto } from '@generatedTypes/data-contracts';
import { Translation } from '@services/hooks/translations/useTranslations';
import { PriceOverview } from '@assets/translations/translations';
import { GridLayoutWithMargins } from '@components/gridLayout/gridLayoutWithMargins';
import { getFormattedNumber, getFormattedNumberWithStaticFraction } from '@pages/NewLeads/utils';
import React, { useEffect } from 'react';
import { AdditionalCostsCard } from './AdditionalCosts';
import { useFormContext } from 'react-hook-form';
import { OfferPriceOverviewSchemaType } from '@pages/NewLeads/offer/price/OfferPriceOverviewPage';
import { Divider, Stack, Typography } from '@mui/material';
import { Numeric } from '@components/controls/react-hook-form-friendly/smart';

type PriceOverviewProjectRowProps = {
  name: string;
  key: string | number;
  bidIndex: number;
  translate: (translation: Translation) => string;
  translation: PriceOverview;
  debouncedSubmit: () => void;
  isDisabled: boolean;
  additions: AdditionPriceDto[];
  deductions: DeductionDto[];
};

export const PriceOverviewProjectRow: React.FC<PriceOverviewProjectRowProps> = ({
  name,
  bidIndex,
  translation,
  translate,
  debouncedSubmit,
  isDisabled,
  additions,
  deductions,
}) => {
  const { control, watch } = useFormContext<OfferPriceOverviewSchemaType>();
  const [bidNetPrice, margin, calculatedMargin, totalNetPriceWithoutDeduction] = watch([
    `bids.${bidIndex}.netPrice`,
    `bids.${bidIndex}.margin`,
    `bids.${bidIndex}.calculatedMargin`,
    `bids.${bidIndex}.totalNetPriceWithoutDeduction`,
  ]);

  useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, margin]);

  return (
    <GridLayoutWithMargins>
      <Stack gap={4}>
        <Typography variant="h2">{name}</Typography>
        <Stack>
          <div className="row space-between price-row">
            <Typography variant="h3">{translate(translation.materialHeader)}</Typography>
          </div>
          <div className="row space-between price-row">
            <Typography>{translate(translation.cost)}</Typography>
            <Typography>{getFormattedNumberWithStaticFraction(bidNetPrice ?? 0, 2)}</Typography>
          </div>
          <Stack ml={2} direction="row" justifyContent="space-between" className="price-row price-row--number">
            <Typography>{translate(translation.materialMargin)}</Typography>
            <div className="row space-between align-center hw">
              <div>
                <Numeric
                  control={control}
                  name={`bids.${bidIndex}.margin`}
                  label=""
                  suffix="%"
                  classesForContainer="no-background"
                  precision={2}
                  isDisabled={isDisabled}
                />
              </div>
              <Typography>{getFormattedNumber(calculatedMargin)}</Typography>
            </div>
          </Stack>
          {additions?.length ? <AdditionalCostsCard additionPriceFromBid={additions} /> : null}
        </Stack>
        <Stack>
          <div className="price-row">
            <Typography variant="h4">{translate(translation.projectSummaryHeader)}</Typography>
          </div>
          <div className="row space-between price-row">
            <Typography>{translate(translation.projectSummaryBaseCost)}</Typography>
            <Typography>{getFormattedNumberWithStaticFraction(totalNetPriceWithoutDeduction, 2)}</Typography>
          </div>
          {deductions.map((deduction) => (
            <div key={`${deduction.name}-${deduction.value}`} className="row space-between price-row">
              <Typography>{deduction.name}</Typography>
              <Typography>{`- ${getFormattedNumberWithStaticFraction(deduction.netDeduction, 2)}`}</Typography>
            </div>
          ))}
        </Stack>

        <Divider />
      </Stack>
    </GridLayoutWithMargins>
  );
};
