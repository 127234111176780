import { CarChargingProjectDto, ProjectType } from '@generatedTypes/data-contracts';
import { authFetch } from '../utils';
import { CAR_CHARGING_PROJECT } from '@variables/queryKeys';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useGetLeadProjects } from './lead-info';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { selectCurrentOfferBidId } from '@redux/selectors/lead';

const fetchCarChargingSitesForLead = async (id: number) => {
  if (id < 0) {
    return null;
  }

  return authFetch(`${process.env.PROTECTED_API_URL}/car-charging-projects/${id}`, {
    method: `GET`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        const json = await res.json();
        throw json;
      }
      return res.json();
    })
    .then((json) => json as CarChargingProjectDto)
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

export const useGetCarChargingSitesForLead = () => {
  const currentProjectID = useSelector(selectCurrentOfferBidId);
  const { leadProjects } = useGetLeadProjects();

  const projectType = useMemo(
    () => leadProjects?.find((project) => project.id === currentProjectID)?.type ?? ProjectType.None,
    [currentProjectID, leadProjects],
  );

  const { data, isLoading, refetch } = useCustomQuery({
    queryFn: () => fetchCarChargingSitesForLead(currentProjectID),
    queryKey: [CAR_CHARGING_PROJECT, currentProjectID],
    enabled: Boolean(currentProjectID) && projectType === ProjectType.CarCharging,
  });

  return { carChargingSites: data ?? null, isLoading, refetch };
};
