import { Patch } from '../../../roofVisualisationTypes';
import { getCalculationAreaForPatch, getCalculationAreaSize } from './utils';
import { resolverFunctions } from './resolverFunctions';
import { defaultPanelsResolverResult, PanelsResolverResult } from '../panelsResolver';

export const panelsResolverV10 = (map: google.maps.Map, patch: Patch): PanelsResolverResult => {
  const selectedEdge = patch.edges.findIndex((edge) => edge.selected);
  const isCompletePolygon = patch.edges.length >= 3;
  const colsAndRows = { col: 0, row: 0 };

  if (!isCompletePolygon || !patch.polygon || selectedEdge < 0 || !patch.panelOrientation) {
    return defaultPanelsResolverResult;
  }
  const calculationArea = getCalculationAreaForPatch(map, patch);
  if (!calculationArea) {
    return defaultPanelsResolverResult;
  }

  const {
    projectionLoaded,
    movePanelInRowToNextPosition,
    movePanelInColumnToNextPosition,
    isPanelInCalculationArea,
    isNextRowInCalculationArea,
    isPanelInOriginPolygon,
    pushCurrentPanel,
    getNewPanels,
  } = resolverFunctions({
    map,
    edges: patch.edges,
    polygon: patch.polygon,
    currentPanels: patch.panels,
    calculationArea,
    panelOrientation: patch.panelOrientation,
  });
  if (!projectionLoaded) {
    calculationArea.setMap(null);
    return { ...defaultPanelsResolverResult, panels: patch.panels };
  }
  let canPlaceNextInRow = true;
  let canPlaceNextInColumn = true;
  do {
    colsAndRows.col = 0;
    do {
      movePanelInRowToNextPosition();
      if (!isPanelInCalculationArea()) {
        canPlaceNextInRow = false;
      } else {
        pushCurrentPanel(isPanelInOriginPolygon(), { ...colsAndRows });
        colsAndRows.col++;
      }
    } while (canPlaceNextInRow);
    movePanelInColumnToNextPosition();
    if (isNextRowInCalculationArea()) {
      canPlaceNextInRow = true;
      colsAndRows.row++;
    } else {
      canPlaceNextInColumn = false;
    }
  } while (canPlaceNextInColumn);

  calculationArea.setMap(null);
  return {
    panels: getNewPanels(),
    ...getCalculationAreaSize(calculationArea),
    columns: colsAndRows.col,
    rows: colsAndRows.row + 1,
  };
};
