import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { background } from 'src/styles/theme/theme';

export interface SlidingContentWrapper {
  visible: boolean;
  height: number;
}

export interface CardProps {
  backgroundColor?: keyof typeof background;
}
export const Card = styled(MuiCard)<CardProps>`
  position: relative;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.palette.background[backgroundColor] : theme.palette.background.paper};
`;

export const SlidingContentWrapper = styled(`div`, {
  shouldForwardProp: (prop) => ![`visible`, `backgroundColor`].includes(prop as string),
})<SlidingContentWrapper>(({ theme, visible, height }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  position: `absolute`,
  height,
  transform: `translateY(${visible ? 0 : `${height}px`})`,
  backgroundColor: theme.palette.background.default,
  width: `100%`,
  padding: theme.spacing(3),
  transition: `transform 0.3s ease-in-out`,
  bottom: 0,
}));

export const DimOverlay = styled(`div`)(({ theme }) => ({
  position: `absolute`,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.background.default,
  opacity: 0.5,
}));
