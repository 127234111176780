import { Compass } from '@components/controls/compass';
import { ChipList } from '@components/controls/react-hook-form-friendly/smart/ChipList';
import { Stack, Typography } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SolarEnergyProjectValues } from '../../SolarEnergyProject';
import { PropertiesFormButtons } from './PropertiesFormButtons/PropertiesFormButtons';
import { InputSuffixWrapperSmart } from '@components/controls/react-hook-form-friendly/smart';
import { useSelector } from 'react-redux';
import { selectSlopeChipsOptions } from '@redux/reducers/slices/solarEnergyProjectPage';
import { useHandleRemovePatch } from '../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/patch';
import { MuiNumberInput } from '@components/controls/react-hook-form-friendly/smart/MuiNumberInput';
import InputAdornment from '@mui/material/InputAdornment';

export type GroupStatePropertiesProps = {
  roofIndex: number;
  patchIndex: number;
  onContinue: () => void;
  disableContinue?: boolean;
};

export const GroupStateProperties: React.FC<GroupStatePropertiesProps> = ({
  roofIndex,
  patchIndex,
  onContinue,
  disableContinue,
}) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: { slope, slopeSuffix, direction, roofOrientationLabel },
            },
          },
        },
      },
    },
  } = useTranslations();

  const slopeChipsOptions = useSelector(selectSlopeChipsOptions);

  const { control, watch } = useFormContext<SolarEnergyProjectValues>();
  const { remove } = useFieldArray<SolarEnergyProjectValues, `roofs.${number}.patches`>({
    control,
    name: `roofs.${roofIndex}.patches`,
  });
  const rotation = watch(`roofs.${roofIndex}.patches.${patchIndex}.direction`);

  const handleRemovePatch = useHandleRemovePatch();

  return (
    <Stack spacing={2}>
      <Typography variant="h3" className="bold">
        {translate(roofOrientationLabel)}
      </Typography>
      <MuiNumberInput
        control={control}
        name={`roofs.${roofIndex}.patches.${patchIndex}.angle`}
        label={translate(slope)}
        InputProps={{
          endAdornment: <InputAdornment position="end">{translate(slopeSuffix)}</InputAdornment>,
        }}
        decimalScale={0}
      />
      <ChipList
        // TODO: hack for https://github.com/react-hook-form/react-hook-form/pull/10490. Remove after version update.
        key={`roofs.${roofIndex}.patches.${patchIndex}.angle`}
        chips={slopeChipsOptions}
        control={control}
        name={`roofs.${roofIndex}.patches.${patchIndex}.angle`}
        version="square"
        wrapChips={true}
      />
      <InputSuffixWrapperSmart
        control={control}
        name={`roofs.${roofIndex}.patches.${patchIndex}.direction`}
        label={translate(direction)}
        type={`number`}
        suffix={translate(slopeSuffix)}
        isDisabled
      />
      <Compass rotation={rotation} />
      <PropertiesFormButtons
        disableContinue={disableContinue}
        onContinue={onContinue}
        onRemove={handleRemovePatch(remove)}
      />
    </Stack>
  );
};
