import { useTranslations } from '@services/hooks/translations/useTranslations';

type ResultProductCountProps = {
  count?: number;
};

export const ResultMaterialCount: React.FC<ResultProductCountProps> = ({ count }) => {
  const {
    translate,
    translations: {
      infoLabels: { results },
    },
  } = useTranslations();

  return <p className={`small color-grey-1 margin-bottom-10`}>{`${count ?? 0} ${translate(results)}`}</p>;
};
