import { DeductionType, ProjectDeductionDto } from '@generatedTypes/data-contracts';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { ListItem } from '@components/ListMui/ListItem';
import { Stack, Typography } from '@mui/material';
import { getFormattedNumber } from '@pages/NewLeads/utils';

type DeductionsListContentProps = {
  deductions: Omit<ProjectDeductionDto, `projectId`>[];
  onShowAddEditDeductionForm?: (projectDeductionId?: ProjectDeductionDto[`id`]) => void;
};

export const DeductionsListContent: React.FC<DeductionsListContentProps> = ({
  deductions,
  onShowAddEditDeductionForm,
}) => {
  const {
    translate,
    translations: {
      common: { units },
    },
  } = useTranslations();

  return (
    <>
      {deductions.map(({ id, name, type, value }) => (
        <ListItem
          sx={{ paddingInlineStart: 3 }}
          key={id}
          title={
            <Stack direction="row" width={`80%`} justifyContent="space-between">
              <Typography>{name}</Typography>
            </Stack>
          }
          onClick={() => onShowAddEditDeductionForm?.(id)}
          secondaryAction={
            <Typography noWrap>
              {type === DeductionType.Sum
                ? `- ${getFormattedNumber(value, translate(units.currencyCrowns))}`
                : `${value}%`}
            </Typography>
          }
        />
      ))}
    </>
  );
};
