import { useContext, useMemo } from 'react';
import { useSelectedRoofAndPatch } from '../utils/accessors';
import { SOLAR_MAP_EDIT_STATE } from '../utils/constants';
import { GroupStateProperties, GroupStatePropertiesProps } from './GroupStateProperties';
import { PatchStateProperties, PatchStatePropertiesProps } from './PatchStateProperties/PatchStateProperties';
import { RoofStateProperties, RoofStatePropertiesProps } from './RoofStateProperties';
import { RoofSettingsStateProperties, RoofSettingsStatePropertiesProps } from './RoofSettingsStateProperties';
import { Empty } from '@components/Empty/Empty';
import RoofOutlined from '@assets/svg/RoofOutlined.svg';
import { Box, Button } from '@mui/material';
import { UseFieldArrayAppend, useFieldArray, useFormContext } from 'react-hook-form';
import { SolarEnergyProjectValues } from '../../SolarEnergyProject';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { SolarMapVisualisationContext } from '../utils/useSolarMapVisualisation/context';
import { getNewRoofName } from '../utils/roof';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { useHandleCreateRoof } from '../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/roof';

type MapVisualisationPropertiesProps = Omit<RoofSettingsStatePropertiesProps, `roofIndex`> &
  Omit<RoofStatePropertiesProps, `roof` | `roofIndex` | `onCreatePatch`> &
  Omit<PatchStatePropertiesProps, `roofIndex` | `patchIndex`> &
  Omit<GroupStatePropertiesProps, `roofIndex` | `patchIndex`> & {
    onContinue: () => void;
    disableContinue?: boolean;
  };

export const MapVisualisationProperties: React.FC<MapVisualisationPropertiesProps> = ({
  onContinue,
  disableContinue,
}) => {
  const { solarEnergyProject, mapEditState } = useContext(SolarMapVisualisationContext);
  const { selectedRoof, selectedRoofIndex } = useSelectedRoofAndPatch();

  const { control, getValues } = useFormContext<SolarEnergyProjectValues>();
  const { append: roofAppend } = useFieldArray<SolarEnergyProjectValues>({
    control,
    name: `roofs`,
  });
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: { newRoofNamePrefix, startByAddingRoof, addRoof },
            },
          },
        },
      },
    },
  } = useTranslations();

  const handleCreateRoof = useHandleCreateRoof() as (
    appendRoofCallback: UseFieldArrayAppend<SolarEnergyProjectValues>,
    roofName: string,
    defaultLathDimension: number | null,
  ) => () => void;

  const { lathDimension } = useGetSolarEnergyProjectSettings();

  const defaultLathDimensionId = useMemo(
    () =>
      lathDimension?.values.map(({ name, id }) => ({
        label: name as string,
        value: id,
      }))?.[0].value ?? null,
    [lathDimension?.values],
  );

  const propertiesComponent = useMemo(() => {
    switch (mapEditState) {
      case SOLAR_MAP_EDIT_STATE.BASE: {
        return (
          <Empty
            description={translate(startByAddingRoof)}
            icon={RoofOutlined}
            callToActionButton={
              <Button
                variant="contained"
                onClick={handleCreateRoof(
                  roofAppend,
                  getNewRoofName(
                    getValues().roofs.map(({ name }) => name),
                    translate(newRoofNamePrefix),
                  ),
                  defaultLathDimensionId,
                )}
              >
                {translate(addRoof)}
              </Button>
            }
          />
        );
      }
      case SOLAR_MAP_EDIT_STATE.ROOF: {
        return selectedRoof ? <RoofStateProperties roof={selectedRoof} roofIndex={selectedRoofIndex} /> : null;
      }
      case SOLAR_MAP_EDIT_STATE.ROOF_SETTINGS: {
        return selectedRoof ? (
          <RoofSettingsStateProperties
            key={`${selectedRoofIndex}-${solarEnergyProject.roofs[selectedRoofIndex].id}`}
            roofIndex={selectedRoofIndex}
            onContinue={onContinue}
            disableContinue={disableContinue}
          />
        ) : null;
      }
      case SOLAR_MAP_EDIT_STATE.SHAPE:
      case SOLAR_MAP_EDIT_STATE.PANELS: {
        const selectedRoofIndex = solarEnergyProject.roofs.findIndex((roof) => roof.selected);
        const selectedPatchIndex = solarEnergyProject.roofs[selectedRoofIndex]?.patches.findIndex(
          (patch) => patch.selected,
        );
        return selectedRoofIndex >= 0 && selectedPatchIndex >= 0 ? (
          <PatchStateProperties
            key={selectedRoofIndex}
            roofIndex={selectedRoofIndex}
            patchIndex={selectedPatchIndex}
            disabled={mapEditState === SOLAR_MAP_EDIT_STATE.SHAPE}
            onContinue={onContinue}
            disableContinue={disableContinue}
          />
        ) : null;
      }
      case SOLAR_MAP_EDIT_STATE.GROUP: {
        const selectedRoofIndex = solarEnergyProject.roofs.findIndex((roof) => roof.selected);
        const selectedPatchIndex = solarEnergyProject.roofs[selectedRoofIndex]?.patches.findIndex(
          (patch) => patch.selected,
        );
        return selectedRoofIndex >= 0 && selectedPatchIndex >= 0 ? (
          <GroupStateProperties
            roofIndex={selectedRoofIndex}
            patchIndex={selectedPatchIndex}
            onContinue={onContinue}
            disableContinue={disableContinue}
          />
        ) : null;
      }
      default:
        return null;
    }
  }, [
    mapEditState,
    translate,
    startByAddingRoof,
    handleCreateRoof,
    roofAppend,
    getValues,
    newRoofNamePrefix,
    defaultLathDimensionId,
    addRoof,
    selectedRoof,
    selectedRoofIndex,
    solarEnergyProject.roofs,
    onContinue,
    disableContinue,
  ]);

  return <Box sx={{ maxWidth: `512px` }}>{propertiesComponent}</Box>;
};
