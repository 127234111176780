import React, { useContext } from 'react';
import { Patch } from '../roofVisualisationTypes';
import { panelSizeToPixels } from '../utils/calculations';
import { PANEL_SIZE } from '../utils/constants';
import { MapSolarPanel } from './MapSolarPanel';
import { PatchVertex } from './PatchVertex';
import { PatchEdge } from './PatchEdge';
import { PatchPolygon } from './PatchPolygon';
import {
  SolarMapVisualisationContext,
  SolarMapVisualisationDispatchContext,
} from '../utils/useSolarMapVisualisation/context';
import { actions } from '../utils/useSolarMapVisualisation/store';
import { useHandlePanelClick } from '../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/panels';
import { useHandleEdgeSelect } from '../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/edge';
import { useHandleSelectPatch } from '../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/navigation';
import { useHandlePolygonDragEnd } from '../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/patch';
import {
  useHandleMarkerLoad,
  useHandlePolygonLoad,
} from '../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/map';
import {
  useHandleVertexDragEnd,
  useHandleVertexSelect,
} from '../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/vertex';
import { useLocation } from 'react-router-dom';

type RoofPatchParentProps = {
  patch: Patch;
  disablePanels?: boolean;
  enablePanelsEditing?: boolean;
  enableShapeEditing?: boolean;
  highlightEdges?: boolean;
  enablePatchSelectionFromMap?: boolean;
  disableMapUi?: boolean;
};

export const RoofPatch: React.FC<RoofPatchParentProps> = ({
  disablePanels,
  enablePanelsEditing = false,
  enablePatchSelectionFromMap = false,
  enableShapeEditing = false,
  disableMapUi,
  highlightEdges = false,
  patch,
}) => {
  const location = useLocation();
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  const { meterInPixels, hidePanels, parentWidth, parentHeight } = useContext(SolarMapVisualisationContext);
  const { onDragStart } = actions;
  const panelWidth = panelSizeToPixels(PANEL_SIZE.width, meterInPixels);
  const panelHeight = panelSizeToPixels(PANEL_SIZE.height, meterInPixels);

  const showSolarPanels = !!meterInPixels && !hidePanels;
  const onSelectPatch = useHandleSelectPatch();
  const onVertexDragStart = () => {
    dispatch(onDragStart());
  };

  const handlePanelClick = useHandlePanelClick();
  const handleEdgeSelect = useHandleEdgeSelect();
  const handlePolygonLoad = useHandlePolygonLoad();
  const handlePolygonDragEnd = useHandlePolygonDragEnd();
  const handleMarkerLoad = useHandleMarkerLoad();
  const handleVertexSelect = useHandleVertexSelect();
  const handleVertexDragEnd = useHandleVertexDragEnd();

  return (
    <>
      <PatchPolygon
        path={patch.vertices.map(({ latLng }) => latLng)}
        enableShapeEditing={enableShapeEditing}
        hidePolygon={disableMapUi}
        highlightEdges={highlightEdges}
        onDragStart={patch.selected ? onVertexDragStart : undefined}
        onDragEnd={patch.selected ? handlePolygonDragEnd(patch) : undefined}
        onLoad={handlePolygonLoad(patch)}
        onClick={enablePatchSelectionFromMap ? onSelectPatch(patch.id) : undefined}
      />
      {patch.edges.map((edge, index) => (
        <PatchEdge
          key={edge.id}
          edge={edge}
          selected={index === 0}
          onEdgeClick={enableShapeEditing ? handleEdgeSelect(edge.id, patch) : undefined}
          showDistanceMarker={patch.selected}
          highlightEdges={highlightEdges}
        />
      ))}
      {enableShapeEditing &&
        patch.vertices.map(({ latLng, selected, id }, index) => (
          <PatchVertex
            key={id}
            position={latLng}
            onMarkerLoad={handleMarkerLoad(index, patch)}
            onVertexClick={handleVertexSelect(index, patch)}
            onVertexDragEnd={handleVertexDragEnd(index, patch)}
            onVertexDragStart={onVertexDragStart}
            selected={selected}
          />
        ))}
      {showSolarPanels &&
        patch.panels
          .filter(({ insideShape, active }) => insideShape && (active || !disableMapUi))
          .map(
            (panel) =>
              panel.latLng && (
                <MapSolarPanel
                  isOfferPage={location.pathname.includes(`offer`)}
                  key={`${panel.latLng.toString()}-${panel.id}`}
                  panel={panel}
                  width={panelWidth}
                  height={panelHeight}
                  onClick={
                    enablePanelsEditing
                      ? handlePanelClick(panel.id, patch.id)
                      : enablePatchSelectionFromMap
                        ? onSelectPatch(patch.id)
                        : undefined
                  }
                  disable={disablePanels}
                  orientation={patch.panelOrientation}
                  parentWidth={parentWidth}
                  parentHeight={parentHeight}
                />
              ),
          )}
    </>
  );
};
