import { useCallback, useEffect, useState } from 'react';
import './numeric.css';
import { SvgIcon } from '@components/controls/svgIcon';
import { convertToNumber, convertToStringAsNumber } from '@utils/math';
import { formatPercentsInInput } from '@pages/NewLeads/utils';

export type NumericProps = {
  name: string;
  value?: number | string | null;
  onChange: (newValue: number | string, name: string) => void;
  min?: number;
  max?: number;
  classesForContainer?: string;
  classesInput?: string;
  isDisabled?: boolean;
  step?: number;
  precision?: number;
  suffix?: string;
};

export const Numeric: React.FC<NumericProps> = ({
  name,
  value = 0,
  onChange,
  min,
  max,
  classesForContainer = ``,
  classesInput = ``,
  isDisabled = false,
  step = 1,
  precision = 3,
  suffix,
}) => {
  const [valueNum, setValueNum] = useState<number>(0);

  const setNewValue = useCallback(
    // TO DO
    // refactor after Formik is used every where (no useForm)
    (newVal: number | string) => {
      let newValueNumber = 0;
      if (typeof newVal === `string`) {
        if (newVal.at(-1) === `,`) {
          onChange(newVal + `0`.repeat(precision), name);
          return;
        }
        onChange(newVal, name);
        return;
      }

      newValueNumber = convertToNumber(newVal);

      if (typeof newVal === `number`) {
        const newRounded = Number(newValueNumber.toFixed(precision));

        const afterMin = typeof min !== `undefined` ? Math.max(newRounded, min) : newRounded;
        const afterMax = typeof max !== `undefined` ? Math.min(afterMin, max) : afterMin;
        const valueToSet = convertToStringAsNumber(isNaN(afterMax) ? newValueNumber ?? 0 : afterMax);
        onChange(valueToSet, name);
      }
    },
    [max, min, name, onChange, precision],
  );

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (typeof event.target.value === `string`) {
        const data = formatPercentsInInput(event.currentTarget.value, event.currentTarget.selectionStart ?? 0);

        event.currentTarget.value = data?.value || `0`;
        event.currentTarget.setSelectionRange(data?.caretPos.start || 0, data?.caretPos.end || 0);
        setNewValue(data?.value || `0`);
      } else {
        setNewValue(event.target.value);
      }
    },
    [setNewValue],
  );

  const onAdd = useCallback(() => {
    setNewValue(valueNum + step);
  }, [setNewValue, step, valueNum]);

  const onSubtract = useCallback(() => {
    setNewValue(valueNum - step);
  }, [setNewValue, step, valueNum]);

  useEffect(() => {
    setValueNum(convertToNumber(value));
  }, [value]);

  return (
    <div className="numeric">
      <div className={`numeric-wrapper ${classesForContainer}`}>
        <button className="numeric-button" onClick={onSubtract} type="button" disabled={isDisabled}>
          <SvgIcon iconId="minus" svgClasses="option-icon" isDisabled={isDisabled} />
        </button>
        <input
          name={name}
          type="text"
          value={`${value ?? 0}${suffix ?? ``}`}
          onChange={onChangeHandler}
          min={min}
          max={max}
          disabled={isDisabled}
          className={`numeric-input ${classesInput}`}
        />
        <button className="numeric-button" onClick={onAdd} type="button" disabled={isDisabled}>
          <SvgIcon iconId="plus" svgClasses="option-icon" isDisabled={isDisabled} />
        </button>
      </div>
    </div>
  );
};
