import { Column } from '@components/columnLayout/column';
import { ColumnLayout } from '@components/columnLayout/columnLayout';
import { showNext, VIEW_TYPES } from '@components/columnLayout/utils';
import { AppState } from '@redux/reducers';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import notFound404Cord from '@assets/svg/404cord.svg';
import notFoundCord from '@assets/svg/Cord.svg';
import { Button } from '@mui/material';

type NotFoundProps = {
  onButtonClick: () => void;
};

export function NotLoggedInNotFoundImg() {
  return (
    <div className="column gap">
      <img src={notFoundCord} />
    </div>
  );
}

export function LoggedInNotFoundImg() {
  return (
    <div className="column gap">
      <img src={notFound404Cord} />
    </div>
  );
}

const NotLoggedInNotFound: React.FC<NotFoundProps> = ({ onButtonClick }) => {
  const { translations, translate } = useTranslations();
  const tr = translations.notFound;
  return (
    <div className="column gap">
      <h2>{translate(tr.notAccess)}</h2>
      <Button variant="contained" onClick={onButtonClick}>
        {translate(tr.toTheLoginButton)}
      </Button>
    </div>
  );
};

const LoggedInNotFound: React.FC<NotFoundProps> = ({ onButtonClick }) => {
  const { translations, translate } = useTranslations();
  const tr = translations.notFound;
  return (
    <div className="column gap">
      <h2>{translate(tr.goToInboxLabel)}</h2>
      <Button variant="contained" onClick={onButtonClick}>
        {translate(tr.toTheInboxButton)}
      </Button>
    </div>
  );
};

export function NotFound() {
  const { translations, translate } = useTranslations();
  const tr = translations.notFound;
  const loggedInUserId = useSelector<AppState, number | null>((state) => state.User.userId);
  const navigate = useNavigate();

  useEffect(() => {
    showNext(VIEW_TYPES.INFO)();
  }, []);

  const onButtonClick = useCallback(
    (targetPath: string) => () => {
      navigate(targetPath);
    },
    [navigate],
  );

  return (
    <div className="column fh">
      <ColumnLayout>
        <Column viewType={VIEW_TYPES.LIST} noMenu={!loggedInUserId} />
        <Column viewType={VIEW_TYPES.INFO}>
          <div className="wrapper-box column align-center gap">
            {loggedInUserId ? (
              <>
                <LoggedInNotFoundImg />
                <h1 className="bold">{translate(tr.siteNotFound)}</h1>
                <LoggedInNotFound onButtonClick={onButtonClick(`/leads`)} />
              </>
            ) : (
              <>
                <NotLoggedInNotFoundImg />
                <h1 className="bold">{translate(tr.somethingWentWrong)}</h1>
                <NotLoggedInNotFound onButtonClick={onButtonClick(`/login`)} />
              </>
            )}
          </div>
        </Column>
      </ColumnLayout>
    </div>
  );
}
