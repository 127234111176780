import { OfferAgreementDto } from '@generatedTypes/data-contracts';
import { TAction } from '@redux/reducers';
import { OfferPageState } from '@redux/reducers/offerPage';

export const OFFER_PAGE_ACTIONS = {
  GET_OFFER_AGREEMENTS: `GET_OFFER_AGREEMENTS`,
} as const;

export type OfferPageAction = TAction<typeof OFFER_PAGE_ACTIONS, OfferPageState>;

export const setOfferAgreements = (value: OfferAgreementDto): OfferPageAction => ({
  type: OFFER_PAGE_ACTIONS.GET_OFFER_AGREEMENTS,
  payload: { offerAgreements: value },
});
