import { showNext, VIEW_TYPES } from '@components/columnLayout/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import React, { useEffect } from 'react';
import NotFound from '@assets/svg/EmptyComponentIcons/NotFound.svg';
import { Container } from '@mui/material';
import { GridLayoutWithSideElementAndMargins } from '@components/gridLayout/gridLayoutWithSideElementAndMargins';
import { OfferAgreementPartnerDto, TeaserContentDto } from '@generatedTypes/data-contracts';
import { Empty } from '@components/Empty/Empty';
import { WebTypography } from '@components/Typography/WebTypography';
import Stack from '@mui/material/Stack';
import { Teasers } from '@pages/offer/OfferPageComponents';
import { ContactForOfferNotFound } from '@pages/offer/components/Contact/ContactForOfferNotFound';

type OfferNotValidProps = {
  partnerDetails?: OfferAgreementPartnerDto;
  teasers: TeaserContentDto[];
};

export const OfferNotValid: React.FC<OfferNotValidProps> = ({ partnerDetails, teasers }) => {
  const {
    translations: {
      leads: {
        offer: {
          offerNotValid: { header, emptyComponentHeader, emptyComponentDescription },
        },
      },
    },
    translate,
  } = useTranslations();

  useEffect(() => {
    showNext(VIEW_TYPES.INFO)();
  }, []);

  return (
    <Stack spacing={20} sx={(theme) => ({ margin: `${theme.spacing(15)} 0 ${theme.spacing(10)} 0` })}>
      <GridLayoutWithSideElementAndMargins sideSticky>
        <Container>
          <WebTypography variant="h1">{translate(header)}</WebTypography>
          <Empty
            title={translate(emptyComponentHeader)}
            description={translate(emptyComponentDescription)}
            icon={NotFound}
          />
        </Container>
        {partnerDetails && <ContactForOfferNotFound partnerDetails={partnerDetails} />}
      </GridLayoutWithSideElementAndMargins>
      <Teasers teasers={teasers} />
    </Stack>
  );
};
