import { OfferAgreementDto } from '@generatedTypes/data-contracts';
import { OfferPageAction, OFFER_PAGE_ACTIONS } from '@redux/actions/offerPage';

export type OfferPageState = {
  offerAgreements: OfferAgreementDto | null;
};

export const initState: OfferPageState = {
  offerAgreements: null,
};

export const offerPageReducer = (state = initState, action: OfferPageAction) => {
  switch (action.type) {
    case OFFER_PAGE_ACTIONS.GET_OFFER_AGREEMENTS:
      return { ...state, offerAgreements: action.payload?.offerAgreements ?? null };
    default:
      return state;
  }
};
