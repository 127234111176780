import { useForm } from 'react-hook-form';
import { ColumnItem } from '@components/columnLayout/utils';
import { PutPartner, TemplateType } from '@services/api/partners/partners';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback } from 'react';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { parsePartnerForUpdate } from '@services/api/partners/utils';
import { ParsedBackendValidationResults } from '@components/controls/validations';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { FormSection } from '@components/forms/MuiFormSection';
import { RichTextInputWrapper } from '@components/controls/react-hook-form-friendly/smart/richTextInputWrapper';

type OfferTemplateEditProps = {
  disableForm: boolean;
  goBackHandler: () => void;
  updatePartner: (partner: PutPartner) => void;
  partner: PartnerDto | null;
  templateToEdit: TemplateType;
  beValidationResults: ParsedBackendValidationResults | null;
};

export const OfferTemplateEdit: ColumnItem<OfferTemplateEditProps> = ({
  disableForm,
  goBackHandler,
  partner,
  updatePartner,
  templateToEdit,
}) => {
  const {
    translate,
    translations: {
      myAccount: {
        offerSettings: {
          templates: { description, headers },
        },
      },
    },
  } = useTranslations();

  const { handleSubmit, reset, control } = useForm({
    defaultValues: {
      [templateToEdit]: partner?.[templateToEdit] ?? ``,
    },
  });

  const onCancel = useCallback(() => {
    goBackHandler();
    reset();
  }, [goBackHandler, reset]);

  return (
    <FormWrapper
      onCancel={onCancel}
      onSubmit={handleSubmit((values) => {
        if (partner) {
          updatePartner({
            ...parsePartnerForUpdate(partner),
            ...values,
          });
        }
      })}
      disabled={disableForm}
    >
      <FormSection title={translate(headers[templateToEdit])}>
        <div className="h2 bold">{translate(description)}</div>
        <RichTextInputWrapper label="" control={control} name={templateToEdit} />
        <div />
        <HorizontalLine classes="dark" />
        <div />
      </FormSection>
    </FormWrapper>
  );
};
