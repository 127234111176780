import { authFetch, getErrorFromFormRequest } from '@services/api/utils';
import {
  GetOfferBidPriceOverview,
  OfferPriceOverviewDto,
  UpdateOfferPriceOverviewRequest,
} from '@generatedTypes/data-contracts';
import { usePost } from '@services/hooks/api/useGet';
import { useGetOffer } from './offers';
import { useQueryClient } from 'react-query';
import { LEADS, OFFER_PRICE_OVERVIEW } from '@variables/queryKeys';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useCustomQuery } from '@hooks/useCustomQuery';

export type OfferPriceOverviewProps = {
  offerId: number;
  bids?: GetOfferBidPriceOverview[];
};

export const fetchOfferPriceOverview = async ({ offerId, bids }: OfferPriceOverviewProps) => {
  if (offerId > 0) {
    const bidsObject =
      bids?.reduce((acc, { id, margin }, index) => {
        let newAcc = acc;
        if (id) {
          newAcc += newAcc === `` ? `?` : `&`;
          newAcc += `bids[${index}].Id=${String(id)}`;
          newAcc += `&bids[${index}].Margin=${String(margin)}`;
        }
        return newAcc;
      }, ``) ?? ``;
    const url = `${process.env.PROTECTED_API_URL}/offers/${offerId}/price-overview${bidsObject}`;

    const response = await authFetch(url, {
      method: `GET`,
      mode: `cors`,
    });
    const json = await response.json();
    if (response.ok) {
      return json as OfferPriceOverviewDto;
    }
    throw new Error(json.message);
  }
  return null;
};

export type EditOfferPriceOverviewProps = UpdateOfferPriceOverviewRequest & {
  offerId: number;
};

const editOfferPriceOverview = async ({ offerId, ...data }: EditOfferPriceOverviewProps) => {
  if (offerId > 0) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/offers/${offerId}/price-overview`, {
      method: `PUT`,
      mode: `cors`,
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw getErrorFromFormRequest(response);
    }
  }
};

export const useGetOfferPriceOverview = () => {
  const { offer } = useGetOffer();

  const { data, refetch } = useCustomQuery({
    queryKey: [OFFER_PRICE_OVERVIEW, offer?.id],
    queryFn: () => fetchOfferPriceOverview({ offerId: offer?.id || 0 }),
    enabled: !!offer?.id,
  });

  return { offerPriceOverview: data ?? null, refetchOfferPriceOverview: refetch };
};
export const useGetOfferPriceCalculationWithDeductions = () => {
  const { data, isLoading, mutate } = useCustomMutation({
    mutationFn: fetchOfferPriceOverview,
  });

  return {
    offerPriceCalculationWithDeductions: data,
    isOfferPriceCalculationWithDeductionsLoading: isLoading,
    getOfferPriceOverviewSimulationData: mutate,
  };
};

usePost(fetchOfferPriceOverview);

export const useEditOfferPriceOverview = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, mutate, isLoading } = useCustomMutation({
    mutationFn: editOfferPriceOverview,
    onSuccess: () => {
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
      queryClient.invalidateQueries(LEADS);
    },
  });

  return {
    editOfferPriceOverviewAsync: mutateAsync,
    isEditOfferPriceOverviewLoading: isLoading,
    editOfferPriceOverview: mutate,
  };
};
