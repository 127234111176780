import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { Fab } from '@mui/material';
import React from 'react';
import './mapOptionButton.css';

type MapOptionButtonProps = {
  icon: string;
  onClick?: () => void;
  alt: string;
  animate?: boolean;
  disabled?: boolean;
  primaryButton?: boolean;
};

export const MapOptionButton: React.FC<MapOptionButtonProps> = ({
  icon,
  onClick,
  alt,
  disabled = ``,
  primaryButton = false,
  animate,
}) => (
  <Fab
    disabled={!!disabled}
    onClick={onClick}
    color={primaryButton ? `primary` : `secondary`}
    size="medium"
    aria-label={alt}
    className={animate ? `animate-map-option-button` : ``}
  >
    <MdiIconWrapper path={icon} />
  </Fab>
);
