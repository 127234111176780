import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { CardHeaderProps, CardMedia, Stack, SxProps } from '@mui/material';
import MuiCard, { CardProps as CardPropsCore } from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import { FormEvent, useMemo } from 'react';

type CardProps = {
  title?: React.ReactNode;
  titleAction?: () => void;
  titleActionIcon?: string;
  customTitleAction?: CardHeaderProps[`action`];
  cardMedia?: { src: string; alt: string };
  children?: React.ReactNode;
  hidden?: boolean;
  className?: string;
  sx?: SxProps;
  wrapperComponent?: CardPropsCore[`component`];
  buttonType?: `button` | `submit` | `reset`;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  disabled?: boolean;
  titleActionTestId?: string;
};

const CardHeaderAndContent: React.FC<CardProps> = ({
  title,
  titleAction,
  titleActionIcon,
  customTitleAction,
  onSubmit,
  children,
  titleActionTestId,
}) => {
  const action = useMemo(
    () =>
      customTitleAction
        ? customTitleAction
        : (titleAction || onSubmit) &&
          titleActionIcon && (
            <IconButton onClick={titleAction} type={onSubmit ? `submit` : `button`} data-testid={titleActionTestId}>
              <MdiIconWrapper path={titleActionIcon} />
            </IconButton>
          ),
    [customTitleAction, titleAction, onSubmit, titleActionIcon, titleActionTestId],
  );

  return (
    <>
      {(title || titleAction || titleActionIcon || customTitleAction) && (
        <CardHeader
          title={title}
          action={action}
          sx={{
            flexWrap: `wrap`,
            '&.MuiCardHeader-root': { paddingBlockEnd: children ? 0 : 3 },
            '& .MuiCardHeader-action': {
              marginInlineEnd: -1,
            },
          }}
        />
      )}
      {children && <MuiCardContent>{children}</MuiCardContent>}
    </>
  );
};

export const Card: React.FC<CardProps> = ({
  title,
  titleAction,
  titleActionIcon,
  customTitleAction,
  cardMedia,
  children,
  hidden = false,
  className,
  sx,
  wrapperComponent = `div`,
  onSubmit,
  titleActionTestId,
}) => {
  return (
    <MuiCard hidden={hidden} className={className} sx={sx} component={wrapperComponent} onSubmit={onSubmit}>
      {cardMedia && (
        <CardMedia
          image={cardMedia.src}
          title={cardMedia.alt}
          sx={{ flexGrow: 1, backgroundSize: `contain` }}
        ></CardMedia>
      )}
      {cardMedia ? (
        <Stack sx={{ flexGrow: 1 }}>
          <CardHeaderAndContent title={title} titleAction={titleAction} titleActionIcon={titleActionIcon}>
            {children}
          </CardHeaderAndContent>
        </Stack>
      ) : (
        <CardHeaderAndContent
          title={title}
          titleAction={titleAction}
          titleActionIcon={titleActionIcon}
          onSubmit={onSubmit}
          titleActionTestId={titleActionTestId}
          customTitleAction={customTitleAction}
        >
          {children}
        </CardHeaderAndContent>
      )}
    </MuiCard>
  );
};
