import { Roof } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/roofVisualisationTypes';

export const getMapBoundsFromRoofs = (roofs: Roof[]) => {
  const bounds = new window.google.maps.LatLngBounds();

  for (const roof of roofs) {
    for (const patch of roof.patches) {
      for (const vertex of patch.vertices) {
        bounds.extend(vertex.latLng);
      }
    }
  }

  return bounds;
};
