import { DeductionTemplateDto, DeductionType } from '@generatedTypes/data-contracts';
import { CheckBoxProps as CheckBoxDumbProps } from '@components/controls/react-hook-form-friendly/dumb/Checkbox/Checkbox';
import { Stack, Typography } from '@mui/material';
import { getFormattedNumber } from '@pages/NewLeads/utils';

type TemplateCheckboxRowProps = {
  currencyTranslation: string;
};

export const getMapDeductionTemplateToCheckboxRow =
  ({ currencyTranslation }: TemplateCheckboxRowProps) =>
  (
    deductionTemplate: DeductionTemplateDto,
  ): Omit<CheckBoxDumbProps, `name` | `onChange` | `checked` | `isLabelOnRight`> => ({
    label: (
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography>{deductionTemplate.name}</Typography>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography noWrap>
            {deductionTemplate.type === DeductionType.Sum
              ? `- ${getFormattedNumber(deductionTemplate.value, currencyTranslation)}`
              : `${deductionTemplate.value}%`}
          </Typography>
        </Stack>
      </Stack>
    ),
    value: deductionTemplate.id,
  });
