export const enum AnalyticEvents {
  addProject = `addProject`,
  assignLead = `assignLead`,
  cancelProject = `cancelProject`,
  contactInfo = `contactInfo`,
  costAdd = `costAdd`,
  costAddInitiated = `costAddInitiated`,
  createLead = `createLead`,
  customerInfo = `customerInfo`,
  priceSimulation = `priceSimulation`,
  deductionTemplateAdd = `deductionTemplateAdd`,
  deductionAddInitiate = `deductionAddInitiate`,
  deductionAdd = `deductionAdd`,
  priceSave = `priceSave`,
  materialAdd = `materialAdd`,
  materialAddInitiate = `materialAddInitiate`,
  materialChange = `materialChange`,
  materialChoice = `materialChoice`,
  materialDownload = `materialDownload`,
  offerAccept = `offerAccept`,
  offerIntroduction = `offerIntroduction`,
  offerNext = `offerNext`,
  offerPrint = `offerPrint`,
  projectAdditionTemplateAdd = `projectAdditionTemplateAdd`,
  saveProject = `saveProject`,
  solarIntroduction = `solarIntroduction`,
  statusUpdate = `statusUpdate`,
}
