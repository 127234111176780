import * as S from './LeftNavLink.styled';

export interface SingleRowProps {
  title: string;
  selected?: boolean;
}

export const SingleRow = ({ title, selected }: SingleRowProps) => {
  return (
    <S.SingleRowWrapper selected={selected}>
      <S.Title selected={selected}>{title}</S.Title>
    </S.SingleRowWrapper>
  );
};
