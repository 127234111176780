import '@components/navbar/navbar.css';
import './print.css';
import { showNext, VIEW_TYPES } from '@components/columnLayout/utils';
import { useEffect } from 'react';
import { OfferNotValid } from '@pages/fallbacks/offerNotValid';
import { OfferDetailsPage } from './OfferDetailsPage';
import { useGetOfferAgreement, useUpdateOfferAgreement } from '@services/api/offerAgreements/offerAgreements';
import { useGetTeasers } from '@services/api/contents/contents';
import { OfferPageLoader } from './OfferPageLoader';
import { LeadOfferStatus } from '@generatedTypes/data-contracts';

export const OfferPage: React.FC = () => {
  const { offerAgreement, isLoading: isOfferAgreementLoading } = useGetOfferAgreement({ isLoginRequired: false });
  const { data: teasers } = useGetTeasers(null);

  const { updateOfferAgreementAsync, isUpdatingOfferAgreement } = useUpdateOfferAgreement();

  const offerExpired = new Date(offerAgreement?.details?.validToDate ?? ``) < new Date();
  const offerAccepted = offerAgreement?.status === LeadOfferStatus.Accepted;

  const shouldShowOffer = !offerExpired || offerAccepted;

  useEffect(() => {
    showNext(VIEW_TYPES.INFO)();
  }, []);

  if (isOfferAgreementLoading) {
    return <OfferPageLoader />;
  }

  return !isOfferAgreementLoading && offerAgreement?.details && shouldShowOffer ? (
    <OfferDetailsPage
      offerDetails={offerAgreement}
      sendOfferAgreement={updateOfferAgreementAsync}
      teasers={teasers ?? []}
      isSendingOfferAgreement={isUpdatingOfferAgreement}
    />
  ) : (
    <OfferNotValid partnerDetails={offerAgreement?.partner} teasers={teasers ?? []} />
  );
};
