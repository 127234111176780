import { ButtonBase, Card } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';

export interface ChipWrapperProps {
  selected?: boolean;
  disabled?: boolean;
}

export const ChipWrapper = styled(ButtonBase)<ChipWrapperProps>(({ theme, selected, disabled }) => ({
  padding: theme.spacing(0.5, 0.5),
  width: `fit-content`,
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.primary.light,

  ...(!disabled && {
    '&:hover, &:focus-visible': {
      backgroundColor: theme.palette.primary.dark,

      'label, p': {
        color: theme.palette.common.white,
      },
    },
    '&:focus-visible': {
      outline: `2px solid ${theme.palette.primary.dark}`,
      outlineOffset: `1px`,
    },
  }),

  ...(selected &&
    !disabled && {
      backgroundColor: theme.palette.primary.dark,

      'label, p': {
        color: theme.palette.common.white,
      },
    }),

  ...(disabled &&
    !selected && {
      backgroundColor: theme.palette.common.white,

      'label, p': {
        color: theme.palette.common.black,
      },
    }),

  ...(disabled &&
    selected && {
      backgroundColor: theme.palette.rexelEnergy.lightGrey,

      'label, p': {
        color: theme.palette.common.white,
      },
    }),
}));

export const ChipCard = styled(Card)(() => ({
  display: `flex`,
  alignItems: `center`,
  flexWrap: `wrap`,
  border: `none`,
  backgroundColor: `transparent`,
}));

export const ChipContent = styled(CardContent)(({ theme }) => ({
  '&.MuiCardContent-root:last-child': {
    padding: `0 ${theme.spacing(2)}`,
  },
}));
