import { PartnerDto } from '@generatedTypes/data-contracts';
import Stack from '@mui/material/Stack';
import { PartnerCard } from './PartnerCard';
import { ClientInformationCard } from './ClientInformationCard';
import { LogoCard } from './LogoCard';
import { TermsAndConditionsTab } from './TermsAndConditionsCard';

export interface BusinessTabProps {
  partner: PartnerDto | null;
}

export const BusinessTab = ({ partner }: BusinessTabProps) => {
  if (!partner) {
    return <>Loading</>;
  }

  return (
    <Stack spacing={3}>
      <PartnerCard partner={partner} />
      <ClientInformationCard partner={partner} />
      <LogoCard partner={partner} />
      <TermsAndConditionsTab partner={partner} />
    </Stack>
  );
};
