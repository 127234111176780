import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { useTranslations } from '@services/hooks/translations/useTranslations';
import { betweenNumberValue, minimalNumberValue } from '@variables/zod';
import Typography from '@mui/material/Typography';
import { Slider } from '@components/controls/react-hook-form-friendly/smart/Slider';
import { useCalculateReturnOfInvestment } from '@pages/offer/useCalculateReturnOfInvestement';
import Stack from '@mui/material/Stack';
import Tabs from '@components/Tabs/Tabs';
import { useTheme } from '@mui/material';
import { ReturnOfInvestementChartTab } from './ReturnOfInvestementChartTab';
import { PricesFormTab } from './PricesFormTab';
import * as S from '@pages/offer/offer.styled';
import { formatCurrency } from '@pages/NewLeads/utils';

const ROI_TAB_CONTENT_HEIGHT = 121;
const MINIMAL_SLIDER_VALUE = 0;
const MAXIMAL_SLIDER_VALUE = 100;

const solarEnergyReturnOfInvestmentZodSchema = z.object({
  purchasePrice: minimalNumberValue(MINIMAL_SLIDER_VALUE),
  sellingPrice: minimalNumberValue(MINIMAL_SLIDER_VALUE),
  soldEnergyFactor: betweenNumberValue(MINIMAL_SLIDER_VALUE, MAXIMAL_SLIDER_VALUE),
});

export type SolarEnergyReturnOfInvestmentType = z.infer<typeof solarEnergyReturnOfInvestmentZodSchema>;

const initialFormValues = {
  purchasePrice: 2,
  sellingPrice: 1,
  soldEnergyFactor: 50,
};

type ReturnFromInvestmentFormProps = {
  yearlyProductionInkWh?: number | null | undefined;
  projectGrossPrice: number;
  onChange?: (values: SolarEnergyReturnOfInvestmentType) => void;
  initValues?: SolarEnergyReturnOfInvestmentType;
  disableInputs?: boolean;
};

export const ReturnFromInvestmentForm: React.FC<ReturnFromInvestmentFormProps> = ({
  yearlyProductionInkWh,
  projectGrossPrice,
  onChange,
  initValues = initialFormValues,
  disableInputs,
}) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { solarEnergyOffer },
      },
    },
  } = useTranslations();

  const theme = useTheme();

  const { watch, control, getValues, reset } = useForm<SolarEnergyReturnOfInvestmentType>({
    resolver: zodResolver(solarEnergyReturnOfInvestmentZodSchema),
    defaultValues: initValues,
  });
  const values = getValues();

  useEffect(() => {
    reset({
      soldEnergyFactor: initValues.soldEnergyFactor,
      purchasePrice: initValues.purchasePrice,
      sellingPrice: initValues.sellingPrice,
    });
  }, [initValues.purchasePrice, initValues.sellingPrice, initValues.soldEnergyFactor, reset]);

  const {
    returnOfInvestmentCalculation: { percentageReturn, saving, reducedCost, earning, totalProjectGrossPrice },
    calculateReturnOfInvestment,
  } = useCalculateReturnOfInvestment({
    sellingPrice: values.sellingPrice,
    purchasePrice: values.purchasePrice,
    soldEnergyFactor: values.soldEnergyFactor,
    totalProjectGrossPrice: projectGrossPrice,
    yearlyEnergyProductionInWh: yearlyProductionInkWh,
  });

  const returnOfInvestmentData = useMemo(
    () => [saving, totalProjectGrossPrice - saving],
    [saving, totalProjectGrossPrice],
  );

  watch((values) => {
    calculateReturnOfInvestment({
      sellingPrice: values.sellingPrice,
      purchasePrice: values.purchasePrice,
      soldEnergyFactor: values.soldEnergyFactor,
      totalProjectGrossPrice: projectGrossPrice,
      yearlyEnergyProductionInWh: yearlyProductionInkWh,
    });
  });

  const [soldEnergyFactor, sellingPrice, purchasePrice] = watch([`soldEnergyFactor`, `sellingPrice`, `purchasePrice`]);

  useEffect(() => {
    if (onChange) {
      onChange({ sellingPrice, purchasePrice, soldEnergyFactor });
    }
  }, [sellingPrice, purchasePrice, soldEnergyFactor, onChange]);

  return (
    <Stack spacing={3}>
      <Stack>
        <Stack direction="row" alignItems="flex-end">
          <S.GreenText variant="h1" component="p">
            {percentageReturn}%
          </S.GreenText>
          &nbsp;
          <Typography component="span">{translate(solarEnergyOffer.firstYear)}</Typography>
        </Stack>
        <Typography>{`${translate(solarEnergyOffer.saving)} ${formatCurrency(saving)}`}</Typography>
      </Stack>
      <Stack>
        <Stack justifyContent="space-between" sx={{ height: 212, fontSize: `13px` }}>
          <Tabs
            label=""
            tabs={[translate(solarEnergyOffer.saving), translate(solarEnergyOffer.price)]}
            wrapperStyle={{
              '& .MuiTabs-indicator': { height: `1px` },
              maxHeight: theme.spacing(3),
              minHeight: theme.spacing(3),
            }}
            tabStyle={{
              maxHeight: theme.spacing(3),
              minHeight: theme.spacing(3),
              '&.MuiTab-root': {
                paddingTop: 0,
                paddingBottom: 0,
                fontFamily: theme.typography.fontFamily,
                fontSize: 13,
              },
              '&.MuiTab-root[aria-selected="false"]': { color: theme.palette.rexelEnergy.ctaBlue },
            }}
            tabPanels={[
              <ReturnOfInvestementChartTab
                key="roi-chart"
                chartHeight={ROI_TAB_CONTENT_HEIGHT}
                earning={earning}
                percentageReturn={percentageReturn}
                reducedCost={reducedCost}
                returnOfInvestmentData={returnOfInvestmentData}
              />,
              <PricesFormTab
                key="roi-form"
                control={control}
                formHeight={ROI_TAB_CONTENT_HEIGHT}
                purchasePrice={purchasePrice}
                sellingPrice={sellingPrice}
                disable={disableInputs}
              />,
            ]}
          />
          <Slider
            control={control}
            name="soldEnergyFactor"
            min={MINIMAL_SLIDER_VALUE}
            max={MAXIMAL_SLIDER_VALUE}
            hideInputs
            variant="simple"
            disabled={disableInputs}
          />
          <Typography variant="subtitle1" mt={-1}>
            {soldEnergyFactor}% {translate(solarEnergyOffer.estimationText)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
