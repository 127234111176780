import { ProductCategoryRowDto } from '@generatedTypes/data-contracts';
import { TAction } from '@redux/reducers';
import { LeadState } from '@redux/reducers/lead';
import { AppliedFilters } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/productsAttributeFilters/utils/types';

export const LEAD_ACTIONS = {
  PRODUCT_CATEGORIES: `PRODUCT_CATEGORIES`,
  CURRENT_PRODUCT_CATEGORY_ID: `CURRENT_PRODUCT_CATEGORY_ID`,
  CURRENT_PROJECT_ID: `CURRENT_PROJECT_ID`,
  CURRENT_PROJECT_PRODUCT_ID: `CURRENT_PROJECT_PRODUCT_ID`,
  CURRENT_PROJECT_PRODUCT_DETAILS: `CURRENT_PROJECT_PRODUCT_DETAILS`,
  CURRENT_PRODUCTS_ATTRIBUTES_FILTERS: `CURRENT_PRODUCTS_ATTRIBUTES_FILTERS`,
  CURRENT_PROJECT_PRODUCT_PRODUCT_ID: `CURRENT_PROJECT_PRODUCT_PRODUCT_ID`,
  CURRENT_PROJECT_ADDITION_ID: `CURRENT_PROJECT_ADDITION_ID`,
  CURRENT_PROJECT_DEDUCTION_ID: `CURRENT_PROJECT_DEDUCTION_ID`,
  CURRENT_OFFER_BID_ID: `CURRENT_OFFER_BID_ID`,
  CURRENT_OFFER_BID_PRODUCT_CATEGORIES: `CURRENT_OFFER_BID_PRODUCT_CATEGORIES`,
  CAR_CHARGING_PROJECT_DETAILS: `CAR_CHARGING_PROJECT_DETAILS`,
  LEADS_PROJECTS: `LEADS_PROJECTS`,
  SOLAR_ENERGY_AVAILABLE_PANEL_ORIENTATIONS: `SOLAR_ENERGY_AVAILABLE_PANEL_ORIENTATIONS`,
  SOLAR_ENERGY_AVAILABLE_RAIL_ORIENTATIONS: `SOLAR_ENERGY_AVAILABLE_RAIL_ORIENTATIONS`,
  SOLAR_ENERGY_AVAILABLE_MOUNTING_MATERIAL_MANUFACTURERS: `SOLAR_ENERGY_AVAILABLE_MOUNTING_MATERIAL_MANUFACTURERS`,
  SOLAR_ENERGY_SELECTED_ROOF_ID: `SOLAR_ENERGY_SELECTED_ROOF_ID`,
  CURRENT_OFFER_ID: `CURRENT_OFFER_ID`,
  BIDS_FOR_OFFER: `BIDS_FOR_OFFER`,
  PROJECT_CATEGORIES: `PROJECT_CATEGORIES`,
  CURRENT_OFFERT_DATA: `CURRENT_OFFERT_DATA`,
  OFFER_PRICE_OVERVIEW: `OFFER_PRICE_OVERVIEW`,
} as const;

export type LeadAction = TAction<typeof LEAD_ACTIONS, LeadState>;

export const setProductCategories = (value: ProductCategoryRowDto[]): LeadAction => ({
  type: LEAD_ACTIONS.PRODUCT_CATEGORIES,
  payload: { productCategories: value },
});
export const setCurrentProductCategoryId = (value: number): LeadAction => ({
  type: LEAD_ACTIONS.CURRENT_PRODUCT_CATEGORY_ID,
  payload: { currentProductCategoryId: value },
});
export const setCurrentProjectId = (value: number | null): LeadAction => ({
  type: LEAD_ACTIONS.CURRENT_PROJECT_ID,
  payload: { currentProjectId: value },
});
export const setCurrentProjectProductId = (value: number | null): LeadAction => ({
  type: LEAD_ACTIONS.CURRENT_PROJECT_PRODUCT_ID,
  payload: { currentProjectProductId: value },
});
export const setCurrentProjectProductProductId = (value: number | null): LeadAction => ({
  type: LEAD_ACTIONS.CURRENT_PROJECT_PRODUCT_PRODUCT_ID,
  payload: { currentProjectProductProductId: value },
});
export const setCurrentProductsAttributesFilters = (value: AppliedFilters): LeadAction => ({
  type: LEAD_ACTIONS.CURRENT_PRODUCTS_ATTRIBUTES_FILTERS,
  payload: { currentProductsAttributesFilters: value },
});
export const setCurrentOfferBidId = (value: number): LeadAction => ({
  type: LEAD_ACTIONS.CURRENT_OFFER_BID_ID,
  payload: { currentOfferBidId: value },
});
export const setCurrentProjectAdditionId = (value: number | null): LeadAction => ({
  type: LEAD_ACTIONS.CURRENT_PROJECT_ADDITION_ID,
  payload: { currentProjectAdditionId: value },
});
export const setCurrentProjectDeductionId = (value: number | null): LeadAction => ({
  type: LEAD_ACTIONS.CURRENT_PROJECT_DEDUCTION_ID,
  payload: { currentProjectDeductionId: value },
});
export const setSolarEnergySelectedRoofId = (value: number | null): LeadAction => ({
  type: LEAD_ACTIONS.SOLAR_ENERGY_SELECTED_ROOF_ID,
  payload: { solarEnergySelectedRoofId: value },
});
