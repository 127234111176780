import { Card } from '@components/Card/Card';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useForgotPassword } from '@services/api/accounts';
import { UniversalUser } from '@services/api/users/types';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback } from 'react';

export interface ResetUserPasswordProps {
  user: UniversalUser;
}

export const ResetUserPassword = ({ user }: ResetUserPasswordProps) => {
  const { resetPassword, isResettingPassword } = useForgotPassword();
  const {
    translate,
    translations: {
      users: { details },
    },
  } = useTranslations();

  const handleResetPassword = useCallback(() => {
    if (user?.email) {
      resetPassword({ email: user.email });
    }
  }, [resetPassword, user]);

  return (
    <Card>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline" flexWrap="wrap" width="100%">
        <h2>{translate(details.password)}</h2>
        <Button
          variant="outlined"
          onClick={handleResetPassword}
          disabled={isResettingPassword}
          data-testId="reset-password"
        >
          {translate(details.resetPassword)}
        </Button>
      </Stack>
    </Card>
  );
};
