import { CreateProjectDeductionRequest } from '@generatedTypes/data-contracts';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { AddEditDeductionForm, DeductionValuesType } from './AddEditDeductionForm';
import { selectCurrentProjectId } from '@redux/selectors/lead';
import { useGetLeadProjects } from '@services/api/leads/lead-info';
import { useGetOffer } from '@services/api/offers/offers';
import { useCreateProjectDeduction } from '@services/api/projectDeductions/projectDeductionsAdd';
import { useDeleteProjectDeduction } from '@services/api/projectDeductions/projectDeductionsDelete';
import {
  PutProjectDeductionProps,
  useUpdateProjectDeduction,
} from '@services/api/projectDeductions/projectDeductionsEdit';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useGetProjectDeduction } from '@services/api/projectDeductions/projectDeductionsGet';

export interface AddEditProductDeductionWrapperProps {
  onClose: () => void;
}

export const AddEditProductDeductionWrapper = ({ onClose }: AddEditProductDeductionWrapperProps) => {
  const pushDataLayer = useAnalytics();
  const currentProjectId = useSelector(selectCurrentProjectId);
  const { leadProjects } = useGetLeadProjects();
  const { isOfferLocked } = useGetOffer();
  const { deleteProjectDeduction } = useDeleteProjectDeduction();
  const { updateProjectDeductionAsync, isUpdatingProjectDeduction, updateProjectDeductionBeValidationErrors } =
    useUpdateProjectDeduction();
  const { addProjectDeductionAsync, isAddingProjectDeduction, addProjectDeductionBeValidationErrors } =
    useCreateProjectDeduction();
  const { projectDeduction } = useGetProjectDeduction();

  const onSubmitProjectDeduction = useCallback(
    ({ value, type, name, scope }: DeductionValuesType) => {
      pushDataLayer({
        event: AnalyticEvents.deductionAdd,
        projectType: leadProjects?.find((project) => project.id === currentProjectId)?.type,
      });
      const baseData = {
        value,
        type,
        name,
        scope,
      };

      if (projectDeduction?.id) {
        updateProjectDeductionAsync({
          ...baseData,
          id: projectDeduction.id,
        } as PutProjectDeductionProps).then(onClose);
      } else if (currentProjectId) {
        addProjectDeductionAsync({
          ...baseData,
          projectId: currentProjectId,
        } as CreateProjectDeductionRequest).then(onClose);
      }
    },
    [
      pushDataLayer,
      leadProjects,
      projectDeduction?.id,
      currentProjectId,
      updateProjectDeductionAsync,
      onClose,
      addProjectDeductionAsync,
    ],
  );

  return (
    <AddEditDeductionForm
      onDelete={deleteProjectDeduction}
      closeModal={onClose}
      onSubmitDeductionTemplate={onSubmitProjectDeduction}
      disableForm={isUpdatingProjectDeduction || isAddingProjectDeduction || isOfferLocked}
      beValidationResults={addProjectDeductionBeValidationErrors || updateProjectDeductionBeValidationErrors}
      initialDeductionTemplate={projectDeduction}
    />
  );
};
