import { SystemSettingsDto } from '@generatedTypes/data-contracts';
import { SYSTEM_SETTINGS_ACTIONS, SystemSettingsAction } from '@redux/actions/systemSettings';

export type SystemSettingsState = {
  systemSettings: SystemSettingsDto | null;
};

export const initState: SystemSettingsState = {
  systemSettings: null,
};

export const SystemSettingsReducer = (state = initState, action: SystemSettingsAction) => {
  switch (action.type) {
    case SYSTEM_SETTINGS_ACTIONS.GET_SYSTEM_SETTINGS:
      return { ...state, systemSettings: action.payload.systemSettings ?? null };
    default:
      return state;
  }
};
