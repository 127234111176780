import React, { useContext, useMemo } from 'react';
import { useFieldArray, UseFieldArrayAppend, useFormContext } from 'react-hook-form';

import { TabButton } from '@components/button/TabButton';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/SolarEnergyProject';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { mdiHomeRoof, mdiPlus } from '@mdi/js';
import Stack from '@mui/material/Stack';
import { getNewRoofName } from '../../utils/roof';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { SolarMapVisualisationContext } from '../../utils/useSolarMapVisualisation/context';
import {
  useHandleCreateRoof,
  useHandleSelectRoof,
} from '../../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/roof';

export type SelectionBaseStateButtonsProps = {
  disableButtons?: boolean;
};

export const SelectionRoofStateButtons: React.FC<SelectionBaseStateButtonsProps> = ({ disableButtons = false }) => {
  const { solarEnergyProject } = useContext(SolarMapVisualisationContext);
  const { control, getValues } = useFormContext<SolarEnergyProjectValues>();
  const { append } = useFieldArray<SolarEnergyProjectValues>({
    control,
    name: `roofs`,
  });
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: { newRoofNamePrefix },
            },
          },
        },
      },
    },
  } = useTranslations();

  const { lathDimension } = useGetSolarEnergyProjectSettings();
  const handleCreateRoof = useHandleCreateRoof() as (
    appendRoofCallback: UseFieldArrayAppend<SolarEnergyProjectValues>,
    roofName: string,
    defaultLathDimension: number | null,
  ) => () => void;
  const handleSelectRoof = useHandleSelectRoof();

  const defaultLathDimensionId = useMemo(
    () =>
      lathDimension?.values.map(({ name, id }) => ({
        label: name as string,
        value: id,
      }))?.[0].value ?? null,
    [lathDimension?.values],
  );

  return (
    <Stack direction="row">
      {solarEnergyProject.roofs.map(({ id, selected }) => (
        <TabButton
          key={id}
          icon={mdiHomeRoof}
          alt="Edit roof"
          selected={selected}
          onClick={!disableButtons ? handleSelectRoof?.(id) : undefined}
          disabled={!selected && disableButtons}
        />
      ))}
      <TabButton
        alt="Add roof"
        icon={mdiPlus}
        onClick={handleCreateRoof(
          append,
          getNewRoofName(
            getValues().roofs.map(({ name }) => name),
            translate(newRoofNamePrefix),
          ),
          defaultLathDimensionId,
        )}
        disabled={disableButtons}
      />
    </Stack>
  );
};
