import { LeadDto, ProjectDto } from '@generatedTypes/data-contracts';
import { authFetch } from '../utils';
import { useParams } from 'react-router-dom';
import { LEAD, PROJECTS } from '@variables/queryKeys';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useQueryClient } from 'react-query';

const fetchLeadInfo = async (leadId: number) => {
  if (leadId === -1) {
    return null;
  }
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/leads/${leadId}`, {
    method: `GET`,
    mode: `cors`,
  });
  const json = await response.json();
  if (response.ok) {
    return json as LeadDto;
  }
  throw new Error(json.message);
};

export const fetchLeadProjects = async (leadId: number) => {
  if (leadId === -1) {
    return null;
  }

  return authFetch(`${process.env.PROTECTED_API_URL}/leads/${leadId}/projects`, {
    method: `GET`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    })
    .then((json) => json as ProjectDto[])
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

export const fetchLeadProject = async (leadId: number, projectId: number): Promise<ProjectDto | null> => {
  if (leadId < 0 || projectId < 0) {
    return null;
  }

  return authFetch(`${process.env.PROTECTED_API_URL}/leads/${leadId}/projects?projectId=${projectId}`, {
    method: `GET`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    })
    .then((json) => (json as ProjectDto[])?.[0])
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

export const useGetLead = () => {
  const { leadId } = useParams<{ leadId: string }>();

  const { data, isLoading, refetch } = useCustomQuery({
    queryKey: [LEAD, leadId],
    queryFn: () => fetchLeadInfo(Number(leadId)),
    enabled: Boolean(leadId),
  });

  return { lead: data ?? null, isLoadingLead: isLoading, refetch };
};

export const useGetLeadProjects = () => {
  const { leadId } = useParams<{ leadId: string }>();

  const { data, isLoading, refetch } = useCustomQuery({
    queryKey: [LEAD, PROJECTS, leadId],
    queryFn: () => fetchLeadProjects(Number(leadId)),
    enabled: Boolean(leadId),
  });

  return { leadProjects: data ?? null, isLoading, refetch };
};

export const useGetLeadProject = (options?: { onSuccess: (data: ProjectDto | null) => void }) => {
  const { leadId } = useParams<{ leadId: string }>();
  const queryClient = useQueryClient();

  const { data, isLoading, mutate, mutateAsync } = useCustomMutation({
    mutationFn: (projectId: number) => fetchLeadProject(Number(leadId), projectId),
    onSuccess: (data, projectId) => {
      queryClient.setQueryData([LEAD, PROJECTS, leadId], (oldData: ProjectDto[] | null | undefined): ProjectDto[] => {
        if (!oldData) {
          return data ? [data] : [];
        }
        if (oldData.some((project) => project.id === projectId)) {
          return oldData
            .map((project) => {
              if (project.id === projectId) {
                return data;
              }
              return project;
            })
            .filter((project) => project !== null) as ProjectDto[];
        } else {
          return data ? [...oldData, data] : oldData;
        }
      });
      options?.onSuccess(data);
    },
  });

  return { leadProject: data ?? null, isLoading, fetchLeadProject: mutate, fetchLeadProjectAsync: mutateAsync };
};
