import { FormValidationArgs } from '@components/controls/validations';
import { OnBlurType } from './onBlurs';
import { OnChangeType } from './onChanges';
import { ParsedBackendValidationResults } from '@components/controls/validations';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { GroupBase } from 'react-select';

export type UseFormOptionsType = {
  beValidationResults?: ParsedBackendValidationResults | null;
  isDisabled?: boolean;
};

export type DropdownOption<T extends string | number = string | number> = {
  value: T;
  label: string;
  disabled?: boolean;
};

export type ValuesForSubmitValue<Schema extends UseFormSchema, Key extends keyof Schema> = Schema[Key][`type`] extends
  | SimpleFormFieldType.numeric
  | SimpleFormFieldType.numericString
  ? Schema[Key][`isRequired`] extends true
    ? number
    : number | undefined
  : Schema[Key][`type`] extends ComplexFormFieldType.checkbox
    ? (string | number)[]
    : Schema[Key][`isRequired`] extends true
      ? string
      : string | undefined;

export type FieldValue<Type extends SimpleFormFieldType | ComplexFormFieldType> =
  | (Type extends SimpleFormFieldType.numeric
      ? number | null
      : Type extends ComplexFormFieldType.dropdown
        ? DropdownOption
        : string | number)
  | undefined;

export type CheckboxRadioFieldsType = {
  type: ComplexFormFieldType.checkbox | ComplexFormFieldType.radio | string;
  label: string;
  name: string;
  value: string;
  checked: boolean;
  onChange: OnChangeType<ComplexFormFieldType.checkbox | ComplexFormFieldType.radio>;
  isDisabled?: boolean;
};

export type BaseFormInputs<Schema extends UseFormSchema> = {
  [Key in keyof Schema]: FieldValue<Schema[Key][`type`]>;
};

export enum SimpleFormFieldType {
  text = `text`,
  numeric = `numeric`,
  numericString = `numericString`,
}

export enum ComplexFormFieldType {
  dropdown = `dropdown`,
  checkbox = `checkbox`,
  radio = `radio`,
}

export type ComponentsFieldType<
  Schema extends UseFormSchema,
  key extends keyof Schema,
> = Schema[key][`type`] extends ComplexFormFieldType.dropdown
  ? Partial<SelectComponents<DropdownOption, false, GroupBase<DropdownOption>>>
  : undefined;

interface UseFormSchemaBaseFieldType extends Partial<Omit<FormValidationArgs[keyof FormValidationArgs], `setter`>> {
  isRequired?: boolean;
  type: SimpleFormFieldType | ComplexFormFieldType;
  defaultValue?: FieldValue<UseFormSchema[string][`type`]>;
  isDisabled?:
    | boolean
    | ((
        value: FieldValue<UseFormSchema[keyof UseFormSchema][`type`]>,
        data: Record<keyof UseFormSchema, FieldValue<UseFormSchema[keyof UseFormSchema][`type`]>>,
      ) => boolean);
}

export interface UseFormSchemaSimpleFieldType extends UseFormSchemaBaseFieldType {
  type: SimpleFormFieldType;
}

export interface UseFormSchemaNumericFieldType extends UseFormSchemaBaseFieldType {
  type: SimpleFormFieldType.numeric;
  precision?: number;
  min?: number | null;
  max?: number;
}

export interface UseFormSchemaNumericStringFieldType extends UseFormSchemaBaseFieldType {
  type: SimpleFormFieldType.numericString;
  precision?: number;
  min?: number | null;
  max?: number;
}

export interface UseFormSchemaComplexFieldType extends UseFormSchemaBaseFieldType {
  type: ComplexFormFieldType;
  options: DropdownOption[] | undefined;
}
export interface UseFormSchema {
  [key: string]:
    | UseFormSchemaBaseFieldType
    | UseFormSchemaSimpleFieldType
    | UseFormSchemaNumericFieldType
    | UseFormSchemaNumericStringFieldType
    | UseFormSchemaComplexFieldType;
}

export interface UseFormFormFieldReturnTypeBase<
  Schema extends UseFormSchema,
  Key extends keyof Schema,
  Type extends Schema[Key][`type`],
> {
  value: FieldValue<Type>;
  errorMsg: string | undefined;
  onChange: OnChangeType<Type>;
  onBlur?: OnBlurType;
  isRequired?: boolean;
  name: string;
  type: string;
  isDisabled?: boolean;
  min?: number;
  max?: number;
}

export interface UseFormFormFieldReturnTypeDropdown<
  Schema extends UseFormSchema,
  Key extends keyof Schema,
  Type extends ComplexFormFieldType.dropdown,
> extends UseFormFormFieldReturnTypeBase<Schema, Key, Type> {
  components: ComponentsFieldType<Schema, Key>;
  options: DropdownOption[] | undefined;
  errorMsg: string | undefined;
}

export interface UseFormFormFieldReturnTypeCheckboxRadio<
  Type extends ComplexFormFieldType.checkbox | ComplexFormFieldType.radio,
> {
  isDisabled?: boolean;
  value: FieldValue<Type>;
  fields: CheckboxRadioFieldsType[];
  errorMsg?: string | undefined;
}

export type UseFormInputFields<
  Schema extends UseFormSchema,
  Key extends keyof Schema,
> = Schema[Key][`type`] extends ComplexFormFieldType.dropdown
  ? UseFormFormFieldReturnTypeDropdown<Schema, Key, Schema[Key][`type`]>
  : Schema[Key][`type`] extends ComplexFormFieldType.checkbox | ComplexFormFieldType.radio
    ? UseFormFormFieldReturnTypeCheckboxRadio<Schema[Key][`type`]>
    : UseFormFormFieldReturnTypeBase<Schema, Key, Schema[Key][`type`]>;

export interface UseFormReturnType<Schema extends UseFormSchema> {
  formFields: {
    [key in keyof Schema]: {
      inputFields: UseFormInputFields<Schema, key>;
      setValue: (newValue: FieldValue<Schema[key][`type`]>) => void;
      setError: (newErrorValue: string) => void;
    };
  };
  valuesForSubmit: {
    [key in keyof Schema]: ValuesForSubmitValue<Schema, key>;
  };
  setAllErrors: (newErrors: Record<keyof Schema, string>) => void;
  setAllValues: (newValues: BaseFormInputs<Schema>) => void;
  setBackendValidation: (errors: ParsedBackendValidationResults) => void;
  resetErrorFields: () => void;
  resetAllValues: () => void;
  resetToStartingValues: () => void;
  validate: () => boolean;
  hasErrors: boolean;
  refreshSchema: () => void;
}
