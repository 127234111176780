import { setCurrentPageView, setPreviousPageView } from '@redux/actions/global';
import { store } from '@redux/store';
import { ValueOf } from '@utils/typeUtils';

export const VIEW_TYPES = {
  LIST: `list`,
  INFO: `info`,
  EDIT: `edit`,
  INFO_LAYOUT_WITH_MARGINS: `INFO_LAYOUT_WITH_MARGINS`,
} as const;

export type ViewType = ValueOf<typeof VIEW_TYPES>;

type ColumnItemProps = {
  label?: string;
};

export type ColumnItem<P extends Record<string, unknown> = Record<string, unknown>> = React.FC<ColumnItemProps & P>;

export function assertsReactElementOfColumnItem(
  item: React.ReactNode | React.ReactElement,
): asserts item is React.ReactElement<ColumnItemProps> {
  if (
    item &&
    typeof item === `object` &&
    Object.hasOwn(item, `props`) &&
    Object.hasOwn((item as React.ReactElement).props, `label`)
  ) {
    return;
  }
  throw new Error(`Column children must be of type ColumnItem`);
}

export const viewTypeTagParams = {
  [VIEW_TYPES.LIST]: {
    id: `page-view-items-list`,
    classes: [
      `box box-mobile`,
      `page-list-wrapper`,
      `col-desktop-3`,
      `col-desktop-small-4`,
      `col-mobile-small-12`,
      `col-mobile-12`,
      `col-tablet-12`,
    ],
  },
  [VIEW_TYPES.INFO]: {
    id: `page-view-selected-item-info`,
    classes: [
      `to-slide`,
      `col-desktop-9`,
      `col-desktop-small-8`,
      `col-mobile-small-12`,
      `col-mobile-12`,
      `col-tablet-12`,
      `page-view-selected-item-info`,
    ],
  },
  [VIEW_TYPES.INFO_LAYOUT_WITH_MARGINS]: {
    id: `page-view-selected-item-info`,
    classes: [
      `box`,
      `box-no-left-lg`,
      `box-mobile`,
      `to-slide`,
      `col-desktop-12`,
      `col-mobile-small-12`,
      `col-mobile-12`,
      `col-tablet-12`,
    ],
  },
  [VIEW_TYPES.EDIT]: {
    id: `page-view-selected-item-edit`,
    classes: [
      `box`,
      `box-large`,
      `box-mobile`,
      `to-slide`,
      `col-desktop-3`,
      `col-mobile-small-12`,
      `col-mobile-12`,
      `col-tablet-12`,
      `edit-column`,
    ],
  },
};

export const showNext = (nextView: ViewType) => () => {
  store.dispatch(setCurrentPageView(nextView));
};

export const showNextAsync = (nextView: ViewType) => async () => {
  const nextViewAction = await Promise.resolve(setCurrentPageView(nextView));
  store.dispatch(nextViewAction);
};

export function managePageViews(currentView: ViewType, newView: ViewType, appEl: HTMLDivElement) {
  if (currentView === VIEW_TYPES.LIST && newView === VIEW_TYPES.LIST) {
    // show only list on left
    const activeElements = appEl.querySelector(`#page-view-port`);

    activeElements &&
      [...activeElements.querySelectorAll(`.to-slide.active`)].forEach((el) => {
        el.classList.remove(`active`);
      });
    store.dispatch(setPreviousPageView(currentView));
    return;
  }
  if (
    (newView === VIEW_TYPES.INFO && currentView === VIEW_TYPES.EDIT) ||
    (newView === VIEW_TYPES.INFO && currentView === VIEW_TYPES.LIST)
  ) {
    // show only info panel (both case from edit and from list)
    appEl.querySelector(`#page-view-selected-item-edit`)?.classList.remove(`active`);
    appEl.querySelector(`#page-view-selected-item-info`)?.classList.add(`active`);
    store.dispatch(setPreviousPageView(currentView));
    return;
  }
  if (currentView === VIEW_TYPES.LIST && newView === VIEW_TYPES.EDIT) {
    // show only edit panel
    appEl.querySelector(`#page-view-selected-item-edit`)?.classList.add(`active`);
    store.dispatch(setPreviousPageView(currentView));
    return;
  }
}
