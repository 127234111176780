import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import {
  AddEditAdditionForm,
  AdditionValuesType,
} from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/ProjectAdditions/Forms/EditForm/AddEditAdditionForm';
import { useCallback } from 'react';
import { parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';
import { useGetPartner } from '@services/api/partners';
import { useGetProjectAdditionTemplate } from '@services/api/projectAdditionTemplates/useGetProjectAdditionTemplate';
import { useCreateProjectAdditionTemplate } from '@services/api/projectAdditionTemplates/useCreateProjectAdditionTemplate';
import { useUpdateProjectAdditionTemplate } from '@services/api/projectAdditionTemplates/useUpdateProjectAdditionTemplate';
import { useDeleteProjectAdditionTemplate } from '@services/api/projectAdditionTemplates/useDeleteProjectAdditionTemplate';

export interface EditProductAdditionsWrapperProps {
  onClose: () => void;
}

export const EditProductAdditionTemplateWrapper = ({ onClose }: EditProductAdditionsWrapperProps) => {
  const pushDataLayer = useAnalytics();
  const { partner } = useGetPartner();
  const { deleteProjectAdditionTemplateAsync } = useDeleteProjectAdditionTemplate();
  const {
    updateProjectAdditionTemplateAsync,
    isUpdatingProjectAdditionTemplate,
    updateProjectAdditionTemplateValidationErrors,
  } = useUpdateProjectAdditionTemplate();
  const {
    addProjectAdditionTemplateAsync,
    isAddingProjectAdditionTemplate,
    addProjectAdditionTemplateValidationErrors,
  } = useCreateProjectAdditionTemplate();
  const { partnerProjectAdditionsTemplate } = useGetProjectAdditionTemplate();

  const onSubmitProjectAdditionTemplate = useCallback(
    ({ netPrice: formNetPrice, ...formData }: AdditionValuesType) => {
      pushDataLayer({
        event: AnalyticEvents.projectAdditionTemplateAdd,
      });

      const netPrice = Number(parseInputValueToSubmitNumberValue(formNetPrice));
      if (partnerProjectAdditionsTemplate?.id) {
        updateProjectAdditionTemplateAsync({
          ...formData,
          id: partnerProjectAdditionsTemplate.id,
          netPrice,
          isDeductible: formData.isDeductible === `true`,
        }).then(onClose);
      } else {
        addProjectAdditionTemplateAsync({
          ...formData,
          netPrice,
          partnerId: partner?.id ?? -1,
          isDeductible: formData.isDeductible === `true`,
        }).then(onClose);
      }
    },
    [
      addProjectAdditionTemplateAsync,
      onClose,
      partner?.id,
      partnerProjectAdditionsTemplate?.id,
      pushDataLayer,
      updateProjectAdditionTemplateAsync,
    ],
  );

  return (
    <AddEditAdditionForm
      onDelete={deleteProjectAdditionTemplateAsync}
      closeModal={onClose}
      onSubmitAddEditAddition={onSubmitProjectAdditionTemplate}
      disableForm={isUpdatingProjectAdditionTemplate || isAddingProjectAdditionTemplate}
      beValidationResults={updateProjectAdditionTemplateValidationErrors || addProjectAdditionTemplateValidationErrors}
      initialProjectAddition={partnerProjectAdditionsTemplate}
      disableHourPrice
    />
  );
};
