import { UserType } from '@generatedTypes/data-contracts';
import { selectUserType } from '@redux/selectors/user';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export interface AuthorizedUserTypesProps {
  userTypes: UserType[];
  children: React.ReactNode;
  redirectTo?: string;
}

export const AuthorizedUserTypes = ({ userTypes, children, redirectTo = `/404` }: AuthorizedUserTypesProps) => {
  const userType = useSelector(selectUserType);

  const isUserTypeAuthorized = userTypes.includes(userType);

  if (!isUserTypeAuthorized) {
    return <Navigate to={redirectTo} />;
  }

  return <>{children}</>;
};
