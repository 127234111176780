import { FC, useMemo } from 'react';
import { OfferRoofDto, SolarEnergyProjectDto } from '@generatedTypes/data-contracts';
import { RoofVisualisationMap } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/RoofVisualisationMap';
import { getRoofs } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/useGetRoofsForForm';
import { getMapBoundsFromRoofs } from '@utils/getMapBoundsFromRoofs';

export interface OfferMapWithSolarProps {
  roofs: OfferRoofDto[];
  panelsResolverVersion: number;
}

const size = { width: 644, height: 278 };

export const OfferMapWithSolars: FC<OfferMapWithSolarProps> = ({ roofs, panelsResolverVersion }) => {
  const project = useMemo(() => {
    return {
      roofs: roofs,
      panelsResolverVersion,
    };
  }, [panelsResolverVersion, roofs]);

  const { mapBounds, position } = useMemo(() => {
    const mapBounds = getMapBoundsFromRoofs(getRoofs({ roofs }));
    return {
      mapBounds,
      position: { lat: mapBounds.getCenter().lat(), lng: mapBounds.getCenter().lng() },
    };
  }, [roofs]);

  return (
    <RoofVisualisationMap
      bounds={mapBounds}
      disableMapUi
      projectDetails={project as unknown as SolarEnergyProjectDto}
      position={position}
      size={size}
      skipAnimationDuration
    />
  );
};
