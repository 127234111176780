import { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { SvgIcon } from './svgIcon';

export interface DropdownValue {
  label: string;
  value: number;
}

export interface DropdownProps {
  values: DropdownValue[];
  selectedValueIndex?: number;
  onValueClick: (value: DropdownValue) => void;
  isDisabled?: boolean;
  isSearchable?: boolean;
}

const DownChevron = () => <SvgIcon iconId="chevron-down" />;

/**
 * This component is design to control state of application, if you are looking for an input please use `<Select />` component
 */
export const DropdownControl = ({
  values,
  selectedValueIndex,
  onValueClick,
  isDisabled,
  isSearchable = false,
}: DropdownProps) => {
  const [selectedValue, setSelectedValue] = useState(values[selectedValueIndex || 0]);

  useEffect(() => {
    setSelectedValue(values[selectedValueIndex || 0]);
  }, [selectedValueIndex, values]);

  const handleChange = (value: SingleValue<DropdownValue>) => {
    if (value) {
      setSelectedValue(value);
      onValueClick(value);
    }
  };
  return (
    <div className="dropdown__container--no-border">
      <Select<DropdownValue>
        options={values}
        value={selectedValue}
        onChange={handleChange}
        classNamePrefix="dropdown"
        components={{ DownChevron }}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
      />
    </div>
  );
};
