import React, { useCallback } from 'react';
import { ProductDto, ProjectProductRowDto } from '@generatedTypes/data-contracts';
import ProductFallback from '@assets/svg/Article fallback.svg';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import Typography from '@mui/material/Typography';
import { getFormattedNumberWithStaticFraction } from '@pages/NewLeads/utils';
import { ListItem } from '@components/ListMui/ListItem';
import { Stack } from '@mui/material';

type ProductsListProps = {
  products: ProductDto[] | ProjectProductRowDto[];
  ProductCategoriesName?: string;
};

export const ProductsListForOffer: React.FC<ProductsListProps> = ({ products, ProductCategoriesName }) => {
  const {
    translate,
    translations: {
      leads: { offer },
    },
  } = useTranslations();

  const imageError = useCallback(function (e: React.SyntheticEvent<HTMLImageElement, Event>) {
    e.currentTarget?.classList.add(`hidden`);
    e.currentTarget?.parentElement?.querySelector(`svg`)?.classList.remove(`hidden`);
  }, []);

  return (
    <Stack spacing={1} component="ul" sx={{ padding: 0 }}>
      {products.length > 0
        ? products.map((product) => {
            const imageUrl =
              (`productImageUrl` in product
                ? product.productImageUrl
                : `imageUrl` in product
                  ? product.imageUrl
                  : null) ?? ProductFallback;

            const warranty = (product as ProjectProductRowDto).productWarranty ?? (product as ProductDto).warranty;

            return (
              <ListItem
                key={product.id}
                title={ProductCategoriesName || (product as ProjectProductRowDto)?.productCategoryName}
                avatar={imageUrl && <img src={imageUrl || ``} onError={imageError} alt="product image" />}
                subtitle={
                  <Stack>
                    <Typography variant="subtitle1">
                      {(product as ProductDto)?.name ?? (product as ProjectProductRowDto)?.productName}
                    </Typography>
                    {!!warranty && (
                      <Typography variant="subtitle1">{`${warranty} ${translate(offer.productWarrantySuffix)}`}</Typography>
                    )}
                  </Stack>
                }
                secondaryAction={
                  getFormattedNumberWithStaticFraction(
                    (product as ProjectProductRowDto)?.quantity,
                    null,
                    (product as ProjectProductRowDto)?.unit,
                  ) ?? null
                }
              />
            );
          })
        : translate(offer.noMaterials)}
    </Stack>
  );
};
