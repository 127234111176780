import React from 'react';
import { Polygon } from '@react-google-maps/api';
import { useTheme } from '@mui/material/styles';
import { getPolygonStyle } from '../utils/style';
import { useHover } from './utils';

type PatchPolygonProps = {
  path: google.maps.LatLng[];
  enableShapeEditing: boolean;
  hidePolygon?: boolean;
  highlightEdges: boolean;
  onClick?: () => void;
  onDragEnd?: () => void;
  onDragStart?: () => void;
  onLoad: (newPolygon: google.maps.Polygon) => void;
};
export const PatchPolygon: React.FC<PatchPolygonProps> = ({
  path,
  enableShapeEditing,
  onDragStart,
  onDragEnd,
  onLoad,
  onClick,
  highlightEdges,
  hidePolygon = false,
}) => {
  const [isHovered, hoverFunctions] = useHover();
  const theme = useTheme();

  return (
    <Polygon
      path={path}
      draggable={enableShapeEditing}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onLoad={onLoad}
      onClick={onClick}
      options={getPolygonStyle({
        hidePolygon,
        highlightEdges,
        isHovered,
        palette: theme.palette,
      })}
      {...hoverFunctions}
    />
  );
};
