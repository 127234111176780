import { Stack, Typography, useTheme } from '@mui/material';
import { useGetOffer, useUpdateOffer } from '@services/api/offers/offers';
import { useGetLeadProjects } from '@services/api/leads/lead-info';
import { useCallback, useEffect, useMemo } from 'react';
import {
  ReturnFromInvestmentForm,
  SolarEnergyReturnOfInvestmentType,
} from '@pages/offer/components/SolarEnergyProject/ReturnOfInvestementForm/ReturnForInvestmentForm';
import { TypographyWithIconTooltip } from '@components/Tooltip/TypographyWithIconTooltip';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { ProjectType } from '@generatedTypes/data-contracts';
import { Card } from '@pages/offer/offer.styled';
import debounce from 'lodash.debounce';

export const PayoffCard = () => {
  const {
    translate,
    translations: {
      leads: {
        offer: { solarEnergyOffer, estimatedReturnHeader, estimatedReturnDescription },
      },
    },
  } = useTranslations();
  const theme = useTheme();
  const { updateOffer } = useUpdateOffer();
  const { leadProjects } = useGetLeadProjects();
  const { offer: offerDto, isOfferLocked } = useGetOffer();

  const { grossPrice, yearlyProduction } = useMemo(
    () =>
      (leadProjects ?? []).reduce(
        (sums, project) => {
          if (project.type === ProjectType.SolarEnergy) {
            const projectsYearlyProduction = project.solarEnergyProjectDetails?.totalYearlyProductionInkWhHundreds ?? 0;
            const projectsGrossPrice =
              offerDto?.price.bidPrices.find((bidPrice) => bidPrice.projectId === project.id)?.totalGrossPrice ?? 0;
            sums.yearlyProduction = sums.yearlyProduction + projectsYearlyProduction;
            sums.grossPrice = sums.grossPrice + projectsGrossPrice;
          }
          return sums;
        },
        { grossPrice: 0, yearlyProduction: 0 },
      ),
    [leadProjects, offerDto?.price.bidPrices],
  );

  const onUpdateProductionSettings = useCallback(
    ({ sellingPrice, purchasePrice, soldEnergyFactor }: SolarEnergyReturnOfInvestmentType) => {
      if (offerDto) {
        updateOffer({
          offerId: offerDto.id,
          introText: offerDto.introText,
          offerSenderId: offerDto.sender?.id,
          numberOfDaysBeforeExpiry: offerDto.numberOfDaysBeforeExpiry,
          ownToSoldEnergyFactor: soldEnergyFactor,
          sellingPrice,
          purchasePrice,
        });
      }
    },
    [offerDto, updateOffer],
  );

  const debouncedOnUpdateProductionSettings = useMemo(
    () => debounce(onUpdateProductionSettings, 500),
    [onUpdateProductionSettings],
  );

  useEffect(() => {
    return () => {
      debouncedOnUpdateProductionSettings.cancel();
    };
  });

  if (!yearlyProduction) {
    return null;
  }

  return (
    <Stack direction="row" sx={{ backgroundColor: theme.palette.background.default }} p={2}>
      <Stack sx={{ flex: 1 }} mr={2}>
        <Typography variant="h4">{translate(estimatedReturnHeader)}</Typography>
        <Typography>{translate(estimatedReturnDescription)}</Typography>
      </Stack>
      <Card
        title={
          <TypographyWithIconTooltip
            typographyVariant="h3"
            web
            text={translate(solarEnergyOffer.returnInvestmentTooltip)}
          >
            {translate(solarEnergyOffer.returnInvestmentHeader)}
          </TypographyWithIconTooltip>
        }
      >
        <ReturnFromInvestmentForm
          projectGrossPrice={grossPrice}
          yearlyProductionInkWh={yearlyProduction}
          onChange={debouncedOnUpdateProductionSettings}
          disableInputs={isOfferLocked}
          initValues={
            offerDto
              ? {
                  soldEnergyFactor: offerDto?.payoff?.ownToSoldEnergyFactor ?? 0,
                  purchasePrice: offerDto?.payoff?.purchasePrice ?? 0,
                  sellingPrice: offerDto?.payoff?.sellingPrice ?? 0,
                }
              : undefined
          }
        />
      </Card>
    </Stack>
  );
};
