import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { Card } from '@mui/material';

export const ModalDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    boxShadow: `none`,
    backgroundColor: `transparent`,
    margin: theme.spacing(6, 0),
    width: `100%`,
    maxWidth: `none`,
    minWidth: 600,

    [theme.breakpoints.down(`md`)]: {
      margin: 0,
      maxHeight: `100%`,
      minWidth: `100%`,

      '& .grid-container': {
        margin: 0,
        height: `100%`,
      },
    },
  },
}));

export const ResponsiveContentWrapper = styled(`div`)(({ theme }) => ({
  gridColumnStart: 3,
  gridColumnEnd: 11,
  [theme.breakpoints.down(`md`)]: {
    gridColumnStart: 1,
    gridColumnEnd: 13,
  },
}));

export const ContentCard = styled(Card)(({ theme }) => ({
  display: `flex`,
  flexDirection: `column`,
  maxHeight: `calc(100vh - ${theme.spacing(12)})`,
  border: `none`,

  [theme.breakpoints.down(`md`)]: {
    maxHeight: `calc(100vh)`,
  },
}));
