import { SignInRequest } from '@generatedTypes/data-contracts';

export async function loginUser(credentials: SignInRequest) {
  return fetch(`${process.env.PROTECTED_API_URL}/Authenticate/sign-in`, {
    method: `POST`,
    mode: `cors`,
    headers: {
      'Content-Type': `application/json`,
    },
    body: JSON.stringify(credentials),
  })
    .then(async (res) => {
      if (!res.ok) {
        const json = await res.json();
        throw json;
      }
      return res.json();
    })
    .catch((err) => {
      throw new Error(err.errors[0].message);
    });
}
