import { ImageUploader } from '@components/ImageUploader/ImageUploader';
import { ColumnItem } from '@components/columnLayout/utils';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { FormSection } from '@components/forms/MuiFormSection';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { UserType } from '@generatedTypes/data-contracts';
import { UniversalUser } from '@services/api/users/types';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback, useState } from 'react';

type EditProfilePictureProps = {
  closeModal: () => void;
  user: UniversalUser | null;
  onPictureUpdated: (userId: number, userType: UserType, profilePicture?: File) => void;
};

export const EditProfilePicture: ColumnItem<EditProfilePictureProps> = ({ closeModal, user, onPictureUpdated }) => {
  const [profilePicture, setProfilePicture] = useState<File | undefined>();

  const {
    translate,
    translations: {
      users: { details },
    },
  } = useTranslations();

  const updateUserProfilePicture = () => {
    if (user) {
      if (profilePicture) {
        onPictureUpdated(user.id, user.type, profilePicture);
      } else if (user.logotypeUri && !profilePicture) {
        onPictureUpdated(user.id, user.type);
      }
    }
  };

  const imageUploaded = useCallback((uploadedImage: File) => {
    setProfilePicture(uploadedImage);
  }, []);

  const imageDeleted = useCallback(() => {
    setProfilePicture(undefined);
  }, []);

  return (
    <FormWrapper
      onCancel={closeModal}
      onSubmit={(e) => {
        e.preventDefault();
        updateUserProfilePicture();
      }}
    >
      <FormSection title={translate(details.profilePicture.header)}>
        <ImageUploader
          onImageUpload={imageUploaded}
          onImageDelete={imageDeleted}
          currentImage={user?.logotypeUri}
          aspectRatio="16:9"
          key={`profile-image-uploader-${user?.id}`}
        />
        <HorizontalLine />
      </FormSection>
    </FormWrapper>
  );
};
