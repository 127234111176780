import { CreateProjectProductRequest } from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useQueryClient } from 'react-query';
import { LEAD, LEADS, OFFER_PRICE_OVERVIEW, PROJECTS } from '@variables/queryKeys';

const postProjectProduct = async (product: CreateProjectProductRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/project-products`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(product),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

export const useCreateProjectProductMutation = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: postProjectProduct,
    onSuccess: () => {
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
    },
  });

  return {
    addProjectProductAsync: mutateAsync,
    addProjectProduct: mutate,
    isAddingProjectProduct: isLoading,
    addProjectProductBeValidationErrors: validationErrors,
  };
};
