import { Card } from '@components/Card/Card';
import { PartnerDto } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import { mdiPencil } from '@mdi/js';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { EditClientInformation } from './EditClientInformation';
import { useDeleteDealersPartners, useGetDealers, useUpdateDealersPartners } from '@services/api/dealers';

export interface ClientInformationCardProps {
  partner: PartnerDto | null;
}

export const ClientInformationCard = ({ partner }: ClientInformationCardProps) => {
  const {
    translate,
    translations: {
      users: { details },
    },
  } = useTranslations();
  const [showEditClientInformation, toggleShowEditClientInformation] = useToggle();
  const deleteDealersPartners = useDeleteDealersPartners();
  const updateDealersPartners = useUpdateDealersPartners({ onSuccess: toggleShowEditClientInformation });
  const { dealers } = useGetDealers();

  return (
    <Card
      title={translate(details.clientInformation.header)}
      titleAction={toggleShowEditClientInformation}
      titleActionIcon={mdiPencil}
      titleActionTestId="edit-client-information"
    >
      <Stack spacing={3}>
        <Typography variant="h4" component="b">
          {translate(details.clientInformation.dealerBranchHeader)}
        </Typography>
        <div>{partner?.dealer ? partner.dealer.name : translate(details.clientInformation.dealerNotSetUp)}</div>
      </Stack>
      <Drawer anchor="right" open={showEditClientInformation} onClose={toggleShowEditClientInformation}>
        <EditClientInformation
          goBackHandler={toggleShowEditClientInformation}
          deletePartnerDealer={deleteDealersPartners.mutate}
          updatePartnerDealer={updateDealersPartners.mutate}
          partner={partner}
          beValidationResults={updateDealersPartners.validationErrors}
          dealerRows={dealers}
        />
      </Drawer>
    </Card>
  );
};
