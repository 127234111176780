import { useContext } from 'react';
import {
  isPathsVertexArray,
  Patch,
} from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/roofVisualisationTypes';
import { EnqueuePatchContext } from '../../useSavePatchQueue';
import { UseFieldArrayAppend, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/SolarEnergyProject';
import { addPatch, isPatchShapeValid, removePatch } from '../../patch';
import { actions, getDispatchSolarEnergyProject } from '../store';
import { SOLAR_MAP_EDIT_STATE } from '../../constants';
import { getValuesFromAttributeRow } from '@pages/NewLeads/project/solarEnergyProject/utils/utils';
import { SolarMapVisualisationContext, SolarMapVisualisationDispatchContext } from '../context';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { useSelector } from 'react-redux';
import {
  selectAvailablePanelOrientations,
  selectDirectionChipsOptions,
  selectSlopeChipsOptions,
} from '@redux/reducers/slices/solarEnergyProjectPage';
import { useSelectedRoofAndPatch } from '../../accessors';

export const useHandleUpdatePatch = () => {
  const enqueuePatch = useContext(EnqueuePatchContext);
  return (patch: Patch) => {
    enqueuePatch(patch);
  };
};

export const useHandleCreatePatch = () => {
  const { solarEnergyProject } = useContext(SolarMapVisualisationContext);
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  const dispatchSolarEnergyProject = getDispatchSolarEnergyProject(dispatch);
  const { railOrientation, solarPanelOrientation, numberOfRails, roofMaterial } = useGetSolarEnergyProjectSettings();
  const initialRoofMaterial = getValuesFromAttributeRow(roofMaterial)[0];
  const orientationOptions = useSelector(selectAvailablePanelOrientations);
  const slopeChipsOptions = useSelector(selectSlopeChipsOptions);
  const directionChipsOptions = useSelector(selectDirectionChipsOptions);

  const angle = slopeChipsOptions[1].value;
  const direction = directionChipsOptions?.[0].value;
  const solarPanelOrientationId = orientationOptions?.find(({ disabled }) => !disabled)?.value ?? null;

  return (appendPatchCallback: UseFieldArrayAppend<SolarEnergyProjectValues, `roofs.${number}.patches`>) => () => {
    const newSolarEnergyProject = addPatch(solarEnergyProject);
    if (newSolarEnergyProject) {
      dispatchSolarEnergyProject(newSolarEnergyProject);
      dispatch(actions.setMapEditState(SOLAR_MAP_EDIT_STATE.SHAPE));
      const allRailOrientations = getValuesFromAttributeRow(railOrientation);
      const defaultSelectedRailOrientation =
        allRailOrientations.find(
          (railOrientation) => initialRoofMaterial?.availableRailOrientationIds?.includes(railOrientation.id) ?? false,
        )?.id ?? -1;
      const allPanelOrientations = getValuesFromAttributeRow(solarPanelOrientation);
      const defaultSelectedPanelOrientation = initialRoofMaterial?.availablePanelOrientationIds?.includes(
        solarPanelOrientationId ?? -1,
      )
        ? solarPanelOrientationId
        : allPanelOrientations.find(
            (panelOrientation) =>
              initialRoofMaterial?.availablePanelOrientationIds?.includes(panelOrientation.id) ?? false,
          )?.id ?? -1;
      appendPatchCallback({
        id: null,
        shape: null,
        direction,
        angle,
        solarPanelOrientationId: defaultSelectedPanelOrientation,
        solarPanelGrid: null,
        shapeType: `free`,
        numberOfRailsId: getValuesFromAttributeRow(numberOfRails)[0].id || -1,
        railOrientationId: defaultSelectedRailOrientation,
        height: 0,
        width: 0,
        numberOfRows: 0,
        numberOfColumns: 0,
      });
    }
  };
};

export const useHandleRemovePatch = () => {
  const { solarEnergyProject } = useContext(SolarMapVisualisationContext);
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  const dispatchSolarEnergyProject = getDispatchSolarEnergyProject(dispatch);
  const { selectedPatch, selectedPatchIndex, selectedRoof } = useSelectedRoofAndPatch();

  return (removePatchCallback: UseFieldArrayRemove) => () => {
    if (!selectedPatch) {
      return;
    }
    const selectedPatchIndexValid = selectedPatchIndex >= 0 && selectedPatchIndex < (selectedRoof?.patches.length ?? 0);

    if (selectedPatchIndexValid) {
      removePatchCallback(selectedPatchIndex);
      const removedPatchProject = removePatch(solarEnergyProject, selectedPatch.id);
      dispatchSolarEnergyProject(removedPatchProject);
      dispatch(actions.setMapEditState(SOLAR_MAP_EDIT_STATE.ROOF));
    }
  };
};

export const useIsSelectedPatch = () => {
  const { selectedPatch } = useSelectedRoofAndPatch();
  if (!selectedPatch) {
    return false;
  }
  return isPatchShapeValid(selectedPatch);
};

export const useHandleApplyShape = () => {
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  return () => {
    dispatch(actions.setMapEditState(SOLAR_MAP_EDIT_STATE.PANELS));
  };
};

export const useHandleEditShape = () => {
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  const { selectedRoofIndex, selectedPatchIndex } = useSelectedRoofAndPatch();
  const formFunctions = useFormContext<SolarEnergyProjectValues>();

  return () => {
    dispatch(actions.setMapEditState(SOLAR_MAP_EDIT_STATE.SHAPE));
    formFunctions.setValue(`roofs.${selectedRoofIndex}.patches.${selectedPatchIndex}.shapeType`, `free`);
  };
};

export const useHandlePolygonDragEnd = () => {
  const handleUpdatePatch = useHandleUpdatePatch();

  return (patch: Patch) => () => {
    const paths = patch.polygon?.getPath()?.getArray();
    const selectedVertex = patch.vertices.findIndex((vertex) => vertex.selected);
    if (isPathsVertexArray(paths)) {
      const newVertices = paths.map((latLng, index) => {
        const marker = patch.vertices[index].marker;
        marker?.setPosition(latLng);
        return {
          latLng,
          marker,
          id: index.toString(),
          selected: selectedVertex === index,
        };
      });
      handleUpdatePatch({ ...patch, vertices: newVertices });
    }
  };
};
