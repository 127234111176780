import { ColumnLayoutContent } from '@components/Layout/ColumnLayout/ColumnLayoutContent';
import { PageHeader } from '@components/PageHeader/PageHeader';
import Tabs from '@components/Tabs/Tabs';
import { useDeletePartner, useGetPartner } from '@services/api/partners';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useNavigate, useParams } from 'react-router-dom';
import { BusinessTab } from './BusinessTab';
import { PartnersUsersTab } from './PartnerUsersTab';
import { ActionWithConfirmation } from '@components/ActionWithConfirmation';
import { mdiClose } from '@mdi/js';

export const PartnerDetails = () => {
  const navigate = useNavigate();
  const { partnerId } = useParams<{ partnerId: string }>();
  const { partner } = useGetPartner();
  const deletePartner = useDeletePartner();

  const {
    translate,
    translations: {
      users: { details },
      common: {
        buttons: { delete: deleteButton },
      },
      partners: {
        list: { removePartnerModalContent },
      },
    },
  } = useTranslations();

  return (
    <ColumnLayoutContent
      isOpen={Boolean(partnerId)}
      header={
        <PageHeader
          title={partner?.name ?? ``}
          actions={
            partnerId ? (
              <ActionWithConfirmation
                dataTestId="remove-partner"
                buttonIconPath={mdiClose}
                confirmationPopupTitle={translate(deleteButton)}
                confirmationPopupContent={translate(removePartnerModalContent)}
                onConfirm={() => {
                  deletePartner.mutate(Number(partnerId));
                  navigate(`/partners`);
                }}
              >
                {translate(deleteButton)}
              </ActionWithConfirmation>
            ) : undefined
          }
        />
      }
    >
      <Tabs
        label="Partner tabs"
        tabs={[translate(details.logo.tabs.title1), translate(details.logo.tabs.title2)]}
        tabPanels={[
          <BusinessTab key="business-tab" partner={partner} />,
          <PartnersUsersTab key="partners-users-tab" />,
        ]}
      />
    </ColumnLayoutContent>
  );
};
