import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPartnerId } from '@redux/reducers/slices/user';
import { selectIsPartnerUser } from '@redux/selectors/user';
import { useQueryClient } from 'react-query';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { PARTNER, PROJECT_ADDITION_TEMPLATES } from '@variables/queryKeys';
import { postProjectAdditionTemplate } from '@services/api/projectAdditionTemplates/projectAdditionTempaltes';

export const useCreateProjectAdditionTemplate = (options?: { onSuccess?: () => void }) => {
  const { partnerId: partnerIdParams } = useParams<{ partnerId: string }>();
  const partnerId = useSelector(selectPartnerId);
  const isPartnerUser = useSelector(selectIsPartnerUser);

  const selectedPartnerId = isPartnerUser ? partnerId : Number(partnerIdParams);

  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors, parsedBackendError } = useCustomMutation({
    mutationKey: [PROJECT_ADDITION_TEMPLATES],
    mutationFn: postProjectAdditionTemplate,
    onSuccess: (createdProjectAdditionTemplateId) => {
      queryClient.invalidateQueries([PARTNER, PROJECT_ADDITION_TEMPLATES, selectedPartnerId]);
      queryClient.invalidateQueries([PROJECT_ADDITION_TEMPLATES, createdProjectAdditionTemplateId]);
      options?.onSuccess?.();
    },
  });

  return {
    isAddingProjectAdditionTemplate: isLoading,
    addProjectAdditionTemplate: mutate,
    addProjectAdditionTemplateAsync: mutateAsync,
    addProjectAdditionTemplateValidationErrors: validationErrors,
    addProjectAdditionTemplateValidationErrorsParsedBackendError: parsedBackendError,
  };
};
