import { FC, ReactNode } from 'react';
import { ThemeProvider as ThemeProviderCore } from '@mui/material/styles';
import { theme } from './theme';

export interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => (
  <ThemeProviderCore theme={theme}>{children}</ThemeProviderCore>
);
