import { SolarEnergyProjectValues } from '../../SolarEnergyProject';
import { useWatch, useFormContext } from 'react-hook-form';
import { Select } from '@components/controls/react-hook-form-friendly/smart/MuiSelect';
import { RoofMaterialAttributeValueDto } from '@generatedTypes/data-contracts';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useEffectOnRoofPropertiesChange } from './useEffectOnRoofPropertiesChange';
import InputAdornment from '@mui/material/InputAdornment';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { getValuesFromAttributeRow } from '@pages/NewLeads/project/solarEnergyProject/utils/utils';
import { MuiNumberInput } from '@components/controls/react-hook-form-friendly/smart/MuiNumberInput';

interface AttachementInputsProps {
  roofIndex: number;
  roofMaterial: RoofMaterialAttributeValueDto;
  isTileRoofMaterial: boolean;
  maxDistanceBetweenTopsTinOrMetal?: number | null;
}

export const AttachementInputs = ({
  roofIndex,
  roofMaterial,
  isTileRoofMaterial,
  maxDistanceBetweenTopsTinOrMetal,
}: AttachementInputsProps) => {
  const {
    translate,
    translations: {
      common: { lath },
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: { distanceFolds, distancePeaks, maxDistanceCanBe },
            },
          },
        },
      },
    },
  } = useTranslations();

  const { solarPanelAttachments, lathDimension } = useGetSolarEnergyProjectSettings();
  const formContext = useFormContext<SolarEnergyProjectValues>();

  const lathDimensions =
    getValuesFromAttributeRow(lathDimension).map(({ name, id }) => ({
      label: name as string,
      value: id,
    })) || [];

  const selectedAttachementId = useWatch({
    control: formContext.control,
    name: `roofs.${roofIndex}.solarPanelAttachmentId`,
  });

  const isLathAttachment =
    getValuesFromAttributeRow(solarPanelAttachments).find(({ id }) => String(id) === String(selectedAttachementId))
      ?.name === `Läkt`;
  const { defaultDistanceBetweenTopsTinOrMetal, defaultDistanceBetweenFolds } = roofMaterial;

  useEffectOnRoofPropertiesChange({
    roofIndex,
    path: `lathDimensionId`,
    defaultValue: lathDimensions[0]?.value ?? null,
  });
  useEffectOnRoofPropertiesChange({
    roofIndex,
    path: `distanceBetweenTopsTinOrMetal`,
    defaultValue: defaultDistanceBetweenTopsTinOrMetal ?? null,
  });
  useEffectOnRoofPropertiesChange({
    roofIndex,
    path: `distanceBetweenFolds`,
    defaultValue: defaultDistanceBetweenFolds ?? null,
  });

  return (
    <>
      {isTileRoofMaterial && isLathAttachment && (
        <Select
          control={formContext.control}
          label={translate(lath)}
          name={`roofs.${roofIndex}.lathDimensionId`}
          options={lathDimensions}
        />
      )}
      {defaultDistanceBetweenFolds && (
        <MuiNumberInput
          control={formContext.control}
          name={`roofs.${roofIndex}.distanceBetweenFolds`}
          label={translate(distanceFolds)}
          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
          required
          size="small"
        />
      )}
      {defaultDistanceBetweenTopsTinOrMetal && (
        <MuiNumberInput
          control={formContext.control}
          name={`roofs.${roofIndex}.distanceBetweenTopsTinOrMetal`}
          label={translate(distancePeaks)}
          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
          helperText={
            maxDistanceBetweenTopsTinOrMetal && `${translate(maxDistanceCanBe)} ${maxDistanceBetweenTopsTinOrMetal}mm`
          }
          required
          size="small"
        />
      )}
    </>
  );
};
