import { authFetch, getErrorFromFormRequest } from '@services/api/utils';
import {
  CreateEnergyStorageProjectRequest,
  EnergyStorageProjectDto,
  ProjectType,
  UpdateEnergyStorageProjectRequest,
} from '@generatedTypes/data-contracts';
import { useSelector } from 'react-redux';
import { selectCurrentOfferBidId } from '@redux/selectors/lead';
import { useGetLeadProjects } from '@services/api/leads/lead-info';
import { useMemo } from 'react';
import { useCustomQuery } from '@hooks/useCustomQuery';
import {
  ENERGY_STORAGE_PROJECT,
  LEAD,
  LEADS,
  OFFER_PRICE_OVERVIEW,
  PROJECTS,
  SOLAR_ENERGY_PROJECT,
} from '@variables/queryKeys';
import { useQueryClient } from 'react-query';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { selectFeatureEnabled } from '@redux/reducers/slices/user';

const getEnergyStorageProject = async (id: number) => {
  if (id < 0) {
    return null;
  }

  return authFetch(`${process.env.PROTECTED_API_URL}/energy-storage-projects/${id}`, {
    method: `GET`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    })
    .then((json) => json as EnergyStorageProjectDto)
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

const postEnergyStorageProject = async (data: CreateEnergyStorageProjectRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/energy-storage-projects`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

export interface PutEnergyStorageProject extends UpdateEnergyStorageProjectRequest {
  id: number;
}

const putEnergyStorageProject = async ({ id, ...data }: PutEnergyStorageProject) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/energy-storage-projects/${id}`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

const deleteEnergyStorageProject = async (projectId: number) => {
  if (projectId === -1) {
    return null;
  }
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/energy-storage-projects/${projectId}`, {
    method: `DELETE`,
    mode: `cors`,
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
};

export const useGetEnergyStorageProject = () => {
  const selectedProjectId = useSelector(selectCurrentOfferBidId);
  const { leadProjects } = useGetLeadProjects();
  const isEnergyStorageEnabled = useSelector(selectFeatureEnabled(`energy-storage-project`));

  const projectType = useMemo(
    () => leadProjects?.find((project) => project.id === selectedProjectId)?.type ?? ProjectType.None,
    [selectedProjectId, leadProjects],
  );
  const { data, refetch, isLoading } = useCustomQuery({
    queryKey: [SOLAR_ENERGY_PROJECT, selectedProjectId],
    queryFn: () => getEnergyStorageProject(selectedProjectId),
    enabled: isEnergyStorageEnabled && selectedProjectId > 0 && projectType === ProjectType.EnergyStorage,
  });

  return { energyStorageProjectDetails: data ?? null, refetchEnergyStorageProjectDetails: refetch, isLoading };
};

export const usePostEnergyStorageProject = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: postEnergyStorageProject,
    onSuccess: () => {
      queryClient.invalidateQueries(ENERGY_STORAGE_PROJECT);
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(LEAD);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
    },
  });

  return {
    createEnergyStorageProject: mutate,
    createEnergyStorageProjectAsync: mutateAsync,
    isCreateEnergyStorageProjectLoading: isLoading,
    validationErrors,
  };
};
export const usePutEnergyStorageProject = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: putEnergyStorageProject,
    onSuccess: () => {
      queryClient.invalidateQueries(ENERGY_STORAGE_PROJECT);
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
    },
  });

  return {
    updateEnergyStorageProject: mutate,
    updateEnergyStorageProjectAsync: mutateAsync,
    isUpdateEnergyStorageProjectLoading: isLoading,
    validationErrors,
  };
};

export const useDeleteEnergyStorageProject = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading } = useCustomMutation({
    mutationFn: deleteEnergyStorageProject,
    onSuccess: () => {
      queryClient.invalidateQueries(ENERGY_STORAGE_PROJECT);
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(LEAD);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
    },
  });

  return { deleteEnergyStorageProject: mutate, deleteEnergyStorageProjectAsync: mutateAsync, isLoading };
};
