import { CustomerDetails, Common } from '@assets/translations/translations';
import { CommaSeparatedTitle } from '@components/CommaSeparatedTitle/CommaSeparatedTitle';
import { LeftNavLinkThreeRows } from '@components/LeftNavLink/LeftNavLinkThreeRows';
import { LeadOfferStatus, LeadRowDto } from '@generatedTypes/data-contracts';
import { Translation } from '@services/hooks/translations/useTranslations';
import { formatDate } from '@utils/formatDate';
import { Dispatch } from 'react';

export interface LeadsLeftNavLinkProps {
  lead: LeadRowDto;
  isOperationUser: boolean;
  isDealerUser: boolean;
  isPartnerUser: boolean;
  translate: (translation: Translation | null) => string;
  paramLeadId?: string;
  commonTranslations: Common;
  isFirstLoad: boolean;
  setLeftLink: Dispatch<React.SetStateAction<HTMLAnchorElement | null>>;
  getTranslatedLeadStatus: ({ status }: LeadRowDto) => string;
  customerDetails: CustomerDetails;
  currentLanguage: string;
  expiresPrefix: string;
}
export const LeadsLeftNavLink = ({
  lead,
  isOperationUser,
  isDealerUser,
  translate,
  isPartnerUser,
  paramLeadId,
  commonTranslations,
  isFirstLoad,
  setLeftLink,
  getTranslatedLeadStatus,
  customerDetails,
  currentLanguage,
  expiresPrefix,
}: LeadsLeftNavLinkProps) => {
  const partnerData = lead.assignedPartner?.name ?? lead.assignedPartner?.accountId;
  const dealerName = lead.assignedDealer?.name;
  const operationString =
    isOperationUser && partnerData && dealerName ? `${partnerData}, ${dealerName}` : partnerData ?? dealerName;
  const dealerString = isDealerUser && partnerData;
  const partnerString = ` `;

  const customerType = lead?.customerType.split(``);
  customerType[0] = customerType[0].toLowerCase();
  const modifiedCustomerType = customerType.join(``);
  const isSelected = paramLeadId === String(lead.id);

  const leftTopText = lead.customerType
    ? translate(customerDetails[modifiedCustomerType as keyof typeof customerDetails])
    : translate(commonTranslations.noCustomer);
  const leftBottomText = isPartnerUser
    ? partnerString
    : operationString ?? (dealerString || translate(commonTranslations.notAssigned));
  const expiresDate = new Date(lead.expiresOn ?? ``);

  return (
    <LeftNavLinkThreeRows
      ref={isFirstLoad && String(lead.id) === paramLeadId ? setLeftLink : undefined}
      selected={isSelected}
      key={lead.id}
      href={`/leads/${lead.id}`}
      title={<CommaSeparatedTitle firstPart={lead.address.street} secondPart={lead.address.city} preventWrap />}
      leftTop={leftTopText}
      rightTop={formatDate(lead.createdDate, translate(commonTranslations.yesterday))}
      rightBottom={
        lead.isCloseToExpire &&
        `${expiresPrefix} ${expiresDate.getDate()} ${expiresDate.toLocaleString(currentLanguage, { month: `short` })}`
      }
      middleRight={lead.status !== LeadOfferStatus.Created ? getTranslatedLeadStatus(lead) : ``}
      leftBottom={leftBottomText}
      options={{
        leftTop: { italic: leftTopText === translate(commonTranslations.noCustomer) },
        leftBottom: { italic: leftBottomText === translate(commonTranslations.notAssigned) },
      }}
    />
  );
};
