import React from 'react';
import './../../chips.css';
import Stack from '@mui/material/Stack';
import { MdiIconWrapper } from '@components/MdiIconWrapper';

export type ChipProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  version: `small` | `large` | `square`;
  label: string;
  disabled?: boolean;
  icon?: string;
};

export const Chip: React.FC<ChipProps> = ({
  version,
  label,
  disabled = false,
  type,
  name,
  checked,
  onChange,
  onClick,
  value,
  icon,
}) => (
  <div className={`chips chips--${version}`}>
    <input
      id={`chip-radio-${name}-${value}`}
      type={type}
      name={name}
      value={value}
      className="chips-radio"
      disabled={disabled}
      checked={checked}
      onChange={!disabled ? onChange : undefined}
      onClick={!disabled ? onClick : undefined}
    />
    <label htmlFor={`chip-radio-${name}-${value}`} className={`${disabled ? `disabled` : ``}`}>
      <Stack direction="row" spacing={1} alignItems="center" lineHeight={1}>
        {icon && <MdiIconWrapper path={icon} />}
        {label}
      </Stack>
    </label>
  </div>
);
