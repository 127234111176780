import Brown3Background from '@assets/svg/Brown3Background.svg';
import logo from '@assets/svg/logo.svg';
import Icons from '@assets/svg/svgDefs.svg';
import { CarChargingOffer, ProjectNames, ProjectOffer, SolarEnergyOffer } from '@assets/translations/translations';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { OfferPageManagementDropdown } from '@components/OfferManagementDropdown';
import { WebTypography } from '@components/Typography/WebTypography';
import { CheckBox } from '@components/controls/check-box';
import { formatZipCode } from '@components/controls/formatters';
import { RichTextPreview } from '@components/controls/rich-text-preview';
import { GridLayoutWithSideElementAndMargins } from '@components/gridLayout/gridLayoutWithSideElementAndMargins';
import { UserTypeBaseRenderer } from '@components/helpers/UserTypeBaseRenderer';
import { GoogleMaps } from '@components/maps/GoogleMaps/GoogleMaps';
import {
  OfferAgreementCarChargingBidDto,
  OfferAgreementDto,
  OfferAgreementEmptyBidDto,
  OfferAgreementSolarEnergyBidDto,
  LeadOfferStatus,
  ProjectType,
  SubscriptionDescriptionDto,
  TeaserContentDto,
  OfferAgreementEnergyStorageBidDto,
  OfferAgreementAttachmentRowDto,
  BidPriceRowDto,
} from '@generatedTypes/data-contracts';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { mdiChevronLeft, mdiFileOutline } from '@mdi/js';
import { Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { getDescriptionRows } from '@pages/NewLeads/offer/subscription/utils';
import { getFormattedNumberWithStaticFraction, getFormattedPriceWithStaticFraction } from '@pages/NewLeads/utils';
import { handlePrint } from '@pages/offer/utils';
import {
  EditOfferAgreementProps,
  useGetOfferAgreement,
  useUpdateOfferAgreement,
} from '@services/api/offerAgreements/offerAgreements';
import { Translation, useTranslations } from '@services/hooks/translations/useTranslations';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SolarCalculation } from './SolarCalculation';
import { Roof } from './components/Roof';
import { OfferMapWithSolars } from './components/map';
import './offer.css';
import { ContactForOffer } from './components/Contact/ContactForOffer';
import { CURRENT_PANELS_RESOLVER_VERSION } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/panelsResolver/panelsResolver';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { ProductsListForOffer } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/ProductsListForOffer';
import { GridLayoutWithMargins } from '@components/gridLayout/gridLayoutWithMargins';

export function Logo() {
  return (
    <div className="bold h1">
      <img src={logo} alt="logo" />
    </div>
  );
}

export type OfferHeaderProps = {
  leadOfferStatus: LeadOfferStatus;
};

export const OfferHeader: React.FC<OfferHeaderProps> = ({ leadOfferStatus }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { header, headerBeforeSending },
      },
    },
  } = useTranslations();

  const isOfferCreatedOrProjectingOrProjected =
    leadOfferStatus === LeadOfferStatus.Created ||
    leadOfferStatus === LeadOfferStatus.Projecting ||
    leadOfferStatus === LeadOfferStatus.Projected;
  const offerIsProjected = leadOfferStatus === LeadOfferStatus.Projected;

  return (
    <Box className="row space-between offer-top">
      <UserTypeBaseRenderer include={[`Partner`]}>
        <WebTypography variant="h1">{translate(header)}</WebTypography>
      </UserTypeBaseRenderer>
      <UserTypeBaseRenderer exclude={[`Partner`]}>
        <WebTypography variant="h1">
          {translate(isOfferCreatedOrProjectingOrProjected ? headerBeforeSending : header)}
        </WebTypography>
        {offerIsProjected && (
          <div className="row">
            <PrintButton />
          </div>
        )}
      </UserTypeBaseRenderer>
    </Box>
  );
};

const PrintButton = () => {
  const pushDataLayer = useAnalytics();
  const { offerAgreement } = useGetOfferAgreement();
  return (
    <div className="row gap-big no-print">
      <IconButton
        onClick={() => {
          pushDataLayer({
            event: AnalyticEvents.offerPrint,
            projectType:
              (offerAgreement?.details?.carChargingBids?.map(() => ProjectType.CarCharging).join(`, `) ?? ``) +
              (offerAgreement?.details?.solarEnergyBids?.map(() => ProjectType.SolarEnergy).join(`, `) ?? ``),
          });
          handlePrint();
        }}
      >
        <svg className="icon icon-medium color-blue">
          <use href={`${Icons}#print`} />
        </svg>
      </IconButton>
    </div>
  );
};

export type OfferNavbarProps = {
  onOfferStatusChange: (edit: EditOfferAgreementProps) => Promise<void>;
  isUpdatingOfferStatus: boolean;
};

export const OfferNavbar: React.FC<OfferNavbarProps> = ({ onOfferStatusChange, isUpdatingOfferStatus }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { headerBackButton },
      },
    },
  } = useTranslations();

  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className="box row fw space-between no-print offer-topbar">
      <Button variant="text" onClick={handleBack} startIcon={<MdiIconWrapper path={mdiChevronLeft} />}>
        {translate(headerBackButton)}
      </Button>
      <Box sx={{ flex: `1 1 auto` }} />
      <OfferPageManagementDropdown onAccept={onOfferStatusChange} isUpdating={isUpdatingOfferStatus} />
    </div>
  );
};

export type OfferCardIntroInformationProps = {
  offerDetails: OfferAgreementDto;
};

export const OfferCardIntroInformation: React.FC<OfferCardIntroInformationProps> = ({ offerDetails }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { offerIntro },
      },
    },
  } = useTranslations();

  const offerLeadAddressStreet = offerDetails.details?.property.address.street;
  const offerLeadAddressCity = offerDetails.details?.property.address.city;
  const zipCodeAndTown = `${formatZipCode(
    offerDetails.details?.property.address.zipCode ?? ``,
  )} ${offerLeadAddressCity}`;
  const isPossibleToShowMapLocation = offerLeadAddressStreet && offerLeadAddressCity;

  return (
    <div className="offer-intro-information column gap">
      <Stack gap={2} className="offer-partner-information">
        <RichTextPreview richTextAsHtml={offerDetails.details?.introText ?? ``} />
        <Stack gap={1} className="address">
          <WebTypography variant="h4">{translate(offerIntro.propertyHeadline)}</WebTypography>
          <div>
            <WebTypography className="lead-street">
              {(offerDetails.details?.property.customerType &&
                translate(offerIntro.propertyType[offerDetails.details.property.customerType])) ||
                ``}
            </WebTypography>
            <WebTypography className="lead-street">{offerDetails.details?.property.address.street ?? ``}</WebTypography>
            <WebTypography className="lead-zip-code-town">{zipCodeAndTown || ``}</WebTypography>
          </div>
        </Stack>
        {isPossibleToShowMapLocation && (
          <div className="map">
            {offerDetails.details?.property.address && (
              <GoogleMaps address={offerDetails.details?.property.address} disbaleMapUi={true} />
            )}
          </div>
        )}
      </Stack>
    </div>
  );
};

export type TermsAndConditionsAndContactProps = {
  offerDetails: OfferAgreementDto;
};

export const TermsAndConditionsAndContact: React.FC<TermsAndConditionsAndContactProps> = ({ offerDetails }) => {
  const {
    translate,
    translations: {
      users: {
        details: { termsAndConditions },
      },
    },
  } = useTranslations();

  return (
    <Box className="no-print" sx={{ marginTop: 10 }}>
      <GridLayoutWithSideElementAndMargins bgColor="bg-beige">
        <Stack id="terms-and-conditions" spacing={3}>
          <WebTypography variant="h2">{translate(termsAndConditions.header)}</WebTypography>
          <RichTextPreview
            richTextAsHtml={offerDetails.partner?.termsAndConditions ?? ``}
            innerClassName="color-grey-1 small web-content"
          />
        </Stack>
        {offerDetails.partner.offerSender && <ContactForOffer senderDetails={offerDetails.partner.offerSender} />}
      </GridLayoutWithSideElementAndMargins>
    </Box>
  );
};

export type AcceptOfferCardProps = {
  offerDetails: OfferAgreementDto;
  loggedInUser: boolean;
};

const TermsAndConditionsLabel = () => {
  const {
    translate,
    translations: {
      leads: {
        offer: { acceptOffer },
      },
    },
  } = useTranslations();
  const onTermsAndConditionsClick = () => {
    document.getElementById(`terms-and-conditions`)?.scrollIntoView({ behavior: `smooth`, block: `end` });
  };

  return (
    <div>
      <p>
        {translate(acceptOffer.termsFront)}
        {` `}
        <span className="terms-and-conditions-link" onClick={onTermsAndConditionsClick}>
          {translate(acceptOffer.termsLink)}
        </span>
      </p>
    </div>
  );
};

export const AcceptOfferCard: React.FC<AcceptOfferCardProps> = (props) => {
  const pushDataLayer = useAnalytics();
  const {
    translate,
    translations: {
      leads: {
        offer: { acceptOffer, price, offerManagementPopup, priceOverviewPage },
      },
    },
  } = useTranslations();

  const [termsAccept, setTermsAccept] = useState(props.offerDetails.status === LeadOfferStatus.Accepted);
  const [offerAccepted, setOfferAccepted] = useState(props.offerDetails.status);
  const onChangeHandlerTerms = (e: React.ChangeEvent<HTMLInputElement>) => setTermsAccept(e.target.checked);

  const { updateOfferAgreementAsync, isUpdatingOfferAgreement } = useUpdateOfferAgreement();

  const toggleOfferStatusHandler = () => {
    pushDataLayer({
      event: AnalyticEvents.offerAccept,
      projectType:
        (props.offerDetails.details?.carChargingBids.map(() => ProjectType.CarCharging).join(`, `) ?? ``) +
        (props.offerDetails.details?.solarEnergyBids.map(() => ProjectType.SolarEnergy).join(`, `) ?? ``),
    });
    const { hashedId } = props.offerDetails;
    if (hashedId) {
      if (offerAccepted === LeadOfferStatus.Sent || offerAccepted === LeadOfferStatus.Projected) {
        updateOfferAgreementAsync({
          hashedId,
          status: LeadOfferStatus.Accepted,
        }).then(() => setOfferAccepted(LeadOfferStatus.Accepted));
      }
    }
  };

  const projectsPrices = props.offerDetails?.details?.price.bidPrices.map((bid, index) => (
    <div key={`${bid.projectName}-${index}`} className="column gap-small">
      <div className="row space-between">
        <Typography>{bid.projectName}</Typography>
        <Typography>{getFormattedNumberWithStaticFraction(bid?.totalGrossPriceWithoutDeduction ?? 0, 2)}</Typography>
      </div>
      {bid.deductions.map(({ grossDeduction, name, value }) => (
        <div className="row space-between" key={`${name}-${value}`}>
          <Typography>{name}</Typography>
          <Typography>- {getFormattedNumberWithStaticFraction(grossDeduction, 2)}</Typography>
        </div>
      ))}
      <HorizontalLine classes="gray" />
    </div>
  ));

  return (
    <Stack spacing={3} className="no-print">
      <Typography variant="h1" component="h2">
        {offerAccepted === LeadOfferStatus.Accepted
          ? translate(offerManagementPopup.states.operation.accepted.fullTitle)
          : translate(acceptOffer.acceptTitle)}
      </Typography>
      <div className="column gap-small">{projectsPrices}</div>
      <div className="row space-between">
        <Typography>{translate(priceOverviewPage.rounding)}</Typography>
        <Typography>
          {getFormattedNumberWithStaticFraction(props.offerDetails.details?.price?.totalGrossPriceRounding ?? 0, 2)}
        </Typography>
      </div>
      <HorizontalLine classes="gray" />
      <div className="column gap gap-extra-small">
        <Typography variant="h3">{translate(priceOverviewPage.totalHeader)}</Typography>
        <div className="row space-between">
          <Typography variant="h4">{translate(price.totalGross)}</Typography>
          <Typography variant="h4">
            {getFormattedPriceWithStaticFraction(
              props.offerDetails.details?.price?.totalGrossPriceIncludingRounding ?? 0,
            )}
          </Typography>
        </div>
        <div className="row space-between">
          <Typography>{translate(price.totalTax)}</Typography>
          <Typography>
            {getFormattedPriceWithStaticFraction(props.offerDetails.details?.price?.totalTax ?? 0)}
          </Typography>
        </div>
      </div>
      <hr className="horizontal-line fw" />
      <div className="column gap gap-small">
        <div className="row">
          <CheckBox
            name="terms"
            value="terms"
            label={<TermsAndConditionsLabel />}
            checked={termsAccept}
            isDisabled={offerAccepted === LeadOfferStatus.Accepted || props.loggedInUser}
            isLabelOnRight
            onChange={onChangeHandlerTerms}
          />
        </div>
        <div className="row">
          {offerAccepted === LeadOfferStatus.Accepted ? (
            <div>
              <span>{translate(offerManagementPopup.states.operation.accepted.shortTitle)}</span>
            </div>
          ) : (
            <Button
              disabled={!termsAccept || isUpdatingOfferAgreement || props.loggedInUser}
              onClick={toggleOfferStatusHandler}
              fullWidth
            >
              {translate(acceptOffer.accept)}
            </Button>
          )}
        </div>
      </div>
    </Stack>
  );
};

export type InformationBlockProps = {
  imageUrl: string;
  header: string;
  content: string;
  linkUrl: string;
  linkText: string;
};
export const InformationBlock: React.FC<InformationBlockProps> = ({ header, content, imageUrl, linkText, linkUrl }) => (
  <Stack spacing={3}>
    <img src={imageUrl} width="100%" alt="teaser image" />

    <Stack className="information-block-content">
      <img className="teaser-background" src={Brown3Background} alt="Teaser background" />
      <Stack spacing={2} zIndex={1}>
        <WebTypography variant="h2">{header}</WebTypography>
        <WebTypography variant="subtitle1">{content}</WebTypography>
        <a
          href={linkUrl}
          className="no-decoration color-blue-1 row align-center gap-extra-small teaser-link"
          target="_blank"
          rel="noreferrer"
        >
          <Typography variant="h3">{linkText}</Typography>
          <svg className="icon icon-medium color-blue">
            <use href={`${Icons}#chevron-right`} />
          </svg>
        </a>
      </Stack>
    </Stack>
  </Stack>
);

export type TeasersProps = {
  teasers: TeaserContentDto[];
};

export const Teasers: React.FC<TeasersProps> = ({ teasers }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: {
          teasers: { linkText, header, moreInfo, disclaimer },
        },
      },
    },
  } = useTranslations();

  return (
    <GridLayoutWithMargins bgColor="bg-brown-3" offset={0} size={12}>
      <Grid container rowGap={2} columnGap={3}>
        <Grid item xs={12}>
          <WebTypography variant="h2">{translate(header)}</WebTypography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <WebTypography>{translate(moreInfo)}</WebTypography>
        </Grid>
        {teasers.map((teaser) => (
          <Grid item xs={5.8} key={teaser.id ?? 0}>
            <InformationBlock
              header={teaser.heading ?? ``}
              content={teaser.text ?? ``}
              imageUrl={teaser.imageUrl ?? ``}
              linkText={translate(linkText)}
              linkUrl={teaser.url ?? ``}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Stack spacing={4} mt={2}>
            <HorizontalLine classes="teasers-horizontal-line gray" />
            <WebTypography variant="caption">{translate(disclaimer)}</WebTypography>
          </Stack>
        </Grid>
      </Grid>
    </GridLayoutWithMargins>
  );
};

export type CarChargingCardsProps = {
  translate: (translation: Translation) => string;
  translations: CarChargingOffer;
  subscriptionName: string;
  subscriptionDescription?: SubscriptionDescriptionDto;
};

export const CarChargingCards: React.FC<CarChargingCardsProps> = ({
  translate,
  translations,
  subscriptionDescription,
  subscriptionName,
}) => (
  <div className="column gap gap-small no-break">
    <h3 className="h3--web">{translate(translations.serviceHeader)}</h3>
    <div className="offer-agreement-subscription-wrapper fw column gap-extra-small">
      <h2 className="bold">{translate(translations.subscriptionHeader)}</h2>
      <h1 className="bold color-blue-1">Rexolution {subscriptionName}</h1>
      <div>{getDescriptionRows(subscriptionDescription)}</div>
    </div>
  </div>
);

export type SolarEnergyCardsProps = {
  translate: (translation: Translation) => string;
  translations: SolarEnergyOffer;
  bid: OfferAgreementSolarEnergyBidDto;
  projectGrossPrice: number;
  isPartnerAssigned: boolean;
};

export const SolarEnergyCards: React.FC<SolarEnergyCardsProps> = ({
  translate,
  translations,
  bid,
  isPartnerAssigned,
  projectGrossPrice,
}) => (
  <Stack spacing={2}>
    <WebTypography variant="h3">{translate(translations.projectHeader)}</WebTypography>
    <Grid container rowGap={1}>
      {bid.roofs?.map((roof) => (
        <Grid item xs={6} key={roof.id}>
          <Roof roof={roof} />
        </Grid>
      ))}
    </Grid>
    <Stack spacing={4}>
      {bid.roofs && (
        <OfferMapWithSolars
          roofs={bid.roofs}
          panelsResolverVersion={bid.panelsResolverVersion || CURRENT_PANELS_RESOLVER_VERSION}
        />
      )}
      {bid.solarEnergyProduction ? (
        <SolarCalculation solarEnergyBid={bid} showPayoff={isPartnerAssigned} projectGrossPrice={projectGrossPrice} />
      ) : null}
    </Stack>
  </Stack>
);

export type ProjectCommonCard = {
  translate: (translation: Translation) => string;
  projectNamesTranslations: ProjectNames;
  headers: ProjectOffer;
  project: OfferAgreementCarChargingBidDto | OfferAgreementSolarEnergyBidDto | OfferAgreementEmptyBidDto;
  type: ProjectType;
};

export const ProjectCommonCard: React.FC<ProjectCommonCard> = ({
  translate,
  projectNamesTranslations,
  headers,
  project,
  type,
}) => {
  const projectNameTranslation = useMemo(() => {
    switch (type) {
      case ProjectType.CarCharging:
        return translate(projectNamesTranslations.carCharging);
      case ProjectType.SolarEnergy:
        return translate(projectNamesTranslations.solarEnergy);
      case ProjectType.EnergyStorage:
        return translate(projectNamesTranslations.energyStorage);
      case ProjectType.Empty:
        return project.name;
      default:
        return translate(projectNamesTranslations.none);
    }
  }, [
    project.name,
    projectNamesTranslations.carCharging,
    projectNamesTranslations.none,
    projectNamesTranslations.solarEnergy,
    projectNamesTranslations.energyStorage,
    translate,
    type,
  ]);

  return (
    <Stack gap={4} className="common-project-components">
      <Stack gap={3} className="project-header">
        <WebTypography variant="h2">{projectNameTranslation}</WebTypography>
        <RichTextPreview richTextAsHtml={project.description ?? ``} />
      </Stack>
      <Stack spacing={2}>
        <WebTypography variant="h3">{translate(headers.header2)}</WebTypography>
        <ProductsListForOffer products={project.products ?? []} />
      </Stack>
    </Stack>
  );
};

export type ProjectsCardProps = {
  carChargingBids: OfferAgreementCarChargingBidDto[];
  solarEnergyBids: OfferAgreementSolarEnergyBidDto[];
  emptyBids: OfferAgreementEmptyBidDto[];
  energyStorageBids: OfferAgreementEnergyStorageBidDto[];
  isPartnerAssigned: boolean;
  bidPrices: BidPriceRowDto[];
};

type OfferAttachmentsProps = {
  attachments: OfferAgreementAttachmentRowDto[];
};
export const OfferAttachments: React.FC<OfferAttachmentsProps> = ({ attachments }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { attachmentsHeader },
      },
    },
  } = useTranslations();

  const theme = useTheme();

  return (
    attachments.length > 0 && (
      <Stack spacing={4}>
        <WebTypography variant="h2">{translate(attachmentsHeader)}</WebTypography>
        <Stack spacing={0}>
          {attachments.map((attachment) => (
            <Button
              key={attachment.name}
              variant="text"
              size="medium"
              startIcon={<MdiIconWrapper path={mdiFileOutline} iconColor={theme.palette.rexelEnergy.ctaBlue} />}
              href={attachment.attachmentUri ?? ``}
              download
              sx={{
                '&.MuiButton-root': {
                  justifyContent: `flex-start`,
                  fontFamily: `'Gotham Book', Arial, Helvetica, sans-serif`,
                },
              }}
            >
              {`${attachment.name} (${attachment.extension})`}
            </Button>
          ))}
        </Stack>
      </Stack>
    )
  );
};

export const ProjectsCards: React.FC<ProjectsCardProps> = ({
  carChargingBids,
  solarEnergyBids,
  emptyBids,
  energyStorageBids,
  isPartnerAssigned,
  bidPrices,
}) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: { projectNames },
        },
        offer: { project, carChargingOffer, solarEnergyOffer },
      },
    },
  } = useTranslations();
  const carChargingBidsCards = carChargingBids.map((bid) => (
    <Stack key={bid.id} gap={4}>
      <ProjectCommonCard
        key={bid.id}
        translate={translate}
        projectNamesTranslations={projectNames}
        headers={project}
        project={bid}
        type={ProjectType.CarCharging}
      />
      <CarChargingCards
        subscriptionDescription={bid.subscriptionDescription}
        subscriptionName={bid.subscriptionName ?? ``}
        translate={translate}
        translations={carChargingOffer}
      />
    </Stack>
  ));

  const solarEnergyBidsCards = solarEnergyBids.map((bid) => (
    <Stack key={bid.id} gap={4}>
      <ProjectCommonCard
        key={bid.id}
        translate={translate}
        projectNamesTranslations={projectNames}
        headers={project}
        project={bid}
        type={ProjectType.SolarEnergy}
      />
      <SolarEnergyCards
        translate={translate}
        translations={solarEnergyOffer}
        bid={bid}
        isPartnerAssigned={isPartnerAssigned}
        projectGrossPrice={bidPrices.find((price) => price.projectId === bid.id)?.totalGrossPrice ?? 0}
      />
    </Stack>
  ));
  const emptyBidsCards = emptyBids.map((bid) => (
    <Stack key={bid.id} gap={4}>
      <ProjectCommonCard
        key={bid.id}
        translate={translate}
        projectNamesTranslations={projectNames}
        headers={project}
        project={bid}
        type={ProjectType.Empty}
      />
    </Stack>
  ));
  const energyStorageBidsCards = energyStorageBids.map((bid) => (
    <Stack key={bid.id} gap={4}>
      <ProjectCommonCard
        key={bid.id}
        translate={translate}
        projectNamesTranslations={projectNames}
        headers={project}
        project={bid}
        type={ProjectType.EnergyStorage}
      />
    </Stack>
  ));

  return (
    <Stack gap={4}>
      {carChargingBidsCards}
      {solarEnergyBidsCards}
      {energyStorageBidsCards}
      {emptyBidsCards}
    </Stack>
  );
};
