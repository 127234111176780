import { ProjectType } from '@generatedTypes/data-contracts';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { EmptyProjectForm, FormInputs } from '@pages/NewLeads/project/emptyProject/emptyProject';
import {
  useDeleteEmptyProject,
  useGetEmptyProject,
  usePostEmptyProject,
  usePutEmptyProject,
} from '@services/api/emptyProjects/emptyProjects';
import { useGetOffer } from '@services/api/offers/offers';
import { useSelector } from 'react-redux';
import { selectCurrentOfferBidId } from '@redux/selectors/lead';

export interface EmptyProjectFormWrapperProps {
  onClose: () => void;
}

export const EmptyProjectFormWrapper = ({ onClose }: EmptyProjectFormWrapperProps) => {
  const pushDataLayer = useAnalytics();
  const { isOfferLocked, lead } = useGetOffer();
  const { emptyProjectDetails } = useGetEmptyProject();
  const { createEmptyProjectAsync, isCreateEmptyProjectLoading, createEmptyProjectError } = usePostEmptyProject();
  const { updateEmptyProjectAsync, isUpdateEmptyProjectLoading, updateEmptyProjectError } = usePutEmptyProject();
  const { deleteEmptyProjectAsync } = useDeleteEmptyProject();
  const currentProjectID = useSelector(selectCurrentOfferBidId);

  const handleAddEditEmptyProject = (values: FormInputs) => {
    pushDataLayer({
      event: AnalyticEvents.saveProject,
      projectType: ProjectType.Empty,
    });
    if (currentProjectID) {
      updateEmptyProjectAsync({ ...values, id: currentProjectID }).then(() => onClose());
    } else {
      createEmptyProjectAsync({ ...values, leadId: lead?.id || 0 }).then(() => onClose());
    }
  };

  return (
    <EmptyProjectForm
      onSubmit={handleAddEditEmptyProject}
      onDelete={(id) => deleteEmptyProjectAsync(id).then(() => onClose())}
      onClose={() => {
        onClose();
        pushDataLayer({ event: AnalyticEvents.cancelProject, projectType: ProjectType.Empty });
      }}
      projectDetails={emptyProjectDetails}
      beValidationResults={createEmptyProjectError || updateEmptyProjectError}
      isDisabled={isCreateEmptyProjectLoading || isUpdateEmptyProjectLoading || isOfferLocked}
    />
  );
};
