import { SnackbarProvider } from '@components/SnackbarProvider';
import { LoadScript } from '@react-google-maps/api';
import { store } from '@redux/store';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { router } from './router';
import { ThemeProvider } from './styles/theme/ThemeProvider';

const container = document.getElementById(`root`);
const root = createRoot(container);
const regionAndLang = `sv`;
const libraries = [`places`, `geometry`];
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
    },
  },
});
const isLocalhost = window.location.hostname === `localhost`;

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <LoadScript
        googleMapsApiKey={process.env.GOOGLE_MAPS_API_KEY}
        language={regionAndLang}
        libraries={libraries}
        region={regionAndLang}
      >
        <ThemeProvider>
          <SnackbarProvider>
            <RouterProvider router={router} />
          </SnackbarProvider>
        </ThemeProvider>
      </LoadScript>
    </Provider>
    {isLocalhost && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
