import { HTMLAttributes } from 'react';
import { SliderThumb as SliderThumbCore } from '@mui/material/Slider';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiDrag } from '@mdi/js';

export const SliderThumb = ({ children, ...restProps }: HTMLAttributes<unknown>) => (
  <SliderThumbCore {...restProps}>
    {children}
    <MdiIconWrapper path={mdiDrag} style={{ transform: `rotate(90deg)` }} />
  </SliderThumbCore>
);
