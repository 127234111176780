import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnItem } from '@components/columnLayout/utils';
import { CheckBox } from '@components/controls/check-box';
import { ICON_COLOR, SvgIcon } from '@components/controls/svgIcon';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { DealerRowDto } from '@generatedTypes/data-contracts';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import './rightPanelViewSubForCreatingUser.css';
import { useGetDealer, useGetDealers } from '@services/api/dealers';
import { Button } from '@mui/material';

type RightPanelViewSubForCreatingUserProps = {
  onCancel: () => void;
  isVisible: boolean;
  confirmCb: (list: DealerRowDto[]) => void;
};
export const RightPanelViewSubForCreatingUser: ColumnItem<RightPanelViewSubForCreatingUserProps> = ({
  isVisible,
  onCancel,
  confirmCb,
}) => {
  const {
    translate,
    translations: {
      users: { create },
      dealer,
    },
  } = useTranslations();

  const { dealer: selectedDealer } = useGetDealer();
  const { dealers } = useGetDealers();
  const [selectedDealers, setSelectedDealers] = useState<DealerRowDto[]>([]);
  const [isTouchedCheckbox, setIsTouchedCheckbox] = useState(true);

  const selectedDealerId = selectedDealer?.id || -1;

  // refresh selected dealers
  useEffect(() => {
    if (selectedDealerId >= 0) {
      const newDealers = dealers.filter((el) => el.id === selectedDealerId);
      setSelectedDealers(newDealers);
      confirmCb(newDealers);
    }
  }, [dealers, confirmCb, selectedDealerId]);

  const dealersSelectionHandler = useCallback((dealer: DealerRowDto) => {
    setSelectedDealers((currentDealers) => {
      const index = currentDealers.findIndex((el) => el.id === dealer.id);
      return index > -1 ? [...currentDealers.filter((el) => el.id !== dealer.id)] : [...currentDealers, dealer];
    });
  }, []);

  const handleConfirm = () => {
    confirmCb(selectedDealers);
  };

  const dealersHTML = useMemo(() => {
    const checkedLogic = (dealerId: number) => {
      if (selectedDealerId >= 0 && dealerId === selectedDealerId && !isTouchedCheckbox) {
        setIsTouchedCheckbox(false);
        return true;
      }
      return selectedDealers.findIndex((el) => el.id === dealerId) > -1;
    };

    return dealers.map((dealer) => {
      return (
        <CheckBox
          key={dealer.id}
          label={dealer.name || ``}
          checked={checkedLogic(dealer.id || -1)}
          onChange={() => dealersSelectionHandler(dealer)}
          name={dealer.name || `` + dealer.id}
          value={dealer.id + ``}
          isLabelOnRight
        />
      );
    });
  }, [dealers, selectedDealerId, isTouchedCheckbox, selectedDealers, dealersSelectionHandler]);

  return (
    <div
      id="right-panel-sub-for-creating-user"
      className={`box box-large box-mobile column gap ${isVisible ? `visible` : `hidden`}`}
    >
      <div className="header row align-center">
        <SvgIcon iconId="chevron-left" color={ICON_COLOR.COLOR_DARK} onClick={onCancel} />
        <div className="h1 bold">{translate(create.createHeader)}</div>
      </div>
      <div className="h3 bold">{translate(dealer.users.create.branchOffice)}</div>
      <div className="dealers column gap-small">{dealersHTML}</div>
      <HorizontalLine classes="dark" />
      <Button variant="contained" onClick={handleConfirm}>
        {translate(dealer.users.create.choose)}
      </Button>
    </div>
  );
};
