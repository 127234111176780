import { WebTypography } from '@components/Typography/WebTypography';
import { OfferAgreementPartnerDto } from '@generatedTypes/data-contracts';
import { Box, Container, Link, Stack, Typography } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { theme } from 'src/styles/theme/theme';

export interface ContactForOfferNotFoundProps {
  partnerDetails: OfferAgreementPartnerDto;
}

export const ContactForOfferNotFound: React.FC<ContactForOfferNotFoundProps> = ({ partnerDetails }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: {
          offerNotValid: { contactHeader, contactDescription },
        },
      },
    },
  } = useTranslations();

  const descriptionLines = translate(contactDescription).split(`\n`);

  return (
    <Stack spacing={4}>
      {partnerDetails?.logotypeUri && <img src={partnerDetails?.logotypeUri} alt="logo" />}
      <Container>
        <Stack spacing={1}>
          <WebTypography variant="h2">{translate(contactHeader)}</WebTypography>
          <Box>
            {descriptionLines.map((descriptionLine) => (
              <Typography key={descriptionLine}>{descriptionLine}</Typography>
            ))}
          </Box>
          <Box>
            <Typography>{partnerDetails?.contactPerson}</Typography>
            <Typography>{partnerDetails?.phoneNumber}</Typography>
            <Link color={theme.palette.primary.main} underline="none" href={`mailto:${partnerDetails?.email}`}>
              {partnerDetails?.email}
            </Link>
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
};
