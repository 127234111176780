import { selectRoof } from '../../roof';
import { actions, getDispatchSolarEnergyProject } from '../store';
import { SOLAR_MAP_EDIT_STATE } from '../../constants';
import { useCallback, useContext } from 'react';
import { SolarMapVisualisationContext, SolarMapVisualisationDispatchContext } from '../context';
import { useSelectedRoofAndPatch } from '../../accessors';
import { selectPatch } from '../../patch';

export const useGoBackToRoot = () => {
  const { solarEnergyProject } = useContext(SolarMapVisualisationContext);
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  const dispatchSolarEnergyProject = getDispatchSolarEnergyProject(dispatch);
  const { selectedRoof } = useSelectedRoofAndPatch();

  return () => {
    if (!selectedRoof) {
      return;
    }
    const newSolarEnergyProject = selectRoof(solarEnergyProject, selectedRoof?.id);
    if (newSolarEnergyProject) {
      dispatchSolarEnergyProject(newSolarEnergyProject);
      dispatch(actions.setMapEditState(SOLAR_MAP_EDIT_STATE.ROOF));
    }
  };
};

export const useHandleOpenRoofSettings = () => {
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  const { selectedRoof } = useSelectedRoofAndPatch();

  return () => {
    if (selectedRoof) {
      dispatch(actions.setMapEditState(SOLAR_MAP_EDIT_STATE.ROOF_SETTINGS));
    }
  };
};

export const useHandleSelectPatch = () => {
  const { solarEnergyProject } = useContext(SolarMapVisualisationContext);
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  return useCallback(
    (patchId: string) => () => {
      const newSolarEnergyProject = selectPatch(solarEnergyProject, patchId);
      if (newSolarEnergyProject) {
        dispatch(actions.setSolarEnergyProject(newSolarEnergyProject));
        dispatch(actions.setMapEditState(SOLAR_MAP_EDIT_STATE.PANELS));
      }
    },
    [dispatch, solarEnergyProject],
  );
};
export const useHandleSelectGroup = () => {
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  return () => {
    dispatch(actions.setMapEditState(SOLAR_MAP_EDIT_STATE.GROUP));
  };
};
