import { ChipList } from '@components/controls/react-hook-form-friendly/smart/ChipList';
import { useEffectOnRoofPropertiesChange } from './useEffectOnRoofPropertiesChange';
import { RoofMaterialAttributeValueDto } from '@generatedTypes/data-contracts';
import { useMemo } from 'react';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { getValuesFromAttributeRow } from '@pages/NewLeads/project/solarEnergyProject/utils/utils';

export interface SolarPanelAttachmentChipsProps {
  roofIndex: number;
  availableAttachmentIds: RoofMaterialAttributeValueDto[`availableAttachmentIds`];
}

export const SolarPanelAttachmentChips = ({ roofIndex, availableAttachmentIds }: SolarPanelAttachmentChipsProps) => {
  const {
    translate,
    translations: {
      common: { fastening },
    },
  } = useTranslations();

  const { solarPanelAttachments } = useGetSolarEnergyProjectSettings();

  const filteredSolarPanelAttachments = useMemo(
    () =>
      getValuesFromAttributeRow(solarPanelAttachments)
        .filter(({ id }) => availableAttachmentIds?.includes(id))
        .map(({ name, id }) => ({ label: name as string, value: id })),
    // refresh only on availableAttachmentIds
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [solarPanelAttachments, availableAttachmentIds],
  );

  const { formControl } = useEffectOnRoofPropertiesChange({
    roofIndex,
    path: `solarPanelAttachmentId`,
    defaultValue: filteredSolarPanelAttachments[0]?.value,
  });

  if (availableAttachmentIds?.length) {
    return (
      <ChipList
        version="large"
        control={formControl}
        name={`roofs.${roofIndex}.solarPanelAttachmentId`}
        chips={filteredSolarPanelAttachments}
        wrapChips={true}
        label={filteredSolarPanelAttachments.length > 0 ? translate(fastening) : ``}
      />
    );
  }

  return null;
};
