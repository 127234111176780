import { Card } from '@components/Card/Card';
import { mdiPlus } from '@mdi/js';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import Tabs from '@components/Tabs/Tabs';
import { useSelector } from 'react-redux';
import { selectIsPartnerUser } from '@redux/selectors/user';
import { AdditionsAndDeductionsTab, AdditionsAndDeductionsTabProps } from './AdditionsAndDeductionsTab';
import { ProductsTab, ProductsTabProps } from './ProductsTab';
import { CustomAdditionsAndDeductionsTabAction } from './CustomAdditionsAndDeductionsTabAction';
import { useGetOffer } from '@services/api/offers/offers';

type LeadMaterialsListProps = Omit<ProductsTabProps, `leadDetails`> &
  Omit<AdditionsAndDeductionsTabProps, `projectDeductionHeaderTranslation` | `projectAdditionsTranslations`> & {
    onShowAddNewProductPanel?: () => void;
  };

export const ProductsAdditionsAndDeductionsListCard: React.FC<LeadMaterialsListProps> = (props) => {
  const {
    translate,
    translations: {
      editProduct: { productOverview },
      leads: {
        details: {
          project: { otherCosts },
        },
      },
    },
  } = useTranslations();

  const { isOfferLocked } = useGetOffer();
  const isPartnerUser = useSelector(selectIsPartnerUser);

  const tabs = isPartnerUser
    ? [translate(productOverview), translate(otherCosts.header)]
    : [translate(productOverview)];

  return (
    <Card>
      <Tabs
        tabs={tabs}
        tabsActions={
          !isOfferLocked && !props.isSavingInProgress
            ? [
                { icon: mdiPlus, action: props.onShowAddNewProductPanel },
                {
                  customAction: (
                    <CustomAdditionsAndDeductionsTabAction
                      onShowAddEditAdditionForm={props.onShowAddEditAdditionForm}
                      onShowAddEditDeductionForm={props.onShowAddEditDeductionForm}
                    />
                  ),
                },
              ]
            : []
        }
        tabPanels={[
          <ProductsTab
            key="projectProducts"
            projectProducts={props.projectProducts}
            projectType={props.projectType}
            productCategories={props.productCategories}
            lastModifiedDate={props.lastModifiedDate}
            onShowAddEditProductPanel={props.onShowAddEditProductPanel}
            projectId={props.projectId}
            solarEnergyProductionForRoof={props.solarEnergyProductionForRoof}
            isSavingInProgress={props.isSavingInProgress}
          />,
          <AdditionsAndDeductionsTab
            key="projectAdditions"
            projectAdditions={props.projectAdditions}
            projectDeductions={props.projectDeductions}
            onShowAddEditAdditionForm={!props.isSavingInProgress ? props.onShowAddEditAdditionForm : undefined}
            onShowAddEditDeductionForm={!props.isSavingInProgress ? props.onShowAddEditDeductionForm : undefined}
          />,
        ]}
        label="projectProducts"
      />
    </Card>
  );
};
