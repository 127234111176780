export const fileDownloadActions = (blob: Blob, filename: string): boolean => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement(`a`);
  link.href = url;
  if (filename) {
    link.setAttribute(`download`, filename);
    document.body.appendChild(link);
    link.click();
    return true;
  }
  return false;
};
