import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export interface DealerUserState {
  selectedDealerId?: number;
}

const initialState: DealerUserState = {};

const dealerUserSlice = createSlice({
  name: `DEALER_USER`,
  initialState,
  reducers: {
    setSelectedDealerId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedDealerId = action.payload;
    },
  },
});

export const { setSelectedDealerId } = dealerUserSlice.actions;
export const { reducer: dealerUserReducer } = dealerUserSlice;

export const selectDealerUser = (state: { DealerUserReducer: DealerUserState }) => state.DealerUserReducer;
export const selectSelectedDealerId = createSelector(selectDealerUser, (dealerUser) => dealerUser.selectedDealerId);
