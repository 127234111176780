import { ColumnLayoutContent } from '@components/Layout/ColumnLayout/ColumnLayoutContent';
import { PageHeader } from '@components/PageHeader/PageHeader';
import Tabs from '@components/Tabs/Tabs';
import { useGetPartner } from '@services/api/partners';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import Stack from '@mui/material/Stack';
import { OfferRichTextPreviewWithEdit } from './OfferRichTextPreviewWithEdit';
import { useSelector } from 'react-redux';
import { selectFeatureEnabled } from '@redux/reducers/slices/user';

export const Offer = () => {
  const {
    translate,
    translations: {
      myAccount: { offerSettings },
    },
  } = useTranslations();
  const { partner } = useGetPartner();
  const isCarChargingEnabled = useSelector(selectFeatureEnabled(`car-charging-project`));
  const isEnergyStorageEnabled = useSelector(selectFeatureEnabled(`energy-storage-project`));

  return (
    <ColumnLayoutContent isOpen header={<PageHeader title={translate(offerSettings.header)} />}>
      <Tabs
        label="offer-settings-tabs"
        tabs={[translate(offerSettings.tabsNames.templates)]}
        tabPanels={[
          <Stack gap={2} key="offer">
            <OfferRichTextPreviewWithEdit
              partner={partner}
              templateToEdit="offerIntroTextTemplate"
              richTextInputPick="offerIntroTextTemplate"
              offerSettingsTemplateHeaders="offerIntroTextTemplate"
            />
            {isCarChargingEnabled && (
              <OfferRichTextPreviewWithEdit
                partner={partner}
                templateToEdit="carChargingProjectOfferTextTemplate"
                richTextInputPick="carChargingProjectOfferTextTemplate"
                offerSettingsTemplateHeaders="carChargingProjectOfferTextTemplate"
              />
            )}
            <OfferRichTextPreviewWithEdit
              partner={partner}
              templateToEdit="solarEnergyProjectOfferTextTemplate"
              richTextInputPick="solarEnergyProjectOfferTextTemplate"
              offerSettingsTemplateHeaders="solarEnergyProjectOfferTextTemplate"
            />
            {isEnergyStorageEnabled && (
              <OfferRichTextPreviewWithEdit
                partner={partner}
                templateToEdit="energyStorageProjectOfferTextTemplate"
                richTextInputPick="energyStorageProjectOfferTextTemplate"
                offerSettingsTemplateHeaders="energyStorageProjectOfferTextTemplate"
              />
            )}
            <OfferRichTextPreviewWithEdit
              partner={partner}
              templateToEdit="emptyProjectOfferTextTemplate"
              richTextInputPick="emptyProjectOfferTextTemplate"
              offerSettingsTemplateHeaders="emptyProjectOfferTextTemplate"
            />
          </Stack>,
        ]}
      />
    </ColumnLayoutContent>
  );
};
