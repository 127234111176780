import { setDialog } from '@redux/actions/global';
import { AppState } from '@redux/reducers';
import { store } from '@redux/store';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import './dialog.css';
import { Button } from '@mui/material';

export interface DialogProps {
  isOpen?: boolean;
  header?: string;
  content?: string;
  callback?: () => void;
  dataTestId?: string;
}

export const Dialog: React.FC<DialogProps> = (props) => {
  const {
    translate,
    translations: { common, editProduct },
  } = useTranslations();

  const dialog = useSelector<AppState, DialogProps>((state) => state.Global.dialog);
  const isDialogOpen = dialog.isOpen;
  const dialogHeader = dialog.header;
  const dialogRef = useRef<HTMLDialogElement>(null);

  const removeHandler = () => {
    store.dispatch(
      setDialog({
        isOpen: false,
      }),
    );
    props.callback && props.callback();
  };

  useEffect(() => {
    if (isDialogOpen) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: bug in TS, fixed in new version https://github.com/microsoft/TypeScript/issues/48267
      dialogRef?.current?.showModal();
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: bug in TS, fixed in new version https://github.com/microsoft/TypeScript/issues/48267
      dialogRef?.current?.close();
    }
  }, [isDialogOpen]);

  const setDialogHandler = () => {
    store.dispatch(
      setDialog({
        isOpen: false,
      }),
    );
  };
  return (
    <dialog className="modal alert" ref={dialogRef}>
      <h2 className="bold">{dialogHeader}</h2>
      <p>{dialog.content}</p>
      <form method="dialog">
        <div className="row buttons gap gap-small">
          <Button variant="outlined" onClick={setDialogHandler} data-testid={`${props.dataTestId}-cancel`}>
            {translate(common.buttons.cancel)}
          </Button>
          <Button variant="contained" onClick={removeHandler} data-testid={`${props.dataTestId}-confirm`}>
            {translate(editProduct.remove)}
          </Button>
        </div>
      </form>
    </dialog>
  );
};
