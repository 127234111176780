import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/sv';

dayjs.extend(utc);
dayjs.extend(timezone);

const stockholmTimeZone = `Europe/Stockholm`;

export const formatDate = (dateToFormat: string, yesterdayString: string) => {
  const today = dayjs().tz(stockholmTimeZone);
  const yesterday = today.subtract(1, `day`);
  const date = dayjs.utc(dateToFormat).tz(stockholmTimeZone);

  dayjs.locale(`sv`);

  if (date.isSame(today, `day`)) {
    return date.format(`HH:mm`);
  }

  if (date.isSame(yesterday, `day`)) {
    return yesterdayString;
  }

  if (date.isSame(today, `month`)) {
    return date.format(`DD MMM`);
  }

  return date.format(`DD MMM, YYYY`);
};
